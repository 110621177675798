/**
 * Ce paramètre permet de définir des règles d'accès supplémentaires aux routes définies dans chacun des modules de 
 * l'application. Chacune des routes vérifiant ces règles sont configurées au besoin.
 * S'il y a besoin d'ajouter une règle d'accès à une route, il faut aller vérifier la définition de la ou les routes 
 * concernées et ajouter IsRouteAccessible dans le tableau canActivate ou canActivateChild.
 * Chaque règle est définie par la route en clé sous forme de RegExp. Il est possible d'utiliser une chaîne de caractère 
 * simple décrivant la route entière ou d'utiliser la syntaxe de RegExp.
 * Chaque règle est configurée soit par un boolean soit par un objet contenant des règles supplémentaires.
 * La seule règle supplémentaire implémentée actuellement est onlyForLocalizations qui permet de définir une liste de
 * locales avec lesquelles ont peut accéder à la route.
 * Si besoin une ajouter d'autres règles, basées sur le rôle par exemple ou d'autres paramètres. Il faut implémenter
 * la gestion du paramètre dans IsRouteAccessible
 */
export const routesAccess = {
    '/register': false,
}