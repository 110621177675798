// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  background-color: #DADADA !important;
}
.title .close-button {
  margin-right: 24px;
}

p {
  text-align: center;
}

.mat-raised-button {
  background-color: #24B7C7;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/agenda/core/dialog-confirm-delete/dialog-confirm-delete.component.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;AACJ;AACI;EACI,kBAAA;AACR;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,WAAA;AAAJ","sourcesContent":[".title {\n    background-color: #DADADA !important;\n\n    .close-button {\n        margin-right: 24px;\n    }\n}\n\np {\n    text-align: center;\n}\n\n.mat-raised-button {\n    background-color: #24B7C7;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
