import {Component, OnDestroy, OnInit} from '@angular/core';
import {TimelineService} from '@modules/timeline/core/timeline.service';
import {ActivatedRoute} from '@angular/router';
import {DataEntity} from 'octopus-connect';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnDestroy {

    public timelines: DataEntity[];
    public selectedTimeline: DataEntity;
    public canClose = false;

    constructor(public timelineService: TimelineService,
                private activatedRoute: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        this.timelineService.getTimeline()
            .subscribe((entities) => {
                this.timelines = entities;
                // TODO: until we add an select in html, we select by default the first timeline
                if (entities && entities.length) {
                    this.selectedTimeline = entities[0];
                    this.timelineService.initAllData(this.selectedTimeline);
                }
            });

        this.setCanClose();
    }

    ngOnDestroy(): void {
    }

    public setCanClose(): void {
        this.activatedRoute.queryParams.pipe(
            map(params => {
                if (params.hasOwnProperty('onComplete')) {
                    this.canClose = this.timelineService.onTimelineTempConsultIsDone.hasOwnProperty(params['onComplete']);
                }
            })
        ).subscribe();
    }

    public close(): void {
        this.activatedRoute.queryParams.pipe(
            map(params => {
                if (params.hasOwnProperty('onComplete')) {
                    this.timelineService.onTimelineTempConsultIsDone[params['onComplete']].next();
                    return;
                }
            })
        ).subscribe();
    }
}
