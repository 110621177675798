import { Injectable } from '@angular/core';
import {ResearchTemplate} from '../definitions';

@Injectable({
    providedIn: 'root'
})
export class ResearchTemplateService {

    editedTemplate: ResearchTemplate;

    constructor() { }

    public getTemplateEditLink(templateId: number): string {
        return '/research-template/edit/' + templateId;
    }

    public getTemplateViewLink(templateId: number): string {
        return '/research-template/view/' + templateId;
    }

}
