import { AddInputPipe } from './add-input.pipe';
import { CommonModule } from '@angular/common';
import { IntToBooleanPipe } from '@modules/activities/core/pipes/int-to-boolean.pipe';
import { NgModule } from '@angular/core';
import { StringToHtmlPipe } from '@modules/activities/core/pipes/string-to-html.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [IntToBooleanPipe, StringToHtmlPipe, AddInputPipe],
    exports: [IntToBooleanPipe, StringToHtmlPipe, AddInputPipe],
    providers: [],
})
export class ActivitiesPipesModule {}
