
import {take, combineLatest} from 'rxjs/operators';
import {Component, Input} from '@angular/core';
import {ErrorReportingService} from 'fuse-core/components/error-reporting/error-reporting.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {TranslateService} from '@ngx-translate/core';

export enum reportingType {
    'exercise' = 'exercise',
    'resource' = 'resource'
}

@Component({
    selector: 'app-error-reporting-trigger-button',
    templateUrl: './error-reporting-trigger-button.component.html',
    styleUrls: ['./error-reporting-trigger-button.component.scss']
})
export class ErrorReportingTriggerButtonComponent {

    @Input() type: reportingType;
    @Input() activityId: string;
    @Input() objectTitle: string;

    constructor( private errReportSvc: ErrorReportingService,
                 private communicationCenter: CommunicationCenterService,
                 private translate: TranslateService ) {

    }

    openReportDialog($event: MouseEvent): void {
        this.validInputs();

        const isExercise = this.type === reportingType.exercise;
        const subjectKeyLabel = `error_reporting.${isExercise ? 'exercise' : 'resource'}_subject_value_exercise`;
        const activityIdKey = isExercise ? 'exerciseId' : 'resourceId';

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData').pipe(
            combineLatest(this.translate.get(subjectKeyLabel)),
            take(1),)
            .subscribe((value) => {
                this.errReportSvc.openDialog(this.type, {
                        mail: value[0].get('email'),
                        [activityIdKey] : this.activityId,
                        subject: value[1],
                        objectTitle: this.objectTitle
                    }
                );
            });
    }

    validInputs(): void {
        if (this.type === undefined || this.type === null) {
            throw Error('Reporting Type cannot be undefined or null');
        }
        if (this.activityId === undefined || this.activityId === null || this.activityId === '') {
            throw Error('Reporting Type cannot be undefined, null or empty');
        }
        if (this.objectTitle === undefined || this.objectTitle === null || this.objectTitle === '') {
            throw Error('Reporting Activity Title cannot be undefined, null or empty');
        }
    }
}
