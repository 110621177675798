import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leanersByGroupByWorkingGroups'
})
export class LeanersByGroupByWorkingGroupsPipe implements PipeTransform {

    transform(learners: any[], group: any, wgroup: any[]): any[] {
        if (Array.isArray(learners)) {
            let filtredList = learners;
            if (typeof group !== 'undefined') {
                filtredList = filtredList.filter(item => group === 'all' || item.groups.includes(group));
            }
            if (typeof wgroup !== 'undefined') {
                filtredList = filtredList.filter(item => (wgroup && wgroup.length === 1 && wgroup[0] === 'all') 
                        || item.workgroups.filter(value => wgroup.includes(value)).length > 0 );
            }
            return filtredList;
        } else {
            return [];
        }
      }

}
