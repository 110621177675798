// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-card-actions {
  padding: 8px 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/licensing/core/licensing-display/licensing-display.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF","sourcesContent":[".mat-card-actions {\n  padding: 8px 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
