// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  background: rgba(255, 120, 125, 0.1);
  text-align: center;
  margin: -24px;
  padding: 24px;
  width: 800px;
  position: relative;
}

.text {
  text-align: center;
  margin-bottom: 23px;
  font-weight: bold;
}

input {
  margin-left: 30px !important;
  margin-right: 30px !important;
  background: #FF787D !important;
  border-radius: 10px !important;
  padding: 5px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  cursor: pointer !important;
  color: white !important;
  width: 114px;
  text-align: center;
}

.counter {
  background: #FF787D;
  width: 24px;
  height: 24px;
  color: white;
  font-size: 11px;
  line-height: 26px;
  position: absolute;
  bottom: 12px;
  right: 12px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/research-sheet/core/section-draft-display/draft-questions-modal/draft-questions-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,4BAAA;EACA,6BAAA;EACA,8BAAA;EACA,8BAAA;EACA,uBAAA;EACA,6BAAA;EACA,8BAAA;EACA,0BAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AACF","sourcesContent":[".content {\n  background: rgba(255, 120, 125, 0.1);\n  text-align: center;\n  margin: -24px;\n  padding: 24px;\n  width: 800px;\n  position: relative;\n}\n\n.text {\n  text-align: center;\n  margin-bottom: 23px;\n  font-weight: bold;\n}\n\ninput {\n  margin-left: 30px !important;\n  margin-right: 30px !important;\n  background: #FF787D !important;\n  border-radius: 10px !important;\n  padding: 5px !important;\n  padding-left: 16px !important;\n  padding-right: 16px !important;\n  cursor: pointer !important;\n  color: white !important;\n  width: 114px;\n  text-align: center;\n}\n\n.counter {\n  background: #FF787D;\n  width: 24px;\n  height: 24px;\n  color: white;\n  font-size: 11px;\n  line-height: 26px;\n  position: absolute;\n  bottom: 12px;\n  right: 12px;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
