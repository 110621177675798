import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-licensing-restricted',
  templateUrl: './licensing-restricted.component.html',
  styleUrls: ['./licensing-restricted.component.scss']
})
export class LicensingRestrictedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
