import {TranslateService} from '@ngx-translate/core';
import {defaultLanguage, langs} from './../../settings';
import {Directive, ElementRef, HostListener, OnInit, OnDestroy, Output, EventEmitter, Input, OnChanges} from '@angular/core';
import {Subscription} from 'rxjs';

declare namespace TTS {
    interface IOptions {
        /** text to speak */
        text: string;
        /** cancel, boolean: true/false */
        identifier: string;
        /** voice identifier (iOS / Android) from getVoices */
        locale?: string;
        /** speed rate, 0 ~ 1 */
        rate?: number;
        /** pitch, 0 ~ 1 */
        pitch?: number;
        /** cancel, boolean: true/false */
        cancel?: boolean;
        /** iOS ONLY: a voice URI (DEPRECATED, use identifier) **/
        voiceURI?: string;
    }

    interface TTSVoice {
        /** Voice name */
        name: string;
        /** Language **/
        language: string;
        /** identifier string */
        identifier: string;
    }

    function speak(options: IOptions): Promise<void>;
    function speak(text: string): Promise<void>;

    function stop(): Promise<void>;

    function checkLanguage(): Promise<string>;

    function openInstallTts(): Promise<void>;

    function getVoices(): Promise<TTSVoice[]>;
}

@Directive({
    selector: '[appReadable]'
})
export class ReadableDirective implements OnInit, OnDestroy, OnChanges {
    public voices: SpeechSynthesisVoice[] = [];
    public selectedVoice?: any = null;
    private synth: any;
    private defaultLanguage: string;
    private currentLanguage: string;
    private tranlationChangeObservable: Subscription;
    private isSpeaking = false;
    private readText: string;
    @Output() public speaking = new EventEmitter<{ id: string, value: boolean }>();
    @Input() public uuid?: string = null;
    @Input() forceText: string = null;
    @Input() autoRead = false; // by default false mean text is not read without click action
    @Input() wordingAudio?: string = null; // mp3 could replace the text to speach
    @Input() instructionAudio?: string = null; // mp3 could replace the text to speach
    @Input() activateDirective?: boolean = true; // in some case we want to deactivate the directive
    private audioFile: HTMLAudioElement;

    constructor(
        private elt: ElementRef,
        private translate: TranslateService) {
    }

    ngOnInit(): void {
        if (this.activateDirective) {
            this.launchTts();
        }
    }

    ngOnChanges() {
        if (this.activateDirective) {
            this.launchTts();
        }
    }

    launchTts(): void {
        const defaultLangSetting = langs.find((lang) => lang.id === defaultLanguage);
        this.defaultLanguage = defaultLangSetting.code;
        this.currentLanguage = this.defaultLanguage;

        this.synth = speechSynthesis;
        this.voices = this.synth.getVoices();


        // Set the synth voice based on app lang
        const currentLangSetting = langs.find((lang) => lang.id === this.translate.currentLang);
        if (currentLangSetting) {
            this.currentLanguage = currentLangSetting.code;
        }
        // Safari's case
        this.setVoice(this.currentLanguage);

        // Subscribe to lang change to automatically change voice when the lang changes
        this.tranlationChangeObservable = this.translate.onLangChange.subscribe((newLang) => {
            const newLangSetting = langs.find((lang) => lang.id === newLang.lang);
            if (newLangSetting) {
                this.currentLanguage = newLangSetting.code;
                this.setVoice(this.currentLanguage);
            }
        });
        this.initVoice();
        if (this.forceText && this.forceText !== '') {
            if (this.autoRead) {
                this.play();
            }
        }
    }

    onUtteranceStart(): void {
        this.isSpeaking = true;
        this.speaking.emit({id: this.uuid, value: true});
    }

    onUtteranceStop(): void {
        this.isSpeaking = false;
        this.speaking.emit({id: this.uuid, value: false});
    }

    audioAlternative(elt: ElementRef): string {
        let urlAudio = '';
        const eltClassList = elt.nativeElement.classList;

        if (eltClassList.contains('wording') && !eltClassList.contains('is-instruction') && this.wordingAudio) {
            urlAudio = this.wordingAudio;
        }
        if (eltClassList.contains('instruction') || eltClassList.contains('is-instruction') && this.instructionAudio) {
            urlAudio = this.instructionAudio;
        }
        return urlAudio;
    }

    @HostListener('click')
    click(): void {
        // if directive is deactivate we do nothing
        if (!this.activateDirective) {
            return;
        }
        if (!this.isSpeaking && this.audioAlternative(this.elt) === '') {
            this.synth.cancel();
            // In case of utterance.void === undefined, the default browser lang will be used
            if (!this.selectedVoice) {
                this.synth.addEventListener('voiceschanged', (ev) => {
                    // Need this callback on chrome based as getVoices() will return an empty array prior to this event trigger
                    this.voices = this.synth.getVoices();
                    this.setVoice(this.currentLanguage);
                    this.play();
                });
            } else {
                this.play();
            }
        }

        if (!this.isSpeaking && this.audioAlternative(this.elt)) {
            this.audioFile = new Audio(String(this.audioAlternative(this.elt)));
            this.audioFile.play().then(r => {
            }).catch(err => {
                console.error('Error load wording mp3 file', err);
            });
            
            this.audioFile.onplay = this.onUtteranceStart.bind(this);
            this.audioFile.onended = this.onUtteranceStop.bind(this);
            this.audioFile.onpause = this.onUtteranceStop.bind(this);
            this.audioFile.onerror = this.onUtteranceStop.bind(this);
        }
    }

    /**
     * Replace the text of the element with the text to read
     * @param pattern regex pattern to match
     * @param replacement string to replace the matched pattern
     * @param text string to replace
     * @private
     */
    private replaceChars(pattern, replacement, text): string {
        for (let i = 0; i < (text.match(pattern) || []).length; i++) {
            text = text.replace(pattern, replacement);
        }
        return text;
    }

    private play(): void {
        this.readText = this.forceText || this.elt.nativeElement.innerText;
        // TODO : localize the text
        // replace - with minus
        this.readText = this.replaceChars(/(\d+)\s?(-)\s?(\d+)/g, '$1 minus $3', this.readText);
        // replace . with mal
        this.readText = this.replaceChars(/(\d+)\s?(\.)\s?(\d+)/g, '$1 mal $3', this.readText);
        // replace / with geteilt durch
        this.readText = this.replaceChars(/(\d+)\s?(\/)\s?(\d+)/g, '$1 geteilt durch $3', this.readText);
        // replace _____ by nothing there s nothing to read
        this.readText = this.replaceChars(/(_____)/g, '', this.readText);

        if (this.selectedVoice.lang === 'de-DE') {
            // replace isolate ' qu ' by ' ku ' in deutch language
            this.readText = this.replaceChars(/( qu )/g, ' ku ', this.readText);
        }

        const utterance = new SpeechSynthesisUtterance(this.readText);
        // Some lifeCycle function to handle start stops and error of the speach synth
        utterance.onstart = this.onUtteranceStart.bind(this);
        utterance.onresume = this.onUtteranceStart.bind(this);
        utterance.onend = this.onUtteranceStop.bind(this);
        utterance.onpause = this.onUtteranceStop.bind(this);
        utterance.onerror = this.onUtteranceStop.bind(this);
        utterance.voice = this.selectedVoice;
        utterance.lang = utterance.voice.lang;

        this.synth.speak(utterance);
    }


    /**
     * is need here because in case of not auto read when we don't click immediatly to launch the read of the text
     * the voiceschanged EventListener will not fire and we can't read the text
     */
    private initVoice(): void {
        this.synth.addEventListener('voiceschanged', (ev) => {
            this.voices = this.synth.getVoices();
            this.setVoice(this.currentLanguage);
        });
    }

// TODO: Append an icon next to all readable text fields
    // ngAfterViewInit(): void {
    //     const div = this.renderer.createElement('img');
    //     // TODO: Trouver une illustration
    //     this.renderer.setAttribute(div, 'src', 'hello');
    //     this.renderer.setStyle(div, 'margin-left', '1rem');
    //     this.renderer.appendChild(this.elt.nativeElement, div);
    // }

    // Set the synth voice based on current available voices will set to the 1st found voice of the requested language
    // @param lang: BCP 47 language tag. The language of the voice
    private setVoice(lang: string): void {
        /*for (const voice of this.voices) { // force voice langue DE
            if (voice.lang === 'de' || voice.lang === 'de_DE' || voice.lang === 'de-DE') {
                this.selectedVoice = voice;
                return;
            }
        }*/
        for (const voice of this.voices) {
            if (voice.lang === lang || voice.lang === lang.replace('-', '_')) {
                this.selectedVoice = voice;
                return;
            }
        }
    }

    public stop(): void {
        if(this.synth?.speaking) {
            this.synth.cancel();
        }
        if(this.audioFile){
            this.audioFile.pause();
        }
    }

    ngOnDestroy(): void {
        if (this.tranlationChangeObservable) {
            this.tranlationChangeObservable.unsubscribe();
        }
    }
}
