export const authentication = {
    displayLoginLogo: true,
    enableSSO: false,
    enableLocalCache: true,
    floatLabelControl: 'always', // 'always' | 'never' | 'auto'
    firstConnexionRedirection: {
        default: '/achievement',
        trainer: '/groups/list/groups',
    },
    overrideDefaultRouteByRole: {
        learner: '/achievement',
        trainer: '/groups/list/groups',
    },
    activeChangePasswordStrategy: true,
    onlyLoginWithMail: false,
    displayGARHelper: false,
    enableGAR: true,
};
