
import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SectionDisplayService} from '@modules/research-sheet/core/section-display/section-display.service';
import {ActivatedRoute} from '@angular/router';
import {ResearchSheetService} from '@modules/research-sheet/core/research-sheet.service';
import {ReplaySubject, combineLatest} from 'rxjs';
import {ResearchSection, ResearchSectionTemplate} from '@modules/research-sheet/core/definitions';
import {AuthenticationService} from '@modules/authentication';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {CorpusService, ExternalCorpusDisplayComponent} from '@modules/corpus';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {SectionDetailComponent} from '@modules/research-sheet/core/section-display/section-detail/section-detail.component';
import {Observable, Subject} from 'rxjs';
import * as _ from 'lodash-es';

@Component({
  selector: 'fuse-section-display',
  templateUrl: './section-display.component.html',
  styleUrls: ['./section-display.component.scss']
})
export class SectionDisplayComponent implements OnInit, OnDestroy {

  currentSectionId: number;
  sectionData: ReplaySubject<object> = new ReplaySubject<object>(1);

  parentSection: ResearchSection;

  sectionDefinition: ResearchSectionTemplate;
  sectionParentDefinition: ResearchSectionTemplate;

  section: ResearchSection;

  accessLearner: string[] = ['learner'];
  accessTrainer: string[] = ['trainer'];

  corpusDisplayed = false;

  drafts: DataEntity[];
  selectedDraft: DataEntity;

  @ViewChild('corpusDisplay') corpusDisplay: ExternalCorpusDisplayComponent;
  @ViewChild('sectionDetail', { static: true }) sectionDetail: SectionDetailComponent;

  private unsubscribeInTakeUntil = new Subject<void>();

  selectedTabIndex = 0;

  sectionEntity: DataEntity;

  activeUser: number;

  public lockSave = false;

  constructor(
      public sectionService: SectionDisplayService,
      private route: ActivatedRoute,
      private authService: AuthenticationService,
      public corpusService: CorpusService,
      private octopusConnect: OctopusConnectService,
      private sheetService: ResearchSheetService
  ) { }

  ngOnInit(): void {
      this.sectionService.linkedResources = [];
      this.sectionService.popUpEditComment.next(false);
      this.sectionService.sectionExistOrNot.next(false);
      this.route.params.subscribe(params => {
          this.currentSectionId = params['sectionid'];

          if (this.currentSectionId) {
              this.sectionService.sectionExistOrNot.next(true);

              this.sectionService.researchSheetService.completedObs.pipe(
                  takeUntil(this.unsubscribeInTakeUntil))
                  .subscribe(() => {
                      this.sectionEntity = this.sheetService.sectionsCollection[this.currentSectionId];

                      this.activeUser = this.sectionEntity.get('activeUser');

                      this.section = _.cloneDeep(this.sectionService.researchSheetService.getSection(params['sectionid']));
                      this.setSectionData();

                      this.corpusService.loadCorpusRessourcesList(this.section.medias).pipe(
                          takeUntil(this.unsubscribeInTakeUntil))
                          .subscribe((resources: CorpusRessource[]) => {
                              this.sectionService.linkedResources = resources;
                          });

                      combineLatest(this.sectionService.researchSheetService.loadResearchDrafts(), this.sectionService.researchSheetService.completedObs).pipe(
                          takeUntil(this.unsubscribeInTakeUntil))
                          .subscribe(resp => {
                              this.drafts = resp[0].entities.filter(entity => +entity.get('sectionDefinitions') === this.sectionDefinition.id);
                          });
                  });
          } else {
              this.section = this.sectionService.researchSheetService.currentSection;
              this.setSectionData();

              combineLatest(this.sectionService.researchSheetService.loadResearchDrafts(), this.sectionService.researchSheetService.completedObs).pipe(
                  takeUntil(this.unsubscribeInTakeUntil))
                  .subscribe(resp => {
                      this.drafts = resp[0].entities.filter(entity => +entity.get('sectionDefinitions') === this.sectionDefinition.id);
                  });
          }
      });

      this.sectionService.sectionLockSave.subscribe(lock => this.lockSave = lock);
  }

  ngOnDestroy(): void {
      if (this.sectionEntity && +this.sheetService.currentUser.id === +this.sectionEntity.get('activeUser')) {
          this.sheetService.claimActive(this.sectionEntity);
      }
      this.corpusService.clearSelection();
      this.unsubscribeInTakeUntil.next();
      this.unsubscribeInTakeUntil.complete();
  }

  takeHandOnText(): void {
    if (this.sectionEntity && this.sectionEntity.get('activeUser') === null) {
        this.sectionEntity.set('activeUser', this.sheetService.currentUser.id);
        this.sectionEntity.save();
    }

  }

  get iAmActiveUser(): boolean {
    if (this.sheetService.currentUser && this.sectionEntity) {
      if (this.sectionEntity.get('activeUser') !== null) {
          const me = +this.sheetService.currentUser.id === +this.sectionEntity.get('activeUser');

          if (!me && this.sectionEntity.get('updated') < new Date().getTime() / 1000 - 3600) {
              this.sheetService.claimActive(this.sectionEntity);
          }

          return me;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  loadSections(sheetId: number): Observable<DataCollection> {
    return this.octopusConnect.loadCollection('sections', {
      researchSheet: +sheetId
    });
  }

  setSectionData(){
    if (this.section){

      this.sectionDefinition =  this.section.template;

      this.accessTrainer = this.sectionService.researchSheetService.settings ? this.sectionService.researchSheetService.settings.section.accessFormTrainer : ['trainer'];
      this.accessLearner = this.sectionService.researchSheetService.settings ? this.sectionService.researchSheetService.settings.section.accessFormLearner : ['learner'];

      if (this.section.parent && this.section.parent !== ''){

        this.parentSection = this.sectionService.researchSheetService.getSection(this.section.parent);

        this.sectionParentDefinition =  this.parentSection.template;

        this.sectionData.next({
          sectionParent: this.parentSection,
          sectionParentDefinition: this.sectionParentDefinition,
          currentSection: this.section,
          sectionDefinition :  this.sectionDefinition,
          readingGrid:  this.sectionDefinition ? this.sectionDefinition.readingGrid : '',
          accessTrainer: this.accessTrainer,
          accessLearner: this.accessLearner
        });

        this.sectionService.access.next(this.checkAccessLearner());

      } else{
        this.sectionData.next({
          currentSection: this.section,
          sectionDefinition :  this.sectionDefinition,
          readingGrid:  this.sectionDefinition ? this.sectionDefinition.readingGrid : '',
          accessTrainer: this.accessTrainer,
          accessLearner: this.accessLearner
        });
      }
    }
  }

  validFormText(){
    this.sectionService.lockSave();
    this.sectionService.getSectionTextReady();
  }

  backToList(){
    this.sectionService.researchSheetService.backToSectionList();
  }

  checkAccessTrainer(){
    return this.authService.hasLevel(this.accessTrainer);
  }

  checkAccessLearner(){
    return this.authService.hasLevel(this.accessLearner);
  }

  closeTrainerComment(){
    this.sectionService.showComment(true);
  }

  getCommentCondition() {
    return this.sectionService.popUpEditComment.value;
  }

  get corpusId(): number {
    if (this.corpusService.getCurrentCorpusSet(this.sectionService.researchSheetService.currentProjectId)){
      return this.corpusService.getCurrentCorpusSet(this.sectionService.researchSheetService.currentProjectId).corpusArray[0].id;
    }
  }

  onCorpusSelectionChanged(resources: DataEntity[]) {
    if (resources && this.section) {
      this.sectionService.linkedResources = resources.map((resource: DataEntity) => new CorpusRessource(resource, this.corpusService));

      const ids: number[] = [];

      this.sectionService.linkedResources.forEach(resource => {
        ids.push(+resource.id);
      });

      this.section.medias = ids;
      this.sectionService.setMedias(ids);
    }
  }

  // pas vraiment clean, mais en attendant
  openTab(event) {
    if (event.index === 3) {
      setTimeout(() => {
        this.corpusDisplayed = true;
      }, 100);
    } else {
      setTimeout(() => {
        this.corpusDisplayed = false;
      }, 100);
    }
  }

  draftClickAction(draft: DataEntity) {
      this.selectedDraft = draft;
  }

  useDraft() {
      this.sectionDetail.useDraft(this.selectedDraft);
      this.selectedTabIndex = 0;
  }

}
