import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatLegacyCheckboxChange as MatCheckboxChange} from '@angular/material/legacy-checkbox';

@Component({
    selector: 'app-select-check-all',
    templateUrl: './select-check-all.component.html',
    styleUrls: ['./select-check-all.component.scss']
})
export class SelectCheckAllComponent implements OnInit {
    @Input() model: UntypedFormControl;
    @Input() values = [];
    @Input() text = 'generic.allf';
    constructor() { }

    ngOnInit(): any {
    }

    isChecked(): boolean {
        return this.model.value && this.values.length
            && this.model.value.length === this.values.length;
    }

    isIndeterminate(): boolean {
        return !!(this.model.value && this.values.length && this.model.value.length
            && this.model.value.length < this.values.length);
    }

    toggleSelection(change: MatCheckboxChange): void {
        if (change.checked) {
            this.model.setValue(this.values);
        } else {
            this.model.setValue([]);
        }
    }

}
