// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-card {
  border-radius: 24px;
}
.mat-card.sectionItem.validated {
  border: 1px solid #00E998;
}
.mat-card.sectionItem.correct {
  border: 1px solid #DFB900;
}
.mat-card.sectionItem.pending {
  border: 1px solid #FF787D;
}
.mat-card.sectionItem.closed {
  border: 1px solid #979797;
}
.mat-card .mat-card-header .mat-card-header-text {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/research-sheet/core/research-sheet/research-sheet-display/research-sheet-display-section/research-sheet-display-section.component.scss"],"names":[],"mappings":"AAAA;EAGE,mBAAA;AACF;AACI;EACE,yBAAA;AACN;AACI;EACE,yBAAA;AACN;AACI;EACE,yBAAA;AACN;AACI;EACE,yBAAA;AACN;AAII;EACE,SAAA;AAFN","sourcesContent":[".mat-card{\n  -webkit-border-radius: 24px;\n  -moz-border-radius: 24px;\n  border-radius: 24px;\n  &.sectionItem{\n    &.validated {\n      border: 1px solid #00E998;\n    }\n    &.correct {\n      border: 1px solid #DFB900;\n    }\n    &.pending {\n      border: 1px solid #FF787D;\n    }\n    &.closed {\n      border: 1px solid #979797;\n    }\n  }\n\n  .mat-card-header{\n    .mat-card-header-text {\n      margin: 0;\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
