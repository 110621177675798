import {Component, Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {AssignmentSelectSeancesComponent} from '@modules/assignation/core/components/assignment-by-steps/assignment-select-seances/assignment-select-seances.component';
import {AssignmentSelectAssignatedComponent} from '@modules/assignation/core/components/assignment-by-steps/assignment-select-assignated/assignment-select-assignated.component';
import {AssignmentSettingsChoiceComponent} from '@modules/assignation/core/components/assignment-by-steps/assignment-settings-choice/assignment-settings-choice.component';
import {get} from 'lodash-es';
import {modulesSettings} from '../../../../../settings';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {
    AssignmentSelectOneSeanceComponentComponent
} from '@modules/assignation/core/components/assignment-by-steps/assignment-select-one-seance-component/assignment-select-one-seance-component.component';

@Injectable({
    providedIn: 'root'
})
export class AssignmentByStepService {
    private settingsStructure: ModelSchema = new ModelSchema({
        steps: Structures.array([AssignmentSelectSeancesComponent,
            AssignmentSelectAssignatedComponent,
            AssignmentSettingsChoiceComponent]),

        stepsForLauncInClass: Structures.array([AssignmentSelectSeancesComponent,
            AssignmentSelectAssignatedComponent]),
        stepsForLaunchInFollowedHelp: Structures.array([AssignmentSelectOneSeanceComponentComponent])
    });

    private data: any;
    private lessonTitle = ''; // title of the lesson
    private seancesToSelect: { value: string, label: string, selected: boolean }[] = []; // sceance selectable by user for this lesson
    private settings: { [key: string]: any };

    public learnersAssignment = [];
    public groupsAssignment = [];
    public workgroupsAssignment = [];

    private lessonDoAgainLesson = true;
    private lessonComment = '';

    private startDateLesson = '';
    private endDateLesson = '';
    private disablenextButtonState = false;
    private assignatedToState = '';
    private disabledValidateButtonState = false;

    private launchInClassroom = false;
    private launchInFollowedHelp = false;

    constructor(private octopusConnect: OctopusConnectService) {
        this.settings = this.settingsStructure.filterModel(
            modulesSettings.assignation
        );
    }

    public get disabledNextButton(): boolean {
        return this.disablenextButtonState;
    }

    public set disabledNextButton(state: boolean) {
        this.disablenextButtonState = state;
    }

    public get assignatedTo(): string {
        return this.assignatedToState;
    }

    public set assignatedTo(assignatedList: string) {
        this.assignatedToState = assignatedList;
    }

    public get disabledValidateButton(): boolean {
        return this.disabledValidateButtonState;
    }

    public set disabledValidateButton(state: boolean) {
        this.disabledValidateButtonState = state;
    }


    getCurrentInfo(): void {
        console.log(this.data);
    }

    setCurrentInfo(data): void {
        this.data = data;
    }

    public get steps(): Component [] {
        return get(this.settings, 'steps', []);
    }

    public get stepsIfLaunchInGroup(): Component [] {
        return get(this.settings, 'stepsForLauncInClass', []);
    }

    public get stepsIfLaunchInFollowedHelp(): Component [] {
        return get(this.settings, 'stepsForLaunchInFollowedHelp', []);
    }

    public get seances(): { value: string, label: string, selected: boolean }[] {
        return this.seancesToSelect;
    }

    public set seances(s: { value: string, label: string, selected: boolean }[]) {
        this.seancesToSelect = s;
    }

    public get isLaunchInClassroom(): boolean {
        return this.launchInClassroom;
    }

    public set isLaunchInClassroom(isLaunchInClassroom: boolean) {
        this.launchInClassroom = isLaunchInClassroom;
    }

    public get isLaunchInFollowedHelp(): boolean {
        return this.launchInFollowedHelp;
    }

    public set isLaunchInFollowedHelp(isLaunchInFollowedHelp: boolean) {
        this.launchInFollowedHelp = isLaunchInFollowedHelp;
    }

    public get title(): string {
        return this.lessonTitle;
    }

    public set title(t: string) {
        this.lessonTitle = t;
    }

    public get startDate(): string {
        return this.startDateLesson;
    }

    public set startDate(d: string) {
        this.startDateLesson = d;
    }

    public get doAgainLesson(): boolean {
        return this.lessonDoAgainLesson;
    }

    public set doAgainLesson(d: boolean) {
        this.lessonDoAgainLesson = d;
    }

    public get comment(): string {
        return this.lessonComment;
    }

    public set comment(c: string) {
        this.lessonComment = c;
    }

    public get endDate(): string {
        return this.endDateLesson;
    }

    public set endDate(d: string) {
        this.endDateLesson = d;
    }

    public get learners(): IUSer[] {
        return this.learnersAssignment;
    }

    public set learners(l: IUSer[]) {
        this.learnersAssignment = l;
    }

    public get groups(): IGroup[] {
        return this.groupsAssignment;
    }

    public set groups(g: IGroup[]) {
        this.groupsAssignment = g;
    }

    public get workgroups(): IWorkGroup[] {
        return this.workgroupsAssignment;
    }

    public set workgroups(w: IWorkGroup[]) {
        this.workgroupsAssignment = w;
    }

    public dataFormatedForAssignment(): {
        endDate: number;
        learners: number[];
        lesson: number;
        groups: number[];
        state: number;
        type: number;
        sceances: { value: string; label: string; selected: boolean }[];
        startDate: number;
        numberOfLearners: number
    } {
        const groups = this.groups.filter(g => g.selected === true).map(g => +g.id).concat(this.workgroups.filter(w => w.selected === true).map(w => +w.id));
        const groupsLabel = this.groups.filter(g => g.selected === true).map(g => g.groupname).concat(this.workgroups.filter(w => w.selected === true).map(w => w.workgroupname));

        const learners = this.learners.filter(l => l.selected === true).map(l => +l.id);

        const learnersIdTemp = this.learners.filter((learner) =>
            learner.groups.some((groupId) => groupsLabel.includes(groupId.toString()))
            ||
            learner.workgroups.some((groupId) => groupsLabel.includes(groupId.toString()))
        ).map(l => l.id);

        const allDistinctlearnerId = [...new Set([...learnersIdTemp, ...learners])];
        const numberOfLearnersTemp = allDistinctlearnerId.length;

        /* const numberOfLearnersTemp = +this.learners.filter((learner) =>
                 learner.groups.some((groupId) => groupsLabel.includes(groupId.toString()))
                 ||
                 learner.workgroups.some((groupId) => groupsLabel.includes(groupId.toString()))
             ).length
             + learners.length; */// si les learner sont aussi dans des groupes le compte sera faux

        return {
            learners: learners,
            groups: groups,
            lesson: +this.data?.nodeId,
            state: 2681,
            type: 308,
            startDate: +this.startDateLesson,
            endDate: +this.endDateLesson,
            sceances: this.seances, // not do in back for moment
            numberOfLearners: numberOfLearnersTemp, // number of learners in group workgroup and learner added
        };

        /*     point qu'il reste à traiter en back
               console.log('eleve font de nouveau' + this.doAgainLesson);
               console.log('seance' + this.seances.filter(s => s.selected === true).map(sc => sc.id));
               console.log('comment' + this.comment);*/
    }

    public createAssignment(): Observable<DataEntity> {
        return <any>this.octopusConnect
            .createEntity('assignations-batch', {
                learners: this.learners.filter(l => l.selected === true).map(l => +l.id),
                groups: this.groups.filter(g => g.selected === true).map(g => +g.id).concat(this.workgroups.filter(w => w.selected === true).map(w => +w.id)),
                lesson: +this.data?.nodeId,
                state: 2681,
                type: 308,
                startDate: +this.startDateLesson,
                endDate: +this.endDateLesson,
                sublessons: this.seances.filter(s => s.selected === true).map(sc => +sc.value)
            }).pipe(take(1));

            //console.log(this.seances.filter(s => s.selected === true).map(sc => sc.value));
        /*     point qu'il reste à traiter en back
               console.log('eleve font de nouveau' + this.doAgainLesson);
               console.log('seance' + this.seances.filter(s => s.selected === true).map(sc => sc.id));
               console.log('comment' + this.comment);*/
    }

    public setBreadcrumb(): string[] {
        const concept = this.data.node.get('metadatas')?.concepts[0]?.label;
        const index = this.data.node.get('metadatas')?.indexation[0]?.label;
        const educationalLevel = this.data.node.get('metadatas')?.educationalLevel[0]?.label;
        const chapter = this.data.node.get('metadatas')?.chapters[0]?.label;
        return [
            concept,
            index,
            educationalLevel,
            chapter,
        ];
    }

    public reset(): void {
        this.data = null;
        this.lessonTitle = '';
        this.seancesToSelect = [];
        this.learners = [];
        this.groups = [];
        this.workgroups = [];
        this.doAgainLesson = true;
        this.startDateLesson = '';
        this.endDateLesson = '';
        this.comment = '';
    }
}

export interface IGroup {
    id: number;
    groupname: string;
    color: string | null;
    learnersIds: number[];
    learners: number;
    level: string;
    code: string;
    projects: any[];
    archived: boolean;
    metacognitionActive: boolean;
    schoolyear_term: {
        id: string;
        name: string;
    };
    admins: {
        uid: string;
        roles: {
            [key: string]: string;
        };
    }[];
    selected: boolean;
}

export interface IWorkGroup {
    id: number;
    workgroupname: string;
    archived: boolean;
    learners: IUSer[];
    learnersIds: number[];
    projects: any[];
    schoolyear_term: {
        id: string;
        name: string;
    };
    admins: {
        uid: string;
        roles: {
            [key: string]: string;
        };
    }[];
    selected: boolean;
}

export interface IUSer {
    id: number;
    avatar: string;
    username: string;
    nickname: string;
    parentalConsent: number;
    password: string;
    groups: string[];
    groupsWithData: IGroup[];
    schoolyear_term: {
        id: string;
        name: string;
    };
    workgroups: string[];
    workgroupsWithData: IWorkGroup[];
    sso: boolean;
    codeid: string;
    mail: string;
    level: {
        id: string;
        label: string;
        weight: string;
        showIn: string[];
    }[];
    selected: boolean;
}

export interface IAssignmentByStepResult {
    learners: number[],
    groups: number[],
    sceances: { value: string, label: string, selected: boolean }[],
    lesson: number,
    state: number,
    type: number,
    startDate: number,
    endDate: number,
    comment?: string,
    numberOfLearners?: number,
}
