import {Injectable} from '@angular/core';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '@modules/graph-dysapp/core/model/rules';
import {GraphConfigurationService} from '@modules/graph-dysapp/core/services/graph-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class GraphDysappAuthorizationService {

    constructor(
        private authorizationService: AuthorizationService,
        private graphConfig: GraphConfigurationService
    ) {

    }

    activeRulesOnStartup(): void {
        this.authorizationService.addRoleRule(SyncRules.seeGraphsLinksInMenu, ['learner', 'trainer']);
        this.authorizationService.addRoleRule(SyncRules.seeDysappGraphsBannerInfo, this.graphConfig.isRolesCanShowBannerInfo());
    }

    /**
     * if true, display header banner info
     */
    public get rolesCanShowBannerInfo(): boolean {
        return this.authorizationService.currentUserCan<boolean>(SyncRules.seeDysappGraphsBannerInfo);
    }

}
