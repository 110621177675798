import {Injectable} from '@angular/core';
import {NewsService} from 'fuse-core/news/news.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {debounceTime, filter, map, mergeMap, takeUntil, tap} from 'rxjs/operators';
import {NewsInterface} from 'fuse-core/news/news.interface';
import {combineLatest, Observable, ReplaySubject, Subject} from 'rxjs';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {NavigationEnd, Router} from '@angular/router';
import {SnackbarNewsSettings} from 'fuse-core/news/snackbar/snackbar-news.settings';
import {globalNewsSettings} from '../../../app/settings';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {SnackBarDataInjectedInterface} from 'fuse-core/news/snackbar/snack-bar-data-injected.interface';
import {AuthenticationService} from "@modules/authentication";

const LOCAL_STORAGE_IDENTIFIER = 'snackbar-news-cache';

const DEFAULT_SETTINGS: SnackbarNewsSettings = {
    snackbar: {
        active: {
            default: false,
        },
        cacheInLocalStorage: false,
    },
    recallButton: {
        active: {
            default: false,
        },
        pathLimitations: {
            accepted: [/.*/], // all paths are accepted
            forbidden: [], // no path is forbidden
        }
    }
};

interface LocalStorageCacheInterface {
    [userId: string]: string[];
}

/**
 * This service listen news service to send last news in snackbar
 * - TODO change the NewsInterface to choose a channel (dashboard, snackbar or both)
 */
@Injectable({
    providedIn: 'root'
})
export class SnackbarNewsService {

    private onPostLogout = new Subject<void>();
    private settings: SnackbarNewsSettings = Object.assign({}, DEFAULT_SETTINGS, globalNewsSettings);
    private lastBatchOfNews: NewsInterface[] = [];
    private user: UserDataEntity = null;

    constructor(private communicationCenter: CommunicationCenterService,
                private authService: AuthenticationService,
                private newsService: NewsService,
                private snackBar: MatSnackBar,
                private router: Router) {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((user: UserDataEntity) => {
                if (user) {
                    this.user = user;
                    this.postAuthentication();
                } else {
                    this.user = null;
                    this.postLogout();
                }
            });
    }

    private _shouldDisplayRecallButton$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    public get isRecallButtonActive(): boolean {
        if (Object.keys(this.settings.recallButton.active).includes(this.authService.accessLevel)) {
            return this.settings.recallButton.active[this.authService.accessLevel];
        }

        return this.settings.recallButton.active['default'];
    }

    public get isSnackbarActive(): boolean {
        if (Object.keys(this.settings.snackbar.active).includes(this.authService.accessLevel)) {
            return this.settings.snackbar.active[this.authService.accessLevel];
        }

        return this.settings.snackbar.active['default'];
    }

    public get shouldDisplayRecallButton$(): Observable<boolean> {
        return this._shouldDisplayRecallButton$.asObservable();
    }

    public recallLastNews(): void {
        this.emitNewsInSnackbar(this.lastBatchOfNews);
    }

    private postAuthentication(): void {
        const news$ = this.getNews$();
        const pageChange$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

        if (this.isSnackbarActive) {
            combineLatest([news$, pageChange$])
                .pipe(
                    debounceTime(1000),
                    takeUntil(this.onPostLogout),
                    map(([news, event]: [NewsInterface[], NavigationEnd]) => this.filterNewsByUrl(news, event)),
                    tap(news => this.lastBatchOfNews = news),
                    map((news: NewsInterface[]) => {
                        if (!!this.settings.snackbar.cacheInLocalStorage) {
                            return this.filterNewsByCache(news);
                        }
                        return news;
                    }),
                    map((news: NewsInterface[]) => this.emitNewsInSnackbar(news))
                )
                .subscribe();
        }

        if (this.settings.recallButton.active) {
            pageChange$.pipe(
                filter(event => event instanceof NavigationEnd),
                map(event => event as NavigationEnd),
                map(event => event.url),
                map(url => {
                    const isInWhiteList = this.settings.recallButton.pathLimitations.accepted.some(regex => regex.test(url));
                    const isInBlackList = this.settings.recallButton.pathLimitations.forbidden.some(regex => regex.test(url));
                    return isInWhiteList && !isInBlackList;
                })
            ).subscribe((shouldDisplayButton) => this._shouldDisplayRecallButton$.next(shouldDisplayButton));
        }
    }

    private postLogout(): void {
        this.onPostLogout.next();
        this.onPostLogout.complete();
        this.onPostLogout = new Subject<void>();

        this.lastBatchOfNews = [];
    }

    private getNews$(): Observable<NewsInterface[]> {
        return this.communicationCenter.getRoom('news').getSubject('isReady').pipe(
            filter(isReady => !!isReady),
            mergeMap(() => this.newsService.getNews$())
        );
    }

    private emitNewsInSnackbar(newsList: NewsInterface[]): void {
        this.addNewsIdsInCache(newsList.map(n => n.id));

        newsList.sort((a, b) => b.weight - a.weight) // trié du plus grand au plus petit
            .slice(0, 1)
            .forEach(news => {
                const data: SnackBarDataInjectedInterface = {
                    dismiss: () => {
                        snackBarRef.dismiss();
                    },
                    isInSnackbar: true,
                }

                const snackBarRef = this.snackBar.openFromComponent(news.component as any, {
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    duration: 15000,
                    data
                });

            });

    }

    private filterNewsByUrl(news: NewsInterface[], event: NavigationEnd): NewsInterface[] {
        return news.filter(n => new RegExp(n.channel.snackbar.acceptedUrlRegex).test(event.url));
    }

    private filterNewsByCache(news: NewsInterface[]): NewsInterface[] {
        const newsInCacheIds = this.getNewsIdsInCache();
        return news.filter(n => !newsInCacheIds.includes(n.id));
    }

    private getNewsIdsInCache(): string[] {
        if (this.user) {
            const cache = this.getLocalStorageCache();
            return cache[this.user.id] || [];
        }
        return [];
    }

    private addNewsIdsInCache(newsIds: string[]): void {
        if (this.user) {
            const cache = this.getLocalStorageCache();
            const alreadyInCache = this.getNewsIdsInCache();
            cache[this.user.id] = [...alreadyInCache, ...newsIds];
            localStorage.setItem(LOCAL_STORAGE_IDENTIFIER, JSON.stringify(cache));
        }
    }

    private getLocalStorageCache(): LocalStorageCacheInterface {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_IDENTIFIER) || '{}') as LocalStorageCacheInterface;
    }
}
