import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {GraphConfig} from 'fuse-core/components/graph/graph-mixed/graph.config';
import {GraphDysappService} from '../../services/graph-dysapp.service';
import {filter, mergeMap, takeUntil, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {rgbOpacity} from 'shared/utils';
import {GraphDysappTranslateService} from '../../services/graph-dysapp-translate.service';
import {TypedDataCollectionInterface} from 'shared/models/octopus-connect/typed-data-collection.interface';
import {ProgressDataAttributeInterface} from '@modules/graph-dysapp/core/model/progress-data-attribute-interface';

const simpleColor = 'rgb(156,255,78)';

@Component({
    selector: 'app-progress-graph',
    templateUrl: './progress-graph.component.html',
})
export class ProgressGraphComponent implements OnInit, OnDestroy {

    public graphConfigObs: ReplaySubject<GraphConfig> = new ReplaySubject<GraphConfig>(1);
    public isReady = false;
    /**
     * Cache le loader si le service n'est pas encore pret
     */
    public showLoader = false;
    private unsubscribeInTakeUntil = new Subject<void>();
    /**
     * Aide a déterminer le temps entre les steps du loader
     * @private
     */
    private loaderShouldEnd = false;

    constructor(private graphDysappService: GraphDysappService, public graphDysappTranslateService: GraphDysappTranslateService) {
    }

    ngOnInit(): void {
        this.graphDysappService.isReady
            .pipe(
                filter(isReady => !!isReady),
                tap(() => this.showLoader = true),
                tap(() => this.graphDysappService.graphDataArePending.subscribe(() => this.isReady = false)),
                mergeMap(() => this.graphDysappService.getProgressGraphData()),
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data) => {
            this.loaderShouldEnd = true;
            this.generateSimpleGraph(data);
            this.isReady = true;
        });

    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private generateSimpleGraph(progressData: TypedDataCollectionInterface<ProgressDataAttributeInterface> ): void {
        const chartLabels: string[] = [];
        const dots: number[] = [];

        progressData.entities.map(e => e.attributes)
            .forEach((dot, index) => {
                chartLabels.push(moment.unix(+dot.timestamp).format('DD/MM/YYYY')); // TotalGood marcherait aussi
                dots.push(dot.progress);
            });

        this.graphConfigObs.next({
            chartLabels,
            chartConfig: {
                aspectRatio: 3,
                scales: {
                    y: {
                        beginAtZero: true, max: 100, min: 0,
                        offset: false
                    },
                    x: {
                        offset: false
                    }
                },
                plugins:{legend: {
                    align: 'start',
                    position: 'bottom',
                    onClick: event => event.native.stopPropagation()
                }}
            },
            chartData: [{
                data: dots,
                type: 'line',
                fill: true,
                label: this.graphDysappTranslateService.get('generic.pourcent_success'),
                backgroundColor: rgbOpacity(simpleColor, '0.3'),
                borderColor: simpleColor,
            }],
            modalContent: undefined,
        });
    }
}
