
import {takeUntil, tap} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import {Router} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {Subject, Observable, BehaviorSubject, ReplaySubject} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';

@Component({
    selector: 'app-options',
    templateUrl: './options.component.html'
})
export class OptionsComponent implements OnInit, OnDestroy {
    @Input('answer') options: any;
    @Input('autoCorrection') public autoCorrection?: boolean;
    @Input('availableAnswers') public availableAnswers?; // todo Add type ItemAnswerInterface[], export from ../../models
    @Input('optionsText') optionText: string;
    @Input('usersSaved') usersSaved: any;
    @Input('mode') mode: string;
    @Input('activityId') activityId: any;
    @Input('contextId') contextId: any;
    @Input('answerStatus') answerStatus: any;
    @Input('isAnswered') isAnswered: any;
    @Input('hideCorrection') hideCorrection: boolean;
    @Input('hideMissing') hideMissing: boolean;
    @Input('disabled') public disabled: boolean;
    @Input('displaySolution') public displaySolution?: boolean;
    @Input('testAnswer') public testAnswer?: boolean;
    @Input('reset') public reset?: ReplaySubject<boolean> = new ReplaySubject(1);

    @Output('onOptionChange') public onOptionChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    public checked: boolean;
    public validated = false;
    public answersChecked = false;
    public showFinalAnswers = false;

    private unsubscribeInTakeUntil = new Subject<void>();
    private userAnswerTakeUntil = new Subject<void>();

    constructor(
        private router: Router,
        private activityService: ActivitiesService,
        public lessonsService: LessonsService
    ) {
        this.activityService.userAnswer.pipe(
            takeUntil(this.userAnswerTakeUntil))
            .subscribe(answers => {
                // Do whatever you want with your
                const finalObj = {
                    showAnswers: true,
                    withoutAnyUserResponse: false,
                    reinitializeOptions: false
                };
                this.activityService.checkAnswers.next(finalObj);

                if (answers) {
                    this.usersSaved = answers;
                    this.checkAnswers();
                }
            });
    }

    ngOnInit(): void {
        this.activityService.doesUserResponsed.next(false);
        this.reset.pipe(
            tap((val: boolean) => {
                if (val === true) { this.resetAllOptions(); }
            })).subscribe();
        this.reset.pipe(
            tap((val: boolean) => {
                if (val === true) { this.resetAllOptions(); }
            })).subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private get getUserAccessCorrection(): boolean {
        return this.lessonsService.isLessonEvaluation() &&
            (this.lessonsService.isAtLeastTrainer() || this.lessonsService.isLessonCorrected());
    }

    public get valueChecked(): string {
        const answerSelected = this.availableAnswers && this.availableAnswers.find(option => option.select);
        return answerSelected && answerSelected.answer || null;
    }

    public set valueChecked(value: string) {
        this.availableAnswers.forEach(item => {
            item.select = (value === item.answer);
        });
    }

    public radioChange(value: string): void {
        this.valueChecked = value;
        this.sendOptionChangeEvent(!!value);
    }

    private sendOptionChangeEvent(value: boolean): void {
        this.onOptionChange.emit(!!value);
        // todo: check if setSaveAnswer is necessary
        this.activityService.doesUserResponsed.next(true);
    }

    public checkAnswers(access = false): any {
        if ((this.lessonsService.isLessonTest() && this.valueChecked) ||
            this.lessonsService.isLessonTraining() || access) {
            this.testAnswer = true;
        } else if (this.lessonsService.isLessonEvaluation()) {
            this.validated = true;
        }

        this.activityService.doesUserResponsed.next(true);
    }

    public saveAnswer(options: any[], status: number, userSave?: DataEntity, step?: number): Observable<DataEntity> {
        const answers = options.filter(item => item.select).map(item => item.id);
        if (!this.testAnswer) {
            this.validated = true;
        }
        return this.activityService.saveUserSave(this.activityId.id.toString(), this.contextId, answers, status, 'qcm-save', userSave, step);
    }

    /* *
    * When user check a single option this will enable TESTER MA REPONSE button,
    * if user Deselect all options it will deactivate the same buttion.
    * */
    public informThatUserInteracted(event): void {
        this.onOptionChange.emit(this.isAnyOtherOptionsChecked(this.availableAnswers));
        this.activityService.doesUserResponsed.next(this.isAnyOtherOptionsChecked(this.availableAnswers));
    }

    /* *
    * Function to check if any option is checked or not.
    * RETURNS: boolean if any number of options are checked.
    * */
    private isAnyOtherOptionsChecked(options: Array<any>): boolean {
        const arrayLength = options.map((item) => (item.select));
        return arrayLength.includes(true);
    }

    public optionState(option: any): string {
        if (this.displaySolution) {
            if (option.correct_answer) {
                return 'correctAnswer';
            }
        } else {
            if (!this.hideCorrection && this.testAnswer) {
                if (option.select) {
                    if (option.correct_answer) {
                        return 'correctAnswer';
                    } else {
                        return 'wrongAnswer';
                    }
                } else {
                    if (option.correct_answer && !this.hideMissing) {
                        return 'answerMissed';
                    }
                }
            }

            if (option.select) {
                if (this.validated) {
                    return 'validated';
                } else {
                    return 'selected';
                }
            }
        }

        return '';
    }

    public resetAllOptions(): void {
        this.testAnswer = false;
        this.displaySolution = false;
        this.validated = false;
        this.hideMissing = !this.getUserAccessCorrection && !this.lessonsService.isLessonTest();
        this.validated = false;
        if (this.mode === 'qcu') {
            this.valueChecked = undefined;
        }
        if (this.mode === 'qcm') {
            this.availableAnswers.forEach(item => {
                item.select = false;
            });
        }

        this.activityService.doesUserResponsed.next(false);
    }
}
