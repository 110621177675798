import {Injectable} from '@angular/core';
import {DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {filter, mergeMap, map, mapTo, take, tap} from 'rxjs/operators';
import {CollectionOptionsInterface} from 'octopus-connect';
import * as _ from 'lodash-es';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MindmapDataEditorModalComponent} from '@modules/mindmap/core/mindmap-data-editor-modal/mindmap-data-editor-modal.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {NavigationExtras, Router} from '@angular/router';
import {MindmapGenericAlertModalComponent} from '@modules/mindmap/core/mindmap-generic-alert-modal/mindmap-generic-alert-modal.component';
import {IMindmapFormData, MindmapRepositoryService} from '@modules/mindmap/core/mindmap-repository.service';
import {MindmapContentViewerModalComponent} from '@modules/mindmap/core/mindmap-content-viewer-modal/mindmap-content-viewer-modal.component';
import {MindmapListComponent, MindmapListDialogData} from '@modules/mindmap/core/mindmap-list/mindmap-list.component';
import {MindmapContentEditorComponent} from '@modules/mindmap/core/mindmap-content-editor/mindmap-content-editor.component';

// Should be a copy of LessonToolDataCommunicationCenterInterface interface in GenericPluginService
export interface MindmapDataCommunicationCenterInterface {
    lesson: DataEntity;
    onComplete: ReplaySubject<DataEntity>;
}

/**
 * List field options to apply on mindmap creation or edition
 *
 * @remarks For now, only the `associatedLessonId` field is used
 */
export interface IMindmapFormOptions {
    [fieldName: string]: { disable: boolean };
}

@Injectable({
    providedIn: 'root'
})
/**
 * Define the mindmap business rules of the application
 */
export class MindmapService {
    /**
     * Obtain the current user or null if not authenticated
     */
    public currentUser$ = new ReplaySubject<DataEntity>(1);
    /**
     * List of unique {@link ReplaySubject} used to identity when an mindmap edition is done.
     * There should never have more than one subject at times, but a subject in this array can be an old one and we need a way to identify if it's the good one.
     * For resolve this problem, we use an object has an hashMap/Key->Value array. The `k` is the unique identifier to a subject
     */
    public onMindmapDataEditionCompleteSubject: { [k: string]: ReplaySubject<DataEntity> } = {};

    constructor(
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private mindmapRepoSvc: MindmapRepositoryService,
        private octopusConnect: OctopusConnectService,
        private router: Router,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject<DataEntity>('userData')
            .pipe(
                filter(currentUser => !!currentUser),
                tap(currentUser => {
                    this.currentUser$.next(currentUser);
                })
            )
            .subscribe();

        /**
         * Used to create or edit a mindmap from everywhere out of the current module
         */
        this.communicationCenter
            .getRoom('mindmap')
            .getSubject('execute')
            .pipe(
                tap((args: MindmapDataCommunicationCenterInterface) =>
                    this.openEmbeddedMindmapListing(args)
                )
            )
            .subscribe();
    }

    /**
     * Obtains the paginated list of current user's notes
     * @param filterOptions
     * @return The {@link DataEntity} are `granules` and the are not of `mindmaps` but `BasicSearch` endpoint
     */
    public getCurrentUserPaginatedMindmaps(filterOptions: CollectionOptionsInterface = {}): Observable<PaginatedCollection> {
        return this.currentUser$.pipe(
            filter(currentUser => !!currentUser),
            map(currentUser => _.merge({
                filter: {
                    author: currentUser.id
                }
            }, filterOptions)),
            mergeMap(options => this.mindmapRepoSvc.getPaginatedMindmaps(options)),
            take(1)
        );
    }

    /**
     * Open and return the mindmap data editor modal.
     *
     * - It's the same way to create a note ({@link goToMindmapDataCreation}) but the save method passed to the modal is used to patch mindmap
     *
     * @param mindmap Used to defined which mindmap to edit and the default form values.
     * Should be a entity given by the `BasicSearch` endpoint or similar because the default values are not in the same path for other endpoints
     */
    public goToMindmapDataEdition(mindmap: DataEntity, embedded: boolean, forceAssociationOnSave: number | string): MatDialogRef<MindmapDataEditorModalComponent, DataEntity | null> {
        const alreadyAssociatedNodes = _.get(
            mindmap.get('reference'),
            'activity_content[0].associated_nodes'
        ) || [];

        return this.goToMindmapDataAndContentEditors({
            saveMindmap: (data) => this.mindmapRepoSvc.updateMindmap(mindmap.id, data),
            defaultValues: {
                title: _.get(mindmap.get('metadatas'), 'title'),
                associatedLessonIds: alreadyAssociatedNodes.map(node => node.id),
            },
            embeddedContentEditor: embedded,
            forceAssociationOnSave
        });
    }

    /**
     * Open and return the mindmap data editor modal.
     *
     * - It's the same way to edit a note ({@link goToMindmapDataCreation}) but the save method passed to the modal is used to create mindmap*
     */
    public goToMindmapDataCreation(defaultValues?: IMindmapFormData, formOptions?: IMindmapFormOptions, embedded?: boolean): MatDialogRef<MindmapDataEditorModalComponent, DataEntity | null> {
        return this.goToMindmapDataAndContentEditors({
            saveMindmap: (data) => this.mindmapRepoSvc.createMindmap(data),
            defaultValues: defaultValues,
            options: formOptions,
            embeddedContentEditor: embedded
        });
    }

    /**
     * Redirect to the list of current user's mindmap
     */
    public goToMindmapList(): Promise<boolean> {
        return this.router.navigate(['mindmap', 'list']);
    }

    /**
     * Ask user if he confirm the mindmap deletion and, if it's ok, delete it
     * @param id of the mindmap granule
     */
    public deleteMindmap(id: number | string): Observable<boolean> {
        const modalData = {data: {contentKey: 'mindmap.ask_before_delete_alert'}};
        return this.dialog.open(MindmapGenericAlertModalComponent, modalData).afterClosed().pipe(
            filter(isConfirm => !!isConfirm),
            mergeMap(() => this.mindmapRepoSvc.destroyMindmap(id))
        );
    }

    /**
     * Display the mindmap content as a read-only modal
     * @param id of the mindmap Granule
     */
    displayMindmap(id: number | string): void {
        this.dialog.open(MindmapContentViewerModalComponent, {data: {mindmap$: this.mindmapRepoSvc.getMindmap(id)}});
    }

    public goToLesson(associatedLessonId: string | number): void {
        this.communicationCenter.getRoom('lessons').next('playLesson', {id: associatedLessonId});
    }

    /**
     * Open the mindmap editor modal and if there are a mindmap given on closed go to mindmap content editor
     * @param data The saveMindmap method receive the form data TODO give an interface of form data here
     */
    private goToMindmapDataAndContentEditors(
        data: {
            saveMindmap: (data) => Observable<DataEntity>;
            defaultValues: IMindmapFormData;
            options?: IMindmapFormOptions,
            embeddedContentEditor?: boolean
            forceAssociationOnSave?: number | string
        }
    ): MatDialogRef<MindmapDataEditorModalComponent, DataEntity | null> {
        const dialogRef = this.dialog.open(MindmapDataEditorModalComponent, {
            data
        });

        dialogRef.afterClosed().pipe(
            // If is mindmapGranule empty is because the modal is closed without want to create/edit the mindmap
            filter(mindmapGranule => !!mindmapGranule),
            tap(mindmapGranule => {
                if (data.embeddedContentEditor) {
                    // If the notepad is embedded, open the notepad content editor modal
                    this.dialog.open(MindmapContentEditorComponent, {
                        data: {mindmapGranule, forceAssociationOnSave: data.forceAssociationOnSave},
                        // todo faire mieux plus tard
                        width: '100vw',
                        maxWidth: '100vw',
                        height: '100vh',
                        maxHeight: '100vh',
                        panelClass: 'mindmap-content-editor-dialog'
                    });
                } else {
                    // If the notepad is not embedded, redirect to the notepad content editor
                    this.goToMindmapContentEditor(mindmapGranule);
                }
            })).subscribe();

        return dialogRef;
    }

    /**
     * Redirect the user to the mindmap content editor page.
     *
     * @param mindmapGranule
     * @param navigationExtras some data to pass by the url, show {@link onMindmapDataEditionCompleteSubject} for example
     */
    private goToMindmapContentEditor(mindmapGranule: DataEntity, navigationExtras?: NavigationExtras): void {
        this.router.navigate(['mindmap', mindmapGranule.id, 'edit'], navigationExtras);
    }

    private openEmbeddedMindmapListing(args: MindmapDataCommunicationCenterInterface): void {
        this.dialog.open<MindmapListComponent, MindmapListDialogData>(MindmapListComponent, {
            data: {forceAssociationOnSave: args.lesson},
            // todo faire mieux plus tard
            width: '100vw',
            maxWidth: '100vw',
            height: '100vh',
            maxHeight: '100vh',
            panelClass: 'mindmap-listing-modal'
        });
    }

    public dissociateLesson(mindmapEntity: DataEntity, associatedLesson: { title: string; id: string | number }) {
        return this.mindmapRepoSvc.getMindmapActivityContent(mindmapEntity.get('reference').activity_content[0].id).pipe(
            take(1),
            mergeMap((mindmapActivityContent) => {
                const lessonIds = mindmapActivityContent.get('associated_nodes')
                    .map(node => node.id)
                    .filter(lessonId => +lessonId !== +associatedLesson.id);
                const distinctLessonIds = _.uniq(lessonIds);
                mindmapActivityContent.set('associated_nodes', distinctLessonIds);
                return mindmapActivityContent.save();
            })
        );
    }
}
