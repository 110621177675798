import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-activity-edition-horizontal-selector',
  templateUrl: './activity-edition-horizontal-selector.component.html',
  styleUrls: ['./activity-edition-horizontal-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityEditionHorizontalSelectorComponent {

    @Input() choices: {[key: string]: string};
    @Input() selected: string;
    @Output() selectedChange = new EventEmitter<string>();

    get choicesArray(): {key: string, value: string}[] {
        return Object.entries(this.choices).map(([key, value]) => ({key, value}));
    }

    // LOGIC

    private select(choice: string): void {
        this.selected = choice;
        this.selectedChange.emit(choice);
    }

    // EVENTS
    public onChoiceClick($event: MouseEvent, choice: string): void {
        $event.preventDefault();
        this.select(choice);
    }
}
