import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import {Subject, Subscription, Observable, ReplaySubject} from 'rxjs';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {CollectionOptionsInterface, DataEntity} from 'octopus-connect';
import {AssignationService} from '@modules/assignation';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {AssignationConfigurationService} from "@modules/assignation/core/services/assignation-configuration.service";

interface Lesson {
    lesson?: DataEntity;
    activities?: DataEntity[];
}

@Component({
    selector: 'app-assignation-groups-detail',
    templateUrl: './assignation-groups-detail.component.html',
    styleUrls: ['./assignation-groups-detail.component.scss']
})
export class AssignationGroupsDetailComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input('isAssignationClosedActive') isAssignationClosedActive = false;
    private optionsInterface: CollectionOptionsInterface;
    private resourcesSubscription: Subscription;
    private unsubscribeInTakeUntil = new Subject<void>();
    public resources = [];
    public lessons: { [key: string]: Lesson } = {};
    public lessonsList: any;


    @ViewChildren('assignationsElement') assignationsElement: QueryList<ElementRef>;

    constructor(public assignationService: AssignationService,
                private authService: AuthenticationService,
                private communicationCenter: CommunicationCenterService,
                private cdr: ChangeDetectorRef,
                private config: AssignationConfigurationService,
    ) {
    }

    ngOnInit(): void {
        this.optionsInterface = {
            filter: {assignated_user: this.authService.userData.id},
            page: 1,
            range: 10,
        };

        this.launchSearch();
    }

    public launchSearch(optionInterface?: CollectionOptionsInterface): void {
        if (this.resourcesSubscription) {
            this.resourcesSubscription.unsubscribe();
        }
        if (optionInterface) {
            this.optionsInterface = optionInterface;
        }

        this.resourcesSubscription = this.refreshList();
    }

    ngAfterViewInit() {
        // Vérifie si chaque div est affichée
        this.assignationsElement.forEach((divRef: ElementRef) => {
            if (divRef.nativeElement.offsetParent !== null) {
                // console.log('La div est affichée à l\'écran');
            } else {
                // console.log('La div n\'est pas affichée à l\'écran');
            }
        });
    }

    /**
     * Main method to refresh the list of lessons and assignments.
     * It resets the list, fetches the assignments, and processes them.
     */
    private refreshList(): Subscription {
        this.resetList();
        this.resourcesSubscription = this.fetchAndProcessAssignments();
        return this.resourcesSubscription;
    }

    /**
     * Resets the lessons list and the assignments list.
     */
    private resetList(): void {
        this.optionsInterface.filter['date_before'] = Math.floor(Date.now() / 1000);
        this.optionsInterface.filter['removeLearnerAutoAssignment'] = true;
        this.lessonsList = {};
        this.lessons = {};
    }

    /**
     * Fetches the assignments from the service and processes them.
     * It also updates the resources property and the assignations in the service.
     */
    private fetchAndProcessAssignments(): Subscription {
        return this.assignationService.loadPaginatedAssignments(this.optionsInterface)
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((resources) => {
                if (!resources) {
                    return;
                }
                this.resources = resources;
                this.assignationService.assignations = resources;
                this.processAssignations(resources);
            });
    }

    /**
     * Process the assignations fetched from the service.
     * It extracts the lessons from the assignations and processes each lesson.
     */
    private processAssignations(assignations: DataEntity[]): void {
        const lessons = this.extractLessonsFromAssignations(assignations);
        this.getLessonByIds(lessons).subscribe((lessons) => {
            lessons.forEach((lesson: DataEntity) => {
                this.processLesson(lesson);
            });
        });
    }

    /**
     * Extracts the lessons from the assignations.
     * It reads the config from each assignation, assigns activities to the assignation,
     * and returns an array of unique questionSets.
     */
    private extractLessonsFromAssignations(assignations): string[] {
        return assignations.reduce((lessons, assignation, index) => {
            const config = JSON.parse(assignation.get('config'));
            if (config) {
                const questionSet = Object.keys(config)[0];
                assignation.assignedActivities = config[questionSet];
                if (!lessons.includes(questionSet)) {
                    lessons.push(questionSet);
                }
            }
            return lessons;
        }, []);
    }

    /**
     * Processes the given lesson.
     * It fetches activities for the lesson and updates the lessons list and lessons.
     */
    private processLesson(lesson: DataEntity): void {
        this.getActivities(lesson).subscribe((activities: DataEntity[]) => {
            this.updateLessonList(lesson, activities);
            this.updateLessons();
            this.cdr.detectChanges();
        });
    }

    /**
     * Updates the lessons list with the given lesson and activities.
     */
    private updateLessonList(lesson: DataEntity, activities: DataEntity[]): void {
        if (!this.lessonsList[lesson.id]) {
            this.lessonsList[lesson.id] = {};
        }
        this.lessonsList[lesson.id].lesson = lesson;
        this.lessonsList[lesson.id].activities = activities;
    }

    /**
     * Updates the lessons property based on the lessons list and the assignations.
     * It reads the config from each assignation and maps the assignation to a lesson and its activities.
     */
    private updateLessons(): void {
        this.assignationService.assignations.forEach((assignation) => {
            const config = JSON.parse(assignation.get('config'));
            if (config) {
                const questionSet = Object.keys(config)[0];
                if (!this.lessons[assignation.id]) {
                    this.lessons[assignation.id] = {};
                }
                this.lessons[assignation.id] = {lesson: this.lessonsList[questionSet]?.lesson, activities: this.lessonsList[questionSet]?.activities};
            }
        });
    }

    private getLessonByIds(ids: string[] | number[]): Observable<DataEntity[]> {
        const lesson$ = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter
            .getRoom('lessons')
            .next('getLessons', {
                lessonIds: ids,
                callbackSubject: lesson$
            });
        return lesson$.pipe(
            mergeMap(obs => obs)
        );
    }

    private getActivities(lesson: DataEntity): Observable<DataEntity[]> {
        const lesson$ = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter
            .getRoom('lessons')
            .next('getActivities', {
                lesson: lesson,
                callbackSubject: lesson$
            });
        return lesson$.pipe(
            mergeMap(obs => obs)
        );
    }

    public identify(index: number, item: DataEntity) {
        return item.id;
    }

    public get rolesCanShowBannerInfo(): string[] {
        return this.config.rolesCanShowBannerInfo();
    }

    public get rolesCanShowBannerInfoClosedAssignment(): string[] {
        return this.config.rolesCanShowBannerInfoClosedAssignment();
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }
}
