
import {from as observableFrom, combineLatest as observableCombineLatest, Observable, Subject} from 'rxjs';

import {takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CollectionOptionsInterface} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {HttpClient} from '@angular/common/http';
import {AccountManagementProviderService} from '@modules/account-management';
import {defaultApiURL} from 'app/settings';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import {
    DOMAIN_MESSAGING, EVENT_CORE_LABELS, EVENT_THREAD_LIST_NEXT, EVENT_THREAD_EDIT_LAST_VIEWED,
    EVENT_THREAD_LIST, EVENT_THREAD_COUNT, EVENT_THREAD_LIST_NEXT_HREF,
    EVENT_MESSAGE_LIST_NEXT, EVENT_MESSAGE_LIST, EVENT_MESSAGE_LIST_NEXT_HREF,
    EVENT_THREAD_NEW, EVENT_THREAD_EDIT, EVENT_MESSAGE_NEW, EVENT_THREAD_DELETE,
    EVENT_MESSAGE_DELETE, EVENT_THREAD_UPDATE, ALL_EVENTS, EVENT_THREAD_ACTIVATED
} from './messaging.events';
import {isArray, isNumber, isString} from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class MessagingService {

    private _translatedLabels = [
        'generic.all',
        'generic.close',
        'generic.open',
        'generic.delete',
        'generic.cancel',
        'generic.register',
        'generic.send',
        'generic.edit',
        'messaging.participants',
        'messaging.start_new_thread_with_teacher',
        'messaging.subject',
        'messaging.message',
        'messaging.add_new_thread',
        'messaging.about_thread',
        'messaging.edit_thread',
        'messaging.title',
        'messaging.start_thread_hint',
        'messaging.tape_new_message',
        'messaging.me',
        'messaging.the',
        'messaging.at',
        'messaging.confirm_delete_text',
    ];
    private _userFieldsLabels = [
        'label', 'roles', 'email', 'you_are', 'type', 'contact_email',
        'find_us', 'email_status', 'newsletter', 'sso', 'sso_token',
        'picture', 'access', 'groups'];

    private _dataStore: {
        user: any,
        userTeacher: string,
        lastFilterApplied: any,
        labels: any,
        groups: any[]
    };
    private unsubscribeInTakeUntil = new Subject<void>();

    activeThread: any;

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        private accountManagementProvider: AccountManagementProviderService,
        private communicationCenter: CommunicationCenterService,
        private authService: AuthenticationService,
    ) {
        this._dataStore = {
            user: undefined,
            userTeacher: undefined,
            lastFilterApplied: undefined,
            labels: {},
            groups: []
        };

        this.consumeEvent('authentication', 'userData', (data) => {
            if (data) {
                this._dataStore.user = this.entityMapper(data, this._userFieldsLabels);
                if (this.isTeacher === false) {
                    this.fetchEntity('groups', String(this._dataStore.user.groups[0]))
                        .subscribe(
                            (fetchedGroup: any) => this._dataStore.userTeacher = fetchedGroup.data[0].uid
                        );
                }
            }
            this.prepareTranslationKiesMap();
        }, false);

        this.consumeEvent('groups-management', 'groupsList', (data) => this._dataStore.groups = data, false);

        this.translate.onDefaultLangChange
            .subscribe((event: LangChangeEvent) => this.prepareTranslationKiesMap());
        this.translate.onLangChange
            .subscribe((event: LangChangeEvent) => this.prepareTranslationKiesMap());

        this.consumeEvent('authentication', 'userData', (data) => {
            if (!data) {
                this.resetEventBus();
            } else {
                this.loadThreads();
            }
        }, false);

        this.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_ACTIVATED, (thread) => {
            this.loadMessages(thread.id);
            this.setLastMsgViewedInStorage(thread);
        }, false);
    }

    /**
     *
     */
    prepareTranslationKiesMap(): void {
        setTimeout(() => {
            const _listOfObs = [];
            this._translatedLabels.forEach(label => _listOfObs.push(this.translate.get(label)));
            observableCombineLatest(_listOfObs).subscribe((_labelsResult) => {
                let ctr = 0;
                _labelsResult.forEach(lbl => this._dataStore.labels[this._translatedLabels[ctr++]] = lbl);
                this.publishEvent(DOMAIN_MESSAGING, EVENT_CORE_LABELS, this._dataStore.labels);
            });
        }, 20);
    }

    /**
     *
     */
    get user(): any {
        return this._dataStore.user;
    }

    /**
     * return `true` when the connected user is an admin || trainer
     */
    get isTeacher(): boolean {
        return this.authService.isAdministrator() || this.authService.isTrainer();
    }

    /**
     *
     */
    loadPaginatedThreads(optionsInterface: CollectionOptionsInterface): Observable<Object[]> {
        throw new Error('Method not implemented.');
    }

    /**
     *
     */
    isAuthor(thread: any): boolean {
        return String(this._dataStore.user.id) === String(thread.author);
    }

    /**
     *
     * @param userId
     */
    getUser(userId: string): Observable<string> {
        return this._transactionWrapper((resolve, reject) => {
            this.fetchEntity('users', userId)
                .subscribe(
                    (data: any) => resolve(data.data[0]['label']),
                    e => resolve('')
                );
        });
    }

    /**
     *
     * @param threadId
     * @param timestamp
     */
    updateLastViewedMessageByUser(threadId: string, timestamp: string): Observable<any> {
        return this._transactionWrapper((resolve, reject) => {
            this.updateEntity('threads-view', threadId, {'lastViewed': timestamp})
                .subscribe(
                    (updatedEntity: any) => {
                        this.publishEvent(DOMAIN_MESSAGING, EVENT_THREAD_EDIT_LAST_VIEWED, {
                            id: threadId,
                            lastViewed: updatedEntity.data.lastViewed
                        });
                        resolve(updatedEntity.data);
                    },
                    e => reject(e)
                );
        });
    }

    /**
     * Load list of threads with filter when provided
     * @param filter
     */
    loadThreads(filter?: any, useNextPageUrl?: string): Observable<any> {
        return this._transactionWrapper((resolve, reject) => {
            let _url = defaultApiURL;
            if (useNextPageUrl) {
                _url = useNextPageUrl;
            } else {
                this._dataStore.lastFilterApplied = Object.assign({}, filter);
                let qparam = '';
                if (filter) {
                    if (isArray(filter.members) && filter.members.length > 0) {
                        qparam += '?filter[members]=' + filter.members.join(',');
                    }
                    if (isNumber(filter.group)) {
                        qparam += (qparam === '' ? '?' : '&') + 'filter[group]=' + filter.group;
                    }
                    if (isString(filter.text) && filter.text.length >= 3) {
                        qparam += (qparam === '' ? '?' : '&') + 'filter[label]=' + filter.text;
                    }
                    if (typeof filter.archived === 'boolean') {
                        qparam += (qparam === '' ? '?' : '&') + 'filter[archived]=' + (filter.archived ? '0' : '1');
                    }
                }
                _url += 'api/threads' + qparam;
            }

            this.http
                .get<any>(_url, {headers: {'access-token': this.accountManagementProvider.userAccessToken}})
                .subscribe(
                    data => {
                        this.publishEvent(DOMAIN_MESSAGING, useNextPageUrl ? EVENT_THREAD_LIST_NEXT : EVENT_THREAD_LIST, {data: data.data, callback: this.loadThreads.bind(this)});
                        this.publishEvent(DOMAIN_MESSAGING, EVENT_THREAD_COUNT, data.count);
                        if (data.next && data.next.href) {
                            this.publishEvent(DOMAIN_MESSAGING, EVENT_THREAD_LIST_NEXT_HREF, data.next.href);
                        } else {
                            this.publishEvent(DOMAIN_MESSAGING, EVENT_THREAD_LIST_NEXT_HREF, undefined);
                        }
                        resolve(true);
                    },
                    e => {
                        reject(e);
                    }
                );
        });
    }

    /**
     *
     * @param threadId
     * @param useNextPageUrl
     */
    loadMessages(threadId: number, useNextPageUrl?: string): Observable<any> {
        const _url = useNextPageUrl ? useNextPageUrl : defaultApiURL + 'api/messages?filter[thread]=' + threadId;
        return this._transactionWrapper((resolve, reject) => {
            this.http
                .get<any>(_url, {headers: {'access-token': this.accountManagementProvider.userAccessToken}})
                .subscribe(
                    data => {
                        if (isArray(data.data)) {
                            this.publishEvent(DOMAIN_MESSAGING, useNextPageUrl ? EVENT_MESSAGE_LIST_NEXT : EVENT_MESSAGE_LIST, data.data);
                            if (data.next && data.next.href) {
                                this.publishEvent(DOMAIN_MESSAGING, EVENT_MESSAGE_LIST_NEXT_HREF, data.next.href);
                            } else {
                                this.publishEvent(DOMAIN_MESSAGING, EVENT_MESSAGE_LIST_NEXT_HREF, undefined);
                            }
                        }
                        resolve(true);
                    },
                    e => {
                        reject(e);
                    }
                );
        });
    }

    /**
     *
     * @param newThread
     * @param newMessage
     */
    newThread(newThread: any, newMessage: any): Observable<any> {
        return this._transactionWrapper((resolve, reject) => {
            newThread.members = this.computeMembersList(newThread.members, true);
            this.addEntity('threads', newThread)
                .subscribe(
                    (newThreadData: any) => {
                        if (typeof newMessage.message === 'string' && newMessage.message.length > 0) { // no message to add
                            newMessage['thread'] = newThreadData.data[0].id;
                            this.addEntity('messages', newMessage)
                                .subscribe(
                                    (newMessageThread: any) => {
                                        this.fetchEntity('threads', String(newThreadData.data[0].id))
                                            .subscribe(
                                                (fetchedThread: any) => {
                                                    this.publishEvent(DOMAIN_MESSAGING, EVENT_THREAD_NEW, fetchedThread.data[0]);
                                                    resolve(true);
                                                },
                                                e3 => reject(e3)
                                            );
                                    },
                                    e2 => {
                                        this.deleteEntity('threads', newThreadData.data[0].id);
                                        reject(false);
                                    }
                                );
                        } else {
                            this.publishEvent(DOMAIN_MESSAGING, EVENT_THREAD_NEW, newThreadData.data[0]);
                            resolve(true);
                        }
                    },
                    e => reject(e)
                );
        });
    }

    /**
     *
     * @param threadId
     * @param newThread
     */
    updateThread(threadId: number, newThread: any): Observable<any> {
        return this._transactionWrapper((resolve, reject) => {
            this.fetchEntity('threads', String(threadId))
                .subscribe(
                    (fetchedThread: any) => {
                        let _allowUpdate = false;
                        const _computeMembersList = this.computeMembersList(newThread.members, false);
                        if (isArray(_computeMembersList) && _computeMembersList.length >= 2) {
                            fetchedThread.data[0].members = _computeMembersList;
                            _allowUpdate = true;
                        }
                        if (isString(newThread.label) && String(newThread.label).length > 0) {
                            fetchedThread.data[0].label = newThread.label;
                            _allowUpdate = true;
                        }
                        if (_allowUpdate) {
                            this.updateEntity('threads', String(fetchedThread.data[0].id), fetchedThread.data[0])
                                .subscribe(
                                    (savedThread: any) => {
                                        this.publishEvent(DOMAIN_MESSAGING, EVENT_THREAD_EDIT, savedThread.data[0]);
                                        resolve(true);
                                    },
                                    e2 => reject(e2)
                                );
                        } else {
                            reject('NOP');
                        }
                    },
                    e1 => reject(e1)
                );
        });
    }

    /**
     *
     * @param newMessage
     * @param threadId
     */
    newMessage(newMessage: string, threadId: number): Observable<any> {
        return this._transactionWrapper((resolve, reject) => {
            this.addEntity('messages', {
                'message': newMessage,
                'thread': threadId
            }).subscribe((savedMessage: any) => {
                this.publishEvent(DOMAIN_MESSAGING, EVENT_MESSAGE_NEW, savedMessage.data[0]);
                resolve(true);
            }, (error: any) => {
                reject(error);
            });
        });
    }

    /**
     *
     * @param message
     */
    isUserMessageAuthor(message: any): boolean {
        return !!message.author && String(this._dataStore.user.id) === String(message.author.id);
    }

    /**
     *
     * @param threadId
     */
    deleteThread(threadId: number): Observable<any> {
        return this._transactionWrapper((resolve, reject) => {
            this.fetchEntity('threads', String(threadId))
                .subscribe(
                    (fetchedThread: any) => {
                        if (this.isTeacher || this.isAuthor(fetchedThread.data[0])) {
                            this.deleteEntity('threads', String(threadId))
                                .subscribe(
                                    (deletedThread: any) => {
                                        this.publishEvent(DOMAIN_MESSAGING, EVENT_THREAD_DELETE, threadId);
                                        resolve(true);
                                    },
                                    e2 => reject(e2)
                                );
                        } else {
                            reject(false);
                        }
                    },
                    e1 => reject(e1)
                );
        });
    }

    /**
     *
     * @param messageId
     */
    deleteMessage(messageId: any): Observable<any> {
        return this._transactionWrapper((resolve, reject) => {
            this.deleteEntity('messages', messageId)
                .subscribe(
                    (deletedMessage: any) => {
                        this.loadMessages(this.activeThread.id);
                        this.publishEvent(DOMAIN_MESSAGING, EVENT_MESSAGE_DELETE, messageId);
                        resolve(true);
                    },
                    e => reject(e)
                );
        });
    }

    /**
     *
     * @param thread
     */
    isArchived(thread: any): boolean {
        return thread.archived === true;
    }

    /**
     *
     * @param threadId
     */
    archiveUnarchiveThread(threadId: number, archive: boolean): Observable<any> {
        return this._transactionWrapper((resolve, reject) => {
            this.fetchEntity('threads', String(threadId))
                .subscribe(
                    (fetchedThread: any) => {
                        if (fetchedThread.data[0].archived !== archive) {
                            fetchedThread.data[0].archived = archive;
                            this.updateEntity('threads', String(threadId), fetchedThread.data[0])
                                .subscribe(
                                    (savedThread: any) => {
                                        this.publishEvent(DOMAIN_MESSAGING, EVENT_THREAD_UPDATE, savedThread.data[0]);
                                        resolve(true);
                                    },
                                    e2 => reject(e2)
                                );
                        }
                    },
                    e1 => reject(e1)
                );
        });
    }

    /**
     *
     */
    private _transactionWrapper(transaction: Function): Observable<any> {
        return observableFrom(
            new Promise((resolve, reject) => {
                try {
                    transaction(resolve, reject);
                } catch (e) {
                    reject(e);
                }
            })
        );
    }

    /**
     *
     * @param address
     * @param action
     * @param data
     */
    publishEvent(address: string, action: string, data: any): void {
        this.communicationCenter
            .getRoom(address)
            .getSubject(action)
            .next(data);
    }

    /**
     *
     * @param address
     * @param action
     * @param handler
     * @param unsubscribe
     */
    consumeEvent(address: string, action: string, handler: Function, unsubscribe = true): void {
        let obs = <Observable<any>>(this.communicationCenter
            .getRoom(address)
            .getSubject(action));

        if (unsubscribe) {
            obs = obs.pipe(takeUntil(this.unsubscribeInTakeUntil));
        }

        obs.subscribe(data => handler(data));
    }

    /**
     * remove a subject to reset data and avoid auto push event
     * when using getRoom because of existing data
     * @param adress :  adress for communication center getRoom
     * @param action : action for communication center removeSubject
     */
    public removeSubject(adress: string, action: string): void {
        this.communicationCenter
            .getRoom(adress)
            .removeSubject(action);
    }

    /**
     *
     * @param entity
     * @param fieldsLabels
     */
    entityMapper(entity: DataEntity, fieldsLabels: string[]): any {
        const dto = {};
        dto['id'] = entity.id;
        fieldsLabels.forEach(label => {
            dto[label] = entity.get(label);
        });
        return dto;
    }

    /**
     *
     * @param thread
     */
    isLastMessageHasBeenViewedByUser(thread: any): boolean {
        return thread && +thread['lastViewed'] >= +thread['lastMessage'];
    }

    /**
     *
     * @param thread
     */
    setLastMsgViewedInStorage(thread: any): void {
        localStorage.setItem('iboost:messaging:thread:lastmsg:' + thread.id, thread.lastMessage);
    }

    /**
     *
     * @param members
     */
    computeMembersList(members: any[], newOp: boolean): any[] {
        if (!isArray(members)) {
            members = [];
        }
        members = members.filter(mem => String(mem) !== String(this.user.id));
        if (newOp === true) {
            return members;
        } else {
            if (this.isTeacher) {
                members.push(String(this.user.id));
            } else {
                members.push(String(this.user.id));
                members.push(this._dataStore.userTeacher);
            }
        }
        return this.removeDuplicates(members);
    }

    /**
     *
     * @param resourceEndpoint
     * @param resourceId
     */
    fetchEntity(resourceEndpoint: string, resourceId: string): Observable<any> {
        return this.http
            .get(
                defaultApiURL + 'api/' + resourceEndpoint + (resourceId ? '/' + resourceId : ''),
                {headers: {'access-token': this.accountManagementProvider.userAccessToken}}
            );
    }

    /**
     *
     * @param resourceEndpoint
     * @param data
     */
    addEntity(resourceEndpoint: string, data: any): Observable<any> {
        return this.http
            .post(
                defaultApiURL + 'api/' + resourceEndpoint, data,
                {headers: {'access-token': this.accountManagementProvider.userAccessToken}}
            );
    }

    /**
     *
     * @param resourceEndpoint
     * @param data
     */
    updateEntity(resourceEndpoint: string, resourceId: string, data: any): Observable<any> {
        return this.http
            .patch(
                defaultApiURL + 'api/' + resourceEndpoint + '/' + resourceId, data,
                {headers: {'access-token': this.accountManagementProvider.userAccessToken}}
            );
    }

    /**
     *
     * @param resourceEndpoint
     * @param resourceId
     */
    deleteEntity(resourceEndpoint: string, resourceId: string): Observable<any> {
        return this.http
            .delete(
                defaultApiURL + 'api/' + resourceEndpoint + '/' + resourceId,
                {headers: {'access-token': this.accountManagementProvider.userAccessToken}}
            );
    }

    /**
     *
     */
    resetEventBus(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
        this.unsubscribeInTakeUntil = new Subject<void>();
    }

    /**
     *
     * @param ids
     */
    removeDuplicates(ids: any[]): any[] {
        const unique = {};
        ids.forEach((i: any) => {
            if (!unique[String(i)]) {
                unique[String(i)] = true;
            }
        });
        return Object.keys(unique);
    }
}
