import {Injectable} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {Observable, ReplaySubject} from 'rxjs';
import {NewsInterface} from 'fuse-core/news/news.interface';
import * as _ from 'lodash-es';

const DEFAULT_NEWS: NewsInterface = {
    channel: {
        snackbar: {
            acceptedUrlRegex: /.*/
        }
    },
    component: undefined,
    id: 'DEFAULT_NEWS',
    weight: 999
};


@Injectable({
    providedIn: 'root'
})
export class NewsService {

    protected newsList: NewsInterface[] = [];
    protected newsList$: ReplaySubject<NewsInterface[]> = new ReplaySubject<NewsInterface[]>(1);

    constructor(protected communicationCenter: CommunicationCenterService) {
        this.communicationCenter.getRoom('news').getSubject('add').subscribe((news: NewsInterface[]) => this.addNews(news));
        this.communicationCenter.getRoom('news').getSubject('delete').subscribe((news: NewsInterface[]) => this.deleteNews(news));

        this.emit();
        this.communicationCenter.getRoom('news').next('isReady', true);
    }

    public getNews$(): Observable<NewsInterface[]> {
        return this.newsList$.asObservable();
    }

    protected addNews(newsPotentiallyList: NewsInterface[] | NewsInterface): void {
        const newsList: NewsInterface[] =
            Array.isArray(newsPotentiallyList) ?
                newsPotentiallyList.slice() :
                [newsPotentiallyList];

        const secureNewsList = newsList.map(n => this.secureNewsByDefaultValues(n));
        this.newsList.push(...secureNewsList);
        this.emit();
    }

    protected deleteNews(toDeleteNewsPotentiallyList: NewsInterface[] | NewsInterface): void {
        const toDeleteNewsList: NewsInterface[] =
            Array.isArray(toDeleteNewsPotentiallyList) ?
                toDeleteNewsPotentiallyList.slice() :
                [toDeleteNewsPotentiallyList];

        this.newsList = this.newsList.filter(actualNews => toDeleteNewsList
            .map(toDeleteNews => toDeleteNews.id)
            .includes(actualNews.id) === false);
        this.emit();
    }

    protected emit(): void {
        this.newsList$.next(this.newsList.slice());
    }

    private secureNewsByDefaultValues(news: NewsInterface): NewsInterface {
        return _.merge({}, DEFAULT_NEWS, news);
    }
}
