import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {
    DataCollection,
    DataEntity,
    OctopusConnectService,
} from 'octopus-connect';
import {map, mergeMap, take, tap} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';

@Injectable({
    providedIn: 'root'
})

/**
 * service create to separate and extract save logic from activities service
 * I'haven't found using program how to fired createUserSave method perhaps is it not used.
 */
export class SaveService {
    public saving = false; // TODO manage save with finally action in caller in futur refacto
    public userAnswerOnChange = new Subject<void>(); // TODO is it really necessary?

    constructor(private octopusConnect: OctopusConnectService, private communicationCenter: CommunicationCenterService) {
        this.communicationCenter.getRoom('userSave')
            .getSubject('loadUserSave')
            .pipe(
                tap(({filterOptions, onComplete}: { filterOptions: {granule: string}, onComplete: ReplaySubject<Observable<DataEntity[]>> }) =>
                    onComplete.next(this.octopusConnect.loadCollection('user-save', filterOptions).pipe(
                        take(1),
                        map((collection: DataCollection) => collection.entities)
                    ))
                )).subscribe();
    }

    /**
     * note TODO in app a lot of loadUserSave exist make a turn off all to see if they can use this one or a common one
     * load the user save
     * @param currentAssignmentID current assignment
     * @param presentArrayElementIndex current index
     * @param granuleId granuleId
     * @param contextId contextId
     * @param getAllUserSave getAllUserSave => if activity summary, we need all usersave for polls
     * @param step  step
     * @param lessonsAnswers
     * @returns  Observable <DataEntity> || any[]
     */
    public loadUserSave(currentAssignmentID: any, presentArrayElementIndex: number,
                        granuleId: string | number, contextId?: string, getAllUserSave = null, step = null,
                        lessonsAnswers = {}): any {
        const filters = {granule: granuleId};
        if (!contextId && currentAssignmentID) {
            contextId = currentAssignmentID;
        }
        if (contextId) {
            filters['context'] = contextId;
        }
        if (!filters['context']) {
            const observable = new ReplaySubject<DataEntity>();
            observable.next(lessonsAnswers[step || step === 0 ? filters['granule'] + '-' + step.toString() : filters['granule'] + '-' + presentArrayElementIndex.toString()]);
            return observable;
        }

        return this.loadUserSaveFiltered(filters, getAllUserSave, presentArrayElementIndex);
    }

    /**
     * load the user save with some filter to apply
     * @param filters filter
     * @param getAllUserSave all user save
     * @param presentArrayElementIndex index of array
     * @private
     */
    private loadUserSaveFiltered(filters: { granule: string | number }, getAllUserSave, presentArrayElementIndex: number): any {
        return this.octopusConnect.loadCollection('user-save', filters).pipe(
            take(1),
            map((collection: DataCollection) => {
                if (collection.entities.every((usersave) => usersave.get('step') && usersave.get('step') !== '')) {
                    // there is two save for the same question the diff will be by step value

                    if (getAllUserSave) {
                        return collection.entities;
                    }
                    return collection.entities.filter((entity) => +entity.get('step') === presentArrayElementIndex)[0];
                } else {
                    return collection.entities.sort((a, b) => a.id > b.id ? -1 : 1)[0];
                }
            }));
    }

    /**
     * save an answer : local shortanswer will be changed be careful
     * @param currentAssignmentID currentAssingment
     * @param localShortAnswers string[] of short answers
     * @param entity dataentity
     * @param type type endpoint name where we save
     */
    public saveAnswer(currentAssignmentID: any, localShortAnswers: string[], entity, type = null): any {
        if (!currentAssignmentID) {
            const labelAnswer = entity.answer;
            const observable = new ReplaySubject<DataEntity>();
            entity = new DataEntity(type, {
                answer: labelAnswer
            }, null, localShortAnswers.length);
            localShortAnswers[entity.id] = labelAnswer;
            observable.next(entity);
            return observable;
        } else if (entity.id) {
            entity.save();
        } else if (type && entity.answer !== undefined) {
            return this.octopusConnect.createEntity(type, entity);
        } else {
            const observable = new ReplaySubject(1);
            observable.next(null);
            return observable;
        }
        return entity;
    }

    /**
     * createUserSaveIfContextIdAndNoSave
     * @param save data entity
     * @param type endpoint
     * @param selectedAnswers answers selected
     * @param state current state
     * @private
     */
    // eslint-disable-next-line max-len
    private createUserSaveIfContextIdAndNoSave(save: DataEntity, type: string, selectedAnswers: Array<any> | string, state: string): Observable<DataEntity> {
        const entitySaveData = save.get('userActivity').entitySave;
        const entitySave = new DataEntity(type, entitySaveData, this.octopusConnect);
        const observable = new ReplaySubject<DataEntity>(1);

        // for genericsave endpoint the name of field to save is content for the other it's answers
        if (type === 'genericsave') {
            entitySave.set('content', selectedAnswers);
        } else {
            entitySave.set('answers', selectedAnswers);
        }

        // entitySave.set('answers', selectedAnswers);
        entitySave.save().pipe(
            take(1))
            .subscribe((saveData) => {
                save.set('state', state);
                // force update change prop if usersave content edited
                save.set('changed', Math.round(new Date().getTime() / 1000));
                save.save(true).pipe(
                    take(1))
                    .subscribe((userSave) => {
                        (<ReplaySubject<DataEntity>>observable).next(userSave);
                    }, (error) => {
                        (<ReplaySubject<DataEntity>>observable).error(error);
                    });
            });
        return observable;
    }

    /**
     * createUserSaveIfNoContextId
     * @param selectedAnswers : selected answers
     * @param type endpoint
     * @param localShortAnswers short answers
     * @param activityId id of activity
     * @param state current state
     * @param step current step
     * @param presentArrayElementIndex index
     * @param currentLesson current lesson
     * @param lessonsAnswers answers of lessons
     * @private
     */
    // eslint-disable-next-line max-len
    private createUserSaveIfNoContextId(selectedAnswers: Array<any> | string, type: string, localShortAnswers: string[], activityId: string, state: string, step: number, presentArrayElementIndex: any, currentLesson: any, lessonsAnswers: any): Observable<DataEntity> {
        const observable = new ReplaySubject<DataEntity>(1);
        const answers = <any>selectedAnswers;
        const filter = ['rb-save', 'app-save'];
        if (!filter.includes(type)) {
            for (let i = 0; i < answers.length; i++) {
                if (!answers[i]) {
                    continue;
                }
                const _answer = localShortAnswers[answers[i]] ? localShortAnswers[answers[i]] : answers[i].answer || answers[i];
                answers[i] = {id: answers[i].id || answers[i], answer: _answer};
            }
        }

        const entity = new DataEntity(type, {
            granule: activityId,
            context: null,
            state: state,
            step: step !== null ? step : presentArrayElementIndex,
            userActivity: {
                entitySave: {
                    answers: answers,
                }
            }
        });
        if (currentLesson) {
            entity.set('lesson', currentLesson.id);
        }
        lessonsAnswers[step || step === 0 ? activityId + '-' + step.toString() : activityId + '-' + presentArrayElementIndex.toString()] = entity;
        (<ReplaySubject<DataEntity>>observable).next(entity);
        return observable;
    }

    /**
     * return state in regard of status
     * @param status  validated incomplete correct etc.
     * @private
     */
    private getStateInRegardOfStatus(status: number): string {
        let state: string;
        switch (status) {
            case 1:
                state = 'validated';
                break;
            case 2:
                state = 'incomplete';
                break;
            case 3:
                state = 'correct';
                break;
            default:
                state = 'closed';
                break;
        }
        return state;
    }

    /**
     * create the user save prepare by save usersave method
     *@param currentAssignmentID current assignment
     * @param presentArrayElementIndex current index
     * @param currentLesson current lesson
     * @param activityId : id of current activity
     * @param contextId : id of assignation or lessonId(not sure for lessonId...)
     * @param type : type of save 'qcm-save' etc... it's equal to the endpoint name genericSAve etc..
     * @param step : index og activity in current lesson
     * @param answers : answers list of answers
     * @param step : step current step
     */
    // eslint-disable-next-line max-len
    private createUserSave(currentAssignmentID: any, presentArrayElementIndex: number, currentLesson: any, activityId: string, contextId: string, answers: string[] | string, state: string, type: string, step: number = null): Observable<DataEntity> {
        const observable = new Subject<DataEntity>();

        if (!contextId && currentAssignmentID) {
            contextId = currentAssignmentID;
        }
        // for genericsave endpoint the name of field to save is content for the other it's answers

        let data: any;
        if (type === 'genericsave') {
            data = {'content': answers};
        } else {
            data = {answers: answers};
        }

        this.octopusConnect.createEntity(type, data).pipe(take(1),
            mergeMap((save: DataEntity) => {
                return this.octopusConnect.createEntity('user-activity', {entitySave: save.id}).pipe(take(1),
                    mergeMap((userActivity: DataEntity) => {
                        return this.octopusConnect.createEntity('user-save', {
                            granule: activityId,
                            context: contextId,
                            state: state,
                            step: step ? step : presentArrayElementIndex,
                            userActivity: userActivity.id,
                            granuleParent: currentLesson.id, // save the id of granule lesson parent
                            lesson: currentLesson.id // save the id of current lesson
                        }).pipe(take(1));
                    }));
            }))
            .subscribe((userSave: DataEntity) => {
                observable.next(userSave);
            }, (error) => {
                observable.error(error);
            });

        return observable;
    }

    /**
     * save the DataEntity of type Lesson with their metadata
     * @param {DataEntity} activityEntity
     * @param {DataEntity[]} metadatasEntity
     * @returns {Observable<DataEntity>}
     *
     *   */
    public saveSubLesson(activityEntity: DataEntity, metadatasEntity: DataEntity, originalActivity?: DataEntity): Observable<DataEntity> {
        return metadatasEntity.save(true).pipe(
            mergeMap((metadatas: DataEntity) => {
                activityEntity.set('metadatas', metadatas.id);
                if (originalActivity && originalActivity.id !== activityEntity.id) {
                    activityEntity.set('original', originalActivity.id);
                }
                return activityEntity.save(true);
            })
        );
    }
}
