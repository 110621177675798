import {ChangeDetectorRef, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities';
import {CommunicationCenterService} from '@modules/communication-center';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ImageZoningAbstractComponent} from '@modules/activities/core/player-components/image-zoning-base/image-zoning-abstract.component';
import {LessonNavigationService} from '@modules/activities/core/lesson-navigation.service';
import {ContextualService} from "@modules/activities/core/services/contextual.service";

@Component({
    selector: 'app-image-zoning-play-sound',
    templateUrl: './image-zoning-play-sound.component.html'
})
export class ImageZoningPlaySoundComponent extends ImageZoningAbstractComponent {
    public showButton = true;
    public currentAudioToPlay = '';
    private currentAnswerClicked: AnswerInterface = null;

    constructor(protected activatedRoute: ActivatedRoute,
                protected activitiesService: ActivitiesService,
                protected lessonsService: LessonsService,
                protected communicationCenter: CommunicationCenterService,
                protected contextualService: ContextualService,
                protected lessonNavigationService: LessonNavigationService,
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, contextualService, lessonNavigationService);
    }

    /**
     * step 1 : a click is made on picure and there is an audio associated
     * it will be auto launch and after the audio is read we launch the standard
     * code after a good answer
     * @param answer
     */
    public zoneGeneratorClick(answer: AnswerInterface): void {
        // audio is currently playing
        if (this.currentAnswerClicked) {
            return;
        }

        this.currentAudioToPlay = '';
        if (answer.audio && answer.audio.uri) {
            this.currentAudioToPlay = answer.audio.uri;
            this.currentAnswerClicked = answer;
            // change state because beeing playing sound
            this.answersSelected.find(a => a.id === answer.id).state = ItemAnswerStateEnum.currentlyCorrect;
        } else {
            // nothing
        }
    }

    /**
     * step 2 : sound was played now we send info that user
     * has click on it and listened it
     */
    public endPlayingSound(): void {
        this.currentAudioToPlay = '';
        // change state because end playing sound
        this.answersSelected.find(a => a.id === this.currentAnswerClicked.id).state = ItemAnswerStateEnum.wasCorrect;
        this.zoneClickEvent(this.currentAnswerClicked, false, true);
        this.currentAnswerClicked = null;
    }

    public nextStep(): void {
        if (this.allAnswerCorrect()) {
            this.doAction('next');
        }
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }
}
