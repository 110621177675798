import {GraphData} from '@modules/graph-mathia/core/model/graph-data';
import {ProgressDataEntity} from '@modules/graph-mathia/core/model/progress-data-entity';
import * as _ from 'lodash-es';
import {RawProgressDotDetail} from '@modules/graph-mathia/core/model/raw-progress-dot-detail';
import {LearnerProgressDataEntity} from '@modules/graph-mathia/core/model/learner-progress-data-entity';

export class ErrorsData extends GraphData {
    public toGraphFriendlyData(filtered: boolean): { data: number, label: string }[] {
        const errorsData = this.toDistinctErrorsGroupedData(filtered);
        return Object.keys(errorsData).map(error => ({label: error, data: errorsData[error].length}));
    }

    private toErrorsGroupedData(filtered: boolean): { [error: string]: { entity: ProgressDataEntity, learnerId: string, dot: RawProgressDotDetail, error: string }[] } {
        const flat = this.flatData;
        const filteredData = filtered ? flat.filter(d => this.graphFilters.errors.includes(d.error)) : flat;
        return _.groupBy(filteredData, 'error');
    }

    private toDistinctErrorsGroupedData(filtered: boolean): { [error: string]: { entity: ProgressDataEntity, learnerId: string, dot: RawProgressDotDetail, error: string }[] } {
        const flat = this.flatData;
        const filteredData = filtered ? flat.filter(d => this.graphFilters.errors.includes(d.error)) : flat;
        const optimised = filteredData.map(f => _.merge({optimised: f.error + f.learnerId}, f));
        const distinct = _.uniqBy(optimised, 'optimised');
        return _.groupBy(distinct, 'error');
    }

    /**
     * Retourne les donnée a plat pour chaque valeur erreur imbriqué dans les resultats
     * @private
     */
    private get flatData(): { entity: ProgressDataEntity; learnerId: string; dot: RawProgressDotDetail; error: string }[] {
        const data: { entity: ProgressDataEntity, learnerId: string, dot: RawProgressDotDetail, error: string }[] = [];

        this.entities.forEach(
            entity => Object.keys(entity.attributes.data)
                .filter(learnerId => this.graphFilters.learnerList.includes(+learnerId)) // on a tout les resultats, mais on ne garde que ceux des élèves selectionnés
                .forEach(
                    learnerId => (<LearnerProgressDataEntity>entity.attributes.data[learnerId]).detail.forEach(
                        dot => dot.errors.forEach(error => {
                            data.push({entity, learnerId, dot, error});
                        })
                    )
                )
        );

        return data;
    }

    public getStatsForError(errorLabel: string): { entity: ProgressDataEntity; learnerId: string; dot: RawProgressDotDetail; error: string }[] {
        const errorsData = this.toErrorsGroupedData(false);
        return errorsData.hasOwnProperty(errorLabel) ? errorsData[errorLabel] : [];
    }
}
