// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-card .pastille {
  background: grey;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/research-sheet/core/research-sheet/research-sheet-display/research-sheet-display-add-section/research-sheet-display-add-section.component.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;AADJ","sourcesContent":[".mat-card{\n\n  .pastille{\n    background: grey;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
