import {Component, OnInit} from '@angular/core';
import {Contest} from '@modules/contest/core/model/contest';
import {ActivatedRoute} from '@angular/router';
import {ContestService} from 'app/@modules/contest';
import {switchMap, takeUntil} from 'rxjs/operators';
import {AutoUnsubscribeTakeUntilClass} from "shared/models";
import {SyncRules} from "@modules/contest/core/model/rules";
import {AuthorizationService} from "@modules/authorization";
import {DataEntity} from "octopus-connect";
import {CommunicationCenterService} from "@modules/communication-center";

@Component({
    selector: 'app-contest-detail',
    templateUrl: './contest-detail.component.html'
})

export class ContestDetailComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    private canCompeteInContest: boolean;
    
    public contest: Contest;
    public contestId: string | number;
    public tickets: number = 0

    constructor(
        private authorization: AuthorizationService,
        private communicationCenter: CommunicationCenterService,
        private route: ActivatedRoute,
        private contestService: ContestService
    ) {
        super();

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.canCompeteInContest = this.authorization.currentUserCan(SyncRules.CompeteInContest)
                }
            });
    }

    ngOnInit(): void {
        this.route.parent?.paramMap.pipe(
            switchMap(params => {
                this.contestId = params.get('id');
                return this.contestService.getContestById(+this.contestId);
            }),
            takeUntil(this.unsubscribeInTakeUntil)
        ).subscribe(contest => {
            this.contest = contest;
        });

        this.contestService.getTickets(this.contestId).pipe(
            takeUntil(this.unsubscribeInTakeUntil)
        ).subscribe(tickets => {
            this.tickets = tickets.entities[0].get('tickets');
        });
    }

    public get canCompete(): boolean {
        return this.canCompeteInContest;
    }
    
    public get progress(): number {
        return this.contest?.globalProgress['percent'];
    }

    public get score(): number {
        return this.contest?.globalProgress['points'];
    }
    
    public get stretchGoals(): any[] {
        return []
    }
}