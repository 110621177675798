import {Component, inject} from '@angular/core';
import {AssignmentByStepService} from '@modules/assignation/core/components/assignment-by-steps/assignment-by-step.service';

@Component({
    selector: 'app-assignment-manage-dates',
    templateUrl: './assignment-manage-dates.component.html',
})
export class AssignmentManageDatesComponent {
    private assignmentByStepService: AssignmentByStepService;
    selectedStartDate: Date;
    selectedEndDate: Date;
    hours: string[] = [];
    selectedStartHour = '';
    selectedEndHour = '';
    public breadcrumb: string[] = [];

    constructor() {
        this.assignmentByStepService = inject(AssignmentByStepService);
        this.setHourSelectorsOptions();
        this.initTimeIfAlreadySet();
        this.breadcrumb = this.assignmentByStepService.setBreadcrumb();
        this.breadcrumb.push(
            this.assignmentByStepService.title + ' (' + this.assignmentByStepService.seances.filter(s => s.selected).length + ' séances)'
        );
    }

    private initTimeIfAlreadySet() {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 7);
        this.selectedStartDate = this.assignmentByStepService.startDate ? new Date(+this.assignmentByStepService.startDate * 1000) : new Date();
        this.selectedEndDate = this.assignmentByStepService.endDate ? new Date(+this.assignmentByStepService.endDate * 1000) : endDate;
        this.selectedStartHour = this.assignmentByStepService.startDate ? ('0' + this.selectedStartDate.getHours()).slice(-2) + ':' + ('0' + this.selectedStartDate.getMinutes()).slice(-2) : ('0' + new Date().getHours()).slice(-2) + ':00';
        this.selectedEndHour = this.assignmentByStepService.endDate ? ('0' + this.selectedEndDate.getHours()).slice(-2) + ':' + ('0' + this.selectedEndDate.getMinutes()).slice(-2) : '23:00';

        if (!this.assignmentByStepService.startDate) {
            this.assignmentByStepService.startDate = Math.floor(this.selectedStartDate.getTime() / 1000).toString();
            this.assignmentByStepService.startDate = this.updateTimestampWithHour(this.assignmentByStepService.startDate, this.selectedStartHour).toString();
        }

        if (!this.assignmentByStepService.endDate) {
            this.assignmentByStepService.endDate = Math.floor(this.selectedEndDate.getTime() / 1000).toString();
            this.assignmentByStepService.endDate = this.updateTimestampWithHour(this.assignmentByStepService.endDate, this.selectedEndHour).toString();
        }
    }

    private setHourSelectorsOptions() {
        for (let i = 0; i < 24 * 4; i++) {
            let hour = Math.floor(i / 4);
            let minute = (i % 4) * 15;
            let formattedHour = hour < 10 ? '0' + hour : hour;
            let formattedMinute = minute === 0 ? '00' : minute;
            this.hours.push(`${formattedHour}:${formattedMinute}`);
        }
    }

    startDateSelected(event: any) {
        this.selectedStartDate = event.value.toDate();
        this.assignmentByStepService.startDate = (Date.parse(event.value.toDate()) / 1000).toString();

        if (this.selectedStartHour !== '') {
            this.assignmentByStepService.startDate = this.updateTimestampWithHour(this.assignmentByStepService.startDate, this.selectedStartHour).toString();
        }
    }

    endDateSelected(event: any) {
        this.selectedEndDate = event.value.toDate();
        this.assignmentByStepService.endDate = (Date.parse(event.value.toDate()) / 1000).toString();
        if (this.selectedEndHour !== '') {
            this.assignmentByStepService.endDate = this.updateTimestampWithHour(this.assignmentByStepService.endDate, this.selectedEndHour).toString();
        }
    }

    onStartHourSelectionChange(event) {
        if (this.assignmentByStepService.startDate) {
            this.assignmentByStepService.startDate = this.updateTimestampWithHour(this.assignmentByStepService.startDate, this.selectedStartHour).toString();
        }
    }

    onEndHourSelectionChange(event) {
        if (this.assignmentByStepService.endDate) {
            this.assignmentByStepService.endDate = this.updateTimestampWithHour(this.assignmentByStepService.endDate, this.selectedEndHour).toString();
        }
    }

    public updateTimestampWithHour(timestamp: string, time: string): number {
        let date = new Date(parseInt(timestamp) * 1000);
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        if (time) {
            let [selectedHour, selectedMinute] = time.split(':').map(Number);
            date.setHours(selectedHour);
            date.setMinutes(selectedMinute);
        }

        let updatedTimestamp = Math.floor(date.getTime() / 1000);
        return updatedTimestamp;
    }
}