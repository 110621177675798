import {DynamicGraphFilters} from '@modules/graph-mathia/core/model/dynamic-graph-filters';
import * as moment from 'moment';

export const ProgressFilters: DynamicGraphFilters = {
    always: [{
        label: 'startDate',
        value: moment().add(-2, 'weeks').startOf('day').toDate()
    }, {
        label: 'endDate',
        value: moment().endOf('day').toDate()
    }, {
        label: 'view',
        value: 'simple'
    }, {
        label: 'exerciseType',
        value: null,
        custom: {
            rules: ['allowEmpty']
        }
    }, {
        label: 'multiLesson',
        value: null,
    }],
    hidden: [{
        label: 'learner',
        value: null
    }]
};
