import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ContestService} from "@modules/contest";
import {Contest} from "@modules/contest/core/model/contest";

@Component({
    selector: 'app-contest-header',
    templateUrl: './contest-header.component.html'
})
export class ContestHeaderComponent implements OnChanges {
    @Input() private contest: Contest;
    
    public startingPlay = false;
    public daysLeft = 0;
    public userToBest = '';
    public userScore = 0;
    public communityScore = 0;
    
    constructor(
        private contestService: ContestService,
    ) {
    }
    
    ngOnChanges(changes: SimpleChanges) {
        if (changes['contest']) {
            this.computeDaysLeft();
            this.getUserToBest();
            this.getScores();
        }
    }

    private computeDaysLeft(): void {
        if (this.contest && this.contest.endDate) {
            const diff = this.contest.endDate.getTime() - Date.now();
            this.daysLeft = Math.ceil(diff / (1000 * 3600 * 24));
        }
    }

    private getUserToBest(): void {
        // TODO récupérer le nom de l'utilisateur placé devant soit dans le classement à mettre dans this.userToBest
    }

    private getScores(): void {
        // TODO récupérer le score de l'utilisateur et de la communauté à mettre dans this.userScore et this.communityScore
    }

    public playContest(): void {
        if (!this.startingPlay) {
            this.startingPlay = true;
            this.contestService.playContest(this.contest);
        }
    }
}