import {Component, Inject, Input, OnInit} from '@angular/core';
import {ResearchSection, ResearchSectionTemplate} from '@modules/research-sheet/core/definitions';
import {ResearchSheetService} from '@modules/research-sheet/core/research-sheet.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';


@Component({
  selector: 'lms-research-sheet-display-add-section',
  templateUrl: './research-sheet-display-add-section.component.html',
  styleUrls: ['./research-sheet-display-add-section.component.scss']
})
export class ResearchSheetDisplayAddSectionComponent implements OnInit {



  public sectionSelected: ResearchSectionTemplate = null;
  parentSection: ResearchSection = null;
  templateSections: ResearchSectionTemplate[] = [];

  constructor(
    private sheetService: ResearchSheetService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private ref: MatDialogRef<ResearchSheetDisplayAddSectionComponent>,
  ) { }

  ngOnInit(): any {
    this.templateSections = this.data.sections;
    this.parentSection = this.data.parent;
  }

  public addSection(): void {
    const section: ResearchSection = new ResearchSection();

    section.name = this.sectionSelected.name;
    section.parent = this.parentSection ? this.parentSection.id.toString() : '';
    section.template = this.sectionSelected;

    this.sheetService.currentSection = section;
    this.sheetService.currentSheetEntity = null;

    this.close(true);
  }

  getFirstCharacter (section: ResearchSection): string {
    if (section && section.name) {
      return section.name.substring(0, 2);
    }
  }

  selectSection(type: ResearchSectionTemplate): void {
    this.sectionSelected = type;
  }

  close(mode: boolean = null): void {
    this.ref.close(mode);
  }
}
