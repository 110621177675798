import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-full-page',
    templateUrl: './full-page.component.html',
    styleUrls: ['./full-page.component.scss']
})
export class FullPageComponent implements OnInit {

    public alias: string;

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.alias = params.get('alias');
        });

    }

}
