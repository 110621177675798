import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from 'fuse-core/animations';

@Component({
    selector: 'app-tags-selection-core',
    templateUrl: './tags-selection-core.component.html',
    styleUrls: ['./tags-selection-core.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TagsSelectionCoreComponent implements OnInit {
    @Input() type: string;
    @Input() titleFilter: any;
    @Input()
    set tagsSelected(val: any[]) {
        this.tagsSelectedChange.emit(val);
        this._tagsSelected = val;
    }
    get tagsSelected(): any[] {
        return this._tagsSelected;
    }

    @Output() tagsSelectedChange = new EventEmitter();
    @Input() displayedColumns: any;
    @Input() dataSource: any;

    private _tagsSelected: any[];

    constructor() { }

    ngOnInit(): void {
    }

    resetTags(): void {
        this.tagsSelected = [];
        if (this.titleFilter && !this.titleFilter.value){
            this.dataSource.data = [];
        }
    }

    checkSelected(entity): number{
        return this.tagsSelected.findIndex(obj => entity['id'] === obj['id']);
    }

    selectedChapter(event: object): void{
        const position = this.checkSelected(event);
        if (position > -1) {
            this.tagsSelected.splice(position, 1);
        } else {
            this.tagsSelected.push(event);
        }
    }

}
