// mettre a jour avec /api/activity_type

export enum TypologyLabel {
    audio = 'Audio',
    awareness = 'awareness',
    comics = 'bd',
    cp = 'CP',
    divider = 'divider',
    drawLine = 'DRL',
    external = 'EXT',
    fillBlanks = 'RB',
    image = 'IMG',
    imageSoundZone = 'imgsoundzone',
    interactiveImage = 'IMGI',
    memory = 'MEM',
    mindMap = 'mindmap',
    missing = 'missing',
    missingAudio = 'missingaudio',
    multiActivity = 'MULTIAC',
    multimedia = 'MULTI',
    multipleChoice = 'QCM',
    multipleChoiceGrid = 'GCM',
    multipleChoiceUniqueAnswer = 'QCMU',
    notepad = 'notepad',
    pairing = 'APP',
    pointImage = 'POINTIMG',
    pointMultiWord = 'POINTMULTIWORD',
    pointWord = 'POINTWORD',
    recording = 'recording',
    shortAnswer = 'CRT',
    sort = 'ORD',
    sortMat = 'ORDMAT',
    summary = 'summary',
    textMatching = 'TXTMAT',
    timeline = 'timeline',
    tool = 'Tool',
    trueFalse = 'VF',
    video = 'video',
}