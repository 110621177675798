export const dashboard = {
    alwaysShowDashboard: true,
    displayHeaderLink: true,
    displayedWidgets: {
        default: [],
        learner: ['assignments'],
        trainer: ['favorites-lessons', 'favorites-corpus'],
    },
    displaySentenceBeforeHeader: false,
    displayTabsPage: {
        default: true,
        learner: false,
        trainer: true
    },
    matAlignMainDashboardTabs: 'center', // default start | center | end => Tab group with aligned labels
    newsLimit: 1,
};
