export const cards = {
    cardDisplay: {
        displayLessonBreadcrumb: {concepts: true, chapters: true, skills: false},
        showButtonThemeLesson: false,
        showButtonBookmarks: {
            themes: true,
            lesson: true,
            model: true,
            community: true,
            image: true,
            video: true,
            videoUrl: true,
            audio: true,
            document: true,
            url: true,
        },
        isTextButton: true,
    },
    cardFields: {
        default: {
            theme: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty', 'target-age'],
            lesson: ['title', 'titleIcon', 'menu', 'image', 'description', 'educationalLevel-chip-list', 'keywords-chip-list', 'button-assign-as-label-in-action-menu', 'skills', 'difficulty', 'author', 'share-lesson'],
            model: ['title', 'titleIcon', 'menu', 'image', 'description', 'educationalLevel-chip-list', 'keywords-chip-list', 'button-assign-as-label-in-action-menu', 'skills', 'difficulty', 'author', 'share-lesson'],
            community: ['title', 'titleIcon', 'menu', 'description', 'image', 'theme', 'usage', 'skills', 'difficulty', 'target-age', 'author', 'share-lesson'],
            image: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            video: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            videoUrl: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            audio: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            document: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            url: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
        }
    },
    cardDefaultHeader: '/assets/humanum/images/backgrounds/img_default_lesson.png',
    cardPlayOpenMenu: false,
    chooseSubLessonFeatureActive: {
        default: false,
        trainer: true,
    },
    isLaunchLessonAskModalActive: true,
    isLaunchLessonAskTitle: true,
    menuLinks: {
        lesson: {
            default: ['add', 'editOwn', 'delete', 'deleteOwn', 'assign', 'duplicate'],
            trainer: ['add', 'deleteOwn', 'assign', 'duplicateIfNeededAndEdit']
        },
        model: {
            administrator: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'duplicateIfNeededAndEdit'],
            manager: ['duplicate'],
            default: ['duplicateIfNeededAndEdit']
        }
    },
    multiPdfDownload: false,
    playButtonUseIcon: false,
    hidePlayPreviewFromFavoritesPage: true,
    allowedMultiPdfDownloadFromFavoritePage: true,
    viewLessonPage: true, //allow to show lesson page
    moveActionsButtonInMenu: {
        lesson: true,
        default: false
    }// Display all button in menu button except bookmark and open lesson page
};
