import {Injectable} from '@angular/core';
import {CommunicationCenterService} from "@modules/communication-center";
import {Observable, of} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor(
        private communicationCenter: CommunicationCenterService,
    ) {
    }

    public getData$(dataString: string): Observable<string | string[]> {
        if (dataString) {
            const [subject, data] = dataString.split('/');

            return new Observable<string | string[]>((observer) => {
                this.communicationCenter.getRoom('contextualData')
                    .next(subject, {data, callback: (result: string | string[]) => {observer.next(result)}});
            });
        }

        return of('');
    }

    public loadLearners(): Observable<any[]> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList');
    }

    public loadClasses(): Observable<any[]> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList');
    }

    public loadWorkgroups(): Observable<any[]> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList');
    }
}
