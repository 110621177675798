import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from "rxjs";

@Pipe({
    name: 'loadTemplateComponent'
})
export class LoadTemplateComponentPipe implements PipeTransform {
    private static matchTemplate = /{component\/([^}]*)}/g;

    transform(value: string, ...args: unknown[]): Observable<any[]> {
        const components = [];
        const matches = value.matchAll(LoadTemplateComponentPipe.matchTemplate);

        for (let match of matches) {
            // match[0] = "{component/<module>/<component>[/<paramName>/<paramValue[...]]}" - match[1] = "<module>/<component>[/<paramName>/<paramValue[...]]"
            const [module, component, ...paramsArray] = match[1].split('/');
            const params = {};
            for (let i = 0; i < paramsArray.length - 1; i += 2) {
                params[paramsArray[i]] = paramsArray[i + 1];
            }
            components.push({module, component, params});
        }

        return of(components);
    }
}
