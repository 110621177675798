import {RawProgressDotDetail} from '@modules/graph-mathia/core/model/raw-progress-dot-detail';
import {GraphData} from '@modules/graph-mathia/core/model/graph-data';

export type ProgressDotDetail = (RawProgressDotDetail & { exerciseName: string });

export const CHALLENGE_TYPE_LABEL = 'défi';

export class ProgressData extends GraphData {
    public get challenges(): ProgressDotDetail[] {
        return this.dots.filter(dot => dot.type === CHALLENGE_TYPE_LABEL);
    }

    public get notChallenges(): ProgressDotDetail[] {
        return this.dots.filter(dot => dot.type !== CHALLENGE_TYPE_LABEL);
    }

    private get dots(): ProgressDotDetail[] {
        return this.getDataOfLearnerById(this.graphFilters.learner)
            .sort((a, b) => +a.date - +b.date);
    }

    private getDataOfLearnerById(id: string): ProgressDotDetail[] {
        return this.entities
            .filter(pd => pd.attributes.data.hasOwnProperty(id) && pd.attributes.data[id].activitiesDone > 0)
            .map(pd => {
                return pd.attributes.data[id].detail.map(d => ({
                    exerciseName: pd.attributes.localized,
                    ...d
                }));
            }).flat();
    }
}
