export interface InteractiveImageActivityContent {
    id: string;
    image: {
        id: string;
        uri: string;
    };
    points: IActivityContentPoint [];
}

export interface IActivityContentPoint {
    title: string;
    description: string;
    height: string;
    width: string;
    x: string;
    y: string;
    id: string;
    alreadyOpen: boolean; // data not send from back add in front after
    media:
        {
            fid: string;
            uri: string;
        }[];
}