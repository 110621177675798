import {NgModule} from '@angular/core';
import {UploadFileGenericComponent} from '@fuse/components/upload-file-generic/upload-file-generic.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxFileDropModule} from 'ngx-file-drop';
import {CommonModule} from '@angular/common';
@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgxFileDropModule,
        CommonModule
    ],
    declarations: [
        UploadFileGenericComponent,

    ],
    exports: [
        UploadFileGenericComponent
    ],
})
export class UploadFileGenericComponentModule {
}
