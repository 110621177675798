import {inject, Injectable} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';
import {TypedDataCollectionInterface, TypedDataEntityInterface} from 'shared/models/octopus-connect';

export type UsageAttributes = {
    label: string;
}
export type UsageEntity = TypedDataEntityInterface<UsageAttributes>
export type UsageCollection = TypedDataCollectionInterface<UsageAttributes>

export interface UsagesFilters {
    label?: string;
}

@Injectable({
    providedIn: 'root'
})
export class UsagesService {

    public autonomyUsageLabel = 'usage.autonomie' as const;
    public collectiveUsageLabel = 'usage.collectif' as const;

    private octopusConnect = inject(OctopusConnectService)

    public getUsages(filters?: UsagesFilters) {
        return this.octopusConnect.loadCollection('usage', filters || {})
            .pipe(map(collection => collection as UsageCollection));
    }

    public getUsage(id: string | number): Observable<UsageEntity> {
        return this.octopusConnect.loadEntity('usage', id) as Observable<UsageEntity>;
    }

    public getUsageByLabel(label: string): Observable<UsageEntity> {
        return this.octopusConnect.loadCollection('usage', {label: label}).pipe(
            map(collection => {
                return collection.entities.length > 0 && collection.entities[0] as UsageEntity
            })
        );
    }
}
