import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';

const settingsStructure: ModelSchema = new ModelSchema({
    searchFields: Structures.array(['educationnalLevel']),
    filterTitleToTranslate: Structures.string(''),
    displayedFiltersIcons: Structures.boolean(false),
    rolesCanShowBannerInfo: Structures.array([]),
});

@Injectable({
    providedIn: 'root'
})
export class FaqService {

    public questionsPaginated: PaginatedCollection;
    public settings: { [key: string]: any };

    constructor(
        private octopusConnect: OctopusConnectService
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.faq);
    }

    public loadPaginatedFaq(filterOptions = {}): Observable<DataEntity[]> {
        this.questionsPaginated = this.octopusConnect.paginatedLoadCollection('faq_search', filterOptions);
        return this.questionsPaginated.collectionObservable
            .pipe(
                map(collection => collection.entities)
            );
    }
}
