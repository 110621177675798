import {Subscription, Observable, finalize, of, combineLatest, ReplaySubject} from 'rxjs';
import {takeUntil, filter, mergeMap, map, take, tap, mapTo} from 'rxjs/operators';
import {
    Component,
    ElementRef,
    EventEmitter, inject,
    Input, OnDestroy,
    OnInit, Output,
    ViewChild,
} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {CollectionOptionsInterface} from 'octopus-connect';
import {DataEntity, OctopusConnectService, OrderDirection} from 'octopus-connect';
import {DatacardService} from 'shared/datacard.service';
import {AuthenticationService} from '@modules/authentication';
import * as _ from 'lodash-es';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {DeleteLessonWarningComponent} from '@modules/activities/core/lessons/lessons-list/lessons-tab/delete-lesson-warning/delete-lesson-warning.component';
import {DataCardInterface} from 'fuse-core/components/card/data-card.interface';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {CardsService} from 'fuse-core/components/card/cards.service';
import {ContextualService} from '@modules/activities/core/services/contextual.service';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {LessonsCardService} from '@modules/activities/core/lessons/services/lessons-card.service';
import {AsyncRules, LessonGranuleEntity} from '@modules/activities/core/models';
import {isEmpty} from 'shared/utils';
import {AddLessonButtonMorphableType} from '@modules/activities/core/lessons/lessons-list/lessons-tab/add-lesson-button-morphable/add-lesson-button-morphable.component';
import {AuthorizationService} from '@modules/authorization';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'fuse-app-lessons-tab',
    templateUrl: './lessons-tab.component.html'
})
export class LessonsTabComponent extends AutoUnsubscribeTakeUntilClass implements OnInit, OnDestroy {
    @Input() type: 'currentUser' | 'byRole';
    @Input() creatorsRolesFilter: number[];
    /**
     * The filters to apply to the lessons list given by the url
     * By changing the filter's inputs, the url will be updated and the lessons list will be refreshed
     * you have to connect the urlFilters to the url changes
     */
    @Input() urlFilters: { [key: string]: string; };
    @Input() hiddenForcedFilters: { [key: string]: any; };

    @Output() dataUpdated = new EventEmitter<void>;

    @ViewChild('scrollContainer', {static: true}) scrollContainer: ElementRef;

    public displayedFiltersIcons;
    public fieldsToDisplay: string[] = [];
    public showLoader = false; // show a loader during soma action duplicate lesson delete lesson etc.
    public countEntities = 0;
    public pageIndex = 0;
    public readonly pageRangeOptions = [12];
    public customFieldName: { field: string, value: string }[] = [{field: 'title', value: ''}];
    public dataCards: DataCardInterface<LessonGranuleEntity>[] = [];
    public methods: any[] = [];
    public addCardButtonPosition: 'first' | 'last' | 'none' = 'last';
    public addCardButtonType: AddLessonButtonMorphableType = 'neo-already-practiced';
    public dialog = inject(MatDialog);
    public lessonsService = inject(LessonsService);
    private resourcesSubscription: Subscription;
    private optionsInterface: CollectionOptionsInterface;
    private dataCardsCallbacks: Partial<DataCardInterface<LessonGranuleEntity>>;
    private loadingLessonActivities: boolean;
    private givePriorityToNeoFirstTimeCard = false;
    private activitiesService = inject(ActivitiesService);
    private authorizationService = inject(AuthorizationService);
    private router = inject(Router);
    private octopusConnect = inject(OctopusConnectService);
    private contextualService = inject(ContextualService);
    private datacardService = inject(DatacardService);
    private lessonsCardService = inject(LessonsCardService);
    private cardsService = inject(CardsService);
    private authService = inject(AuthenticationService);
    private translate = inject(TranslateService);
    private route = inject(ActivatedRoute);
    private lessonsConfigurationService = inject(LessonsConfigurationService);
    private communicationCenter = inject(CommunicationCenterService);
    private assignments: DataEntity[] = [];
    private userSaves: DataEntity[] = [];
    private resources: LessonGranuleEntity[] = [];
    constructor() {
        super();
        this.communicationCenter
            .getRoom('assignment')
            .getSubject('assignmentsAssignedToUser')
            .pipe(
                take(1),
                tap((assignments) => this.assignments = assignments)
            )
            .subscribe();
    }
    /**
     * show info if allowed in setting and if type is not model (model = byRole) because user never create lesson model by front
     */
    public get displayCreateLessonHelper(): boolean {
        return this.lessonsService.settings.displayCreateLessonHelper && this.type !== 'byRole';
    }

    public get rolesCanShowBannerInfo(): string[] {
        return this.lessonsConfigurationService.rolesCanShowBannerInfo();
    }

    public get showSpinner(): boolean {
        return this.lessonsService.showSpinner || this.cardsService.isOneLessonIsAlreadyLaunch;
    }

    /**
     * check if we show add button
     * @returns {boolean}
     */
    public get showAddLessonButton(): boolean {
        if ((this.type !== 'byRole') &&
            this.router.url.includes('community')) {
            return !this.activitiesService.settings.hideAddButtonLessonForCommunity;
        }
        return (this.type !== 'byRole' && !this.activitiesService.settings.hideAddButtonLessonList) ||
            this.type === 'byRole'
            && this.lessonsService.getAllowedRolesForModelsCreation().includes(this.authService.accessLevel)
            && !this.activitiesService.settings.hideAddButtonLessonForModel;
    }

    /**
     * check if we show add Card button
     * @returns {boolean}
     */
    public get showAddLessonButtonCard(): boolean {
        return this.type !== 'byRole' ? this.activitiesService.settings.showAddLessonButtonCard : false;
    }

    /**
     * check if we show community helper
     * @returns {boolean}
     */
    public get displayCommunityLessonHelper(): boolean {
        return this.router.url.includes('community');
    }

    public get usageOfLessonsDisplay(): string {
        let usage = 'lessons'; // default

        if (this.lessonsConfigurationService.bannerInfoMustListenUsages()) {
            const usagesSettings = this.lessonsConfigurationService.bannerInfoMustListenUsages();
            const usageUrlId = this.route.snapshot.queryParams.usage;

            if (usageUrlId && usagesSettings.hasOwnProperty(usageUrlId)) {
                usage += '_' + usagesSettings[usageUrlId];
            }

        }
        return usage;

    }

    ngOnInit(): void {
        this.optionsInterface = {
            filter: {}, // filter results by current user id
            page: 1,
            range: 12
        };

        this.router.events
            .pipe(
                filter((e) => e instanceof NavigationEnd),
            )
            .subscribe((event: NavigationEnd) => {
                if (event.url.includes('/lessons/list/search')) {
                    this.setupDataCardsCallbacks(); // re init to get new origin path
                }
            });
        this.subscribeToRouteParams();
        this.initCustomFields();
        this.subscribeToMethods();
        this.setupDataCardsCallbacks();

        this.contextualService.dataLessonFilteredCount$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => {
                this.dataUpdated.pipe(takeUntil(this.unsubscribeInTakeUntil))
                    .subscribe(() => callback(this.countEntities.toString()));
                callback(this.countEntities.toString());
            });

        this.doesUserAlreadyHasLessons$().pipe(
            takeUntil(this.unsubscribeInTakeUntil),
            tap((hasLessons) => {
                this.givePriorityToNeoFirstTimeCard = hasLessons === false;
                this.setTheButtonCardType(this.addCardButtonType);
            })
        ).subscribe();
    }

    public launchSearch(optionsInterface: CollectionOptionsInterface = {filter: {}, page: 1, range: 12}): void {
        this.resourcesSubscription.unsubscribe();
        this.resourcesSubscription = this.refreshList(optionsInterface).pipe(
            tap(() => this.setTheButtonCardPositionConfig(optionsInterface)),
            finalize(() => this.showLoader = false)
        ).subscribe();
    }

// TODO Move loading of datacards in a datacard service (lesson-datacard.service.ts ?) to allow other components to load them
    refreshList(optionsInterface: CollectionOptionsInterface = {filter: {}, page: 1, range: 12, orderOptions: []}): Observable<LessonGranuleEntity[]> {
        this.optionsInterface = _.cloneDeep(optionsInterface);
        // some spécific filter not coming from search filter top component

        this.optionsInterface.filter['multi_step'] = this.activitiesService.settings.loadLessonWithSublesson['multi_step'];
        this.optionsInterface.filter['typology'] = this.activitiesService.settings.loadLessonWithSublesson['typology'];


        if (this.activitiesService.settings && !!this.activitiesService.settings.shareableModel && !!this.creatorsRolesFilter) {
            this.optionsInterface.filter['model'] = this.activitiesService.settings.shareableModel;
        }
        // for erasme back order by default by title metadata force on changer for users lessons
        if (this.type === 'currentUser') {
            this.optionsInterface.orderOptions = [{field: 'changed', direction: OrderDirection.DESC}];
        }
        this.setFiltersByUrl();

        // If a parent force a filter, we add it to the request
        _.merge(this.optionsInterface.filter, this.hiddenForcedFilters);
        return this.lessonsService.loadPaginatedLessons(this.type, this.creatorsRolesFilter, '', this.optionsInterface)
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
                filter((resources) => !!resources),
                tap((resources) => this.resources = resources),
                mergeMap(() => {
                    const subjectUserSaves = new ReplaySubject<Observable<DataEntity[]>>(1);
                    this.communicationCenter
                        .getRoom('userSave')
                        .next('loadUserSave', {filterOptions: {}, onComplete: subjectUserSaves});
                    return subjectUserSaves.pipe(
                        take(1),
                        mergeMap((userSave$: Observable<DataEntity[]>) => userSave$),
                        tap((userSaves) => this.userSaves = userSaves),
                    );
                }),
                tap(() => {
                    this.dataCards = this.datacardService.processResources(this.resources,
                        this.dataCardsCallbacks,
                        this.type,
                        this.userSaves,
                        {displayQuizBtnInCard: this.lessonsConfigurationService.displayQuizBtnInCard(), assignments: this.assignments});
                    this.setPaginator();

                    this.dataUpdated.emit();

                    // Automatically open lesson page when asked to
                    if (this.route.snapshot.queryParams['openLessonPage'] && this.dataCards.length > 0) {
                        this.cardsService.openLessonPage(this.dataCards[0], this.route.snapshot.queryParams['origin-url']);
                    }
                    this.showLoader = false; // needed because of subscribing with takeUntil complete will be fired only ondestroy
                }),
                mapTo(this.resources)
            );
    }

    public onPaginateChange(event, type): void {
        this.showLoader = true;
        this.scrollToTop();
        switch (type) {
            case 'currentUser':
                this.lessonsService.userLessonsPaginated.paginator.page = event.pageIndex + 1;
                break;
            case 'byRole':
                this.lessonsService.lessonsPaginated.paginator.page = event.pageIndex + 1;
                break;
        }
    }

    public onAddLessonButtonClick($event: MouseEvent): void {
        $event.stopPropagation();
        this.lessonsService.launchEditor(null);
    }

    private doesUserAlreadyHasLessons$(): Observable<boolean> {
        return this.lessonsService.loadCurrentUserOwnedLessons().pipe(
            takeUntil(this.unsubscribeInTakeUntil),
            map(lessons => lessons.length > 0)
        );
    }

    private setTheButtonCardPositionConfig(optionsInterface?: CollectionOptionsInterface): void {
        // difficile de faire plus simple sans refacto toutes les pages de listing de lesson
        const totalElements = this.countEntities;
        const howManyPerPage = this.optionsInterface.range ?? this.pageRangeOptions[0];
        const howManyPages = Math.ceil(totalElements / howManyPerPage);
        const currentPage = this.pageIndex + 1;

        const isFirstPage = currentPage === 1;
        const isLastPage = currentPage === howManyPages || howManyPages === 0;

        // on utilise optionsInterface et pas this.optionsInterface car on veut savoir si le filtre retourné par le searchFiltersComponent est vierge
        // Il est fort possible qu'entre le filtre du searchFiltersComponent et le lancement de la requête des filtres aient été ajoutés
        // si c'est le cas, ce serait un faux négatif ici
        // De plus on est obligé de prendre en compte les filtres de l'url car ils sont appliqués avant le lancement de la requete
        const appliedFilters = _.merge({}, this.urlFilters ?? {}, optionsInterface?.filter ?? {});
        const isFilterPristine = Object.keys(appliedFilters).every(k => isEmpty(appliedFilters[k]));

        if (isFilterPristine && isFirstPage) {
            this.addCardButtonPosition = 'first';
            this.setTheButtonCardType('neo-already-practiced');
        } else if (isFilterPristine === false && isLastPage) {
            this.addCardButtonPosition = 'last';
            this.setTheButtonCardType('neo-searching');
        } else {
            this.addCardButtonPosition = 'none';
        }
    }

    private setupDataCardsCallbacks(): void {
        const defaultDataCardsCallbacks = this.lessonsCardService.getCallbacks();

        const overloadedDataCardsCallbacks: Partial<DataCardInterface<LessonGranuleEntity>> = {
            openAssign: (resource) => this.openAssign(resource, this.dataCards),
            openDialog: (resource) => this.openDeleteDialog(resource),
            'openDuplicate': (id) => this.duplicateAndEdit(id),
            'openEditor': (id) => this.launchEditor(id),
        };
        const newDataCardsCallbacks: Partial<DataCardInterface<LessonGranuleEntity>> = {
            duplicateAndAssign: (id) => this.duplicateAndAssign(id, 'lesson'),
            duplicateAndEdit: (id) => this.duplicateAndEdit(id),
            duplicateIfNeededAndEdit: (id) => this.duplicateIfNeededAndEdit(id),
            downloadDoc: (path) => this.datacardService.downloadDoc(path),
            share: (resource, val) => this.lessonsService.editGranuleLesson(resource, val),
            isShareableCommunity: this.lessonsService.isShareableCommunity,
            isShareableModel: this.lessonsService.isShareableModel,
            isContextDefinedItAsModel: this.type === 'byRole',
            deleteWithAssignments: (resource: DataEntity) => this.deleteWithAssignments(resource),
            deleteWithAssignments$: (resource: DataEntity) => this.deleteWithAssignments$(resource),
            editAndDeleteAssignments: (resource: DataEntity) => this.editAndDeleteAssignments(resource),
            headerClasses: (resource: DataEntity) => this.lessonsCardService.getLessonTypeClasses(resource as LessonGranuleEntity),
            contextualFilters: this.optionsInterface,
        };

        this.dataCardsCallbacks = _.merge({}, defaultDataCardsCallbacks, overloadedDataCardsCallbacks, newDataCardsCallbacks);
    }

    private subscribeToRouteParams(): void {
        this.route.queryParams.subscribe((params) => {
            this.setDisplayField();

            if (this.urlFilters?.titleFilter) {
                this.optionsInterface.urlExtension = this.urlFilters['titleFilter'];
            } else {
                this.optionsInterface.urlExtension = '';
            }

            this.updateFilterOptions(params);
            this.showLoader = true;
            this.resourcesSubscription = this.refreshList(this.optionsInterface).pipe(
                tap(() => this.setTheButtonCardPositionConfig())
            ).subscribe();
        });
    }

    /**
     * Updates filter options based on the provided parameters.
     *
     * The function uses a pre-defined mapping between parameter names and filter options.
     * The parameter values are assigned to the corresponding filter options if they exist.
     *
     * The 'title' parameter is a special case, its value is assigned to 'this.optionsInterface.urlExtension'.
     *
     * @param {object} params - An object containing key-value pairs where the key is the name of the parameter,
     *                          and the value is the corresponding value that needs to be set in the filter options.
     *
     * @returns {void}
     */
    private updateFilterOptions(params): void {
        // Define mapping
        const paramFilterMapping = {
            'usage': 'usage',
            'concepts': 'concepts',
            'educationalLevel': 'level',
            'title': 'urlExtension',
            'autocorrection': 'autocorrection',
            'chapters': 'chapters',
            'notions': 'notions',
            'skills': 'skills',
            'id': 'nid',
            'bookmarks': 'bookmarks',
        };

        // Reset filter
        this.optionsInterface.filter = {};

        // Loop over mapping and assign value to filter if exists in params
        for (const param in paramFilterMapping) {
            if (params[param]) {
                const filterKey = paramFilterMapping[param];

                // Check if the mapping is for urlExtension
                if (filterKey === 'urlExtension') {
                    this.optionsInterface[filterKey] = params[param];
                } else {
                    this.optionsInterface.filter[filterKey] = params[param];
                }
            }
        }
    }

    private subscribeToMethods(): void {
        if (this.lessonsService.checkAccess(['manager'])) {
            this.subscribeToMethodsForManager();
        } else {
            this.activitiesService.licensingMethods.pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((methods) => {
                    this.methods = methods;
                });
        }
    }

    private subscribeToMethodsForManager(): void {
        this.activitiesService.getMethods().pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe((methods) => {
            methods.entities.forEach(method => {
                this.methods.push({
                    id: method.id,
                    label: method.get('name')
                });
            });
        });
    }

    private initCustomFields(): void {
        this.customFieldName[0].value = this.activitiesService.settings.filterTitleToTranslate;
        this.displayedFiltersIcons = this.activitiesService.settings.displayedFiltersIcons;
    }

    /**
     * manage delete lesson without assignment lamorim for example
     */
    private openDeleteDialog(resource: DataEntity): void {
        this.showLoader = true;
        this.lessonsService.openDeleteDialog(resource)
            .subscribe({
                error: error => {
                    throw error;
                },
                complete: () => this.showLoader = false
            });

    }

    /**
     * set array with fields to show in regard to settings
     */
    private setDisplayField(): void {
        const routeUrl = this.route.snapshot.url.join('/');
        const queryParams = this.route.snapshot.queryParams;
        const searchParams = new URLSearchParams(queryParams).toString();
        const routeKey = searchParams ? `${routeUrl}?${searchParams}` : routeUrl;
        this.fieldsToDisplay = this.lessonsConfigurationService.getSearchFields(routeKey, this.authService.accessLevel);
    }

    /**
     * apply filter in regard of setting filtertoApplyOnLessonsByUrl and of current route
     * filter use taxonomy terme on back and field chapters
     */
    private setFiltersByUrl(): void {
        this.activitiesService.settings.filtertoApplyOnLessonsByUrl.forEach((urlToTest: { url: string, id: number }) => {
            if (this.router.url.includes(urlToTest.url)) {
                this.optionsInterface.filter['chapters'] = urlToTest.id;
            }
        });
    }

    private setPaginator(): void {
        switch (this.type) {
            case 'currentUser':
                if (this.lessonsService.userLessonsPaginated.paginator) {
                    this.countEntities = this.lessonsService.userLessonsPaginated.paginator.count;
                    this.pageIndex = this.lessonsService.userLessonsPaginated.paginator.page - 1;
                }
                break;
            case 'byRole':
                if (this.lessonsService.lessonsPaginated.paginator) {
                    this.countEntities = this.lessonsService.lessonsPaginated.paginator.count;
                    this.pageIndex = this.lessonsService.lessonsPaginated.paginator.page - 1;
                }
                break;
        }
    }

    /**
     * scroll to top when changing page
     */
    private scrollToTop(): void {
        this.scrollContainer.nativeElement.scrollTop = 0;
    }

    private openAssign(lesson, dataCard): void {
        if (this.canSelectActivities(lesson)) {
            this.loadLessonActivities(lesson);
        } else {
            this.openAssignmentDialog(lesson, null, dataCard.filter(d => d.resource.id === lesson.id).length > 0 ? dataCard.filter(d => d.resource.id === lesson.id)[0] : null);
        }
    }

    private canSelectActivities(lesson): boolean {
        return this.lessonsConfigurationService.canSelectActivities() && lesson.get('reference').every(item => item.type === 'lesson');
    }

    private loadLessonActivities(lesson): void {
        if (!this.loadingLessonActivities) {
            this.loadingLessonActivities = true;
            this.lessonsService.loadLessonActivities(lesson).subscribe((activities) => {
                this.loadingLessonActivities = false;
                this.openAssignmentDialog(lesson, activities);
            });
        }
    }

    private openAssignmentDialog(lesson, activities = null, datacard = null): void {
        if (this.activitiesService.assignmentView) {
            const seances = datacard?.resource.get('reference').map((subLesson: { id: string | number, title: string }) => {
                return ({label: subLesson.title, value: subLesson.id});
            }) || [];
            const dialogRef = this.dialog.open(this.activitiesService.assignmentView, {
                data: {
                    origine: 'assignment',
                    nodeId: lesson.id,
                    node: lesson,
                    activities: activities,
                    // sublesson when exist (seances)
                    seances: seances.length > 1 ? seances : [],
                }
            });

            dialogRef.afterClosed().subscribe((data) => {
                if (this.activitiesService.settings.showInfoToTeacherAfterAssignment === true && data) {
                    this.openInfoAssignmentDialog();
                }
                // only if we used the new method of assignment
                if (data && this.activitiesService.assignmentView.name === 'AssignmentByStepsComponent') {
                    this.communicationCenter
                        .getRoom('assignment')
                        .next('createAssignmentV2', {
                            assignment: data, callback: assignment => {
                                // done
                            }
                        });
                }
            });
        }
    }

    private openInfoAssignmentDialog(): void {
        let dialogBody = '';

        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
            titleDialog: '',
        };

        this.translate
            .get('generic.info_assignment_teacher')
            .subscribe(
                (translation: string) => (dialogBody = translation)
            );

        this.translate
            .get('generic.info_assignment_teacher_title')
            .subscribe(
                (translation: string) => (
                    dialogConfig.data.titleDialog = translation
                )
            );
        dialogConfig.data.bodyDialog = dialogBody;
        this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
    }

    private duplicateAndAssign(lessonGranuleId: number | string, type: string): void {
        this.showLoader = true;
        this.lessonsService.getLessonObs(lessonGranuleId.toString(), true).pipe(
            take(1),
            mergeMap(() => this.lessonsService.lessonDuplication(lessonGranuleId, type)),
            mergeMap((duplicatedResult) => this.lessonsService.getLessonObs(duplicatedResult.get('duplicateGranuleLesson').nid, true).pipe(
                take(1)
            )),
            map((duplicatedLesson) => this.openAssign(duplicatedLesson, this.dataCards))
        ).subscribe({
            error: error => {
                throw error;
            },
            complete: () => this.showLoader = false
        });
    }

    private duplicateAndEdit(lessonGranuleId: number | string): void {
        this.showLoader = true;
        this.lessonsService.duplicateLessonById(lessonGranuleId.toString()).pipe(
            take(1),
            tap((duplicatedLesson) => this.launchEditor(duplicatedLesson.get('duplicateGranuleLesson').nid.toString())),
        ).subscribe(() => this.showLoader = false);
    }

    private duplicateIfNeededAndEdit(parentLessonGranuleId: number | string): void {
        this.showLoader = true;

        const isUserAllowedToEdit$ = this.authorizationService.currentUserCan(AsyncRules.EditLesson, of(false), null);
        const parentLessonGranule$ = this.lessonsService.getLessonObs(parentLessonGranuleId.toString());
        const references$ = parentLessonGranule$.pipe(
            take(1),
            map(parentLessonGranule => parentLessonGranule.get('reference')),
        );

        const areThereSomeSubLessons$ = references$.pipe(
            map(reference => reference.length > 0 && reference.some(item => item.type === 'lesson')),
        );

        isUserAllowedToEdit$.pipe(
            tap(isEditionFeatureAllowed => {
                if (isEditionFeatureAllowed === false) {
                    throw new Error('User is not allowed to edit');
                }
            }),
            mergeMap(() => areThereSomeSubLessons$),
            mergeMap(areThereSomeSubLessons => {
                if (areThereSomeSubLessons) {
                    return this.lessonsService.openChooseSubLessonDialog(parentLessonGranuleId.toString());
                }

                return of(parentLessonGranuleId.toString());
            }),
            filter(lessonGranuleId => !!lessonGranuleId),
            mergeMap(lessonToEditId => this.lessonsService.getLessonObs(lessonToEditId.toString()).pipe(take(1))),
            mergeMap(lessonToEdit => {
                return this.authorizationService.currentUserCan(AsyncRules.EditLesson, of(false), lessonToEdit)
                    .pipe(
                        mergeMap(isLessonEditable => {
                            if (isLessonEditable === false) {
                                return this.lessonsService.duplicateLessonById(lessonToEdit.id.toString()).pipe(
                                    take(1),
                                    map(duplicatedLesson => duplicatedLesson.get('duplicateGranuleLesson').nid.toString())
                                );
                            }

                            return of(lessonToEdit.id.toString());
                        })
                    );
            }),
            map(lessonToEditId => this.launchEditor(lessonToEditId)),
        ).subscribe(() => this.showLoader = false);
    }

    /**
     * Remove an lesson and his assignment
     * If there are any assignment, a modal will ask the user if he is sure.
     * @param resource
     * @private
     */
    private deleteWithAssignments$(resource: DataEntity) {
        return this.lessonsService.getAssignmentsCountByLessonId(resource.id).pipe(
            take(1),
            mergeMap(count => {
                if (count === 0) {
                    this.showLoader = true;
                    return this.lessonsService.openDeleteDialog(resource, false);
                } else {
                    return this.dialog.open(DeleteLessonWarningComponent, {data: {count}}).afterClosed().pipe(
                        filter((isConfirmed: boolean) => isConfirmed),
                        tap(() => this.showLoader = true),
                        mergeMap(() => new DataEntity('granule', resource.attributes, this.octopusConnect, resource.id).remove()),
                        filter(isDeleted => isDeleted),
                        tap(() => this.refreshList()),
                    );
                }
            })
        );
    }

    /**
     * Remove an lesson and his assignment
     * If there are any assignment, a modal will ask the user if he is sure.
     * @param resource
     * @private
     */
    private deleteWithAssignments(resource: DataEntity) {
        return this.deleteWithAssignments$(resource).subscribe({
            error: error => {
                throw error;
            },
            complete: () => this.showLoader = false
        });
    }

    /**
     * Edit a lesson and remove assignments
     * If there are any assignment, a modal will ask the user if he is sure.
     * @param resource
     * @private
     */
    private editAndDeleteAssignments(resource: DataEntity): void {
        this.showLoader = true;
        this.lessonsService.editAndDeleteAssignments(resource).subscribe({
            next: () => {
                this.showLoader = false;
                this.refreshList();
            }
        });
    }

    private launchEditor(id) {
        this.showLoader = true;
        this.lessonsService.launchEditor(id).subscribe({
            next: () => {
                this.showLoader = false;
                this.refreshList();
            }
        });
    }

    private setTheButtonCardType(type: AddLessonButtonMorphableType): void {
        if (this.lessonsConfigurationService.isAddLessonCardNewStyle()) {
            if (this.givePriorityToNeoFirstTimeCard) {
                this.addCardButtonType = 'neo-first-time';
            } else {
                this.addCardButtonType = type;
            }
        } else {
            this.addCardButtonType = 'old-school';
        }
    }

    public get isOneLessonIsAlreadyLaunch(): boolean {
        return this.cardsService.isOneLessonIsAlreadyLaunch;
    }

    ngOnDestroy(): void {
        this.cardsService.isOneLessonIsAlreadyLaunch = false;
    }
}
