import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
    CollectionsBlock, CollectionsBlockElement
} from "fuse-core/components/basic-page/custom-blocks/collections-block/collections-block.model";
import {Router} from "@angular/router";

@Component({
    selector: 'app-collections-block',
    templateUrl: './collections-block.component.html',
    styleUrls: ['./collections-block.component.scss']
})
export class CollectionsBlockComponent {
    @Input() private blockData: CollectionsBlock;
    @Output() private action = new EventEmitter<() => void>();

    constructor(
        private router: Router,
    ) {
    }

    public get description(): string {
        return this.blockData.description;
    }

    public get collections(): CollectionsBlockElement[] {
        return this.blockData.collections;
    }

    public navigate(collection: CollectionsBlockElement): void {
        this.action.emit(() => {
            this.router.navigate([collection.link_relative_url]);
        });
    }
}
