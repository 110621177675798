import {Component} from '@angular/core';
import {GraphInclusiveService} from '../../services/graph-inclusive.service';
import {
    GraphInclusiveAuthorizationService
} from "@modules/graph-inclusive/core/services/graph-inclusive-authorization.service";

@Component({
    selector: 'app-multi-graph-view',
    templateUrl: './multi-graph-view.component.html',
})
export class MultiGraphViewComponent {
    constructor(
        private graphService: GraphInclusiveService,
        private graphInclusiveAuthorizationService: GraphInclusiveAuthorizationService
    ) {
    }

    graphsAreAvailable(): boolean {
        return this.graphService.graphsAreAvailable();
    }

    public get rolesCanShowBannerInfo(): boolean {
        return this.graphInclusiveAuthorizationService.rolesCanShowBannerInfo;
    }
}
