import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {UserScoreService} from '@modules/achievement/core/services/user-score.service';

export interface LeaderboardTableRow {
    studentName: string;
    points: number;
    rank: number;
}

@Component({
    templateUrl: './leaderboard.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaderboardComponent {
    public displayedColumns: string[] = ['studentName', 'points', 'rank'];
    public dataSource: LeaderboardTableRow[];

    constructor(public userScoreService: UserScoreService,
                private changeDetector: ChangeDetectorRef) {
        this.initData();
    }

    private initData(): void {
        this.userScoreService.leaderboardData()
            .subscribe(res => {
                this.dataSource = res;
                this.changeDetector.detectChanges();
            });
    }
}
