import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-create-group-news',
    templateUrl: './create-group-news.component.html',
})
export class CreateGroupNewsComponent {

    constructor(public router: Router) {
    }

    public goToGroupManagementPage(): void {
        this.router.navigate(['/groups/list/groups']);
    }
}
