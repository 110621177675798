import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchFiltersComponent} from './search-filters.component';
import {SharedTranslateModule} from '../../../app/shared/shared-translate.module';
import {SharedMaterialModule} from '../../../app/shared/shared-material.module';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {FuseDirectivesModule} from 'fuse-core/directives/directives';
import {FlexLayoutModule} from '@angular/flex-layout';
import { GenericSearchFiltersComponent } from './generic-search-filters/generic-search-filters.component';

@NgModule({
    declarations: [
        SearchFiltersComponent,
        GenericSearchFiltersComponent,
    ],
    imports: [
        CommonModule,
        SharedTranslateModule,
        SharedMaterialModule,
        ReactiveFormsModule,
        FormsModule,
        FuseDirectivesModule,
        FlexLayoutModule,
    ],
    exports: [
        SearchFiltersComponent,
        GenericSearchFiltersComponent
    ]
})
export class SearchFiltersModule {
}
