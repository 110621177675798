import {AfterViewInit, Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Subscription} from 'rxjs';
import { MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';

import {FlagService} from 'shared/flag.service';
import {ActivitiesService} from '@modules/activities/core/activities.service';

@Component({
    selector: 'fuse-app-sequences-tab',
    templateUrl: './sequences-tab.component.html',
})

export class SequencesTabComponent implements OnInit, AfterViewInit {
    corpusId: string;
    ressourcesSubscription: Subscription;
    corpusSubscription: Subscription;
    ressources;
    selectAll = false;
    isChecked = false;
    files: any;
    ELEMENT_DATA = [];
    dataSource = new MatTableDataSource();
    uniqueTypes;
    uniqueAuthors;
    displayedColumns;
    selected;
    numberOfActivities: number;
    selectedValue = null;
    items: any[];
    types: string[];
    activityCount: number = 0;
    stepCount: number = 0;
    lessonCount: number = 0;
    itemAdds: object;
    // https://github.com/angular/material2/issues/10205
    private paginator: MatPaginator;
    private sort: MatSort;

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        //this.setDataSourceAttributes();
    }

    @ViewChild(MatPaginator, { static: true }) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        //this.setDataSourceAttributes();
    }

    selectedResources;
    checkboxes: {};

    @ViewChildren(MatCheckbox) checkBoxes: QueryList<MatCheckbox>;

    constructor(
        private flagService: FlagService,
        // private lessonsService: LessonsService,
        private activitiesService: ActivitiesService,
        private route: ActivatedRoute,
        private router: Router,
    ) {

        this.activitiesService.onFilesChanged.subscribe(files => {
            this.files = files;

            this.checkboxes = {};
            files.map(file => {
                this.checkboxes[file.id] = false;
            });
        });

        this.activitiesService.onSelectedResourcesChanged.subscribe(data => {
            for (const id in this.checkboxes) {
                if (!this.checkboxes.hasOwnProperty(id)) {
                    continue;
                }

                this.checkboxes[id] = data.resources.includes(id);
            }
            this.selectedResources = this.ressources;
        });

        this.activitiesService.onFileSelected.subscribe(selected => {
            this.selected = selected;
        });
    }


    ngAfterViewInit(): void {
        this.setDataSourceAttributes();
    }

    ngOnInit(): void {

        this.ressourcesSubscription = this.activitiesService.loadSequences().subscribe(resources => {
            this.ressources = resources;

            if (!resources) return;
            this.ELEMENT_DATA = this.ressources;
            this.displayedColumns = ['checkbox', 'type', 'title', 'utilisation', 'favori', 'evaluation', 'actions'];
            this.dataSource.data = this.ELEMENT_DATA;
            this.numberOfActivities = resources.length;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        });
    }

    setDataSourceAttributes() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    onSelect(selected) {
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    cleanOutputForClassName(type: string) {
        type = type.trim(); // Remove whitespace
        type = type.toLowerCase(); // Datasource defaults to lowercase matches
        return type;
    }

    isSelected(row): boolean {
        return this.selected && (this.selected.id === row.id);
    }

    updateCheck() {
        this.isChecked = !this.isChecked;
    }

    navigateToLesson(item: any): void {
        const navigationParams: NavigationExtras = {
            queryParams: {
                'data': item.id
            }
        };

        // consulted flag
        let flaggingId;

        if (typeof(item.get('consulted')) === 'object') {
            flaggingId = item.get('consulted').flagging_id;
        }

        this.flagService.updateFlagEntity(item, 'node', 'consulted', flaggingId );

        this.router.navigate(['lessons', item.id, 'player'], navigationParams);
    }
}

