import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DataSource} from '@angular/cdk/collections';
import {InstitutionGroupService} from '@modules/groups-management/core/services/institution-group.service';
import {UntypedFormGroup} from '@angular/forms';
import {InstitutionGroupAddress} from '@modules/groups-management/core/definitions';
import {GroupsManagementService} from '@modules/groups-management/core/services/groups-management.service';
import {DataEntity} from 'octopus-connect';
import {GroupService} from '@modules/groups-management/core/services/group.service';
import {FuseGroupsFormDialogData} from '@modules/groups-management/core/groups-listing/groups-form/groups-form.component';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '../models/rules';
import {filterType, GroupManagementConfigurationService, roleList} from '@modules/groups-management/core/services/group-management-configuration.service';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector: 'app-institution-group',
    templateUrl: './institution-group.component.html',
    styleUrls: ['./institution-group.component.scss']
})
export class InstitutionGroupComponent implements OnInit {
    public displayedColumns = ['checkbox', 'institutiongroupname', 'address', 'city', 'postalCode', 'country', 'buttons'];
    public displayedFilters: string[] = []; // filter to show
    public dataSource: InstitutionDataSource | null;


    public newInstitutionGroup: FuseGroupsFormDialogData = {
        data: {
            action: 'new',
            title: 'add_institution_group',
            fields: ['id', 'title', 'institution', 'postalCode', 'country'],
            selects: {
                parent: () => this.institutionGroupService.getAllInstitutionsFormated(),
                metadata: () => this.institutionGroupService.getMetadata()
            },
            typeEntity: 'institution'
        },
        callback: (response) => this.newGroupCallback(response),
        isAuthorized: () => this.authorizationService.currentUserCan(SyncRules.CreateInstitution),
    };

    public deleteInstitutionGroup: FuseGroupsFormDialogData = {
        data: {
            titleDialog: 'groups-management.title_remove',
            bodyDialog: 'groups-management.sure_remove_institution',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (group) => this.deleteGroupCallback(group),
        callbackWithMultiple: (list) => this.deleteListCallback(list),
        isAuthorized: (institution) => this.authorizationService.currentUserCan(SyncRules.DeleteInstitution, undefined, institution)
    };

    constructor(
        private institutionGroupService: InstitutionGroupService,
        private groupsManagementService: GroupsManagementService,
        private groupService: GroupService,
        private authorizationService: AuthorizationService,
        private authService: AuthenticationService,
        private groupManagementConfigurationService: GroupManagementConfigurationService
    ) {
        try {
            this.displayedFilters = this.groupManagementConfigurationService.displayFilterByOriginAndRole(filterType.institutions, roleList[this.authService.accessLevel]);
        } catch (ex) {
            console.error('erreur getting settings institution component 65 ' + ex);
        }
    }

    ngOnInit(): void {
        this.dataSource = new InstitutionDataSource(this.institutionGroupService);
        this.groupsManagementService.setHeaderTitle('groups-management.institutions');
    }

    public newGroupCallback(response: UntypedFormGroup): Observable<DataEntity> {
        if (response) {
            return this.institutionGroupService.addInstitutionGroup(response.getRawValue());
        }
    }

    public deleteGroupCallback(group: any): Observable<DataEntity> {
        return this.institutionGroupService.deleteInstitutionGroup(group);
    }


    public deleteListCallback(groups: any): void {
        for (const group of groups) {
            this.institutionGroupService.deleteInstitutionGroup(group);
        }
        this.groupService.loadGroups();
    }
}

export class InstitutionDataSource extends DataSource<any> {

    data: BehaviorSubject<InstitutionGroupAddress[]>;

    constructor(private institutionGroupService: InstitutionGroupService) {
        super();
        this.data = new BehaviorSubject<InstitutionGroupAddress[]>(this.institutionGroupService.groups);

        this.institutionGroupService.onInstitutionGroupsChanged.subscribe((data: InstitutionGroupAddress[]) => {
            this.data.next(data);
        });
    }

    get groupService(): InstitutionGroupService {
        return this.institutionGroupService;
    }

    connect(): Observable<any[]> {
        return this.institutionGroupService.onInstitutionGroupsChanged;
    }

    disconnect(): void {
    }
}
