export const dashboard = {
    alwaysShowDashboard: true,
    displayHeaderLink: true,
    displayedWidgets: {
        default: [],
        learner: ['assignments'],
        trainer: ['news', 'assignmentsClosed', 'learnersWithoutAssignments'],
        director: ['news', 'assignmentsClosed', 'learnersWithoutAssignments'],
    },
    newsLimit: 1,
    forbiddenPaths: {
        default: [],
        manager: [/^\/dashboard/]
    }
};
