import {Component, Input} from '@angular/core';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {FullscreenService} from 'fuse-core/services/fullscreen.service';

// TODO ce composant n'est pas au bon endroit, dans ce dossier il y a des composants qui sont utilisés pour executer un typologies, ce composant n'a rien a faire ici

@Component({
    selector: 'app-zoomable',
    templateUrl: './zoomable.component.html',
    styleUrls: ['./zoomable.component.scss']
})
export class ZoomableComponent {

    @Input() config: LessonsConfigurationService;

    @Input() index: number;

    @Input() uuid: string;

    constructor(public fullscreenService: FullscreenService) {
    }

    /**
     * ref of the img to zoom by default is 'imgToZoom'
     * the index value is use when there is multiple img in an activity
     * the uuid is necessary for summary to be sure to add only one id by img when there's multiple one
     */
    get target(): string {
        let target = 'imgToZoom';
        if (this.uuid) {
            target = target + this.uuid;
        }
        if (this.index >= 0) {
            target = target + this.index.toString();
        }
        return target;
    }

}
