import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-resource-upload-modal-error',
    templateUrl: './resource-upload-modal-error.component.html',
    styleUrls: ['./resource-upload-modal-error.component.scss']
})
export class ResourceUploadModalErrorComponent implements OnInit {

    @Output('goBack') goBack: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    ngOnInit() {
    }

    goBackAction() {
      this.goBack.emit();
    }
}
