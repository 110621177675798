import {AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FlashCardInterface} from 'fuse-core/components/flashcard/flash-card.interface';
import {Subject} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

@Component({
    selector: 'app-flashcard',
    templateUrl: './flashcard.component.html',
    styleUrls: ['./flashcard.component.scss']
})
export class FlashcardComponent implements OnInit, AfterViewInit {

    @HostBinding('class.is-playing') showPlayingCss = false;
    @HostBinding('class.is-selected') showSelectedCss = false;

    @Output() state = new EventEmitter<'is-playing' | 'is-selected'>();
    @Input() flashCard!: FlashCardInterface;
    @Input() flashCardStopPlayingOrderSubject?: Subject<string[]>;
    @Input() resetFlashCardSubject?: Subject<boolean>;
    @Input() readBlocked = false;
    @Input() showTitleOnlyIfNoAudio = false;

    private player: HTMLAudioElement;

    constructor() {
    }

    @ViewChild('stream') set playerRef(ref: ElementRef<HTMLAudioElement>) {
        this.player = ref?.nativeElement;
    }

    ngOnInit(): void {
        if (this.resetFlashCardSubject) {
            this.resetFlashCardSubject.pipe(
                filter(() => this.showSelectedCss),
                tap(() => {
                    this.showSelectedCss = false;
                    this.showPlayingCss = false;
                })
            ).subscribe();
        }
    }

    ngAfterViewInit(): void {
        if (this.flashCardStopPlayingOrderSubject && this.player) {
            this.flashCardStopPlayingOrderSubject.pipe(
                filter((idOfFlashCard) => idOfFlashCard.some((id) => id === this.flashCard.id)),
                tap(() => {
                    this.player.pause();
                    this.player.currentTime = 0;
                })
            ).subscribe();
        }
    }

    @HostListener('click')
    onClick($event): void {
        if (this.player) { // If this flashcard contains an audio player
            this.player.pause();
            this.player.currentTime = 0;
            if (this.readBlocked) {
                return;
            } else {
                this.showPlayingCss = true;
                this.player.onended = () => {
                    this.showPlayingCss = false;
                    this.showSelectedCss = true;
                    this.state.emit('is-selected');
                };
            }
            if (this.flashCard?.audio?.uri) {
                this.state.emit('is-playing');
                this.player.play();
            } else {
                // no sound on this flashcard we directly emit sound is finish
                this.showSelectedCss = true;
                this.state.emit('is-selected');
            }
        } else { // If this flashcard does not contain an audio player
            this.showSelectedCss = true;
            this.state.emit('is-selected');
        }
    }

    public reset(): void {
        this.showPlayingCss = false;
        this.showSelectedCss = false;
        this.player.pause();
    }

}