import {Injectable} from '@angular/core';
import {ContextualService} from "@modules/mindmap/core/services/contextual.service";
import {MindmapService} from "@modules/mindmap";

@Injectable({
    providedIn: 'root'
})
export class ServicesInstantiatorService {

    constructor(
        private contextualService: ContextualService,
        private mindmapService: MindmapService,
    ) {
    }
}
