import { Injectable } from '@angular/core';
import {CollectionOptionsInterface} from 'octopus-connect/lib/models/collection-options.interface';
import {observable, Observable, of, ReplaySubject} from 'rxjs';
import {CollectionPaginator, DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {filter, map, mapTo, mergeMap, take, tap} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';
import {License, User, UserSearchDataEntity} from '@modules/groups-management/core/models/user-search-data-entity';
import {InstitutionGroupService} from '@modules/groups-management/core/services/institution-group.service';
import {
    InstitutionDataCollection,
    InstitutionDataEntity,
} from '@modules/groups-management/core/definitions';
import {LicensesService} from '@modules/groups-management/core/services/licenses.service';
import * as _ from 'lodash-es';
import {LicenseManagementDataToSave} from '@modules/groups-management/core/models/license-management';

export enum LicenseTypes {
    class = 'Class',
    institution = 'Institution',
    free = 'Free'
}

export enum TranslatedLicenseType {
    institution = 'groups-management.license_institution',
    class = 'groups-management.license_class',
    free = 'groups-management.license_free',
}

export type LicenseTypeKey = keyof typeof LicenseTypes;

export const LICENSE_TYPES_KEYS = new Map<LicenseTypes, LicenseTypeKey>(
    Object.entries(LicenseTypes).map(([key, value]: [LicenseTypeKey, LicenseTypes]) => [value, key])
);

@Injectable({
    providedIn: 'root'
})
export class LicenseManagementService {
    public institutionsFiltered: InstitutionDataEntity[] = [];
    public institutions: InstitutionDataEntity[] = [];
    public usersEntities: UserSearchDataEntity[];
    public licenseTypes = [LICENSE_TYPES_KEYS.get(LicenseTypes.class), LICENSE_TYPES_KEYS.get(LicenseTypes.institution), LICENSE_TYPES_KEYS.get(LicenseTypes.free)];

    public roles: {[key: string]: number};
    constructor(private communicationCenter: CommunicationCenterService,
                private octopusConnect: OctopusConnectService,
                private institutionsService: InstitutionGroupService,
                private licenseService: LicensesService) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('roles')
            .pipe(tap((roles:  {[key: string]: number}) => this.roles = roles))
            .subscribe();
    }

    /**
     * récupère tous les utilisateurs
     * @param filterOptions
     */
    public getUsersAndPaginator(filterOptions: CollectionOptionsInterface): Observable<{entities: UserSearchDataEntity[], paginator: CollectionPaginator}> {
        if (!filterOptions.filter.roles) {
            filterOptions.filter.roles = [this.roles.trainer, this.roles.director];
        }
        const activitiesPaginated = this.octopusConnect.paginatedLoadCollection('user_search', filterOptions);
        const activitiesPaginatedObs = activitiesPaginated.collectionObservable;
        return activitiesPaginatedObs.pipe(
            map(collection => collection.entities as UserSearchDataEntity[]),
            tap(entities => {
                this.usersEntities = entities;
            }),
            map((entities: UserSearchDataEntity[]) => ({entities, paginator: activitiesPaginated.paginator}))
        );
    }

    /**
     * recupère toutes les institutions
     * @param userEntities
     */
    public loadInstitutions(userEntities: UserSearchDataEntity[]): Observable<DataCollection> {
        const institutionsId = [];
        userEntities.forEach((user) => {
            const institutions = user.get('og_user_node').filter((group) => group.type === 'Institution');
            if (institutions.length) {
                institutionsId.push(...institutions.map((institution) => institution.id)
                    .filter((institutionId) => !institutionsId.includes(institutionId)));
            }
        });

        return this.institutionsService.getInstitutionGroupInUserFromServer(institutionsId.length ? {id: institutionsId} : null)
            .pipe(
                tap((institutionsCollection: InstitutionDataCollection) => {
                    if (institutionsCollection && institutionsCollection.entities) {
                        this.institutions = institutionsCollection.entities;
                    }
                })
            );
    }

    /**
     * retourne les entites d'utilisateurs sous forme de interface User
     * @param userEntities
     */
    public associateUserEntitiesWithUserInterface(userEntities: UserSearchDataEntity[]): User[] {
        return userEntities.map((userEntity: DataEntity) => {
            return  {
                id: +userEntity.id,
                name: userEntity.get('name'),
                firstName: userEntity.get('firstName'),
                lastName: userEntity.get('lastName'),
                mail: userEntity.get('mail'),
                og_user_node: userEntity.get('og_user_node') && userEntity.get('og_user_node')
                    .filter((group) => group.type === 'Institution') || [],
                roles: userEntity.get('roles'),
                rolesInInstitution: null,
                status: userEntity.get('status'),
                license: _.isArray(userEntity.get('licence')) && userEntity.get('licence')[0] || null
            };
        });
    }

    /**
     * chargement de toutes les institutions
     */
    public loadAllInstitutionsWithFilter(): Observable<InstitutionDataEntity[]> {
        const filter = {noDuplicateClassLicense: true};
        const subject = new ReplaySubject<Observable<InstitutionDataEntity[]>>(1);
        this.communicationCenter.getRoom('groups-management')
            .next('institutions$', {
                filter,
                callback: (data: Observable<DataCollection>): void => {
                    subject.next(this.formatInstitutions(data));
                }
            });
        // for reduce to only a simple observable
        return subject.pipe(
            mergeMap(obs => obs),
        );
    }

    /**
     * chargement de toutes les institutions
     */
    public loadAllInstitutions(forceReload?: boolean): Observable<InstitutionDataEntity[]> {
        if (forceReload) {
            this.communicationCenter
                .getRoom('groups-management')
                .next('refresh-institutionList', true);
        }
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionEntitiesList');
    }

    private formatInstitutions(data: Observable<DataCollection>): Observable<InstitutionDataEntity[]> {
        return data.pipe(
            filter(g => !!g),
            map(g => g.entities
                .map((entity: any) => {
                    return entity as InstitutionDataEntity;
                }))
        );
    }

    /**
     * edition d'une license
     * @param license
     * @param change
     */
    public patchLicense(license: { id: string; startDate: number; endDate: number; type: string }, change: {type: string, endDate: number}): Observable<DataEntity> {
        return this.licenseService.patchLicense(license, change);
    }

    /**
     * creation d'une nouvelle institution
     * @param user
     * @param institution
     */
    public createInstitution(userId: string | number, institution: InstitutionDataEntity | { label: string, uai: string }): Observable<InstitutionDataEntity> {
        const data = {
            uid: userId.toString(),
            type: '52',
            label: '',
            uai: null
        };
        if ('label' in institution) {
            data.label = institution.label;
            data.uai = institution.uai;
        } else {
            data.label = institution.get('label');
            data.uai = institution.get('uai');
        }
        return this.institutionsService.createInstitution(data)
            .pipe(
                mergeMap((institutionEntity: InstitutionDataEntity) => this.patchInstitution(userId, institutionEntity)),
                take(1)
            );
    }

    /**
     * creation d'une nouvelle license
     * @param param
     */
    public createLicense(param: { endDate: number; type: string, userId: string }): Observable<DataEntity> {
        return this.licenseService.createLicense(param);
    }

    /**
     * sauvegarde del'edition d'un utilisateur
     * @param user
     * @param params
     */
    public editUser(user: User, params: {institution?: InstitutionDataEntity, updateRoleToDirector?: boolean }): Observable<DataEntity> {
        const oldUserData: DataEntity = this.usersEntities.find((u) => +u.id === +user.id);
        const newUserData: DataEntity = new DataEntity('users', {groups: []}, this.octopusConnect, user.id);
        const newUserDataForRole: DataEntity = new DataEntity('user-role-update', {roles: oldUserData ? oldUserData.get('roles') : ['2', '5']}, this.octopusConnect, user.id);
        // 2 endpoint distinct donc deux sauvegardes à faire possiblement role et/ou institution
        if (params.institution) {
            newUserData.set('groups', oldUserData ? [params.institution,  ...oldUserData.get('og_user_node')
                .filter((group) => group.type !== 'Institution' && +group.id !== +params.institution.id)]
                .map((group) => group.id) : [params.institution.id]);
        }
        if (params.updateRoleToDirector) {
            newUserDataForRole.set('roles', ['2', this.roles.director.toString()]);
        }

        if (params.institution && params.updateRoleToDirector) {
            return newUserData.save(true).pipe(
                mergeMap(() => newUserDataForRole.save(true))
            );
        }
        if (params.institution) {
            return newUserData.save(true);
        }
        if (params.updateRoleToDirector) {
            return newUserDataForRole.save(true);
        }
        return of(null);
    }
    /**
     * sauvegarde de la creation d'un utilisateur
     * @param user
     * @param params
     */
    public createUser(userData: LicenseManagementDataToSave,
                      mustDuplicateInstitution?: boolean): Observable<DataEntity> {
        const data: {label: string, firstName: string, lastName: string, email: string, groups?: number[], role?: number, password: string} = {
          label: userData.firstName + ' ' + userData.lastName,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.mail,
          password: userData.password,
          role: this.roles.trainer,
        };
        if (userData.license === 'institution' && userData.institution && !userData.institution['id']) {
            data.role = this.roles.director;
        }
        return this.octopusConnect.createEntity('user-registration', data).pipe(
            mergeMap((userEntity: DataEntity) => {
                if (userData.institution && !userData.institution['id'] || mustDuplicateInstitution) {
                    return this.createInstitution(userEntity.id,
                        mustDuplicateInstitution ? userData.institution as InstitutionDataEntity : userData.institution as { label: string, uai: string }).pipe(
                        mergeMap((institutionEntity) => {
                            userEntity.set('groups', [institutionEntity.id]);
                            userEntity.type = 'users';
                            return userEntity.save(true);
                        }),
                        mergeMap((userEntity: DataEntity) => {
                            if (userData.license === 'institution') {
                                userEntity.type = 'user-role-update';
                                userEntity.set('roles', ['2', this.roles.director.toString()]);
                                return userEntity.save(true);
                            }
                            return of(userEntity);
                        }),
                        mergeMap((userEntity: DataEntity) => this.createLicense({
                            endDate: userData.endDate,
                            type: userData.license,
                            userId: userEntity.id.toString()
                        })),
                        mapTo(userEntity)
                    );
                } else if (userData.license !== 'free') {
                    return this.patchInstitution(userEntity.id, userData.institution as InstitutionDataEntity)
                        .pipe(
                            mergeMap((institutionEntity: InstitutionDataEntity) => {
                                userEntity.type = 'users';
                                if (userData.institution) {
                                    userEntity.set('groups', [institutionEntity.id]);
                                }
                                return userEntity.save(true);
                            })
                        );
                } else {
                    return of(userEntity);
                }
            })
        );

        return of(null);
    }

    /**
     * return an array with that contain the user corresponding to the id pass
     * @param idUser
     */
    public getUserById(idUser: string): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('users', {id: idUser})
            .pipe(map(collection => collection.entities));
    }

    public patchInstitution(userId: number | string, institution: InstitutionDataEntity): Observable<InstitutionDataEntity> {
        if (institution.get('admins').some((admin) => +admin.uid !== +userId)) {
            institution.set('admins', [...institution.get('admins').slice(),
                {
                    uid: userId.toString(),
                    roles: {
                        4: 'educator',
                        5: 'manager'
                    }
                }]);
        }

        return institution.save(true);
    }

    public detachUserFromInstitution(userId: number | string): Observable<DataEntity> {
        const oldUserData: DataEntity = this.usersEntities.find((u) => +u.id === +userId);
        const newUserData: DataEntity = new DataEntity('users', {groups: []}, this.octopusConnect, userId);
        const groups = oldUserData.get('og_user_node').slice().filter((group) => group.type !== 'Institution').map((group) => group.id);

        newUserData.set('groups', groups);
        return newUserData.save(true);
    }

    public editLicense(licenseId: string | number, data: LicenseManagementDataToSave): Observable<DataEntity> {
        const licenseEntity = new DataEntity('licenses', {type: data.license, endDate: data.endDate}, this.octopusConnect, licenseId);
        return licenseEntity.save(true);
    }
}
