import {ModuleWithProviders, NgModule} from '@angular/core';
import {GroupsListingComponent} from './groups-listing/groups-listing.component';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {RouterModule, Routes} from '@angular/router';
import {GroupsManagementComponent} from './groups-management.component';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseGroupsFormDialogComponent} from '@modules/groups-management/core/groups-listing/groups-form/groups-form.component';
import {FuseGroupsMainSidenavComponent} from '@modules/groups-management/core/groups-listing/sidenavs/main/main.component';
import {FuseGroupsListComponent} from './groups-listing/groups-list/groups-list.component';
import {GroupsListingService} from '@modules/groups-management/core/services/groups-listing.service';
import {LearnerComponent} from './learner/learner.component';
import {GroupComponent} from './group/group.component';
import {WorkgroupComponent} from './workgroup/workgroup.component';
import {TrainerComponent} from './trainer/trainer.component';
import {TrainerService} from '@modules/groups-management/core/trainer/trainer.service';
import {LearnerService} from '@modules/groups-management/core/services/learner.service';
import {GroupService} from '@modules/groups-management/core/services/group.service';
import {WorkgroupService} from '@modules/groups-management/core/workgroup/workgroup.service';
import {GroupsManagementService} from '@modules/groups-management/core/services/groups-management.service';
import {AuthenticationService} from '@modules/authentication';
import {SharedModule} from 'shared/shared.module';
import {InstitutionGroupComponent} from './institution-group/institution-group.component';
import {InstitutionGroupService} from '@modules/groups-management/core/services/institution-group.service';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {JoinGroupComponent} from './join-group/join-group.component';
import {modulesSettings} from '../../../settings';
import {GroupsCustomActionComponent} from './groups-listing/groups-custom-action/groups-custom-action.component';
import {UsersImportComponent} from './users-import/users-import.component';
import {TwoStepsSelectionModule} from 'fuse-core/components/two-steps-selection/two-steps-selection.module';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {GroupsInlineModalComponent} from './groups-listing/groups-inline-modal/groups-inline-modal.component';
import {CreateGroupNewsComponent} from './onboarding/components/create-group-news/create-group-news.component';
import {CreateLeanerNewsComponent} from './onboarding/components/create-leaner-news/create-leaner-news.component';
import {IsCollapsibleFieldDirective} from './groups-listing/directives/is-collapsible-field.directive';
import {ForbiddenPathInterface, IsUserAuthorisedByRoleGuard} from '../../../guards/is-user-authorised-by-role.guard';
import {AlertLearnersComeFromSsoNewsComponent} from './onboarding/components/alert-learners-come-from-sso-news/alert-learners-come-from-sso-news.component';
import {
    AlertTeachersComeFromSsoNewsComponent
} from '@modules/groups-management/core/onboarding/components/alert-teachers-come-from-sso-news/alert-teachers-come-from-sso-news.component';
import {TabSelectorComponent} from '@modules/groups-management/core/institution-management/tab-selector/tab-selector.component';
import {MultiTabsViewComponent} from '@modules/groups-management/core/institution-management/multi-tabs-view/multi-tabs-view.component';
import {IsAtLeastTrainerGuard} from '../../../guards/is-at-least-trainer-guard.service';
import {InstitutionManagementRoutes} from '@modules/groups-management/core/institution-management/institution-management.routes';
import {InstitutionUsersComponent} from '@modules/groups-management/core/institution-management/institution-users/institution-users.component';
import {InstitutionInformationComponent} from '@modules/groups-management/core/institution-management/institution-information/institution-information.component';
import {GroupManagementAuthorizationService} from '@modules/groups-management/core/services/group-management-authorization.service';
import {EditUserComponent} from './institution-management/institution-users/edit-user/edit-user.component';
import {AuthorizationService} from '@modules/authorization';
import {AsyncRules, SyncRules} from '@modules/groups-management/core/models/rules';
import {LicenseManagementComponent} from './license-management/license-management/license-management.component';
import {EditUserAndLicenseComponent} from './license-management/edit-user-and-license/edit-user-and-license.component';
import {IsUserCanEditLicensesGuard} from '@modules/groups-management/core/guards/is-user-can-edit-licences.guard';
import {LicensesService} from '@modules/groups-management/core/services/licenses.service';
import {GlobalAsyncRules} from 'shared/models/';
import {combineLatest, Observable, Subject} from 'rxjs';
import {IsUserCanAccessUsersAdminFeaturesGuard} from '@modules/groups-management/core/guards/is-user-can-acces-users-management.guard';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ChooseGroupComponent} from '@modules/groups-management/core/choose-group/choose-group.component';
import {map, takeUntil} from 'rxjs/operators';
import {CreateTeacherNewsComponent} from './onboarding/components/create-teacher-news/create-teacher-news.component';
import { AssociationLearnerToGroupComponent } from './onboarding/components/association-learner-to-group/association-learner-to-group.component';
import {
    GroupManagementConfigurationService
} from '@modules/groups-management/core/services/group-management-configuration.service';

const routes: Routes = [
    {
        path: 'groups',
        canActivate: [IsUserLogged, IsUserAuthorisedByRoleGuard],
        children: [
            {
                path: 'list',
                component: GroupsManagementComponent,
                children: [
                    {
                        path: 'learners',
                        component: LearnerComponent,
                        data: {
                            icon: 'student_add'
                        },
                    },
                    {
                        path: 'groups',
                        component: GroupComponent,
                        data: {
                            icon: 'class_add'
                        },
                    },
                    {
                        path: 'workgroups',
                        component: WorkgroupComponent,
                        data: {
                            icon: 'workgroup_add'
                        },
                    },
                    {
                        path: 'trainers',
                        component: TrainerComponent,
                        data: {
                            icon: 'trainer_add'
                        }
                    },
                    {
                        path: 'institutions',
                        component: InstitutionGroupComponent,
                        data: {
                            icon: 'institution_add'
                        }
                    },
                    {
                        path: '**',
                        pathMatch: 'full',
                        redirectTo: 'learners'
                    },
                ]
            },
            {
                path: '**',
                pathMatch: 'full',
                redirectTo: 'list'
            }
        ]
    },
    {
        path: 'institution',
        component: GroupsManagementComponent,
        children: [
            {
                path: 'multi',
                component: MultiTabsViewComponent,
                children: [
                    {path: InstitutionManagementRoutes.Users, component: InstitutionUsersComponent, canActivate: [IsUserCanAccessUsersAdminFeaturesGuard]},
                    {path: InstitutionManagementRoutes.Information, component: InstitutionInformationComponent},
                    {
                        path: '**',
                        redirectTo: InstitutionManagementRoutes.Users,
                        pathMatch: 'full'
                    }
                ],
                canActivate: [IsAtLeastTrainerGuard]
            }
        ],
    },
    {
        path: 'license-management',
        canActivate: [IsUserCanEditLicensesGuard],
        component: LicenseManagementComponent
    }
];

@NgModule({
    imports: [
        FuseSharedModule,
        SharedModule,
        RouterModule.forChild(routes),
        TwoStepsSelectionModule,
        MatProgressSpinnerModule
    ],
    declarations: [
        GroupsListingComponent,
        GroupsManagementComponent,
        FuseGroupsFormDialogComponent,
        FuseGroupsMainSidenavComponent,
        FuseGroupsListComponent,
        LearnerComponent,
        GroupComponent,
        WorkgroupComponent,
        TrainerComponent,
        InstitutionGroupComponent,
        JoinGroupComponent,
        GroupsCustomActionComponent,
        UsersImportComponent,
        GroupsInlineModalComponent,
        CreateGroupNewsComponent,
        CreateLeanerNewsComponent,
        IsCollapsibleFieldDirective,
        AlertLearnersComeFromSsoNewsComponent,
        AlertTeachersComeFromSsoNewsComponent,
        TabSelectorComponent,
        MultiTabsViewComponent,
        InstitutionUsersComponent,
        InstitutionInformationComponent,
        EditUserComponent,
        LicenseManagementComponent,
        EditUserAndLicenseComponent,
        ChooseGroupComponent,
        CreateTeacherNewsComponent,
        AssociationLearnerToGroupComponent
    ],
    providers: [],
})
export class GroupsManagementModule {
    private static isMenuSet = false;
    childrenMenu: Array<any> = [];
    private isUserAuthorisedByRoleGuardForbiddenPaths: ForbiddenPathInterface[] = [];
    private userData: DataEntity;
    private logout$ = new Subject<void>();


    constructor(
        private authService: AuthenticationService,
        private dynamicNavigation: DynamicNavigationService,
        groupsService: GroupService,
        private groupsManagementService: GroupsManagementService,
        private communicationCenter: CommunicationCenterService,
        private groupManagementAuthorizationService: GroupManagementAuthorizationService,
        private authorization: AuthorizationService,
        private licensesService: LicensesService,
        private dialog: MatDialog,
        private config: GroupManagementConfigurationService,
    ) {
        this.groupManagementAuthorizationService.activeRulesOnStartup();

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.userData = data;
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter.getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Groupes',
                type: 'ADDED_IN_GROUP',
                icon: 'business',
                text: 'groups-management.groups_notifications_add_in',
                textTransform: (text: string, data: Object) => {
                    return {
                        groupName: data['groupName']
                    };
                },
                action: (data: Object) => {
                    return ['groups', 'list', 'workgroups'];
                }
            });

        this.communicationCenter.getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Groupes',
                type: 'REMOVED_FROM_GROUP',
                icon: 'business',
                text: 'groups-management.groups_notifications_remove',
                textTransform: (text: string, data: Object) => {
                    return {
                        groupName: data['groupName']
                    };
                },
                action: (data: Object) => {
                    return ['groups', 'list', 'workgroups'];
                }
            });

        this.communicationCenter.getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Groupes',
                type: 'GROUP_NAME_CHANGED',
                icon: 'business',
                text: 'groups-management.groups_notifications_rename',
                textTransform: (text: string, data: Object) => {
                    return {
                        oldGroupName: data['oldName'],
                        newGroupName: data['newName']
                    };
                },
                action: (data: Object) => {
                    return ['groups', 'list', 'workgroups'];
                }
            });

        this.communicationCenter.getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Groupes',
                type: 'ADDED_IN_YOUR_GROUP',
                icon: 'business',
                text: 'groups-management.groups_notifications_comrade_added',
                textTransform: (text: string, data: Object) => {
                    return {
                        studentName: data['studentName'],
                        groupName: data['groupName'],
                    };
                },
                action: (data: Object) => {
                    return ['groups', 'list', 'workgroups'];
                }
            });
    }

    static forRoot(): ModuleWithProviders<GroupsManagementModule> {

        return {
            ngModule: GroupsManagementModule,
            providers: [
                GroupsManagementService,
                GroupsListingService,
                TrainerService,
                LearnerService,
                GroupService,
                WorkgroupService,
                InstitutionGroupService
            ]
        };
    }

    private postLogout(): void {
        this.logout$.next();
        this.logout$.complete();
        this.logout$ = new Subject<void>();

        GroupsManagementModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'parameter');
        this.dynamicNavigation.clearMenuItem('level0', 'institution');
        this.dynamicNavigation.clearMenuItem('level0', 'param-child');
        this.communicationCenter.getRoom('IsUserAuthorisedByRoleGuard').next('remove', this.isUserAuthorisedByRoleGuardForbiddenPaths);
        this.communicationCenter.getRoom('global-authorization')
            .next(GlobalAsyncRules.accessToExternalLinkButton, {id: 'groups-management', shouldDisplay: null});
    }

    private getForbiddenPathObject(accessLevel: string): ForbiddenPathInterface[] {
        const forbiddenPaths = this.authService.getRoleAssociatedValueOrDefault(this.groupsManagementService.getForbiddenPaths());
        return forbiddenPaths.map((fp, index) => ({
            identifier: `groups_management_${accessLevel}_${index}`,
            path: fp
        }));
    }

    private postAuthentication(): void {
        const userInstitutionLicences$: Observable<{ license: { type: string } }[]> = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionList');
        const userLicences$ = this.licensesService.currentUserLicenses$

        combineLatest([userInstitutionLicences$, userLicences$])
            .pipe(
                takeUntil(this.logout$),
                map(([userInstitutionLicences, userLicences]) => {
                    const institutionLicences = userInstitutionLicences.map((institutionLicence) => institutionLicence.license.type);
                    const licences = userLicences.map((licence) => licence.type);
                    return [...institutionLicences, ...licences];
                }),
                map(licences => licences.includes('Class') || licences.length === 0), // sans licence ou avec une licence classe
            ).subscribe((isAuthorize) => {
            this.communicationCenter.getRoom('global-authorization')
                .next(GlobalAsyncRules.accessToExternalLinkButton, {id: 'groups-management', shouldDisplay: isAuthorize});
        });

        const isUserAuthorisedByRoleGuardForbiddenPaths = this.getForbiddenPathObject(this.authService.accessLevel);
        if (isUserAuthorisedByRoleGuardForbiddenPaths.length > 0) {
            this.isUserAuthorisedByRoleGuardForbiddenPaths = isUserAuthorisedByRoleGuardForbiddenPaths;
            this.communicationCenter.getRoom('IsUserAuthorisedByRoleGuard').next('add', isUserAuthorisedByRoleGuardForbiddenPaths);
        }

        this.childrenMenu = [];


        if (this.authService.hasLevel(['trainer', 'manager', 'administrator']) &&
            this.groupsManagementService.settingsAccess.institutionsGroupAccess) {
            this.childrenMenu.push({
                'id': 'param-child',
                'title': 'Mes établissements',
                'translate': 'groups-management.institutions',
                'type': 'item',
                'url': '/groups/list/institutions',
            });
        }

        if (this.authService.hasLevel(this.groupsManagementService.isLinkTrainerMustBeDisplayed())) {
            this.childrenMenu.push({
                'id': 'trainers',
                'title': 'Mes enseignants',
                'translate': 'groups-management.my_trainers',
                'type': 'item',
                'url': '/groups/list/trainers',
            });
        }

        if (this.authorization.currentUserCan(SyncRules.SeeAllGroup)
            || this.authorization.currentUserCan(SyncRules.SeeOwnGroup)) {
            this.childrenMenu.push(...[
                {
                    'id': 'param-child-groups',
                    'title': 'Mes classes',
                    'translate': 'groups-management.classes',
                    'type': 'item',
                    'url': '/groups/list/groups',
                }]);
        }

        if (this.authorization.currentUserCan(SyncRules.SeeAllGroup) || this.authorization.currentUserCan(SyncRules.SeeOwnGroup)) {
            this.childrenMenu.push(...[{
                'id': 'param-child-workgroups',
                'title': 'Mes groupes',
                'translate': 'groups-management.workgroups',
                'type': 'item',
                'url': '/groups/list/workgroups',
            }]);
        }

        if (this.authorization.currentUserCan<boolean>(SyncRules.SeeAllLearner)) {
            const item: {id: string, title: string, translate: string, type: string, url: string, icon?: string} = {
                'id': 'param-child',
                'title': 'Mes élèves',
                'translate': 'groups-management.students',
                'type': 'item',
                'url': '/groups/list/learners',
            };
            if (this.config.isDisplayIconInMenuAllowed()) {
                item.icon = 'group';
            }
            this.childrenMenu.push(item);
        }

        if (this.authService.hasLevel(['learner'])) {
            this.childrenMenu = [
                {
                    'id': 'param-child-groups',
                    'title': 'Mes classes',
                    'translate': 'groups-management.classes',
                    'type': 'item',
                    'url': '/groups/list/groups',
                },
                {
                    'id': 'param-child-workgroups',
                    'title': 'Mes groupes',
                    'translate': 'groups-management.workgroups',
                    'type': 'item',
                    'url': '/groups/list/workgroups',
                }];
        }

        const authorizedRoles = modulesSettings.groupsManagement.authorizedRoles;

        if (!GroupsManagementModule.isMenuSet && this.authService.hasLevel(authorizedRoles)) {
            combineLatest([this.authorization.currentUserCan<Observable<boolean>>(AsyncRules.AccessInstitutionUsersManagement),
                this.authorization.currentUserCan<Observable<boolean>>(AsyncRules.AccessInstitutionInformation)])
                .subscribe(([hasAccessInstitutionUsersManagement, hasAccessInstitutionInformation]) => {
                    if (hasAccessInstitutionUsersManagement || hasAccessInstitutionInformation) {

                        this.dynamicNavigation.refreshModuleMenu('level0', {
                            'id': 'institution',
                            'title': 'institution management',
                            'translate': 'navigation.institution-management',
                            'type': 'item',
                            'icon': 'institution',
                            'url': hasAccessInstitutionUsersManagement ? 'institution/multi/users' : 'institution/multi/information',
                        });
                    }

                });

            if (!this.authService.isGAR() || (this.authService.isGAR() && this.authService.isAtLeastTrainer())) {
                this.dynamicNavigation.refreshModuleMenu('level0', {
                    'id': 'parameter',
                    'title': 'Group management',
                    'translate': 'navigation.groups-management',
                    'type': 'collapse',
                    'icon': 'group',
                    'children': this.childrenMenu
                });
            }

            GroupsManagementModule.isMenuSet = true;
        }

        if (this.authorization.currentUserCan(SyncRules.AccessTrainersAndDirectorsManagementPanel)) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                'id': 'trainers-and-directors-management-panel',
                'title': 'trainers and directors management panel',
                'translate': 'groups-management.trainers-and-directors-management-panel',
                'type': 'item',
                'icon': 'group',
                'url': 'license-management',
            });
        }
    }
}
