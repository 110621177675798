
import {mergeMap, tap} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {ActivatedRoute, Router} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {combineLatest, Observable, Subject} from 'rxjs';
import {AddMediaModalComponent} from '@modules/activities/core/editor-components/multimedia-editor/add-media-modal/add-media-modal.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {EntityDataSet} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {DragulaService} from 'ng2-dragula';
import {AddTextModalComponent} from '@modules/activities/core/editor-components/multimedia-editor/add-text-modal/add-text-modal.component';

@Component({
    selector: 'app-multimedia-editor',
    templateUrl: './multimedia-editor.component.html',
    providers: [DragulaService]
})
export class MultimediaEditorComponent implements OnInit {
    public activityForm: UntypedFormGroup;
    public formIsSaving = false;
    public draggableMedias: DataEntity[] = [];
    public renderIsReady = false;

    private activity: DataEntity;
    private activityGranule: DataEntity;
    private activityGranuleId: string;
    private allowedExtensionsByFormat: string[][];
    private corpusResourceMaxSize: string | number;
    private mediaActivityContent: EntityDataSet;

    constructor(
        private activatedRoute: ActivatedRoute,
        private activitiesService: ActivitiesService,
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        private lessonsService: LessonsService,
        private octopusConnect: OctopusConnectService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params) => {
            this.activityGranuleId = params['activityId'];

            this.communicationCenter.getRoom('corpus')
                .getSubject('corpusSettings')
                .subscribe((corpusSettings => {
                    this.allowedExtensionsByFormat = corpusSettings.allowedExtensionsByFormat;
                    this.corpusResourceMaxSize = corpusSettings.fileMaxSize;
                }));

            this.resetComponent();
        });
    }

    /**
     * Patch activity with instruction value, activity metadatas with title value and activity content with media order
     */
    public activityFormOnSubmit(): void {
        this.formIsSaving = true;
        this.activityForm.disable();

        this.activity.set('instruction', this.activityForm.get('instruction').value);

        const metadatas = (<DataEntity>this.activityGranule.getEmbed('metadatas'));
        metadatas.set('title', this.activityForm.get('title').value);

        combineLatest([
            this.activitiesService.replaceCorpusResourcesOfActivity(this.activity, this.draggableMedias.map(media => +media.id)),
            this.activity.save().pipe(tap(updatedActivity => this.activity = updatedActivity)),
            metadatas.save().pipe(
                mergeMap(() =>
                    this.activitiesService.loadActivitiesFromId(this.activityGranule.id.toString()).pipe(
                        tap(activityGranule => this.activityGranule = activityGranule))))
        ]).subscribe(() => {
            this.resetComponent();
        });
    }

    /**
     * Open Add media modal
     * On close, if a resource is provided, add id (as a Corpus resource) to the current activity content and reload component
     */
    public createGenericMedia(): void {
        const dialogRef = this.dialog.open(AddMediaModalComponent, {
            data: {
                allowedMediaTypeList: this.getAllowedMediaTypeList(),
                allowedExtensionByFormat: this.allowedExtensionsByFormat,
                fileMaxSize: +this.corpusResourceMaxSize
            }
        });

        dialogRef.afterClosed().subscribe(resource => {
            if (resource) {
                this.activitiesService.addResourceToActivity(this.activity, resource).pipe(
                    tap(() => {
                        this.resetComponent();
                    }))
                    .subscribe();
            }
        });
    }

    /**
     * Open Add text modal
     * On close, if a resource is provided, add id (as a Corpus resource) to the current activity content and reload component
     */
    public createTextMedia(): void {
        const dialogRef = this.dialog.open(AddTextModalComponent, {
            data: {
                resource: null
            }
        });

        dialogRef.afterClosed().subscribe((textCorpusResource: DataEntity) => {
            if (textCorpusResource) {
                this.activitiesService.addResourceToActivity(this.activity, textCorpusResource).pipe(
                    tap(() => {
                        this.resetComponent();
                    }))
                    .subscribe();
            }
        });
    }

    /**
     * Define if user can add a Text typed resource to this activity
     */
    public canAddTextMedia(): boolean {
        return this.getAllowedMediaTypeList().includes('text');
    }

    /**
     * Define if user can add a resource to this activity
     *
     * @remarks
     * The 'text' type if ignored
     */
    public canAddGenericMedia(): boolean {
        return this.getAllowedMediaTypeList().some(type => type !== 'text');
    }

    /**
     * Remove a resource form the activity_content of current loadded activity
     * @param resource to remove
     */
    public deleteMedia(resource: DataEntity): void {
        this.activitiesService.removeCorpusResourceFromActivity(this.activity, resource)
            .subscribe(() => this.resetComponent());
    }

    /**
     * Return to the parent component
     */
    public goBack(): void {
        // This component need two params : activity's id & 'multimedia' key. that's why there are two "../" in the route.
        this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
    }

    /**
     * Return true if it's useful to save, false if useless.
     * Typically, if user do nothing on the page, there are no need to save the component.
     */
    public isUserShouldSave(): boolean {
        const mediaOrderIsChanged = this.draggableMedias.map(m => +m.id).join(', ') !== this.mediaActivityContent['granule'].map(m => +m.id).join(', ');
        const formIsChanged =
            this.activityForm.get('title') !== this.activityGranule.get('metadatas').title
            || this.activityForm.get('instruction') !== this.activity.get('instruction');
        return mediaOrderIsChanged || formIsChanged;
    }

    /**
     * Generate the Form with default values and validators
     */
    private activityFormInitialize(): void {
        this.activityForm = this.formBuilder.group({
            title: [this.activityGranule.get('metadatas').title, Validators.required],
            instruction: [this.activity.get('instruction')],
        });
        this.formIsSaving = false;
    }

    /**
     * Prepare component when all data are loaded
     */
    private initialize(): void {
        this.activityFormInitialize();
        this.draggableMedias = this.mediaActivityContent['granule'].slice();
        this.renderIsReady = true;
    }

    /**
     * Return a list of acceptable media type provide by filtering possibilities with already selected media types
     *
     * @remarks
     * Do not support order of media.
     * Do not support when same media type are many time accepted in one combination (like ['text', 'image', 'text']).
     *  * In this case, if an 'image' is selected, this will return ['text'].
     *  * If a 'text' is selected, this will return ['image'] and not ['image', 'text'].
     */
    private getAllowedMediaTypeList(): string[] {
        const absoluteMediaCombinationlist = this.lessonsService.getAllowedMediaTypeCombination();
        const actualySelectedMedia: string[] = this.mediaActivityContent.granule.map(media => media.format.label);

        if (actualySelectedMedia.length === 0) {
            return absoluteMediaCombinationlist.flat()
                // unique filter
                .filter((media, index, array) => array.indexOf(media) === index);
        }

        return absoluteMediaCombinationlist
            // Keep only combination if combination accept actually selected medias
            .filter(allowedCombination => actualySelectedMedia.every(alreadySelectedMediaType => allowedCombination.includes(alreadySelectedMediaType)))
            // Remove actually selected medias
            .map(allowedCombination => allowedCombination.filter(allowedMediaType => actualySelectedMedia.includes(allowedMediaType) === false))
            .flat()
            // unique filter
            .filter((media, index, array) => array.indexOf(media) === index);
    }

    /**
     * Reload all data : Activity && medias
     */
    private resetComponent(): void {
        this.activitiesService.loadActivitiesFromId(this.activityGranuleId)
            .subscribe((activityGranule: DataEntity) => {
                this.activityGranule = activityGranule;
                this.activitiesService.loadActivityInterface(this.activityGranule.get('reference').id).subscribe(activity => {
                    this.activity = activity;
                    this.mediaActivityContent = activity.get('activity_content')[0];
                    this.initialize();
                });
            });
    }

    /**
     * Return if user is allowed to edit resource
     *
     * @remarks
     * Only Text resource can be edited
     *
     * @param resource
     */
    canEdit(resource: DataEntity): boolean {
        return resource['format'].label === 'text';
    }

    /**
     * Show Edit media modal
     *
     * @remarks
     * Only text media are supported
     *
     * @param resource
     */
    editMedia(resource: DataEntity): void {
        if (this.canEdit(resource) === false) {
            throw new Error('Resource is not editable');
        }

        const dialogRef = this.dialog.open(AddTextModalComponent, {
            data: {
                resource: resource
            }
        });

        dialogRef.afterClosed().subscribe(() => {
            this.resetComponent();
        });
    }
}
