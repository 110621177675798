import {Component, Input, OnInit} from '@angular/core';
import {ResearchSheetDisplayService} from '@modules/research-sheet/core/research-sheet/research-sheet-display/research-sheet-display.service';
import {ResearchSection, ResearchSectionTemplate, ResearchSheet} from '@modules/research-sheet/core/definitions';
import {ActivatedRoute, Router} from '@angular/router';
import {ResearchSheetService} from '@modules/research-sheet/core/research-sheet.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {ResearchSheetDisplayAddSectionComponent} from '@modules/research-sheet/core/research-sheet/research-sheet-display/research-sheet-display-add-section/research-sheet-display-add-section.component';

@Component({
    selector: 'lms-research-sheet-display-section',
    templateUrl: './research-sheet-display-section.component.html',
    styleUrls: ['./research-sheet-display-section.component.scss']
})
export class ResearchSheetDisplaySectionComponent implements OnInit {
    @Input('data') sectionData: ResearchSection;

    private spaceX: number = 400;
    private spaceY: number = 50;
    private states = {
        validated: 'chat_happy',
        correct: 'chat_sad',
        updated: 'hourglass',
        pending: 'hourglass',
        closed: 'chat_close'
    };

    public showDetails: boolean = false;

    public dialogRef: MatDialogRef<ResearchSheetDisplayAddSectionComponent>;

    constructor(
        private sheetService: ResearchSheetService,
        public displayService: ResearchSheetDisplayService,
        public route: ActivatedRoute,
        public router: Router,
        public dialog: MatDialog,
    ) {}

    ngOnInit(): any {
        if (this.sectionData && !this.states[this.sectionData.state]) {
            this.sectionData.state = 'pending';
        }

        if (this.sheetService.isLastSection(this.sectionData.id)) {
            this.showDetails = true;
        }

        this.sheetService.showDetailsSubject.subscribe((data) => {

            if (data && (data.id !== this.sectionData.id && data.mode ||
                data.id !== this.sectionData.id && !data.mode && !this.sheetService.isLastSection(this.sectionData.id))) {
                this.showDetails = false;
            }

            if (data && data.id !== this.sectionData.id && !data.mode && this.sheetService.isLastSection(this.sectionData.id)) {
                this.showDetails = true;
            }

        });
    }

    public get researchSheet(): ResearchSheet {
        if (this.sectionData) {
            return this.sheetService.getSheet(this.sectionData.researchSheet.toString());
        } else if (this.sheetService.currentSheet) {
            return this.sheetService.currentSheet;
        }

        return null;
    }

    public get sectionsTemplate(): ResearchSectionTemplate[] {
        const templates = [];
        const sheet = this.researchSheet;

        if (sheet && sheet.template) {
            templates.push(...sheet.template.sections);
        }

        return templates;
    }

    public get classes(): {[key: string]: boolean} {
        return {
            validated: this.sectionData && this.sectionData.state === 'validated',
            correct: this.sectionData && this.sectionData.state === 'correct',
            pending: this.sectionData && this.sectionData.state === 'pending',
            closed: this.sectionData && this.sectionData.state === 'closed'
        };
    }

    public get gridXPx(): number {
        return this.sectionData ? this.sectionData.x * this.spaceX : 0;
    }

    public get gridYPx(): number {
        return this.sectionData ? this.sectionData.y * this.spaceY : 0;
    }

    public get icon(): string {
        return this.sectionData ? this.states[this.sectionData.state] : '';
    }

    public get linkType(): string {
        const parent: ResearchSection = this.displayService.getSection(this.sectionData.parent);

        if (parent) {
            if (parent.x === this.sectionData.x) {
                return 'line';
            } else {
                return 'corner';
            }
        }

        return '';
    }

    public get linkSize(): {x: number, y: number, w: number, h: number} {
        const size = {x: 0, y: 0, w: 0, h: 0};
        const parent: ResearchSection = this.displayService.getSection(this.sectionData.parent);

        if (parent) {
            size.x = (parent.x - this.sectionData.x) * this.spaceX + 20;
            size.y = (parent.y - this.sectionData.y) * (this.spaceY + 50);
            size.w = (this.sectionData.x - parent.x) * this.spaceX;
            size.h = (this.sectionData.y - parent.y) * (this.spaceY + 50);
        }

        return size;
    }

    public toggleDetails(): void {
        this.showDetails = !this.showDetails;

        const data = {
            id : this.sectionData.id,
            mode: this.showDetails,
        };

        this.sheetService.showDetailsSubject.next(data);
    }

    public goToSection(): void {
        this.router.navigate(['../' + this.displayService.goToSection(this.sectionData.id)], {relativeTo: this.route});
    }

    public  selectTypeSection(): void {
        this.dialogRef = this.dialog.open(ResearchSheetDisplayAddSectionComponent, {data : {
                sections : this.sectionsTemplate,
                parent: this.sectionData

            }});

        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.router.navigate(['../section/edit'], {relativeTo: this.route});
            }
        });

    }
}
