import { Component, OnInit } from '@angular/core';
import {
    GraphBayardAuthorizationService
} from "@modules/graph-bayard/core/services/graph-bayard-authorization.service";

@Component({
  selector: 'app-single-graph-view',
  templateUrl: './single-graph-view.component.html',
})
export class SingleGraphViewComponent implements OnInit {

  constructor(
      private graphBayardAuthorizationService: GraphBayardAuthorizationService
  ) { }

  ngOnInit(): void {
  }

    public get rolesCanShowBannerInfo(): boolean {
        return this.graphBayardAuthorizationService.isRolesCanShowBannerInfo;
    }

}
