// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-agenda .calendar-container {
  flex: 1;
  margin: 2em;
}
app-agenda .add-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 99;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/agenda/core/agenda/agenda.component.scss"],"names":[],"mappings":"AAEI;EACI,OAAA;EACA,WAAA;AADR;AAII;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;AAFR","sourcesContent":["app-agenda {\n\n    .calendar-container {\n        flex: 1;\n        margin: 2em;\n    }\n\n    .add-button {\n        position: absolute;\n        bottom: 10px;\n        right: 10px;\n        z-index: 99;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
