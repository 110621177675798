import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {IPositionAndStyle} from '@modules/timeline/core/models/position-and-style.models';

@Directive({
    selector: '[appPositionAndStyle]'
})
export class PositionAndStyleDirective implements OnInit {

    constructor(private el: ElementRef) {
    }

    @Input() positionAndStyle?: IPositionAndStyle;
    @Input() position?: number;

    ngOnInit(): void {

        if (this.positionAndStyle) {
            this.el.nativeElement.style.left = this.positionAndStyle.position + '%';
            this.el.nativeElement.style.backgroundColor = this.positionAndStyle.color;
            this.el.nativeElement.style.width = this.positionAndStyle.width + '%';
        }

        if (this.position){
            this.el.nativeElement.style.left = this.position + '%';
        }
    }
}




