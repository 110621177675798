import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

interface State {
    listenIncomingAudioButtonDisabled: boolean;
    recordingButtonDisabled: boolean;
    isRecording: boolean;
    listenUserRecordingDisabled: boolean;
    incomingAudioUrl: string;
    userAudioFileBlob: Blob | null;
    image: {
        uri: string;
        title: string;
    };
}

@Injectable()
export class VoiceRecorderService {
    state$ = new BehaviorSubject<State>({
        listenIncomingAudioButtonDisabled: false,
        recordingButtonDisabled: true,
        isRecording: false,
        listenUserRecordingDisabled: true,
        incomingAudioUrl: '',
        userAudioFileBlob: null,
        image: {
            uri: '',
            title: ''
        }
    });

    get state(): State {
        return this.state$.getValue();
    }

    select<T>(mapFn: (state: State) => T): Observable<T> {
        return this.state$.asObservable().pipe(
            map((state: State) => mapFn(state)),
            distinctUntilChanged()
        );
    }

    setState(newState: Partial<State>): void {
        this.state$.next({
            ...this.state,
            ...newState
        });
    }

    resetState(): void {
        this.setState({
            listenIncomingAudioButtonDisabled: false,
            recordingButtonDisabled: true,
            isRecording: false,
            listenUserRecordingDisabled: true,
            incomingAudioUrl: '',
            userAudioFileBlob: null,
            image: {
                uri: '',
                title: ''
            }
        });
    }
}
