import {takeUntil, filter, mergeMap, take, tap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {LessonsService} from '../../services/lessons.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {ActivitiesService} from '../../../activities.service';
import {DragulaService} from 'ng2-dragula';
import {ActivatedRoute, Router} from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {combineLatest, Observable, Subject, Subscription} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {TranslateService} from '@ngx-translate/core';
import {GenericPluginsService, PluginType} from '@modules/activities/core/services/generic-plugins.service';
import * as _ from 'lodash-es';
import {ToolEditorComponent, ToolEditorDataInterface} from '@modules/activities/core/editor-components/tool-editor/tool-editor.component';
import { ActivityGranule, LessonGranuleEntity} from '@modules/activities/core/models';

@Component({
    selector: 'app-lesson-activities',
    templateUrl: './lesson-activities.component.html',
    providers: [
        DragulaService
    ]
})
export class LessonActivitiesComponent implements OnInit, OnDestroy {
    public selectedLessonContent: ActivityGranule[] = [];
    public orderedContent: ActivityGranule[] = [];
    public btnLeave = true;
    public selectedLesson: LessonGranuleEntity;

    private unsubscribeInTakeUntil = new Subject<void>();
    private dragulaItems = 'itemsBAG';
    private dragulaSubs = new Subscription();
    private corpusService: any;
    private openDividerCreationModal: (DataEntity) => {};

    constructor(
        private octopusConnect: OctopusConnectService,
        public lessonsService: LessonsService,
        public activitiesService: ActivitiesService,
        private dragulaService: DragulaService,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private communicationCenter: CommunicationCenterService,
        public translate: TranslateService,
        private genericPluginsService: GenericPluginsService,
    ) {
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
        this.dragulaSubs.unsubscribe();
    }

    ngOnInit(): void {
        this.dragulaService.createGroup(this.dragulaItems, {});
        this.dragulaSubs.add(this.dragulaService.out(this.dragulaItems)
            .subscribe(({el, container}) => {
                this.lessonsService.btnSave = true;
                /**
                 after drop activity draggable,
                 associate each activity with the right title and right instruction
                 */
                if (this.activitiesService.settings.canOverrideStepMetadatasInLesson) {
                    this.activitiesService.overrideActivityMetadatas(this.orderedContent);
                }
            })
        );

        this.communicationCenter
            .getRoom('corpus')
            .getSubject('corpusService')
            .subscribe((corpusService) => this.corpusService = corpusService);

        this.communicationCenter
            .getRoom('corpus')
            .getSubject('openDividerCreationModal')
            .subscribe((openDividerCreationModal) => this.openDividerCreationModal = openDividerCreationModal);

        if (this.corpusService && !this.corpusService.formats) { // get granule formats
            this.corpusService.loadFormats();
        }

        this.lessonsService.getLessonObs(this.lessonsService.selectedLessonId).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(lesson => this.updateCurrentLesson(lesson));

        this.lessonsService.onLessonUpdate.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((lesson) => this.updateCurrentLesson(lesson));
    }

    private updateCurrentLesson(currentLesson: LessonGranuleEntity): void {
        // todo: set selected lesson in each service, in future refacto need to have just one variable
        this.lessonsService.currentLesson = currentLesson;
        this.activitiesService.currentLesson = currentLesson;
        this.selectedLesson = currentLesson;
        if (this.selectedLesson['LocaleDateCreated'] === undefined) { // add localeDate if not exist
            this.selectedLesson['LocaleDateCreated'] = this.lessonsService.localeDate(+this.selectedLesson.attributes.metadatas.created);
            this.selectedLesson['LocaleDateChanged'] = this.lessonsService.localeDate(+this.selectedLesson.attributes.metadatas.changed);
        }
        const lessonContentArray = currentLesson.get('reference');
        this.activitiesService
            .setActivitiesAfterLoaded(lessonContentArray).pipe(
            take(1))
            .subscribe((entities) => {
                this.activitiesService.activitiesArray = [];
                entities.forEach((entity, index) => {
                    // set activitiesArray with activities fetch from current lesson
                    this.activitiesService.activitiesArray[index] = entity;
                    /**
                     *the display of activities is modified to display the title of the activity that the user has to modify.
                     * Nothing is saved for the moment
                     */
                    if (this.activitiesService.settings.canOverrideStepMetadatasInLesson && this.activitiesService.metadatasUsedForOverride.length) {
                        entity.attributes.metadatas.title = _.get(this.activitiesService.metadatasUsedForOverride[index], 'title', '');
                        if (entity.get('format').label === 'activity' && typeof entity.attributes.reference !== 'string') {
                            entity.attributes.reference.instruction = _.get(this.activitiesService.metadatasUsedForOverride[index], 'instruction', '');
                        }
                    }
                    this.selectedLessonContent[index] = entity;
                });
                this.orderedContent = this.selectedLessonContent.slice();
                if (this.activitiesService.settings.canOverrideStepMetadatasInLesson) {
                    this.activitiesService.overrideActivityMetadatas(this.orderedContent, true);
                }
            });
    }

    public openMediaCreationModal(item?: DataEntity): void {
        if (this.openDividerCreationModal) {
            this.openDividerCreationModal(item);
        }
    }

    public chooseActivity(): void {
        this.activitiesService.setSelectionMode(this.router.routerState.snapshot.url);
        this.router.navigate(['activities'], {relativeTo: this.route});
    }

    public chooseResource(): void {
        this.communicationCenter.getRoom('corpus').next('selectionMode', this.router.routerState.snapshot.url);
        this.router.navigate(['resources'], {relativeTo: this.route});
    }

    public deleteActivity(item: any): void {
        let activityIndex = this.selectedLessonContent.findIndex((element) => +element.id === +item.id);
        if (activityIndex > -1) {
            this.selectedLessonContent.splice(activityIndex, 1);
        }

        activityIndex = this.orderedContent.findIndex((element) => +element.id === +item.id);
        if (activityIndex > -1) {
            this.orderedContent.splice(activityIndex, 1);
        }
        /**
         * need to remove update the array of title and wording if we delete an activity
         */
        if (this.activitiesService.settings.canOverrideStepMetadatasInLesson) {
            this.activitiesService.overrideActivityMetadatas(this.orderedContent);
        }
        this.lessonsService.removeActivityFromLesson(item);
    }

    /**
     * if case user launched a sub lesson,
     * need to load all the activities in it
     * then initialize activities service with the downloaded activities
     * @param {DataEntity} row
     */
    public navigateToMainActivity(row: DataEntity): void {
        this.activitiesService.internalLaunchPreview(row.id);
    }

    public saveLesson(): void {

        this.btnLeave = false;

        this.selectedLessonContent = this.orderedContent; // update selectedLessonContent

        const lessonContent = this.orderedContent.map((activity) => {
            if (activity) {
                const activityData = activity.attributes;
                activityData['id'] = activity.id.toString();
                return activityData;
            }
        });

        this.selectedLesson.set('reference', <any> lessonContent);
        this.lessonsService
            .saveLessonActivities(+this.selectedLesson.id).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((entity) => {
                this.btnLeave = true;
                this.lessonsService.btnSave = false;
                /**
                 * update each activity and sub content in current lesson
                 * with the new title and wording
                 */
                if (this.activitiesService.settings.canOverrideStepMetadatasInLesson) {
                    this.activitiesService.editSubLessonContent(entity).subscribe();
                }
                this.lessonsService.saveMetadatas(this.selectedLesson.id, [], 'lesson')
                    .subscribe();
            });
    }

    public exit(): void {
        this.lessonsService.clearTempActivities();
        // TODO menu
        // let pathToSetting = 'default';
        // if (modulesSettings.mainMenu.displayMenu[this.authenticationService.accessLevel]){
        //     pathToSetting = this.authenticationService.accessLevel;
        // }
        // if (modulesSettings.mainMenu.displayMenu[pathToSetting]['level0'].indexOf('lessonsDeployable') !== -1 ||
        //     modulesSettings.mainMenu.displayMenu[pathToSetting]['level0'].indexOf('lessonsDeployableWithoutCommunity') !== -1) {
        //     this.router.navigate(['lessons', 'list', 'lessons']);
        // } else {
        //     this.router.navigate(['lessons', 'list']).then((event) => {
        //         this.lessonsService.selectedIndexChange(0);
        //     });
        // }
    }

    public get btnSave(): boolean {
        return this.lessonsService.btnSave;
    }

    public getContentTypeIcon(data): object {
        return this.activitiesService.getContentTypeIcon(data);
    }

    /**
     * Create a child multimedia activity for current lesson & redirect to the editor of it.
     */
    public addMultimediaActivity(): void {
        this.lessonsService.addChildMultimediaActivity(this.selectedLesson)
            .subscribe((multimediaActivity: DataEntity) => {
                this.router.navigate(['multi', multimediaActivity.id], {relativeTo: this.route});
            });
    }

    public displayAddButton(buttonLabel: string): boolean {
        return this.lessonsService.getAvailableAddButtons().includes(buttonLabel);
    }

    public editSubLesson(subLessonGranule: DataEntity): void {
        this.router.navigate(['multi', subLessonGranule.id], {relativeTo: this.route});
    }

    /**
     * @deprecated should be done in the service && duplicated in {@link LessonEditionService}
     */
    public addToolToLesson(): void {
        const getTools = () => this.genericPluginsService.getPluginsByType(PluginType.lessonTool);
        this.dialog.open(ToolEditorComponent, {data: {getTools: getTools}}).beforeClosed().pipe(
            filter((defaultValues: ToolEditorDataInterface) => !!defaultValues),
            mergeMap((defaultValues: ToolEditorDataInterface)  =>
                this.activitiesService.createToolActivity(this.genericPluginsService.pluginTypeToActivityType[defaultValues.tool.setting.pluginType], {
                    activity: {instruction: defaultValues.instruction, config: {tool: defaultValues.tool.toolIdentifier}},
                    metadatas: {title: defaultValues.title}
                }).pipe(
                    mergeMap((toolActivity) => this.internalAddToolToLesson(toolActivity, defaultValues)
                ))
            )
        ).subscribe();
    }

    public internalAddToolToLesson(toolActivity: ActivityGranule, defaultValues: ToolEditorDataInterface): Observable<DataEntity> {
        return this.activitiesService.loadActivitiesFromId(toolActivity.id.toString()).pipe(
            tap(() => {
                this.lessonsService.setActivitiesOfSelectedLesson([...this.selectedLessonContent, toolActivity]);
                if (this.activitiesService.settings.canOverrideStepMetadatasInLesson) {
                    this.activitiesService.metadatasUsedForOverride.push({
                        id: toolActivity.id,
                        instruction: defaultValues.instruction,
                        title: defaultValues.title
                    });
                }
            })
        );
    }

    /**
     * Open the modal editor of a tool activity and, when the modal will be close, update the activity with the modal results
     * @param item
     */
    public editToolActivity(item: ActivityGranule): void {
        const getTools = () => this.genericPluginsService.getPluginsByType(PluginType.lessonTool);
        const metadatas = <DataEntity>item.getEmbed('metadatas');
        this.activitiesService.loadActivityInterface(<any> item.get('reference')).pipe(
            take(1),
            mergeMap(activityInterface =>
                this.dialog.open(ToolEditorComponent, {
                    data: {
                        getTools: getTools,
                        title: metadatas.get('title'),
                        instruction: activityInterface.get('instruction'),
                        tool: getTools().find(t => t.toolIdentifier === (<any> activityInterface.get('config')).tool)
                    }
                }).beforeClosed().pipe(
                    filter((updatedValues) => !!updatedValues),
                    mergeMap((updatedValues) => {
                        metadatas.set('title', updatedValues.title);
                        activityInterface.set('instruction', updatedValues.instruction);
                        return combineLatest([metadatas.save(), activityInterface.save()]);
                    }),
                )),
            mergeMap(() => this.activitiesService.loadActivitiesFromId(item.id.toString())),
            tap((activity) =>
                // Not really sure for doing it like this but
                // - we don't want to save the lesson
                // - we don't want to loose the changes not saving yet
                // - we want to have an up to date activity
                this.selectedLessonContent[this.selectedLessonContent.indexOf(item)] = activity
            )
        ).subscribe();
    }

    /**
     * Obtain true if it's an activity and an activity of type `tool`
     * @param item
     */
    public isToolActivity(item: DataEntity): boolean {
        return _.get(item, 'attributes.format.label') === 'activity' && _.get(item, 'attributes.metadatas.typology.label') === 'Tool';
    }

    public isDividerActivity(item: DataEntity): boolean {
        return _.get(item, 'attributes.format.label') === 'divider';
    }

    public isLessonActivity(item: DataEntity): boolean {
        return _.get(item, 'attributes.format.label') === 'lesson';
    }

    public edit(item: ActivityGranule): void {
        if (this.isToolActivity(item)) {
            this.editToolActivity(item);
        } else if (this.isDividerActivity(item)) {
            this.openMediaCreationModal(item);
        } else if (this.isLessonActivity(item)) {
            this.editSubLesson(item);
        }
        // TODO gérer l'edition générique de titre && instruction
        // else if (this.activitiesService.settings.canOverrideStepMetadatasInLesson) {
        //    this.editGenericStep(item);
        //}
    }

    public canEdit(item: DataEntity): boolean {
        // TODO gérer l'edition générique de titre && instruction
        // return this.activitiesService.settings.canOverrideStepMetadatasInLesson
        //     || this.isToolActivity(item)
        //     || this.isDividerActivity(item)
        //     || this.isLessonActivity(item);
        return this.isToolActivity(item)
            || this.isDividerActivity(item)
            || this.isLessonActivity(item);

    }
}

