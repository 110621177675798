// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
}

.wall-item {
  width: 100%;
}

.walls-main-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.mc {
  flex: 1;
  position: relative;
}

.walls-container {
  padding: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.header {
  background: rgba(48, 87, 146, 0.1);
  color: #305792;
  font-size: 22px;
  line-height: 75px;
  padding-left: 31px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/ideas-wall/core/ideas-walls-list/ideas-walls-list.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,sBAAA;AACF;;AAEA;EACE,OAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,cAAA;AACF;;AAEA;EACE,kCAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AACF","sourcesContent":[":host {\n  position: relative;\n}\n\n.wall-item {\n  width: 100%;\n}\n\n.walls-main-container {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n}\n\n.mc {\n  flex: 1;\n  position: relative;\n}\n\n.walls-container {\n  padding: 20px;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  overflow: auto;\n}\n\n.header {\n  background: rgba(48, 87, 146, 0.1);\n  color: #305792;\n  font-size: 22px;\n  line-height: 75px;\n  padding-left: 31px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
