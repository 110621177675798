// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-event-controls .event-controls-container {
  display: flex;
}
app-event-controls .event-controls-container .mat-icon-button {
  height: 20px;
  width: 20px;
  margin-left: 0;
}
app-event-controls .event-controls-container .mat-icon-button .mat-icon {
  color: #fff;
  font-size: 14px;
  min-height: 14px;
  min-width: 14px;
  height: 14px;
  width: 14px;
}
app-event-controls .event-controls-container .mat-icon-button .mat-icon svg {
  fill: #fff;
  height: 14px;
  width: 14px;
}
app-event-controls .event-controls-container .mat-icon-button .mat-icon svg path {
  fill: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/agenda/core/event-controls/event-controls.component.scss"],"names":[],"mappings":"AACI;EAEI,aAAA;AADR;AAGQ;EACI,YAAA;EACA,WAAA;EACA,cAAA;AADZ;AAGY;EACI,WAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;AADhB;AAGgB;EACI,UAAA;EACA,YAAA;EACA,WAAA;AADpB;AAGoB;EACI,UAAA;AADxB","sourcesContent":["app-event-controls {\n    .event-controls-container {\n\n        display: flex;\n\n        .mat-icon-button {\n            height: 20px;\n            width: 20px;\n            margin-left: 0;\n\n            .mat-icon {\n                color: #fff;\n                font-size: 14px;\n                min-height: 14px;\n                min-width: 14px;\n                height: 14px;\n                width: 14px;\n\n                svg {\n                    fill: #fff;\n                    height: 14px;\n                    width: 14px;\n\n                    path {\n                        fill: #fff;\n                    }\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
