import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {IdeasWallDdService} from './ideas-wall-dd.service';

@Directive({
    selector: '[ddColumn]'
})
export class ColumnDirective implements OnInit, OnDestroy {

    @Input('columnNum') columnNum: number;

    constructor(
        private element: ElementRef,
        private ddService: IdeasWallDdService
    ) { }

    ngOnInit(): void {
        this.ddService.registerColumn(this.element, this.columnNum);
    }

    ngOnDestroy(): void {
        this.ddService.unRegisterColumn(this.columnNum);
    }
}
