import {Injectable} from '@angular/core';
import {CommunicationCenterService} from "@modules/communication-center";
import {Subject} from "rxjs";

/**
 * This service's scope is to :
 *   - receive messages on rooms 'contextualActions', 'contextualConditions' and 'contextualData'
 *   - expose Observables for the components of the module to subscribe to
 *   - have one Observable per action, condition and data handled in the module
 *   - have no other logic implemented. The components and services of the module handle the logic
 */
@Injectable({
    providedIn: 'root'
})
export class ContextualService {
    public actionLessonAssign$ = new Subject<void>();
    public actionLessonPlay$ = new Subject<void>();
    public actionLessonSummary$ = new Subject<void>();

    public conditionLessonAvailable$ = new Subject<(boolean) => void>();
    public conditionSummaryAvailable$ = new Subject<(boolean) => void>();
    public conditionAssignmentDialogOpen$ = new Subject<(boolean) => void>();

    public dataAssignmentFirstAvailableName$ = new Subject<(value: string) => void>();
    public dataAssignmentFirstValidAssigneeName$ = new Subject<(value: string) => void>();

    constructor(
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('contextualActions')
            .getSubject('lesson')
            .subscribe((action) => {
                switch (action) {
                    case 'assign':
                        this.actionLessonAssign$.next();
                        break;
                    case 'play':
                        this.actionLessonPlay$.next();
                        break;
                    case 'summary':
                        this.actionLessonSummary$.next();
                        break;
                }
            });

        this.communicationCenter
            .getRoom('contextualConditions')
            .getSubject('lesson')
            .subscribe(({condition, callback}) => {
                switch (condition) {
                    case 'available':
                        this.conditionLessonAvailable$.next(callback);
                        break;
                }
            });

        this.communicationCenter
            .getRoom('contextualConditions')
            .getSubject('summary')
            .subscribe(({condition, callback}) => {
                switch (condition) {
                    case 'available':
                        this.conditionSummaryAvailable$.next(callback);
                        break;
                }
            });

        this.communicationCenter
            .getRoom('contextualConditions')
            .getSubject('assignment')
            .subscribe(({condition, callback}) => {
                switch (condition) {
                    case 'dialogOpen':
                        this.conditionAssignmentDialogOpen$.next(callback);
                        break;
                }
            });

        this.communicationCenter
            .getRoom('contextualData')
            .getSubject('assignment')
            .subscribe(({data, callback}) => {
                switch (data) {
                    case 'firstAvailableName':
                        this.dataAssignmentFirstAvailableName$.next(callback);
                        break;
                    case 'firstValidAssigneeName':
                        this.dataAssignmentFirstValidAssigneeName$.next(callback);
                        break;
                }
            });
    }

    /**
     * Notifies the contextual system that it needs to reevaluate conditions
     * following a data update.
     */
    public onConditionUpdate(): void {
        this.communicationCenter
            .getRoom('contextualUpdate')
            .next('condition', true);
    }
}
