import {Component, ElementRef} from '@angular/core';
import {DragulaService} from 'ng2-dragula';
import * as _ from 'lodash-es';
import {brand} from '../../../../../settings';
import {Observable, of} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../../activities.service';
import {LessonsService} from '../../lessons/services/lessons.service';
import {BaseActivityComponent} from '../base-activity.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {LessonNavigationService} from '../../lesson-navigation.service';
import {ContextualService} from '@modules/activities/core/services/contextual.service';
import {OrderingActivityGranule} from '@modules/activities/core/models/activities/typologies/ordering-activity.granule';

@Component({
    selector: 'app-ordon',
    templateUrl: './ordon.component.html',
    providers: [DragulaService]
})
export class OrdOnComponent extends BaseActivityComponent<OrderingActivityGranule>{
    public answers = [];
    public answerValidated = false;
    public brand = brand;
    public canMove = 1;
    public correctAnswer = [];
    public options: any;
    public orderList = [];
    public recordAnswer: any[] = [];
    public recordAnswerWrong: boolean;
    public shuffledOptions: any[] = [];
    public userOrderList: any[] = [];

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected communicationCenter: CommunicationCenterService,
        protected contextualService: ContextualService,
        protected lessonsService: LessonsService,
        private dragulaService: DragulaService,
        private elementRef: ElementRef,
        protected lessonNavigationService: LessonNavigationService
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, contextualService, lessonNavigationService);

        this.dragulaService.drop().subscribe(({source, target}) => {
            this.onDragChange();
        });

        this.dragulaService.createGroup('bag-1',
            {
                // Ce code n'est pas compatible avec la nouvelle version de dragula et je n'ai pas trouvé de solution pour le moment
                // moves: (el: any, container: any, handle: any): any => {
                //     return !el.classList.contains('cannotMove');
                // }
            }
        );
    }

    protected setContentData(activityAttributes): void {
        this.activityType = activityAttributes.metadatas.typology.label;
        if (activityAttributes.reference.config) {
            this.isVertical = (activityAttributes.reference.config.direction === 'vertical') || true;
            // Ce code n'est pas compatible avec la nouvelle version de dragula et je n'ai pas trouvé de solution pour le moment
            // this.dragulaService.find('bag-1').options.direction = activityAttributes.reference.config.direction;
        }
        this.referenceActivityGranule = activityAttributes.reference;
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.options = this.referenceActivityGranule.activity_content.answers;
        if (this.options.length) {
            const options = this.options;
            this.options = [];
            options.forEach((elem, index) => {
                elem.orderno = index + 1;
                elem.errorstat = 0;
                this.options[index] = elem;
            });
        } else {
            this.options = [];
        }
        const answers = this.referenceActivityGranule.activity_content.answers;
        if (answers.length) {
            answers.forEach((elem, index) => {
                this.answers[index] = elem.id;
            });
        }
        this.orderList = this.options;
        this.orderList = this.derange(this.orderList);
        this.shuffledOptions = this.orderList.slice();
        this.userOrderList = _.cloneDeep(this.orderList);
        this.changeOrderNo();
        if (!this.isActivityEmbedded) {
            this.loadUserSave();
        }
        this.activityStepIndex = this.lessonsService.activityIndex;

        if (this.lessonsService.isTrainerSeeCorrection()) {
            this.testAnswer = true;
        }
    }

    protected setAnswer(): void {
        if (this.userSave?.get('userActivity')?.entitySave?.answers && !this.displayForSummary) {
            const answers = this.userSave.get('userActivity').entitySave.answers;
            this.orderList = answers.map((answer) => this.options.find((option) => option.id === answer.id));
            this.userOrderList = _.cloneDeep(this.orderList);
            this.shuffledOptions = _.cloneDeep(this.orderList);
            this.changeOrderNo();
            if (!this.lessonsService.isTrainerSeeCorrection()) {
                this.checkAnswer();
                this.activitiesService.userAnswer.next(answers);
                this.testAnswer = true;
            }
        }
    }

    private onDragChange(): void {
        const elems = this.elementRef.nativeElement.querySelectorAll('.counting-circle');
        elems.forEach((elem, index) => {
            elem.innerText = index + 1;
        });
        // re order
        const recordoptions = this.shuffledOptions;
        this.userOrderList = [];
        const optionsTemp = [];
        recordoptions.forEach(elem => {
            optionsTemp[elem.id] = elem;
        });
        const elemids = this.elementRef.nativeElement.querySelectorAll('.orderlist-id');
        elemids.forEach((elem, index) => {
            const ansid = elem.innerText.trim();
            if (optionsTemp[ansid]) {
                this.userOrderList[index] = optionsTemp[ansid];
            }
        });
        this.answerValidated = true;
        // to enabled the button Tester Ma Reposnse only when User has interacted.
        this.activitiesService.doesUserResponsed.next(true);
        this.onOptionChange(true);
    }

    private changeOrderNo(): void {
        const orderList = _.cloneDeep(this.orderList);
        orderList.forEach((ele, index) => {
            ele.orderno = index + 1;
            this.orderList[index] = ele;
        });
    }

    protected checkAnswer(): void {
        let correctCnt = 0;
        this.answerStatus = 2;
        // let elems = this.elementRef.nativeElement.querySelectorAll('.orderlist-id');
        this.userOrderList.forEach((elem, index) => {
            const ansid = elem.id;
            this.correctAnswer[ansid] = 3;
            if (this.answers[index] !== ansid) {
                this.correctAnswer[ansid] = 2;

            } else if (this.answers[index] === ansid) {
                this.correctAnswer[ansid] = 1;
                correctCnt++;
            }
        });
        if (this.orderList.length === correctCnt) {
            this.answerStatus = 1;
        } else if (this.answerValidated) {
            this.answerStatus = 3;
        }

        this.recordAnswer = _.cloneDeep(this.correctAnswer);
        this.canMove = 0;
        /* state message functionality */
        if (this.lessonsService.isLessonTest() || this.lessonsService.isLessonTraining()) {
            this.displayFeedback = this.activitiesService.settings.displayFeedback
                && this.answerStatus !== 1
                && this.lessonsService.isLessonTraining()
                && !this.lessonsService.isAtLeastTrainer();
            this.recordAnswerWrong = this.displayFeedback;
        }
        // for lesson answerStatus

        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});

        if (this.testAnswer) {
            this.activitiesService.checkAnswers.next({lessonCorrected: true});
        }

    }

    public answerState(answer: any): string {
        if (this.displaySolution || this.testAnswer) {
            switch (this.correctAnswer[answer.id]) {
                case 1:
                    return 'correctAnswer';
                case 2:
                    return 'wrongAnswer';
                case 3:
                    return 'answerMissed';
            }
        } else if (this.correctAnswer[answer.id]) {
            return 'validated';
        }

        return '';
    }

    protected getGrade(): {
        newGrade: number,
        oldGrade: number
    } {
        let oldGrade;
        let grade;
        let oldUserCorrectAnswer = 0;
        let userCorrectAnswer = 0;

        if (this.userSave) {
            this.userSave.get('userActivity').entitySave.answers.forEach((elem, index) => {
                const answerId = elem.id;
                if (this.answers[index] === answerId) {
                    oldUserCorrectAnswer += 1;
                }
            });
        }

        this.userOrderList.forEach((elem, index) => {
            const answerId = elem.id;
            if (this.answers[index] === answerId) {
                userCorrectAnswer += 1;
            }
        });

        oldGrade = oldUserCorrectAnswer / this.answers.length;
        grade = userCorrectAnswer / this.answers.length;

        return {
            newGrade: grade,
            oldGrade: oldGrade
        };
    }

    protected saveAnswer(): Observable<any> {
        const orderList = this.userOrderList.map((element) => element.id);
        return of(orderList);
    }

    private derange(arr): Array<any> {
        const c = arr.slice();
        if (c.length < 2) {
            return c;
        }

        let result = [];
        let idx, i, iLen;
        let lastMoved = false;

        for (let i = 0, iLen = c.length - 1; i < iLen; i++) {

            if (c.length === 2 && !lastMoved) {
                result = result.concat(c.reverse().splice(0, 2));
                break;
            }

            do {
                idx = Math.random() * c.length | 0;

            } while (arr.indexOf(c[idx]) === result.length);

            result.push(c.splice(idx, 1)[0]);
            lastMoved = lastMoved || idx === c.length;
        }

        if (c.length) {
            result.push(c[0]);
        }
        return result;
    }

    protected seeAnswerSolution(): void {
        if (!this.options) {
            return;
        }
        this.options.forEach((elem, index) => {
            this.correctAnswer[elem.id] = 1;
        });
        this.orderList = [];
        this.orderList = this.referenceActivityGranule.activity_content.answers;
        this.changeOrderNo();
        this.displaySolution = true;
        this.displayFeedback = false;
    }

    protected reset(resetAllSubscribe: boolean = false, type: string = null): Observable<boolean> {
        this.recordAnswerWrong = false;
        this.orderList = _.cloneDeep(this.shuffledOptions);
        this.userOrderList = _.cloneDeep(this.shuffledOptions);
        this.recordAnswer = [];
        this.correctAnswer = [];
        this.canMove = 1;
        if (this.lessonsService.isAtLeastTrainerAndAssignmentExist) {
            this.canMove = 0;
        }
        this.answerValidated = false;
        this.changeOrderNo();
        return super.reset(resetAllSubscribe, type);
    }

    protected reviewAnswer(): void {
        this.canMove = 1;
        this.orderList = [];
        this.orderList = _.cloneDeep(this.userOrderList);
        this.changeOrderNo();
        this.correctAnswer = _.cloneDeep(this.recordAnswer);
        this.answerValidated = false;
        this.displaySolution = false;
        this.displayFeedback = this.recordAnswerWrong = this.answerStatus !== 1
            && this.lessonsService.isLessonTraining()
            && !this.lessonsService.isAtLeastTrainer()
            && this.activitiesService.settings.displayFeedback;
    }

    public get isPreview(): boolean {
        return this.lessonsService.isLessonTest() || this.lessonsService.isLessonTraining();
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}
