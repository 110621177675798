import {Component, OnInit} from '@angular/core';
import {EmbeddedActivitiesService} from '@modules/activities/core/services/embedded-activities.service';

@Component({
  selector: 'app-embedded-activities',
  templateUrl: './embedded-activities.component.html',
  styleUrls: ['./embedded-activities.component.scss']
})
export class EmbeddedActivitiesComponent implements OnInit {
    constructor(private embeddedActivitiesService: EmbeddedActivitiesService) {
   }
   ngOnInit() {
   }
}
