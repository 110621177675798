import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {GraphConfig} from 'fuse-core/components/graph/graph-mixed/graph.config';
import {GraphUbolinoService} from '../../services/graph-ubolino.service';
import {delay, filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ProgressData, ProgressDotDetail} from '@modules/graph-ubolino/core/model/progress-data';
import {CustomTooltipModalContentSettings} from 'fuse-core/components/graph/graph-details-modal/custom-tooltip.setting';
import * as moment from 'moment';
import {rgbOpacity} from 'shared/utils';
import * as _ from 'lodash-es';
import {GraphUbolinoTranslateService} from '@modules/graph-ubolino/core/services/graph-ubolino-translate.service';
import {FakeStepperOptions} from '@modules/graph-ubolino/core/component/fake-stepper/fake-stepper.component';

const simpleColor = 'rgb(78,117,255)';
// const firstLayerColor = 'rgb(66,212,53)';
// const secondLayerColor = 'rgb(156,255,78)';
// const thirdLayerColor = 'rgb(0,144,205)';
// const fourthLayerColor = 'rgb(0,209,255)';

@Component({
  selector: 'app-own-progress-graph',
  templateUrl: './own-progress-graph.component.html',
})
export class OwnProgressGraphComponent implements OnInit, OnDestroy {

    public graphConfigObs: ReplaySubject<GraphConfig> = new ReplaySubject<GraphConfig>(1);
    public isReady = false;
    public challenge: {
        averageScore: number;
        count: number;
        lastScore: number;
        lastDate: string;
    } = null;
    /**
     * Cache le loader si le service n'est pas encore pret
     */
    public showLoader = false;
    private unsubscribeInTakeUntil = new Subject<void>();
    /**
     * Aide a déterminer le temps entre les steps du loader
     * @private
     */
    private loaderShouldEnd = false;
    /**
     * Défini les steps du loader, le temps entre les stepsn, etc.
     */
    public loaderOptions: Partial<FakeStepperOptions> = {
        loop: false,
        interval: stateIndex => (this.loaderShouldEnd === false || stateIndex <= 2 ? 4000 : 1000),
        states: ['fake_stepper.state_1', 'fake_stepper.state_2', 'fake_stepper.state_3'],
        onEnd: new ReplaySubject(1)
    };
    private fakeData: any;

    constructor(private graphUbolinoService: GraphUbolinoService, public graphUbolinoTranslateService: GraphUbolinoTranslateService) {
    }

    ngOnInit(): void {const fakeQuestionSetsLimit = Math.ceil(Math.random() * 10) + 5;
        this.graphUbolinoService.isReady
            .pipe(
                filter(isReady => !!isReady),
                tap(() => this.showLoader = true),
                tap(() => this.graphUbolinoService.graphDataArePending.subscribe(() => this.isReady = false)),
                mergeMap(() => this.graphUbolinoService.getOwnProgressGraphData()),
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data) => {
            this.loaderShouldEnd = true;
            this.generateComplexGraph(data);
            // this.setChallengeStats(data);
            // Ne retirez pas de delay, sinon le timing foire et ça ne marche pas (je pense que le graphDataArePending est trigger trop tot)
            this.loaderOptions.onEnd.pipe(take(1), delay(750)).subscribe(() => this.isReady = true);
        });

    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public twoDigitsAfterDecimalMax(averageScore: number): string {
        const twoDigits = averageScore.toFixed(2);
        const [head, tail] = twoDigits.split('.');
        if (tail === '00') {
            return head;
        } else {
            return twoDigits.replace('.', ',');
        }
    }

    private generateComplexGraph(progressData: ProgressData): void {
        // const chartLabels: string[] = [];
        // const dots: number[][] = [[], [], [], []];
        // const dotsDetails: CustomTooltipModalContentSettings[][] = [[], [], [], []];
        //
        // progressData.notChallenges
        //     .filter(dot => dot.firstAnswer + dot.secondAnswer + dot.failedFirstAttempt + dot.failedRetry !== 0)
        //     .forEach((dot, index) => {
        //         let labelWithDot = dot.exerciseName;
        //         if (dot.exerciseName.length > 35) {
        //             labelWithDot = dot.exerciseName.substr(0, 35) + '...';
        //         }
        //         chartLabels.push(labelWithDot);
        //         dots[0].push(dot.firstAnswer);
        //         dots[1].push(dot.firstAnswer + dot.secondAnswer);
        //         dots[2].push(dot.firstAnswer + dot.secondAnswer + dot.failedFirstAttempt);
        //         dots[3].push(100);
        //         for (let i = 0; i < 4; i++) {
        //             const translatedHeader = this.graphUbolinoTranslateService.get('graph_ubolino.progress_tooltip_header')
        //                 .replace('{{date}}', moment(+dot.date, 'X').format('DD/MM/YYYY'));
        //             dotsDetails[i].push({
        //                 header: `<label>${dot.exerciseName}\n${translatedHeader}</label>`,
        //                 content: this.generateModalContentHtml(dot, index)
        //             });
        //         }
        //     });
        //
        // this.graphConfigObs.next({
        //     chartLabels,
        //     chartColors: [],
        //     chartConfig: {
        //         scales: {
        //             y:
        //                 {beginAtZero: true, max: 100, min: 0, offset: false},
        //             ],
        //             x:
        //                 {offset: false},
        //             ]
        //         },
        //         legend: {
        //             align: 'start',
        //             position: 'bottom',
        //             onClick: event => event.native.stopPropagation()
        //         }
        //     },
        //     chartData: [
        //         {
        //             data: dots[0],
        //             type: 'line',
        //             fill: 'origin',
        //             label: this.graphUbolinoTranslateService.get('graph_ubolino.progress_good_first_percent'),
        //             backgroundColor: rgbOpacity(firstLayerColor, '0.3'),
        //             borderColor: firstLayerColor
        //         },
        //         {
        //             data: dots[1],
        //             type: 'line',
        //             fill: '0',
        //             label: this.graphUbolinoTranslateService.get('graph_ubolino.progress_good_second_percent'),
        //             backgroundColor: rgbOpacity(secondLayerColor, '0.3'),
        //             borderColor: secondLayerColor
        //         },
        //         {
        //             data: dots[2],
        //             type: 'line',
        //             fill: '1',
        //             label: this.graphUbolinoTranslateService.get('graph_ubolino.progress_bad_first_percent'),
        //             backgroundColor: rgbOpacity(thirdLayerColor, '0.3'),
        //             borderColor: thirdLayerColor
        //         },
        //         {
        //             data: dots[3],
        //             type: 'line',
        //             fill: '2',
        //             label: this.graphUbolinoTranslateService.get('graph_ubolino.progress_bad_second_percent'),
        //             backgroundColor: rgbOpacity(fourthLayerColor, '0.3'),
        //             borderColor: fourthLayerColor
        //         }
        //     ],
        //     modalContent: dotsDetails,
        // });

        const chartLabels: string[] = [];
        const dots: number[] = [];
        const dotsDetails: CustomTooltipModalContentSettings[] = [];

        // progressData.notChallenges
        //     .filter(dot => dot.firstAnswer + dot.secondAnswer + dot.failedFirstAttempt + dot.failedRetry !== 0)
        this.fakeData.forEach((dot, index) => {
            const labelWithDot = dot.questionSet; // exerciseName;
            // if (dot.exerciseName.length > 35) {
            //     labelWithDot = dot.exerciseName.substr(0, 35) + '...';
            // }
            chartLabels.push(labelWithDot);
            dots.push(dot.goodOnesPercent); // dot.firstAnswer + dot.secondAnswer); // TotalGood marcherait aussi
            dotsDetails.push({
                header: `<div>${dot.questionSet/*exerciseName*/}\n</div><div>${moment(+dot.date/*, 'X'*/).format('DD/MM/YYYY')}</div>`,
                content: this.generateModalContentHtml(dot, index)
            });
        });

        this.graphConfigObs.next({
            chartLabels,
            chartConfig: {
                scales: {
                    y: {
                        beginAtZero: true, max: 100, min: 0,
                        offset: false
                    },
                    x: {
                        offset: false
                    }
                },
                plugins: {
                    legend: {
                        align: 'start',
                        position: 'bottom',
                        onClick: event => event.native.stopPropagation()
                    }
                }
            },
            chartData: [{
                data: dots,
                type: 'line',
                fill: true,
                label: this.graphUbolinoTranslateService.get('generic.pourcent_success'),
                backgroundColor: rgbOpacity(simpleColor, '0.3'),
                borderColor: simpleColor,
            }],
            modalContent: [dotsDetails],
        });
    }

    private generateModalContentHtml(currentDot/*: ProgressDotDetail*/, index: number): string {
        return `<ul>
    <li>${this.graphUbolinoTranslateService.get('graph_ubolino.progress_duration')} ${moment.duration(currentDot.duration, 'seconds').format('mm[m]ss[s]')}</li>
    <li>${this.graphUbolinoTranslateService.get('graph_ubolino.progress_good_ones')} ${currentDot.goodOnes}</li>
    <li>${this.graphUbolinoTranslateService.get('graph_ubolino.progress_bad_ones')} ${currentDot.goodOnes}</li>
    <li>${this.graphUbolinoTranslateService.get('graph_ubolino.progress_variation')} ${currentDot.variation}</li>
</ul>`;
    }

    // private setChallengeStats(data: ProgressData): void {
    //     const challenges = _.clone(data.challenges);
    //
    //     if (challenges.length > 0) {
    //         this.challenge = {
    //             count: challenges.length,
    //             lastDate: moment(challenges[challenges.length - 1].date, 'X').format('DD/MM/YYYY'),
    //             lastScore: challenges[challenges.length - 1].rawFirstAnswer,
    //             averageScore: challenges.reduce((acc, c) => acc + c.rawFirstAnswer, 0) / challenges.length
    //         };
    //     } else {
    //         this.challenge = null;
    //     }
    // }
}
