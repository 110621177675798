import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-go-buy-licence-news-passive',
    templateUrl: './go-buy-licence-news-passive.component.html',
    styleUrls: ['./go-buy-licence-news-passive.component.scss']
})
export class GoBuyLicenceNewsPassiveComponent  {

    constructor(private router: Router) {
    }
}
