import * as _ from "lodash-es";

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { DataEntity } from "octopus-connect";
import { BdRepositoryService } from "@modules/bdtool/core/bd-repository.service";
import { BdService } from "../bd.service";
import { brand } from "../../../../settings";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-bd-card",
  templateUrl: "./bd-card.component.html",
})
export class BdCardComponent implements OnInit {
  /**
   * Bd resource used to get card data
   */
  @Input()
  public bdBasicSearchEntity: DataEntity;
  /**
   * Emit when granule has changed from this component and need to be reloaded
   */
  @Output()
  public hasChanged = new EventEmitter<void>();
  /**
   * Bd creation date
   */
  public creationDate: string;
  /**
   * card image url (in the left side)
   */
  public imageUri: string;
  /**
   * Bd last modification date
   */
  public lastUpdate: string;
  /**
   * Bd associated lesson name
   */
  public associatedLessonTitle: string | null;
  public associatedLessonId: string | number;
  /**
   * Define if assiciated lesson name is loading from it's id
   */
  public associatedLessonIsLoading = true;
  /**
   * Define bd title
   */
  public title: string;
  private bdEntity: DataEntity;

  constructor(
    private bdSvc: BdService,
    private bdRepoSvc: BdRepositoryService
  ) {}

  ngOnInit(): void {
    this.creationDate = new Date(
      this.bdBasicSearchEntity.get("created") * 1000
    ).toLocaleDateString();
    this.imageUri = this.getImageUri();
    this.lastUpdate = new Date(
      this.bdBasicSearchEntity.get("changed") * 1000
    ).toLocaleDateString();
    // this.title = this.bdBasicSearchEntity.get("title");

    // For get the associated lesson title, we need to load the bd activity content.
    // We don't have the activity content here and to have it, we need to load the granule and everything (incluce the activitycontent) is loaded with it.
    this.bdRepoSvc
      .getBd(this.bdBasicSearchEntity.id)
      .pipe(
        tap((bd) => (this.bdEntity = bd)),
        tap((bd) => (this.title = bd.attributes?.reference?.activity_content[0]?.content?.title)),
        // There are only one activity content and one assignated lesson by bd
        tap((bd) => {
          this.associatedLessonTitle = _.get(
            bd.get("reference"),
            "activity_content[0].associated_nodes[0].title"
          );
          this.associatedLessonId = _.get(
            bd.get("reference"),
            "activity_content[0].associated_nodes[0].id"
          );
        }),

        tap(() => (this.associatedLessonIsLoading = false))
      )
      .subscribe();
  }

  /**
   * Go to bd editor.
   * No need to know how to edit a bd here.
   * @param $event
   */
  public edit($event: MouseEvent): void {
    $event.preventDefault();
    this.bdSvc.goToBdDataEdition(this.bdEntity);
  }

  /**
   * Delete the current Bd
   * @param $event
   */
  public delete($event: MouseEvent): void {
    $event.preventDefault();
    this.bdSvc
      .deleteBd(this.bdEntity.id)
      .pipe(tap(() => this.hasChanged.next()))
      .subscribe();
  }

  /**
   * Display the content of the bd
   * @param $event
   */
  public show($event: MouseEvent): void {
    $event.preventDefault();
    this.bdSvc.displayBd(this.bdBasicSearchEntity.id);
  }

  /**
   * Extracted way to know the bd image urls
   */
  private getImageUri(): string {
    return "./assets/" + brand + "/icons/illu_outil_bd.svg";
  }

  public goToLesson(): void {
    this.bdSvc.goToLesson(this.associatedLessonId);
  }
}
