import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {BaseActivityComponent} from '../base-activity.component';
import {Observable, of} from 'rxjs';
import {PollActivityGranule} from '@modules/activities/core/models/activities/typologies/poll-activity.granule';

@Component({
    selector: 'app-poll',
    templateUrl: './poll.component.html'
})
export class PollComponent extends BaseActivityComponent<PollActivityGranule> implements OnChanges {

    @Input('value') currentValue: any;
    @Input('min') minValue = 0;
    @Input() assignatedCount = 0;

    @Output() moveSlider = new EventEmitter<number>();
    @Output() clickSlider = new EventEmitter<number>();

    public isShowingPopUp = false;
    public pollControl: UntypedFormControl = new UntypedFormControl(0);


    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnInit(): any {
        this.initialize();
    }

    ngOnChanges(): any {
        this.initialize();
    }

    protected initialize(): void {
        this.pollControl.setValue(this.currentValue && this.currentValue !== '' ? +this.currentValue : 0);
    }

    /**
     * emit to the parent the position when moving cursor
     * @param evt : position du curseur sur la frise.
     */
    public sliderMove(evt: any): void {
        this.moveSlider.emit(+evt.currentTarget.value);
    }

    /**
     * emit to the parent the position after click
     * @param evt : position du curseur sur la frise.
     */
    public sliderClick(evt: any): void {
        this.clickSlider.emit(+evt.currentTarget.value);
    }

    /**
     * return the position for popup whith current value
     */
    public get positionTooltip(): string {
        let percent = 0;
        /**
         *  position can be NaN because 0/0 = NaN because this.assignatedCount can be 0, so wee initialize position to 0
         */
        const position =  +this.assignatedCount ? ((Math.abs(this.minValue) + +this.currentValue) / (Math.abs(this.minValue) + Math.abs(+this.assignatedCount))) : 0;

        const minPercent = 1.12;
        const maxPercent = 95.5;

        percent = minPercent + (maxPercent - minPercent) * position;

        return +percent + '%';
    }

    /**
     * show pop up on mouse down
     */
    public showPopUp(): void {
        this.isShowingPopUp = true;
    }

    /**
     * hide pop up on mouse upe
     */
    public hidePopUp(): void {
        this.isShowingPopUp = false;
    }

    /**
     * return answer percent value of poll
     * @returns {string}
     */
    public get  valPol(): string {
        return +this.assignatedCount ? ((+this.pollControl.value * 100) / +this.assignatedCount).toFixed(0) + '%' : 0 + '%';
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setContentData(): void {
        throw new Error('Method not implemented.');
    }

    protected  getGrade(): {oldGrade: number, newGrade: number} {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}
