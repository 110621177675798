// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folded:not(.unfolded) :host > .group-title {
  align-items: center;
}
.folded:not(.unfolded) :host > .group-title > span {
  opacity: 0;
  transition: opacity 200ms ease;
}
.folded:not(.unfolded) :host > .group-title:before {
  content: "";
  display: block;
  position: absolute;
  min-width: 1.6rem;
  border-top: 2px solid;
  opacity: 0.2;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/navigation/vertical/group/group.component.scss"],"names":[],"mappings":"AAIQ;EACI,mBAAA;AAHZ;AAKY;EACI,UAAA;EACA,8BAAA;AAHhB;AAMY;EACI,WAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;EACA,qBAAA;EACA,YAAA;AAJhB","sourcesContent":[":host {\n\n    .folded:not(.unfolded) & {\n\n        > .group-title {\n            align-items: center;\n\n            > span {\n                opacity: 0;\n                transition: opacity 200ms ease;\n            }\n\n            &:before {\n                content: '';\n                display: block;\n                position: absolute;\n                min-width: 1.6rem;\n                border-top: 2px solid;\n                opacity: 0.2;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
