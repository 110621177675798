import {ChangeDetectionStrategy, Component} from '@angular/core';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {OctopusConnectService} from 'octopus-connect';
import {TypedDataEntityInterface} from 'app/shared/models/octopus-connect/typed-data-entity.interface';
import {Router} from '@angular/router';
import {Achievement, OpenBadge, UserPoints, UserProgress, UserScoreService} from '../../services/user-score.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {AchievementDialog} from '@modules/achievement/core/components/my-profile/achievement-dialog/achievement-dialog.component';
import {brand} from 'app/settings';
import {OpenBadgeDialog} from '@modules/achievement/core/components/my-profile/open-badge-dialog/open-badge-dialog.component';
import {ConfigService} from '@modules/achievement/core/config.service';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector: 'app-my-profile',
    templateUrl: './my-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyProfileComponent {


    userInformation$: BehaviorSubject<UserDataEntity> = this.accountService.data;
    public brand = brand;

    constructor(
        private octopusConnect: OctopusConnectService,
        private accountService: AccountManagementProviderService,
        private userScoreService: UserScoreService,
        private router: Router,
        public dialog: MatDialog,
        private configService: ConfigService,
        private authenticationService: AuthenticationService
    ) {
    }

    public showOpenBadgeDialog(badge: TypedDataEntityInterface<OpenBadge>): void {
        if (badge.get('unLocked')) {
            this.dialog.open(OpenBadgeDialog, {
                data: badge,
                panelClass: 'openbadge-dialog',
            });
        }
    }

    public navigateToAvatar(): void {
        this.router.navigate(['avatars']);
    }

    public openAchievementDialog(achievement: Achievement): void {
        this.dialog.open(AchievementDialog, {
            data: achievement,
            panelClass: 'achievement-dialog',
        });
    }

    public get userPoints(): TypedDataEntityInterface<UserPoints> {
        return this.userScoreService.userPoints[0];
    }

    public get userProgress(): TypedDataEntityInterface<UserProgress> {
        return this.userScoreService.userProgress[0];
    }

    public get userAchievements(): TypedDataEntityInterface<Achievement>[] {
        return this.userScoreService.userAchievements;
    }

    public get userOpenBadges(): TypedDataEntityInterface<OpenBadge>[] {
        return this.userScoreService.userOpenBadges;
    }

    public get loadingSubject(): Subject<boolean> {
        return this.userScoreService.loadingSubject;
    }

    public get isOpenBadgesActive(): boolean {
        if (this.authenticationService.isGAR()) {
            return this.configService.isOpenBadgeActive().gar;
        } else {
            return this.configService.isOpenBadgeActive().default;
        }

    }
}

