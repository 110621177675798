import {AfterViewInit, Component, ComponentFactoryResolver, Inject, inject, OnDestroy, ViewChild, ViewContainerRef} from '@angular/core';
import {AssignmentByStepService} from '@modules/assignation/core/components/assignment-by-steps/assignment-by-step.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AssignationComponent} from '@modules/assignation/core/components/assignation/assignation.component';
import {AssignationService} from '@modules/assignation';


@Component({
    selector: 'app-assignment-by-steps',
    templateUrl: './assignment-by-steps.component.html',
})
export class AssignmentByStepsComponent implements AfterViewInit, OnDestroy {
    public currentStep = 0;

    @ViewChild('stepContainer', {read: ViewContainerRef}) stepContainer: ViewContainerRef;

    public assignmentByStepService: AssignmentByStepService;
    private assignationService: AssignationService;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                public dialogRef: MatDialogRef<AssignationComponent>,
                public dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) private data: any,) {

        this.assignmentByStepService = inject(AssignmentByStepService);
        this.assignationService = inject(AssignationService);

        this.initData();
    }

    private initData() {
        // launch in a classroom
        this.assignmentByStepService.isLaunchInClassroom = this.data?.origine === 'launchInGroup';
        // launch from help button in followed
        this.assignmentByStepService.isLaunchInFollowedHelp = this.data?.origine === 'launchInFollowedHelp';
        this.assignmentByStepService.disabledNextButton = false;

        // assignatedTo to : in some case we have already do assignment it's the list of group class who was assignated
        this.assignmentByStepService.assignatedTo = this.data?.assignatedTo;

        // set the lists of learner groups and workgroups
        this.assignmentByStepService.learners = this.assignationService.learnersList ? this.assignationService.learnersList.map(l => ({...l, selected: false})) : [];
        this.assignmentByStepService.groups = this.assignationService.groupsList ? this.assignationService.groupsList.map(g => ({...g, selected: false})) : [];
        this.assignmentByStepService.workgroups = this.assignationService.workgroupsList ? this.assignationService.workgroupsList.map(w => ({...w, selected: false})) : [];

        // set the data
        this.assignmentByStepService.setCurrentInfo(this.data);

        // set list of seances for lessons with sublessons
        this.assignmentByStepService.seances = this.data.seances.map(s => ({...s, selected: !(this.assignmentByStepService.isLaunchInFollowedHelp || this.assignmentByStepService.isLaunchInClassroom)})); // in one case default is select all in other no select

        // set the main title
        this.assignmentByStepService.title = this.data?.node?.get('metadatas')?.title;


    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('assignment-by-steps-wrapper'); // Add a CSS class to the overlay pane for styling
    }

    ngAfterViewInit(): void {
        // load first step component
        this.loadComponent();
    }

    public get steps(): any[] {
        if (this.data?.origine === 'launchInGroup') {
            return this.assignmentByStepService.stepsIfLaunchInGroup;
        } else if (this.data?.origine === 'launchInFollowedHelp') {
            return this.assignmentByStepService.stepsIfLaunchInFollowedHelp;
        } else {
            return this.assignmentByStepService.steps;
        }
    }

    next(): void {
        if (this.currentStep < this.steps.length - 1) {
            this.currentStep++;
            this.loadComponent();
        }
    }

    previous(): void {
        if (this.currentStep > 0) {
            this.currentStep--;
            this.loadComponent();
        }
        if (this.currentStep === 0) {
            this.assignmentByStepService.disabledNextButton = false;
        }
    }

    validate(): void {
        // send data
        this.dialogRef.close(this.assignmentByStepService.dataFormatedForAssignment());
    }

    /**
     * generate the component to show in regard of the current step
     */
    loadComponent(): void {
        if (this.stepContainer) {
            this.stepContainer.clear();
        }
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.steps[this.currentStep]);
        const componentRef = this.stepContainer.createComponent(componentFactory);

        const componentInstance: any = componentRef.instance;
        if ('clickOnNextButton' in componentInstance) {
            componentInstance.clickOnNextButton.subscribe((data: any) => {
                if (data) {
                    if (data) {
                        if (this.data?.origine === 'launchInFollowedHelp') {
                            this.validate();
                        } else {
                            this.next();
                        }
                    }
                }
            });
        }
    }

    /**
     * when we launch a lesson in classroom only one lesson is choose and the button next is not used at the first step
     */
    public nextButtonAllowed(): boolean {
        return (this.assignmentByStepService.seances.length === 0 && this.currentStep === 0) || !(this.assignmentByStepService.isLaunchInClassroom && this.currentStep === 0);
    }

    ngOnDestroy(): void {
        this.assignmentByStepService.reset();
    }
}