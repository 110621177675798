import {Injectable} from '@angular/core';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RewardService {

    constructor(private octopusConnect: OctopusConnectService) {
    }

    /**
     * get progression stat of current user
     */
    loadProgressionStat(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('user-progress');
    }

    /**
     * load badge by level
     * @param level
     */
    public loadBadgeByLevel(level: number): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('badges', {
            level: level
        });
    }
}
