import {AccountManagementServicesInstantiatorService} from '@modules/account-management';
import {ActivitiesServicesInstantiatorService} from '@modules/activities';
import {AssignmentServicesInstantiatorService} from '@modules/assignation';
import {AuthenticationServicesInstantiatorService} from '@modules/authentication';
import {BasicPageService} from 'fuse-core/components/basic-page/basic-page.service';
import {ConfigurationService} from '@modules/configuration';
import {FormDialogService} from 'fuse-core/components/form-dialog/form-dialog.service';
import {GlobalAuthorizationService} from '../../../../src/app/global-authorization.service';
import {GroupsManagementServicesInstantiatorService} from '@modules/groups-management';
import {Injectable} from '@angular/core';
import {SnackbarNewsService} from 'fuse-core/news/snackbar/snackbar-news.service';
import {SvgIconList} from '../../../../src/app/themes/sapiens/svgIconList';

@Injectable()
export class ServiceInstantiator {

    constructor(
        private _accountManagementServices: AccountManagementServicesInstantiatorService,
        private _activitiesServices: ActivitiesServicesInstantiatorService,
        private _assignmentServices: AssignmentServicesInstantiatorService,
        private _authenticationServices: AuthenticationServicesInstantiatorService,
        private _basicPageService: BasicPageService,
        private _configurationService: ConfigurationService,
        private _formDialogService: FormDialogService,
        private _globalAuthorizationService: GlobalAuthorizationService,
        private _groupsManagementServices: GroupsManagementServicesInstantiatorService,
        private _snackbarNewsService: SnackbarNewsService,
        private _svgIconList: SvgIconList,
    ) {
    }

}
