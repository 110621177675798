import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LaboratoryCardInterface} from '@modules/activities/core/language-lab/language-lab.service';

@Component({
    selector: 'app-language-lab-card',
    templateUrl: './language-lab-card.component.html',
})
export class LanguageLabCardComponent {
    @Output() state = new EventEmitter<string>();
    @Output() openCard = new EventEmitter<boolean>();
    @Input() flashCard!: LaboratoryCardInterface;
    @Input() idCardPlaying = '';
    constructor() {
    }

    public cardClick() {
        this.openCard.emit(true);
        // this.state.emit('card-click');
    }

    public isPlaying(): boolean {
        return this.idCardPlaying && this.idCardPlaying !== '' && this.idCardPlaying === this.flashCard.id;
    }

    public openCardActivity(): void{
        this.openCard.emit(true);
    }

}


