import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GraphUbolinoRoutes} from '../../graph-ubolino.routes';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector: 'app-graph-selector',
    templateUrl: './graph-selector.component.html',
})
export class GraphSelectorComponent implements OnInit {

    private labelByRole = {
        trainer: [
            'group_management.graph_attendance_trainer',
            'group_management.graph_progress_trainer',
        ],
        default: [
            'group_management.graph_attendance',
            'group_management.graph_progress',
        ]
    };


    public graphLinks: { url: string, label: string }[] = [
        {url: '/graph-ubolino/multi/' + GraphUbolinoRoutes.Attendance, label: this.labelByRole[this.translationGraphLabelByRole()][0]},
        {url: '/graph-ubolino/multi/' + GraphUbolinoRoutes.Progress, label: this.labelByRole[this.translationGraphLabelByRole()][1]},
    ];

    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) {

    }

    ngOnInit(): void {
    }

    public isRouteActive(url: string): boolean {
        return this.router.isActive(url, true);
    }

    private translationGraphLabelByRole(): string {
        return this.authService.isAtLeastTrainer() ? 'trainer' : 'default';
    }
}
