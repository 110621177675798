// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#entity .content {
  overflow: hidden;
}

.add-entity-button.fixed {
  position: fixed;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/groups-management/core/groups-listing/groups-listing.component.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;AADR;;AAIA;EACI,eAAA;AADJ","sourcesContent":["#entity {\n\n    .content {\n        overflow: hidden;\n    }\n}\n.add-entity-button.fixed { // TODO(val) delete\n    position: fixed;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
