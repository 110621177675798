import {Component, ViewChild, ElementRef, OnInit, AfterViewInit, Input, OnChanges, Output, EventEmitter, HostListener} from '@angular/core';
import {IImage, IMove, IParamsZoneAround, IStyleZoneBeginEnd} from '@modules/timeline/core/models/timeline-data.models';

@Component({
    selector: 'app-viewer',
    templateUrl: './viewer.component.html',
    styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild('divToScrollMultiImage', { static: true }) private divToScrollMultiImage: ElementRef;

    @Input() moveTo: IMove = {position: 0, type: 'auto', numeroImage: 0, ajustementPositionPx: 0};
    @Input() globalImageWidth: number;
    @Input() images: IImage[];
    @Input() paramsZoneAround: IParamsZoneAround = {width: 0, startBackgroundCss: '', endBackgroundCss: ''};
    @Input() activateGesture: boolean = false;
    @Output() openDocument = new EventEmitter<number>();
    @Output() currentImage = new EventEmitter<number>();

    public beginImageStyles: IStyleZoneBeginEnd = {height: '', width: '', background: ''};
    public endImageStyles: IStyleZoneBeginEnd = {height: '', width: '', background: ''};
    public commonStyles: { height: string } = {height: ''};
    public cadreImageStyles: { width: string } = {width: ''};
    private innerHeight: number;
    public ratio: number = 1; // ratio for responsive image size default 1 no change

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        this.setRatioToApply();
        this.setImagesHeight();
        this.moveToPositionById();
    }

    constructor() {
    }

    ngOnInit(): void {
        this.setRatioToApply();
        this.setImagesHeight();

        this.setBeginAndEndTimelineStyle();

        this.cadreImageStyles.width = this.globalImageWidth + 'px';
    }

    /**
     * change begin and end of timeline by css send by back
     */
    private setBeginAndEndTimelineStyle(): void {
// begin bloc before image
        this.beginImageStyles.width = this.paramsZoneAround.width + 'px';
        this.beginImageStyles.background = this.paramsZoneAround.startBackgroundCss;

        // end image after image
        this.endImageStyles.width = this.paramsZoneAround.width + 'px';
        this.endImageStyles.background = this.paramsZoneAround.endBackgroundCss;
    }

    /**
     * change the image height size in regard of the  height of the window
     * based image in back must be 500 px height.
     * use to permit a minimum responsive way
     */
    private setRatioToApply(): void {
        this.innerHeight = window.innerHeight;
        if (this.innerHeight < 600) {
            this.ratio = this.innerHeight / 1000 - 0.1;
        }
        if (this.innerHeight > 600 && this.innerHeight < 850) {
            this.ratio = this.innerHeight / 1000;
        }
        if (this.innerHeight > 850) {
            this.ratio = 1.1;
        }
    }

    /**
     * set the image height size come from back but take count of ratio to apply
     */
    private setImagesHeight(): void {
        // all ok ratio 1 for 939 px height and 500  px back height image
        const myHeight = this.images[0].height * this.ratio + 'px'; // reférence image in back have 500px height
        this.beginImageStyles.height = myHeight;
        this.endImageStyles.height = myHeight;
        this.commonStyles.height = myHeight;
    }

    ngAfterViewInit(): void {

        // scroll to good position
        this.moveToPositionById();
    }

    /**
     * each time the moveTo object change scrollTo the position in the timeline value in pixel
     */
    ngOnChanges(): void {
        try {
            if (this.moveTo.type === 'auto') {
                this.divToScrollMultiImage.nativeElement.scrollTo({left: this.moveTo.position, top: 0, behavior: this.moveTo.type});
            } else {
                // scroll to good position by id smooth
                this.moveToPositionById();
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * center the image using id and adjustment
     */
    private moveToPositionById(): void {
        const element = document.getElementById('loupe' + this.moveTo.numeroImage);
        // before adjustement came from back now it's calculate todo if all is ok remove field from back and in object input
        this.moveTo.ajustementPositionPx = - this.divToScrollMultiImage.nativeElement.offsetWidth / 2;
        if (element) {
            setTimeout(() => {
                    const position = element.offsetLeft;
                    this.divToScrollMultiImage.nativeElement.scrollTo({left: position + this.moveTo.ajustementPositionPx, top: 0, behavior: this.moveTo.type});
                }
                , 100);
        }
    }

    /**
     * launch open document details in parent.
     * @param image contain id of period with the dot info to open
     */
    public openDocumentEmitter(image: IImage): void {
        this.openDocument.emit(+image.idPeriod);
    }

    /**
     * gesture to move image by image by left swipe
     * @param evt :not use for moment
     */
    public onSwipeLeft(evt: any): void {
        if (!this.activateGesture) {
            return;
        }
        if (this.moveTo.numeroImage < this.images.length - 1) {
            this.moveTo.numeroImage = +this.moveTo.numeroImage + 1;
            this.movetoImage();
        }
    }

    /**
     * gesture to move image by image by right swipe
     * @param evt :not use for moment
     */
    public onSwipeRight(evt: any): void {
        if (!this.activateGesture) {
            return;
        }
        if (this.moveTo.numeroImage !== 0) {
            this.moveTo.numeroImage = +this.moveTo.numeroImage - 1;
            this.movetoImage();
        }
    }

    /**
     * launch move to image and emit info to parent
     */
    private movetoImage(): void {
        this.moveToPositionById();
        this.currentImage.emit(this.moveTo.numeroImage);
    }
}
