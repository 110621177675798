import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-end-screen-score',
    templateUrl: './end-screen-score.component.html'
})
export class EndScreenScoreComponent implements OnInit {
    @Input() reward = 0; // number of stars 1 2 or 3,  4 equal diamond
    @Input() coinsToShow: boolean[] = [];// coin to show or not

    constructor() {
    }

    ngOnInit(): void {
    }

    /**
     * position 0 1 2 3 (position on the array)
     * @param position
     */
    public isToshow(position: number): boolean {
        if (position <= this.coinsToShow.length) {
            return this.coinsToShow[position];
        } else {
            return false;
        }
    }
}