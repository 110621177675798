import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {LayoutConfigService} from 'fuse-core/services/layout-config.service';
import {navigation} from 'app/navigation/navigation';
import {FullscreenService} from '@fuse/services/fullscreen.service';
import {LayoutConfig} from 'fuse-core/types';


@Component({
    selector: 'vertical-layout-1',
    templateUrl: './layout-1.component.html',
    styleUrls: ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
    layoutConfig: LayoutConfig

    // todo - remove this if it's really not needed
    navigation: any;

    private _unsubscribeAll: Subject<void>;

    constructor(
        private _layoutConfigService: LayoutConfigService,
        public fullscreenService: FullscreenService
    ) {
        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();
    }

    ngOnInit(): void {
        // Subscribe to config changes
        this._layoutConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.layoutConfig = config;
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
