// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.definition-icon {
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  line-height: 63px;
  font-weight: bold;
  font-size: 20px;
  color: #FF787D;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(255, 120, 125, 0.3);
}
.definition-icon:hover {
  background: #FF787D;
  color: white;
}

.content {
  width: 100%;
  text-align: center;
  margin-top: 200px;
}

.text {
  line-height: normal;
  font-size: 36px;
  text-align: center;
  letter-spacing: 0.145455px;
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/research-sheet/core/section-draft-display/section-type-selection/section-type-selection.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,qBAAA;EAEA,iBAAA;EACA,iBAAA;EACA,eAAA;EAEA,cAAA;EAEA,mBAAA;EACA,iDAAA;AAFF;AAIE;EACE,mBAAA;EACA,YAAA;AAFJ;;AAMA;EACE,WAAA;EACA,kBAAA;EACA,iBAAA;AAHF;;AAMA;EACE,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,0BAAA;EACA,mBAAA;AAHF","sourcesContent":[".definition-icon {\n  width: 64px;\n  height: 64px;\n  margin: 8px;\n  border-radius: 50px;\n  cursor: pointer;\n  text-align: center;\n  display: inline-block;\n\n  line-height: 63px;\n  font-weight: bold;\n  font-size: 20px;\n\n  color: #FF787D;\n\n  background: #FFFFFF;\n  box-shadow: 0px 0px 10px rgba(255, 120, 125, 0.3);\n\n  &:hover {\n    background: #FF787D;\n    color: white;\n  }\n}\n\n.content {\n  width: 100%;\n  text-align: center;\n  margin-top: 200px;\n}\n\n.text {\n  line-height: normal;\n  font-size: 36px;\n  text-align: center;\n  letter-spacing: 0.145455px;\n  margin-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
