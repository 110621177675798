import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {DynamicComponentLoaderService} from '@modules/activities/core/services/dynamicComponentLoader.service';
import {UserActionsService} from '@modules/activities/core/services/user-actions.service';
import {RewardService} from '@modules/activities/core/services/reward.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: []
})
export class ActivityServicesModule {
    static forRoot(): ModuleWithProviders<ActivityServicesModule> {
        return {
            ngModule: ActivityServicesModule,
            providers: [
                ActivitiesService,
                DynamicComponentLoaderService,
                UserActionsService,
                RewardService
            ]
        };
    }
}
