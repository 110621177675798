import {Component, Input, OnInit} from '@angular/core';
import {ResearchSheetService} from '@modules/research-sheet/core/research-sheet.service';
import {ResearchSection} from '@modules/research-sheet/core/definitions';

@Component({
  selector: 'lms-research-sheet-display-link',
  templateUrl: './research-sheet-display-link.component.html',
  styleUrls: ['./research-sheet-display-link.component.scss']
})
export class ResearchSheetDisplayLinkComponent implements OnInit {
  @Input('type') type: string;
  @Input('size') size: {x: number, y: number, w: number, h: number};
  @Input('section') section: ResearchSection;

  public customSize: {x: number, y: number, w: number, h: number} = {x: 0, y: 0, w: 0, h: 0};
  constructor(private sheetService: ResearchSheetService) {
    this.sheetService.showDetailsSubject.subscribe((data) => {
      if (data && data.mode &&
          !this.sheetService.isFirstSection(data.id) &&
          !this.sheetService.isLastSection(data.id) &&
          +data.id !== +this.section.id &&
          this.sheetService.linkValidForChangeSize(data.id, this.section.parent) && +this.section.id > +data.id) {
          this.customLink = {x: this.size.x, y: this.size.y + -(88 + 88 + 10), w: this.size.w, h: this.size.h + 88 + 88 + 10};
          // height between top of card and link height * 2 (card + component) + padding top-bottom of card
      } else {
          this.customLink = this.size;
      }
    });
  }

  ngOnInit(): any {
    this.customLink = this.size;
  }

  public get sizeLink(): any {
    return this.customSize;
  }

  public set customLink(data){
      for (const field in data) {
          this.customSize[field] = data[field];
      }
  }

}
