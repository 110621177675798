import {Component} from '@angular/core';
import {BaseActivityComponent, TIME_DELAY_BEFORE_SAVE, TIME_DISPLAYING_CORRECTION,} from '@modules/activities/core/player-components/base-activity.component';
import {AnswerResultInterface, ItemAnswerStateEnum} from '@modules/activities/core/models';
import {shuffle} from 'shared/utils/array';
import {Observable, of} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities';
import {CommunicationCenterService} from '@modules/communication-center';
import {FullscreenService} from 'fuse-core/services/fullscreen.service';
import {LessonNavigationService} from '../../lesson-navigation.service';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {ContextualService} from '@modules/activities/core/services/contextual.service';
import {v4 as uuidv4} from 'uuid';
import {OrderingMatchingActivityGranule} from '@modules/activities/core/models/activities/typologies/ordering-matching-activity.granule';


@Component({
    selector: 'app-order-matching',
    templateUrl: './order-matching.component.html',
    styleUrls: ['./order-matching.component.scss']
})

export class OrderMatchingComponent extends BaseActivityComponent<OrderingMatchingActivityGranule> {
    public availableAnswers: (AnswerInterface & {state?: ItemAnswerStateEnum})[] = []; // réponses disponible à sélectionner.
    public answersOrderToGuess: AnswerInterface[] = []; // réponses dans le bon ordre à deviner.
    private cacheForDisplaySolution: AnswerInterface[] = []; // sert à afficher les réponse de l'utilisateur si il regarde la solution de l'activité.
    public disableAllAnswers: boolean;
    public isOrderSentence = false; // in some case the order is a sentence in this case design must differ of just a standart order
    private waitUntilCorrectionFinished = false;
    public uuid = '';

    constructor(protected activatedRoute: ActivatedRoute,
                public activitiesService: ActivitiesService,
                protected lessonsService: LessonsService,
                protected communicationCenter: CommunicationCenterService,
                protected contextualService: ContextualService,
                public fullscreenService: FullscreenService,
                protected lessonNavigationService: LessonNavigationService,
                public config: LessonsConfigurationService) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, contextualService, lessonNavigationService);
    }

    /**
     * renseigne les variables de l'activité, les réponses, la consigne la sauvegarde etc..
     * @param activityAttributes attributs d'une dataEntity
     * @private
     */
    protected setContentData(activityAttributes): void {
        this.uuid = uuidv4();
        this.waitUntilCorrectionFinished = false;
        if (activityAttributes.reference.config) {
            this.isTwoColumns = (activityAttributes.reference.config.direction === 'vertical');
            this.isOrderSentence = (activityAttributes.reference.config?.type === 'orderSentence');
        }
        this.referenceActivityGranule = activityAttributes.reference;
        this.instruction = this.referenceActivityGranule.instruction;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wording = this.referenceActivityGranule.wording;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.answersOrderToGuess = this.referenceActivityGranule.activity_content.answers;
        this.makeItShuffled();
        if (this.displayForSummary) {
            this.seeAnswerSolution();
        } else {
            this.setDefaultAnswersSelected();
            if (!this.isActivityEmbedded) {
            this.loadUserSave();
        }
        }
    }

    /**
     * calcule et retourne la note obtenue en fonction des bonnes et mauvaises reponses de l'activité
     * @private
     */
    protected getGrade(): { oldGrade: number, newGrade: number } {
        let countFromUserSave = 0;
        let count = 0;
        this.answersOrderToGuess.forEach((answerToGuess: AnswerInterface, index: number) => {
            const answerSaved = this.userSave && this.userSave.get('userActivity').entitySave.answers[index];
            if (answerSaved && answerSaved.id === answerToGuess.id) {
                countFromUserSave++;
            }
            if (answerToGuess.id === this.answersSelected[index].id) {
                count++;
            }
        });

        return {
            oldGrade: countFromUserSave / this.answersOrderToGuess.length,
            newGrade: count / this.answersOrderToGuess.length
        };
    }

    /**
     * définis les réponses présentes dans la sauvegarde pour qu'elle soit affiché aux emplacements des réponses
     * @private
     */
    protected setAnswer(): void {
        if (this.userSave?.get('userActivity')?.entitySave?.answers && !this.displayForSummary) {
            this.answersSelected = this.answersOrderToGuess.map((answerToGuess: AnswerInterface, index: number) => {
                const answerSaved = this.userSave.get('userActivity').entitySave.answers[index];
                return {
                    state: ItemAnswerStateEnum.pristine,
                    id: answerSaved ? answerSaved.id : answerToGuess.id,
                    answer: answerSaved ? answerSaved.answer : null,
                    select: answerSaved ? answerSaved.select : answerToGuess.select,
                    image: answerSaved ? answerSaved.image : null
                };
            });
            if (!this.lessonsService.isTrainerSeeCorrection()) {
                this.checkAnswer();
                this.activitiesService.userAnswer.next(this.userSave.get('userActivity').entitySave.answers);
                this.testAnswer = true;
            }
        }
    }

    /**
     * corrige l'activité en attribuant un état bonne, mauvaise ou manquante pour les réponses
     * @private
     */
    protected checkAnswer(): void {
        if (this.testAnswer) {
            this.answersSelected.forEach((answerSelected, index: number) => {
                const answerToGuess = this.answersOrderToGuess[index];
                const answerAvailable = this.availableAnswers.find((answer: AnswerInterface) => answer.id === answerSelected.id);
                if (answerSelected.id === answerToGuess.id) {
                    answerSelected.state = ItemAnswerStateEnum.currentlyCorrect;
                    answerAvailable.state = ItemAnswerStateEnum.currentlyCorrect;
                } else {
                    answerSelected.state = ItemAnswerStateEnum.incorrect;
                    answerAvailable.state = ItemAnswerStateEnum.incorrect;
                }
            });
        }

        if (this.answersSelected.length === this.answersOrderToGuess.length) {
            if (this.answersOrderToGuess
                .every((answerToGuess: AnswerInterface, index: number) => answerToGuess.id === this.answersSelected[index].id)) {
                this.answerStatus = answerStatusEnum.correct;
            } else {
                this.answerStatus = answerStatusEnum.wrong;
            }
        } else {
            this.answerStatus = answerStatusEnum.missing;
        }
    }

    /**
     * permet d'initialisé le tableau des réponses selectionnés
     * @param isForSolution permet de savoir si l'on veut que le tableau des réponses sélectionnés soit remplie avec les bonne réponses pour la solution.
     * @private
     */
    private setDefaultAnswersSelected(isForSolution = false): void {
        this.answersSelected = this.answersOrderToGuess.slice().map((answer) => {
            return {
                state: isForSolution ? ItemAnswerStateEnum.currentlyCorrect : ItemAnswerStateEnum.pristine,
                id: answer.id,
                answer: isForSolution ? answer.answer : null,
                select: answer.select,
                image: isForSolution ? answer.image : null,
                feedback: answer.feedback ? answer.feedback : ''
            };
        });
    }

    /**
     * mélange le tableau des réponses disponible (cliquable)
     * @private
     */
    private makeItShuffled(): void {
        this.availableAnswers = this.answersOrderToGuess.map((answer) => {
            return {
                state: ItemAnswerStateEnum.pristine,
                id: answer.id,
                answer: answer.answer,
                select: answer.select,
                image: answer.image,
                feedback: answer.feedback ? answer.feedback : ''
            };
        });
        this.availableAnswers = shuffle(this.availableAnswers, true);
    }

    /**
     * retourne l'index de la premiere réponse non répondu
     */
    public get currentAnswerToSelect(): number {
        if (this.answersSelected.length === 0) {
            return 0;
        }
        return this.answersSelected.findIndex((answer: AnswerInterface, index) => {
            if (!answer) {
                return index;
            }
            return this.isTwoColumns ? !answer.answer : !answer.image;
        });
    }

    /**
     * si l'autocorrection active, au clique sur une réponse, on affiche une correction
     * dans le cas contraire on renseigne dans le tableau des réponses sélectionné la réponse cliqué.
     * @param answer
     */
    protected validate(answer: AnswerInterface & {state?: ItemAnswerStateEnum}): void {
        // if answer is already correct it's avoid to click again on it
        if (answer.state === ItemAnswerStateEnum.wasCorrect || answer.state === ItemAnswerStateEnum.currentlyCorrect) {
            return;
        }
        const answerResult: AnswerResultInterface = {
            id: +this.activity.id,
            state: ItemAnswerStateEnum.missing,
            isLast: undefined
        };
        if (!this.answersSelected.length) {
            this.setDefaultAnswersSelected();
        }
        if (!this.disableAllAnswers && !this.waitUntilCorrectionFinished) {
            if (this.autoCorrection) {
                if (this.answersOrderToGuess[this.currentAnswerToSelect]?.id === answer.id || (this.isOrderSentence && this.answersOrderToGuess[this.currentAnswerToSelect]?.answer === answer.answer)) {
                    const fieldToEdit = this.isTwoColumns ? 'answer' : 'image';
                    this.answersSelected[this.currentAnswerToSelect].state = ItemAnswerStateEnum.currentlyCorrect;
                    this.answersSelected[this.currentAnswerToSelect][fieldToEdit] = answer[fieldToEdit];
                    answerResult.state = ItemAnswerStateEnum.currentlyCorrect;
                    answer.state = ItemAnswerStateEnum.currentlyCorrect;
                } else {
                    answer.state = ItemAnswerStateEnum.incorrect;
                    if (!!this.answersSelected.find(a => a.id === answer.id)) {
                        this.answersSelected.find(a => a.id === answer.id).state = ItemAnswerStateEnum.incorrect;
                    }
                    answerResult.state = ItemAnswerStateEnum.incorrect;
                }
                answerResult.isLast = this.allAnswerCorrect();
                super.manageProgressBarEventToSend(answerResult);
                this.animateAndSaveAnswer();
            } else {
                this.answersSelected[this.currentAnswerToSelect] = {
                    state: ItemAnswerStateEnum.pristine,
                    id: answer.id,
                    answer: answer.answer,
                    select: answer.select,
                    image: answer.image,
                    feedback: answer.feedback
                };
            }
            this.onOptionChange(true);
        }
    }

    private animateAndSaveAnswer(): void {
        // TODO faire une meilleure animation angular
        this.waitUntilCorrectionFinished = true;
        setTimeout(() => {
            this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.incorrect).map(a => a.state = ItemAnswerStateEnum.pristine);
            this.disableAllAnswers = false;
            this.availableAnswers.filter(a => a.state === ItemAnswerStateEnum.incorrect).forEach(a => a.state = ItemAnswerStateEnum.pristine);
            this.availableAnswers.filter(a => a.state === ItemAnswerStateEnum.currentlyCorrect).forEach(a => a.state = ItemAnswerStateEnum.wasCorrect);

            if (this.allAnswerCorrect()) {
                if (this.isActivityEmbedded) {
                    super.setFeedBackFromApi(null, answerStatusEnum.correct);
                    this.isActivityEmbeddedDone = true;
                } else {
                    setTimeout(() => {
                        this.doAction('next', ['save']);
                    }, TIME_DELAY_BEFORE_SAVE);
                }
            } else {
                this.waitUntilCorrectionFinished = false;
            }
        }, TIME_DISPLAYING_CORRECTION);
    }

    private allAnswerCorrect(): boolean {
        return this.currentAnswerToSelect === -1;
    }

    /**
     * récupere dans la config de l'activité la mise en page.
     */
    public getColumnClass(): string {
        return this.answersOrderToGuess.length > 3 ? 'columns-2' : 'columns-1';
    }

    /**
     * réinitialise l'activité.
     * @param resetAllSubscribe
     * @param type
     */
    protected reset(resetAllSubscribe = false, type = null): Observable<boolean> {
        this.waitUntilCorrectionFinished = false;
        this.cacheForDisplaySolution = [];
        this.makeItShuffled();
        this.setDefaultAnswersSelected();
        this.disableAllAnswers = false;
        return super.reset(resetAllSubscribe, type);
    }

    /**
     * permet d'afficher la solution de l'exercice
     */
    protected seeAnswerSolution(): void {
        this.cacheForDisplaySolution = this.answersSelected.map((answer, index) => {
            this.availableAnswers[index].state = ItemAnswerStateEnum.currentlyCorrect;
            return {
                state: ItemAnswerStateEnum.pristine,
                id: answer.id,
                answer: answer.answer,
                select: answer.select,
                image: answer.image,
            };
        });
        this.setDefaultAnswersSelected(true);
    }

    /**
     * permet de revoir la réponse de l'utilisateur s'il regarde la solution
     */
    reviewAnswer(): void {
        this.answersSelected = this.cacheForDisplaySolution.map((answerInCache, index) => {
            const availableAnswer = this.availableAnswers.find((answer) => answer.id === answerInCache.id);
            availableAnswer.state = answerInCache.id === this.answersOrderToGuess[index].id
            && !!answerInCache.answer ? ItemAnswerStateEnum.currentlyCorrect : ItemAnswerStateEnum.pristine;

            return {
                state: answerInCache.id === this.answersOrderToGuess[index].id && !!answerInCache.answer ? ItemAnswerStateEnum.currentlyCorrect : ItemAnswerStateEnum.pristine,
                id: answerInCache.id,
                answer: answerInCache.answer,
                select: answerInCache.select,
                image: answerInCache.image,
            };
        });
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        const answersSelectedCopy = this.answersSelected.map((answer) => {
            if (answer.image || answer.answer) {
                return answer;
            }
            return null;
        });
        // if at least one
        this.answersSelected = answersSelectedCopy.some(answer => !!answer) ? answersSelectedCopy : [];
        return of(null);
    }

    /**
     * add class in regard of answer state current reponse and previous response
     * @param _answer current answer in the loop ngfor
     * @param index index in the ngfor loop to find the good place where push the class
     */
    returnClassAnswerState(_answer: AnswerInterface, index: number): string {
        if (index !== this.currentAnswerToSelect) {
            if (this.answersSelected[index]?.answer || this.answersSelected[index]?.image) {
                return this.answersSelected[index]?.state;
            }
        }
        return this.answersSelected[this.currentAnswerToSelect]?.state || ItemAnswerStateEnum.pristine;
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }
}
