import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {ActivitiesListComponent} from '@modules/activities/core/activities-list/activities-list.component';
import {SharedModule} from 'shared/shared.module';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {FuseSharedModule} from 'fuse-core/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        CommonSharedModule,
        MatButtonToggleModule
    ],
    exports: [
        FuseSharedModule,
        SharedModule,
        CommonSharedModule
    ],
    declarations: [
        ActivitiesListComponent,
    ]
})
export class ActivitiesSharedModule {
}
