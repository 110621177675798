import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {HomePageService} from '@fuse/components/home-page/home-page.service';
import {DataCollection, DataEntity} from 'octopus-connect';
import {DatacardService} from '../../../app/shared/datacard.service';
import {ActivatedRoute, NavigationExtras, NavigationStart, Router} from '@angular/router';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {titleTextInHomePageBanner} from '../../../app/settings';
import urlParser from 'js-video-url-parser';
import {VideoInfo} from 'js-video-url-parser/lib/urlParser';
import {AuthenticationService, UserDataEntity} from '@modules/authentication';
import {combineLatest, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {map, mergeMap, take, tap} from 'rxjs/operators';
import {ProgressionComponent} from 'fuse-core/components/progression/progression.component';

interface Chapter {
    assignations: {[key: string]: {id: string, type: string, assignated_node: string}},
    chapterId: string,
    progress: number,
    progressChapter:  {[key: string]: {score: number, scoreByActivities:  {[key: string]: number}}}[],
    sequences: number[],
    state: string
}
export interface UserProgression {
    chapters : Chapter[];
    completed: number;
    pending: number;
    total: number;
}

interface AssignmentsDataFilteredByGroups {
    group: any;
    learnersCount: number;
    assignmentsCollectiveInClasse: DataEntity[];
    allAssignmentsCollectiveInClasse: DataEntity[];
    assignmentsIndividualInClasse: { [key: string]: any };
    assignmentGroups: DataEntity[];
    concept?: DataEntity;
    enableProgression?: boolean;
    sequence?: DataEntity;
    chapter?: Chapter;
}

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

    public content: string;
    public dataCards: string;
    public dialogRedirectMessage: string;
    public dialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    public imgBannerUrl: string;
    public imgLogoUrl: string;
    public title: string;
    private homePageData: DataEntity;
    private homepages: DataEntity[];
    private home: DataEntity[];
    public titleTextInHomePageBanner: boolean = titleTextInHomePageBanner;
    public alias: string;
    public username: string;
    public isRoleHomepage: boolean;
    public classes: { [key: string]: any }[] = [];
    public workgroups: { [key: string]: any }[] = [];
    public concepts: DataEntity[] = [];

    public showLoader = true;
    public showLoaderProgression = false;
    private userData: DataEntity;
    public assignmentGroupedByClasses: AssignmentsDataFilteredByGroups[] = [];
    public assignmentGroupedByWorkgroups: AssignmentsDataFilteredByGroups[] = [];
    public collectivesAssignments: DataEntity[] = [];
    public individualsAssignments: DataEntity[] = [];
    public chapters: DataEntity[] = [];
    private assignmentStates: DataEntity[] = [];
    private assignmentTypes: any;

    constructor(public homeService: HomePageService,
                public datacardService: DatacardService,
                private router: Router,
                private route: ActivatedRoute,
                private dialog: MatDialog,
                private translate: TranslateService,
                private communicationCenter: CommunicationCenterService,
                private ref: ChangeDetectorRef,
                private authService: AuthenticationService
    ) {
        this.communicationCenter.getRoom('assignments').getSubject('assignmentTypes').pipe(
            tap((assignmentsTypes) => this.assignmentTypes = assignmentsTypes)
        ).subscribe();
    }

    ngOnInit(): void {
        this.communicationCenter.getRoom('authentication').getSubject('userData').subscribe(
            (userData) => {
                this.userData = userData;
                this.username = userData.get('nickname') ? userData.get('nickname') : userData.get('username');
                if (this.isProgressionEnable) {
                    this.initializeProgression();
                } else {
                    this.showLoaderProgression = false;
                }

                this.ref.detectChanges();
            }
        );

        this.translate.get('corpus.dialog_redirect_message').subscribe((translation: string) => this.dialogRedirectMessage = translation);

        this.homeService.loadHomePage().subscribe((collection: DataCollection) => {
            this.homepages = collection.entities;
            this.route.params.subscribe(params => {
                this.showLoader = true;
                if (params['alias'] && params['alias'] !== 'home') {
                    this.isRoleHomepage = false;
                    this.alias = 'home/' + params['alias'];
                    // load homepage from its alias
                    this.loadData(this.homepages.find(entity => entity.get('alias') === this.alias));
                } else {
                    this.isRoleHomepage = true;
                    const role = this.authService.accessLevel;

                    this.home = this.homepages.filter(home => home.attributes.homeLmsType?.label ? home.attributes.homeLmsType.label === role : false);

                    // load home : get the first homepage created on backoffice if no home tagged by role in backoffice
                    this.loadData(this.home.length > 0 ? this.home[0] : this.homepages[0]);
                }
            });
        });
    }

    loadData(entity) {
        this.homePageData = entity;
        this.imgBannerUrl = this.homePageData?.get('background_header')?.uri || '';
        this.imgLogoUrl = this.homePageData?.get('logo_header')?.uri || '';
        this.title = this.homePageData?.get('title') || '';
        this.content = this.homePageData?.get('body') || '';
        this.dataCards = this.homePageData?.get('blocks') || [];
        this.ref.detectChanges();
        this.showLoader = false;
    }

    public play(card: any): void {
        if (card && card.granule && !card.granule[0] && card.link_relative_url) {
            if (!this.isValidHttpUrl(card.link_relative_url)) {
                const queryParams = this.extractQueryParamsFromUrl(card.link_relative_url);
                const navigationExtras: NavigationExtras = {
                    queryParams
                };
                const linkRelativeUrlWithoutParams = card.link_relative_url.split('?')[0];
                this.router.navigate([linkRelativeUrlWithoutParams], navigationExtras);
            } else {
                const win = window.open(card.link_relative_url, '_blank');
                win.focus();
            }
        }

        if (card && card.granule && card.granule[0] && card.granule[0].format.label === 'document') {
            const uri = card.granule[0].reference.uri;
            this.downloadDoc(uri);
        }

        if (card && card.granule && card.granule[0] && (card.granule[0].format.label === 'video' || card.granule[0].format.label === 'videoUrl')) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
                titleDialog: card.granule[0].metadatas.title,
            };
            dialogConfig.width = '80%';
            dialogConfig.panelClass = 'resource-modal';

            switch (card.granule[0].format.label) {
                case 'video':
                    dialogConfig.data.bodyDialog = '<video controls>' +
                        '<source src="' + card.granule[0].file.uri + '" type="' + card.granule[0].reference.filemime + '">' +
                        'Your browser does not support HTML5 video.' +
                        '</video>';
                    break;
                case 'videoUrl':
                    dialogConfig.data.bodyDialog = '<div class="videoWrapper">' +
                        '<iframe width="100%" src="' +
                        this.getVideoLinkInfo(card.granule[0].reference.url) +
                        '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>' +
                        '</div>';
                    break;
            }
            this.dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
            this.dialogRef.afterClosed().subscribe(() => {
            });
        }
    }

    private extractQueryParamsFromUrl(url: string) {
        const queryParams = {};
        const matches = url.match(/[?&][^?&=]+=[^?&=]+/g);
        if (matches) {
            matches.forEach((match) => {
                const parts = match.slice(1).split('=');
                queryParams[parts[0]] = parts[1];
            });
        }
        return queryParams;
    }


    public isValidHttpUrl(string): boolean {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === 'http:' || url.protocol === 'https:';
    }

    getVideoLinkInfo(url): string {
        const parsed: VideoInfo | undefined = urlParser.parse(url);
        if (parsed) {
            return urlParser.create({
                videoInfo: parsed,
                format: 'embed'
            });
        } else if (this.homeService.settings.urlVideoException.some((urlException) => url.includes(urlException))) {
            return url;
        } else {
            return '';
        }

    }


    /***
     * download document and open in a new tab
     * @param path : path of the document
     */
    public downloadDoc(path: string): void {
        window.open(path, '_blank');
    }

    private initializeProgression() {
        this.showLoaderProgression = true;
        const collectiveAssignment = this.getAssignments(false);
        const assignmentsGroups$ = this.getAssignmentsGroups();
        combineLatest([this.loadAssignmentState(), this.loadChapters(), this.loadConcepts(), this.loadClasses(), this.loadWorkgroups(), collectiveAssignment, assignmentsGroups$])
            .pipe(
                take(1),
                tap(([states, chapters, concepts, classes, workgroups, collectives, assignmentsGroupsEntities]) => {
                    this.assignmentStates = states;
                    this.concepts = concepts;
                    this.chapters = chapters;
                    this.classes = classes;
                    this.workgroups = workgroups;
                    const conceptsByInstance: string[] = this.homeService.settings.concepts || [];
                    this.collectivesAssignments = collectives.filter((assignment) => {
                        const conceptLabel = assignment.get('assignated_node')?.concepts?.length ? assignment.get('assignated_node')?.concepts[0]?.label : '';
                        return this.homeService.settings.concepts ? this.homeService.settings.concepts.includes(conceptLabel) : true;
                    });
                    this.individualsAssignments = assignmentsGroupsEntities;
                    if (conceptsByInstance.length) {
                        this.assignmentGroupedByClasses = conceptsByInstance.map((concept: string) => this.getAssignmentsDataFilteredByGroups(classes, concept))
                            .flat();
                        this.assignmentGroupedByWorkgroups = conceptsByInstance.map((concept: string) => this.getAssignmentsDataFilteredByGroups(workgroups, concept))
                            .flat();
                    } else {
                        this.assignmentGroupedByClasses = this.getAssignmentsDataFilteredByGroups(classes);
                        this.assignmentGroupedByWorkgroups = this.getAssignmentsDataFilteredByGroups(workgroups);
                    }
                }),
                mergeMap(() => {
                    const progressions$ = this.assignmentGroupedByClassesAndGroups.map((data) => {
                        const chapter = this.chapters.find((ch) => !!data.allAssignmentsCollectiveInClasse[0]
                            && !!data.allAssignmentsCollectiveInClasse[0]?.get('assignated_node')?.chaptersTag?.length
                            && +ch.id === +data.allAssignmentsCollectiveInClasse[0]?.get('assignated_node')?.chaptersTag[0].id);
                        if (!!data.allAssignmentsCollectiveInClasse[0] && !!chapter) {
                            return this.loadUserProgression(data.allAssignmentsCollectiveInClasse[0], chapter.get('grade')[0]);
                        } else if (!!this.userData?.get('config')?.educational_level && !!this.userData.get('config').concept) {
                            return this.loadUserProgression(data.allAssignmentsCollectiveInClasse[0], this.userData?.get('config')?.educational_level);
                        } else {
                            return of(null);
                        }
                    });

                    if (progressions$.length) {
                        return combineLatest(progressions$);
                    } else {
                        return of([])
                    }

                }),
                tap((userProgressions: UserProgression[]) => {
                    this.assignmentGroupedByClassesAndGroups.forEach((assignmentGroupedByClasseAndGroup: AssignmentsDataFilteredByGroups, index) => {
                        const assignment = assignmentGroupedByClasseAndGroup.allAssignmentsCollectiveInClasse[0];
                        if (assignment && !!userProgressions[index]) {
                            const chapter = userProgressions[index].chapters.find((ch) => !!assignmentGroupedByClasseAndGroup.allAssignmentsCollectiveInClasse[0]
                                && !!assignment?.get('assignated_node')?.chaptersTag?.length
                                && +ch.chapterId === +assignment?.get('assignated_node')?.chaptersTag[0].id);
                            const isLastChapterInProgression = chapter && chapter.chapterId === userProgressions[index].chapters[userProgressions[index].chapters.length - 1].chapterId;
                            const isLastSequenceInProgression = chapter && chapter.sequences.some((sequenceId, index) =>
                                +assignment?.get('assignated_node').id === sequenceId && index === chapter.sequences.length - 1)
                            assignmentGroupedByClasseAndGroup.enableProgression =  !(assignment.get('state_term').label === 'closed' || assignment.get('state_term').label === 'valid')
                                || !(isLastChapterInProgression && isLastSequenceInProgression);
                        } else if (!!userProgressions[index]?.chapters?.length) {
                            assignmentGroupedByClasseAndGroup.enableProgression = true;
                            assignmentGroupedByClasseAndGroup.chapter = userProgressions[index].chapters[0]
                        } else {
                            assignmentGroupedByClasseAndGroup.enableProgression = false;
                        }
                    })
                }),
                tap(() => this.showLoaderProgression = false)
            ).subscribe();
    }

    private getAssignmentsDataFilteredByGroups(groups, concept?: string): AssignmentsDataFilteredByGroups[] {
        const conceptEntity = this.concepts.find((c) => c.get('label') === concept);
        return groups.map((group) => {
            const allAssignmentsCollectiveInClasse = this.collectivesAssignments.filter((assignment) =>
                !!assignment.get('groups').find((c) => +group.id === +c.id)
                && (conceptEntity ? !!assignment?.get('assignated_node')?.concepts.find((c) => +c.id === +conceptEntity.id) : true)
            );
            const assignmentsCollectiveInClasse = allAssignmentsCollectiveInClasse.filter((assignment) =>
                assignment.get('state_term').label !== 'closed' && assignment.get('state_term').label !== 'valid'
            );
            const assignmentGroups = this.individualsAssignments.filter((assignmentGrp) =>
                assignmentGrp.get('group').includes(group.id.toString())
                && (conceptEntity ? assignmentGrp?.get('concepts')?.includes(conceptEntity.id.toString()) : true)
            );
            const assignmentsIndividualInClasse = [];
            assignmentGroups.forEach((assignment) => {
                for (const field in assignment.get('assignations')) {
                    if (assignment.get('assignations') && assignment.get('assignations')[field]) {
                        assignmentsIndividualInClasse.push(assignment.get('assignations')[field]);
                    }
                }
            });
            return {
                group: group,
                learnersCount: group.learnersIds && group.learnersIds.length || 0,
                allAssignmentsCollectiveInClasse,
                assignmentsCollectiveInClasse,
                assignmentsIndividualInClasse,
                assignmentGroups,
                concept: conceptEntity,
            };
        }).filter((d) => !!d.allAssignmentsCollectiveInClasse.length || !!d.assignmentsIndividualInClasse.length);
    }

    private loadUserProgression(assignment: DataEntity, educational_level: string): Observable<UserProgression> {
        const userProgressFilter = {
            educationalLevel: educational_level,
            concept: assignment ? assignment?.get('assignated_node')?.concepts[0]?.id : this.userData.get('config').concept,
        };
        return this.homeService.loadUserProgression(userProgressFilter).pipe(take(1));
    }

    private loadAssignmentState(): Observable<DataEntity[]> {
        return this.communicationCenter.getRoom('assignments').getSubject('statesList');
    }

    private loadConcepts(): Observable<DataEntity[]> {
        const subjectConcepts = new ReplaySubject<Observable<DataEntity[]>>(1);
        subjectConcepts.pipe(
            mergeMap((concepts$: Observable<DataEntity[]>) => concepts$),
            take(1)
        ).subscribe();
        this.communicationCenter
            .getRoom('assignment')
            .next('getConcepts$', subjectConcepts);
        return subjectConcepts.pipe(
            mergeMap((concepts$: Observable<DataEntity[]>) => concepts$),
            take(1)
        );
    }

    private loadChapters(): Observable<DataEntity[]> {
        const subjectChapters = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter
            .getRoom('assignment')
            .next('getChapters$', subjectChapters);
        subjectChapters.pipe(
            mergeMap((chapters$: Observable<DataEntity[]>) => chapters$),
            take(1)
        ).subscribe();
        return subjectChapters.pipe(
            mergeMap((chapters$: Observable<DataEntity[]>) => chapters$),
            take(1)
        );
    }

    private loadClasses(): Observable<{ [key: string]: any }[]> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList');

    }

    private loadWorkgroups(): Observable<{ [key: string]: any }[]> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList');

    }

    private getAssignments(excludeAssignator: boolean = false): Observable<DataEntity[]> {
        const replaySubjectResults = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter
            .getRoom('assignment')
            .next('loadPaginatedAssignments', {
                filter: {
                    'assignator': this.userData.id,
                    'excludeAssignator': excludeAssignator
                },
                onComplete: replaySubjectResults
            });

        return replaySubjectResults
            .pipe(
                take(1),
                mergeMap(assignmentsObservable => assignmentsObservable), // Unwrap l'Observable
            );

    }

    private getPaginatedAssignmentsGroups() {
        const replaySubjectResults = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter
            .getRoom('assignment')
            .next('loadPaginatedAssignmentsGroup', {
                filter: {
                    page: 1,
                },
                onComplete: replaySubjectResults
            });

        return replaySubjectResults
            .pipe(
                take(1),
                mergeMap(assignmentsObservable => assignmentsObservable), // Unwrap l'Observable
            );
    }

    private getAssignmentsGroups() {
        const replaySubjectResults = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter
            .getRoom('assignment')
            .next('loadAssignmentsGroup',
                replaySubjectResults
            );

        return replaySubjectResults
            .pipe(
                take(1),
                mergeMap(assignmentsObservable => assignmentsObservable), // Unwrap l'Observable
            );
    }

    public openAssignmentIfEnter(evt: any, dataCard: any) {
        if (evt.key === 'Enter') {
            this.seeIndividualAssignment(dataCard);
        }
    }

    public seeIndividualAssignment(dataCard: AssignmentsDataFilteredByGroups) {
        if (!!dataCard?.assignmentsIndividualInClasse?.length) {
            this.router.navigate(['followed', 'tab', 'list']);
        }
    }

    public openProgressionIfEnter(evt: any, dataCard: any) {
        if (evt.key === 'Enter') {
            this.openProgression(dataCard);
        }
    }

    public openProgression(dataCard: AssignmentsDataFilteredByGroups, isAssignmentPending?: boolean) {
        const assignmentToContinue = isAssignmentPending && !dataCard.assignmentsCollectiveInClasse[0]?.get('assignated_node').custom ? dataCard.assignmentsCollectiveInClasse[0] : dataCard.allAssignmentsCollectiveInClasse[0];
        if ( dataCard?.enableProgression && ((isAssignmentPending && dataCard.assignmentsCollectiveInClasse[0]) || !isAssignmentPending)) {
            if (assignmentToContinue) {
                this.communicationCenter
                    .getRoom('assignment')
                    .next('assignmentToContinue', assignmentToContinue);
                this.dialog.open(ProgressionComponent, {
                    panelClass: `progression`,
                    data: {
                        assignment: assignmentToContinue,
                    }
                });
            } else {
                this.loadSequence(dataCard.chapter.sequences[0]).pipe(
                    mergeMap((sequence: DataEntity) => {
                        dataCard.sequence = sequence;
                        return this.createAssignment(dataCard)
                    }),
                    tap((assignment) => {
                        dataCard.allAssignmentsCollectiveInClasse.push(assignment);
                        dataCard.assignmentsCollectiveInClasse.push(assignment);
                        this.dialog.open(ProgressionComponent, {
                            panelClass: `progression`,
                            data: {
                                assignment: assignment,
                            }
                        });
                    })
                ).subscribe();
            }
        }
    }

    private loadSequence(sequenceId: number): Observable<DataEntity> {
        const lesson$ = new ReplaySubject<Observable<DataEntity>>(1);
        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId: sequenceId,
                callbackSubject: lesson$
            });
        return lesson$.pipe(
            take(1),
            mergeMap(obs => obs),
        );
    }

    private createAssignment(data: AssignmentsDataFilteredByGroups): Observable<DataEntity> {
        const subjectAssignment =  new ReplaySubject<Observable<DataEntity>>(1);
        const state = this.assignmentStates.find((state) => state.get('label') === 'assigned');
        const typeTerm = this.assignmentTypes.find((type) => type.label === 'auto');
        let title = '';
        const sequenceTitle = data.sequence.get('metadatas').title || '';
        const chapterTitle = data.sequence.get('metadatas').chapters[0]?.label || '';
        if (chapterTitle && chapterTitle !== '') {
            title = chapterTitle;
        }
        if (sequenceTitle && sequenceTitle !== '') {
            if (!title || title === '') {
                title = sequenceTitle;
            } else {
                title += ' | ' +  sequenceTitle;
            }
        }
        const assignment = {
            assignator: +this.userData.id,
            assignated_user: +this.userData.id,
            assignated_node: data.sequence.id,
            state_term: state && state.id || null,
            type_term: typeTerm && typeTerm.id || null,
            title,
        };
        if (data.group) {
            assignment['groups'] = [data.group.id];
        }
        this.communicationCenter
            .getRoom('assignment')
            .next('createAssignmentWithSubject', {assignment, onComplete: subjectAssignment})
        return subjectAssignment.pipe(
            mergeMap((assignment$: Observable<DataEntity>) => assignment$),
            take(1)
        );
    }

    public isBtnProgressionCanBeDisplayed(card: AssignmentsDataFilteredByGroups): boolean {
        return (card.assignmentsCollectiveInClasse.length && !card.assignmentsCollectiveInClasse[0]?.get('assignated_node').custom)
            || (!card.assignmentsCollectiveInClasse.length && !card.allAssignmentsCollectiveInClasse[0]?.get('assignated_node').custom);
    }

    public get assignmentGroupedByClassesAndGroups(): AssignmentsDataFilteredByGroups[] {
        return this.assignmentGroupedByClasses.concat(this.assignmentGroupedByWorkgroups);
    }

    public get isClassOrWorkgroupExist(): boolean {
        return !!this.classes.concat(this.workgroups).length;
    }

    /**
     * verifie sans se baser sur le status des assignations si au moins une assignation existe
     */
    public get isAssignmentsExist(): boolean {
        return !!this.collectivesAssignments.concat(this.individualsAssignments).length;
    }

    public get isProgressionEnable(): boolean {
        return this.homeService.settings.isProgressionEnable && this.authService.isTrainer() && this.router.url.includes('/home');
    }
}
