
import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DatacardService} from 'shared/datacard.service';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {MediaUploadService} from '@modules/corpus/core/media-upload.service';
import {Observable, of, Subject} from 'rxjs';
import {AuthorizationService} from '@modules/authorization';

import {AsyncRules} from '@modules/corpus/core/async.rules';

@Component({
    selector: 'app-corpus-created',
    templateUrl: './corpus-created.component.html',
    styleUrls: ['./corpus-created.component.scss']
})
export class CorpusCreatedComponent implements OnInit, OnDestroy {
    public dataCards: any[] = [];

    private type = 'currentUser';
    private unsubscribeInTakeUntil = new Subject<void>();

    constructor(
        public corpusService: CorpusService,
        private datacardService: DatacardService,
        private mediaUploadService: MediaUploadService,
        private authorizationService: AuthorizationService,
    ) { }

    ngOnInit(): void {
        const callbacks = {
            'isEditableAndErasable&': (resource) => this.isEditableAndErasable(resource, this.type),
            'openAssign': (originalResource) => this.openResourceEditionModal(originalResource),
            'openDialog': (resource) => this.corpusService.openDialog(resource),
            'openDuplicate': (originalResource) => this.openResourceEditionModal(originalResource),
            'openEditor': (originalResource) => this.openResourceEditionModal(originalResource),
            'openMetaDialog': (originalResource) => this.corpusService.openRessource(originalResource),
            'play': (originalResource) => this.corpusService.openRessource(originalResource),
        };

        this.corpusService
            .loadLastCreatedCorpusResources({range : 6}).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((resources: CorpusRessource[]) => {
                this.corpusService.onFilesChanged.next(resources);
                this.dataCards = this.datacardService.processResources(resources, callbacks, this.type);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private openResourceEditionModal(resource: CorpusRessource): void {
        this.mediaUploadService.openResourceEditionModal(resource);
    }

    private isEditableAndErasable(resource, type: string) {
        return this.authorizationService.currentUserCan<Observable<boolean>>(AsyncRules.editResource, of(false), resource, type)
    }
}


