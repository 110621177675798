import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-activity-edition-point-img-button-selected-element',
    templateUrl: './activity-edition-point-img-button-selected-element.component.html',
    styleUrls: ['./activity-edition-point-img-button-selected-element.component.scss']
})
export class ActivityEditionPointImgButtonSelectedElementComponent {
    @Input() disabled = true;
    @Output() handleClickEvent = new EventEmitter<boolean>();

    handleClick(evt: any) {
        this.handleClickEvent.emit(true);
    }
}
