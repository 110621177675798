declare var tarteaucitron: any;
declare global {
    interface Window {
        tarteaucitronForceLanguage: string;
        opera: string;
        _paq: any;
    }
}

import {Platform} from '@angular/cdk/platform';
import {DOCUMENT, Location, NgClass, NgIf} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AfterViewChecked, Component, Inject, inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ExtendedModule} from '@angular/flex-layout';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialog, MatDialogConfig, MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {DomSanitizer, HammerModule, Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {AccountManagementProviderService} from '@modules/account-management';
import {AuthenticationService, UserDataEntity} from '@modules/authentication';
import {AuthorizationService} from '@modules/authorization';
import {BrowserTestService} from '@modules/browser-test';
import {CommunicationCenterService} from '@modules/communication-center';
import {LangChangeEvent, TranslateModule, TranslateService} from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import {Angulartics2GoogleGlobalSiteTag, Angulartics2Matomo} from 'angulartics2';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from 'fuse-core/components';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {MobileRedirectionModalComponent} from 'fuse-core/components/mobile-redirection-modal/mobile-redirection-modal.component';
import {FuseNavigationService} from 'fuse-core/components/navigation/navigation.service';
import {FuseSidebarService} from 'fuse-core/components/sidebar/sidebar.service';
import {NewsModule} from 'fuse-core/news/news.module';
import {SnackbarNewsService} from 'fuse-core/news/snackbar/snackbar-news.service';
import {GlobalConfigurationService} from 'fuse-core/services/global-configuration.service';
import {LayoutConfigService} from 'fuse-core/services/layout-config.service';
import {FuseSplashScreenService} from 'fuse-core/services/splash-screen.service';
import {FuseTranslationLoaderService} from 'fuse-core/services/translation-loader.service';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {FuseNavigation, FuseNavigationItem, LayoutConfig} from 'fuse-core/types';
import {capitalize} from 'lodash-es';
import {RecaptchaV3Module} from 'ng-recaptcha';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {combineLatest, Observable, Subject} from 'rxjs';
import {timer} from 'rxjs/internal/observable/timer';
import {filter, map, mergeMap, startWith, take, takeUntil} from 'rxjs/operators';
import {LanguageService} from 'shared/language.service';
import {GlobalAsyncRules, GlobalSyncRules} from 'shared/models';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
import {SharedTranslateModule} from 'shared/shared-translate.module';
import {IsAllowedBySettings} from '../../../../src/app/guards';
import {HorizontalLayout1Module} from '../../../../src/app/layout/horizontal/layout-1/layout-1.module';
import {LayoutModule} from '../../../../src/app/layout/layout.module';
import {VerticalLayout1Module} from '../../../../src/app/layout/vertical/layout-1/layout-1.module';
import {VerticalLayout2Module} from '../../../../src/app/layout/vertical/layout-2/layout-2.module';
import {VerticalLayout3Module} from '../../../../src/app/layout/vertical/layout-3/layout-3.module';
import {SampleModule} from '../../../../src/app/main/sample/sample.module';
import {DynamicNavigationService} from '../../../../src/app/navigation/dynamic-navigation.service';
import {
    brand,
    defaultMetaDescription,
    brandTitle,
    defaultLanguage,
    langs,
    GATracking,
    trackingIDFromSettings,
    matomoTrackingIDFromSettings,
    matomoTracking,
    tarteaucitronConf,
    mobileUrlRedirection,
    youTubeTracking,
    twitterTracking,
    isMobileAlert,
    customLayoutConfig,
    accessibiActivate,
    GAdsTracking,
    GTMTracking,
    MetaPixelTracking,
    SnapchatTracking,
    GAdsTrackingIDFromSettings,
    GTMTrackingIDFromSettings,
    MetaPixelTrackingIDFromSettings,
    SnapchatTrackingIDFromSettings, externalUrl, baseAppUrl, defaultApiURL, furtherLinks, enableRgpdLinks, addRoleLicenceSuffixForExternalLink,
} from '../../../../src/app/settings/sapiens';
import {TralaTranslationLoaderService} from '../../../../src/app/trala-translation-loader.service';
import {navigation} from 'app/navigation/navigation';
import {TtsModule} from '../../../../src/app/tts/tts.module';
import { ServiceInstantiator } from './service-instantiator.class';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        ExtendedModule,
        FuseProgressBarModule,
        HorizontalLayout1Module,
        NewsModule,
        NgClass,
        NgIf,
        TranslateModule,
        VerticalLayout1Module,
        VerticalLayout2Module,
        VerticalLayout3Module,
        RecaptchaV3Module,
        HttpClientModule,
        SharedTranslateModule,
        MatMomentDateModule,
        MatExpansionModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatSidenavModule,
        MatDialogModule,
        MatBottomSheetModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        TtsModule,
        LayoutModule,
        SampleModule,
        HammerModule,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
    title = 'sapiens';

    lang: string;
    browserLang: string;
    themeColor = 'red';
    layoutConfig: LayoutConfig;
    navigation: FuseNavigation[];
    public isSnackbarRecallButtonConfigActive = false;
    public isAllowedSkipNavigation = false;
    public brand: string = brand;
    public globalConfigurationService = inject(GlobalConfigurationService);
    private tarteaucitronConf: object = tarteaucitronConf;
    private brandTitle: string = brandTitle;
    private defaultMetaDescription: any = defaultMetaDescription;
    private trackingID: string = trackingIDFromSettings;
    private matomoTrackingID: string = matomoTrackingIDFromSettings;
    private GAdsTrackingID: string = GAdsTrackingIDFromSettings;
    private GTMTrackingID: string = GTMTrackingIDFromSettings;
    private MetaPixelTrackingID: string = MetaPixelTrackingIDFromSettings;
    private SnapchatTrackingID: string = SnapchatTrackingIDFromSettings;
    private GATracking: boolean = GATracking;
    private matomoTracking: boolean = matomoTracking;
    private youTubeTracking: boolean = youTubeTracking;
    private twitterTracking: boolean = twitterTracking;
    private GAdsTracking: boolean = GAdsTracking;
    private GTMTracking: boolean = GTMTracking;
    private MetaPixelTracking: boolean = MetaPixelTracking;
    private SnapchatTracking: boolean = SnapchatTracking;
    private accessibi = accessibiActivate;
    private _unsubscribeAll: Subject<void>;
    private languageService = inject(LanguageService);
    private route = inject(ActivatedRoute);
    private isRouteAccessible = inject(IsAllowedBySettings);
    private trackingInitialized = false;
    private dynamicNavigation = inject(DynamicNavigationService);
    private activatedRoute = inject(ActivatedRoute);
    private router = inject(Router);
    private translate = inject(TranslateService);
    private communicationCenter = inject(CommunicationCenterService);
    private authService = inject(AuthenticationService);
    private authorizationService = inject(AuthorizationService);
    private dialog = inject(MatDialog);
    private _layoutConfigService = inject(LayoutConfigService);
    private _fuseNavigationService = inject(FuseNavigationService);
    private _fuseSidebarService = inject(FuseSidebarService);
    private _fuseSplashScreenService = inject(FuseSplashScreenService);
    private _fuseTranslationLoaderService = inject(FuseTranslationLoaderService);
    private _translateService = inject(TranslateService);
    private _platform = inject(Platform);
    private translationLoader = inject(TralaTranslationLoaderService);
    private _serviceInstantiator = inject(ServiceInstantiator);
    private browserTestService = inject(BrowserTestService);
    private matIconRegistry = inject(MatIconRegistry);
    private domSanitizer = inject(DomSanitizer);
    private location = inject(Location);
    private connector = inject(OctopusConnectService);
    private snackbarNews = inject(SnackbarNewsService);
    private usersProvider = inject(AccountManagementProviderService);
    private titleService = inject(Title);
    private angularticsGA = inject(Angulartics2GoogleGlobalSiteTag);
    private angularticsMatomo = inject(Angulartics2Matomo);
    private renderer = inject(Renderer2);
    private meta = inject(Meta);

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private document: Document,
    ) {
        this._unsubscribeAll = new Subject<void>();
        this.initNavigation();
        this.initLangManagement();
        this.initMobilePlatformManagement();
        this.initLayoutConfigManagement();
        this.browserTestService.checkCompatibility();
        this.loadAccessiBe();
        // add default meta description if exists
        if (this.defaultMetaDescription && this.defaultMetaDescription[this._translateService.currentLang]) {
            this.meta.addTag({
                name: 'description',
                content: this.defaultMetaDescription[this._translateService.currentLang]
            });
        }

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data) => {
                if (data) {
                    !this.trackingInitialized ? this.initTracking() : null;
                    this.postAuthentication();
                } else {
                    !this.trackingInitialized ? this.initTracking() : null;
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    Sentry.setUser({email: data.get('email')});
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => {
                    if (route.data) {
                        return route.data;
                    } else {
                        return null;
                    }
                })
            )
            .subscribe((evt) => {
                let menuId: string;

                if (evt && evt['menu']) {
                    menuId = evt['menu'];
                } else {
                    menuId = 'level0';
                }
                this.registerDefaultLinkWithoutAuthentication();
                this.dynamicNavigation.generateMenu(menuId);
                this.dynamicNavigation.switchClassPath(menuId);
            });
    }

    ngOnInit(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                // if in whitelist array and is not authenticated
                if (!this.authService.isAuthenticated) {
                    const whiteListUrls = ['login', 'register', 'forgot-password'];
                    for (let i = 0; i < whiteListUrls.length; i++) {
                        if (window.location.href.indexOf(whiteListUrls[i]) > -1) {
                            !this.trackingInitialized ? this.initTracking() : null;
                        }
                    }
                }
            });

        this.document.body.classList.add(this.brand);
        this.document.querySelector<HTMLLinkElement>('link[ rel *= "icon"]').href =
            'assets/' + this.brand + '/favicon.png?v=6';
        this.setTitle(this.brandTitle);
        // Subscribe to config changes
        this._layoutConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.layoutConfig = config;

                if (this.layoutConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }
            });

        // Check (prepare data) if user is Logged or Anonymous
        this.connector
            .authenticated('http')
            .pipe(take(1))
            .subscribe(
                (userData: UserDataEntity) => {
                    this.authService.loggedUser = userData;
                    this.authService.isAuthenticated = true;
                    // redirect to default when page load
                    // this.authService.overrideDefaultRouteInRegardOfRole();

                    this.communicationCenter
                        .getRoom('authentication')
                        .next('userData', userData);
                },
                (err) => {
                    if (err === 401) {
                        this.connector.logout('http').subscribe(() => {
                            this.communicationCenter
                                .getRoom('authentication')
                                .next('doLogout', null);
                        });
                    }
                }
            );
    }

    ngAfterViewChecked(): void {
        this.closeMenuButtonListener();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public setTitle(newTitle: string): void {
        this.titleService.setTitle(newTitle);
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    loadGoogleAnalytics(trackingID: string): void {
        const gaScript = document.createElement('script');
        gaScript.setAttribute('async', 'true');
        gaScript.setAttribute(
            'src',
            `https://www.googletagmanager.com/gtag/js?id=${trackingID}`
        );

        const gaScript2 = document.createElement('script');
        gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\', {'anonymize_ip': true, 'cookie_expires': 33696000});`;

        document.documentElement.firstChild.appendChild(gaScript);
        document.documentElement.firstChild.appendChild(gaScript2);
    }

    loadMatomo(trackingID: string): void {
        const matomoScript = document.createElement('script');
        matomoScript.setAttribute('type', `text/javascript`);
        matomoScript.innerText = `var _paq = window._paq || [];_paq.push([\'enableLinkTracking\']);(function() {var u=\'https://matomo.tralalere.com/\';_paq.push([\'setTrackerUrl\', u+\'matomo.php\']);_paq.push([\'setSiteId\', \'${trackingID}\']);var d=document, g=d.createElement(\'script\'), s=d.getElementsByTagName(\'script\')[0];g.type=\'text/javascript\'; g.async=true; g.defer=true; g.src=u+\'matomo.js\'; s.parentNode.insertBefore(g,s);})();`;
        document.documentElement.firstChild.appendChild(matomoScript);
    }

    loadTartaucitron(): HTMLScriptElement {
        const tarteaucitronScript = document.createElement('script');
        tarteaucitronScript.setAttribute(
            'src',
            `https://www.tralalere.com/header/tarteaucitron/gcmv2/tarteaucitron.min.js`
        );
        this.renderer.appendChild(document.body, tarteaucitronScript);
        return tarteaucitronScript;
    }

    tarteaucitronInit(): void {
        window.tarteaucitronForceLanguage = this._translateService.currentLang;
        tarteaucitron.init(this.tarteaucitronConf);
        // Google analytics
        if (this.GATracking) {
            tarteaucitron.user.gtagUa = this.trackingID;
            // tarteaucitron.user.gtagCrossdomain = ['example.com', 'example2.com'];
            tarteaucitron.user.gtagMore = function (): void {
                /* add here your optionnal gtag() */
            };
            (tarteaucitron.job = tarteaucitron.job || []).push('gtag');
        }

        // matomo
        /* // remove from tarteaucitron to force matomo tracking
        if (this.matomoTracking) {
            tarteaucitron.user.matomoId = this.matomoTrackingID;
            tarteaucitron.user.matomoHost = 'https://matomo.tralalere.com/';
            (tarteaucitron.job = tarteaucitron.job || []).push('matomo');
        } */

        // youtube
        if (this.youTubeTracking) {
            (tarteaucitron.job = tarteaucitron.job || []).push('youtube');
        }

        // twitter
        if (this.twitterTracking) {
            (tarteaucitron.job = tarteaucitron.job || []).push('twitterembed');
        }

        // GAds
        if (this.GAdsTracking) {
            tarteaucitron.user.googleadsId = this.GAdsTrackingID;
            (tarteaucitron.job = tarteaucitron.job || []).push('googleads');
        }

        // GTM
        if (this.GTMTracking) {
            tarteaucitron.user.googletagmanagerId = this.GTMTrackingID;
            (tarteaucitron.job = tarteaucitron.job || []).push('googletagmanager');
        }

        // Meta Pixel
        if (this.MetaPixelTracking) {
            tarteaucitron.user.facebookpixelId = this.MetaPixelTrackingID;
            tarteaucitron.user.facebookpixelMore = function () { /* add here your optionnal facebook pixel function */
            };
            (tarteaucitron.job = tarteaucitron.job || []).push('facebookpixel');
        }

        // Snapchat
        if (this.SnapchatTracking) {
            tarteaucitron.user.snapchatId = this.SnapchatTrackingID;
            tarteaucitron.user.snapchatEmail = '__INSERT_USER_EMAIL__';
            (tarteaucitron.job = tarteaucitron.job || []).push('snapchat');
        }

        //GCMv2
        if (this.GTMTracking || this.GAdsTracking || this.GATracking) {
            (tarteaucitron.job = tarteaucitron.job || []).push('gcmadstorage');
            (tarteaucitron.job = tarteaucitron.job || []).push('gcmanalyticsstorage');
            (tarteaucitron.job = tarteaucitron.job || []).push('gcmfunctionality');
            (tarteaucitron.job = tarteaucitron.job || []).push('gcmpersonalization');
            (tarteaucitron.job = tarteaucitron.job || []).push('gcmadsuserdata');
            (tarteaucitron.job = tarteaucitron.job || []).push('gcmsecurity');
        }

        this.trackingInitialized = true;
    }

    navigateToAnchor(event: Event) {
        event.preventDefault();
        const anchorId = 'maincontent';
        const element = document.getElementById(anchorId);

        if (element) {
            element.focus();
            element.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }

    /**
     * use for stay on menu even if there is some index on main content
     * when we press tab
     */
    closeMenuButtonListener() {
        const buttonElement = document.getElementById('togglemenutabgestion');
        if (buttonElement) {
            buttonElement.addEventListener('keydown', this.handleKeyDown.bind(this));
        }
    }

    handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab' && event.target instanceof Element && event.target.id === 'togglemenutabgestion') {
            // La touche Tab est pressée sur le bouton fermer le menu on va aller dans le menu et pas dans la page principal
            event.preventDefault();
            const element = document.getElementById('mainmenuelem');
            const firstFocusableElement = element.querySelector('a, button, [tabindex]:not([tabindex="-1"])');
            if (firstFocusableElement && firstFocusableElement instanceof HTMLElement) {
                // first focusable element
                firstFocusableElement.focus();
            }
        }
    }

    getMostImportantLicenceOfUser$(): Observable<string> {
        const userLicences$: Observable<string[]> = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('userLicenses')
            .pipe(
                map((userLicences: TypedDataEntityInterface<{ type: { label: string } }>[]) => userLicences.map(l => l.get('type')?.label)),
            );

        const userInstitutionLicences$: Observable<string[]> = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionList')
            .pipe(
                map((institutionList: { license: { type: string } }[]) => institutionList.map(i => i.license?.type)),
            );

        return combineLatest([userLicences$, userInstitutionLicences$])
            .pipe(
                map(([userLicences, userInstitutionLicences]) => [...userLicences, ...userInstitutionLicences]),
                map(licences => {
                    if (licences.includes('Institution')) {
                        return 'Institution';
                    }
                    if (licences.includes('Class')) {
                        return 'Class';
                    } else {
                        return 'Free';
                    }
                }),
            );
    }

    private initLayoutConfigManagement(): void {
        if (customLayoutConfig.hasOwnProperty('default')) {
            if (customLayoutConfig.hasOwnProperty(this.authService.accessLevel)) {
                this._layoutConfigService.setConfig(customLayoutConfig[this.authService.accessLevel], {emitEvent: true});
            } else {
                this._layoutConfigService.setConfig(customLayoutConfig['default'], {emitEvent: true});
            }

            this.communicationCenter
                .getRoom('authentication')
                .getSubject('userData')
                .subscribe(userData => {
                    const role = this.authService.accessLevel;
                    if (customLayoutConfig.hasOwnProperty(role)) {
                        this._layoutConfigService.setConfig(customLayoutConfig[role], {emitEvent: true});
                    } else {
                        this._layoutConfigService.setConfig(customLayoutConfig['default'], {emitEvent: true});
                    }
                });
        }
    }

    /**
     * insert script to evaluate accessibility
     */
    private loadAccessiBe(): void {
        if (this.accessibi) {
            const s = document.createElement('script');
            s.setAttribute('type', `text/javascript`);
            s.innerText = `(function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'right', leadColor : '#146FF8', triggerColor : '#146FF8', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'bottom', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 20, triggerOffsetY : 20, triggerRadius : '20' } }); }; h.appendChild(s); })();`;
            document.documentElement.firstChild.appendChild(s);
        }

    }

    private initMobilePlatformManagement(): void {
        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }
    }

    private initTracking(): void {

        // cookies managment with tarteaucitron
        if ((this.youTubeTracking
                || this.twitterTracking
                || this.GATracking
                || this.GAdsTracking
                || this.GTMTracking
                || this.MetaPixelTracking
                || this.SnapchatTracking)
            && !this.authService.isGAR()
        ) {
            this.loadTartaucitron().onload = () => {
                this.tarteaucitronInit();
            };
        }

        // Matomo tracking
        if (this.matomoTracking) {
            this.loadMatomo(this.matomoTrackingID);
            this.angularticsMatomo.startTracking();
        }

        // Google Analytics tracking
        if (this.GATracking) {
            this.angularticsGA.startTracking();
        }
    }

    private initLangManagement(): void {
        if (this.globalConfigurationService.getUseSelectedLangAsDefault()) {
            const defaultLang: string[] = langs.map((lang) => lang.id);
            this.browserLang =
                defaultLang.indexOf(navigator.language.substring(0, 2)) !== -1
                    ? navigator.language.substring(0, 2)
                    : defaultLanguage;

            // Add languages
            this._translateService.addLangs(defaultLang);

            // Set the default language
            this._translateService.setDefaultLang(this.browserLang);

            // Use a language
            this.lang = localStorage.getItem('lang') || this.browserLang;
            this.document.documentElement.lang = this.lang;
            this.connector.setLanguage(this.lang);
            this._translateService.use(this.lang);
            // Set the navigation translations
            this.translationLoader.loadTranslations(...defaultLang);
        } else {
            const lang = this.languageService.getActiveLanguage().id;
            this.document.documentElement.lang = lang;
            this.connector.setLanguage(lang);
            // Set the navigation translations
            this.translationLoader.loadTranslations(...this.languageService.availableLanguages.map((lang) => lang.id));
        }

        this._translateService.onLangChange.subscribe(
            (event: LangChangeEvent) => {
                this.connector.setLanguage(event.lang);
                this.document.documentElement.lang = event.lang;
                this._translateService.currentLang = event.lang;
                // init tarteaucitron and update tarteaucitron language
                if (
                    this.youTubeTracking ||
                    this.twitterTracking ||
                    this.GATracking
                ) {
                    this.tarteaucitronInit();
                }
            }
        );
        if (this.globalConfigurationService.getsubscribeToLanguageChange()) {
            this.checkIfQueryParamsForceLanguage();
            this.languageService.onLanguageChange$.subscribe((lang) => {
                // Update dynamic menu with new language
                this.dynamicNavigation.generateMenu(this.dynamicNavigation.currentMenuId, true);
                // check route with access settings for new language
                this.isRouteAccessible.checkRouteAllowedOrRedirect(this.route.snapshot, this.router.routerState.snapshot);
            });
        }
    }

    private initNavigation(): void {
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', []);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                const slugs = event.url.split('/').slice(1);

                const title = slugs
                    .map((slug) => `${capitalize(slug.split('?')[0])}`)
                    .join(' - ');
                this.setTitle(`${this.brandTitle} | ${title}`);
                this.isAllowedSkipNavigation = !(event.url.includes('register') || event.url.includes('login') || event.url.includes('password'));
            });
    }

    private postAuthentication(): void {
        this.isSnackbarRecallButtonConfigActive = this.snackbarNews.isRecallButtonActive;

        timer(1000).subscribe(() => {
            // isMobileAlert is used to show a msg mobile not supported but without redirection
            if (this.isMobile() && (mobileUrlRedirection || isMobileAlert)) {
                const dialogRef = this.dialog.open(
                    MobileRedirectionModalComponent,
                    {}
                );
                dialogRef.afterClosed().subscribe((result) => {
                    // isMobileAlert => no redirection only alert
                    if (result && !isMobileAlert) {
                        window.location.href = mobileUrlRedirection;
                    }
                });
            }
        });

        if (this.authService.isGAR()) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'access-adventure',
                title: 'access adventure',
                translate: 'navigation.access_adventure',
                type: 'item',
                icon: 'code_sand_box',
                url: 'page/aventure',
                externalUrl: false,
                openInNewTab: false,
                displayIcon: true,
            });
        }

        this.handleFurtherMenu();
        this.handleFurtherLinksAtRoot();
        this.registerDefaultLinkWithoutAuthentication();
        this.handleEducationalResourcesMenu();
        this.handleExternalButton();

        if (this.authService.isGAR()) {
            this.dynamicNavigation.clearMenuItem('level0', 'access-future-engineer');
        }


        if (this.authService.hasLevel(['learner', 'parent', 'trainer', 'manager', 'administrator'])) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'home',
                title: 'Home',
                translate: 'navigation.home',
                type: 'item',
                icon: 'home',
                url: '/home',
            });
        }

        if (this.authService.hasLevel(['trainer', 'manager', 'administrator'])) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'needhelp',
                title: 'Need help',
                translate: 'navigation.needhelp',
                type: 'link',
                icon: 'help',
                url: '/',
            });

            this.updateHelpLink();

            // boussole as faq component
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'faq',
                title: 'faq',
                translate: 'generic.faq',
                type: 'item',
                icon: 'compass',
                url: '/faq',
            });

            // boussole as rise360 export
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'faq',
                title: 'faq',
                translate: 'generic.faq',
                type: 'item',
                icon: 'compass',
                url: `${defaultApiURL}interactive/boussole/`,
                externalUrl: true,
                openInNewTab: false,
                displayIcon: true,
                function: (event, item) => this.askConsent(event, item)
            });

            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'funambuleMove',
                title: 'Manoeuvre du funambule',
                translate: 'funambule.move',
                type: 'item',
                icon: 'funambule',
                url: '/page/manoeuvre-funambule',
            });

            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'virtualClass',
                title: 'Classe virtuelle',
                translate: 'virtual.class',
                type: 'item',
                icon: 'virtual_class',
                url: '/page/classe-virtuelle',
            });

            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this.updateHelpLink();
            });
        } else if (this.authService.hasLevel(['learner'])) {
            this.updateHelpLink('_learner');
            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this.updateHelpLink('_learner');
            });
        }

        if (this.authorizationService.currentUserCan<boolean>(GlobalSyncRules.accessToEducationalSheetsAsCards, false)) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'educational-sheets-as-cards',
                title: 'global.educational-sheets-as-cards',
                translate: 'global.educational-sheets-as-cards',
                type: 'item',
                icon: 'book',
                url: 'page-as-card-list/educational-sheets-list',
            });
        }

        this.dynamicNavigation.clearMenuItem('level0', 'help_anonymous');
    }

    private postLogout(): void {
        this.isSnackbarRecallButtonConfigActive = false;
        this.registerDefaultLinkWithoutAuthentication();
        this.dynamicNavigation.clearMenuItem(
            'level0',
            'external-target-link-menu'
        );
        this.dynamicNavigation.clearMenuItem('level0', 'home');
        this.dynamicNavigation.clearMenuItem('level0', 'needhelp');
        this.dynamicNavigation.clearMenuItem('level0', 'funambuleMove');
        this.dynamicNavigation.clearMenuItem('level0', 'virtualClass');
        this.dynamicNavigation.clearMenuItem('level0', 'faq');
        this.dynamicNavigation.clearMenuItem('level0', 'educational-sheets-as-cards');
        this.dynamicNavigation.clearMenuItem('level0', 'educational_resources');
        this.dynamicNavigation.clearMenuItem('level0', 'forFurther');
        this.dynamicNavigation.clearMenuItem('level0', 'informations-legales');
        this.dynamicNavigation.clearMenuItem('level0', 'qui-sommes-nous');
        furtherLinks.forEach(l => this.dynamicNavigation.clearMenuItem('level0', 'root_' + l.label));
        if (!enableRgpdLinks) {
            this.dynamicNavigation.clearMenuItem('level0', 'mentions-legales');
            this.dynamicNavigation.clearMenuItem('level0', 'cgurgpd');
            this.dynamicNavigation.clearMenuItem(
                'level0',
                'politique-confidentialite'
            );
            this.dynamicNavigation.clearMenuItem('level0', 'accessibility');
            this.addHelpAnonymous();
        }
    }

    /**
     * know if device is mobile..
     * @private
     */
    private isMobile(): boolean {
        let check = false;
        (function (a: string): void {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
                check = true;
            }
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    private checkIfQueryParamsForceLanguage(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            const queryParams = this.router.parseUrl(this.router.url).queryParams;
            const suggestedLanguageId = queryParams && queryParams['SuggestedLanguage'];

            // if there is no our query param, we don't need to do anything
            if (!suggestedLanguageId) {
                return;
            }
            const suggestedLanguage = this.languageService.availableLanguages.find(lang => lang.id === suggestedLanguageId);

            // if the language is already set, we don't need to do anything
            if (this.languageService.getActiveLanguage() === suggestedLanguage) {
                return;
            }
            this.languageService.setActiveLanguage(suggestedLanguage);

            // and now we remove the suggested language from the query params
            this.router.navigate([], {
                queryParams: {...queryParams, SuggestedLanguage: null},
                queryParamsHandling: 'merge'
            });
        });
    }

    private registerDefaultLinkWithoutAuthentication(): void {
        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'legalnotice',
            classes: 'legalnotice',
            title: 'Mentions légales',
            translate: 'navigation.legalnotice',
            type: 'item',
            icon: 'new_releases',
            url: '/page/conditions-generales',
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'privacy',
            classes: 'privacy',
            title: 'Privacy',
            translate: 'navigation.privacy',
            type: 'link',
            icon: 'shield',
            url: '/page/privacy',
        });
        this.addHelpAnonymous();
        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'needhelp',
            classes: 'needhelp',
            title: 'Need help',
            translate: 'navigation.needhelp',
            type: 'link',
            icon: 'help',
            url: '/',
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'how-to',
            title: 'how-to',
            translate: 'navigation.how-to',
            type: 'item',
            icon: 'idea',
            url: '/page/comment-ca-marche',
            displayIcon: true,
        });

        if (!this.authService.isGAR()) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'access-future-engineer',
                title: 'access future engineer',
                translate: 'navigation.access_future_engineer',
                type: 'item',
                icon: 'access-future-engineer',
                url: externalUrl && externalUrl['access-future-engineer'],
                externalUrl: externalUrl && !!externalUrl['access-future-engineer'],
                openInNewTab: externalUrl && !!externalUrl['access-future-engineer'],
                displayIcon: true,
            });
        }

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'external-url',
            title: 'navigate to external url',
            translate: this.authService.isLearner() ? 'navigation.external-url-learner' : 'navigation.external-url',
            type: 'item',
            url: `${baseAppUrl}#/session?token=${localStorage
                .getItem('http_accessToken')
                ?.slice(1, -1)}&redirectPath=lessons/home`,
            externalUrl: true,
            openInNewTab: false,
            displayIcon: false,
            function: (event, item) => this.askConsent(event, item)
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'contact-us',
            title: 'contact us',
            translate: 'navigation.contact_us',
            type: 'item',
            icon: 'contact-us',
            url: this.urlFromSettings('contact-us').url,
            externalUrl: this.urlFromSettings('contact-us').isExternalUrl,
            openInNewTab: this.urlFromSettings('contact-us').isExternalUrl,
            displayIcon: true,
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'mentions-legales',
            title: 'mentions-legales',
            translate: 'navigation.legalnotice',
            type: 'item',
            icon: 'visibility_legalnotice',
            url: this.urlFromSettings('mentions-legales').url,
            displayIcon: true,
            externalUrl: this.urlFromSettings('mentions-legales').isExternalUrl,
            openInNewTab: this.urlFromSettings('mentions-legales').isExternalUrl,
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'informations-legales',
            title: 'informations-legales',
            classes: 'legalnotice',
            translate: 'navigation.legal_notice',
            type: 'item',
            icon: 'policy-rounded-fill',
            url: '/informations-legales',
            displayIcon: true,
            externalUrl: false,
            openInNewTab: false,
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'qui-sommes-nous',
            title: 'qui-sommes-nous',
            classes: 'legalnotice',
            translate: 'navigation.who_are_we',
            type: 'item',
            icon: 'who_are_we',
            url: '/page/qui-sommes-nous',
            displayIcon: true,
            externalUrl: false,
            openInNewTab: false,
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'cgurgpd',
            classes: 'cgurgpd',
            title: 'cgurgpd',
            translate: 'navigation.cgurgpd',
            type: 'item',
            icon: 'visibility_cgurgpd',
            url: this.urlFromSettings('cgurgpd').url,
            displayIcon: true,
            externalUrl: this.urlFromSettings('cgurgpd').isExternalUrl,
            openInNewTab: this.urlFromSettings('cgurgpd').isExternalUrl,
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'accessibility',
            classes: 'accessibility',
            title: 'accessibility',
            translate: 'navigation.accessibility',
            type: 'item',
            icon: 'accessibility',
            url: '/page/accessibilite',
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'contact',
            classes: 'contact',
            title: 'contact',
            translate: 'navigation.contact',
            type: 'item',
            icon: 'help',
            url: this.urlFromSettings('contact').url,
            externalUrl: this.urlFromSettings('contact').isExternalUrl,
            openInNewTab: this.urlFromSettings('contact').isExternalUrl,
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'feedback',
            classes: 'contact',
            title: 'contact',
            translate: 'navigation.contact',
            type: 'item',
            icon: 'feedback',
            url: this.authService.isGAR() ? '/page/feedback-gar' : '/page/feedback',
        });


        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'politique-confidentialite',
            classes: 'politique-confidentialite',
            title: 'politique-confidentialite',
            translate: 'navigation.privacy',
            type: 'item',
            icon: 'visibility_privacy',
            url: this.urlFromSettings('politique-confidentialite').url,
            displayIcon: true,
            externalUrl: this.urlFromSettings('politique-confidentialite').isExternalUrl,
            openInNewTab: this.urlFromSettings('politique-confidentialite').isExternalUrl,
        });
    }

    /**
     * Replace help link with appropriate url
     */
    private updateHelpLink(Urlsuffix = ''): void {
        this.dynamicNavigation.clearMenuItem('level0', 'needhelp');
        this.translate
            .get('navigation.needhelp_url' + Urlsuffix)
            .subscribe((url: string) => {
                this.dynamicNavigation.refreshModuleMenu('level0', {
                    id: 'needhelp',
                    title: 'Need help',
                    translate: 'navigation.needhelp',
                    type: 'link',
                    icon: 'help',
                    url: url,
                });
            });
    }

    private getFurtherChildLinks(): FuseNavigationItem[] {
        return [
            {
                id: 'gameCodeList',
                title: 'gameCodeList',
                translate: 'generic.game_code_title',
                type: 'item',
                url: '/code/gamecode/list',
            },
            {
                id: 'gleamCodeList',
                title: 'gleamCodeList',
                translate: 'generic.gleam_code_title',
                type: 'item',
                url: '/code/gleamcode/list',
            },
            {
                id: 'brume',
                classes: 'brume',
                title: 'brume',
                translate: 'navigation.brume',
                type: 'item',
                url: '/collection/brume',
            },
            {
                id: 'activities',
                title: 'activities',
                translate: 'activities.title',
                type: 'item',
                url: '/activities/list'
            },
            {
                id: 'faq',
                title: 'faq',
                translate: 'generic.faq',
                type: 'item',
                icon: 'compass',
                url: '/faq',
            },
            {
                id: 'external-dictionary',
                title: 'navigate to external url',
                translate: 'navigation.external-url-dictionary',
                type: 'item',
                url: `${baseAppUrl}#/session?token=${localStorage
                    .getItem('http_accessToken')
                    ?.slice(1, -1)}&redirectPath=activities/dictionary`,
                externalUrl: true,
                openInNewTab: false,
                displayIcon: false,
                function: (event, item) => this.askConsent(event, item)
            },
            {
                id: 'external-language-lab',
                title: 'navigate to external url',
                translate: 'navigation.external-language-lab',
                type: 'item',
                url: `${baseAppUrl}#/session?token=${localStorage
                    .getItem('http_accessToken')
                    ?.slice(1, -1)}&redirectPath=activities/language-lab`,
                externalUrl: true,
                openInNewTab: false,
                displayIcon: false,
                function: (event, item) => this.askConsent(event, item)
            },
            ...this.getFurtherChildLinksFromSettings()
        ];
    }

    private askConsent($event, item): void {
        $event.preventDefault();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            bodyDialog: 'activities.redirect_' + this.authService.accessLevel + '_to_app',
            labelTrueDialog: 'generic.confirm',
            labelFalseDialog: 'generic.cancel'
        };

        for (const term in dialogConfig.data) {
            this.translate.get(dialogConfig.data[term]).subscribe((translation: string) => dialogConfig.data[term] = translation);
        }

        const modalRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        modalRef.afterClosed().subscribe(result => {
            if (result === true) {
                window.open(item.url, item.openInNewTab ? '_blank' : '_self'); // Redirige l'utilisateur si le résultat est true
            }
        });
    }

    /**
     * Add link to helper resources just for unlogged user
     * @private
     */
    private addHelpAnonymous(): void {
        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'help_anonymous',
            title: 'help',
            translate: 'navigation.general_help',
            type: 'item',
            icon: 'help',
            url: '/page/aide-aux-ressources',
        });
    }

    private urlFromSettings(id: string): { url: string, isExternalUrl: boolean } {
        const urls = {
            'cgurgpd': '/page/cgurgpd',
            'mentions-legales': '/page/mentions-legales',
            'politique-confidentialite': '/page/politique-confidentialite',
            'contact': '/feedback', // default value
        };

        const garUrls = {
            'cgurgpd': '/page/cgurgpd-gar',
            'mentions-legales': '/page/mentions-legales-gar',
            'politique-confidentialite': '/page/politique-confidentialite-gar',
            'contact-us': '/page/contact-us',
            'contact': '/feedback', // default value
        };

        const sapiensUrls = {
            'contact': 'https://unstoppable-us.com/contact', // contact for sapiens is an external website
        };

        if (this.authService.isGAR()) {
            if (garUrls[id]) {
                return {url: garUrls[id], isExternalUrl: false};
            }
        } else if (this.brand.toString() === 'sapiens' && sapiensUrls[id]) {
            return {url: sapiensUrls[id], isExternalUrl: true};
        } else if (urls[id]) {
            return {url: urls[id], isExternalUrl: false};
        }

        if (externalUrl && externalUrl[id]) {
            return {url: externalUrl[id], isExternalUrl: true};
        }

        return {url: '', isExternalUrl: undefined};
    }

    private getFurtherChildLinksFromSettings(): FuseNavigationItem[] {
        return furtherLinks.map(l => (
            {
                id: 'further_' + l.label,
                classes: l.label,
                title: l.label,
                translate: 'navigation.' + l.label,
                type: 'item',
                url: l.url,
            }
        ));
    }

    private handleFurtherMenu() {
        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'forFurther',
            title: 'Pour aller plus loin',
            translate: 'generic.for_further',
            type: 'collapse',
            icon: 'for_further',
            children: this.getFurtherChildLinks(),
        });
    }

    private handleEducationalResourcesMenu() {
        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'educational_resources',
            title: 'Ressources pédagogiques',
            translate: 'generic.educational_resources',
            type: 'collapse',
            icon: 'educational-resources',
            children: [
                {
                    id: 'ressources_humanum',
                    classes: 'ressources_humanum',
                    title: 'ressources_humanum',
                    translate: 'navigation.ressources_humanum',
                    type: 'item',
                    url: '/further/ressources-partenaires-ilove',
                },
                {
                    id: 'ressources_supplementaires',
                    classes: 'ressources_supplementaires',
                    title: 'ressources_supplementaires',
                    translate: 'navigation.ressources_supplementaires',
                    type: 'item',
                    url: '/further/ressources-supplementaires',
                }
            ],
        });
    }

    private handleExternalButton() {
        combineLatest([
            this.authorizationService.currentUserCan<Observable<boolean>>(GlobalAsyncRules.accessToExternalLinkButton),
            this.getMostImportantLicenceOfUser$().pipe(startWith('free')),
        ]).pipe(
            filter(([can, _licence]) => can),
        ).subscribe(([_can, licence]) => {
            let suffix = '';
            if (addRoleLicenceSuffixForExternalLink) {
                suffix = '_' + this.authService.accessLevel + '_' + licence;
            }
            this.translate
                .get('navigation.external-target-link-menu-url')
                .subscribe((translation: string) => {
                    this.dynamicNavigation.refreshModuleMenu('level0', {
                        id: 'external-target-link-menu',
                        classes: 'external-target-link-menu' + suffix,
                        title: 'Lien externe',
                        translate:
                            'navigation.external-target-link-menu' + suffix,
                        type: 'item',
                        icon: 'external-target-link-menu-icon',
                        url: translation,
                        externalUrl: true,
                        openInNewTab: true,
                        displayIcon: true,
                    });
                });
        });
    }

    private handleFurtherLinksAtRoot() {
        furtherLinks.forEach(l => {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'root_' + l.label,
                title: l.label,
                translate: 'navigation.' + l.label,
                type: 'item',
                url: l.url,
            });
        });
    }
}
