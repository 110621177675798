import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-medal',
    templateUrl: './medal.component.html',
    styleUrls: ['./medal.component.scss']
})
/**
 * simple componenent take in input 'locked' , 'bronze', 'silver', 'gold' or 'platinium'
 * who correspond to the name of the icon we will use
 * we just add 'medal_' because in svgiconlist we call it :'medal_gold' , 'medal_silver', for more clarity on svg role
 */
export class MedalComponent implements OnInit {
    @Input('medal') medal;

    constructor() {
    }

    ngOnInit(): void {
    }
}