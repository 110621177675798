// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link {
  position: absolute;
  border-radius: 10px 10px 0;
  border-right: 2px solid #979797;
  z-index: -100;
}
.link.corner {
  border-top: 2px solid #979797;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/research-sheet/core/research-sheet/research-sheet-display/research-sheet-display-link/research-sheet-display-link.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,0BAAA;EACA,+BAAA;EACA,aAAA;AACF;AACE;EACE,6BAAA;AACJ","sourcesContent":[".link {\n  position: absolute;\n  border-radius: 10px 10px 0;\n  border-right: 2px solid #979797;\n  z-index: -100;\n\n  &.corner {\n    border-top: 2px solid #979797;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
