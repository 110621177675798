import {DynamicGraphFilters} from '@modules/graph-humanum/core/model/dynamic-graph-filters';
import * as moment from 'moment';

export const AttendanceFilters: DynamicGraphFilters = {
    always: [{
        label: 'startDate',
        value: moment().add(-2, 'weeks').startOf('day').toDate(),
        custom: {
            rules: ['required']
        }
    }, {
        label: 'endDate',
        value: moment().endOf('day').toDate(),
        custom: {
            rules: ['required']
        }
    }, {
        label: 'group',
        value: null,
        custom: {
            rules: ['ignore', 'autofill:multiLearner']
        }
    }, {
        label: 'workgroup',
        value: null,
        custom: {
            rules: ['ignore', 'autofill:multiLearner']
        }
    }, {
        label: 'multiLearner',
        value: [],
        custom: {
            rules: ['required']
        }
    }],
    hidden: []
};
