import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {GraphMathiaService} from './services/graph-mathia.service';
import {ProgressGraphComponent} from './component/progress-graph/progress-graph.component';
import {LevelGraphComponent} from './component/level-graph/level-graph.component';
import {AttendanceGraphComponent} from './component/attendance-graph/attendance-graph.component';
import {SharedFiltersComponent} from './component/shared-filters/shared-filters.component';
import {GraphSelectorComponent} from './component/graph-selector/graph-selector.component';
import {MultiGraphViewComponent} from './component/multi-graph-view/multi-graph-view.component';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from 'shared/shared.module';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {CdkTableModule} from '@angular/cdk/table';
import {StatisticsGraphComponent} from './component/statistics-graph/statistics-graph.component';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {ErrorsGraphComponent} from '@modules/graph-mathia/core/component/errors-graph/errors-graph.component';
import {FakeStepperComponent} from './component/fake-stepper/fake-stepper.component';
import {SingleGraphViewComponent} from './component/single-graph-view/single-graph-view.component';
import {defaultRoute} from '../../../settings';
import {IsLearnerGuard} from '../../../guards/is-learner.guard';
import {IsAtLeastTrainerGuard} from '../../../guards/is-at-least-trainer-guard.service';
import {GraphMathiaRoutes} from '@modules/graph-mathia/core/graph-mathia.routes';
import {OwnProgressGraphComponent} from './component/own-progress-graph/own-progress-graph.component';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '@modules/graph-mathia/core/model/rules';
import {GraphMathiaAuthorizationService} from './services/graph-mathia-authorization.service';
import {FuseNavigationItem} from 'fuse-core/types';

const routes: Routes = [
    {
        path: 'graph-mathia',
        children: [
            {
                path: 'multi',
                component: MultiGraphViewComponent,
                children: [
                    {path: GraphMathiaRoutes.Attendance, component: AttendanceGraphComponent},
                    {path: GraphMathiaRoutes.Progress, component: ProgressGraphComponent},
                    {path: GraphMathiaRoutes.Errors, component: ErrorsGraphComponent},
                    {path: GraphMathiaRoutes.Level, component: LevelGraphComponent},
                    {path: GraphMathiaRoutes.Statistics, component: StatisticsGraphComponent},
                    {
                        path: '**',
                        redirectTo: GraphMathiaRoutes.Errors,
                        pathMatch: 'full'
                    }
                ],
                canActivate: [IsAtLeastTrainerGuard]
            },
            {
                path: 'single',
                component: SingleGraphViewComponent,
                children: [
                    {path: GraphMathiaRoutes.OwnProgress, component: OwnProgressGraphComponent},
                ],
                canActivate: [IsLearnerGuard]
            },
            {
                path: '**',
                redirectTo: defaultRoute,
                pathMatch: 'full'
            }
        ],
        canActivate: [IsUserLogged]
    }
];

@NgModule({
    declarations: [
        AttendanceGraphComponent,
        MultiGraphViewComponent,
        GraphSelectorComponent,
        ErrorsGraphComponent,
        LevelGraphComponent,
        ProgressGraphComponent,
        SharedFiltersComponent,
        StatisticsGraphComponent,
        FakeStepperComponent,
        SingleGraphViewComponent,
        OwnProgressGraphComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, {}),
        SharedModule,
        FuseSharedModule,
        MatProgressSpinnerModule,
        CdkTableModule
    ],
    providers: [
        IsUserLogged
    ]
})
export class GraphMathiaModule {
    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private communicationCenter: CommunicationCenterService,
        private graphMathiaAuthorizationService: GraphMathiaAuthorizationService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.graphMathiaAuthorizationService.activeRulesOnStartup();
    }


    static forRoot(): ModuleWithProviders<GraphMathiaModule> {

        return {
            ngModule: GraphMathiaModule,
            providers: [
                GraphMathiaService
            ]
        };
    }

    private postLogout(): void {
        GraphMathiaModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'graph-mathia');
    }

    private postAuthentication(): void {
        try {
            if (!GraphMathiaModule.isMenuSet && this.authorizationService.currentUserCan<boolean>(SyncRules.seeGraphsLinksInMenu)) {
                this.dynamicNavigation.registerModuleMenu('level0', {
                    'id': 'graph-mathia',
                    'title': 'graph-mathia',
                    'translate': 'graph_mathia.menu_link',
                    'type': 'item',
                    'icon': 'analytics',
                    'url': '/graph-mathia/multi',
                });

                Object.keys(GraphMathiaRoutes)
                    .map(route => GraphMathiaRoutes[route].toLowerCase())
                    .map(route => {
                            const menuItem: FuseNavigationItem = {
                                'id': `graph-mathia-single-${route}`,
                                'title': `graph-mathia-single-${route}`,
                                'translate': `graph_mathia.single_${route}_link`,
                                'type': `item`,
                                'icon': `analytics`,
                                'url': `/graph-mathia/single/${route}`,
                            };
                            if (this.authService.isLearner() && !this.authService.isGAR()) {
                                menuItem.classes = 'no-adventure-app-link-exist-below';
                            }
                            return menuItem;
                    }).forEach(menuLink => {
                    this.dynamicNavigation.registerModuleMenu('level0', menuLink);
                });

                GraphMathiaModule.isMenuSet = true;
            }
        } catch (e) {
            console.error(e);
        }
    }
}
