import {ModuleIconInterface} from "../module-icon.interface";

export class SvgIcon {
    [key: string]: string | ModuleIconInterface
}

export const SVGICONTHEME: SvgIcon = {
    'accessibility': 'accessibility',
    'accessorize': 'accessorize',
    'admin-role': 'admin-role',
    'allTypes': 'toustypes',
    'archive': 'archiver',
    'arrow_back': 'arrow_left',
    'arrow_diag': 'arrow_diag',
    'arrow_left': 'arrow_left',
    'arrow_left_circle_outline': 'arrow_left_circle_outline',
    'arrow_right': 'arrow_right',
    'arrow_right_circle_outline': 'arrow_right_circle_outline',
    'assignment_ind': 'assignment_ind',
    'avatar': 'avatar',
    'bookmark-added': 'bookmark_added',
    'brand': 'brand',
    'brand_menu': 'brand_menu',
    'brand_menu_large': 'brand_menu_large',
    'brand_square': 'brand_square',
    'check': 'check_rounded_700',
    'chevron-left-rounded-extra-light': 'chevron_left_rounded_extra_light',
    'class_add': 'add',
    'close': 'cross',
    'contact': 'contact',
    'credits': 'credits',
    'cross': 'cross',
    'download-pdf': 'cgu',
    'editor-play': 'play_outline',
    'trash': 'trash',
    'cross_outline': 'cross_outline',
    'delete': 'trash',
    'document': 'document',
    'drag_indicator': 'arrow_down_circle_outline',
    'duplicate': 'duplicate',
    'edit': 'edit',
    'educ-role': 'educ-role',
    'educ-role-without-rec': 'educ-role-without-rec',
    'exit': 'exit',
    'file': 'corpus',
    'file_download': 'menu_book_fill',
    'fill-in-blanks': 'remplir_blancs',
    'group': 'group',
    'groups-form-add': 'check',
    'icon-arrow-back': 'arrow_back',
    'information': 'information',
    'institution_add': 'add',
    'join': 'join',
    'lessons': 'transition',
    'link': 'url',
    'lock': 'padlock',
    'mail': 'mail',
    'next': 'arrow_right_alt_rounded',
    'pdf': 'ressources',
    'play': 'play',
    'play-as-icon': 'play',
    'play-preview': 'visibility',
    'play-lesson': 'play_outline',
    'play-quiz': 'quiz_rounded',
    'plus': 'plus',
    'quit': 'logout_rounded_flipped_500',
    'retry': 'refresh_rounded_alt',
    'round-videoprojeter': 'round_videoprojeter',
    'round-videoprojeteroff': 'round_videoprojeteroff',
    'save': 'save',
    'shield': 'confi',
    'step-done': 'step_done',
    'step-inprogress': 'step_inprogress',
    'step-pasfait': 'step_pasfait',
    'step_success': 'step_done',
    'student_add': 'check_circle_outline',
    'tooltip-info': 'info',
    'trainer_add': 'add',
    'transition': 'transition',
    'unarchive': 'desarchiver',
    'unassign': 'unassign',
    'unlock': 'unlock',
    'user-menu': 'keyboard_arrow_down_rounded',
    'visibility': 'visibility',
    'visibility_cgurgpd': 'cgu',
    'visibility_legalnotice': 'visibility',
    'visibility_privacy': 'privacy',
    'workgroup_add': 'add',
    'world': 'world',
    'xxx': 'dislike',
};