export const assignation = {
    assignableLesson: true,
    columnsDashboardWidget: {
        default: ['type', 'title', 'start_date', 'end_date', 'group', 'workgroup', 'progress']
    },
    completionStartDateOnly: ['*'],
    rolesCanShowBannerInfo: ['administrator', 'manager', 'director'],
    defaultType: 'training',
    defaultStartDate: true,
    displayNews: {
        default: ['myActivitiesWelcome']
    },
    doTrainersHaveRestrictiveRights: true,
    followedTabsList: ["assignation.groups-list-detail", "assignation.groups-list", "assignation.usersaves-list"],
    assignationGroupsActive: ['trainer'],
    isUserSaveListActive: ['learner', 'trainer'],
    assignationGroupsDetailActive: ['learner'],
    assignationGroupsFields: {
        default: [ 'exoName', 'beginDate'],
        learner: []
    },
    followedLogBookFields: {
        default: ['group', 'type', 'exoName', 'learner', 'beginDate', 'endDate'],
        learner: []
    },
    userSavesList: {
        default: [],
        learner: [],
        trainer: ["group", "learner"]
    },
    formFields: {
        default: ['group', 'learner', 'start_date', 'assignment_type', 'activities'],
    },
    genericAssignmentListAllowedActions: {
        default: [],
        director: ['unassign', 'editAssign'],
        trainer: ['unassign', 'editAssign'],
    },
    genericAssignmentListDisableOpening: true,
    genericAssignmentListFilters: {
        default: ['title', 'group', 'workgroup', 'learner'],
        learner: ['title']
    },
    genericAssignmentListShowHours: false,
    genericAssignmentsListCheckDefaultFiltersInUrl: true,
    genericAssignmentListColumns: {
        default: ['assigned_node_title', 'start_date', 'assigned_user', 'comment', 'buttons']
    },
    hasCompletionTime: false,
    hasType: false,
    hideLearnerAutoAssignments: true,
    isAutoAssignmentForced: true,
    menuLearnerMustBeInGroup: true,
    noFilterforAssignmentsWidget: true,
    rolesCanShowBannerInfoClosedAssignment: [],
};
