import {Injectable} from '@angular/core';
import {CommunicationCenterService} from "@modules/communication-center";
import {Observable, of, ReplaySubject, Subject} from "rxjs";
import {DataCollection, DataEntity} from "octopus-connect";
import {mergeMap, take, tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AssignationService {
    private currentUser: DataEntity;
    private assignmentStates: DataEntity[];

    constructor(
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.currentUser = data;
                } else {
                    this.currentUser = null;
                }
            });
        this.communicationCenter
            .getRoom('assignments')
            .getSubject('statesList')
            .subscribe((states: DataEntity[]) => this.assignmentStates = states);
    }
    
    public get currentUserId(): number {
        return this.currentUser ? +this.currentUser.id : null;
    }

    public getAssignmentsByLessonId(id: string | number): Observable<DataCollection> {
        const assignmentsCallback$ = new ReplaySubject<Observable<DataCollection>>(1)
            .pipe(
                take(1),
                mergeMap(dataCollection$ => dataCollection$)
            );

        this.communicationCenter
            .getRoom('assignment')
            .next('getAssignmentsByLesson$', {
                lessonId: id,
                onComplete: assignmentsCallback$,
            });

        return assignmentsCallback$;
    }

    public createAssignment(lessonId: string | number, callback: (assignment: DataEntity) => void): void {
        if (this.currentUser) {
            const dataForAssignments = {
                assignated_node: lessonId,
                assignated_user: this.currentUser.id,
                assignator: this.currentUser.id,
                dates: {value: 0, value2: 0},
                state_term: +(this.assignmentStates.find((state: DataEntity) => state.get('label') === 'assigned').id)
            };

            this.communicationCenter
                .getRoom('assignment')
                .next('createAssignment', {
                    assignment: dataForAssignments,
                    callback: callback,
                    dontUseSpecificConfiguration: true,
                });
        }
    }

    public createAssignment$(lessonId: string | number): Observable<DataEntity> {
        if (this.currentUser) {
            return new Observable((observer) => {
                const dataForAssignments = {
                    assignated_node: lessonId,
                    assignated_user: this.currentUser.id,
                    assignator: this.currentUser.id,
                    dates: {value: 0, value2: 0},
                    state_term: +(this.assignmentStates.find((state: DataEntity) => state.get('label') === 'assigned').id)
                };

                this.communicationCenter
                    .getRoom('assignment')
                    .next('createAssignment', {
                        assignment: dataForAssignments,
                        callback: (assignment: DataEntity) => observer.next(assignment),
                        dontUseSpecificConfiguration: true,
                    });
            });
        }
        
        return of(null);
    }
    
    public setCurrentAssignment(assignment: DataEntity): void {
        this.communicationCenter
            .getRoom('assignment')
            .next('current', assignment);
    }
}
