import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leanersByGroupByWorkingGroup'
})
export class LearnersByGroupByWorkingGroupPipe implements PipeTransform {

  transform(learners: any[], group: any, wgroup: any): any[] {
    if (Array.isArray(learners)) {
        let filtredList = learners;
        if (typeof group !== 'undefined') {
            filtredList = filtredList.filter(item => group === 'all' || item.groups.includes(group));
        }
        if (typeof wgroup !== 'undefined') {
            filtredList = filtredList.filter(item => wgroup === 'all' || item.workgroups.includes(wgroup));
        }
        return filtredList;
    } else {
        return [];
    }
  }

}
