import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {DomSanitizer} from '@angular/platform-browser';
import {filter, mergeMap, takeUntil, tap} from 'rxjs/operators';
import {GraphMathiaService} from '@modules/graph-mathia/core/services/graph-mathia.service';
import {Subject} from 'rxjs';
import {defaultApiURL} from 'app/settings';

@Component({
  selector: 'app-statistics-graph',
  templateUrl: './statistics-graph.component.html',
  styleUrls: ['./statistics-graph.component.scss']
})
export class StatisticsGraphComponent implements OnInit, OnDestroy {

  private iframeUrl = null;
  public isReady = false;
  private unsubscribeInTakeUntil = new Subject<void>();
  private userAccessToken: string;
  private defaultApiURL: string = defaultApiURL;


  constructor(
      private communicationCenter: CommunicationCenterService,
      private sanitizer: DomSanitizer,
      private graphMathiaService: GraphMathiaService
  ) {
      this.communicationCenter
          .getRoom('authentication')
          .getSubject('userAccessToken')
          .subscribe((token: string) => {
              if (token) {
                  this.userAccessToken = token;
              }
          });
  }

  ngOnInit(): void {
    this.graphMathiaService.isReady
        .pipe(
            filter(isReady => !!isReady),
            tap(() => this.graphMathiaService.graphDataArePending.subscribe(() => this.isReady = false)),
            mergeMap(() => this.graphMathiaService.getStatisticsGraphData()),
            takeUntil(this.unsubscribeInTakeUntil),
        ).subscribe((data) => {
      this.getIframeUrl(data.group);
      this.isReady = true;
    });

  }

  ngOnDestroy(): void {
    this.unsubscribeInTakeUntil.next();
    this.unsubscribeInTakeUntil.complete();
  }

  getIframeUrl(group: string): void{
    let preprodVar = '';
    if (this.defaultApiURL.includes('preprod')){ // check if we are in preprod
      preprodVar = '&preprod=true';
    }
    if (group && this.userAccessToken){
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://api.mathia.education/sso?uid=' + this.getCurrentUser().id + '&codeClasse=' + group + '&token=' + this.userAccessToken + preprodVar);
    }
  }

  getCurrentUser(): DataEntity{
    return this.graphMathiaService.currentUser;
  }
}
