import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {GraphDysappService} from './services/graph-dysapp.service';
import {ProgressGraphComponent} from './component/progress-graph/progress-graph.component';
import {FiltersComponent} from './component/filters/filters.component';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from 'shared/shared.module';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {CdkTableModule} from '@angular/cdk/table';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {SingleGraphViewComponent} from '@modules/graph-dysapp/core/component/single-graph-view/single-graph-view.component';

const routes: Routes = [
    {
        path: 'graph-dysapp',
        component: SingleGraphViewComponent,
        children: [{
            path: '',
            component: ProgressGraphComponent,
        }],
        canActivate: [IsUserLogged]
    }
];

@NgModule({
    declarations: [
        ProgressGraphComponent,
        SingleGraphViewComponent,
        FiltersComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, {}),
        SharedModule,
        FuseSharedModule,
        MatProgressSpinnerModule,
        CdkTableModule
    ],
    providers: [
        IsUserLogged
    ]
})
export class GraphDysappModule {
    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }


    static forRoot(): ModuleWithProviders<GraphDysappModule> {

        return {
            ngModule: GraphDysappModule,
            providers: [
                GraphDysappService
            ]
        };
    }

    private postLogout(): void {
        GraphDysappModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'graph-dysapp');
    }

    private postAuthentication(): void {
        if (!GraphDysappModule.isMenuSet && this.authService.hasLevel(['trainer', 'learner'])) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': `graph-dysapp`,
                'title': `graph-dysapp`,
                'translate': `graph_dysapp.progress_link`,
                'type': `item`,
                'icon': `analytics`,
                'url': `/graph-dysapp`,
            });

            GraphDysappModule.isMenuSet = true;
        }
    }
}
