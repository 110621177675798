import {DataEntity} from 'octopus-connect';
import * as moment from 'moment';

export class AgendaEventUI {
    id: string | number;
    start: string;
    end: string;
    title: string;
    extendedProps: {
        role: string;
        notes?: string;
        entity?: DataEntity;
        type: 'event' | 'assignation';
        members?: Array<number | string>;
        assignationType?: 'homework' | 'assessment';
    };
    classNames: Array<string>;

    static fromEventEntity(event: DataEntity): AgendaEventUI {
        const data = event.attributes as AgendaEventAPI;
        const start = data.dates ? moment.unix(data.dates.value).format() : null;
        const end = data.dates ? moment.unix(data.dates.value2).format() : null;
        let role: string;
        if (data.author) {
            if (data.author.role.indexOf(4) > -1) {
                role = 'manager';
            } else if (data.author.role.indexOf(5) > -1) {
                role = 'trainer';
            } else if (data.author.role.indexOf(6) > -1) {
                role = 'learner';
            }
        }
        return {
            id: event.id,
            start,
            end,
            title: data.label,
            extendedProps: {
                role,
                notes: data.body,
                entity: event,
                type: 'event',
                members: data.members
            },
            classNames: [role]
        };
    }


    static fromAssignationEntity(event: DataEntity): AgendaEventUI {
        const data = event.attributes as AgendaAssignationAPI;
        const start = moment.unix(data.startDate).format();
        const end = moment.unix(data.endDate).format();
        const role = 'trainer';

        return {
            id: event.id,
            start,
            end,
            title: data.lesson,
            extendedProps: {
                role,
                assignationType: data.type_term ? data.type_term.label : null,
                entity: event,
                type: 'assignation',
                members: this.convertDataToGetMembersId(event)
            },
            classNames: [data.type_term ? data.type_term.label : '']
        };
    }

    /**
     * the members Id are store like an attribute of the object :
     * extract the id by getting the attribute of the object => very moche
     * @param event : DataEntity
     */
    private static convertDataToGetMembersId(event: DataEntity): string[] {
        const myMembers: string[] = [];
        const objectKeys = Object.keys(event.attributes.assignations) as Array<keyof any>;
        for (const key of objectKeys) {
            myMembers.push(key.toString());
        }
        return myMembers;
    }
}

export class AgendaEventAPI {
    label: string;
    body: string;
    dates: {
        value: number;
        value2: number;
    };
    author?: any;
    members?: Array<number | string>;


    static fromUI(event: AgendaEventUI): AgendaEventAPI {
        return {
            label: event.title,
            body: event.extendedProps.notes,
            dates: {
                value: moment(event.start).unix(),
                value2: moment(event.end).unix()
            },
            members: event.extendedProps.members ? event.extendedProps.members : []
        };

    }
}

export class AgendaAssignationAPI {
    lesson: string;
    startDate: number;
    endDate: number;
    members?: Array<number>;
    type_term?: { id: string, label: 'homework' | 'assessment' };
}

export class EventFilters {
    members?: Array<string | number>;
    type_term?: string | number;
    date_from?: number;
    date_to?: number;
    label?: string;
    group?: string | number;
}
