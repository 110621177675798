
import {takeUntil} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SectionDisplayService} from '@modules/research-sheet/core/section-display/section-display.service';
import {ResearchSection} from '@modules/research-sheet/core/definitions';
import {Subscription, Subject} from 'rxjs';

@Component({
  selector: 'fuse-section-comment-trainer',
  templateUrl: './section-comment-trainer.component.html',
  styleUrls: ['./section-comment-trainer.component.scss']
})
export class SectionCommentTrainerComponent implements OnInit, OnDestroy {
  @Input('sectionDetail') sectionDetail: any;

  formSubsection: UntypedFormGroup;
  formErrors: any;
  public stateVal: string[];
  currentState: string = 'pending';
  currentCommentArray: Array<any>;
  newComment: '';

  section: ResearchSection;

  popUpEditComment: boolean;

  private states = {
    validated: 'chat_happy',
    correct: 'chat_sad',
    pending: 'hourglass',
    closed: 'chat_close'
  };

  private unsubscribeInTakeUntil = new Subject<void>();

  constructor( private formBuilder: UntypedFormBuilder,  private sectionService: SectionDisplayService) { }

  ngOnInit(): any {

    this.sectionDetail.subscribe( (data) => {
      this.section = data.currentSection;
      this.currentState = data.currentSection ? data.currentSection.state : '';
      this.currentCommentArray = data.currentSection ? data.currentSection.comment : [];
    });

    this.stateVal = ['validated', 'correct', 'closed'];

    this.formErrors = {
      comments: {},
      state: {}
    };

    this.formSubsection = this.formBuilder.group({
      comments: [this.newComment],
      state: [this.currentState, Validators.required],
    });

    this.formSubsection.valueChanges.subscribe(() => {
      this.onSubsectionFormValuesChanged();
    });

    this.sectionService.sectionCommentReady.pipe(
        takeUntil(this.unsubscribeInTakeUntil))
        .subscribe((ready: boolean) => {
            if (this.formSubsection.valid && ready) {
                this.sectionService.getDataForm(this.formSubsection.value, this.section);
            }
        });
  }

  ngOnDestroy(){
    this.unsubscribeInTakeUntil.next();
    this.unsubscribeInTakeUntil.complete();
  }

  onSubsectionFormValuesChanged(){
    for ( const field in this.formErrors )
    {
      if ( !this.formErrors.hasOwnProperty(field))
      {
        continue;
      }

      // Clear previous errors
      this.formErrors[field] = {};

      // Get the control
      const control = this.formSubsection.get(field);


      if ( control && control.dirty && !control.valid )
      {
        this.formErrors[field] = control.errors;
      }
    }
  }

  validForm(){
    this.sectionService.getSectionCommentReady();
  }

  closeTrainerComment(){
    this.sectionService.showComment(false);
  }

  getIcon(icon): string {
    return this.states[icon];
  }

}
