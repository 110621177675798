// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* notifications */
.unread mat-icon {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/notification/core/notif-list/notif-list.component.scss"],"names":[],"mappings":"AAAA,kBAAA;AACA;EACE,UAAA;AACF","sourcesContent":["/* notifications */\n.unread mat-icon{\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
