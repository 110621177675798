import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FlashCardInterface} from 'fuse-core/components/flashcard/flash-card.interface';
import {CommunicationCenterService} from '@modules/communication-center';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {LanguageLabService} from '@modules/activities/core/language-lab/language-lab.service';
import {Subject} from 'rxjs';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ClosingPopUpComponent} from '@modules/activities/core/language-lab/closing-pop-up/closing-pop-up.component';

@Component({
    selector: 'app-language-lab-card-player',
    templateUrl: './language-lab-card-player.component.html',
})

export class LanguageLabCardPlayerComponent implements OnDestroy, OnInit, AfterViewInit {
    @Input() cards: FlashCardInterface[] = [];
    @Input() mainCardTitle = '';
    @Input() mainCardPicture = '';
    @Output() close = new EventEmitter<boolean>();
    @Input() showResume = true;
    public unlockDialogDone = false;
    private initShowResume = false;
    currentCardIndex = 0;
    recordAnotherTime = false;
    allUserAudio: { id: string, blob: Blob }[] = [];
    enableNextButton = false;
    currentCardSelectedInSummary: FlashCardInterface;
    progress = 0;
    public instruction = '';
    public instructionAudio = '';
    private destroy$: Subject<void> = new Subject<void>();


    constructor(private communicationCenter: CommunicationCenterService,
                private translate: TranslateService,
                private languageLabService: LanguageLabService,
                private dialog: MatDialog,) {
        // hide header
        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'is-player-active');
    }

    ngOnInit(): void {
        this.currentCardSelectedInSummary = this.cards[0];
        this.progress = 100 / this.cards.length;
        this.instruction = this.translate.instant(`generic.lab_lang_card_consigne`);
        this.loadInstructionAudio();
    }

    ngAfterViewInit(): void {
        this.initShowResume = this.showResume;
    }

    private loadInstructionAudio(): void {
        if (this.instruction) {
            this.languageLabService.getAudio(this.instruction).pipe(
                takeUntil(this.destroy$)
            ).subscribe(audio => {
                if (audio) {
                    this.instructionAudio = audio;
                }
            });
        }
    }

    closeActivity(): void {
        if (this.showResume || this.initShowResume === false) {
            this.currentCardIndex = 0;
            this.showResume = true;
            this.allUserAudio = [];
            this.progress = 0;
            this.close.emit(true);
        } else {
            const dialogRef = this.dialog.open(ClosingPopUpComponent);
            dialogRef.afterClosed().subscribe(result => {
                if (result === 'close') {
                    this.currentCardIndex = 0;
                    this.showResume = true;
                    this.allUserAudio = [];
                    this.progress = 0;
                    this.close.emit(true);
                }

                if (result === 'reply') {
                    this.showResume = true;
                    this.unlockDialogDone = true;
                }
            });
        }
    }

    public recordAgain(index: number): void {
        this.showResume = false;
        this.currentCardIndex = index;
        this.recordAnotherTime = true;
    }

    next(): void {
        if (this.recordAnotherTime === true) {
            this.recordAnotherTime = false;
            this.showResume = true;
            this.unlockDialogDone = true;
            return;
        }

        if (this.currentCardIndex < this.cards.length - 1) {
            this.currentCardIndex = this.currentCardIndex + 1;
            this.enableNextButton = false;
            this.progress = this.progress + 100 / this.cards.length;
        } else {
            if (this.initShowResume) {
                this.showResume = true;
                this.unlockDialogDone = true;
            } else {
                this.progress = 100;
                setTimeout(() => {
                    this.closeActivity();
                }, 500);
            }
        }
    }

    get card(): FlashCardInterface {
        return this.cards[this.currentCardIndex];
    }

    public activityDone(): void {
        this.enableNextButton = true;
    }

    ngOnDestroy(): void {
        this.allUserAudio = [];
        this.destroy$.next();
        this.destroy$.complete();
        this.currentCardIndex = 0;
        this.showResume = true;
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'is-player-active');
    }

    userAudio(audio: Blob): void {
        if (!this.allUserAudio.find(c => c.id === this.cards[this.currentCardIndex].id)) {
            this.allUserAudio.push({id: this.cards[this.currentCardIndex].id, blob: audio});
        } else {
            this.allUserAudio.filter(c => c.id === this.cards[this.currentCardIndex].id)[0].blob = audio;
        }
    }

    launch(): void {
        this.showResume = false;
    }

}
