import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {map, mergeMap, take, tap} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';
import {
    CollectionOptionsInterface,
    DataCollection,
    DataEntity,
    OctopusConnectService,
    PaginatedCollection
} from 'octopus-connect';
import {UserDataEntity} from '@modules/authentication';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';

import {Subscription} from 'rxjs/internal/Subscription';

interface AssignmentInChapter {
    id: string,
    type?: string,
    assignated_node: string
}

interface Chapter {
    assignations?: { [key: string]: { id: string, type: string | null, assignated_node: string } },
    chapterId: string,
    progress?: number,
    progressChapter?: Progress[],
    sequences?: number[],
    state?: string,
}

interface ChapterForDisplay {
    id: string | number,
    label: string,
}

interface DataForAssignment {
    lessonEntity: DataEntity;
    subLessonNotCompleted: {
        id: string,
        type: string,
        title: string
    };
    stateOfSubLesson: { [key: string]: {completed: boolean, changed?: number} };
    assignment: AssignmentInChapter;
    chapter?: Chapter;
    groups?: string[];
}

interface Progress {
    [key: number]: {
        score: number,
        scoreByActivities: { [key: string]: number },
        changed?: number
    };

    assignationId?: number;
    group?: string[];
}

@Component({
    selector: 'app-progression',
    templateUrl: './progression.component.html',
    styleUrls: ['./progression.component.scss']
})
export class ProgressionComponent implements OnInit, OnDestroy {
    private userData: UserDataEntity;
    private assignments: DataEntity[] = [];
    private assignment: DataEntity;
    private progression: { [key: string]: boolean } = {};
    public lesson: DataEntity;
    public subLessons: {
        id: string;
        type: string;
        title: string;
    }[] = [];
    public educationalLevels: DataEntity[] = [];
    public chapters: DataEntity[] = [];
    public concepts: DataEntity[] = [];
    public classControl: FormControl<{ [key: string]: any }>;
    public workgroupControl: FormControl<{ [key: string]: any }>;
    public conceptControl: FormControl<DataEntity>;
    public currentConcept: DataEntity;
    public currentChapter: DataEntity;
    public chaptersToDisplay: ChapterForDisplay[] = [];

    private userProgression: {
        chapters: Chapter[]
    };
    public ready = false;
    public subLessonNotCompleted: {
        id: string;
        type: string;
        title: string;
    };
    public classes: { [key: string]: any }[] = [];
    public workgroups: { [key: string]: any }[] = [];
    private dataWithSubLessonNotCompleted: DataForAssignment;
    private assignmentStates: DataEntity[] = [];
    private assignmentTypes: any[] = [];
    private isProgressionWithContinuity = true;
    private obsSubscription: Subscription;

    constructor(public dialogRef: MatDialogRef<ProgressionComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    assignment: DataEntity,
                    retryAssignment: boolean
                },
                private communicationCenter: CommunicationCenterService, private octopusConnect: OctopusConnectService,
                private router: Router) {
        this.assignment = this.data.assignment;

        this.communicationCenter.getRoom('assignments').getSubject('assignmentTypes').pipe(
            tap((assignmentsTypes) => this.assignmentTypes = assignmentsTypes)
        ).subscribe();

    }

    ngOnDestroy() {
        if (this.obsSubscription) {
            this.obsSubscription.unsubscribe();
        }
    }

    ngOnInit() {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: UserDataEntity) => {
                this.userData = data;
                this.initialize();
            });
    }

    private initialize(): void {
        combineLatest([this.loadClasses(), this.loadWorkgroups(), this.loadChapters(), this.loadConcepts(), this.loadAssignmentState()])
            .pipe(
                tap(([classes, workgroups, chapters, concepts, assignmentStates]) => {
                    this.classes = classes;
                    this.workgroups = workgroups;
                    this.chapters = chapters;
                    this.concepts = concepts;
                    this.assignmentStates = assignmentStates;
                }),
                mergeMap(() => this.loadUserProgression().pipe(take(1))),
                mergeMap((userProgression) => {
                    this.userProgression = userProgression;
                    return this.loadSequenceAssociatedData();
                }),
                tap((data: DataForAssignment) => {
                    this.dataWithSubLessonNotCompleted = data;
                    if (this.dataWithSubLessonNotCompleted) {
                        this.subLessonNotCompleted = this.dataWithSubLessonNotCompleted.subLessonNotCompleted;
                        this.currentChapter = this.chapters.find((chapter) => +chapter.id === +this.dataWithSubLessonNotCompleted?.chapter?.chapterId);
                        this.lesson = this.dataWithSubLessonNotCompleted.lessonEntity;
                        this.subLessons = this.dataWithSubLessonNotCompleted.lessonEntity.get('reference');
                    } else {
                        // que faire si l'utilisateur a fini toutes les seances parmis toutes les sequences des periodes existantes pour cette matiere
                    }


                    this.currentConcept = this.concepts.find((concept) => concept.id === this.assignment.get('assignated_node').concepts[0].id);

                    this.conceptControl = new FormControl<DataEntity>(this.currentConcept);
                    if (this.assignment?.get('groups')?.length) {
                        const classe = this.classes.find((c) => this.assignment.get('groups')?.map((g) => +g.id).includes(+c.id));
                        const workgroup = this.workgroups.find((c) => this.assignment.get('groups')?.map((g) => +g.id).includes(+c.id));
                        if (classe) {
                            this.classControl = new FormControl<{ [key: string]: any }>(classe);
                        }
                        if (workgroup) {
                            this.workgroupControl = new FormControl<{ [key: string]: any }>(workgroup);
                        }

                    }
                    // TODO: en attente du fonctionnel associé aux "select" pour classes et groupes
                    if (this.classControl) {
                        this.classControl.valueChanges.pipe(
                            tap((val: DataEntity) => {
                                if (!val) {
                                    // console.log('classControl', val);
                                }
                            })
                        ).subscribe();
                    }
                    if (this.workgroupControl) {
                        this.workgroupControl.valueChanges.pipe(
                            tap((val: DataEntity) => {
                                if (!val) {
                                    // console.log('workgroupControl', val);
                                }
                            })
                        ).subscribe();
                    }
                    this.conceptControl.valueChanges.pipe(
                        tap((val: DataEntity) => {
                            if (!val) {
                                // console.log('concept', val);
                            }
                        })
                    ).subscribe();


                    let currentChapterIndex = 0;
                    let chapters = [];
                    if (!!this.userProgression.chapters && this.isProgressionWithContinuity) {
                        currentChapterIndex = this.userProgression.chapters.findIndex((chapter) => +chapter.chapterId === +this.currentChapter.id) || 0;
                        chapters = this.userProgression.chapters;
                    } else if (!!this.dataWithSubLessonNotCompleted?.chapter) {
                        chapters = [this.dataWithSubLessonNotCompleted.chapter];
                    }

                    this.chaptersToDisplay = chapters.filter((chapter, index) => {
                        if (index === currentChapterIndex - 1) {
                            // affiche dans le breadcrumb le chapitre precedent
                            return chapter;
                        }
                        if (this.isChapterSelected(chapter.chapterId)) {
                            // affiche dans le breadcrumb le chapitre actuel
                            return chapter;
                        }
                        if (index === currentChapterIndex + 1) {
                            // affiche dans le breadcrumb le chapitre suivant
                            return chapter;
                        }
                        if (index === currentChapterIndex + 2) {
                            // affiche dans le breadcrumb le chapitre suivant
                            return chapter;
                        }
                    })
                        .map((chapter) => {
                            const chapterEntity = this.chapters.find((entity) => +chapter.chapterId === +entity.id);
                            return {
                                id: chapterEntity.id,
                                label: chapterEntity.get('label')
                            };
                        });
                    this.ready = true;
                })
            ).subscribe();
    }

    private loadUserProgression(): Observable<{
        chapters: {
            assignations: { [key: string]: { id: string, type: string, assignated_node: string } },
            chapterId: string,
            progress: number,
            progressChapter: { [key: string]: { score: number, scoreByActivities: { [key: string]: number }, changed?: number } }[],
            sequences: number[],
            state: string
        }[],
        completed: number,
        pending: number,
        total: number
    }> {
        const chapter = this.chapters.find((ch) => !!this.assignment?.get('assignated_node')?.chaptersTag?.length
            && +ch.id === +this.assignment?.get('assignated_node')?.chaptersTag[0].id);
        const userProgressFilter = {
            educationalLevel: (chapter && chapter.get('grade')[0]) || this.assignment?.get('assignated_node')?.educationalLevel[0]?.id || this.userData.get('config').educational_level,
            concept: this.assignment?.get('assignated_node')?.concepts[0]?.id || this.userData.get('config').concept,
        };
        return this.octopusConnect.loadCollection('user-progress', userProgressFilter).pipe(
            map((collection) => collection.entities[0].get('currentUser'))
        );
    }

    private loadAssignmentById(assignmentId: string): Observable<DataEntity> {
        const subjectAssignment = new ReplaySubject<Observable<DataEntity>>(1);
        this.communicationCenter
            .getRoom('assignment')
            .getSubject('getAssignmentsById$')
            .next({assignmentId, onComplete: subjectAssignment});

        return subjectAssignment.pipe(
            take(1),
            mergeMap((obsAssignment: Observable<DataEntity>) => obsAssignment)
        );
    }

    private loadAssignments(): Observable<DataEntity[]> {
        const optionsInterface: CollectionOptionsInterface = {
            filter: {
                assignator: this.userData.id,
                assignated_user: this.userData.id,
                excludeAssignator: false
            }
        };
        const subjectAssignments = new ReplaySubject<PaginatedCollection>(1);
        this.communicationCenter
            .getRoom('assignment')
            .getSubject('getAssignments$')
            .next({filterOptions: optionsInterface, onComplete: subjectAssignments});
        subjectAssignments.pipe(
            mergeMap((assignmentsCollection: PaginatedCollection) => assignmentsCollection.collectionObservable),
            take(1),
        ).subscribe();
        return subjectAssignments.pipe(
            mergeMap((assignmentsCollection: PaginatedCollection) => assignmentsCollection.collectionObservable),
            map((assignmentsCollection: DataCollection) => assignmentsCollection.entities),
            take(1)
        );
    }

    private loadEducationalLevels(): Observable<DataEntity[]> {
        const subjectEducationalLevels = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter
            .getRoom('assignment')
            .next('getEducationalLevels$', subjectEducationalLevels);
        subjectEducationalLevels.pipe(
            mergeMap((educationalLevels$: Observable<DataEntity[]>) => educationalLevels$),
            take(1)
        ).subscribe();
        return subjectEducationalLevels.pipe(
            mergeMap((educationalLevels$: Observable<DataEntity[]>) => educationalLevels$),
            take(1)
        );
    }

    private loadClasses(): Observable<{ [key: string]: any }[]> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList');

    }

    private loadWorkgroups(): Observable<{ [key: string]: any }[]> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList');
    }

    private loadAssignmentState(): Observable<DataEntity[]> {
        return this.communicationCenter.getRoom('assignments').getSubject('statesList');
    }

    private loadChapters(): Observable<DataEntity[]> {
        const subjectChapters = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter
            .getRoom('assignment')
            .next('getChapters$', subjectChapters);
        subjectChapters.pipe(
            mergeMap((chapters$: Observable<DataEntity[]>) => chapters$),
            take(1)
        ).subscribe();
        return subjectChapters.pipe(
            mergeMap((chapters$: Observable<DataEntity[]>) => chapters$),
            take(1)
        );
    }

    private loadConcepts(): Observable<DataEntity[]> {
        const subjectConcepts = new ReplaySubject<Observable<DataEntity[]>>(1);
        subjectConcepts.pipe(
            mergeMap((concepts$: Observable<DataEntity[]>) => concepts$),
            take(1)
        ).subscribe();
        this.communicationCenter
            .getRoom('assignment')
            .next('getConcepts$', subjectConcepts);
        return subjectConcepts.pipe(
            mergeMap((concepts$: Observable<DataEntity[]>) => concepts$),
            take(1)
        );
    }

    private loadLessonById(lessonId: string): Observable<DataEntity> {
        const lesson$ = new ReplaySubject<Observable<DataEntity>>(1);
        lesson$.pipe(
            mergeMap(obs => obs),
        ).subscribe();
        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId: lessonId,
                callbackSubject: lesson$
            });
        return lesson$.pipe(
            mergeMap(obs => obs),
            take(1)
        );
    }

    private getSequenceNotCompleted(lessonId: string, progression: Progress | null, assignment: AssignmentInChapter, chapter: Chapter): Observable<DataForAssignment> {
        let lessonEntity: DataEntity;
        let subLessonNotCompleted: {
            id: string;
            type: string;
            title: string;
        };
        const stateOfSubLesson: { [key: string]: {completed: boolean, changed?: number} } = {};
        return this.loadLessonById(lessonId).pipe(
            tap((lesson) => lessonEntity = lesson),
            mergeMap((lesson: DataEntity) => {
                lesson.get('reference').forEach((subLesson) => {
                    stateOfSubLesson[subLesson.id] = {completed: progression && !!progression[subLesson.id]};
                    if (stateOfSubLesson[subLesson.id].completed) {
                        stateOfSubLesson[subLesson.id].changed = progression[subLesson.id].changed;
                    }
                    if (!subLessonNotCompleted) {
                        // pas de progression pour cette seance = assignation non terminée
                        subLessonNotCompleted = subLesson;
                    }
                });
                return of({lessonEntity, subLessonNotCompleted, stateOfSubLesson, assignment, chapter}) as Observable<DataForAssignment>;
            })
        );
    }

    private loadSequenceAssociatedData(): Observable<DataForAssignment> {
        let assignmentsInChapter = [];
        let currentIndexChapter = 0;
        let currentChapter: Chapter;
        let chaptersToCheck: Chapter[] = [];
        if (this.userProgression.chapters) {
            currentIndexChapter = this.userProgression.chapters.findIndex((chapter) =>
                this.assignment.get('assignated_node').chaptersTag[0] && +this.assignment.get('assignated_node').chaptersTag[0].id === +chapter.chapterId) || 0;
            currentChapter = this.userProgression.chapters.find((chapter) =>
                this.assignment.get('assignated_node').chaptersTag[0] && +this.assignment.get('assignated_node').chaptersTag[0].id === +chapter.chapterId);
            chaptersToCheck = this.userProgression.chapters.filter((ch) => {
                const chapEntity = this.chapters.find((entity) => +entity.id === +ch.chapterId);
                const educationalLevelId = (chapEntity && chapEntity.get('grade')[0]) || this.assignment?.get('assignated_node')?.educationalLevel[0]?.id || this.userData.get('config').educational_level;
                if (!educationalLevelId) {
                    // si aucun educational level associé au compte ou au parcours, pas de limitation sur la collection de période
                    return true;
                }
                return chapEntity.get('grade').includes(educationalLevelId.toString());
            }).slice(currentIndexChapter);
        }

        if (!currentChapter) {
            this.isProgressionWithContinuity = false;
            const chapterEntity = this.chapters.find((chap) => +chap.id === +this.assignment?.get('assignated_node')?.chaptersTag[0]?.id);
            if (chapterEntity) {
                currentChapter = {
                    assignations: {
                        [`${this.assignment.id}`]: {
                            id: this.assignment.id.toString(),
                            type: this.assignment.get('type_term').label,
                            assignated_node: this.assignment?.get('assignated_node').id
                        }
                    },
                    chapterId: chapterEntity.id.toString(),
                    sequences: [this.assignment?.get('assignated_node').id],
                };
                chaptersToCheck = [currentChapter];
            }
        }

        let subLessonNotCompleted: {
            id: string;
            type: string;
            title: string;
        };

        return this.loadSequences([this.assignment?.get('assignated_node').id]).pipe(
            mergeMap((sequences: DataEntity[]) => {
                const stateOfSubLesson: { [key: string]: {completed: boolean, changed?: number} } = {};
                const progressionInAssignment = this.assignment?.get('config') && JSON.parse(this.assignment?.get('config')) || {};
                sequences[0].get('reference').forEach((subLesson) => {
                    stateOfSubLesson[subLesson.id] = {completed: !!progressionInAssignment[subLesson.id]};
                    if (stateOfSubLesson[subLesson.id].completed) {
                        stateOfSubLesson[subLesson.id].changed = progressionInAssignment[subLesson.id].changed;
                    }
                    if (!subLessonNotCompleted && !stateOfSubLesson[subLesson.id].completed) {
                        // pas de progression pour cette seance = assignation non terminée
                        subLessonNotCompleted = subLesson;
                    }
                });

                    assignmentsInChapter.push({
                        id: this.assignment.id.toString(),
                        type: this.assignment.get('type_term') && this.assignment.get('type_term').label || null,
                        assignated_node: this.assignment.get('assignated_node').id
                    });
                    return of({
                        lessonEntity: sequences[0],
                        subLessonNotCompleted,
                        stateOfSubLesson,
                        assignment: {
                            id: this.assignment.id,
                            assignated_node: this.assignment.get('assignated_node').id,
                        },
                        chapter: currentChapter,
                        groups: this.assignment.get('groups') || []
                    } as DataForAssignment);
                }),
                mergeMap((data) => {
                    if (!data.subLessonNotCompleted) {
                        const chapterSequenceToCheck$: Observable<DataForAssignment>[] = chaptersToCheck.map((chapter) => {
                            return this.loadSequences(chapter.sequences).pipe(
                                take(1),
                                mergeMap((lessonEntities) => {
                                    assignmentsInChapter = [];
                                    if (chapter.assignations) {
                                        for (const assignment in chapter.assignations) {
                                            assignmentsInChapter.push({
                                                id: assignment,
                                                type: chapter.assignations[assignment].type,
                                                assignated_node: chapter.assignations[assignment].assignated_node
                                            });
                                        }
                                    }
                                    return of(lessonEntities.map((sequence) => {
                                        let subLessonNotCompleted: {
                                            id: string;
                                            type: string;
                                            title: string;
                                        };
                                        const classe = this.classes.find((c) => this.assignment.get('groups')?.map((g) => +g.id).includes(+c.id));
                                        const stateOfSubLesson: {[key: string]: {completed: boolean, changed?: number}} = {};
                                        const assignmentsAssociatedWithSequence = assignmentsInChapter.slice().sort((a,b) => +b.id - +a.id)
                                            .filter((assignment) => +assignment.assignated_node === +sequence.id);
                                        const assignmentAssociatedWithSequence = assignmentsAssociatedWithSequence[0];
                                        const progression = assignmentAssociatedWithSequence && Array.isArray(chapter.progressChapter) && chapter.progressChapter
                                            .find((p: Progress) => {
                                                if (classe && !!p.group) {
                                                    return +p.assignationId === +assignmentAssociatedWithSequence.id && !!p.group?.includes(classe.id.toString());
                                                }
                                                return +p.assignationId === +assignmentAssociatedWithSequence.id;
                                            }) || {};

                                    sequence.get('reference').forEach((subLesson) => {
                                        stateOfSubLesson[subLesson.id] = {completed: progression && !!progression[subLesson.id]};
                                        if (stateOfSubLesson[subLesson.id].completed) {
                                            stateOfSubLesson[subLesson.id].changed = progression[subLesson.id].changed;
                                        }
                                        if (!subLessonNotCompleted && !stateOfSubLesson[subLesson.id]?.completed) {
                                            // pas de progression pour cette seance = assignation non terminée
                                            subLessonNotCompleted = subLesson;
                                        }
                                    });
                                    return {
                                        lessonEntity: sequence,
                                        subLessonNotCompleted,
                                        stateOfSubLesson,
                                        assignment: assignmentAssociatedWithSequence,
                                        chapter
                                    } as DataForAssignment;
                                }));
                            }),
                            mergeMap((datasForSequenceInChapter) =>
                                of(datasForSequenceInChapter.find((item) => {
                                    const sequenceIndexInChapter = currentChapter.sequences.indexOf(+item.lessonEntity.id);
                                    const sequenceFromAssignmentIndexInChapter = currentChapter.sequences.indexOf(+this.assignment.get('assignated_node').id);
                                    if (item.chapter.chapterId === currentChapter.chapterId) {
                                        /* si le chapitre traité est le même que celui de l'assignation en cours,
                                         on vérifie que la sequence à lancer ne précède pas la sequence associé à l'assignation*/
                                        return !!item.subLessonNotCompleted && sequenceIndexInChapter !== -1
                                            && sequenceFromAssignmentIndexInChapter !== -1
                                            && sequenceIndexInChapter > sequenceFromAssignmentIndexInChapter;
                                    }
                                    return !!item.subLessonNotCompleted;
                                })))
                        );
                    });
                    if (chapterSequenceToCheck$.length) {
                        return combineLatest(chapterSequenceToCheck$)
                            .pipe(
                                mergeMap((datasForAssignment) => {
                                    const dataWithSubLessonNotCompleted = datasForAssignment.find((data) => !!data);
                                    if (!!dataWithSubLessonNotCompleted) {
                                        return of(dataWithSubLessonNotCompleted);
                                    } else {
                                        return of(data);
                                    }
                                })
                            );
                    } else {
                        return of(data);
                    }
                }
                return of(data);
            })
        );
    }

    private loadSequences(sequencesIds: number[]): Observable<DataEntity[]> {
        const lesson$ = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter
            .getRoom('lessons')
            .next('getLessons', {
                lessonIds: sequencesIds,
                callbackSubject: lesson$
            });
        return lesson$.pipe(
            take(1),
            mergeMap(obs => obs),
        );
    }

    public isSubLessonDone(subLessonId: string | number): boolean {
        return !this.subLessonNotCompleted || +subLessonId !== +this.subLessonNotCompleted.id;
    }

    public isSubLessonAvailable(subLesson: { id: string, type: string, title: string }, index: number): boolean {
        return !this.isSubLessonDone(subLesson.id) && (index === 0 || this.subLessons[index - 1] && this.isSubLessonDone(this.subLessons[index - 1].id));
    }

    public isSubLessonPending(subLessonId: string | number): boolean {
        return !this.dataWithSubLessonNotCompleted.stateOfSubLesson[subLessonId]?.completed;
    }

    public isChapterSelected(chapterId: string | number): boolean {
        return chapterId === this.currentChapter.id;
    }

    public launchAssignment(subLessonId?: string, createNewAssignment?: boolean): void {
        let obs: Observable<DataEntity>;
        if (subLessonId) {
            if (createNewAssignment) {
                obs = this.createAssignment();
            } else {
                obs = of(this.assignment);
            }
            this.launch(obs, subLessonId);
        } else {
            if (this.subLessonNotCompleted) {
                if (this.dataWithSubLessonNotCompleted?.assignment && +this.dataWithSubLessonNotCompleted.assignment.id === +this.assignment.id) {
                    obs = of(this.assignment);
                } else {
                    obs = this.createAssignment();
                }
                this.launch(obs);
            }
        }
    }

    private launch(obs: Observable<DataEntity>, subLessonId?: string): void {
        this.obsSubscription = obs.pipe(
            tap((assignment) => {
                let startOnStepIndex = 0;
                if (subLessonId) {
                    startOnStepIndex = this.subLessons.findIndex((subLesson) => +subLesson.id === +subLessonId);
                } else if (this.subLessonNotCompleted) {
                    startOnStepIndex = this.subLessons.findIndex((subLesson) => subLesson.id === this.subLessonNotCompleted.id);
                }
                const navigateOptions: { [key: string]: any } = {
                    exitLessonUrl: this.router.url,
                    startOnStepIndex
                };
                this.communicationCenter
                    .getRoom('lessons')
                    .getSubject('playLesson')
                    .next({assignment, navigateOptions});
            }),
            tap(() => this.dialogRef.close())
        ).subscribe();
    }
    private createAssignment(): Observable<DataEntity> {
        const subjectAssignment = new ReplaySubject<Observable<DataEntity>>(1);
        const state = this.assignmentStates.find((state) => state.get('label') === 'assigned');
        const typeTerm = this.assignmentTypes.find((type) => type.label === 'auto');
        let title = '';
        const sequenceTitle = this.lesson.get('metadatas').title || '';
        let chapterEntity: DataEntity = this.chapters.find((chapter) => +chapter.id === +this.dataWithSubLessonNotCompleted.chapter.chapterId);
        const chapterTitle = chapterEntity && chapterEntity.get('label') || this.lesson.get('metadatas').chapters[0]?.label || '';
        if (chapterTitle && chapterTitle !== '') {
            title = chapterTitle;
        }
        if (sequenceTitle && sequenceTitle !== '') {
            if (!title || title === '') {
                title = sequenceTitle;
            } else {
                title += ' | ' + sequenceTitle;
            }
        }
        const assignment = {
            assignator: +this.userData.id,
            assignated_user: +this.userData.id,
            assignated_node: this.lesson.id,
            state_term: state && state.id || null,
            type_term: typeTerm && typeTerm.id || null,
            title
        };
        if (this.assignment.get('groups')) {
            assignment['groups'] = this.assignment.get('groups').map((group) => group.id);
        }
        this.communicationCenter
            .getRoom('assignment')
            .next('createAssignmentWithSubject', {assignment, onComplete: subjectAssignment});
        return subjectAssignment.pipe(
            mergeMap((assignment$: Observable<DataEntity>) => assignment$),
            take(1)
        );
    }

    public openLessonPageOnEnter(evt: any): void {
        if (evt.key === 'Enter') {
            this.openLessonPage();
        }
    }

    public openLessonPage(): void {
        this.communicationCenter.getRoom('lessons').next('openLesson', {
            id: this.lesson.id,
            originUrl: this.router.url
        });
        this.dialogRef.close();
    }

    getDateCompleted(id: string): Date | null {
        if (this.dataWithSubLessonNotCompleted?.stateOfSubLesson[id]?.changed) {
            return new Date(this.dataWithSubLessonNotCompleted?.stateOfSubLesson[id].changed * 1000);
        }
        return null;
    }
}
