import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonListDialogComponent } from './button-list-dialog/button-list-dialog.component';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';



@NgModule({
  declarations: [
    ButtonListDialogComponent
  ],
    imports: [
        CommonModule,
        MatDialogModule,
        TranslateModule,
        MatIconModule,
        FuseSharedModule,
        MatButtonModule
    ]
})
export class ButtonListModule { }
