import {Directive, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Directive()
export abstract class AutoUnsubscribeTakeUntilClass implements OnDestroy {
    protected unsubscribeInTakeUntil = new Subject<void>();

    ngOnDestroy(): void {
        this.unsubscribe();
    }
    
    protected unsubscribe(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }
    
    protected resetUnsubscribe(): void {
        this.unsubscribe();
        this.unsubscribeInTakeUntil = new Subject<void>();
    }
}