// Rules list to test authorizations. As a `SyncRules` they must return a boolean when they are tested.
export enum SyncRules {
    // Allow menu to display menu item for the graph humanum module in the global dynamic menu
    seeGraphsLinksInMenu = 'graph-humanum.see-graphs-links-in-menu',
    // Allow display banner info for the graph humanum
    seeHumanumGraphsBannerInfo = 'graph-humanum.seeHumanumGraphsBannerInfo'
}

// Async Rules list to test authorizations. As an `AsyncRules` they must return a `Observable<boolean>` when they are tested.
export enum AsyncRules {

}