import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {ButtonComponentConfigInterface} from '@modules/activities/core/models/button-component.interface';

@Component({
    selector: 'app-button-element',
    templateUrl: './button-element.component.html',
    styleUrls: ['./button-element.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0}),
                animate('500ms', style({ opacity: 1})),
            ]),
            transition(':leave', [
                animate('500ms', style({ opacity: 0})),
            ]),
        ]),
    ]
})

export class ButtonElementComponent implements OnInit {
    @Input() public button: ButtonComponentConfigInterface;
    @Output() public clickButton: EventEmitter<ButtonComponentConfigInterface> = new EventEmitter<ButtonComponentConfigInterface>();
    constructor() { }

    ngOnInit(): void {
    }

    public onAction(): void {
        this.clickButton.emit(this.button);
    }
}
