import {Component, Inject, OnInit} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-remove-multiple-assignments-confirm',
    templateUrl: './remove-multiple-assignments-confirm.component.html',
    styleUrls: ['./remove-multiple-assignments-confirm.component.scss']
})
export class RemoveMultipleAssignmentsConfirmComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<RemoveMultipleAssignmentsConfirmComponent>) { }
    ngOnInit(): void {
    }
}
