import {Injectable} from '@angular/core';
import {map, take, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from 'rxjs';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {EducationalLevelEntity, Group, Learner, Workgroup} from '@modules/groups-management/core/definitions';
import {CommunicationCenterService} from '@modules/communication-center';
import {currentTimestamp} from 'shared/utils/datetime';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings';
import {GroupsManagementService} from '@modules/groups-management/core/services/groups-management.service';
import {AuthenticationService} from '@modules/authentication';
import {GroupManagementConfigurationService} from '@modules/groups-management/core/services/group-management-configuration.service';
import {ContextualService} from "@modules/groups-management/core/services/contextual.service";

const settingsStructure: ModelSchema = new ModelSchema({
    filterGroupListingsByCurrentYearByDefault: Structures.boolean(false),
    setTeacherRegionWhenAddNewLearner: Structures.boolean(false),
    noPasswordRegistration: Structures.boolean(false)
});

@Injectable()
export class LearnerService {
    public onLearnersChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    public settingsLicensing: { [key: string]: any };
    public schoolyear = 'all';
    public archiveMode = false;
    public groupNameClassroomSelected = '';
    public groupNameSelected = '';
    public settings: { [key: string]: any };
    private userData: DataEntity;
    public workgroupsList: Workgroup[] = [];
    public groupsList: Group[] = [];
    private learnersCollection: { [key: number]: DataEntity } = {};
    private learnersList: Learner[] = null; // null because if learner are not loaded if [] learner are loaded but no learner exist
    private learnerSubscription: Subscription = null;
    private onLogout: Subject<void> = new Subject<void>();
    private educationalLevelList: EducationalLevelEntity[] = [];

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private contextualService: ContextualService,
        private groupsManagement: GroupsManagementService,
        private authService: AuthenticationService,
        private groupManagementConfiguration: GroupManagementConfigurationService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.userData = data;
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnersChanged')
            .subscribe((data: any[]) => {
                // data = array of ids learners, workgroupEntity, callback

                for (const learnerId of data[0]) {
                    const learnerEntity = this.learnersCollection[learnerId];
                    const learnerGroups = [];
                    learnerGroups.push(...learnerEntity.get('groups'));
                    learnerGroups.splice(learnerGroups.indexOf(data[1].id.toString()), 1);

                    learnerEntity.set('groups', learnerGroups);
                    learnerEntity.save();
                }

                data[2](data[1]);
            });

        // Send first value empty array to learnerList subject to allow for subscribe to trigger in workgroup.service
        // send null beacause null mean data not get and [] mean data get but no learner created
        this.communicationCenter
            .getRoom('groups-management')
            .next('learnerList', this.learnersList);

        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('getLearners')
            .subscribe(({callbackSubject}: { callbackSubject: Subject<Observable<DataCollection>> }) =>
                callbackSubject.next(this.loadLearners())
            );

        this.settings = settingsStructure.filterModel(modulesSettings.groupsManagement);
    }

    get userEmail(): string {
        return this.userData.get('contact_email') || this.userData.get('email');
    }

    /**
     * when we use learner list is used in the group or workgroup context we inject it inside a row
     * and show only the learner of the group or workgroup selected
     * @param groupNameClassroomSelected : name of the classroom to filter
     * @param groupNameSelected : name of the group to filter
     */
    public filterLearnerToshow(groupNameClassroomSelected: string, groupNameSelected: string): void {
        this.groupNameClassroomSelected = groupNameClassroomSelected;
        this.groupNameSelected = groupNameSelected;
        if (groupNameClassroomSelected && groupNameClassroomSelected !== '') {
            this.onLearnersChanged.next(this.learnersList
                .filter((group) => group.groups && group.groups.includes(groupNameClassroomSelected))
            );
        }

        if (groupNameSelected && groupNameSelected !== '') {
            this.onLearnersChanged.next(this.learnersList
                .filter((workgroups) => workgroups.workgroups && workgroups.workgroups.includes(groupNameSelected))
            );
        }

        if (!groupNameSelected && !groupNameClassroomSelected) {
            this.onLearnersChanged.next(this.learnersList);
        }
    }

    getLearnerList(): Learner[] {
        return this.learnersList;
    }

    getGroups(): string[] {
        return this.groupsList.filter((grp) =>
            !grp.archived
            && (
            grp.schoolyear_term
            && grp.schoolyear_term['name'].toString() === this.currentSchoolYearBegin().toString()
            || !this.settings.filterGroupListingsByCurrentYearByDefault))
            .map((group: Group) => group.groupname);
    }

    getWorkgroups(): string[] {
        return this.workgroupsList.filter((grp) =>
            !grp.archived
            && (
            grp.schoolyear_term
            && grp.schoolyear_term['name'].toString() === this.currentSchoolYearBegin().toString()
            || !this.settings.filterGroupListingsByCurrentYearByDefault))
            .map((workgroup: Workgroup) => workgroup.workgroupname);
    }

    /**
     * filter on school year take care of archived flag
     * @param schoolYearFromFilter
     */
    public filterSchoolYear(schoolYearFromFilter?: string): any {
        this.schoolyear = schoolYearFromFilter && schoolYearFromFilter !== '' ? schoolYearFromFilter : this.groupsManagement.getCurrentSchoolyearTermId();
        if (this.schoolyear !== 'all') {
            this.onLearnersChanged.next(this.learnersList
                .filter((group) => group.schoolyear_term && group.schoolyear_term['id'] === this.schoolyear)
            );
        } else {
            this.onLearnersChanged.next(this.learnersList);
        }
    }

    /**
     * return an ID list of the group from the group name list in learner.groups & .workgroups lists
     * @param learner
     * @returns {number[]}
     */
    groupsListToId(learner: {groups?: string[], workgroups?: string[]}): string[] {
        const groups: string[] = [];
        if (learner.groups) {
            groups.push(...this.groupsList
                .filter((group: Group) => learner.groups.indexOf(group.groupname) > -1)
                .map((group: Group) => group.id.toString()));
        }
        if (learner.workgroups) {
            groups.push(...this.workgroupsList
                .filter((workgroup: Workgroup) => learner.workgroups.indexOf(workgroup.workgroupname) > -1)
                .map((workgroup: Workgroup) => workgroup.id.toString()));
        }

        return groups;
    }

    loadLearners(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('learners');
    }

    addLearner(learner): Observable<DataEntity> {
        // TODO ticket feature/46-dysap-80t9GEEx ici il faut récupérer les nouveau champs pour les envoyer aux back, ils devraient etre dans la variable learner.
        let birthday = '';
        if (learner.birthMonth || learner.birthYear) {
            birthday = learner.birthMonth + '/' + learner.birthYear;
        } else if (learner.age) {
            birthday = learner.age.toString();
        }
        const data = {
            // ce test est surement inutile, il semble que l'on n'ai pas à changer l'username qui est l'identifiant unique défini par le back
            label: learner.username || learner.nickname,
            password: learner.password,
            picture: learner.avatar,
            newsletter: false,
            you_are: 'teen',
            find_us: 'other',
            groups: this.groupsListToId(learner),
            role: 6, // TODO - webservice should set it itself
            contact_email: this.userEmail,
            parentalConsent: learner.parentalConsent ? 1 : 0,
            gender: learner.gender,
            disorder: JSON.stringify(learner.disorder),
            dyspraxic: learner.dyspraxic,
            birthday: birthday, // can store age or month and year of birth
        };
        if (learner.educationalLevel) {
            data['level'] = learner.educationalLevel;
        }

        if (this.authService.loggedUser.get('region') && this.authService.loggedUser.get('region').id && this.settings.setTeacherRegionWhenAddNewLearner) {
            data['region'] = this.authService.loggedUser.get('region').id;
        }
        if (this.settings.noPasswordRegistration) {
            data['noPasswordRegistration'] = true;
        }

        return this.octopusConnect.createEntity('learners', data);
    }

    saveLearner(learner: Learner): Observable<DataEntity> {
        const learnerEntity = this.learnersCollection[learner.id];

        if (learnerEntity) {
            if (learner.password) {

                // HACK - TODO fix octopus connect in cases where we set a field not present in the dataEntity
                // TODO - Check if hack can be removed
                if (learnerEntity.get('password') === undefined) {
                    learnerEntity.set('password', '');
                    learnerEntity.save();
                }

                learnerEntity.set('password', learner.password);
                // if we have policy of change password active user will have to change password next time he will be logged
                learnerEntity.set('expirePassword', currentTimestamp());
            }

            const addedTo: string[] = [];
            const removedFrom: string[] = [];

            const newGroupsList: any[] = this.groupsListToId(learner);
            const oldGroupsList: any[] = learnerEntity.get('groups');

            newGroupsList.forEach(group => {
                if (oldGroupsList.indexOf(group) === -1) {
                    addedTo.push(group);
                }
            });

            oldGroupsList.forEach(group => {
                if (newGroupsList.indexOf(group) === -1) {
                    removedFrom.push(group);
                }
            });

            learnerEntity.set('label', learner.username);
            if (learner.nickname) {
                learnerEntity.set('nickname', learner.nickname);
            }
            learnerEntity.set('groups', this.groupsListToId(learner));
            learnerEntity.set('parentalConsent', learner.parentalConsent ? 1 : 0);
            learnerEntity.set('disorder', JSON.stringify(learner.disorder));
            learnerEntity.set('gender', learner.gender);
            learnerEntity.set('dyspraxic', learner.dyspraxic);
            try {
                const l = <any>learner;
                if (l.educationalLevel) {
                    learnerEntity.set('level', l.educationalLevel);
                }
            } catch (ex) {
                console.error('error editing level (educational level 269 learner service' + ex);
            }

            const l = <any>learner;
            if (this.groupManagementConfiguration.doLearnerHasBirthdate()) {
                learnerEntity.set('birthday', learner.birthMonth + '/' + learner.birthYear);
            } else if (l.age) {
                // we use age or month and year or nothing
                l.age ? learnerEntity.set('birthday', l.age.toString()) : learnerEntity.set('birthday', '');
            }

            addedTo.forEach(id => {

                const wgroup: Workgroup = this.workgroupsList.find(elem => +elem.id === +id);

                if (wgroup) {

                    this.communicationCenter
                        .getRoom('notifications')
                        .next('sendNotification', {
                            recipient: +learnerEntity.id,
                            type: 'ADDED_IN_GROUP',
                            content: {
                                author: (this.userData ? this.userData.get('label') : ''),
                                group: id,
                                groupName: wgroup.workgroupname
                            }
                        });

                    // les autres élèves du groupe, sauf l'envoyeur
                    const sids: number[] = wgroup.learnersIds.filter(sid => +sid !== +learnerEntity.id);

                    this.communicationCenter
                        .getRoom('notifications')
                        .next('sendNotification', {
                            recipient: sids,
                            type: 'ADDED_IN_YOUR_GROUP',
                            content: {
                                author: (this.userData ? this.userData.get('label') : ''),
                                studentName: learnerEntity.get('label'),
                                groupName: wgroup.workgroupname
                            }
                        });
                }
            });


            removedFrom.forEach(id => {

                const wgroup: Workgroup = this.workgroupsList.find(elem => +elem.id === +id);

                if (wgroup) {

                    this.communicationCenter
                        .getRoom('notifications')
                        .next('sendNotification', {
                            recipient: +learnerEntity.id,
                            type: 'REMOVED_FROM_GROUP',
                            content: {
                                author: (this.userData ? this.userData.get('label') : ''),
                                group: id,
                                groupName: wgroup.workgroupname
                            }
                        });

                }
            });

            // TODO set other fields
            return learnerEntity.save();
        }
    }

    deleteLearner(learner): Observable<boolean> {
        const learnerEntity = this.learnersCollection[learner.id];

        if (learnerEntity) {
            return learnerEntity.remove();
        }
    }

    private postLogout(): void {
        if (this.learnerSubscription) {
            this.learnerSubscription.unsubscribe();
            this.learnerSubscription = null;
        }

        this.onLogout.next();
    }

    private postAuthentication(): void {
        if (!this.learnerSubscription) {
            this.refreshLearners();
        }
        if (this.groupsManagement.settings.filterGroupListingsByCurrentYearByDefault) {
            this.filterSchoolYear();
        }

        this.communicationCenter.getRoom('groups-management').getSubject('refreshLearners')
            .pipe(
                takeUntil(this.onLogout),
                tap(() => this.refreshLearners())
            )
            .subscribe();

        this.setEducationalLevelList();
    }

    /**
     * return the year where begin the current school year in regard of the current date
     */
    private currentSchoolYearBegin(): number {
        // year begin 1er août and finish 31 juillet
        const month = (new Date()).getMonth();
        const year = (new Date()).getFullYear();
        // 1 aout => 31 december
        if (month > 6 && month < 12) {
            return year;
        }
        // december to aout exclude
        if (month < 7) {
            return year - 1;
        }
    }

    public refreshLearners(): void {
        const subjectGroupsList = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList');
        const subjectWorkgroupsList = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList');


        this.communicationCenter.getRoom('licenses')
            .getSubject('settings').pipe(
            take(1))
            .subscribe((data) => {
                this.settingsLicensing = data;
            });

        this.learnerSubscription = combineLatest([this.loadLearners(), subjectGroupsList, subjectWorkgroupsList])
            .subscribe((data: [DataCollection, Group[], Workgroup[]]) => {
                this.groupsList = data[1];
                this.workgroupsList = data[2];
                this.learnersList = [];
                for (const entity of data[0].entities) {
                    // TODO - Refactor to another function and document it
                    // TODO - Limit (work)group name duplicate creation (front + webservice)
                    const groupsId = entity.get('groups') || [];
                    const groups: string[] = this.groupsList
                        .filter((group: Group) => groupsId.indexOf(group.id.toString()) > -1)
                        .map((group: Group) => group.groupname);

                    const schoolyear_term = this.groupsList
                        .filter((group: Group) => groupsId.indexOf(group.id.toString()) > -1)
                        .map((group: Group) => group.schoolyear_term).shift();

                    const workgroups: string[] = this.workgroupsList
                        .filter((workgroup: Workgroup) => groupsId.indexOf(workgroup.id.toString()) > -1)
                        .map((workgroup: Workgroup) => workgroup.workgroupname);

                    this.learnersList.push({
                        id: (entity.id ? parseInt(entity.id.toString(), 10) : -1),
                        avatar: entity.get('picture'),
                        username: entity.get('label'),
                        nickname: entity.get('nickname'),
                        parentalConsent: entity.get('parentalConsent'),
                        password: '',
                        groups: groups,
                        groupsWithData: this.groupsList
                            .filter((group: Group) => groupsId.indexOf(group.id.toString()) > -1),
                        schoolyear_term: schoolyear_term ? schoolyear_term : null,
                        workgroups: workgroups,
                        workgroupsWithData: this.workgroupsList
                            .filter((workgroup: Workgroup) => groupsId.indexOf(workgroup.id.toString()) > -1),
                        sso: entity.get('sso'),
                        disorder: entity.get('disorder'),
                        gender: entity.get('gender'),
                        dyspraxic: entity.get('dyspraxic'),
                        birthYear: entity.get('birthYear'),
                        birthMonth: entity.get('birthMonth'),
                        codeid: entity.get('codeid') ? entity.get('codeid').match(/.{1,4}/g).join('-') : '',
                        mail: entity.get('email'),
                        level: entity.get('level')
                    });

                    this.learnersCollection[entity.id] = entity;
                }

                this.communicationCenter
                    .getRoom('groups-management')
                    .next('learnerList', this.learnersList);

                this.filterLearnerToshow(this.groupNameClassroomSelected, this.groupNameSelected);

                this.contextualService.onConditionUpdate();
            });
    }

    /**
     * list of educational level
     */
    public getEducationalLevelList(): EducationalLevelEntity[] {
        return this.educationalLevelList;
    }

    /**
     * get the list of possible educational level
     */
    private getEducationalLevels(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('educational_level');
    }

    /**
     * set the educationnal level list
     * @private
     */
    private setEducationalLevelList(): void {
        try {
            this.getEducationalLevels().pipe(
                take(1),
                map((collection: DataCollection) => collection.entities),
            ).subscribe((levels: EducationalLevelEntity[]) => {
                this.educationalLevelList = levels;
            });
        } catch (ex) {
            console.error('learner service 51 error getting educational level list' + ex);
        }
    }
}
