import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {defaultApiURL} from '../../../../../settings';
import {ActivatedRoute, Router, RouterStateSnapshot} from '@angular/router';
import {MediaUploadService} from '@modules/corpus/core/media-upload.service';
import {HttpClient} from '@angular/common/http';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {CorpusRessourcesTypes} from '@modules/corpus/core/corpus-ressources-types.class';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {CorpusFileListComponent} from '@modules/corpus/core/components/corpus-file-list/corpus-file-list.component';

@Component({
    selector: 'app-external-corpus-display',
    templateUrl: './external-corpus-display.component.html',
    styleUrls: ['./external-corpus-display.component.scss']
})
export class ExternalCorpusDisplayComponent implements OnInit, OnChanges {

    @Input('corpusId') corpusId: string;
    @Input('itemId') itemId: string = null;

    @ViewChild('fileslist') filesList: CorpusFileListComponent;

    @Input('selection') selection: CorpusRessource[] = [];

    selected: any;
    pathArr: string[];
    user;
    filedata: any;

    private _urlFileUpload: string = defaultApiURL + 'api/file-upload';

    @Output('selectionChanged') selectionChanged: EventEmitter<CorpusRessource[]> = new EventEmitter<CorpusRessource[]>();

    constructor(
        private route: ActivatedRoute,
        private corpusService: CorpusService,
        private accountManagementProvider: AccountManagementProviderService,
        private http: HttpClient,
        private uploadService: MediaUploadService,
        private router: Router
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['selection']) {
        }
    }

    updateSelection(resources: CorpusRessource[]) {
        this.filesList.updateSelection(resources);
    }

    ngOnInit() {
        // alert (this.corpusId);
        this.corpusService.corpusId = this.corpusId;
        const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;
        this.itemId = this.route.snapshot.queryParamMap.get('itemId');
    }

    openResourceCreationModal() {
        this.uploadService.openResourceCreationModal();
    }

    onFileChanged(e){
        this.filedata = e.target.files[0];

        if ( this.validateFileType() ){
            this.user = this.accountManagementProvider.loggedUser;
            this.uploadFile(this.user, this.filedata);
        }
    }

    /** @deprecated use MediaService.createMedia instead */
    uploadFile(user, fileToUpload: File ) {


        const formData = new FormData();
        formData.append('file', fileToUpload);

        this.http
            .post<any>( this._urlFileUpload, formData, { headers: {'access-token': this.accountManagementProvider.userAccessToken} } )
            .subscribe(( res ) => {
                this.corpusService.createRessource(CorpusRessourcesTypes.MEDIA, this.corpusId, +res['data'][0][0]['id'], {
                    title: 'oo',
                    language: 'fr',
                    description: 'descccc',
                    'source-author': 'Moi',
                    source: 'Inconnu'
                }).subscribe(entity => this.corpusService.onFileSelected.next(entity));
            } );

    }

    validateFileType(){
        if ( this.filedata ){
            const fileName = this.filedata.type;

            if (
                fileName === 'image/jpg' ||
                fileName === 'image/jpeg' ||
                fileName === 'image/png' ||
                fileName === 'image/gif' ||
                fileName === 'video/mp4' ||
                fileName === 'video/mpeg' ||
                fileName === 'audio/mp3' ||
                fileName === 'audio/mpeg' ||
                fileName === 'application/pdf'
            ){
                return true;
            }else{
                return false;
            }
        }

        return false;

    }

    onSelectionChanged(resources: CorpusRessource[]) {
        this.selectionChanged.emit(resources);
    }

}
