import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {OpenBadge} from '@modules/achievement/core/services/user-score.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {take} from 'rxjs/operators';
import {AuthenticationService} from '@modules/authentication';

@Component({
    templateUrl: './open-badge-dialog.component.html',
})
export class OpenBadgeDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: OpenBadge,
                private octopusConnect: OctopusConnectService,
                private auth: AuthenticationService) {
        this.data = data['attributes'];
        this.data.id = data.id;
    }

    async downloadBadgeImage() {
        const image = await fetch(this.data.badge);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);

        const link = document.createElement('a');
        link.href = imageURL;
        link.download = `${this.data.name}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    public downloadBadgeCertificate(): void {
        this.octopusConnect.loadEntity('openbadges-awards-pdf', this.data.id)
            .pipe(take(1))
            .subscribe((data: DataEntity) => {
                window.open(data.get('pdf'));
            });
    }

    public get isGar():boolean{
        return this.auth.isGAR()
    }
}