import {HttpClient} from '@angular/common/http';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {Injectable} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';
import {currentTimestamp} from 'shared/utils/datetime';

@Injectable()
export class FlagService {

    constructor(
        public octopusConnect: OctopusConnectService,
        public http: HttpClient,
        public accountManagementProvider: AccountManagementProviderService,
    ) {

    }

    /**
     * Creates a flag of given type for the given entity
     * @param entity Entity to which the flag is associated
     * @param entityType Type of the given entity
     * @param flag Type of the flag
     */
    private flag(entity: DataEntity, entityType: string, flag: string): Observable<DataEntity> {
        const data = {'entity_id': entity.id, 'entity_type': entityType};
        if (flag === 'consulted' || flag === 'consulted_assignation') {
            data['consulted'] = currentTimestamp();
        }

        const observable = this.octopusConnect.createEntity(flag, data);
        observable.subscribe((flaggedEntity) => {
            if (flag === 'consulted' || flag === 'consulted_assignation') {
                entity.set('consulted', {
                    flagging_id: flaggedEntity.get('fid'),
                    consulted: flaggedEntity.get('consulted'),
                    consulted_bool: true,
                });
            }
        });

        return observable;
    }

    /**
     * Updates consulted time of the given flag
     * @param entity Entity to which the flag is associated
     * @param entityType Type of the given entity
     * @param flag Type of the flag
     * @param flaggingId ID of the flag entity
     */
    private updateFlag(entity: DataEntity, entityType: string, flag: string, flaggingId: string): Observable<DataEntity> {
        const data = {'entity_id': entity.id, 'entity_type': entityType};
        const flagEntity = new DataEntity(flag, data, this.octopusConnect, flaggingId);
        flagEntity.set('consulted', currentTimestamp());

        const observable = flagEntity.save();
        observable.subscribe(() => {
            if (flag === 'consulted' || flag === 'consulted_assignation') {
                entity.set('consulted.consulted', currentTimestamp());
            }
        });

        return observable;
    }

    /**
     * Deletes the given flag
     * @param entity Entity to which the flag is associated
     * @param entityType Type of the given entity
     * @param flag Type of the flag
     */
    private unflag(entity: DataEntity, entityType: string, flag: string): Observable<boolean> {
        const flagEntity = new DataEntity(flag, {}, this.octopusConnect, entity.id);
        const observable = flagEntity.remove();
        observable.subscribe();

        return observable;
    }

    /**
     *  Toggles a flag on the fiven entity
     * @param entity Entity to which the flag is associated
     * @param entityType Type of the given entity
     * @param flag Type of the flag
     */
    public flagEntity(entity, entityType: string, flag: string): Observable<boolean|DataEntity> {
        if (!this.isFavorized(entity, flag)) {
            entity.set(flag, true);
            return this.flag(entity, entityType, flag);
        } else {
            entity.set(flag, false);
            return this.unflag(entity, entityType, flag);
        }
    }

    /**
     * Creates or update a flag on the given entity depending if the flag is passed
     * @param entity Entity to which the flag is associated
     * @param entityType Type of the given entity
     * @param flag Type of the flag
     * @param flaggingId ID of the flag entity, can be undefined
     */
    public updateFlagEntity(entity, entityType: string, flag: string, flaggingId?: string): Observable<DataEntity> {
        if (entity) {
            if (flaggingId === undefined) { // check if a flag already exist
                return this.flag(entity, entityType, flag);
            } else {
                return this.updateFlag(entity, entityType, flag, flaggingId);
            }
        }
    }

    isFavorized(entity, flag): string {
        return entity.get(flag);
    }
}
