import {Component, Inject, Input, OnInit} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

// TODO dans ce dossier il y a des composants qui sont utilisés pour executer un typologies, ce composant n'a rien a faire ici
interface MatDialogData {
    url: string;
}
/**
 * component that open on click a pdf on another tab
 */
@Component({
    selector: 'app-read-pdf',
    templateUrl: './read-pdf.component.html'
})
export class ReadPdfComponent implements OnInit {

    @Input('url') pdfUrl? = '';

    constructor(public dialogRef: MatDialogRef<ReadPdfComponent>, @Inject(MAT_DIALOG_DATA) public data?: MatDialogData) {
    }

    ngOnInit(): void {
        if (this.data?.url) {
            this.pdfUrl = this.data.url;
        }
    }
    openPdf(): void {
        window.open(this.pdfUrl, '_blank');
        this.dialogRef.close();
    }

    close(): void {
        this.dialogRef.close();
    }

    public get isComponentInModal(): boolean {
        return !!this.data?.url;
    }
}
