// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-dialog-actions.mat-dialog-actions {
  background-color: whitesmoke;
  padding: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/groups-management/core/groups-listing/groups-custom-action/groups-custom-action.component.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,cAAA;AACF","sourcesContent":["mat-dialog-actions.mat-dialog-actions {\n  background-color: whitesmoke;\n  padding: 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
