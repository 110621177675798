import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
    selector: '[isCollapsibleField]'
})
/**
 * if current field is in list of settings add class that hide field when collapse
 */
export class IsCollapsibleFieldDirective implements AfterViewInit{

    @Input() field = ''; // current field to check if allowed
    @Input() fields = []; // list of field allowed come from setting

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    }

    ngAfterViewInit(): void {
        if (this.field === '' || this.fields.length === 0 || !this.fields.includes(this.field)) {
            return;
        } else {
            this.renderer.addClass(this.elementRef.nativeElement, 'collapsibleFields');
        }
    }
}
