// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  display: flex;
  flex-flow: column;
}
:host .animal-container {
  height: 100%;
  position: relative;
}
:host .animal-container app-animal-controls {
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  height: calc(100% - 40px);
  display: flex !important;
  justify-content: center !important;
}
:host .animal-container .message {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
:host .spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/gamification/core/my-animals/my-animals.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,iBAAA;AACJ;AACI;EACI,YAAA;EACA,kBAAA;AACR;AACQ;EACI,kBAAA;EACA,MAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,wBAAA;EACA,kCAAA;AACZ;AAEQ;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAZ;AAII;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAFR","sourcesContent":[":host {\n    height: 100%;\n    display: flex;\n    flex-flow: column;\n\n    .animal-container {\n        height: 100%;\n        position: relative;\n\n        app-animal-controls {\n            position: absolute;\n            top: 0;\n            width: 100%;\n            margin-top: 20px;\n            margin-bottom: 20px;\n            height: calc(100% - 40px);\n            display: flex !important;\n            justify-content: center !important;\n        }\n\n        .message {\n            height: 100%;\n            width: 100%;\n            display: flex;\n            justify-content: center;\n            align-items: center;\n        }\n    }\n\n    .spinner {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        width: 100%;\n        height: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
