import {Component, EventEmitter, Input, Output} from '@angular/core';
import {VoiceRecorderComponent} from '@modules/activities/core/shared-components/voice-recorder/voice-recorder.component';
import {NgAudioRecorderService} from 'ng-audio-recorder';
import {VoiceRecorderService} from '@modules/activities/core/services/voice-recorder.service';
import {FlashCardInterface} from 'fuse-core/components/flashcard/flash-card.interface';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-voice-recorder-read-only',
    templateUrl: './voice-recorder-read-only.component.html',
    providers: [VoiceRecorderService, NgAudioRecorderService]
})
export class VoiceRecorderReadOnlyComponent extends VoiceRecorderComponent {
    @Input() displayForSummary = false;
    @Input() flashcard: FlashCardInterface;
    @Input() forcePauseIncomingAudio$?: Subject<void>;
    @Input() forceReset$?: Subject<void>;
    @Output() activityDone = new EventEmitter<void>();
    @Input() showTitle = false;
    @Output() userAudioBlobEvent = new EventEmitter<Blob>();
    @Input() userAudioBlob: Blob;

    constructor(audioRecorderService: NgAudioRecorderService,
                service: VoiceRecorderService) {
        super(audioRecorderService, service);
    }

    myAudio(): void {
        this.pauseIncomingAudio();
        this.service.setState({
            listenIncomingAudioButtonDisabled: true,
            recordingButtonDisabled: true,
        });
        const userAudioUrl = URL.createObjectURL(
            this.service.state.userAudioFileBlob
        );
        this.userAudio = new Audio(userAudioUrl);
        // 1 read original audio
        this.userAudio.play();
    }
}
