import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import * as _ from 'lodash-es';
import {combineLatest, Observable, ReplaySubject, Subject} from 'rxjs';
import {GraphUbolinoService} from '@modules/graph-ubolino/core/services/graph-ubolino.service';
import {filter, map, startWith, takeUntil} from 'rxjs/operators';
import {DataEntity} from 'octopus-connect';
import {Learner} from '@modules/graph-ubolino/core/model/learner';
import {GraphFilter, GraphFilterCustomRule} from '@modules/graph-ubolino/core/model/graph-filter';
import {GraphFiltersValues} from '@modules/graph-ubolino/core/model/graph-filters-values';
import {RawGraphFiltersValues} from '@modules/graph-ubolino/core/model/raw-graph-filters-values';
import * as moment from 'moment';
import {Group} from '@modules/graph-ubolino/core/model/group';
import {Workgroup} from '@modules/graph-ubolino/core/model/workgroup';
import {ChapterEntity} from 'fuse-core/services/chapters.service';
import {ConceptEntity} from 'shared/models';

@Component({
    selector: 'app-shared-filters',
    templateUrl: './shared-filters.component.html',
})
export class SharedFiltersComponent implements OnInit, OnDestroy {
    public controls = {
        endDate: new UntypedFormControl(),
        learner: new UntypedFormControl(),
        startDate: new UntypedFormControl(),
        group: new UntypedFormControl(),
        workgroup: new UntypedFormControl(),
        multiLearner: new UntypedFormControl(),
        multiLesson: new UntypedFormControl(),
        concepts: new UntypedFormControl(),
        parentChapters: new UntypedFormControl(),
        chapters: new UntypedFormControl()

    };
    public barIsExpended = true;
    public filteredLearners: Observable<string[]>;
    public filteredByGroupLearners: ReplaySubject<string[]> = new ReplaySubject<string[]>();
    public lessons: DataEntity[] = [];
    public isReady = false;
    public groups: Group[] = [];
    public workgroups: Workgroup[] = [];
    public concepts: ConceptEntity[];
    private filters: { always: GraphFilter[], hidden: GraphFilter[] } = {always: [], hidden: []};
    private unsubscribeInTakeUntil = new Subject<void>();
    private learnerNicknames: string[] = [];
    private filtersValues: Partial<RawGraphFiltersValues> = {};
    private learners: Learner[] = [];
    private chapters: ChapterEntity[] = [];
    private availableParentChapters: ChapterEntity[] = [];
    public parentChapters: ChapterEntity[] = [];
    public childrenChapters: ChapterEntity[] = [];

    constructor(
        private graphUbolinoService: GraphUbolinoService,
    ) {
    }


    public getChildrenChapterFormParentChapterId(parentChapterId: string): ChapterEntity[] {
        return this.chapters.filter(c => c.get('parent').includes(parentChapterId));
    }

    ngOnInit(): void {
        this.isReady = false;
        this.graphUbolinoService.isReady
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
            ).subscribe((isReady) => {
            this.isReady = false;
            if (isReady) {
                this.graphUbolinoService.forceFiltersValues.pipe(
                    takeUntil(this.unsubscribeInTakeUntil),
                    takeUntil(this.graphUbolinoService.isReady.pipe(filter((shadowedIsReady) => shadowedIsReady === false)))
                ).subscribe(() => this.redoAtEachForcedFilters());

                this.learners = this.graphUbolinoService.learners.slice();
                this.learnerNicknames = this.graphUbolinoService.getLearnersAlphabetically().map(l => l.nickname);
                this.lessons = this.graphUbolinoService.lessons.slice();
                this.groups = this.graphUbolinoService.groups.slice();
                this.workgroups = this.graphUbolinoService.workgroups.slice();
                this.concepts = this.graphUbolinoService.concepts.slice();
                this.chapters = this.graphUbolinoService.chapters.slice();
                this.availableParentChapters = this.chapters.filter(c => c.get('parent').length === 0);
                this.parentChapters = this.availableParentChapters.slice();
                this.childrenChapters = this.chapters.filter(c => c.get('parent').length > 0);

                this.initLists();
                this.initAutoSubmitOnChanges();
                this.initAutoFills();
                this.initDefaultValues();

                this.graphUbolinoService.silentlySetFilterValues.pipe(
                    takeUntil(this.unsubscribeInTakeUntil)
                ).subscribe((data: { field: string, value: any }) => {
                    (<UntypedFormControl>this.controls[data.field]).setValue(data.value);
                });

                this.isReady = true;
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public isDisplayed(filterName: string): boolean {
        try {
            return this.filters.always.map(f => f.label).includes(filterName) || (
                this.barIsExpended && this.filters.hidden.map(f => f.label).includes(filterName)
            );
        } catch (e) {
            console.warn(e);
            return false;
        }
    }

    public toggle(): void {
        this.barIsExpended = !this.barIsExpended;
    }

    public couldExpend(): boolean {
        return this.filters.hidden.length > 0;
    }

    public isEmptyAllowed(field: string): boolean {
        return this.isFieldHasRule(field, 'allowEmpty');
    }

    private initLists(): void {
        this.filteredLearners = this.controls.learner.valueChanges
            .pipe(
                startWith(''),
                map(value => this.filterLearners(value))
            );

        combineLatest([
            this.controls.group.valueChanges,
            this.controls.workgroup.valueChanges
        ]).pipe(
            map(([group, workgroup]) => {
                this.filteredByGroupLearners.next(this.filterLearnersByGroup(group, workgroup));
            })
        ).subscribe();
    }

    private initAutoSubmitOnChanges(): void {
        for (const control of Object.keys(this.controls)) {
            this.controls[control].valueChanges.subscribe((v) => {
                this.updateFiltersValues(control, v);
                this.emitFiltersValues();
            });
        }
    }

    private filterLearners(value: string): string[] {
        const filterValue = !!value && value.toLowerCase();
        return this.learnerNicknames.filter(option => option.toLowerCase().includes(filterValue));
    }

    private updateFiltersValues(control: string, v: any): void {
        if (v === undefined || v === null || v === '' || (Array.isArray(v) && v.length === 0)) {
            delete this.filtersValues[control];
        } else {
            this.filtersValues[control] = v;
        }
    }

    private emitFiltersValues(): void {
        const raw = _.cloneDeep(this.filtersValues);
        // On duplique this.filtersValues mais il a pas le meme format que notre variable values
        // alors on va ajouter/retirer des données a values pour respecter le format
        const optimised: Partial<GraphFiltersValues & RawGraphFiltersValues> = _.cloneDeep(raw);

        Object.keys(optimised).forEach((field) => {
            if (this.isIgnored(field)) {
                delete optimised[field];
                return;
            }

            if (field === 'multiLesson') {
                const lessons = this.lessons.filter(l => this.filtersValues.multiLesson.includes(l.get('metadatas').title));
                if (lessons.length > 0) {
                    optimised.lessonList = lessons.map(l => +l.id);
                } else {
                    delete optimised.lessonList;
                }
                delete optimised.multiLesson;
                return;
            }

            if (field === 'multiLearner') {
                const learners = this.learners.filter(l => this.filtersValues.multiLearner.includes(l.nickname));
                if (learners.length > 0) {
                    optimised.learnerList = learners.map(l => +l.id);
                } else {
                    delete optimised.learnerList;
                }
                delete optimised.multiLearner;
                return;
            }

            if (field === 'learner') {
                const learner = this.learners.find(l => l.nickname === this.filtersValues.learner);
                if (!!learner) {
                    optimised.learner = learner.id;
                } else {
                    delete optimised.learner;
                }
                return;
            }

            if (field === 'group') {
                const group = this.groups.find(g => g.groupname === this.filtersValues.group);
                if (!!group) {
                    optimised.group = group.id.toString();
                } else {
                    delete optimised.group;
                }
                return;
            }

            if (field === 'workgroup') {
                const workgroup = this.workgroups.find(wg => wg.workgroupname === this.filtersValues.workgroup);
                if (!!workgroup) {
                    optimised.workgroup = workgroup.id.toString();
                } else {
                    delete optimised.workgroup;
                }
                return;
            }

            if (field === 'startDate') {
                optimised.startDate = moment(optimised.startDate).toDate();
                return;
            }

            if (field === 'endDate') {
                optimised.endDate = moment(optimised.endDate).endOf('day').toDate();
                return;
            }
        });

        this.graphUbolinoService.filtersChanges.next({
            raw,
            optimised
        });
    }

    private initDefaultValues(): void {
        this.graphUbolinoService.setLearnerDynamicFilterWithCacheFilter();
        this.barIsExpended = true;
        this.filters = _.clone(this.graphUbolinoService.dynamicFilters);

        Object.keys(this.controls).forEach(control => this.controls[control].setValue(null));
        [...this.filters.always, ...this.filters.hidden].forEach(f => {
            this.controls[f.label].setValue(f.value);
        });
    }

    private filterLearnersByGroup(groupName: string, workgroupName: string): string[] {
        const group = _.get(this.groups.find(g => g.groupname === groupName), 'id');
        const workgroup = _.get(this.workgroups.find(g => g.workgroupname === workgroupName), 'id');

        const groupAndWorkgroupLearnersIds = this.graphUbolinoService.getLearnerFilteredOfGroupAndWorkgroup(group, workgroup);
        let results = this.learners.slice();

        if (!!group || !!workgroup) {
            results = results.filter(l => groupAndWorkgroupLearnersIds.includes(+l.id));
        }
        return results.map(l => l.nickname);
    }

    private isIgnored(field: string): boolean {
        return this.isFieldHasRule(field, 'ignore');
    }

    private fieldsToAutofill(field: string): string[] {
        const fieldConfig = [...this.filters.always, ...this.filters.hidden].find(f => f.label === field);

        if (!!fieldConfig && !!fieldConfig.custom && !!fieldConfig.custom['rules']) {
            const rules = fieldConfig.custom['rules'].filter(rule => rule.startsWith('autofill:'));

            if (rules.length > 0) {
                return rules.map(r => r.replace('autofill:', ''));
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    private isFieldHasRule(field: string, rule: GraphFilterCustomRule): boolean {
        try {
            const fieldConfig = [...this.filters.always, ...this.filters.hidden].find(f => f.label === field);

            if (!!fieldConfig) {
                return fieldConfig.custom['rules'].includes(rule);
            }
        } catch (e) {
            // on avale volontairement l'erreur pour pas avoir a tester
        }

        return false;
    }

    private autoFill(field: string, targetFill: string): void {
        switch ([field, targetFill].join(':')) {
            case 'group:multiLearner': {
                this.autoFillMultiLeanerWithGroup();
                break;
            }
            case 'concepts:chapters':
            case 'parentChapters:chapters': {
                this.autoFillChildrenChaptersWithParentChapter();
                break;
            }
            case 'concepts:multiLesson':
            case 'parentChapters:multiLesson':
            case 'chapters:multiLesson': {
                this.autoFillMultiLessonWithChapters();
                break;
            }
            default: {
                throw new Error(`Autofill ${targetFill} with ${field} not implemented`);
            }
        }
    }

    private autoFillMultiLessonWithChapters(): void {
        const chaptersRestriction: ChapterEntity[] = [];

        const chapterName = this.controls.chapters.value;

        if (!!chapterName) {
            chaptersRestriction.push(this.chapters.find(pc => pc.get('name') === chapterName));
        } else {
            const parentChapterName = this.controls.parentChapters.value;

            if (!!parentChapterName) {
                const parentChapter = this.parentChapters.find(pc => pc.get('name') === parentChapterName);
                const childrenChapters = this.getChildrenChapterFormParentChapterId(parentChapter.id.toString());
                chaptersRestriction.push(parentChapter, ...childrenChapters);
            } else {
                const conceptName = this.controls.concepts.value;

                if (!!conceptName) {
                    const concept = this.concepts.find(c => c.get('name') === conceptName);
                    const parentChapters = this.availableParentChapters.filter(c => c.get('concepts').includes(concept.id.toString()));
                    const childrenChapters = parentChapters.reduce((acc: ChapterEntity[], pc) => ([...acc, ...this.getChildrenChapterFormParentChapterId(pc.id.toString())]), []);
                    chaptersRestriction.push(...parentChapters, ...childrenChapters);
                }
            }
        }

        if (chaptersRestriction.length === 0) {
            this.lessons = this.graphUbolinoService.lessons.slice();
        } else {
            this.lessons = this.graphUbolinoService.lessons.filter(l => {
                return l.attributes.metadatas.chapters.some((c) => {
                    const chaptersId = chaptersRestriction.map(cr => cr.id.toString());
                    return chaptersId.includes(c.id);
                });
            });
        }
    }

    private autoFillChildrenChaptersWithParentChapter(): void {
        const parentChapterName = this.controls.parentChapters.value;
        const parentChapter = this.parentChapters.find(pc => pc.get('name') === parentChapterName);

        if (!!parentChapter) {
            this.childrenChapters = this.getChildrenChapterFormParentChapterId(parentChapter.id.toString());
        } else {
            const conceptName = this.controls.concepts.value;

            if (!!conceptName) {
                const concept = this.concepts.find(c => c.get('name') === conceptName);
                this.parentChapters = this.availableParentChapters.filter(c => c.get('concepts').includes(concept.id.toString()));
                this.childrenChapters = this.parentChapters.reduce((acc: ChapterEntity[], pc) => ([...acc, ...this.getChildrenChapterFormParentChapterId(pc.id.toString())]), []);
            } else {
                this.childrenChapters = this.chapters.filter(c => c.get('parent').length > 0);
            }
        }
    }

    private autoFillMultiLeanerWithGroup(): void {
        const group = this.groups.find(g => g.groupname === this.filtersValues.group);
        if (!!group) {
            // Deux façon de faire, on pourrait activer tout les learner du champ ou activer tout les learner du group
            const learners = this.learners.filter(l => group.learnersIds.includes(+l.id));
            this.controls.multiLearner.setValue(learners.map(l => l.nickname));
        }
    }

    private initAutoFills(): void {
        [...this.filters.always, ...this.filters.hidden].forEach(field => {
            const autoFills = this.fieldsToAutofill(field.label);
            if (autoFills.length > 0) {
                this.controls[field.label].valueChanges.subscribe(() => {
                    autoFills.forEach(targetFill => this.autoFill(field.label, targetFill));
                    this.emitFiltersValues();
                });
            }
        });
    }

    private redoAtEachForcedFilters(): void {
        this.initDefaultValues();
        this.initAutoFills();
    }

    // private autoFillChaptersWithConcepts(): void {
    //     const conceptName = this.controls.concepts.value;
    //     const concept = this.concepts.find(c => c.get('name') === conceptName);
    //
    //     if (!!concept) {
    //         this.setParentChaptersAndApplyToOtherFilters(this.availableParentChapters.filter(c => c.get('concepts').includes(concept.id.toString())));
    //     } else {
    //         this.setParentChaptersAndApplyToOtherFilters(this.availableParentChapters);
    //     }
    // }
    //
    // private setParentChaptersAndApplyToOtherFilters(chapters: ChapterEntity[]): void {
    //     this.parentChapters = chapters.slice();
    //     if (this.parentChapters.length === 1) {
    //         this.childrenChapters = this.parentChapters.map(pc => this.getChildrenChapterFormParentChapterId(pc.id.toString())).flat();
    //     }
    //
    //     this.setMultilessonFromAvailableChapters();
    //
    // }
}
