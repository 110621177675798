import { TtsModule } from './../../../../tts/tts.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConsignesComponent} from '@modules/activities/core/shared-components/consignes/consignes.component';
import {OptionsComponent} from './options/options.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ActivitiesPipesModule} from '@modules/activities/core/pipes/activitiesPipes.module';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {UserActionButtonComponent} from './user-action-button/user-action-button.component';
import {TrainerFeedbackComponent} from './trainer-feedback/trainer-feedback.component';
import { CorpusDisplayEmbedComponent } from './corpus-display-embed/corpus-display-embed.component';
import {SharedModule} from 'shared/shared.module';
import {ErrorReportingModule} from 'fuse-core/components/error-reporting/error-reporting.module';
import {GenericStepperComponent} from '@modules/activities/core/shared-components/generic-stepper/generic-stepper.component';
import { StepperWrapperComponent } from './basic-stepper-container/stepper-wrapper.component';
import {VideoWithMarkersComponent} from '@modules/activities/core/shared-components/video-markers/video-with-markers.component';
import {SummaryComponent} from '@modules/activities/core/shared-components/summary/summary.component';
import {PollReviewComponent} from '@modules/activities/core/player-components/poll-review/poll-review.component';
import { CorpusDisplayWrapperComponent } from './corpus-display-wrapper/corpus-display-wrapper.component';
import {UploadResourceComponent} from '@modules/activities/core/shared-components/upload-resource/upload-resource.component';
import {EditStepComponent} from '@modules/activities/core/shared-components/edit-step/edit-step.component';
import { FeedbackComponent } from './feedback/feedback.component';
import {SelectCorpusUploadComponent} from '@modules/activities/core/shared-components/select-corpus-upload/select-corpus-upload.component';
import {WinsSummaryModule} from 'fuse-core/components/wins-summary/wins-summary.module';
import { VoiceRecorderComponent } from './voice-recorder/voice-recorder.component';
import { ProgressComponent } from './progress/progress.component';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import { ProgressMultiZoneComponent } from './progress-multi-zone/progress-multi-zone.component';
import {ActivitiesDirectiveModule} from '@modules/activities/core/directive/activities-directive.module';
import { ButtonElementComponent } from './button-element/button-element.component';
import {NgChartsModule} from 'ng2-charts';
import { PrettyActivityCardComponent } from './pretty-activity-card/pretty-activity-card.component';
import { ActivitySampleComponent } from './pretty-activity-card/activity-sample/activity-sample.component';
import {PrettyCardModule} from 'fuse-core/components/pretty-card/pretty-card.module';
import { AudioTimeComponent } from './audio-time/audio-time.component';
import { VideoTimeComponent } from './video-time/video-time.component';
import { ActivitiesFeedbackComponent } from './activities-feedback/activities-feedback.component';


@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatRadioModule,
        MatGridListModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatInputModule,
        MatProgressSpinnerModule,
        ActivitiesPipesModule,
        ErrorReportingModule,
        NgChartsModule,
        WinsSummaryModule,
        MatProgressBarModule,
        ActivitiesDirectiveModule,
        TtsModule,
        PrettyCardModule,
    ],
    declarations: [
        ConsignesComponent,
        OptionsComponent,
        UserActionButtonComponent,
        TrainerFeedbackComponent,
        CorpusDisplayEmbedComponent,
        GenericStepperComponent,
        StepperWrapperComponent,
        VideoWithMarkersComponent,
        SummaryComponent,
        PollReviewComponent,
        CorpusDisplayWrapperComponent,
        UploadResourceComponent,
        EditStepComponent,
        FeedbackComponent,
        SelectCorpusUploadComponent,
        ProgressComponent,
        ProgressMultiZoneComponent,
        ButtonElementComponent,
        VoiceRecorderComponent,
        PrettyActivityCardComponent,
        ActivitySampleComponent,
        AudioTimeComponent,
        VideoTimeComponent,
        ActivitiesFeedbackComponent,
    ],
    exports: [
        ConsignesComponent,
        OptionsComponent,
        UserActionButtonComponent,
        TrainerFeedbackComponent,
        CorpusDisplayEmbedComponent,
        StepperWrapperComponent,
        VideoWithMarkersComponent,
        SummaryComponent,
        PollReviewComponent,
        UploadResourceComponent,
        EditStepComponent,
        FeedbackComponent,
        SelectCorpusUploadComponent,
        ProgressComponent,
        ProgressMultiZoneComponent,
        ButtonElementComponent,
        VoiceRecorderComponent,
        PrettyActivityCardComponent,
        ActivitiesFeedbackComponent
    ],
})
export class CommonSharedModule {
}
