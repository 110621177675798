import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {brand} from '../../../settings';
import {ActivitiesService} from '@modules/activities/core/activities.service';

@Component({
    selector: 'app-activities',
    templateUrl: './activities.component.html'
})
export class ActivitiesComponent implements OnInit {
    public brand: string = brand;

    constructor(private activityService: ActivitiesService) { }

    ngOnInit() {
    }

    /**
     * check if we show header
     * @returns {boolean}
     */
    public get displayHeader(): boolean {
        return !this.activityService.settings.hideHeader;
    }
}
