import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {take, takeUntil} from 'rxjs/operators';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models/auto-unsubscribe-take-until.class';
import {Subject} from 'rxjs';
import {FullscreenService} from 'fuse-core/services/fullscreen.service';
import {DOCUMENT} from '@angular/common';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {DataEntity} from 'octopus-connect';
import {ReadableDirective} from '../../../../../tts/directives/readable.directive';
import {v4 as uuidv4} from 'uuid';
import {LessonsConfigurationService} from "@modules/activities/core/lessons/services/lessons-configuration.service";

@Component({
    selector: 'app-consignes',
    templateUrl: './consignes.component.html',
})
export class ConsignesComponent extends AutoUnsubscribeTakeUntilClass implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    public isTTSSpeaking = false;
    public imgTag: string;
    public instructionUpdated: string;
    public username = '';
    public uuid = '';
    @ViewChild('readMe', {read: ElementRef}) readMe: ElementRef<HTMLElement>;

    speakStateChanged(data: { id: string, value: boolean }): void {
        this.isReading.emit(data);
        this.isTTSSpeaking = data.value;
        this.ref.detectChanges();
    }

    @Input() questionTypeName?: string;
    @Input() instruction = '';
    @Input() instructionAudio?: string;
    @Input() read = new Subject(); // to force read by input event use uuidv4() to pass different value at each time
    @Input() wording = '';
    @Input() readable?: boolean;
    @Input() additionalHiddenText?: string;
    @Input() autoRead = true; // launch automaticly or not the read of instruction or wording
    @Input() wordingAudio?: string;
    @Input() language?: string;
    @Input() forceStopReading? = new Subject<void>();
    @Output() isReading: EventEmitter<{
        id: string;
        value: boolean;
    }> = new EventEmitter<{ id: string; value: boolean }>();

    @ViewChild(ReadableDirective) readableDirective: ReadableDirective;

    private config = inject(LessonsConfigurationService);

    constructor(
        protected communicationCenter: CommunicationCenterService,
        public ref: ChangeDetectorRef,
        public fullscreenService: FullscreenService,
        @Inject(DOCUMENT) private document: any,
        public activitiesService: ActivitiesService
    ) {
        super();
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData').pipe(take(1))
            .subscribe((data: DataEntity) => {
                this.username = data.get('nickname');
            });
    }

    ngOnInit(): void {
        this.uuid = uuidv4();
        this.updateInstruction();
    }

    ngAfterViewInit(): void {
        // read the wording if exist and autoread is not pass at false by input and if there is no global modal feedback opened
        if (this.readMe && this.autoRead) {
            this.readMe.nativeElement.click();
        }
        // launch read manually emitting true from parent
        this.read.pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe((read) => {
            if (read === true) {
                this.readContent();
            }
        });

        if (this.forceStopReading) {
            this.forceStopReading.pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe(() => {
                    this.stopReading();
                });
        }
    }

    updateInstruction(): void {
        this.stopReading();
        if (this.instruction && this.instruction.includes('<img')) {
            this.imgTag = this.instruction.match(/<img([\w\W]+?)>/g)[0];
            this.instructionUpdated = this.instruction.replace(this.imgTag, '');
            this.imgTag = this.imgTag.replace('<img', '<img id="consigneImgTag"');
        } else if (this.instruction) {
            this.instructionUpdated = this.instruction;
            this.imgTag = '';
        }
    }

    private stopReading() {
        if (this.readableDirective) {
            this.readableDirective.stop();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            (changes.instruction && changes.instruction.currentValue !== changes.instruction.previousValue) ||
            (changes.wording && changes.wording.currentValue !== changes.wording.previousValue)
        ) {
            this.updateInstruction();
        }

        if (
            ((changes.instruction && changes.instruction.currentValue !== changes.instruction.previousValue) ||
                (changes.wording && changes.wording.currentValue !== changes.wording.previousValue)) &&
            this.readMe &&
            this.autoRead
        ) {
            this.readContent();
        }
    }

    /**
     * text is readable by default if we pass [readable]=false in input it'will be not
     */
    public isReadable(): boolean {
        if (!this.readable && typeof this.readable !== 'undefined') {
            return false;
        }
        return true;
    }

    // Check if instruction includes an audio tag
    public includesAudioTag(): boolean {
        if (this.instruction?.includes('<audio')) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * read content
     * @private
     */
    private readContent(): void {
        if (this.isReadAllowed() && this.readMe) {
            const el: HTMLElement = this.readMe.nativeElement;
            setTimeout(() => {
                el.click();
            }, 500);
        }
    }

    /**
     * read is allowed if TTS is in FR only or with audio content
     * @private
     */
    public isReadAllowed(): boolean {
        return !!(this.language === 'fr' || this.wordingAudio || this.instructionAudio);
    }

    ngOnDestroy(): void {
        this.stopReading();
    }

    public isTtsActive() {
        return this.config.isTtsActive()
    }
}
