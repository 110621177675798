import {Directive, ElementRef, EventEmitter, Inject, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[focus]'
})
export class FocusDirective implements OnInit{
  constructor(
    @Inject(ElementRef) private element: ElementRef
  ) { }

  ngOnInit() {

  }

  @Input() set focus(event: EventEmitter<string>) {
    event.subscribe(event => {
      if (this.element.nativeElement.name === event) {
        this.element.nativeElement.focus();
      }
    });
  }
}
