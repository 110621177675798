import {Component, Input, OnInit} from '@angular/core';
import {fuseAnimations} from 'fuse-core/animations';
import {ProjectsService} from '@modules/projects-management/core/projects.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '@modules/authentication';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {ReplaySubject} from 'rxjs';
import {localizedDate} from 'shared/utils/datetime';

@Component({
    selector: 'app-widget-research-sheet',
    templateUrl: './widget-research-sheet.component.html',
    styleUrls: ['./widget-research-sheet.component.scss'],
    animations   : fuseAnimations
})
export class WidgetResearchSheetComponent implements OnInit {
    @Input('sectionSubscription') sectionSubscription: ReplaySubject<any>;

    displayedColumns = ['nameSection', 'nameResearchSheet', 'date'];
    dataSource = new MatTableDataSource();
    resource: boolean;

    constructor(
        private projectsService: ProjectsService,
        private router: Router,
        public authService: AuthenticationService
    ) { }

    ngOnInit(): void {
        this.sectionSubscription.subscribe((data) => {
            this.dataSource.data = data.slice(0, 3);
            this.resource = data && data.length;

        });

    }

    translateDate(data): string {
        if (data) {
            return localizedDate(data.sectionUpdated);
        }
        return '';
    }

    getLinkResearchSheet(data): any{
        this.router.navigate(['/projects/' + this.projectsService.currentProject.id + '/tools/research-sheet/' + data.researchSheetId]);
    }
    getLinkSection(data): any{
        this.router.navigate(['/projects/' + this.projectsService.currentProject.id + '/tools/research-sheet/' + data.researchSheetId + '/section/edit/' + data.sectionId]);
    }

    public get empty(): boolean {
        return !this.resource;
    }

    public get getTermsByRole(): string {
        return  this.authService && this.authService.isAtLeastTrainer() ? 'project-management.research_sheet_empty_widget_trainer' : 'project-management.research_sheet_empty_widget_learner';
    }

   public get align(): string {
        if (!this.empty) {
            return 'start ';
        }
       return 'center ';
   }

}
