import {Injectable} from '@angular/core';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '@modules/graph-inclusive/core/model/rules';
import {ModelSchema, Structures} from "octopus-model";
import {modulesSettings} from "../../../../settings";

const settingsStructure: ModelSchema = new ModelSchema({
    rolesCanShowBannerInfo: Structures.array(),
});

@Injectable({
    providedIn: 'root'
})
export class GraphInclusiveAuthorizationService {
    public settings: { [p: string]: any };

    constructor(private authorizationService: AuthorizationService) {
        this.settings = settingsStructure.filterModel(
            modulesSettings.graphMulti
        );
    }

    activeRulesOnStartup(): void {
        this.authorizationService.addRoleRule(SyncRules.seeGraphsLinksInMenu, ['learner', 'trainer']);
        this.authorizationService.addRoleRule(SyncRules.seeInclusiveGraphsBannerInfo, this.settings.rolesCanShowBannerInfo);
    }

    /**
     * if true, display header banner info
     */
    public get rolesCanShowBannerInfo(): boolean {
        return this.authorizationService.currentUserCan<boolean>(SyncRules.seeInclusiveGraphsBannerInfo);
    }

}
