import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, inject, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-audio-time',
  templateUrl: './audio-time.component.html',
  styleUrls: ['./audio-time.component.scss']
})
export class AudioTimeComponent implements AfterViewInit {
    @Input() src!: string;

    @ViewChild('audioElement') audio: ElementRef<HTMLAudioElement>;

    public duration = 0;

    private changeDetectorRef = inject(ChangeDetectorRef);

    public ngAfterViewInit(): void {
        this.audio.nativeElement.addEventListener('loadedmetadata', () => {
            this.duration = this.audio.nativeElement.duration;
            this.changeDetectorRef.detectChanges();
        })
    }
}
