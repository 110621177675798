
import {take} from 'rxjs/operators';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {DataEntity} from 'octopus-connect';
import {Router} from '@angular/router';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector: 'app-corpus-root',
    templateUrl: './corpus-root.component.html',
    styleUrls: ['./corpus-root.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CorpusRootComponent implements OnInit {

    formats: DataEntity[];

    constructor(
        private corpusService: CorpusService,
        private authService: AuthenticationService,
        private router: Router
    ) {}

    ngOnInit() {
        this.corpusService.loadFormats().subscribe(entities => {
            this.formats = entities;
        });
        
        this.corpusService.licensingMethods.pipe(take(1)).subscribe((methods) => {
            const noLicensingMethods = this.corpusService.licensingSettings
                && this.corpusService.licensingSettings.restrict.includes('corpus')
                && methods.length === 0;

            if (this.authService.isTrainer() && noLicensingMethods) {
                this.router.navigate(['/licensing-restricted']);
            }
        });
    }

}
