import {Injectable} from '@angular/core';
import {OnboardingService} from "@modules/account-management/core/onboarding/onboarding.service";
import {
    AccountManagementComponentsService
} from "@modules/account-management/core/services/account-management-components.service";

@Injectable({
    providedIn: 'root'
})
export class ServicesInstantiatorService {

    constructor(
        private accountManagementComponentsService: AccountManagementComponentsService,
        private onboardingService: OnboardingService
    ) {
    }
}
