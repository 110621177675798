import {DynamicGraphFilters} from '@modules/graph-mathia/core/model/dynamic-graph-filters';
import * as moment from 'moment';

export const LevelFilters: DynamicGraphFilters = {
    always: [{
        label: 'startDate',
        value: moment().add(-2, 'weeks').startOf('day').toDate()
    }, {
        label: 'endDate',
        value: moment().endOf('day').toDate()
    }, {
        label: 'multiLesson',
        value: null,
    }, {
        label: 'exerciseType',
        value: null,
        custom: {
            rules: ['allowEmpty']
        }
    }],
    hidden: [{
        label: 'group',
        value: null,
    }, {
        label: 'workgroup',
        value: null,
    }]
};
