import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import * as _ from 'lodash-es';
import {ReplaySubject} from 'rxjs';
import {GraphConfig} from 'fuse-core/components/graph/graph-mixed/graph.config';
import {Observable} from 'rxjs';
import {Chart, ChartEvent, ChartOptions, ChartType, Color} from 'chart.js';
import {CustomTooltipModalContentSettings, CustomTooltipSetting} from 'fuse-core/components/graph/graph-details-modal/custom-tooltip.setting';
import {ChartConfiguration} from 'chart.js/dist/types';

@Component({
    selector: 'app-graph-mixed',
    templateUrl: './graph-mixed.component.html',
    styleUrls: ['./graph-mixed.component.scss']
})
export class GraphMixedComponent implements OnInit {

    @Input() config: Observable<GraphConfig>;
    @ViewChild('chart') chart: ElementRef;

    public chartType: ChartType = 'bar';
    public customSettingsWrapper: CustomTooltipSetting = <CustomTooltipSetting>{};
    public infoSettings: ReplaySubject<CustomTooltipSetting> = new ReplaySubject<CustomTooltipSetting>();
    public showTooltip: boolean;
    private modalContent: CustomTooltipModalContentSettings[][];
    private selectedPoint: {
        index: number;
        dataIndex: number;
    };

    public chartOptions: ChartOptions = {
        responsive: true,
        plugins: {
            title: {
                display: false,
                text: ''
            },
            tooltip: {
                enabled: false,
                external: (tooltipModel) => {
                    let arrowDirection = 'arrow-on-top'; // arrow above by default
                    const tooltipWidth = 280;
                    const tooltipHeight = 300; // Arbitrary height of the tooltip
                    const chartWidth = this.chart.nativeElement.offsetWidth;
                    const chartHeight = this.chart.nativeElement.offsetHeight;
                    const chartTopLimit = chartHeight - tooltipHeight;
                    const chartLeftLimit = tooltipWidth / 2;
                    const chartRightLimit = chartWidth - (tooltipWidth / 2);

                    if (tooltipModel.tooltip.caretY > chartTopLimit) { // point is under the line
                        arrowDirection = 'arrow-on-bottom'; // arrow under
                    }
                    if (tooltipModel.tooltip.caretX > chartRightLimit) {
                        arrowDirection += ' arrow-on-right'; // arrow on right
                    }
                    if (tooltipModel.tooltip.caretX < chartLeftLimit) {
                        arrowDirection += ' arrow-on-left'; // arrow on left of tooltip
                    }

                    this.mergeCustomWrapperData({
                        tooltipY: tooltipModel.tooltip.caretY,
                        tooltipX: tooltipModel.tooltip.caretX,
                        type: arrowDirection,
                    });
                }
            },
        },
        elements: {
            line: {
                tension: 0 // disables bezier curves
            }
        },
        layout: {
            padding: {
                left: 50,
                right: 50,
                top: 25,
                bottom: 50
            }
        },
    };

    public chartData: ChartConfiguration['data']['datasets'] = [];
    public chartLabels: string[] = [];
    public legend = true;
    public graphGenerated: boolean;

    constructor(public router: Router) {
    }

    ngOnInit(): any {
        this.config.subscribe((data: GraphConfig) => {
            this.showTooltip = false;

            const dataLength = data.chartData.length;
            const chartDataLength = this.chartData.length;
            const lengthToSplice = chartDataLength - dataLength;

            if (dataLength) {
                this.chartOptions = _.merge(this.chartOptions, data.chartConfig);
                this.modalContent = data.modalContent;
                for (let index = 0; index < dataLength; index += 1) {
                    this.chartData[index] = data.chartData[index];
                }

                if (lengthToSplice > 0) {
                    this.chartData.splice(dataLength, lengthToSplice);
                }

                this.chartLabels = data.chartLabels;
                this.graphGenerated = true;
            } else {
                this.graphGenerated = false;
            }
        });
        try {
            this.chart.nativeElement.addEventListener('keydown', (event) => {
                this.handleKeyDown(event);
            });
        } catch (ex) {
            console.error(ex);
        }


    }

    onChartClick(e: { event?: ChartEvent, active?: any[] }): void {
        if (e.active.length > 0) {
            const clickedElementIndex = e.active[0].index;
            const clickedElementDataIndex = e.active[0].datasetIndex;

            if (this.selectedPoint
                && this.selectedPoint.index === clickedElementIndex
                && this.selectedPoint['dataIndex'] === clickedElementDataIndex) {
                this.selectedPoint = null;
                this.showTooltip = false;
                return;
            } else {
                this.selectedPoint = {
                    index: clickedElementIndex,
                    dataIndex: clickedElementDataIndex,
                };

                this.mergeCustomWrapperData({
                    modalContent: this.modalContent[clickedElementDataIndex][clickedElementIndex],
                });
                this.infoSettings.next(this.settingsWrapper);
                this.showTooltip = true;
            }
        }
    }

    private mergeCustomWrapperData(data: Partial<CustomTooltipSetting>): void {
        this.customSettingsWrapper = {..._.cloneDeep(this.customSettingsWrapper), ..._.cloneDeep(data)};
    }

    public get settingsWrapper(): CustomTooltipSetting {
        return _.cloneDeep(this.customSettingsWrapper);
    }

    /**
     * to manage tab navigation on graph element next tab after last element go outside and
     * pop up open in middle of screen on top
     * @param event
     */
    handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            const canvas = this.chart.nativeElement;
            if (this.selectedPoint && this.selectedPoint.index === this.chartData[0].data.length - 1) {
                canvas.blur();
                this.selectedPoint = null;
                this.showTooltip = false;
                return;
            }

            this.mergeCustomWrapperData({
                tooltipY: 20,
                tooltipX: ((this.chart.nativeElement.offsetWidth - 180) / 2),
                type: '',
            });
            canvas.focus();

            if (this.selectedPoint && this.selectedPoint.index < this.chartData[0].data.length - 1) {
                this.selectedPoint = {
                    index: this.selectedPoint.index + 1,
                    dataIndex: 0
                };
            } else {
                this.selectedPoint = {
                    index: 0,
                    dataIndex: 0
                };
            }

            this.mergeCustomWrapperData({
                modalContent: this.modalContent[this.selectedPoint.dataIndex][this.selectedPoint.index],
            });
            this.infoSettings.next(this.settingsWrapper);
            this.showTooltip = true;
        }
    }
}

