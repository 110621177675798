import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { AnimalCanvasComponent } from '../animal-canvas/animal-canvas.component';
import { GamificationService } from '../gamification.service';
import { DataEntity } from 'octopus-connect';
import { Creature } from '../definitions';

@Component({
    selector: 'app-my-animals',
    templateUrl: './my-animals.component.html',
    styleUrls: ['./my-animals.component.scss']
})
export class MyAnimalsComponent implements OnInit {

    currentIndex = 0;
    src: string;
    isLoading = false;

    @ViewChild('canvas') canvas: AnimalCanvasComponent;
    get controlsWidth(): number {
        if (!this.canvas || !this.canvas.isReady) {
            return 0;
        }
        return this.canvas.width;
    }

    overrideElements: Array<DataEntity> | undefined;

    @Input()
    set selected(creature: Creature) {
        if (creature) {
            this.currentIndex = this.gamificationService.badges.creatures
                .filter(cr => cr.creature.attributes.unLocked === true)
                .findIndex(cr => cr.creature.id === creature.creature.id);
        } else {
            this.currentIndex = this.gamificationService.badges.creatures
                .filter(cr => cr.creature.attributes.unLocked === true)
                .findIndex(cr => cr.creature.attributes.selected === true);
        }
        if (this.currentIndex < 0) {
            this.currentIndex = 0;
        }
    }


    constructor(private gamificationService: GamificationService) {
    }

    ngOnInit(): void {
    }

    get displayControls(): boolean {
        if (!this.canvas) {
            return false;
        }
        return this.canvas.isReady && this.isLoading === false;
    }


    get availableData(): Array<Creature> {
        const creatures = this.gamificationService.badges.creatures.filter(c => c.creature.attributes.unLocked === true);
        return creatures;
    }

    get currentData(): Creature {
        const currentData = this.availableData[this.currentIndex];
        if (currentData) {
            currentData.loadAccessories();
        }
        return currentData;
    }

    get isReady(): boolean {
        return !this.currentData || this.currentData.status === 'loaded';
    }

    onNext(): void {
        this.overrideElements = undefined;
        this.currentIndex += 1;
        if (this.currentIndex >= this.availableData.length) {
            this.currentIndex = 0;
        }
        this.gamificationService.badges.creatures[this.currentIndex].loadAccessories();
        this.overrideElements = undefined;
    }

    onPrevious(): void {
        this.overrideElements = undefined;
        this.currentIndex -= 1;
        if (this.currentIndex < 0) {
            this.currentIndex = this.availableData.length - 1;
        }
        this.overrideElements = undefined;
        this.gamificationService.badges.creatures[this.currentIndex].loadAccessories();
    }

    onSaveUserImage(setAsAvatar: boolean): void {
        this.isLoading = true;
        const img = this.canvas.getAvatarData();
        this.src = img;

        this.gamificationService.uploadImage(this.currentData, img).then(res => {
            if (res) {
                this.redraw(undefined);
                if (setAsAvatar === true) {
                    this.gamificationService.setAvatar(this.currentData);
                }
            }
            this.isLoading = false;
        }).catch(err => {
            console.log('Error uploading userImage', err);
            this.isLoading = false;
        });
    }


    redraw(elements: Array<DataEntity> | undefined): void {
        this.canvas.refresh(elements);
    }


}
