import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from 'fuse-core/animations';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProjectsFormComponent } from '@modules/projects-management/core/projects-form/projects-form.component';
import { ProjectsService } from '@modules/projects-management/core/projects.service';
import {AuthenticationService} from '@modules/authentication';

@Component({
  selector: 'app-projects-management',
  templateUrl: './projects-management.component.html',
  styleUrls: ['./projects-management.component.scss'],
  animations   : fuseAnimations
})
export class ProjectsManagementComponent implements OnInit {
  dialogRef: any;
  isStudent: boolean;

  constructor(
    public projectsService: ProjectsService,
    public dialog: MatDialog,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): any {
      if (this.authService.hasLevel(['learner'])){
          this.isStudent = true;
      }
  }

  public newProject(): any {
    this.dialogRef = this.dialog.open(ProjectsFormComponent, {
      panelClass: 'project-form-dialog',
      data      : {
        action: 'new'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe((response: UntypedFormGroup) => {
        if (!response) {
          return;
        }

        this.projectsService.addProject(response.getRawValue());
      });
  }

}
