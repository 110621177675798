import {Component, Inject} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

export interface MediaData {
    title: string;
    controlsList?: string;
    oncontextmenu?: string;
    controls?: boolean;
    muted?: string;
    image?: {
        uri: string;
        filemime?: string;
    };
    video?: {
        uri: string;
        filemime?: string;
        subtitles?: {
            [key: string]: string;
        }
    };
}

@Component({
    selector: 'app-modal-wrapper-media',
    templateUrl: './modal-wrapper-media.component.html',
    styleUrls: ['./modal-wrapper-media.component.scss']
})
export class ModalWrapperMediaComponent {
    controlsList: string = undefined;
    oncontextmenu: string = undefined;
    controls: boolean = false;
    muted: string = undefined;
    image: {
        uri: string;
        filemime?: string;
        title?: string;
    }
    video: {
        uri: string;
        filemime?: string;
        subtitles?: {
            [key: string]: string;
        }
    };
    title: string;

    constructor(public dialogRef: MatDialogRef<ModalWrapperMediaComponent>, @Inject(MAT_DIALOG_DATA) public data?: MediaData) {
        if (this.data) {
            this.title = this.data.title;
            this.controlsList = this.data.controlsList;
            this.oncontextmenu = this.data.oncontextmenu;
            this.controls = this.data.controls;
            this.muted = this.data.muted;
            this.video = this.data.video;
            this.image = this.data.image;
        }
    }

    close(value?: boolean): void {
        this.dialogRef.close(value);
    }
}
