import {take} from 'rxjs/operators';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CalendarOptions, defineFullCalendarElement, EventApi} from '@fullcalendar/web-component';
import {EventInput} from '@fullcalendar/common';
import frLocale from '@fullcalendar/core/locales/fr';
import nlLocale from '@fullcalendar/core/locales/nl';
import {AgendaEventUI} from '@modules/agenda/core/definitions';
import {AgendaService} from '@modules/agenda/core/agenda.service';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import dayGridPlugin, {DayGridView} from '@fullcalendar/daygrid';


@Component({
    selector: 'app-agenda-weekly',
    templateUrl: './agenda-weekly.component.html',
    styleUrls: ['./agenda-weekly.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AgendaWeeklyComponent implements OnInit {
    public locale = frLocale;
    public headerConfig = {
        left: '',
        center: '',
        right: ''
    };

    private _allEvents = new Array<AgendaEventUI>();
    private currentDayGridView: DayGridView;
    private currentFilter = {};
    public calendarOptions: CalendarOptions = {initialView: 'dayGridWeek',
        plugins: [dayGridPlugin],
        locale: this.locale,
        events: <EventInput[]> this.allEvents,
        dayHeaderFormat: {weekday: 'long', day: 'numeric', month: 'numeric'},
        headerToolbar: this.headerConfig,
        height: 'auto',
        displayEventTime: false,
        fixedWeekCount: false,
        eventDidMount: this.eventRender.bind(this),
        datesSet: this.datesRender.bind(this),
        eventClick: this.onEventClick.bind(this)};

    constructor(
        private agendaService: AgendaService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
    ) {
        this.communicationCenter
            .getRoom('dashboard')
            .getSubject('filter')
            .subscribe((filter) => {
                this.currentFilter = {};

                if (filter.group) {
                    this.currentFilter['group'] = filter.group;
                }

                if (this.currentDayGridView) {
                    this.datesRender({view: this.currentDayGridView});
                }
            });
    }

    ngOnInit(): void {
        this.setCalendarLocale();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.setCalendarLocale(event.lang);
        });

        this.calendarOptions.locale = this.locale;
    }

    private setCalendarLocale(locale?: string): void {
        let lang = this.translate.currentLang;
        if (locale) {
            lang = locale;
        }
        switch (lang) {
            case 'fr':
                this.locale = frLocale;
                break;
            case 'nl':
                this.locale = nlLocale;
                break;
            default:
                this.locale = frLocale;
                break;
        }
    }

    public get allEvents(): Array<AgendaEventUI> {
        return this._allEvents;
    }

    public eventRender(event: { event: EventApi, el: HTMLElement }): void {
        (event.el as HTMLElement).setAttribute('aria-label', event.event.title);
        (event.el as HTMLElement).setAttribute('data-balloon-pos', 'up');
        (event.el as HTMLElement).setAttribute('data-balloon-length', 'fit');
    }

    public datesRender(event): void {
        this.currentDayGridView = event.view;

        const filterFromView = this.agendaService.getFiltersFromView(event.view);

        this.agendaService.searchAllEvents(Object.assign(filterFromView, this.currentFilter)).pipe(
            take(1))
            .subscribe((events: AgendaEventUI[]) => {
                this._allEvents = events;
                this.calendarOptions.events = <EventInput[]> this.allEvents;
            });
    }

    public openAssignmentsGroup(event: AgendaEventUI): void {
        const data = {
            title: event.title,
            type: event.extendedProps.assignationType
        };
        this.router.navigate(['../', 'followed', 'list'], {relativeTo: this.route, queryParams: data});
    }

    public onEventClick({event}): void {
        if (event.extendedProps.type === 'assignation') {
            if (this.authService.isTrainer()) {
                this.openAssignmentsGroup(event);
            } else if (this.authService.isLearner()) {
                if (event.extendedProps.entity.attributes.type_term.label === 'assessment'
                    || event.extendedProps.entity.attributes.type_term.label === 'homework') {
                    if (moment(new Date()).isBetween(event.start, event.end)) {
                        this.agendaService.launchAssignment(event);
                    }
                }
            }
        }
    }
}
