/** */
export const DOMAIN_MESSAGING = 'messaging';
/** */
export const EVENT_CORE_LABELS = 'core.labels';
/** */
export const EVENT_THREAD_COUNT = 'thread.count';
/** */
export const EVENT_THREAD_ACTIVATED = 'thread.activated';
/** */
export const EVENT_THREAD_UPDATE = 'thread.update';
/** */
export const EVENT_THREAD_DELETE = 'thread.delete';
/** */
export const EVENT_THREAD_NEW = 'thread.new';
/** */
export const EVENT_THREAD_EDIT = 'thread.edit';
/** */
export const EVENT_THREAD_EDIT_LAST_VIEWED = 'thread.edit.last-viewed';
/** */
export const EVENT_THREAD_LIST = 'thread.list';
/** */
export const EVENT_THREAD_LIST_NEXT = 'thread.list.next';
/** */
export const EVENT_THREAD_LIST_NEXT_HREF = 'thread.list.next-href';
/** */
export const EVENT_THREAD_EDIT_OPEN_MODAL = 'thread.edit.open-modal';
/** */
export const EVENT_MESSAGE_NEW = 'message.new';
/** */
export const EVENT_MESSAGE_LIST = 'message.list';
/** */
export const EVENT_MESSAGE_DELETE = 'message.delete';
/** */
export const EVENT_MESSAGE_LIST_NEXT = 'message.list.next';
/** */
export const EVENT_MESSAGE_LIST_NEXT_HREF = 'message.list.next-href';
/** */
export const ALL_EVENTS = [
    EVENT_CORE_LABELS,
    EVENT_THREAD_COUNT,
    EVENT_THREAD_ACTIVATED,
    EVENT_THREAD_UPDATE,
    EVENT_THREAD_DELETE,
    EVENT_THREAD_NEW,
    EVENT_THREAD_EDIT,
    EVENT_THREAD_LIST,
    EVENT_THREAD_LIST_NEXT,
    EVENT_THREAD_LIST_NEXT_HREF,
    EVENT_THREAD_EDIT_OPEN_MODAL,
    EVENT_MESSAGE_NEW,
    EVENT_MESSAGE_LIST,
    EVENT_MESSAGE_DELETE,
    EVENT_MESSAGE_LIST_NEXT,
    EVENT_MESSAGE_LIST_NEXT_HREF,
];
