import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-contest-timer',
    templateUrl: './contest-timer.component.html',
})
export class ContestTimerComponent implements OnChanges {
    @Input() private timerTitle: string;
    @Input() private targetDate: Date;
    @Input() private alignIcon: 'left' | 'center' | 'right' = 'center';
    
    private countdownInterval: NodeJS.Timeout;
    
    public timeRemaining: string;
    
    ngOnChanges(changes: SimpleChanges) {
        if (changes.targetDate) {
            this.startCountdown();
        }
    }
    
    public get title(): string {
        return this.timerTitle || 'contest.will_start_in';
    }

    public get alignIconClass(): string {
        return `icon-${this.alignIcon}`;
    }

    private startCountdown() {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
            this.countdownInterval = null;
        }
        
        const updateCountdown = () => {
            const diffInMilliseconds = this.targetDate.getTime() - Date.now();

            if (diffInMilliseconds <= 0) {
                this.timeRemaining = "00:00:00";
                return;
            }

            const days = Math.floor(diffInMilliseconds / (24 * 60 * 60 * 1000)).toString().padStart(2, '0');
            const hours = Math.floor((diffInMilliseconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)).toString().padStart(2, '0');
            const minutes = Math.floor((diffInMilliseconds % (60 * 60 * 1000)) / (60 * 1000)).toString().padStart(2, '0');

            this.timeRemaining = `${days}:${hours}:${minutes}`;
        };

        if (this.targetDate) {
            updateCountdown();
            this.countdownInterval = setInterval(updateCountdown, 60000);
        }
    }
}
