import { Component } from '@angular/core';
import {brand} from '../../../../app/settings';


@Component({
    selector   : 'fuse-demo-content',
    templateUrl: './demo-content.component.html',
    styleUrls  : ['./demo-content.component.scss']
})
export class FuseDemoContentComponent
{
    brand: string = brand;

    /**
     * Constructor
     */
    constructor()
    {
    }
}
