import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-association-learner-to-group',
    templateUrl: './association-learner-to-group.component.html',
    styleUrls: ['./association-learner-to-group.component.scss']
})
export class AssociationLearnerToGroupComponent {

    constructor(public router: Router) {
    }

    public goToGroupManagementPage(): void {
        this.router.navigate(['/groups/list/groups']);
    }
}
