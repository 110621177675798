import * as _ from 'lodash-es';

/**
 * Return a copy of the array in another order
 * @param arr the array to shuffle
 * @param forceChange prevent the rare case to return the same order if true
 */
export const shuffle = <T>(arr: T[], forceChange: boolean = false) => {
    let shuffled = _.shuffle(arr.slice());

    if (forceChange && shuffled.length > 1 && _.isEqual(arr, shuffled)) {
        shuffled = shuffle(arr, true);
    }

    return shuffled;
};