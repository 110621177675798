import {Injectable} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {Observable, Subject} from 'rxjs';
import {filter, map, mergeMap, takeUntil} from 'rxjs/operators';
import {DataCollection} from 'octopus-connect';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {ContextualService} from "@modules/achievement/core/services/contextual.service";

@Injectable({
    providedIn: 'root'
})
export class IsNewUserService {
    private userData: UserDataEntity;
    private unsubscribeInTakeUntil = new Subject<void>();
    public isNewUser: boolean;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private contextualService: ContextualService,
    ) {
        this.communicationCenter.getRoom('authentication').getSubject('userData').subscribe(userData => {
            if (userData) {
                this.userData = userData;
                this.postAuthentication();
            } else {
                this.postLogout();
            }
        });
        
        this.setupContextual();
    }

    public isNewUser$(): Observable<boolean> {
        return this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .pipe(
                filter(userData => !!userData),
                mergeMap(() => this.communicationCenter
                    .getRoom('assignment')
                    .getSubject('loadPaginatedAssignmentsCallback')),
                mergeMap(callBack => {
                    const obs = callBack({
                        'assignated_user': [this.userData.id],
                    });
                    return obs.collectionObservable ? obs.collectionObservable : obs;
                }),
                map((data: DataCollection) => data.entities.length === 0)
            );
    }

    private postAuthentication(): void {
        const isNewUser$ = this.isNewUser$();

        this.communicationCenter.getRoom('achievement').next('isNewUser$', isNewUser$);

        isNewUser$.pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((isNewUser) => {
                    this.isNewUser = isNewUser;
            });
    }

    private postLogout(): void {
        this.isNewUser = false;
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
        this.unsubscribeInTakeUntil = new Subject<void>();
    }
    
    private setupContextual(): void {
        this.contextualService.conditionAchievementNewUser$
            .subscribe((callback) => {
                callback(this.isNewUser);
            });
    }
}
