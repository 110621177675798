import {Injectable} from '@angular/core';
import {LessonsService} from "@modules/activities";
import {OnboardingService} from "@modules/activities/core/onboarding/onboarding.service";
import {ConceptsService} from "@modules/activities/core/services/concepts.service";
import {ContextualService} from "@modules/activities/core/services/contextual.service";
import {LessonEditionService} from '@modules/activities/core/lessons/editor/services/lesson-edition.service';
import {SaveService} from '@modules/activities/core/save.service';

@Injectable({
    providedIn: 'root'
})
export class ServicesInstantiatorService {

    constructor(
        private lessonsService: LessonsService,
        private onboardingService: OnboardingService,
        private conceptsService: ConceptsService,
        private contextualService: ContextualService,
        private lessonEditionService: LessonEditionService,
        private saveService: SaveService
    ) {
    }
}
