import {Injectable} from '@angular/core';
import {LessonActivityRoutes, NavigateToLessonOptions} from '@modules/activities/core/models/lessonsActivityRoutes';
import {NavigationExtras, Params, Router} from '@angular/router';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import * as _ from 'lodash-es';
import {CommunicationCenterService} from "@modules/communication-center";
import {ActivityGranule} from '@modules/activities/core/models';

interface NavigationToEndingOfLessonOptions {
    lessonId: string | number;
    endingPath: 'summary' | 'recap' | 'reward';
    urlPrefix?: string;
    queryParams?: any;
}

@Injectable({
    providedIn: 'root'
})
export class LessonNavigationService {

    constructor(
        private communicationCenter: CommunicationCenterService,
        private router: Router,
        private lessonsConfigurationService: LessonsConfigurationService,
    ) {
        this.communicationCenter.getRoom('lessons').getSubject('openLesson')
            .subscribe(({id, originUrl, queryParams}: {id: string | number, originUrl: string, queryParams ?: {[key:string]: string|number}})  => {
                this.navigateToLessonPage(id, originUrl, queryParams);
            });
    }

    public navigateToRecap(lessonId: string | number, urlPrefix = '/'): void {
        this.navigateToEndingOfLesson({
            lessonId,
            endingPath: 'recap',
            urlPrefix
        });
    }

    public navigateToReward(lessonId: string | number, urlPrefix = '', queryParams: Params): void {
        this.navigateToEndingOfLesson({
            lessonId,
            endingPath: 'reward',
            urlPrefix,
            queryParams
        });
    }
    public navigateToSummary(lessonId: string | number, urlPrefix = '', queryParams: Params): void {
        this.navigateToEndingOfLesson({
            lessonId,
            endingPath: 'summary',
            urlPrefix,
            queryParams
        });
    }

    /**
     * Directly execute routing to an url for execute the good activity player from the params
     * @param {DataEntity} data Activity to play (can be a lesson to play as a multi-activity)
     * @param loadBeforeLaunch todo
     * @param relativePath Force the relative path used to calculate new path. If no argument is passed the default value is '../..' (or '../../../..' in case of multi).
     * @param forceSkipLocation
     */
    navigateToAnActivityInsideALessonActuallyPlayed(
        data: ActivityGranule,
        loadBeforeLaunch?: boolean,
        relativePath?: string,
        forceSkipLocation?: boolean,
        queryParams?: Params
    ): void {
        const urlFragments = [];
        const type = data.attributes.format.label;

        if (type === 'activity') {
            const label = data.attributes?.metadatas?.typology?.label;
            urlFragments.push(LessonActivityRoutes[label]);
        } else if (type === 'divider') {
            urlFragments.push('divider');
        } else if (type === 'lesson') {
            urlFragments.push('multi');
        } else {
            urlFragments.push('media');
        }

        urlFragments.push(data.id);

        if (!relativePath) {
            // Si le relativePath n'est pas forcé il faut qu'on vérifie si on est dans une multi-activité ou non et ainsi savoir s'il faut remonter de 2 ou 3 niveaux
            relativePath = this.router.url.indexOf('multi') > -1
                ? '../../../..'
                : this.router.url.indexOf('summary') === -1
                ? '../..'
                : '..';
        }
        const navigationExtras = this.constructNavParams(
            !!loadBeforeLaunch,
            data.id.toString(),
            queryParams
        );
        if (forceSkipLocation !== undefined) {
            navigationExtras.skipLocationChange = forceSkipLocation;
        }
        this.router.navigate([relativePath, ...urlFragments], navigationExtras);
    }

    constructNavParams(
        loadBeforeLaunch: boolean,
        activityId: string,
        queryParams: Params,
    ): NavigationExtras {
        let currentRoute = this.router.routerState.root;
        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }
        let  params: Params = {isLoadBeforeLaunch: loadBeforeLaunch, id: activityId};
        if (queryParams) {
            params = _.merge({},queryParams, {isLoadBeforeLaunch: loadBeforeLaunch, id: activityId});
        }

        return {
            relativeTo: currentRoute,
            queryParams: params
        };
    }

    public navigateToLessonPage(lessonId: string | number, originUrl: string, queryParams?: {[key:string]: string|number}) {
        const defautQueryParams = {'origin-url': originUrl};
        const queryParamsToUse = queryParams ? {...defautQueryParams, ...queryParams} : defautQueryParams;

        this.router.navigate(['lessons', lessonId, 'page'], {queryParams: queryParamsToUse});
    }

    public navigateToLesson(lessonId: string | number, urlPrefix: string, forceNextLesson: boolean, options: NavigateToLessonOptions): void {
        const navigationExtras: NavigationExtras = {
            skipLocationChange:  options && !options.isActivitiesListMustBeDisplayed,
            queryParams: options
        }
        if (options?.isActivitiesListMustBeDisplayed) {
            this.router.navigate(
                ['lessons', lessonId, 'activities'],
                navigationExtras
            );
        } else {
            if (!!urlPrefix) {
                //  fix: if current url is the same as the new url (new lesson to start), we have an error "outlet of null".
                if (forceNextLesson) {
                    this.router
                        .navigateByUrl('/followed/list', {skipLocationChange: true})
                        .then(() =>
                            this.router.navigate(
                                [urlPrefix, 'lessons', lessonId, 'player'],
                                navigationExtras
                            )
                        );
                } else {
                    this.router.navigate(
                        [urlPrefix, 'lessons', lessonId, 'player'],
                        navigationExtras
                    );
                }
            } else {
                this.router.navigate(['lessons', lessonId, 'player'], navigationExtras);
            }
        }
    }

    private navigateToEndingOfLesson(options: NavigationToEndingOfLessonOptions): void {

        const {lessonId, endingPath, urlPrefix, queryParams} = options;

        const rootUrl = urlPrefix || '/';
        const navigationExtras: NavigationExtras = {
            skipLocationChange: false,
            queryParams: queryParams || {},
        };

        this.router.navigate(
            [rootUrl, 'lessons', lessonId, 'player', endingPath],
            navigationExtras
        );
    }
}
