import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {modulesSettings} from '../../../settings';
import {CorpusService} from '@modules/corpus/core/corpus.service';

@Injectable()
export class userCorpusTest  {

    modulesSettings = modulesSettings;

    constructor(
        public corpusService: CorpusService,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const userCorpus = this.corpusService.settings.userCorpus;
        const globalCorpus = this.corpusService.settings.globalCorpus;

        if (userCorpus){
            return true;
        } else {
            this.router.navigate(['/corpus/' + globalCorpus]);
        }
    }
}
