import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import * as _ from 'lodash-es';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {shuffle} from 'lodash-es';

export class missingRandomizeTools {

    public static shuffleAnswers(availableAnswers: AnswerInterface[], availableAnswersForButton: AnswerInterface[], shuffleAnswerButton = false): { availableAnswers: AnswerInterface[], availableAnswersForButton: (AnswerInterface & {state?: ItemAnswerStateEnum})[] } {
        // answer use for button sometime change of order or not in regard of config
        availableAnswersForButton = _.cloneDeep(availableAnswers);
        availableAnswers = this.randomizeAvailableAnswers(availableAnswers);
        // set the new good button answer to true
        availableAnswersForButton = this.setGoodButtonAnswer(availableAnswersForButton, availableAnswers);
        // shuffle button answer too or not in regard of settings of config
        if (shuffleAnswerButton) {
            availableAnswersForButton = shuffle(availableAnswersForButton);
        }
        return {availableAnswers: availableAnswers, availableAnswersForButton: availableAnswersForButton};
    }

    private static randomizeAvailableAnswers(availableAnswers: AnswerInterface[]): AnswerInterface[] {
        const randomizeIndexTrueAnswer = Math.floor(Math.random() * availableAnswers.length);
        availableAnswers.map(a => {
            a.correct_answer = false;
        });
        availableAnswers[randomizeIndexTrueAnswer].correct_answer = true;
        return availableAnswers;
    }

    /**
     * take the true answer of available answer and set the same on availableAnswerForButton
     * @param availableAnswersForButton
     * @param availableAnswers
     */
    private static setGoodButtonAnswer(availableAnswersForButton: AnswerInterface[], availableAnswers: AnswerInterface[]): AnswerInterface[] {
        availableAnswersForButton.forEach(a => {
            a.correct_answer = a.id === availableAnswers.find(b => b.correct_answer).id;
        });
        return availableAnswersForButton;
    }
}