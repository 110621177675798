import {TextPatternInterface} from './text-pattern.interface';
import {TextOptionsLight} from 'jspdf';
import {Omit} from '../../../omit.type';
import {FontInterface} from '../../fonts/font.interface';

/**
 * Used to add a text to a pdf. The text has no style expect the used font
 */
export class TextPattern implements TextPatternInterface {

    readonly type = 'TextPattern';

    padding = {top: null, left: null, bottom: null, right: null};
    width = null;

    text = '';
    font: FontInterface;
    fontSize: number;
    options: TextOptionsLight;

    constructor(options?: Omit<Partial<TextPatternInterface>, 'type'>) {
        Object.assign(this, options);
    }
}
