import { Injectable } from '@angular/core';
import {modulesSettings} from "../../../settings";
import {ModelSchema, Structures} from "octopus-model";

const settingsStructure: ModelSchema = new ModelSchema({
    rolesCanShowBannerInfo: Structures.array(),
});

@Injectable({
  providedIn: 'root'
})
export class NotepadConfigurationService {

    public settings: { [p: string]: any };

  constructor() {
      this.settings = settingsStructure.filterModel(
          modulesSettings.notepad
      );
  }

    /**
     * if true, display header banner info
     */
    public get rolesCanShowBannerInfo(): string[] {
        return this.settings.rolesCanShowBannerInfo;
    }

}
