import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {IsNewUserService} from './is-new-user.service';

@Injectable({
    providedIn: 'root'
})
export class IsNewUserGuard  {
    constructor(
        private isNewUserService: IsNewUserService,
        private router: Router) {
    }


    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        return this.isNewUserService.isNewUser$()
            .pipe(
                tap(isNewUser => {
                    if (isNewUser === false) {
                        this.router.navigate(['/achievement/my-profile']);
                    }
                })
            );
    }

}
