import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'app-beginning-pop-up',
    templateUrl: './beginning-pop-up.component.html',
    styleUrls: ['./beginning-pop-up.component.scss']
})
export class BeginningPopUpComponent implements OnInit {

    constructor(
        private router: Router,
        private snackBar: MatSnackBar
    ) {
    }

    ngOnInit(): void {
    }

    onActionClick($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.snackBar.dismiss();
        this.router.navigate(['/lessons/list/models']);
    }
}
