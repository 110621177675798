import { Injectable } from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';

@Injectable({
  providedIn: 'root'
})
export class EmbeddedActivitiesService {

  constructor(private communicationCenter: CommunicationCenterService) {
      this.communicationCenter
          .getRoom('activities')
          .next('isActivityEmbedded', true);


      this.communicationCenter.getRoom('fuseConfig').next('layout', {
          layout: {
              navbar: {
                  hidden: true,
              },
              toolbar: {
                  hidden: true,
              },
              footer: {
                  hidden: true,
              }
          }
      });
  }
}
