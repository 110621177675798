// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-choice {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 30px;
  overflow: auto;
}
.section-choice .title {
  line-height: 34px;
  font-size: 24px;
  letter-spacing: 0.145455px;
  font-weight: bold;
}
.section-choice .items-container {
  padding-top: 20px;
}

.save-button {
  background: #FF787D;
  box-shadow: 0px 1px 8px rgba(209, 209, 209, 0.5);
  border-radius: 17.5px;
  height: 35px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  position: relative;
}
.save-button:disabled {
  opacity: 0.5;
}

.save-input-container {
  padding-top: 12px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/research-sheet/core/research-template/edited-template-section-choice/edited-template-section-choice.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;AACF;AACE;EACE,iBAAA;EACA,eAAA;EACA,0BAAA;EACA,iBAAA;AACJ;AAME;EACE,iBAAA;AAJJ;;AAQA;EACE,mBAAA;EACA,gDAAA;EACA,qBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AALF;AAOE;EACE,YAAA;AALJ;;AASA;EACE,iBAAA;EACA,kBAAA;AANF","sourcesContent":[".section-choice {\n  padding-right: 20px;\n  padding-left: 20px;\n  padding-top: 30px;\n  overflow: auto;\n\n  .title {\n    line-height: 34px;\n    font-size: 24px;\n    letter-spacing: 0.145455px;\n    font-weight: bold;\n  }\n\n  .sub-title {\n\n  }\n\n  .items-container {\n    padding-top: 20px;\n  }\n}\n\n.save-button {\n  background: #FF787D;\n  box-shadow: 0px 1px 8px rgba(209, 209, 209, 0.5);\n  border-radius: 17.5px;\n  height: 35px;\n  color: white;\n  padding-left: 20px;\n  padding-right: 20px;\n  cursor: pointer;\n  position: relative;\n\n  &:disabled {\n    opacity: 0.5;\n  }\n}\n\n.save-input-container {\n  padding-top: 12px;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
