import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';

const settingsStructure = new ModelSchema({
    noMailMenu: Structures.boolean(false),
    rolesCanShowBannerInfo: Structures.array(),
});

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    public settings: { [key: string]: any };

    constructor() {
        this.settings = settingsStructure.filterModel(modulesSettings.mindmap);
    }

    public get noMailmenu(): boolean {
        return this.settings.noMailMenu;
    }

    /**
     * if true, display header banner info
     */
    public get rolesCanShowBannerInfo(): string[] {
        return this.settings.rolesCanShowBannerInfo;
    }
}
