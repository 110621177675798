// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-agenda-weekly full-calendar .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/agenda/core/widget/agenda-weekly/agenda-weekly.component.scss"],"names":[],"mappings":"AAEQ;EACI,kBAAA;AADZ","sourcesContent":["app-agenda-weekly {\n    full-calendar {\n        .fc-toolbar.fc-header-toolbar {\n            margin-bottom: 1em;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
