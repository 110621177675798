import {Component, inject, Input, OnInit} from '@angular/core';
import {CustomBlock} from 'fuse-core/components/collection/custom-blocks/custom-block.model';
import {CommunicationCenterService} from '@modules/communication-center';
import {Router} from '@angular/router';

@Component({
    selector: 'app-lesson-tile',
    templateUrl: './lesson-tile.component.html',
    styleUrls: ['./lesson-tile.component.scss']
})
export class LessonTileComponent implements OnInit {

    @Input() block: CustomBlock;
    public btnTxt: string;
    private lessonId: number;

    private communicationCenter = inject(CommunicationCenterService)
    private router = inject(Router)

    ngOnInit(): void {
        if (this.block['lessons'].length > 0) {
            this.btnTxt = this.block['lessons'][0].metadatas.title;
            this.lessonId = +this.block['lessons'][0].id;
        }
    }

    public openLesson() {
        this.communicationCenter.getRoom('lessons').next('openLesson', {
            id: this.lessonId,
            originUrl: this.router.url,
        });
    }
}
