import {Component, Input, OnInit} from '@angular/core';
import {CustomBlock} from 'fuse-core/components/collection/custom-blocks/custom-block.model';

@Component({
  selector: 'app-key-figures',
  templateUrl: './key-figures.component.html',
  styleUrls: ['./key-figures.component.scss']
})
export class KeyFiguresComponent {

    @Input() block: CustomBlock;

    constructor() {}

}
