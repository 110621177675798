// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
  white-space: nowrap;
}

.add-button {
  border-radius: 50%;
  border: 1px solid black;
  background-color: white;
  width: 20px;
  height: 20px;
  cursor: pointer;
  text-align: center;
  font-size: 24px;
  line-height: 22px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 0;
  z-index: 99;
}

.main-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.categories-scrollable {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/ideas-wall/core/categories-view/categories-view.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,uBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EAEA,kBAAA;EAEA,YAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;AADF;;AAIA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;AADF;;AAIA;EACE,MAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;EACA,kBAAA;EACA,cAAA;AADF","sourcesContent":[":host {\n  position: relative;\n  white-space: nowrap;\n}\n\n.add-button {\n  border-radius: 50%;\n  border: 1px solid black;\n  background-color: white;\n  width: 20px;\n  height: 20px;\n  cursor: pointer;\n  text-align: center;\n  font-size: 24px;\n  line-height: 22px;\n  //margin: 10px;\n  position: absolute;\n\n  bottom: 20px;\n  right: 20px;\n  padding: 0;\n  z-index: 99;\n}\n\n.main-container {\n  position: relative;\n  height: 100%;\n  overflow: hidden;\n}\n\n.categories-scrollable {\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  position: absolute;\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
