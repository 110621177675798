import {debounceTime, filter, map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CommunicationCenterService} from '../../../communication-center';
import {Observable, Subscription, ReplaySubject} from 'rxjs';
import {CollectionOptionsInterface, DataCollection, DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings';
import {AuthenticationService} from '@modules/authentication';
import {Learner} from '@modules/groups-management/core/definitions';

const graphSettingsStructure: ModelSchema = new ModelSchema(({
    learner: Structures.array(['startDate', 'dueDate']),
    default: Structures.array(['learner', 'startDate', 'dueDate'])
}));

@Injectable({
    providedIn: 'root'
})
export class DataVisualizationService {
    public assignmentGroupListPaginated: PaginatedCollection;
    learnerList: Learner[];
    userData: DataEntity;
    public graphSettings: { [key: string]: any };
    public loadPaginatedAssignments$: (filters?: CollectionOptionsInterface) => Observable<DataCollection>;
    public serviceIsReady$ = new ReplaySubject(1);

    constructor(private communicationCenter: CommunicationCenterService,
                private octopusConnect: OctopusConnectService,
                private authService: AuthenticationService
    ) {
        this.serviceIsReady$.next(false);

        this.graphSettings = graphSettingsStructure.filterModel(modulesSettings.graphAssignation);
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.serviceIsReady$.next(false);
                this.userData = data;
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter.getRoom('assignment').getSubject('loadPaginatedAssignmentsCallback')
            .pipe(
                tap((callback: (filters?: CollectionOptionsInterface) => Observable<DataCollection>) => {
                    this.loadPaginatedAssignments$ = callback;
                })
            ).subscribe();
    }

    public get isUserTrainer(): boolean {
        return this.authService.isAtLeastTrainer();
    }

    /**
     * Get current logged user's id
     */
    public getUserId(): number | string {
        return this.userData.id;
    }

    private postLogout(): void {
        this.serviceIsReady$.next(true);
    }

    private postAuthentication(): void {
        const learnersReady$ = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList').pipe(
                debounceTime(1000),
                filter((learners) => learners !== null),
                tap((learners: Learner[]) => this.learnerList = learners),
                map(() => true)
            );

        learnersReady$.subscribe(() => {
            this.serviceIsReady$.next(true);
        });
    }
}

