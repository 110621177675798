import {Component} from '@angular/core';
import {GraphUbolinoService} from '../../services/graph-ubolino.service';

@Component({
    selector: 'app-multi-graph-view',
    templateUrl: './multi-graph-view.component.html',
})
export class MultiGraphViewComponent {
    constructor(private graphService: GraphUbolinoService) {
    }

    graphsAreAvailable(): boolean {
        return this.graphService.graphsAreAvailable();
    }
}
