// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sections {
  position: absolute;
  top: 130px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/research-sheet/core/research-sheet/research-sheet-display/research-sheet-display.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;AACF","sourcesContent":[".sections {\n  position: absolute;\n  top: 130px;\n  left: 10px;\n  right: 10px;\n  bottom: 10px;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
