import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {combineLatest, concat, Observable, of, reduce, ReplaySubject, Subject} from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, filter, map, mapTo, mergeMap, startWith, take, tap} from 'rxjs/operators';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {ProgressData} from '@modules/graph-humanum/core/model/progress-data';
import {DynamicGraphFilters} from '@modules/graph-humanum/core/model/dynamic-graph-filters';
import {Learner} from '@modules/graph-humanum/core/model/learner';
import * as moment from 'moment';
import * as _ from 'lodash-es';
import {GraphFiltersValues} from '@modules/graph-humanum/core/model/graph-filters-values';
import {ProgressDataEntity} from '@modules/graph-humanum/core/model/progress-data-entity';
import {Group} from '@modules/graph-humanum/core/model/group';
import {Workgroup} from '@modules/graph-humanum/core/model/workgroup';
import {GraphData} from '@modules/graph-humanum/core/model/graph-data';
import {AttendanceData} from '@modules/graph-humanum/core/model/attendance-data';
import {GraphFilter} from '@modules/graph-humanum/core/model/graph-filter';
import {ProgressEndpointFilters} from '@modules/graph-humanum/core/model/progress-endpoint-filters';
import {MultiLessonDataEntity} from '@modules/graph-humanum/core/model/multi-lesson-data.entity';
import {
    DefaultAttendanceFilters,
    DefaultProgressFilters,
    DefaultOwnProgressFilters,
} from '@modules/graph-humanum/core/model/default-filters';
import {CollectionOptionsInterface} from 'octopus-connect';
import {CollectionPaginator} from 'octopus-connect';
import {ChapterEntity, ChaptersService} from 'fuse-core/services/chapters.service';
import {EducationalLevelEntity, EducationalLevelService} from '@modules/activities/core/services/educational-level.service';
import {ConceptDataCollection, ConceptEntity} from 'shared/models';

const SHARED_FILTERS = ['startDate', 'endDate', 'chapter', 'concept', 'group', 'multiLesson', 'educationalLevel', 'multiSubLesson'];

@Injectable({
    providedIn: 'root'
})
export class GraphHumanumService {
    public filtersChanges = new ReplaySubject<{ raw: Partial<GraphFiltersValues>, optimised: Partial<GraphFiltersValues> }>(1);
    public forceFiltersValues = new Subject<void>();
    public dynamicFilters: DynamicGraphFilters = {always: [], hidden: []};
    public learners: Learner[] = [];
    public lessons: MultiLessonDataEntity[] = [];
    public chapters: ChapterEntity[] = [];
    public isReady = new ReplaySubject(1);
    public graphDataArePending = new ReplaySubject<void>(1);
    public workgroups: Workgroup[] = [];
    public groups: Group[] = [];
    public currentUser: DataEntity;
    public concepts: ConceptEntity[];
    public conceptsAvailable: ConceptEntity[] = [];
    public educationalLevels: EducationalLevelEntity[];
    private cacheFilters: Partial<GraphFiltersValues> = {};
    private cache: { progressRawData: ProgressData, attendanceRawData: AttendanceData } = {
        progressRawData: null,
        attendanceRawData: null
    };

    constructor(private router: Router,
                private educationalLevelService: EducationalLevelService,
                private communicationCenter: CommunicationCenterService,
                private octopusConnect: OctopusConnectService,
                private chaptersService: ChaptersService) {
        this.isReady.next(false);
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((currentUser: DataEntity) => {
                if (!!currentUser) {
                    this.postAuthentication(currentUser);
                    this.currentUser = currentUser;
                } else {
                    this.postLogout();
                    this.currentUser = undefined;
                }
            });
        this.filtersChanges.pipe(debounceTime(250)).subscribe(filters => {
            this.cacheFilters = _.merge(this.cacheFilters, filters.raw);
        });
    }

    private get flattenedDynamicFilters(): GraphFilter[] {
        return [...this.dynamicFilters.always, ...this.dynamicFilters.hidden];
    }

    /**
     * Transforme an object from something like user filters (inputs) to a format accepted by the endpoint 'Progress' ({@link loadProgressData})
     * @param filters
     */
    static toProgressEndpointFriendlyFilters(filters: Partial<GraphFiltersValues>): ProgressEndpointFilters {
        return !filters ? undefined : new ProgressEndpointFilters(
            moment(filters.startDate).unix(),
            moment(filters.endDate).unix(),
            filters.exerciseList
        );
    }

    public getAttendanceGraphData(): Observable<AttendanceData> {
        const isValid = (a: GraphFiltersValues) => !!a.startDate && !!a.endDate;

        const isSame = (a: Partial<GraphFiltersValues>, b: Partial<GraphFiltersValues>) =>
            a.endDate.toString() === b.endDate.toString()
            && a.startDate.toString() === b.endDate.toString()
            && _.isEqual(a.learnerList, b.learnerList);

        this.resetDynamicFiltersForAttendanceGraph();

        return this.filtersChanges.pipe(
            debounceTime(1000),
            filter((graphFilters) => this.toggleActivityAttendanceView(graphFilters.raw.attendanceView === 'activity')),
            map(f => f.optimised),
            filter(isValid),
            distinctUntilChanged(isSame),
            tap(() => this.graphDataArePending.next()),
            mergeMap(graphFilters => {
                if (!graphFilters.learnerList || graphFilters.learnerList.length === 0) {
                    return of(new AttendanceData(graphFilters, []));
                }

                return this.getProgressDataIfNeeded<AttendanceData>(AttendanceData, graphFilters, this.cache.attendanceRawData).pipe(
                    tap(attendanceData => this.cache.attendanceRawData = attendanceData) // Si je le met sur la ligne d'en dessous, phpstorm est dans les choux
                );
            })
        );
    }

    /**
     * Return the data used to generate a 'progress' graph. If filters changed, the observable is refresh
     * @remarks, use a custom cache to avoid useless request.
     */
    public getProgressGraphData(): Observable<ProgressData> {
        const isSame = (a: Partial<GraphFiltersValues>, b: Partial<GraphFiltersValues>) =>
            a.learner === b.learner
            && a.chapter === b.chapter
            && a.educationalLevel === b.educationalLevel
            && a.focus === b.focus
            // && a.view === b.view
            && a.lessonList === b.lessonList
            && a.subLessonList === b.subLessonList
            && a.endDate.toString() === b.endDate.toString()
            && a.startDate.toString() === b.startDate.toString();

        const isValid = (a: GraphFiltersValues) =>
            !!a.startDate
            && !!a.endDate
            && !!(!!a.subLessonList && a.subLessonList.length > 0)
            && !!a.learner; // on a pas besoin du learner pour la requête, mais sans on pourra pas traiter les données

        this.resetDynamicFiltersForProgressGraph();

        return this.filtersChanges.pipe(
            debounceTime(1000),
            map(f => f.optimised),
            filter(isValid),
            distinctUntilChanged(isSame),
            tap(() => this.graphDataArePending.next()),
            filter(graphFilters => graphFilters.subLessonList.length > 0),
            tap(graphFilters => graphFilters.exerciseList = graphFilters.lessonList.map(sId => sId.toString())),
            mergeMap(graphFilters => this.getProgressDataIfNeeded<ProgressData>(ProgressData, graphFilters, this.cache.progressRawData).pipe(
                tap(progressData => this.cache.progressRawData = progressData)
            ))
        );
    }

    /**
     * Return the data used to generate a 'progress' graph. If filters changed, the observable is refresh
     * @remarks, use a custom cache to avoid useless request.
     */
    public getOwnProgressGraphData(): Observable<ProgressData> {
        const isSame = (a: Partial<GraphFiltersValues>, b: Partial<GraphFiltersValues>) =>
            a.chapter === b.chapter
            && a.focus === b.focus
            // && a.view === b.view
            && a.lessonList === b.lessonList
            && a.subLessonList === b.subLessonList
            && a.endDate.toString() === b.endDate.toString()
            && a.startDate.toString() === b.startDate.toString();

        const isValid = (a: GraphFiltersValues) =>
            !!a.startDate
            && !!a.endDate
            && !!(!!a.subLessonList && a.subLessonList.length > 0)

        this.resetDynamicFiltersForOwnProgressGraph();

        return this.filtersChanges.pipe(
            debounceTime(1000),
            map(f => f.optimised),
            filter(isValid),
            distinctUntilChanged(isSame),
            tap(() => this.graphDataArePending.next()),
            tap(graphFilter => graphFilter.learner = this.currentUser.id.toString()),
            mergeMap(graphFilters => this.getOwnProgressDataIfNeeded<ProgressData>(ProgressData, graphFilters, this.cache.progressRawData).pipe(
                 tap(progressData => { this.cache.progressRawData = progressData; })
            ))
        );
    }

    /**
     * Return the list of the learner sorted by nicknames
     */
    public getLearnersAlphabetically(): Learner[] {
        return this.learners.sort((a, b) => a.nickname.localeCompare(b.nickname, [], {numeric: false}));
    }

    /**
     * get learners from filters group and workgroup. But if both are valued, get only learners inside both group and workgroup.
     * @private
     * @param groupId
     * @param workgroupId
     */
    public getLearnerFilteredOfGroupAndWorkgroup(groupId: string | number, workgroupId: string | number): number[] {
        let learnersIds: number[] = [];
        if (!!groupId && !!workgroupId) {
            const group = this.groups.find(g => +g.id === +groupId);
            const workgroup = this.workgroups.find(g => +g.id === +workgroupId);

            if (!!group && !!workgroup) {
                const wgIds = workgroup.learnersIds.map(id => +id);
                learnersIds = group.learnersIds.filter(id => wgIds.includes(+id));
            }
        } else if (!!groupId) {
            const group = this.groups.find(g => +g.id === +groupId);
            if (!!group) {
                learnersIds.push(...group.learnersIds.map(id => +id));
            }
        } else if (workgroupId) {
            const workgroup = this.workgroups.find(g => +g.id === +workgroupId);
            if (!!workgroup) {
                learnersIds.push(...workgroup.learnersIds.map(id => +id));
            }
        }
        return learnersIds;
    }

    /**
     * store the learner selected on level graph to use it on progress graph when open it directly from level graph
     * @param learnerId : id of learner
     */
    public storeLearnerSelectedInCacheFilter(learnerId: string): void {
        const currentLearner = <any>this.learners.filter(l => l.id.toString() === learnerId)[0];
        this.cacheFilters.learner = currentLearner.nickname;
    }

    /**
     * take the learner in cache and put it inside the dynamic filter used to init filter
     * use to set filter in progress of the learner we click previously in level graph
     */
    public setLearnerDynamicFilterWithCacheFilter(): void {
        // get learner stored in cache to inject the same
        if (!!this.cacheFilters.learner) {
            const learnerFilter = this.flattenedDynamicFilters.find(f => f.label === 'learner');
            if (!!learnerFilter) {
                learnerFilter.value = this.cacheFilters.learner;
            }
        }
    }

    public graphsAreAvailable(): boolean {
        return this.learners.length > 0;
    }

    /**
     * Return a list of Lesson's Granules with silent auto pagination.
     */
    private getLessonGranuleCollectionInChain(options?: CollectionOptionsInterface): Observable<DataEntity[]> {
        return this.communicationCenter.getRoom('activities')
            .getSubject('loadPaginatedLessonGranuleCollection')
            .pipe(
                mergeMap((getLessonCallback: (options?: CollectionOptionsInterface) => Observable<{ entities: DataEntity[], paginator: CollectionPaginator }>) =>
                    getLessonCallback(_.merge({page: 1, range: 10}, options)).pipe(
                        take(1),
                        // Il y a un bug dans octopus-connect, il met à jour le paginator (le count par exemple) un poil trop tard, on a déjà reçu le résultat ici.
                        // Donc on s'endort quelques millisecondes pour lui laisser le temps de réagir au résultat avant nous.
                        delay(1000),
                        mergeMap(firstAttempt => {
                            const firstAttemptEntities = firstAttempt.entities.slice();
                            const total = firstAttempt.paginator.count;
                            const pages: number[] = [];
                            let currentPage = 1; // la premiere page est dans le "firstAttempt", donc on passe directement a la page suivante
                            if (total < options.range) {
                                return of(firstAttemptEntities);
                            }

                            do {
                                currentPage++;
                                pages.push(currentPage);
                            } while ((currentPage * options.range) < total);


                            const observablesOfEachPages = pages.map(i =>
                                getLessonCallback(_.merge({range: 10}, options, {page: i})).pipe(
                                    map(r => r.entities.slice()),
                                    take(1),
                                ));

                            return concat(
                                ...observablesOfEachPages
                            ).pipe(
                                reduce((acc: DataEntity[], val) => acc.concat(val), []),
                                map(results => [...firstAttemptEntities, ...results]),
                            );

                        })
                    )
                ),
            );
    }

    private postAuthentication(currentUser: DataEntity): void {
        combineLatest([
            this.initLearners(),
            this.initChapters(),
            this.initConcepts(),
            this.initEducationalLevels(),
            this.initGroups(),
            this.initWorkgroups(),
            this.initLessons(currentUser)
        ])
            // .pipe(take(1))
            .subscribe(() => {
                // this.resetDynamicFiltersForProgressGraph(); // Default values;
                this.isReady.next(true);
                this.communicationCenter
                    .getRoom('graphHumanum')
                    .getSubject('initSelectedLearnerFilter')
                    .pipe(
                        tap((selectedLearnerId: number) => this.storeLearnerSelectedInCacheFilter(selectedLearnerId.toString())),
                        tap(() => this.router.navigate(['graph-humanum', 'multi', 'progress']))
                    )
                    .subscribe();
            });
    }

    private postLogout(): void {
        this.learners = [];
        this.lessons = [];
        this.chapters = [];
        this.groups = [];
        this.workgroups = [];
        this.concepts = [];
        this.educationalLevels = [];
        this.isReady.next(false);
    }

    private resetDynamicFiltersForAttendanceGraph(): void {
        // Set default values
        const learnerIds: number[] = [];
        this.dynamicFilters = _.clone(DefaultAttendanceFilters);

        const flatFilters = this.flattenedDynamicFilters;
        if (this.groups.length > 0) {
            flatFilters.find(f => f.label === 'group').value = this.groups[0].groupname;
            learnerIds.push(...this.getLearnerFilteredOfGroupAndWorkgroup(this.groups[0].id, null));
        } else {
            learnerIds.push(...this.getLearnerFilteredOfGroupAndWorkgroup(null, null));
        }
        flatFilters.find(f => f.label === 'multiLearner').value = this.learners.filter(l => learnerIds.includes(+l.id)).map(l => l.id);

        this.conceptsAvailable.length = 0;
        this.conceptsAvailable.push(...this.concepts.filter(c => c.get('showIn').includes('graph:attendance')));

        // But erase it with cache filters
        this.setCachedFilters();
        this.forceFiltersValues.next();
    }

    private resetDynamicFiltersForProgressGraph(): void {
        // Set default values
        const learner = this.getFirstLearnerAlphabetically();
        const chapter = this.getFirstChapterAlphabetically();
        this.conceptsAvailable.length = 0;
        this.conceptsAvailable.push(...this.concepts.filter(c => c.get('showIn').includes('graph:progress')));
        const concept = this.getFirstConceptAlphabetically();
        const educationalLevel = this.getFirstEducationalLevelAlphabetically();
        this.dynamicFilters = _.clone(DefaultProgressFilters);
        const flatFilters = this.flattenedDynamicFilters;
        if (!!learner) {
            flatFilters.find(f => f.label === 'learner').value = learner;
        }
        if (!!chapter) {
            flatFilters.find(f => f.label === 'chapter').value = chapter.attributes.name;
        }
        if (!!concept) {
            flatFilters.find(f => f.label === 'concept').value = concept.attributes.name;
        }
        if (!!educationalLevel) {
            flatFilters.find(f => f.label === 'educationalLevel').value = educationalLevel.attributes.label;
        }
        // But erase it with cache filters
        this.setCachedFilters();
        this.forceFiltersValues.next();
    }

    private resetDynamicFiltersForOwnProgressGraph(): void {
        const chapter = this.getFirstChapterAlphabetically();
        this.conceptsAvailable.length = 0;
        this.conceptsAvailable.push(...this.concepts.filter(c => c.get('showIn').includes('graph:progress')));
        const concept = this.getFirstConceptAlphabetically();
        this.dynamicFilters = _.clone(DefaultOwnProgressFilters);
        const flatFilters = this.flattenedDynamicFilters;
        if (!!chapter) {
            flatFilters.find(f => f.label === 'chapter').value = chapter.attributes.name;
        }
        if (!!concept) {
            flatFilters.find(f => f.label === 'concept').value = concept.attributes.name;
        }
        // But erase it with cache filters
        this.setCachedFilters();
        this.forceFiltersValues.next();
    }

    private loadProgressData(filter: ProgressEndpointFilters): Observable<DataCollection> {
        if (filter.id === null || filter.id === undefined) {
            delete filter.id;
        }

        return this.octopusConnect.paginatedLoadCollection('learners-stat', {filter}).collectionObservable;
    }

    private getProgressDataIfNeeded<T extends GraphData>(type: { new(...any: unknown[]): T }, graphFilters: Partial<GraphFiltersValues>, cache: T = null): Observable<T> {
        const newFilters = GraphHumanumService.toProgressEndpointFriendlyFilters(graphFilters);
        const oldFilters = GraphHumanumService.toProgressEndpointFriendlyFilters(_.get(cache, 'graphFilters'));

        let obs: Observable<T>;
        // On teste que oldFilters car c'est pas la responsabilité de cette methode de le gérer avant
        // Et au premier chargement du graph, y'a pas de oldFilters
        if (!!oldFilters && newFilters.isSame(oldFilters)) {
            const cloned = _.clone(cache);
            cloned.graphFilters = graphFilters;
            obs = of(cloned);
        } else {
            obs = this.loadProgressData(newFilters).pipe(
                take(1),
                map(progressRawData => new type(graphFilters, <ProgressDataEntity[]>progressRawData.entities)),
            );
        }

        return obs;
    }

    private getOwnProgressDataIfNeeded<T extends GraphData>(type: { new(...any: unknown[]): T }, graphFilters: Partial<GraphFiltersValues>, cache: T = null): Observable<T> {
        const newFilters = GraphHumanumService.toProgressEndpointFriendlyFilters(graphFilters);
        const oldFilters = GraphHumanumService.toProgressEndpointFriendlyFilters(_.get(cache, 'graphFilters'));

        let obs: Observable<T>;
        // On teste que oldFilters car c'est pas la responsabilité de cette methode de le gérer avant
        // Et au premier chargement du graph, y'a pas de oldFilters
        if (!!oldFilters && newFilters.isSame(oldFilters)) {
            const cloned = _.clone(cache);
            cloned.graphFilters = graphFilters;
            obs = of(cloned);
        } else {
            obs = this.loadProgressData(newFilters).pipe(
                take(1),
                map(progressRawData => new type(graphFilters, <ProgressDataEntity[]>progressRawData.entities))
            );
        }

        return obs;
    }

    private getFirstLearnerAlphabetically(): Learner {
        return this.getLearnersAlphabetically()[0];
    }

    private getFirstChapterAlphabetically(): ChapterEntity {
        return this.chapters.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name, [], {numeric: false}))[0];
    }

    private getFirstConceptAlphabetically(): ConceptEntity {
        return this.conceptsAvailable.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name, [], {numeric: false}))[0];
    }

    private getFirstEducationalLevelAlphabetically(): EducationalLevelEntity {
        return this.educationalLevels.sort((a, b) => a.attributes.label.localeCompare(b.attributes.label, [], {numeric: false}))[0];
    }

    private initLearners(): Observable<void> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList')
            .pipe(
                startWith([]),
                filter((learners) => learners !== null),
                map(list => this.learners = list.slice()),
                tap(() => this.forceFiltersValues.next()) // ¨Fix la liste vide d'élève en rechargeant la page d'un graph.
            );
    }

    private initLessons(currentUser: DataEntity): Observable<MultiLessonDataEntity[]> {
        return this.getUserLessons(currentUser).pipe(
            mergeMap(userLessons => this.getModelsLessons().pipe(map(modelLessons => [...userLessons, ...modelLessons]))),
            map((lessons: DataEntity[]) =>
                (<MultiLessonDataEntity[]>lessons).sort((a, b) => {
                    const lastTime = (d) => +d.attributes.changed > +d.attributes.metadatas.changed ? +d.attributes.changed : +d.attributes.metadatas.changed;
                    return lastTime(a) - lastTime(b);
                }).slice()
            ),
            map(lessons => this.lessons = lessons),
        );
    }

    private getModelsLessons(): Observable<DataEntity[]> {
        return this.communicationCenter.getRoom('activities')
            .getSubject('getAllowedRoleIdsForModelsCreationCallback')
            .pipe(
                map((callback: () => number[]) => callback()),
                mergeMap(modelsRoles => this.getLessonGranuleCollectionInChain({filter: {role: modelsRoles, multi_step: 0, typology: null}, range: 50})),
                take(1)
            );
    }

    private getUserLessons(user: DataEntity): Observable<DataEntity[]> {
        return this.getLessonGranuleCollectionInChain({filter: {author: user.id, multi_step: 0}, range: 50})
            .pipe(
                take(1),
                map(lessons => lessons.slice())
            );
    }

    private initChapters(): Observable<void> {
        return this.chaptersService.getChapters()
            .pipe(
                tap(list => this.chapters = list.slice()),
                mapTo(null)
            );
    }

    private initGroups(): Observable<void> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList')
            .pipe(
                tap(glist => this.groups = glist.filter((group) => !group.archived).slice())
            );
    }

    private initWorkgroups(): Observable<void> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList')
            .pipe(
                tap(wglist => this.workgroups = wglist.filter((wgroup) => !wgroup.archived).slice())
            );
    }

    private setCachedFilters(...limitFilterList: string[]): void {
        const filters = limitFilterList.length > 0
            ? this.flattenedDynamicFilters.filter(f => limitFilterList.includes(f.label))
            : this.flattenedDynamicFilters;

        filters.forEach(graphFilter => {
            if (SHARED_FILTERS.includes(graphFilter.label) && !!this.cacheFilters[graphFilter.label]) {
                graphFilter.value = this.cacheFilters[graphFilter.label];
            }
        });
    }

    /**
     * When activity view is choose in assiduity graph, we add a filter, and remove it when another choice is done
     * @param isActivityView
     * @private
     * @return false if the filter is added or deleted, true if no operation is made
     */
    private toggleActivityAttendanceView(isActivityView: boolean): boolean {
        if (isActivityView && this.dynamicFilters.always.some(f => f.label === 'chapter') === false) {
            const chapter = this.getFirstChapterAlphabetically();
            let defaultValue = '';
            if (!!chapter) {
                defaultValue = chapter.attributes.name;
            }

            this.flattenedDynamicFilters.find(f => f.label === 'attendanceView').value = 'activity';
            this.dynamicFilters.always.push({label: 'chapter', value: defaultValue});
            this.setCachedFilters('chapter');
            this.forceFiltersValues.next();
            return false;
        } else if (isActivityView === false && this.dynamicFilters.always.some(f => f.label === 'chapter')) {
            this.flattenedDynamicFilters.find(f => f.label === 'attendanceView').value = 'global';
            this.dynamicFilters.always = this.dynamicFilters.always.filter(f => f.label !== 'chapter');
            this.forceFiltersValues.next();
            return false;
        }
        return true;
    }

    private initConcepts(): Observable<void> {
        return this.communicationCenter.getRoom('concepts')
            .getSubject('getConceptsCallback')
            .pipe(
                mergeMap(conceptsCb => conceptsCb()),
                take(1),
                map((collection: ConceptDataCollection) => collection.entities),
                map(concepts => concepts.filter(concept => (<string[]>concept.get('showIn') || []).some(c => c.startsWith('graph')))),
                tap((entities) => this.concepts = entities.slice()),
                mapTo(null)
            );
    }

    private initEducationalLevels(): Observable<void> {
        return this.educationalLevelService.getEducationalLevels()
            .pipe(
                take(1),
                tap(educationalLevels => this.educationalLevels = educationalLevels.entities.slice()),
                mapTo(null)
            );
    }
}
