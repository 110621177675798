import {takeUntil} from 'rxjs/operators';
import {Component} from '@angular/core';
import {IAnswer, IMultipleChoice} from '@modules/activities/core/player-components/multiple-choice-grid/multiple-choice/models/imultiple-choice';
import * as _ from 'lodash-es';
import {Observable, of} from 'rxjs';
import {BaseActivityComponent} from '../base-activity.component';
import {MultipleChoiceActivityGranule} from '@modules/activities/core/models/activities/typologies/multiple-choice-activity.granule';

@Component({
    selector: 'app-multiple-choice-grid',
    templateUrl: './multiple-choice-grid.component.html'
})

export class MultipleChoiceGridComponent extends BaseActivityComponent<MultipleChoiceActivityGranule>{
    public answers = [];
    public multipleChoiceDatas: IMultipleChoice[] = [];
    private questions = [];

    /**
     * reset state of component like if component was destroy and create again
     * @param resetAllSubscribe : reset or not all subscribe of component
     */
    protected reset(resetAllSubscribe = false, type: string = null): Observable<boolean> {
        // reset field state
        this.multipleChoiceDatas = [];
        this.questions = [];
        this.answers = [];
        this.instruction = '';
        return super.reset(resetAllSubscribe, type);
    }

    /**
     * save the answers in json format
     */
    protected saveAnswer() {
        return of(JSON.stringify(this.multipleChoiceDatas));
    }

    /**
     * load userSave with answers if exist
     */
    protected loadUserSave(): void {
        this.isSaving = true;
        this.activitiesService.getUserSave(this.activity.id, this.activitiesService.currentAssignmentID).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(userSave => {
                this.isSaving = false;
                if (userSave) {
                    this.userSave = userSave;
                    const content = userSave.get('userActivity').entitySave.content;
                    // all data are store in back in a json object we parse it in current object
                    this.multipleChoiceDatas = JSON.parse(content);
                } else if (this.lessonsService.isMyAssignment()) {
                    this.save();
                }
            });
    }

    /**
     * set the back data in the format we need for the front
     * array of question with for each question an array of possible answers
     */
    protected setContentData(activityAttributes): void {
        // get all the data need
        this.referenceActivityGranule = activityAttributes.reference;
        this.instruction = this.referenceActivityGranule.instruction;
        this.questions = this.referenceActivityGranule.activity_content.questions;
        this.setAnswer();
        this.setMultipleChoiceDatas();
    }

    /**
     * set the answer array with select value by default false
     * @param answers all the possible answer for each question
     **/
    protected setAnswer(): void {
        if ((this.userSave?.get('userActivity')?.entitySave?.answers)
            && !this.displayForSummary) {
            this.userSave.get('userActivity').entitySave.answers.forEach((answer: IAnswer) => {
                this.answers.push({
                    id: answer.id,
                    answer: answer.answer,
                    value: answer.value,
                    select: false
                });
            });
            if (!this.lessonsService.isTrainerSeeCorrection()) {
                this.checkAnswer();
                this.activitiesService.userAnswer.next(this.userSave.get('userActivity').entitySave.answers);
                this.testAnswer = true;
            }
        }
    }

    /**
     * create final object :
     * take data for back with an array of question and one array of answer and prepare data to
     * be use by the component
     */
    private setMultipleChoiceDatas(): void {
        this.questions.forEach(question => {
            this.multipleChoiceDatas.push({
                id: question.id,
                question: question.question,
                multiple_choice: question.multiple_choice,
                answers: _.cloneDeep(this.answers)
            });
        });
    }

    /**
     * event in return of change response
     * /!\ choiceData is equal to this.multipleChoiceDatas because he pass by reference /!\
     * so change of value in choiceData affect this.multipleChoiceData Directly
     * @param choiceData : IMultipleChoice object change in child return to parent
     */
    public updateAnswers(choiceData: IMultipleChoice): void {
        this.allowedSaveButton();
    }

    /**
     * Allowed save buton(enable) only if all question have an answer
     */
    private allowedSaveButton(): void {
        const numberOfQuestionAnswered = this.multipleChoiceDatas.filter(question => {
            return question.answers.filter(answer => answer.select === true).length > 0;
        }).length;
        // enable save button if all question was answered
        if (numberOfQuestionAnswered === this.multipleChoiceDatas.length) {
            this.activitiesService.doesUserResponsed.next(true);
            this.activitiesService.saving = false;
            this.activitiesService.isSaveReady = true;
            this.onOptionChange(true);
        } else {
            this.activitiesService.doesUserResponsed.next(false);
            this.onOptionChange(false);
        }
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected  getGrade(): {oldGrade: number, newGrade: number} {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }


    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}
