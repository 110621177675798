import {Component, Input, OnInit} from '@angular/core';
import {CardsService} from "fuse-core/components/card/cards.service";
import {BreacrumbFields} from 'fuse-core/components/card/lesson-card-breadcrumb/breadcrumb.interface';

@Component({
    selector: 'app-lesson-card-breadcrumb',
    templateUrl: './lesson-card-breadcrumb.component.html',
    styleUrls: ['./lesson-card-breadcrumb.component.scss']
})
export class LessonCardBreadcrumbComponent implements OnInit {

    @Input() public fields: BreacrumbFields;

    constructor() {
    }

    ngOnInit(): void {
    }

}
