import {Injectable} from '@angular/core';
import {IsNewUserService} from "@modules/achievement/core/is-new-user/is-new-user.service";
import {OnboardingService} from "@modules/achievement/core/onboarding/onboarding.service";
import {UserScoreService} from "@modules/achievement/core/services/user-score.service";

@Injectable({
    providedIn: 'root'
})
export class ServicesInstantiatorService {

    constructor(
        private isNewUserService: IsNewUserService,
        private onboardingService: OnboardingService,
        private userScoreService: UserScoreService,
    ) {
    }
}
