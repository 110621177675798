import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataCollection, OctopusConnectService} from 'octopus-connect';

@Injectable({
    providedIn: 'root'
})
export class SearchFiltersService {

    constructor(private octopusConnect: OctopusConnectService) {
    }

    /**
     * Obtain lisy from the server.
     * @return List of {@link DataEntity}
     * @param endpoint
     */
    public getList(endpoint: string, filters = {}): Observable<DataCollection> {
        return this.octopusConnect.loadCollection(endpoint, filters);
    }
}
