import {Injectable} from '@angular/core';
import {CollectionOptionsInterface, DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {FlashCardMediaInterface} from 'fuse-core/components/flashcard/flash-card-media.interface';

@Injectable({
    providedIn: 'root'
})
export class LanguageLabService {
    private dataPaginated: PaginatedCollection;
    private filterOptions: CollectionOptionsInterface = {filter: {}, page: 1, range: 20};

    constructor(private octopusConnectService: OctopusConnectService) {
    }

    /**
     * return laboLang Card formated
     */
    public loadLangCard(page: number = 1, filter = {}, endpoint: string): Observable<LaboratoryCardInterface[]> {
        return this.loadData(page, filter, endpoint).pipe(
            map((data: DataEntity[]) => data.map(d => ({
                    id: d.id.toString(),
                    title: d.get('title'),
                    image: d.get('image'),
                    audio: d.get('audio'),
                    data: d.get('data'),
                    type: d.get('type'),
                    count: d.get('count')
                })),
            ));
    }

    /**
     * get card
     */
    private loadData(page: number = 1, filter: {}, endpoint: string): Observable<DataEntity[]> {
        this.filterOptions.page = page;
        this.filterOptions.filter = filter;
        this.dataPaginated = this.octopusConnectService.paginatedLoadCollection(endpoint, this.filterOptions);
        return this.dataPaginated.collectionObservable
            .pipe(
                take(1),
                map(collection => collection.entities)
            );
    }

    public getAudio(text: string ): Observable<string> {
        return this.octopusConnectService.loadEntity('acapela', text).pipe(
            map(entity => {
                return entity.get('audio');})
        );
    }
}

export interface LaboratoryCardInterface {
    id: string;
    title: string;
    image: FlashCardMediaInterface;
    audio: FlashCardMediaInterface;
    data: string;
    type: string;
    count: string;// number of exo inside
}
