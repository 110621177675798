import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appMoveFocusToNextAllowedElement]'
})

/**
 * use to simulate the tab click and go to the next allowed and valid element when the tab event come on the html element with the directive
 */
export class MoveFocusToNextAllowedElementDirective {

    constructor(private elementRef: ElementRef) {}

    @HostListener('focus')
    onFocus() {
        this.simulateTab();
    }

    simulateTab() {
        const focusableElements = this. elementRef.nativeElement.querySelectorAll('MAT-SELECT,input, button, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = this.findFirstFocusableElement(focusableElements);
        if (firstFocusableElement) {
            firstFocusableElement.focus();
        }
    }

    findFirstFocusableElement(elements: NodeListOf<HTMLElement>): HTMLElement {
        const elementArray = Array.from(elements);

        // order tab index
        elementArray.sort((a, b) => {
            const tabindexA = parseInt(a.getAttribute('tabindex') || '0');
            const tabindexB = parseInt(b.getAttribute('tabindex') || '0');
            return tabindexA - tabindexB;
        });

        for (let i = 0; i < elementArray.length; i++) {
            const element = elementArray[i];
            if (this.isFocusable(element)) {
                return element;
            }
            const childFocusableElement = element.querySelector('input, button, select, textarea, [tabindex]:not([tabindex="-1"]), mat-select');
            if (childFocusableElement) {
                return this.findFirstFocusableElement(element.querySelectorAll('input, button, select, textarea, [tabindex]:not([tabindex="-1"]), mat-select'));
            }
        }
        return null;
    }

    isFocusable(element: HTMLElement): boolean {
        return (
            element.tabIndex >= 0 &&
            !element.hidden &&
            !element.getAttribute('aria-hidden') &&
            (element.offsetWidth > 0 || element.offsetHeight > 0 || element.getClientRects().length > 0)
        );
    }
}
