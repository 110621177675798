import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MembersFilterComponent} from './members-filter.component';
import {TranslateModule} from '@ngx-translate/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    exports: [
        MembersFilterComponent
    ],
    declarations: [MembersFilterComponent]
})
export class MembersFilterModule {
}
