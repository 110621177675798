import {ImagePatternInterface} from './image-pattern.interface';
import {Omit} from '../../../omit.type';

export class ImagePattern implements ImagePatternInterface {
    readonly type = 'ImagePattern';

    padding = {top: null, left: null, bottom: null, right: null};
    width = null;

    base64: string;

    constructor(options?: Omit<Partial<ImagePatternInterface>, 'type'>) {
        Object.assign(this, options);
    }
}
