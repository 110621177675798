import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {CarouselComponent, OwlOptions} from 'ngx-owl-carousel-o';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {brand} from '../../../../../../settings';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs/index';
import {map, take, tap} from 'rxjs/operators';
import {TypedDataCollectionInterface} from 'shared/models/octopus-connect/typed-data-collection.interface';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect/typed-data-entity.interface';
import {NavigateToLessonOptions} from '@modules/activities/core/models/lessonsActivityRoutes';
import {CommunicationCenterService} from '@modules/communication-center';
import {Location} from '@angular/common';

@Component({
    selector: 'app-assignation-detail',
    templateUrl: './assignation-detail.component.html',
    styleUrls: ['./assignation-detail.component.scss']
})
export class AssignationDetailComponent implements OnInit {

    @Input() assignation: any;
    @Input() lesson: any;

    @ViewChild('owlActivities') owlActivities: CarouselComponent;

    public customOptions: OwlOptions = {
        autoWidth: true,
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 700,
        //navText: ['prev', 'next'],
        nav: true,
    };
    private userSaves: DataEntity[] = [];


    constructor(private translate: TranslateService,
                private octopusConnect: OctopusConnectService,
                private communicationCenter: CommunicationCenterService,
                private location: Location) {
    }

    ngOnInit(): void {
        this.loadSaves().pipe().subscribe();
    }

    private loadSaves(): Observable<DataEntity[]> {
        return this.loadUserSaves(null, {granuleParent: this.lesson['lesson']['id']}).pipe(
            tap((userSaves: DataEntity[]) => this.userSaves = userSaves || [])
        );
    }

    public loadUserSaves(contextId?: string, filters?: {}): Observable<TypedDataEntityInterface<{ state: string, granule: (string | number)[] }>[]> {
        if(!filters) {
            let filters;
            if (contextId) {
                filters = {context: contextId};
            }
        }

        return this.octopusConnect.loadCollection('user-save', filters).pipe(
            take(1),
            map((collection: TypedDataCollectionInterface<{ state: string, granule: (string | number)[] }>) => collection.entities));
    }

    public getAssignedActivitiesMessage(assignation: any, lesson: { [key: string]: { lesson?: DataEntity; activities?: DataEntity[]; } }): string {
        if (this.isAllAssignated(assignation, lesson)) {
            return this.translate.instant('assignation.all-assignated');
        } else {
            const activityCount = assignation.assignedActivities.length;
            return activityCount + ' ' + this.translate.instant('assignation.activities-assignated');
        }
    }

    /** return activity by according to its lessonType if exists
     * @param activity
     * @returns {string}
     */
    public getActivityTypeImage(activity: DataEntity): string {
        if (activity.get('metadatas').lessonType.length && activity.get('metadatas').lessonType[0]['image']) {
            return activity.get('metadatas').lessonType[0]['image'];
        }
        return '/assets/' + brand + '/images/thumbs/demo@2x-8.png';
    }

    public isActivityAssignated(assignation: any, activity: DataEntity): boolean {
        return assignation.assignedActivities.includes(activity.id);
    }

    public isAllAssignated(assignation: any, lesson: any): boolean {
        return assignation.assignedActivities.length === lesson['activities']?.length;
    }

    /** check if user has already done the activity - having a grade is enough to know
     * @param activity
     * @returns {boolean}
     */
    public isActivitiesIsDone(activity: DataEntity): boolean {
        return this.userSaves.some((userSave: DataEntity) => +userSave.get('granule')[0] === +activity.id && +userSave.get('grade') > 0);
    }

    public playEvent($event: { subLesson: DataEntity, i: number }): void {

        this.communicationCenter
            .getRoom('assignment')
            .next('current', this.assignation);

        this.play($event.subLesson, $event.i);
    }

    public play(lesson: DataEntity, index): void {
        const options: NavigateToLessonOptions = {
            isActivitiesListMustBeDisplayed: false,
            subLessonId: lesson.id,
            startOnStepIndex: index || 0,
            exitLessonUrl: this.location.path()
        };

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('playLesson')
            .next({assignment: this.assignation, navigateOptions: options});
    }



}
