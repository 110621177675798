import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {DataEntity} from 'octopus-connect';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector     : 'show-profile',
    templateUrl  : './show-profile.component.html',
    styleUrls    : ['./show-profile.component.scss'],
    animations   : fuseAnimations
})
export class ShowProfileComponent implements OnInit
{

    userInformation: DataEntity;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    constructor(private profileProvider: ProfileService,
                private dialog: MatDialog,
                private translate: TranslateService,
                private router: Router,
                private route: ActivatedRoute)
    {

    }

    ngOnInit()
    {
        this.profileProvider.userInformationOnChanged.subscribe(userInformation => {
            this.userInformation = userInformation;

            if (this.userInformation.get('sso') && this.userInformation.get('first_access') === true) {
                this.help();
            }
        });

        this.route.params.subscribe(params => {
                if (params['mode'] && params['mode'] === 'edit') {
                    this.profileProvider.editMode = true;
                } else {
                    this.profileProvider.editMode = false;
                }
            }
        );

    }

    private help(): void {
        const data = {
            titleDialog: 'generic.ask.help',
            bodyDialog: 'generic.ask.help.content',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        };

        this.translate.get(data.titleDialog).subscribe((translation: string) => data.titleDialog = translation);
        this.translate.get(data.bodyDialog).subscribe((translation: string) => data.bodyDialog = translation);
        this.translate.get(data.labelTrueDialog).subscribe((translation: string) => data.labelTrueDialog = translation);
        this.translate.get(data.labelFalseDialog).subscribe((translation: string) => data.labelFalseDialog = translation);

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: data
        });

        this.confirmDialogRef.afterClosed().subscribe(result => {
            this.userInformation.set('first_access', 0);
            this.userInformation.save();
            if (result) {
                // help page
                this.router.navigate(['home']);
            }
            this.confirmDialogRef = null;
        });
    }

    public getMode(): boolean{
        return this.profileProvider.selectedMode;
    }
}
