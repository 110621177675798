import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'lodash-es';

@Pipe({
  name: 'dateReversedMessages'
})
export class DateReversedMessagesPipe implements PipeTransform {

  transform(messages: any[], args?: any): any {
    return isArray(messages) ?  messages.sort((a: any, b: any) => {
        const dateA = parseInt(a.created, 10);
        const dateB = parseInt(b.created, 10);
        if (dateA < dateB) {
            return -1;
          } else if (dateA > dateB) {
            return 1;
          } else {
            return 0;
          }
    }) : [];
  }

}
