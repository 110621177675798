import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ContestService} from 'app/@modules/contest';
import {Contest} from '@modules/contest/core/model/contest';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

@Component({
    selector: 'app-contest-list',
    templateUrl: './contest-list.component.html'
})

export class ContestListComponent implements OnInit, OnDestroy {

    public contests: Contest[];
    private destroy$ = new Subject<void>();

    constructor(private contestService: ContestService) { }

    ngOnInit(): void {
        this.contestService.getContests()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.contests = data;
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
