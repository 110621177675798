import {Component, Injectable} from '@angular/core';
import {NewsInterface} from 'fuse-core/news/news.interface';
import {combineLatest, Observable, Subject, Subscription} from 'rxjs';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {modulesSettings} from '../../../../settings';
import {filter, map, tap} from 'rxjs/operators';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect/typed-data-entity.interface';
import {GoBuyLicenceNewsComponent} from '@modules/account-management/core/onboarding/components/go-buy-licence-news/go-buy-licence-news.component';
import {ModelSchema, Structures} from 'octopus-model';
import {GoBuyLicenceNewsPassiveComponent} from '@modules/account-management/core/onboarding/components/go-buy-licence-news-passive/go-buy-licence-news-passive.component';

interface NewsSettingsInterface {
    displayNews: {
        [role: string]: string[]
    };
}

const settingsStructure: ModelSchema = new ModelSchema({
    displayNews: Structures.object({
        default: []
    }),
});

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {
    static goBuyLicenceNews: Partial<NewsInterface> = {
        id: 'goBuyLicence',
        weight: 30,
        component: GoBuyLicenceNewsComponent as Component,
    };

    static goBuyLicencePassiveNews: Partial<NewsInterface> = {
        id: 'goBuyLicencePassive',
        weight: 20,
        component: GoBuyLicenceNewsPassiveComponent as Component,
        channel: {
            snackbar: {
                acceptedUrlRegex: /no-url-accepted-dummy-regex/,
            }
        }
    };

    private subscriptionLearnerList: Subscription;
    private onLogout = new Subject<void>();
    private almostOneLearnerExist = new Subject<void>();
    private currentUser: UserDataEntity;
    private settings: NewsSettingsInterface;

    constructor(
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
    ) {

        this.settings = <NewsSettingsInterface>settingsStructure.filterModel(modulesSettings.accountManagement);

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((currentUser: UserDataEntity) => {
                this.currentUser = currentUser;
                if (!!currentUser) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    /**
     * Emit list news to the communication center according to the app state and the settings
     * If the user is a teacher have a learner and no assignment for more than seven day emit alert
     */
    private emitNews(): void {
        if (this.isThisNewsAllowed(OnboardingService.goBuyLicenceNews)) {
            this.emitGoBuyLicenceNews();
        }
        if (this.isThisNewsAllowed(OnboardingService.goBuyLicencePassiveNews)) {
            this.emitPassiveGoBuyLicenceNews();
        }
    }

    private userLicences$: Observable<TypedDataEntityInterface<{ type: { label: string } }>[]> = this.communicationCenter
        .getRoom('groups-management')
        .getSubject('userLicenses');

    // TODO mettre ça dans un service/communication center serait pas mal
    private isUserOwnNoPaidLicences$: Observable<boolean> = this.userLicences$.pipe(
        map((userLicenses) =>
            userLicenses.every(l => {
                const label = l.get('type')?.label;
                return label !== 'Class' && label !== 'Institution';
            })
        ));

    private isUserOwnClassLicences$: Observable<boolean> = this.userLicences$.pipe(
        map((userLicenses) =>
                userLicenses.length > 0 && userLicenses.some(licence =>
                    licence.get('type')?.label !== 'Class'
                )
        ));

    private userInstitutionLicences$: Observable<{ license: { type: string } }[]> = this.communicationCenter
        .getRoom('groups-management')
        .getSubject('institutionList');

    private isUsersInstitutionOwnNoPaidLicences$: Observable<boolean> =
        this.userInstitutionLicences$
            .pipe(
                map(institutionList => (institutionList || []).length == 0)
            );

    private isUserInstitutionOwnClassLicences$: Observable<boolean> = this.userInstitutionLicences$.pipe(
        map((institutionList) =>
            institutionList.length > 0 && institutionList.some(institution => institution.license?.type === 'Class')
        )
    );

    private isUserHasFreeLicence$: Observable<boolean> =
        combineLatest([
            this.isUserOwnNoPaidLicences$,
            this.isUsersInstitutionOwnNoPaidLicences$
        ]).pipe(
            map(([isUserOwnNoPaidLicences, isUserInstitutionOwnNoPaidLicences]) =>
                isUserOwnNoPaidLicences
                && isUserInstitutionOwnNoPaidLicences
            )
        );

    private isUserHasClassLicence$: Observable<boolean> =
        combineLatest([
            this.isUserOwnClassLicences$,
            this.isUserInstitutionOwnClassLicences$
        ]).pipe(
            map(([isUserOwnNoPaidLicences, isUserInstitutionOwnNoPaidLicences]) =>
                isUserOwnNoPaidLicences || isUserInstitutionOwnNoPaidLicences
            )
        );

    /**
     * Remove all news managed by this service.
     * @remarks If the news are not displayed it's not a problem.
     */
    private removeNews(news = []): void {
        if (news.length === 0) {
            news = [
                OnboardingService.goBuyLicenceNews,
            ];
        }
        this.communicationCenter.getRoom('news').next('delete', news);
    }

    /**
     * execute all needs to prepare the app for the current user
     * @private
     */
    private postAuthentication(): void {
        this.emitNews();
    }

    /**
     * execute all needs to clean the app on logout
     * @private
     */
    private postLogout(): void {
        this.almostOneLearnerExistCloseListener();
        this.onLogout.next();
        this.onLogout.complete();
        if (!!this.subscriptionLearnerList) {
            this.subscriptionLearnerList.unsubscribe();
        }
        this.removeNews();
    }

    /**
     * if a learner is return the emit news will be done if needed
     * no need to wait until other learner list change
     */
    private almostOneLearnerExistCloseListener(): void {
        this.almostOneLearnerExist.next();
        this.almostOneLearnerExist.complete();
    }

    private isThisNewsAllowed(news: Partial<NewsInterface>): boolean {
        return this.settings.displayNews.hasOwnProperty(this.authService.accessLevel) ?
            this.settings.displayNews[this.authService.accessLevel].includes(news.id) :
            this.settings.displayNews['default'].includes(news.id);
    }

    private emitGoBuyLicenceNews(): void {
        // Only if the user as a free licence or a class licence
        combineLatest([
            this.isUserHasFreeLicence$,
            this.isUserHasClassLicence$
        ])
            .pipe(
                filter(([hasFreeLicence, hasClassLicence]) => hasFreeLicence || hasClassLicence),
                tap(() => this.removeNews([OnboardingService.goBuyLicenceNews])),
            ).subscribe(() => {
            const today = new Date();
            const dayInMonthAsNumber = today.getDate();
            const notMoreThanTwentyHeightDay = dayInMonthAsNumber < 29 ? dayInMonthAsNumber : 28;

            const timestamp = this.currentUser.get('created');
            const userCreationDate = new Date(timestamp * 1000);
            const userCreationDay = userCreationDate.getDate();
            const userCreationDayNotMoreThanTwentyHeightDay = userCreationDay < 29 ? userCreationDay : 28;

            const userCreationDateClone = new Date(userCreationDate);
            const creationDateWithOneDayOffset = new Date(userCreationDateClone.setDate(userCreationDateClone.getDate() + 1));

            if (creationDateWithOneDayOffset < today && userCreationDayNotMoreThanTwentyHeightDay === notMoreThanTwentyHeightDay) {
                this.communicationCenter.getRoom('news').next('add', [OnboardingService.goBuyLicenceNews]);
            }
        });
    }

    private emitPassiveGoBuyLicenceNews(): void {
        // Only if the user as a free licence
        this.isUserHasFreeLicence$
            .pipe(
                filter((hasFreeLicence) => hasFreeLicence),
                tap(() => this.removeNews([OnboardingService.goBuyLicencePassiveNews])),
            ).subscribe(() => {
            this.communicationCenter.getRoom('news').next('add', [OnboardingService.goBuyLicencePassiveNews]);
        });
    }
}
