// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  padding: 2rem;
}

main {
  display: flex;
  padding-left: 5rem;
  padding-right: 5rem;
}
main mat-chip-list {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/corpus/core/components/resource-share-to-groups-modal/resource-share-to-groups-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;EACA,mBAAA;AACJ","sourcesContent":["header {\n  padding: 2rem;\n}\n\nmain {\n  display: flex;\n  padding-left: 5rem;\n  padding-right: 5rem;\n\n  mat-chip-list {\n    margin-top: 0.5rem;\n    margin-bottom: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
