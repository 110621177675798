import {Injectable} from '@angular/core';
import {CommunicationCenterService} from "@modules/communication-center";
import {Subject} from "rxjs";

/**
 * This service's scope is to :
 *   - receive messages on rooms 'contextualActions', 'contextualConditions' and 'contextualData'
 *   - expose Observables for any component of the skeleton or modules to subscribe to
 *   - have one Observable per action, condition and data handled in the application
 *   - have no other logic implemented. The components and services of the skeleton and the modules handle the logic
 * The observables exposed here should be generic and not have any reference to the specifics of any module
 */
@Injectable({
    providedIn: 'root'
})
export class GenericContextualService {
    // receives a value to set to the given field
    public actionFilter$ = new Subject<{field: string, value: string}>();
    // receives an anchor to scroll to in the page with additional options
    public actionNavigate$ = new Subject<{anchor: string, options: string[]}>();

    // provides a callback function to call with an array of data for the given field
    public dataField$ = new Subject<{field: string, callback: (value: string[]) => void}>();

    constructor(
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('contextualActions')
            .getSubject('filter')
            .subscribe(([field, value]) => {
                this.actionFilter$.next({field, value});
            });

        this.communicationCenter
            .getRoom('contextualActions')
            .getSubject('navigate')
            .subscribe(([anchor, ...options]) => {
                this.actionNavigate$.next({anchor, options});
            });

        this.communicationCenter
            .getRoom('contextualData')
            .getSubject('field')
            .subscribe(({data, callback}) => {
                this.dataField$.next({field: data, callback});
            });
    }
}
