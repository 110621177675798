import {AfterViewInit, Component, Inject} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';

/**
 * This component is design to be reusable for two step selections filter
 * It will accept single selection or multi selection
 * and show second list like a list of item or like a combobox
 * we can pass pure data if we already have it or
 * observable of data in good format if we need to get it and that will take time
 * it can return selected data or all data with flag selected on choosen element
 * example of use in another component with opening like a modal :
 this.dialogRef = this.dialog.open(TwoStepsSelectionComponent, {
            panelClass: 'entity-form-dialog',
            data: {
                data: [{id: 100, label: 'test', isSelected: false, data: [{id: 1, label: 'undervalue', isSelected: false}, {id: 2, label: 'undervalue100', isSelected: false}]},
                    {id: 200, label: 'test2', isSelected: false, data: [{id: 3, label: 'undervalue200', isSelected: false}, {id: 4, label: 'undervalue400', isSelected: false}]}],
                params: {
                    returnOnlySelectedValue: true,
                    isMultiChoiceAllowed: false
                }
            }
        });

 this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(result);
            }
        });
 }
 */

@Component({
    selector: 'app-two-steps-selection',
    templateUrl: './two-steps-selection.component.html',
    styleUrls: ['./two-steps-selection.component.scss']
})
export class TwoStepsSelectionComponent implements AfterViewInit {
    public displayedColumns = ['label'];
    public dataSource = new MatTableDataSource([]);
    public selectedValues: ITwoStepDataFormat[] = [];
    public selectedValue: number = null;
    public selectedValueSecondList: number;
    entityForm: UntypedFormGroup;
    public onlyData: ITwoStepData[] = [];

    get onlyDataNotHidden(): ITwoStepData[] {
        return this.onlyData.filter(data => data.hidden === false);
    }

    public dataUnderList: ITwoStepDataFormat[] = [];
    public isLoading: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ITwoStepsDataAndParams,
                public dialogRef: MatDialogRef<TwoStepsSelectionComponent>) {
        if (this.data.data) {
            // we pass directly data
            this.onlyData = this.data.data;
        } else {
            this.isLoading = true;
            // we pass observable of data
            this.data.data$.subscribe(d => {
                this.onlyData = d;
                this.isLoading = false;
            });
        }
    }

    ngAfterViewInit(): void {
        // check if the data was already be selected and select it again if it was
        const mainlistSelected = this.onlyData.find((d: any) => d.isSelected === true);
        if (mainlistSelected) {
            // load underlist
            this.selectElement({value: mainlistSelected.id});
            // select good value in under list
            this.selectedValue = mainlistSelected.id;
            const selectedUnderListelement = mainlistSelected.data.filter((d: any) => d.isSelected === true);
            if (!this.data.params.isMultiChoiceAllowed) {
                this.selectedValueSecondList = selectedUnderListelement[0].id;
                // push the selected element on the array we use to return value because we can make multichoice in regard of params
                this.selectedValues.push(this.onlyData.flatMap(d => d.data).find(data => this.selectedValueSecondList === data.id));
            } else {
                selectedUnderListelement.forEach(elem => {
                    this.selectedValues.push(this.onlyData.flatMap(d => d.data).find(data => elem.id === data.id));
                });
            }


        }
    }


    /***
     * load element of the list corresponding at the user choice in combobox
     * @param event : choose element in comboBox
     */
    public selectElement(event: any): void {
        if (event.value && event.value !== 'all') {
            this.loadList(event.value);
            this.dataUnderList = this.onlyData.find(d => d.id === event.value).data;

        } else {
            this.dataSource.data = this.onlyData.flatMap(d => d.data);
            this.dataUnderList = this.onlyData.flatMap(d => d.data);
        }
    }

    /**
     * push the id in the array we use array for managing simple choice or multiple choise in regards of params
     * @param idSelectedElement : id of element selected in the second list
     */
    selectElementSecondList(event: MatSelectChange): void {
        this.selectedValues = [];
        const values = this.onlyData.flatMap(d => d.data).find(data => event.value === data.id);
        this.selectedValues.push(values);
    }

    /**
     * load data from selected element of firts list
     * @param idElement : id of parent list element
     */
    private loadList(idElement: number): void {
        this.dataSource.data = this.onlyData.find(d => d.id === idElement).data;
    }

    /**
     * unselect all value use if user quit with close button
     */
    reset(): void {
        this.selectedValues = [];
    }

    /**
     * manage to select or unselect element of list when user click
     * @param event : current element where user click
     */
    selectedState(evt: ITwoStepDataFormat): void {
        const position = this.checkSelected(evt);
        if (position > -1) {
            this.selectedValues.splice(position, 1);
        } else {
            if (!this.data.params.isMultiChoiceAllowed) {
                // reset all the previous selected value if only one is allowed
                this.selectedValues = [];
            }
            this.selectedValues.push(evt);
        }
    }

    /**
     * check if the element is already selected or not
     * return the index of the element if already selected : it will be unselected
     * @param selectElement :  element to control if it is or not already selected
     */
    checkSelected(selectElement: Window | ITwoStepDataFormat): number {
        return this.selectedValues.findIndex(obj => selectElement['id'] === obj['id']);
    }


    /**
     * send data with state isSelected is true
     * or
     * send all the data with the flag is selected in regard of params
     */
    validate(): void {
        let idSelected: number[];
        idSelected = this.selectedValues.map(s => s.id);
        this.resetDataFlags();
        // for each element and underelement we pass is selected to true
        this.onlyData.forEach(data => {
            // almost one under element is selected the main list pass to selected status
            if (data.data.findIndex(d => idSelected.includes(d.id)) > -1) {
                data.isSelected = true;
                // pass the element of under list to true if selected
                data.data.filter(d => idSelected.includes(d.id)).every(da => da.isSelected = true);
            }
        });
        if (this.data.params.returnOnlySelectedValue === true) {
            this.dialogRef.close(this.selectedValues);
        } else {
            this.dialogRef.close(this.onlyData);
        }
    }

    /**
     * pass all flag isSelected to false
     */
    private resetDataFlags(): void {
        this.onlyData.forEach(data => {
            if (data.isSelected) {
                data.data.map(d => d.isSelected = false);
                data.isSelected = false;
            }
        });
    }
}

export interface ITwoStepsDataAndParams {
    data?: ITwoStepData[]; // data of main list
    data$?: Observable<ITwoStepData[]>; // observable of data if data of list need time to get it
    params: IParams; // params simple choice mutli choice etc...
}

export interface ITwoStepData extends ITwoStepDataFormat {
    data: ITwoStepDataFormat[]; // data of underlist same format as parent
}

export interface ITwoStepDataFormat {
    id: number;
    label: string;
    isSelected: boolean; // not use in all config flag to select the parent and the child selected element in the underlist
    hidden: boolean; // sometime the list we pass contain field we want to hide or to disabled pass this to true
}

export interface IParams {
    returnOnlySelectedValue: boolean; // return complete object with flag selected or juste selected element
    isMultiChoiceAllowed: boolean; // can we choose only one or most element
}
