import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {AutoUnsubscribeTakeUntilClass} from "../../../app/shared/models/auto-unsubscribe-take-until.class";
import {takeUntil} from "rxjs/operators";
import {GenericContextualService} from "fuse-core/services/generic-contextual.service";
import {
    animate,
    animation,
    AnimationBuilder, AnimationMetadata, AnimationPlayer,
    AnimationReferenceMetadata, AnimationStyleMetadata,
    style
} from "@angular/animations";

@Directive({
    selector: '[anchor]'
})
export class AnchorScrollHighlightDirective extends AutoUnsubscribeTakeUntilClass {
    @Input('anchor') identifier: string;

    private player: AnimationPlayer;

    constructor(
        private htmlElement: ElementRef,
        private animationBuilder: AnimationBuilder,
        private renderer: Renderer2,
        private contextualService: GenericContextualService,
    ) {
        super();

        this.contextualService.actionNavigate$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(({anchor, options}) => {
                if (this.identifier === anchor) {
                    this.scrollToAnchor();

                    if (options.includes('highlight')) {
                        this.highlightElement();
                    }
                }
            });
        
        this.player = this.animationBuilder
            .build(this.createHighlightAnimation('400ms', 4, 0, 1))
            .create((this.htmlElement.nativeElement))
    }
    
    private createHighlightAnimation(time: string, occurences: number, low: number, high: number): AnimationReferenceMetadata {
        let off = style({
            'box-shadow': '0px 4px 4px 0px rgba(255, 233, 0, {{ low }}), ' +
                '0px -4px 4px 0px rgba(255, 233, 0, {{ low }}), ' +
                '-4px 0px 4px 0px rgba(255, 233, 0, {{ low }}), ' +
                '4px 0px 4px 0px rgba(255, 233, 0, {{ low }}), ' +
                '0px 0px 25px 0px rgba(255, 233, 0, {{ low }}), ' +
                'inset 0px 4px 4px 0px rgba(255, 233, 0, {{ low }}), ' +
                'inset 0px -4px 4px 0px rgba(255, 233, 0, {{ low }}), ' +
                'inset -4px 0px 4px 0px rgba(255, 233, 0, {{ low }}), ' +
                'inset 4px 0px 4px 0px rgba(255, 233, 0, {{ low }}), ' +
                'inset 0px 0px 25px 0px rgba(255, 233, 0, {{ low }})'
        });
        let on = style({
            'box-shadow': '0px 4px 4px 0px rgba(255, 233, 0, {{ high }}), ' +
                '0px -4px 4px 0px rgba(255, 233, 0, {{ high }}), ' +
                '-4px 0px 4px 0px rgba(255, 233, 0, {{ high }}), ' +
                '4px 0px 4px 0px rgba(255, 233, 0, {{ high }}), ' +
                '0px 0px 25px 0px rgba(255, 233, 0, {{ high }}), ' +
                'inset 0px 4px 4px 0px rgba(255, 233, 0, {{ high }}), ' +
                'inset 0px -4px 4px 0px rgba(255, 233, 0, {{ high }}), ' +
                'inset -4px 0px 4px 0px rgba(255, 233, 0, {{ high }}), ' +
                'inset 4px 0px 4px 0px rgba(255, 233, 0, {{ high }}), ' +
                'inset 0px 0px 25px 0px rgba(255, 233, 0, {{ high }})'
        });
        
        let steps: AnimationMetadata[] = [off];
        for (let i = 0; i < occurences; i += 1) {
            steps.push(
                animate('{{ time }} ease-in-out', on),
                animate('{{ time }} ease-in-out', off)
            );
        }
        
        let options = {
            params:
                {
                    time: time,
                    low: low,
                    high: high
                }
        }
        
        return animation(steps, options);
    } 

    private scrollToAnchor(): void {
        this.htmlElement.nativeElement.scrollIntoView();
    }

    private highlightElement(): void {
        this.player.play();
    }
}
