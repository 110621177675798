import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GraphBayardRoutes} from '../../graph-bayard.routes';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector: 'app-graph-selector',
    templateUrl: './graph-selector.component.html',
})
export class GraphSelectorComponent implements OnInit {

    private labelByRole = {
        trainer: [
            'group_management.graph_errors_trainer',
            'group_management.graph_level_trainer',
            'group_management.graph_attendance_trainer',
            'group_management.graph_progress_trainer',
            'group_management.graph_statistics_trainer'
        ],
        default: [
            'group_management.graph_errors',
            'group_management.graph_level',
            'group_management.graph_attendance',
            'group_management.graph_progress',
            'group_management.graph_statistics'
        ]
    };


    public graphLinks: { url: string, label: string }[] = [
        // {url: '/graph-bayard/multi/' + GraphBayardRoutes.Errors, label: this.labelByRole[this.translationGraphLabelByRole()][0]},
        // {url: '/graph-bayard/multi/' + GraphBayardRoutes.Level, label: this.labelByRole[this.translationGraphLabelByRole()][1]},
        {url: '/graph-bayard/multi/' + GraphBayardRoutes.Attendance, label: this.labelByRole[this.translationGraphLabelByRole()][2]},
        {url: '/graph-bayard/multi/' + GraphBayardRoutes.Progress, label: this.labelByRole[this.translationGraphLabelByRole()][3]},
        // {url: '/graph-bayard/multi/' + GraphBayardRoutes.Statistics, label: this.labelByRole[this.translationGraphLabelByRole()][4]},
    ];

    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) {

    }

    ngOnInit(): void {
    }

    public isRouteActive(url: string): boolean {
        return this.router.isActive(url, true);
    }

    private translationGraphLabelByRole(): string {
        return this.authService.isAtLeastTrainer() ? 'trainer' : 'default';
    }
}
