import {DynamicGraphFilters} from '@modules/graph-ubolino/core/model/dynamic-graph-filters';
import * as moment from 'moment';

export const AttendanceFilters: DynamicGraphFilters = {
    always: [{
        label: 'startDate',
        value: moment().add(-2, 'weeks').startOf('day').toDate()
    }, {
        label: 'endDate',
        value: moment().endOf('day').toDate()
    }],
    hidden: [{
        label: 'group',
        value: null,
        custom: {
            rules: ['ignore', 'autofill:multiLearner']
        }
    }, {
        label: 'workgroup',
        value: null,
        custom: {
            rules: ['ignore']
        }
    }, {
        label: 'multiLearner',
        value: [],
    }]
};
