import {mergeMap, take} from 'rxjs/operators';
import {Component} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {combineLatest, Observable, of} from 'rxjs';
import {BaseActivityComponent} from '../base-activity.component';
import {LessonsService} from '../../lessons/services/lessons.service';
import {ActivitiesService} from '../../activities.service';
import {ActivatedRoute} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {LessonNavigationService} from '../../lesson-navigation.service';
import {ContextualService} from '@modules/activities/core/services/contextual.service';
import {ActivityGranule} from '@modules/activities/core/models';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
import {SummaryActivityGranule} from '@modules/activities/core/models/activities/typologies/summary-activity.granule';

@Component({
    selector: 'app-summary-activity',
    templateUrl: './summary-activity.component.html'
})
export class SummaryActivityComponent extends BaseActivityComponent<SummaryActivityGranule>{
    public activitiesReferenced: ActivityGranule['attributes'][] = [];
    public config: unknown = {};
    public isReady: boolean;
    // TODO a typer
    public userSaves: TypedDataEntityInterface<any>[] = [];

    private markers: { id: string }[] = [];
    private mode: string;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected communicationCenter: CommunicationCenterService,
        protected contextualService: ContextualService,
        protected lessonsService: LessonsService,
        private octopusConnect: OctopusConnectService,
        protected lessonNavigationService: LessonNavigationService
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, contextualService, lessonNavigationService);
    }

    /**
     * Initializes the current component
     * activity is used when we start the activity from the activities list in a sub lesson
     * for refresh the component exp: start summary then second activity is another summary
     * @param activity
     */
    protected initialize(): void {
        this.reset();
        if (this.activity) {
            this.setContentData(this.activity.attributes);
            if (!this.isActivityEmbedded) {
            this.loadUserSave();
        }
        } else {
            this.activitiesService.loadActivitiesFromId(this.activatedRoute?.snapshot?.params?.activityId)
                .pipe(take(1))
                .subscribe(entity => {
                    this.activity = entity;
                    this.setContentData(entity.attributes);
                    if (!this.isActivityEmbedded) {
            this.loadUserSave();
        }
                });
        }

    }


    protected loadUserSave(): void {
        this.isSaving = true;
        let obsUserSaveLoaded: Observable<DataEntity>[]; // User saves
        if (this.lessonsService.isLessonTest()) {
            // const steps = [];

            if (this.mode === 'poll') {

                const steps = this.stepSettings.map((val, key) => {
                    if (val.type === 'LESSON') {
                        return key;
                    }
                }).filter((step) => step === 0 || !!step);

                obsUserSaveLoaded = [].concat(...this.activitiesReferenced.map((activity) => {
                    if (this.activitiesService.currentAssignment) {
                        return this.activitiesService.getUserSave(activity.id.toString(), this.activitiesService.currentAssignment.id.toString(), true)
                            .pipe(take(1));
                    } else {
                        return steps.map((step) => this.activitiesService.getUserSave(activity.id.toString(), null, true, step)
                            .pipe(take(1)));
                    }
                }));
            }

            if (this.mode === 'marker') {
                const step = this.lessonsService.currentLesson
                    .get('reference')
                    .findIndex((activity) => +activity.id === +this.activitiesReferenced[0].id);

                obsUserSaveLoaded = [this.activitiesService.getUserSave(this.activitiesReferenced[0].id.toString(), this.assignmentId, true, step)];
            }

            if (this.mode === 'qcmu') {
                obsUserSaveLoaded = this.activitiesReferenced
                    .map((activity) => {
                        return this.activitiesService.getUserSave(activity.id.toString(), this.assignmentId, true, null);
                    });
            }

        } else {
            obsUserSaveLoaded = this.activitiesReferenced.map((activity) => {
                return this.activitiesService.getUserSave(activity.id.toString(), this.assignmentId, true);
            });
        }

        combineLatest(...obsUserSaveLoaded)
            //TODO probleme de typage ici
            .subscribe((userSavesEntities: any) => {// can be DataEntity, DataEntity[], DataEntity[][]

                this.isSaving = false;
                const steps = [];
                if (this.stepSettings && this.mode === 'poll') {
                    this.stepSettings.forEach((val, key) => {
                        if (val.type === 'LESSON') {
                            steps.push(key);
                        }
                    });
                    if (!this.activitiesService.currentAssignment) {
                        userSavesEntities = <any> this.activitiesReferenced.map((activity) =>
                            userSavesEntities.filter((userSave: DataEntity) => +userSave.get('granule') === +activity.id)
                        );
                    }
                }
                // if no assignment, userSavesEntities will be an DataEntity because we fetch user-save from local cache CF this.lessonsAnswers in activities.service
                if (userSavesEntities && this.mode === 'marker' && !this.activitiesService.currentAssignment) {
                    userSavesEntities = [userSavesEntities];
                }
                const isAllUserSaveValidated = userSavesEntities.every((entity) => {
                    if (this.mode === 'poll') {
                        return entity && entity.length === steps.length;
                    }
                    if (this.mode === 'marker') {
                        return !!entity;
                    }
                    // keep this 'ugly' condition for easy understanding.
                    if (this.mode === 'qcmu' && !this.stepSettings) {
                        return true;
                    }
                    return false;
                });

                if (isAllUserSaveValidated) {
                    this.userSaves = [].concat(...userSavesEntities);
                    this.config['userSaves'] = this.userSaves;
                    this.isReady = true;
                } else {
                    if (!this.isSaving && this.userSaves.length === 0) {
                        const userSavesExisted = userSavesEntities.filter((userSave) => userSave.length > 0);
                        const obsDefaultUserSaves = userSavesEntities
                            .map((userSave, index) => {
                                let answers;
                                if (this.mode === 'marker') {
                                    answers = this.markers.map((marker) => {
                                        return ' ';
                                    });
                                }
                                if (this.mode === 'poll') {
                                    answers = 0;
                                }

                                const id = this.activitiesReferenced[index].id;

                                if (this.mode === 'marker') {
                                    const step = this.lessonsService.currentLesson
                                        .get('reference')
                                        .findIndex((activity) => +activity.id === +this.activitiesReferenced[0].id);
                                    return [this.saveAnswerWithProp(answers, id, null, step)];
                                }

                                const stepsMissing = steps.filter((step) => {
                                    return !userSave.find((save) => save.get('step') && +save.get('step') === +step);
                                });
                                return stepsMissing.map((step) => {
                                    return this.saveAnswerWithProp(answers, id, null, step);
                                });
                            });

                        this.isSaving = false;
                        combineLatest([].concat(...obsDefaultUserSaves))
                            .subscribe((userSaves) => {
                                this.isSaving = false;
                                this.userSaves = [].concat(...userSavesExisted, ...userSaves);
                                this.config['userSaves'] = this.userSaves;
                                this.isReady = true;
                            });
                    }
                }
            });
    }

    /**
     * cette fonction crée des usersaves pour un ensemble d'acticvités pour lequelles on veut un sommaire.
     * c'est volontaire de ne pas utiliser la fonction save de BaseActivityComponent
     * @param answers
     * @param id id d'une activité precedante pour laquelle on veut le sommaire (pas l'activité en cours)
     * @param userSave sauvegarde d'une activité precedante pour laquelle on veut le sommaire (pas l'activité en cours)
     * @param step index de l'activité  pour laquelle on veut le sommaire (pas l'activité en cours)
     */
    public saveAnswerWithProp(answers, id, userSave, step = null): Observable<DataEntity> {
        this.isSaving = true;
        return this.activitiesService.saveUserSave(id.toString(), this.assignmentId, answers, null, 'qcm-save', userSave, step).pipe(take(1));
    }

    public saveUserNotes(selectedMarker, data): Observable<DataEntity> {
        const index = this.markers.findIndex((marker) => marker.id === selectedMarker.id);
        const answers = this.userSaves && this.userSaves[0] && this.userSaves[0].get('userActivity').entitySave.answers.length > 0 ?
            this.userSaves[0].get('userActivity').entitySave.answers : this.markers.map(() => '');

        let answersEntity;
        let obs;

        if (answers && answers[index] && !answers[index].id) {
            answers[index] = data['survey-notes'];
            obs = this.saveAnswerMarkers(answers).pipe(take(1));
        } else {
            answersEntity = answers.map((answer, key) => {
                const entity = new DataEntity(
                    'answer',
                    answer,
                    this.octopusConnect,
                    answer.id
                );
                if (+key === +index) {
                    entity.set('answer', data['survey-notes']);
                }
                return entity;
            });

            obs = this.saveAnswerMarkers(answersEntity, true).pipe(take(1));
        }

        return obs;
    }


    public saveAnswerMarkers(answers, isEntities = null): Observable<DataEntity> {
        const obsAnswer = answers.flatMap((answer) => {
            if (isEntities) {
                if (this.lessonsService.isLessonTest()) {
                    return this.activitiesService.saveAnswer({answer: answer.get('answer')}, 'answer');
                } else {
                    return answer.save();
                }
            } else {
                return this.activitiesService.saveAnswer({answer: answer}, 'answer');
            }
        });
        this.isSaving = true;
        return combineLatest(...obsAnswer).pipe(
            take(1),
            mergeMap((entities: DataEntity[]) => {
                const obs = this.activitiesService
                    .saveUserSave(this.activitiesReferenced[0].id.toString(),
                        this.activitiesService.currentAssignment ? this.activitiesService.currentAssignment.id.toString() : null,
                        entities ? entities.map((entity) => entity.id.toString()) : [],
                        null,
                        'qcm-save',
                        this.userSaves[0],
                        this.lessonsService.currentLesson.get('reference').findIndex((activity) => +activity.id === +this.activitiesReferenced[0].id));
                obs.subscribe((data: TypedDataEntityInterface<unknown>) => {
                    this.userSaves[0] = data;
                    this.isSaving = false;
                });
                return obs;
            }));
    }

    private get stepSettings() {
        if (this.activitiesService.settings && this.activitiesService.settings.lessonStep) {
            return this.activitiesService.settings.lessonStep.typeSteps;
        }

        return null;
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setContentData(attributes: SummaryActivityGranule['attributes']): void {
        // TODO comment peux il avoir un typology ici alors que c'est pas un champ d'une granule ?
        this.activityType = (<any>attributes).typology.label;
        this.referenceActivityGranule = attributes.reference;
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;

        this.activitiesReferenced = [].concat(this.activity.get('reference') && typeof this.activity.get('reference') === 'object' ?
            this.activity.get('reference').activity_content[0].granule : []);

        if (this.activity.get('reference').config && this.activity.get('reference').config.poll) {
            this.mode = 'poll';
        }
        if (this.activity.get('reference').config && this.activity.get('reference').config.markers) {
            this.markers = this.activitiesReferenced[0].reference.activity_content[0].marker;
            this.config['callback'] = (selectedMarker, val) => this.saveUserNotes(selectedMarker, val);
            this.mode = 'marker';
        }

        if (this.activity.get('reference').config && this.activity.get('reference').config.qcmu) {
            this.mode = 'qcmu';
        }
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected  getGrade(): {oldGrade: number, newGrade: number} {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}
