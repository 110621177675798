import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedModule} from 'shared/shared.module';
import {OctopusConnectModule} from 'octopus-connect';
import {MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/legacy-form-field";
import {UiModule} from '@modules/activity-edition/core/ui/ui.module';

const routes: Routes = [
    {
        path: 'activity-edition',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    },
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        OctopusConnectModule,
        RouterModule.forChild(routes),
        UiModule
    ],

    exports: [RouterModule],
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}}
    ]
})
export class ActivityEditionModule {
    static forRoot(): ModuleWithProviders<ActivityEditionModule> {
        return {
            ngModule: ActivityEditionModule,
            providers: []
        };
    }
}