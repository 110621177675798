import {Injectable} from '@angular/core';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {map, take, tap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect/typed-data-entity.interface';
import {BehaviorSubject, Observable} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {LeaderboardTableRow} from '@modules/achievement/core/components/leaderboard/leaderboard.component';
import {TypedDataCollectionInterface} from 'shared/models/octopus-connect/typed-data-collection.interface';

const OPEN_BADGES_ENDPOINT = 'openbadges';

export interface Achievement {
    id: string;
    name: string;
    criteria: string;
    image: string;
    unLocked: boolean;
}

export interface OpenBadge {
    badge: string | null;
    criteria: string;
    description: string;
    id: string;
    image: URL;
    name: string;
    unLocked: boolean;
    awardDetails: {issueDate: string, username: string};
}

export interface UserPoints {
    uid: string;
    points: number;
    level: number;
    progress: number;
}

export interface UserProgress {
    lessons: any[];
    activitiesDone: number;
}


@Injectable({
    providedIn: 'root'
})
export class UserScoreService {

    public userPoints: TypedDataEntityInterface<UserPoints>[] = [];
    public userProgress: TypedDataEntityInterface<UserProgress>[] = [];
    public loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public userAchievements: TypedDataEntityInterface<Achievement>[] = [];
    public userOpenBadges: TypedDataEntityInterface<OpenBadge>[] = [];

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (!!data) {
                    this.loadPointsAndProgressFromUser();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('gamification')
            .getSubject('showRewards')
            .pipe(
                tap(() => this.loadPointsAndProgressFromUser())
            )
            .subscribe();

        this.communicationCenter
            .getRoom('achievement')
            .getSubject('refreshUserScore')
            .pipe(
                tap(() => this.loadPointsAndProgressFromUser())
            )
            .subscribe();
    }

    /**
     * return data of leaderboard endpoint it's the list of better score made
     */
    public leaderboardData(): Observable<LeaderboardTableRow[]> {
        return this.octopusConnect.loadCollection('leaderboard').pipe(
            take(1),
            map((data: DataCollection) => (data.entities
                        .map(res => (
                            {studentName: res.get('label'), rank: +res.get('rank'), points: +res.get('score')}))
                )
            ));
    }

    private postLogout(): void {
        this.initializeUserPoints();
        this.initializeUserProgress();
    }

    private loadUserPoints(): Observable<TypedDataEntityInterface<UserPoints>[]> {
        return this.octopusConnect
            .loadCollection('user-points')
            .pipe(
                map(
                    (userPointsCollection) =>
                        userPointsCollection.entities as TypedDataEntityInterface<UserPoints>[]
                ),
                tap((entities: TypedDataEntityInterface<UserPoints>[]) => {
                    if (entities.length) {
                        this.userPoints = entities;
                    }
                })
            );
    }

    private loadUserProgress(): Observable<TypedDataEntityInterface<UserProgress>[]> {
        return this.octopusConnect
            .loadCollection('user-progress')
            .pipe(
                map(
                    (userProgressCollection) =>
                        userProgressCollection.entities as TypedDataEntityInterface<UserProgress>[]
                ),
                tap((entities: TypedDataEntityInterface<UserProgress>[]) => {
                    if (entities.length) {
                        this.userProgress = entities;
                        this.communicationCenter.getRoom('achievement').next( 'user-progress', entities );
                    }
                })
            );
    }

    private loadUserAchievements(): Observable<TypedDataEntityInterface<Achievement>[]> {
        return this.octopusConnect
            .loadCollection('achievements')
            .pipe(
                map(
                    (userAchievementCollection) =>
                        userAchievementCollection.entities as TypedDataEntityInterface<Achievement>[]
                ),
                tap((entities: TypedDataEntityInterface<Achievement>[]) => {
                    if (entities.length) {
                        this.userAchievements = entities;
                    }
                })
            );
    }

    private loadUserOpenBadges(): Observable<TypedDataEntityInterface<OpenBadge>[]> {
        return this.octopusConnect
            .loadCollection(OPEN_BADGES_ENDPOINT)
            .pipe(
                map((userOpenBadgesCollection: TypedDataCollectionInterface<OpenBadge>) => userOpenBadgesCollection.entities),
                tap(entities => {
                    if (entities.length) {
                        this.userOpenBadges = entities;
                    }
                })
            );
    }

    private initializeUserPoints(): void {
        this.userPoints = [];
    }

    private initializeUserProgress(): void {
        this.userProgress = [];
    }

    private loadPointsAndProgressFromUser(): void {
        combineLatest([this.loadUserPoints(), this.loadUserProgress(), this.loadUserAchievements(), this.loadUserOpenBadges()])
            .pipe(
                take(1),
            )
            .subscribe(() => {
                this.loadingSubject.next(false);
            });
    }
}
