import {Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import {defineFullCalendarElement, EventApi} from '@fullcalendar/web-component';


@Component({
  selector: 'app-event-controls',
  templateUrl: './event-controls.component.html',
  styleUrls: ['./event-controls.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EventControlsComponent {

  @Input()
  event: EventApi;

  edit: (any) => any;

  delete: (any) => any;

  constructor() {}

  onEdit(ev: MouseEvent): void {
    ev.stopPropagation();
    this.edit(this.event);
  }

  onDelete(ev: MouseEvent): void {
    ev.stopPropagation();
    this.delete(this.event);
  }

}
