import {Injectable} from '@angular/core';
import {CachedUser} from "@modules/authentication/core/models/cached-user.type";
import {UserDataEntity} from "@modules/authentication";
import {
    AuthenticationConfigurationService
} from "@modules/authentication/core/services/authentication-configuration.service";
import {CommunicationCenterService} from "@modules/communication-center";

const LOCAL_STORAGE_IDENTIFIER = 'cached-users';

@Injectable({
    providedIn: 'root'
})
export class LocalCacheService {
    private cachedUsers: CachedUser[] = [];

    constructor(
        private configuration: AuthenticationConfigurationService,
        private communicationCenter: CommunicationCenterService,
    ) {
        if (this.configuration.isLocalCacheEnabled) {
            this.cachedUsers = this.getLocalStorageCache();
        }
        
        this.communicationCenter
            .getRoom('account-management')
            .getSubject('userUpdate')
            .subscribe((user: UserDataEntity) => {
                if (user) {
                    this.updateCachedUser(user);
                }
            });
    }
    
    public get hasCachedUsers(): boolean {
        return this.cachedUsers.length > 0;
    }
    
    public addCachedUser(user: UserDataEntity): void {
        if (!this.cachedUsers.find(cachedUser => cachedUser.label === user.get('label'))) {
            this.cachedUsers.push({
                label: user.get('label').toString(),
                nickname: user.get('nickname').toString(),
                codeid: user.get('codeid')?.toString() || '',
                role: user.get('role'),
                picture: user.get('picture')?.toString() || '',
            });
            
            this.setLocalStorageCache(this.cachedUsers);
        } else {
            this.updateCachedUser(user);
        }
    }
    
    public updateCachedUser(user: UserDataEntity): void {
        const cachedUser = this.cachedUsers.find(cachedUser => cachedUser.label === user.get('label'));
        
        if (cachedUser) {
            cachedUser.codeid = user.get('codeid')?.toString() || cachedUser.codeid;
            cachedUser.picture = user.get('picture')?.toString() || cachedUser.picture;
            
            this.setLocalStorageCache(this.cachedUsers);
        }
    }
    
    public deleteCachedUser(user: CachedUser): void {
        const index = this.cachedUsers.indexOf(user);
        
        if (index > -1) {
            this.cachedUsers.splice(index, 1);
            this.setLocalStorageCache(this.cachedUsers);
        }
    }
    
    public getCachedUsers(): CachedUser[] {
        return this.cachedUsers;
    }
    
    private getLocalStorageCache(): CachedUser[] {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_IDENTIFIER) || '[]') as CachedUser[];
    }
    
    private setLocalStorageCache(cache: CachedUser[]): void {
        localStorage.setItem(LOCAL_STORAGE_IDENTIFIER, JSON.stringify(cache));
    }
}
