import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html'
})
export class TipsComponent {
   @Input() public label: string;

  constructor() { }


}
