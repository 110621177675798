import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {take, map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';

@Component({
    selector: 'app-unlink-parent',
    templateUrl: './unlink-parent.component.html',
})
export class UnlinkParentComponent implements OnInit {
    public trainerLabel: string;
    isLoading = false;

    constructor(private translate: TranslateService, private profileSvc: ProfileService) {
    }

    getCustomTranslationWithTrainerLabel(key: string): Observable<string> {
        return this.translate.get(key).pipe(
            map(translation => translation.replace('{{trainerLabel}}', this.trainerLabel))
        );
    }

    ngOnInit(): void {
        this.trainerLabel = this.profileSvc.getLinkedParentLabelOrNull();
    }

    onUnlinkClick($event: MouseEvent): void {
        this.isLoading = true;
        this.profileSvc.unlinkParent().pipe(take(1)).subscribe(() => {
            this.trainerLabel = this.profileSvc.getLinkedParentLabelOrNull();
            this.isLoading = false;
        });
    }
}
