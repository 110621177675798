export const accountManagement = {
    allowedMaxSize: 1,
    canEditAvatar: ['learner', 'trainer', 'manager', 'administrator'],
    canSelfDelete: ['trainer'],
    displayDialogMinorAdult: false,
    displayFilters: false,
    editableFields: {
        default: ['label|required', 'email|required', 'password|required', 'ido', 'UAI', 'licence'], // label = pseudo
        learner: ['label|required', 'password|required', 'ido', 'UAI', 'licence'], // label = pseudo
        trainer: ['label|required', 'email|required', 'password|required', 'ido', 'UAI', 'checkNotifMail', 'licence'] // label = pseudo
    },
    emailIncludeText: '@ac-', // exist only for mathia teacher may have a info of mail doesn't contain this text
    feedbackMaxFileSize: '10', // max size of file to join in feedback,
    feedbackMaxFileToSend: '3', // number max of file to join
    feedbackUseInputFieldForBug: true, // use input field instead of generating text format in msg zone,
    fields: {
        default: ['label|required', 'email|required', 'password|required'] // label = pseudo
    },
    hideEditSSO: true, // disable edition for users from SSO
    idSubjectToOpenBugInputs: '3984', // if feedbackUseInputFieldForBug true one item : bug open under input it s the id of the item
    passwordPolicy: {
        regex: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    },
    selfSignup: false,
    signup: false,
    validateEmailStrategyActivated: false,
};
