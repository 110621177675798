import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FaqListComponent} from './faq-list/faq-list.component';
import {RouterModule, Routes} from '@angular/router';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {IsUserAuthorisedByRoleGuard} from '../../../guards/is-user-authorised-by-role.guard';
import {FaqService} from '@modules/faq/core/faq.service';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {SharedMaterialModule} from 'shared/shared-material.module';
import {SharedTranslateModule} from 'shared/shared-translate.module';
import {SearchFiltersModule} from 'fuse-core/components';
import {LinkHandlerDirective} from '@modules/faq/core/link-handler.directive';
import {FuseSharedModule} from "fuse-core/shared.module";

const routes: Routes = [
    {
        path: 'faq',
        canActivate: [IsUserLogged, IsUserAuthorisedByRoleGuard],
        component: FaqListComponent,
    },
];

@NgModule({
    declarations: [
        FaqListComponent,
        LinkHandlerDirective
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedMaterialModule,
        SharedTranslateModule,
        SearchFiltersModule,
        FuseSharedModule
    ]
})
export class FaqModule {
    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }
    static forRoot(): ModuleWithProviders<FaqModule> {
        return {
            ngModule: FaqModule,
            providers: [
                FaqService,
            ]
        };
    }

    private postLogout(): void {
        this.dynamicNavigation.clearMenuItem('level0', 'achievement');
    }

    private postAuthentication(): void {
    }
}
