import {Injectable} from '@angular/core';
import {CommunicationCenterService} from "@modules/communication-center";
import {Subject} from "rxjs";

/**
 * This service's scope is to :
 *   - receive messages on rooms 'contextualActions', 'contextualConditions' and 'contextualData'
 *   - expose Observables for the components of the module to subscribe to
 *   - have one Observable per action, condition and data handled in the module
 *   - have no other logic implemented. The components and services of the module handle the logic
 */
@Injectable({
  providedIn: 'root'
})
export class ContextualService {
  public conditionAchievementNewUser$ = new Subject<(value: boolean) => void>();

  constructor(
      private communicationCenter: CommunicationCenterService,
  ) {
    this.communicationCenter
        .getRoom('contextualConditions')
        .getSubject('achievement')
        .subscribe(({condition, callback}) => {
          switch (condition) {
            case 'newUser':
              this.conditionAchievementNewUser$.next(callback);
              break;
          }
        });
  }

  /**
   * Notifies the contextual system that it needs to reevaluate conditions
   * following a data update.
   */
  public onConditionUpdate(): void {
    this.communicationCenter
        .getRoom('contextualUpdate')
        .next('condition', true);
  }
}
