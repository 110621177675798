import {Component, OnInit, inject, OnDestroy} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {takeUntil} from 'rxjs/operators';
import {UserDataEntity} from '@modules/authentication';
import {LessonsListComponent} from '@modules/activities/core/lessons/lessons-list/lessons-list.component';

@Component({
    selector: 'app-user-lessons',
    templateUrl: './user-lessons.component.html',
    styleUrls: ['./user-lessons.component.scss']
})
export class UserLessonsComponent extends LessonsListComponent implements OnInit {
    private user: UserDataEntity;
    private communicationCenter = inject(CommunicationCenterService);
    public forceFilters = { author: '' };

    public ngOnInit(): void {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((data: UserDataEntity | null) => {
                this.user = data;

                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    private postAuthentication(): void {
        this.forceFilters = {author: this.user.id.toString()};
    }

    private postLogout(): void {
        this.forceFilters.author = '';
    }
}

