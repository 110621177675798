import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Keyboard from 'latex-keyboard';
import {Observable, of, Subject} from 'rxjs';
import {BaseActivityComponent} from '../../base-activity.component';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../../../activities.service';
import {LessonsService} from '../../../lessons/services/lessons.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {v4 as uuidv4} from 'uuid';
import {ReplaySubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {LessonNavigationService} from '../../../lesson-navigation.service';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import {FormControl} from '@angular/forms';
import {tap} from 'rxjs/operators';
import {ContextualService} from "@modules/activities/core/services/contextual.service";
import {FillInBlanksActivityGranule} from '@modules/activities/core/models/activities/typologies/fill-in-blanks-activity.granule';

@Component({
    selector: 'app-fill-in-blanks-child',
    templateUrl: './fill-in-blanks-child.component.html'
})

export class FillInBlanksChildComponent extends BaseActivityComponent<FillInBlanksActivityGranule> implements OnInit, AfterViewInit, AfterViewChecked {
    @Input() referenceActivityGranule: FillInBlanksActivityGranule['attributes']['reference'];
    @Input('childQuestion') question: string;
    @Input('disableOnChange') disableOnChange: ReplaySubject<boolean>;
    @Input('ttsChange') ttsChange: ReplaySubject<{ id: string, value: boolean }>;
    @Input('isLatexKeyboard') isLatexKeyboard: boolean;
    @Input('displayForSummary') displayForSummary: boolean;
    @Input('isSuggestedButtons') isSuggestedButtons: boolean;
    // Emet sa propre instance lorsqu'il est chargé
    @Input() readiness: Subject<FillInBlanksChildComponent>;
    @Output('onOptionChange') public optionChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output('clickAnswer') public clickAnswer: EventEmitter<boolean> = new EventEmitter<boolean>();

    public options: any;
    public questionSplit;
    public questionHolder = '#input';
    public answers = [];
    public forceText: string = null;
    public latexKeyboard: Keyboard;
    private fieldCount = 0;
    private callbackToTriggerOnAfterViewChecked: Function;
    public uuid: string;

    public inputControl = new FormControl<string>('');

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        private elementRef: ElementRef,
        private ref: ChangeDetectorRef,
        protected communicationCenter: CommunicationCenterService,
        protected contextualService: ContextualService,
        private translate: TranslateService,
        protected lessonNavigationService: LessonNavigationService
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, contextualService, lessonNavigationService);
    }


    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface OnDestroy est déjà implémenté par BaseActivity
    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.latexKeyboard) {
            this.latexKeyboard.destroy();
        }
    }

    ngAfterViewInit(): void {
        const activitiesService = this.activitiesService;
        const inputElement = this.elementRef.nativeElement.querySelectorAll('.renderedInputContent');
        inputElement.forEach((element, index) => {
            if (this.answersSelected.length) {
                element.innerText = this.answersSelected[index].answer;
            }
            element.addEventListener('keydown', function (event: KeyboardEvent): void {
                if (event.key.toLowerCase() === 'enter') {
                    event.preventDefault();
                }
            });
            element.addEventListener('keyup', function (event): void {
                if (this.innerText.length > 0) {
                    activitiesService.doesUserResponsed.next(true);
                } else {
                    activitiesService.doesUserResponsed.next(false);
                }
            });
            element.addEventListener('blur', function (event): void {
                if (this.innerText.length > 0) {
                    activitiesService.doesUserResponsed.next(true);
                } else {
                    activitiesService.doesUserResponsed.next(false);
                }
            });

            this.disableOnChange.subscribe((mode) => {
                this.disableField(mode);
            });
        });

        if (this.isLatexKeyboard) {
            const field = [];

            for (let i = 0; i < this.fieldCount; i += 1) {
                field.push({
                    placeholder: '\\blue{[?]}',
                    welcomeText: '',
                    katexOptions: {                           /* See https://katex.org/docs/options.html */
                        throwOnError: false,
                        strict: false,
                        allowAllSymbols: true
                    },
                    renderedLatexDivId: `latex-rendering-${this.uuid}` + i           /* Id of div where rendering occurs         */
                });
            }

            const keyboardType: string = this.referenceActivityGranule.config['keyboardType'] || 'alphabetic';
            const symbols = this.referenceActivityGranule && this.referenceActivityGranule.config
                && this.activitiesService.settings.symbolsForLatexKeyboard
                && this.activitiesService.settings.symbolsForLatexKeyboard[keyboardType]
                && this.activitiesService.settings.symbolsForLatexKeyboard[keyboardType].data;

            const optionsFromSettings = this.activitiesService.settings.symbolsForLatexKeyboard
                && this.activitiesService.settings.symbolsForLatexKeyboard[keyboardType]
                && this.activitiesService.settings.symbolsForLatexKeyboard[keyboardType].options || {};

            if (optionsFromSettings.validateButton) {
                this.translate.get('generic.validate').subscribe((translation: string) => optionsFromSettings.validateButtonText = translation);
            }

            const keyboardOptions = {
                inputFields: field,
                blacklistTabs: this.activitiesService.isPrimary(this.referenceActivityGranule) ? ['123 secondaire'] : ['123 primaire'],
                keyboardDivId: `latex-keyboard-${this.uuid}`,      /* Id of div where keyboard tabs are        */
                tabDivContainerGridClass: 'keyboard-grid-container',  /* Class name of each div grid tab          */
                tabDivContainerItemClass: 'keyboard-grid-item',       /* Class name of each div item tab          */
                tabDivMenuEntryClass: 'keyboard-tab-title',       /* Class name of tab titles (123, abc, ..)  */
                tabSwitchClassListener: 'toggleTab',                /* Class name where tab switch has to occur */
                hideKeyboardButtonClass: 'hideKeyboardButton',      /* Class name of close '✖' button containers */
                moveLeftButtonClass: 'moveLeft',                 /* Class name of Move Left button(s) */
                moveRightButtonClass: 'moveRight',                /* Class name of Move Right button(s) */
                backspaceButtonClass: 'backspace',                 /* Class name of Move Backspace button(s) */
            };

            this.latexKeyboard = new Keyboard(symbols, {...keyboardOptions, ...optionsFromSettings});

            if (this.answersSelected.length) {
                this.latexKeyboard.pushAllLatex(this.answersSelected.map((item: AnswerInterface) => item.answer));
            }

            this.latexKeyboard.addEventListener('change', (fields: string[]) => {
                const data = fields.join('').trim();

                if (data === '') {
                    activitiesService.doesUserResponsed.next(false);
                    this.optionChange.emit(false);
                } else {
                    activitiesService.doesUserResponsed.next(true);
                    this.optionChange.emit(true);
                }
            });

            this.latexKeyboard.addEventListener('validate', (validated: boolean) => {
                if (validated) {
                    this.clickAnswer.emit(null); // no need for the value emitted
                }
            });
        }
        this.readiness.next(this);
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnInit(): void {
        this.ttsChange.subscribe((data: {id: string, value: boolean }) => this.speakStateChanged(data));
        this.uuid = uuidv4();
        this.question = this.formatString(this.question);
        this.forceText = this.referenceActivityGranule.activity_content.answer_text.replace('[]', '');
        this.addInputField();
        if (!this.isLatexKeyboard && !this.isSuggestedButtons) {
            this.inputControl.valueChanges.pipe(tap(() => this.optionChange.emit(true))).subscribe();
        }
    }

    disableField(mode): void {
        const el = this.elementRef.nativeElement.querySelectorAll('.latex');

        for (const item of el) {

            if (item && mode) {
                item.classList.add('disabled');
            }

            if (item && !mode) {
                item.classList.remove('disabled');
            }
        }
    }

    addInputField(): void {
        this.questionSplit = this.question.split('##-');
        this.fieldCount = 0;
        let questionTextTemp = '';
        this.questionSplit.forEach((item, index) => {
            if (item === this.questionHolder) {
                if (this.isLatexKeyboard) {
                    questionTextTemp += '<span class="latex-wrapper">' +
                        '<span id="latex-rendering-' + this.uuid + this.fieldCount + '" class="renderedInputContent editable-default latex"></span>' +
                        '<span class="msg-icon hide"></span>' +
                        '</span>';
                } else {
                    questionTextTemp += '<span class="renderedInputContent editable-default" placeholder="reponse ici" name="answers[' + this.fieldCount + ']" contenteditable="' + (!this.lessonsService.isAtLeastTrainer() || this.lessonsService.isLessonTest()) + '"> </span>';
                    questionTextTemp += '<span class="msg-icon hide"><mat-icon class="mat-icon material-icons check" >check</mat-icon><mat-icon class="mat-icon material-icons close" >close</mat-icon><mat-icon class="mat-icon material-icons info" >info_outline</mat-icon><span class="ans-txt"></span></span>';
                }

                this.fieldCount += 1;
            } else {
                questionTextTemp += item;
            }

        });
        this.question = questionTextTemp;
    }

    formatString(answer_text: string): string {
        return answer_text;
    }

    public ngAfterViewChecked(): void {
        if (this.callbackToTriggerOnAfterViewChecked) {
            this.callbackToTriggerOnAfterViewChecked();
            this.callbackToTriggerOnAfterViewChecked = null;
        }
    }

    /**
     * change state of TTS
     * TODO better to move in base-activity, need to add ref (ChangeDetectorRef) in contructor
     * @param data
     */
    public speakStateChanged(data: {id: string, value: boolean }): void {
        this.isTTSSpeaking = data;
        this.ref.detectChanges();
        if (!this.wordingAlreadyReadWithTts && this.isTTSSpeaking && !this.isTTSSpeaking.value && this.uuid !== this.isTTSSpeaking.id) {
            this.clickToTts();
        }
        if (this.isTTSSpeaking && !this.isTTSSpeaking.value && this.uuid !== this.isTTSSpeaking.id) {
            this.wordingAlreadyReadWithTts = true;
        }
    }

    public get isTTSReadingText(): boolean {
        return this.isTTSSpeaking && this.isTTSSpeaking.id === this.uuid && this.isTTSSpeaking.value;
    }

    protected initialize(): void {
        throw new Error('Method not implemented.');
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setContentData(): void {
        this.wordingAlreadyReadWithTts = false;
        this.isTTSSpeaking = null;
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected getGrade(): { oldGrade: number, newGrade: number } {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}