import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {GraphConfig} from 'fuse-core/components/graph/graph-mixed/graph.config';
import {GraphMathiaService} from '../../services/graph-mathia.service';
import {delay, filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ProgressData, ProgressDotDetail} from '@modules/graph-mathia/core/model/progress-data';
import {CustomTooltipModalContentSettings} from 'fuse-core/components/graph/graph-details-modal/custom-tooltip.setting';
import * as moment from 'moment';
import {rgbOpacity} from 'shared/utils';
import * as _ from 'lodash-es';
import {GraphMathiaTranslateService} from '@modules/graph-mathia/core/services/graph-mathia-translate.service';
import {FakeStepperOptions} from '@modules/graph-mathia/core/component/fake-stepper/fake-stepper.component';

const simpleColor = 'rgb(156,255,78)';
const firstLayerColor = 'rgb(66,212,53)';
const secondLayerColor = 'rgb(156,255,78)';
const thirdLayerColor = 'rgb(0,144,205)';
const fourthLayerColor = 'rgb(0,209,255)';

@Component({
    selector: 'app-progress-graph',
    templateUrl: './progress-graph.component.html',
})
export class ProgressGraphComponent implements OnInit, OnDestroy {

    public graphConfigObs: ReplaySubject<GraphConfig> = new ReplaySubject<GraphConfig>(1);
    public isReady = false;
    public challenge: {
        averageScore: number;
        count: number;
        lastScore: number;
        lastDate: string;
    } = null;
    /**
     * Cache le loader si le service n'est pas encore pret
     */
    public showLoader = false;
    private unsubscribeInTakeUntil = new Subject<void>();
    /**
     * Aide a déterminer le temps entre les steps du loader
     * @private
     */
    private loaderShouldEnd = false;
    /**
     * Défini les steps du loader, le temps entre les stepsn, etc.
     */
    public loaderOptions: Partial<FakeStepperOptions> = {
        loop: false,
        interval: stateIndex => (this.loaderShouldEnd === false || stateIndex <= 2 ? 4000 : 1000),
        states: ['fake_stepper.state_1', 'fake_stepper.state_2', 'fake_stepper.state_3'],
        onEnd: new ReplaySubject(1)
    };

    constructor(private graphMathiaService: GraphMathiaService, public graphMathiaTranslateService: GraphMathiaTranslateService) {
    }

    ngOnInit(): void {
        this.graphMathiaService.isReady
            .pipe(
                filter(isReady => !!isReady),
                tap(() => this.showLoader = true),
                tap(() => this.graphMathiaService.graphDataArePending.subscribe(() => this.isReady = false)),
                mergeMap(() => this.graphMathiaService.getProgressGraphData()),
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data) => {
            this.loaderShouldEnd = true;
            if (data.graphFilters.view === 'simple') {
                this.generateSimpleGraph(data);
            } else {
                this.generateComplexGraph(data);
            }
            this.setChallengeStats(data);
            // Ne retirez pas de delay, sinon le timing foire et ça ne marche pas (je pense que le graphDataArePending est trigger trop tot)
            this.loaderOptions.onEnd.pipe(take(1), delay(750)).subscribe(() => this.isReady = true);
        });

    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public twoDigitsAfterDecimalMax(averageScore: number): string {
        const twoDigits = averageScore.toFixed(2);
        const [head, tail] = twoDigits.split('.');
        if (tail === '00') {
            return head;
        } else {
            return twoDigits.replace('.', ',');
        }
    }

    private generateSimpleGraph(progressData: ProgressData): void {
        const chartLabels: string[] = [];
        const dots: number[] = [];
        const dotsDetails: CustomTooltipModalContentSettings[] = [];

        progressData.notChallenges
            .filter(dot => dot.firstAnswer + dot.secondAnswer + dot.failedFirstAttempt + dot.failedRetry !== 0)
            .forEach((dot, index) => {
                let labelWithDot = dot.exerciseName;
                if (dot.exerciseName.length > 35) {
                    labelWithDot = dot.exerciseName.substr(0, 35) + '...';
                }
                chartLabels.push(labelWithDot);
                dots.push(dot.firstAnswer + dot.secondAnswer); // TotalGood marcherait aussi
                const translatedHeader = this.graphMathiaTranslateService.get('graph_mathia.progress_tooltip_header')
                    .replace('{{date}}', moment(+dot.date, 'X').format('DD/MM/YYYY'));
                dotsDetails.push({
                    header: `<label>${dot.exerciseName}\n${translatedHeader}</label>`,
                    content: this.generateModalContentHtml(dot, index)
                });
            });

        this.graphConfigObs.next({
            chartLabels,
            chartConfig: {
                scales: {
                    y: {
                        beginAtZero: true, max: 100, min: 0,
                        offset: false
                    },
                    x: {
                        offset: false
                    }
                },
                plugins: {
                    legend: {
                        align: 'start',
                        position: 'bottom',
                        onClick: event => event.native.stopPropagation()
                    }
                }
            },
            chartData: [{
                data: dots,
                type: 'line',
                fill: true,
                label: this.graphMathiaTranslateService.get('generic.pourcent_success'),
                backgroundColor: rgbOpacity(simpleColor, '0.3'),
                borderColor: simpleColor,
            }],
            modalContent: [dotsDetails],
        });
    }

    private generateComplexGraph(progressData: ProgressData): void {
        const chartLabels: string[] = [];
        const dots: number[][] = [[], [], [], []];
        const dotsDetails: CustomTooltipModalContentSettings[][] = [[], [], [], []];

        progressData.notChallenges
            .filter(dot => dot.firstAnswer + dot.secondAnswer + dot.failedFirstAttempt + dot.failedRetry !== 0)
            .forEach((dot, index) => {
                let labelWithDot = dot.exerciseName;
                if (dot.exerciseName.length > 35) {
                    labelWithDot = dot.exerciseName.substr(0, 35) + '...';
                }
                chartLabels.push(labelWithDot);
                dots[0].push(dot.firstAnswer);
                dots[1].push(dot.firstAnswer + dot.secondAnswer);
                dots[2].push(dot.firstAnswer + dot.secondAnswer + dot.failedFirstAttempt);
                dots[3].push(100);
                for (let i = 0; i < 4; i++) {
                    const translatedHeader = this.graphMathiaTranslateService.get('graph_mathia.progress_tooltip_header')
                        .replace('{{date}}', moment(+dot.date, 'X').format('DD/MM/YYYY'));
                    dotsDetails[i].push({
                        header: `<label>${dot.exerciseName}\n${translatedHeader}</label>`,
                        content: this.generateModalContentHtml(dot, index)
                    });
                }
            });

        this.graphConfigObs.next({
            chartLabels,
            chartConfig: {
                scales: {
                    y:
                        {
                            beginAtZero: true,
                            max: 100,
                            min: 0,
                            offset: false
                        },
                    x:
                        {offset: false},

                },
                plugins: {
                    legend: {
                        align: 'start',
                        position: 'bottom',
                        onClick: event => event.native.stopPropagation()
                    }
                }
            },
            chartData: [
                {
                    data: dots[0],
                    type: 'line',
                    fill: 'origin',
                    label: this.graphMathiaTranslateService.get('graph_mathia.progress_good_first_percent'),
                    backgroundColor: rgbOpacity(firstLayerColor, '0.3'),
                    borderColor: firstLayerColor
                },
                {
                    data: dots[1],
                    type: 'line',
                    fill: '0',
                    label: this.graphMathiaTranslateService.get('graph_mathia.progress_good_second_percent'),
                    backgroundColor: rgbOpacity(secondLayerColor, '0.3'),
                    borderColor: secondLayerColor
                },
                {
                    data: dots[2],
                    type: 'line',
                    fill: '1',
                    label: this.graphMathiaTranslateService.get('graph_mathia.progress_bad_first_percent'),
                    backgroundColor: rgbOpacity(thirdLayerColor, '0.3'),
                    borderColor: thirdLayerColor
                },
                {
                    data: dots[3],
                    type: 'line',
                    fill: '2',
                    label: this.graphMathiaTranslateService.get('graph_mathia.progress_bad_second_percent'),
                    backgroundColor: rgbOpacity(fourthLayerColor, '0.3'),
                    borderColor: fourthLayerColor
                }
            ],
            modalContent: dotsDetails,
        });
    }

    private generateModalContentHtml(currentDot: ProgressDotDetail, index: number): string {
        return `<ul>
    <li>${this.graphMathiaTranslateService.get('graph_mathia.progress_good_first')} ${currentDot.firstAnswer}%</li>
    <li>${this.graphMathiaTranslateService.get('graph_mathia.progress_good_second')} ${currentDot.secondAnswer}%</li>
    <li>${this.graphMathiaTranslateService.get('graph_mathia.progress_bad_first')} ${currentDot.failedFirstAttempt}%</li>
    <li>${this.graphMathiaTranslateService.get('graph_mathia.progress_bad_second')} ${currentDot.failedRetry}%</li>
</ul>`;
    }

    private setChallengeStats(data: ProgressData): void {
        const challenges = _.clone(data.challenges);

        if (challenges.length > 0) {
            this.challenge = {
                count: challenges.length,
                lastDate: moment(challenges[challenges.length - 1].date, 'X').format('DD/MM/YYYY'),
                lastScore: challenges[challenges.length - 1].rawFirstAnswer,
                averageScore: challenges.reduce((acc, c) => acc + c.rawFirstAnswer, 0) / challenges.length
            };
        } else {
            this.challenge = null;
        }
    }
}
