export const ideasWall = {
    isRemoteAllowed: false, // remote is show or not on idea wall
    isIdeaWallOnlyForUCurrentUser: true,
    displayCreateWallHelper: true,
    displayHeader: false,
    displayMatMenuIcon: false,
    ideasWallDialogFields: {
        default: ['name', 'titles', 'steps'],
    },
    lockedOptionEnabled: false
};