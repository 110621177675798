import {Component, Input, OnInit} from '@angular/core';
import {CustomBlock} from 'fuse-core/components/collection/custom-blocks/custom-block.model';
import {BlockHandlerService} from 'fuse-core/components/collection/custom-blocks/block-handler.service';

@Component({
  selector: 'app-faq-block',
  templateUrl: './faq-block.component.html',
  styleUrls: ['./faq-block.component.scss']
})
export class FaqBlockComponent {

  @Input() block: CustomBlock;

  constructor(public blockHandler: BlockHandlerService) { }



}
