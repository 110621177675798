import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';

@Injectable({
    providedIn: 'root'
})
export class GraphUbolinoTranslateService {

    public dump: string[] = []

    private readonly keys: string[] = [
        'graph_ubolino.exercises',
        'graph_ubolino.total_time',
        'generic.pourcent_success',
        'graph_ubolino.progress_tooltip_header',
        'graph_ubolino.progress_variation_no_previous_case',
        'graph_ubolino.progress_duration',
        'graph_ubolino.progress_good_ones',
        'graph_ubolino.progress_bad_ones',
        'graph_ubolino.progress_variation',
    ];

    private translations: {[key: string]: string} = {};

    constructor(
        private communicationCenter: CommunicationCenterService,
        private translate: TranslateService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((currentUser: DataEntity) => {
                if (!!currentUser) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.translate.onLangChange.subscribe(() => this.refreshKeys());
    }

    private postAuthentication(): void {
        this.refreshKeys();
    }

    private postLogout(): void {
        this.translations = {};
    }

    public get(key: string): string {
        if (this.translations.hasOwnProperty(key) === false && this.dump.includes(key) === false) {
            this.dump.push(key);
            console.warn('No translation for ', this.dump.join('\n'));
        }
        return this.translations.hasOwnProperty(key) ? this.translations[key] : key;
    }

    private refreshKeys(): void {
        this.translate.get(this.keys).subscribe(data => {
            this.translations = data;
        });
    }
}
