import { Injectable } from '@angular/core';
import {ModelSchema, Structures} from "octopus-model";
import {modulesSettings} from '../../../../settings';
import {get} from "lodash-es";

const settingsStructure: ModelSchema = new ModelSchema({
    activitiesOptions: Structures.object({}),
});

@Injectable({
  providedIn: 'root'
})
export class ActivitiesConfigurationServiceService {

    public settings: { [p: string]: any };
    private privateSettings: Partial<{
        activityOptions: { displayAnswersLabel: boolean } | undefined,
    }>;

    constructor() {
        this.privateSettings = settingsStructure.filterModel(modulesSettings.activities);
    }

    public activityOptions(typology: 'trueFalse' | 'qcu'): { displayAnswersLabel: boolean } | undefined {
        const activityOptions = get(this.privateSettings, 'activitiesOptions', {});
        return activityOptions[typology] || undefined;
    }
}
