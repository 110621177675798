import {PatternBatchInterface} from './pattern-batch.interface';
import {PatternInterface} from './pattern.interface';
import {Omit} from '../../omit.type';

/**
 * it's a bath of pattern, visual or another batch.
 * But it's defined to be in row or in column
 */
export class PatternBatch implements PatternBatchInterface {
    readonly type = 'PatternBatch';
    readonly disposition: 'column' | 'row' = 'column';

    elements: PatternInterface[] = [];

    constructor(options?: Omit<Partial<PatternBatchInterface>, 'type'>) {
        Object.assign(this, options);
    }
}