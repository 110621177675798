import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {GraphConfig} from 'fuse-core/components/graph/graph-mixed/graph.config';
import {GraphBayardService} from '../../services/graph-bayard.service';
import {delay, filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {AttendanceData, ExerciseAttendanceGraphData} from '../../model/attendance-data';
import {CustomTooltipModalContentSettings} from 'fuse-core/components/graph/graph-details-modal/custom-tooltip.setting';
import {rgbOpacity} from 'shared/utils';
import 'moment-duration-format';
import {GraphBayardTranslateService} from '@modules/graph-bayard/core/services/graph-bayard-translate.service';
import {FakeStepperOptions} from '@modules/graph-bayard/core/component/fake-stepper/fake-stepper.component';
import {ChartConfiguration} from 'chart.js/dist/types';
import {GraphConfigurationService} from '@modules/graph-bayard/core/services/graph-configuration.service';
import {MultiLessonDataEntity} from '@modules/graph-bayard/core/model/multi-lesson-data.entity';

const Colors = [
    'rgb(3,124,194)',
];

@Component({
    selector: 'app-attendance-graph',
    templateUrl: './attendance-graph.component.html',
})
export class AttendanceGraphComponent implements OnInit, OnDestroy {

    public graphConfigObs: ReplaySubject<GraphConfig> = new ReplaySubject<GraphConfig>(1);
    public isReady = false;
    /**
     * Cache le loader si le service n'est pas encore pret
     */
    public showLoader = false;
    public empty = false;
    private unsubscribeInTakeUntil = new Subject<void>();
    /**
     * Aide a déterminer le temps entre les steps du loader
     * @private
     */
    private loaderShouldEnd = false;
    /**
     * Défini les steps du loader, le temps entre les stepsn, etc.
     */
    public loaderOptions: Partial<FakeStepperOptions> = {
        loop: false,
        interval: stateIndex => (this.loaderShouldEnd === false || stateIndex <= 2 ? 4000 : 1000),
        states: ['fake_stepper.state_1', 'fake_stepper.state_2', 'fake_stepper.state_3'],
        onEnd: new ReplaySubject(1)
    };

    constructor(
        private graphBayardService: GraphBayardService,
        private graphBayardTranslateService: GraphBayardTranslateService,
        private graphConfigService: GraphConfigurationService
    ) {
    }

    ngOnInit(): void {
        this.graphBayardService.isReady
            .pipe(
                filter(isReady => !!isReady),
                tap(() => this.graphBayardService.graphDataArePending.subscribe(() => this.isReady = false)),
                mergeMap(() => this.graphBayardService.getAttendanceGraphData()),
                tap(() => this.showLoader = true),
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data) => {
            this.loaderShouldEnd = true;
            if(data.entities.length === 0) {
                this.empty = true;
                this.graphConfigObs.next(null);
            }else {
                this.empty = false;
                this.generateGlobalGraph(data);
            }
            this.loaderOptions.onEnd.pipe(take(1), delay(750)).subscribe(() => this.isReady = true);
        });

    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private generateGlobalGraph(graphData: AttendanceData): void {
        const rawData = graphData.entitiesGroupedByLearner;
        const learners = this.graphBayardService.learners
            .filter(l => (graphData.graphFilters.learnerList || []).includes(+l.id))
            .sort((a, b) => a.nickname < b.nickname ? -1 : 1);
        const chartLabels: string[] = learners.map(l => l.nickname);
        const chartDataSets: ChartConfiguration['data']['datasets'] = [];
        const learnerActivityCounter: {
            learnerId: number,
            count: number
        }[] = [];
        let availableLessons: MultiLessonDataEntity[] = [];

        if(this.graphConfigService.isThisGraphHasToBeFilteredByConcepts('attendance')) {
            availableLessons = this.graphBayardService
                .conceptsAvailable
                .map(c => c.id)
                .map(conceptId =>
                    this.graphBayardService
                        .lessons
                        .filter(l =>
                            l.get('metadatas')
                                ?.concepts
                                .map(currentLessonConcept => +currentLessonConcept.id)
                                .includes(+conceptId)
                        )
                )
                .reduce((acc, val) => acc.concat(val), []);
        } else {
            availableLessons = this.graphBayardService.lessons.slice();
        }

        const chapterColor = Colors[0];

        const chapterDataSet = {
            label: this.graphBayardTranslateService.get('graph_bayard.y_axis_legend'), // label: chapter.attributes.name,
            backgroundColor: rgbOpacity(chapterColor, '.9'),
            hoverBackgroundColor: chapterColor,
            data: [],
            barThickness: 'flex',
        };

        learners.forEach((learner, indexLearner) => {
            const learnerGraphData = rawData[+learner.id + ''] || [];
            let totalOfActivitiesDone = 0;

            learnerGraphData
                .map(lgd => lgd.attributes)
                .forEach(progressData =>
                    progressData.qs
                        .filter(qs => availableLessons.map(l => +l.id).includes(+qs.id))
                        .forEach(qs => totalOfActivitiesDone += qs.answers.total)
                );

            // const duration: number = exerciceGraphData ? exerciceGraphData.map(d => d.detail.map((dd) => (0 + dd.duration))).flat().reduce((acc, dd) => acc + dd, 0) : 0;
            learnerActivityCounter.push({
                learnerId: +learner.id,
                count: totalOfActivitiesDone
            });
            chapterDataSet.data[indexLearner] = totalOfActivitiesDone;
        });

        chartDataSets.push(chapterDataSet);

        this.empty = chartDataSets.every(cds => cds.data.every(d => d === 0));

        // On reboucle parce qu'on avait besoin d'avoir les totaux avant c'est pas la plus optimisé des façon de faire, mais la plus lisible
        const dotsDetails: CustomTooltipModalContentSettings[][] = [learners.map((learner): CustomTooltipModalContentSettings => {
            const activitiesCounter = learnerActivityCounter.filter(c => +c.learnerId === +learner.id);
            const count = activitiesCounter.reduce((acc, ac) => acc + ac.count, 0);
            return ({
                content: `<div>${count} ${this.graphBayardTranslateService.get('graph_bayard.exercises')}</div>`,
                header: `<div>${learner.nickname}</div>`
            });
        })];

        this.graphConfigObs.next({
            chartLabels,
            chartConfig: {
                scales: {
                    x: {
                        grid: {
                            display: true,
                            offset: false
                        },
                        stacked: true,
                        // gridLines: {
                        //     display: false,
                        // }
                    },
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        ticks: {
                            precision: 0
                        },
                        type: 'linear',
                    },

                },
                plugins: {
                    legend: {
                        align: 'start',
                        position: 'bottom',
                        onClick: event => event.native.stopPropagation(),
                        display: true
                    }
                },
                aspectRatio: 2.5
            },
            chartData: chartDataSets,
            modalContent: dotsDetails,
        });
    }
}
