import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-activity-edition-typology-icon',
  templateUrl: './activity-edition-typology-icon.component.html',
  styleUrls: ['./activity-edition-typology-icon.component.scss']
})
export class ActivityEditionTypologyIconComponent {
    @Input() iconUrl: string;
    @Input() ariaLabel: string;
}
