import {DataWrapper} from "shared/models/data-wrapper";

/** @deprecated use FileData instead */
export interface FileReference {
    filemime: string;
    id: string;
    label: string;
    uri: string;
}

/** @deprecated use GenericBackResponse<FileReference> instead */
export type FileReferenceData = DataWrapper<FileReference[]>;