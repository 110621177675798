import {Component, inject, OnInit} from '@angular/core';
import {CompassService} from 'fuse-core/services/compass.service';
import {combineLatest, shareReplay} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {map, take, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';

@Component({
    selector: 'app-compass',
    templateUrl: './compass.component.html',
})
export class CompassComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {

    private compassService = inject(CompassService);
    private router = inject(Router);

    public themes: DataEntity[] = [];
    public exploreData: DataEntity[] = [];
    public displayLoader = true;
    public compassHelperBasicPage$ = this.compassService.compassHomepage$.pipe(
        shareReplay(1)
    );

    ngOnInit() {
        // le async devrait permettre de ne pas avoir à faire le subscribe mais ça ne fonctionne pas alors on le fait
        this.compassHelperBasicPage$.subscribe()
        const exploreData$ = this.compassService.loadExploreData()
            .pipe(
                take(1),
            );

        const themes$ = this.compassService.loadThemes()
            .pipe(
                take(1),
            );

        combineLatest([exploreData$, themes$])
            .pipe(
                take(1),
                tap(([exploreData, themes]) => {
                    this.exploreData = exploreData;
                    this.themes = themes;
                    this.displayLoader = false
                })
            )
            .subscribe();
    }

    /**
     * Get themes promoted
     */
    public get themesPromoted(): DataEntity[] {
        return this.themes.filter(theme => theme.get('promoted') === '1');
    }

    /**
     * cette fonction permet de voir les ressources d'un theme
     * @param idResources
     */
    public seeResources(theme: DataEntity): void {
        if (theme?.get('ressources')?.length > 0) {
            this.router.navigate(['boussole-ressources'],
                {
                    state: {
                        id: theme?.get('ressources'),
                        selectedThemeTitle: theme.get('label'),
                        selectedThemeBody: theme.get('body')
                    }
                });
        }
    }

    /**
     * cette fonction permet de voir les themes
     * @param theme
     */
    public seeThemes(theme: DataEntity): void {
        this.router.navigate(['boussole-themes'], {
            state: {
                diagnostics: theme.get('diagnostic'),
                skills: theme.get('skills'),
                difficulty: theme.get('difficulty'),
                title: theme.get('label'),
                peda: theme.get('peda'),
                orga: theme.get('orga'),
                explore: theme.id,
            }
        });
    }

}

