import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {Achievement} from '@modules/achievement/core/services/user-score.service';
import { brand } from 'app/settings';

@Component({
    templateUrl: './achievement-dialog.component.html',
})
export class AchievementDialog {

    public brand = brand;

    constructor(@Inject(MAT_DIALOG_DATA) public data: Achievement) {
    }
}