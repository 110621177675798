import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import {TranslateService} from '@ngx-translate/core';
import {FaqService} from '@modules/faq/core/faq.service';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {CollectionOptionsInterface, DataEntity, OrderDirection} from 'octopus-connect';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-faq-list',
    templateUrl: './faq-list.component.html'
})
export class FaqListComponent implements OnInit, OnDestroy {
    @ViewChild(MatAccordion) accordion: MatAccordion;
    @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;
    public unsubscribeInTakeUntil = new Subject<void>();
    public questions: DataEntity[] = [];
    public countEntities = 0;
    public pageIndex = 0;
    public pageRange = 10;
    public pageRangeOptions = [10];
    public showLoader = false;
    public questionsSubscription: Subscription;
    public optionsInterface: CollectionOptionsInterface;
    public fieldsToDisplay: string[] = [];
    public customFieldName: { field: string, value: string }[] = [{field: 'title', value: ''}];
    public displayedFiltersIcons: boolean;

    constructor(
        private translate: TranslateService,
        private faqService: FaqService
    ) {
        this.optionsInterface = {
            filter: {}, // filter results by current user id
            page: 1,
            range: 3
        };
    }

    public ngOnInit(): void {
        this.showLoader = true;
        this.questionsSubscription = this.refreshList();
        this.customFieldName[0].value = this.faqService.settings.filterTitleToTranslate;
        this.displayedFiltersIcons = this.faqService.settings.displayedFiltersIcons;
        this.setDisplayField();
    }

    public launchSearch(optionsInterface: CollectionOptionsInterface = {filter: {}, page: 1, range: 10}): void {
        this.questionsSubscription.unsubscribe();
        this.questionsSubscription = this.refreshList(optionsInterface);
    }

    public refreshList(optionsInterface: CollectionOptionsInterface = {filter: {}, page: 1, range: 10, orderOptions: []}): Subscription {
        this.optionsInterface = _.cloneDeep(optionsInterface);

        return this.faqService.loadPaginatedFaq(this.optionsInterface).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((questions) => {
                    this.questions = questions;
                    this.setPaginator();
                    this.showLoader = false;
                },
                (err) => console.log(err));
    }

    public setPaginator(): void {
        this.countEntities = this.faqService.questionsPaginated.paginator.count;
        this.pageIndex = this.faqService.questionsPaginated.paginator.page - 1;
        this.pageRange = this.faqService.questionsPaginated.paginator.range;
    }

    public setDisplayField(): void {
        this.fieldsToDisplay = this.settings.searchFields;
    }

    private get settings(): any {
        return this.faqService.settings;
    }
    public get rolesCanShowBannerInfo(): string[] {
        return this.settings.rolesCanShowBannerInfo;
    }

    public onPaginateChange(event): void {
        this.showLoader = true;
        this.faqService.questionsPaginated.paginator.page = event.pageIndex + 1;
    }

    public ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

}
