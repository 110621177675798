import {Component, Input, OnInit} from '@angular/core';
import {CommunicationCenterService} from "@modules/communication-center";
import {tap} from "rxjs/operators";
import {AuthenticationService} from "@modules/authentication";

@Component({
    selector: 'app-banner-info',
    templateUrl: './banner-info.component.html'
})
export class BannerInfoComponent {

    @Input() public rolesCanShowBanner: string[] | boolean;
    public role: string;
    @Input() situation: string;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private authenticationService: AuthenticationService
    ) {
        this.communicationCenter.getRoom('authentication').getSubject('userData').subscribe(
            (userData) => {
                // Si l'utilisateur n'est pas connecté, accessLevel vaut "anonymous"
                this.role = this.authenticationService.accessLevel
            }
        );
    }

    public get isDisplayBannerInfo(): boolean {
        return this.role &&
            (Array.isArray(this.rolesCanShowBanner) && this.rolesCanShowBanner?.includes(this.role))
            || (typeof this.rolesCanShowBanner === 'boolean' && this.rolesCanShowBanner === true);
    }

    public get roleModifiers(): string {
        let modifiers = '';

        if (this.authenticationService.isGAR()) {
            modifiers += '_gar';
        }

        return modifiers;
    }

}
