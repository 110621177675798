import {DataEntity} from 'octopus-connect';

export class ResearchSheet {
  id: number = -1;
  name: string = '';
  group: any = {};
  project: string = '';
  template: ResearchTemplate = null;
  sections: ResearchSection[] = [];
  created: string = '';
  updated: string = '';
}

export class ResearchTemplate {
  id: number = -1;
  name: string = '';
  sections: ResearchSectionTemplate[] = [];
  sectionsDefinitions: ResearchSectionTemplate[];
}

export class ResearchSection {
  id: number = -1;
  name: string = '';
  text: string = '';
  template: ResearchSectionTemplate;
  state: string = '';
  comments: Array<any> = [];
  created: number = -1;
  updated: number = -1;
  parent: string = '';
  researchSheet: number = -1;
  x?: number = -1;
  y?: number = -1;
  childCount: number = 0;
  medias: any = [];
}

export class ResearchSectionTemplate {
  id: number = -1;
  name: string = '';
  consigne: string = '';
  readingGrid: string = '';
  followingSections: string = '';
  draftQuestions: ResearchDraftQuestion[] = [];
}

export class ResearchDraftQuestion {
  id: number = -1;
  name: string = '';
}
