import {Injectable} from '@angular/core';
import {CorpusService} from "@modules/corpus";
import {ContextualService} from "@modules/corpus/core/services/contextual.service";
import {MediaUploadService} from "@modules/corpus/core/media-upload.service";
import {CorpusAuthorizationService} from '@modules/corpus/core/services/corpus-authorization.service';

@Injectable({
    providedIn: 'root'
})
export class ServicesInstantiatorService {

    constructor(
        private corpusService: CorpusService,
        private mediaUploadService: MediaUploadService,
        private contextualActions: ContextualService,
        private corpusAuthorizationService: CorpusAuthorizationService,
    ) {
    }
}
