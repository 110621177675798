import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LocalCacheService} from "@modules/authentication/core/services/local-cache.service";
import {CachedUser} from "@modules/authentication/core/models/cached-user.type";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {FuseConfirmDialogComponent} from "fuse-core/components/confirm-dialog/confirm-dialog.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-sessions-list',
    templateUrl: './sessions-list.component.html',
    styleUrls: ['./sessions-list.component.scss']
})
export class SessionsListComponent implements OnInit {
    @Output() loginSession = new EventEmitter<CachedUser>()
    private loading = false;

    constructor(
        private localCache: LocalCacheService,
        private dialog: MatDialog,
        private translate: TranslateService,
    ) {
        this.loading = true;
    }
    
    ngOnInit(): void {
        this.loading = false;
    }

    public get cachedUsers(): CachedUser[] {
        return this.localCache.getCachedUsers();
    }
    
    public get showSpinner(): boolean {
        return this.loading;
    }
    
    public login(user: CachedUser): void {
        this.loading = true;
        this.loginSession.emit(user);
    }
    
    public deleteCachedUser(user: CachedUser): void {
        this.dialog.open(FuseConfirmDialogComponent, {
            data: {
                titleDialog: this.translate.instant('account-management.confirm_disconnect_title'),
                bodyDialog: this.translate.instant('account-management.confirm_disconnect_body'),
                labelTrueDialog: this.translate.instant('generic.yes'),
                labelFalseDialog: this.translate.instant('generic.no'),
            }
        })
        .afterClosed().subscribe((confirmDelete: boolean) => {
            if (confirmDelete) {
                this.localCache.deleteCachedUser(user);
            }
        })
    }
}
