export interface GenericBackResponse<T> {
    data: T[],
    count: number,
    self: {
        title: 'Self',
        href: URL | string
    },
    next: {
        title: 'Next',
        href: URL | string
    }
}