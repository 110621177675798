import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FlashCardInterface} from 'fuse-core/components/flashcard/flash-card.interface';
import {DictionaryService} from '@modules/activities/core/dictionary/dictionary.service';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {AccountManagementProviderService} from "@modules/account-management";


@Component({
    selector: 'app-dictionary',
    templateUrl: './dictionary.component.html',
})
export class DictionaryComponent implements OnInit {
    public readInProgress = false;
    public cards: FlashCardInterface[] = [];
    public loader = false;
    private page = 1;
    public currentFlashCardReading: FlashCardInterface;
    public idCardPlaying = '';
    public currentCard = null;
    public instructionAudio = '';
    public instruction = '';
    concept: string;

    private player: HTMLAudioElement;
    private destroy$: Subject<void> = new Subject<void>();

    @ViewChild('stream') set playerRef(ref: ElementRef<HTMLAudioElement>) {
        this.player = ref?.nativeElement;
    }

    constructor(private dictionaryService: DictionaryService,
                private changeDetectorRef: ChangeDetectorRef,
                private translate: TranslateService,
                private accountManagementProvider: AccountManagementProviderService,
                ) {

    }

    ngOnInit(): void {
        this.concept = this.accountManagementProvider.loggedUser.get('config').concept
        this.dictionaryService.setFilterOptions({filter: {letter: "A", flashConcepts: this.concept}});
        this.instruction = this.translate.instant(`generic.dictionary_card_consigne`);
        this.loadData();
        this.loadInstructionAudio();
    }

    private loadInstructionAudio(): void {
        if(this.instruction) {
            this.dictionaryService.getAudio(this.instruction).pipe(
                takeUntil(this.destroy$)
            ).subscribe(audio => {
                this.instructionAudio = audio;
            });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public get filteredLetter(): string {
        return this.dictionaryService.filteredLetter;
    }

    /**
     *  if is playing block other card read
     * @param $event 'is-playing' | 'is-selected'
     * @param flashcard
     */
    public onStateChange($event: string, flashcard: FlashCardInterface): void {
        this.resetPlayer();
        if (flashcard?.audio?.uri) {
            this.startPlaying(flashcard);
        }
    }

    public openActivity(card: FlashCardInterface): void {
        setTimeout(() => {
            this.currentCard = card;
        }, 300);
    }

    public closeActivity(): void {
        this.currentCard = null;
    }

    public filterToApply(elem: string): void {
        this.loader = true;
        this.page = 1;
        this.cards = [];
        this.dictionaryService.setFilterOptions({filter: {letter: elem, flashConcepts: this.concept}});
        this.loadData(false);
    }


    /**
     * when infinite scroll detect event load next data
     */
    public onScroll(): void {
        this.loader = true;
        this.page = this.page + 1;
        this.loadData();
    }

    private loadData(append: boolean = true): void {
        this.dictionaryService.loadDictionaryCard(this.page).pipe(
            takeUntil(this.destroy$)
        ).subscribe(flashcards => {
            if (append) {
                this.cards.push(...flashcards);
            } else {
                this.cards = flashcards;
            }
            this.loader = false;
            this.readInProgress = false;
        });
    }

    private resetPlayer(): void {
        if (this.player) {
            this.player.pause();
            this.player.currentTime = 0;
            this.player.onended = () => {
                this.idCardPlaying = '';
            };
        }
    }

    private startPlaying(flashcard: FlashCardInterface): void {
        this.currentFlashCardReading = flashcard;
        this.idCardPlaying = flashcard.id;
        this.changeDetectorRef.detectChanges();
        this.player.play();
    }
}
