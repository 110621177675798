import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActionsService} from "fuse-core/services/actions.service";
import {ConditionsService} from "fuse-core/services/conditions.service";
import {Observable} from "rxjs";
import {FieldBlock} from "fuse-core/components/basic-page/custom-blocks/field-block/field-block.model";
import {DataService} from "fuse-core/services/data.service";
import {takeUntil} from "rxjs/operators";
import {AutoUnsubscribeTakeUntilClass} from "../../../../../app/shared/models/auto-unsubscribe-take-until.class";

@Component({
    selector: 'app-field-block',
    templateUrl: './field-block.component.html',
    styleUrls: ['./field-block.component.scss']
})
export class FieldBlockComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    @Input() private blockData: FieldBlock;
    @Output() private action = new EventEmitter<void>();

    private chips: string[] = [];

    constructor(
        private actions: ActionsService,
        private conditions: ConditionsService,
        private data: DataService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.data.getData$('field/' + this.blockData.fieldName)
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((data: string[]) => {
                this.chips = data.sort();
            });
    }

    public get description(): string {
        return this.blockData.description;
    }

    public get hasMultiple(): boolean {
        return this.blockData.multiple;
    }

    public selectChip(chip: string): void {
        this.actions.do('filter/' + this.blockData.fieldName + '/' + chip);
        this.triggerAction();
    }

    public triggerAction(): void {
        if (this.blockData.action) {
            this.actions.do(this.blockData.action);
        }

        this.action.emit();
    }

    public isDisplayed$(): Observable<boolean> {
        return this.conditions.check$(this.blockData.condition);
    }
}
