import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(
      private router: Router,
  ) { }

  ngOnInit(): void {
  }

    onStartClick($event: MouseEvent): void {
        $event.stopPropagation();
        $event.preventDefault();

        this.router.navigate(['/lessons/list/models']);
    }
}
