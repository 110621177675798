// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-section-draft-item {
  opacity: 0.5;
}
app-section-draft-item ::ng-deep mat-card {
  border: 1px solid rgba(1, 1, 1, 0);
  margin-left: 4px !important;
  margin-right: 4px !important;
}
app-section-draft-item.selected {
  opacity: 1;
}
app-section-draft-item.selected ::ng-deep mat-card {
  border: 1px solid #7d7d7d;
}

.draft-tab {
  overflow: auto;
}

.draft-container {
  height: 100%;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/research-sheet/core/section-display/section-display.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAEI;EACE,kCAAA;EACA,2BAAA;EACA,4BAAA;AAAN;AAIE;EACE,UAAA;AAFJ;AAKM;EACE,yBAAA;AAHR;;AASA;EACE,cAAA;AANF;;AASA;EACE,YAAA;EACA,cAAA;AANF","sourcesContent":["app-section-draft-item {\n  opacity: 0.5;\n\n  ::ng-deep {\n    mat-card {\n      border: 1px solid rgba(1, 1, 1, 0);\n      margin-left: 4px !important;\n      margin-right: 4px !important;\n    }\n  }\n\n  &.selected {\n    opacity: 1;\n\n    ::ng-deep {\n      mat-card {\n        border: 1px solid #7d7d7d;\n      }\n    }\n  }\n}\n\n.draft-tab {\n  overflow: auto;\n}\n\n.draft-container {\n  height: 100%;\n  overflow: auto;\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
