import {Injectable} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ListOfActivitiesService {
    private currentUser: DataEntity;
    private assignmentStates: DataEntity[];

    constructor(private communicationCenter: CommunicationCenterService) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.currentUser = data;
                }
            });
        this.communicationCenter
            .getRoom('assignments')
            .getSubject('statesList').pipe(
            tap((states: DataEntity[]) => this.assignmentStates = states)
        ).subscribe();
    }


    public createAssignment(lessonId: string | number, callBack): Observable<boolean> {
        const dataForAssignments = {
            assignated_node: lessonId,
            assignated_user: this.currentUser.id,
            assignator: this.currentUser.id,
            dates: {value: 0, value2: 0},
            state_term: +(this.assignmentStates.find((state: DataEntity) => state.get('label') === 'assigned').id)
            // type_term: recommendation.get('assignationType'),
        };

        this.communicationCenter.getRoom('assignment')
            .next('createAssignment', {assignment: dataForAssignments, callback: callBack});
        return of(true);
    }

    public setAssignment(assignment: DataEntity): void {
        this.communicationCenter
            .getRoom('assignment')
            .next('current', assignment);
    }

}
