import { Injectable } from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';

@Injectable({
  providedIn: 'root'
})
export class WidgetMessageService {

  groups: any[];

  constructor(
      private communicationCenter: CommunicationCenterService
  ) { }

  /**
   *
   * @param thread
   */
  isArchived(thread: any): boolean {
    return thread && thread.archived;
  }
  /**
   *
   * @param thread
   */
  isLastMessageHasBeenViewedByUser(thread: any): boolean {
    return thread && +thread['lastViewed'] >= +thread['lastMessage'];
  }


}
