import {Injectable} from '@angular/core';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {filter, mergeMap, map, take, tap} from 'rxjs/operators';
import {CollectionOptionsInterface} from 'octopus-connect';
import * as _ from 'lodash-es';
import {CommunicationCenterService} from '@modules/communication-center';

/**
 * Used to visualize editable gamecode data.
 * Sometimes it's only used for `text` fields, sometimes only the others
 */
export interface IGamecodeFormData {
    title?: string;
    associatedLessonId?: string | number;
    application?: string;
}

const granuleEndpoint = 'creations';
const gamecodeEndpoint = 'creations';
const searchEndpoint = 'basic_search';

const gamecodeTypologyLabel = 'gamecode';

@Injectable({
    providedIn: 'root'
})
/**
 * Define the exchange between front & backend in matter of gamecode
 */
export class GamecodeRepositoryService {

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService
    ) {
    }

    /**
     * Delete a gamecode defined by the granule id. The metadatas, activity & activity_content are deleted on cascade
     * @param id
     */
    public destroyGamecode(id: number | string): Observable<boolean> {
        // Hack: no need to reload the entity, we can mock it :)
        const entity = new DataEntity(granuleEndpoint, {}, this.octopusConnect, id);
        return entity.remove();
    }

    /**
     * Return the gamecode {@link DataEntity} as a granule (contains granule, medatada, activity & activityContent)
     * @param id
     */
    public getGamecode(id: string | number): Observable<DataEntity> {
        return this.octopusConnect.loadEntity(granuleEndpoint, id);
    }

    /**
     * Path a gamecode entity and return it
     *
     * @param gamecodeId the id of the gamecode
     * @param data the data to path, each data are not required, only the given key will be patched.
     * Some data are in metadata of granule, other in activity_content. This method know what to do.
     * @param returnUpdatedGranule If true or by default, returns the patched gamecode granule (but a request is made),
     * otherwise returns the granule in the same state as before patching (and no request are done)
     */
    public updateGamecode(gamecode: DataEntity, data: IGamecodeFormData, returnUpdatedGranule = true): Observable<DataEntity> {
        if (data.hasOwnProperty('title')) {
            gamecode.set('title', data.title);
        }
        return gamecode.save();
    }

    /**
     * Obtains the paginated list of notes
     * @param filterOptions
     * @return The {@link DataEntity} are `granules` and the are not of `gamecodes` but `BasicSearch` endpoint
     */
    public getPaginatedGamecodes(filterOptions: CollectionOptionsInterface = {}): Observable<PaginatedCollection> {
        return this.getGamecodeActivityTypologyId().pipe(
            map(typologyId => {
                filterOptions = _.merge({
                }, filterOptions);
                return this.octopusConnect.paginatedLoadCollection(gamecodeEndpoint, filterOptions);
            }),
            take(1)
        );
    }

    /**
     * Create and obtain a new gamecode activity
     *
     * - The activity is create by the generic activity creation of activity module.
     * - This way to create an activity don't set an activity content, that's why an activity_content are created and associated here.
     * - This way to create an activity don't allow to set default values, that's why the empty activity are updated here just after creation.
     *
     * @param gamecodeData
     */
    public createGamecode(gamecodeData: IGamecodeFormData): Observable<DataEntity> {
        return this.octopusConnect.createEntity(gamecodeEndpoint, gamecodeData);
    }

    /**
     * Obtains the `activity_content` from `gamecodes` endpoint
     *
     * @param id
     */
    private getGamecodeActivityContent(id: string | number): Observable<DataEntity> {
        return this.octopusConnect.loadEntity(gamecodeEndpoint, id);
    }

    /**
     * Obtain the id of the only one activity typology with `gamecode` as label
     */
    private getGamecodeActivityTypologyId(): Observable<string> {
        return this.octopusConnect.loadCollection('variables/instance')
            .pipe(
                filter(collection => collection.entities.length > 0),
                map(collection => collection.entities[0].get('activityTypes')),
                map(activityTypes =>
                    activityTypes.filter(activityType =>
                        activityType.label === gamecodeTypologyLabel
                    ).map(activityType => activityType.id)[0]
                ),
                take(1)
            );
    }
}
