import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthorizationService} from '@modules/authorization/core/services/authorization.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ]
})
export class AuthorizationModule {
    static forRoot(): ModuleWithProviders<AuthorizationModule> {

        return {
            ngModule: AuthorizationModule,
            providers: [
                AuthorizationService
            ]
        };
    }
}
