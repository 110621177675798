import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-activity-edition-preview-write-bad-feedback',
  templateUrl: './activity-edition-preview-write-bad-feedback.component.html',
  styleUrls: ['./activity-edition-preview-write-bad-feedback.component.scss']
})
export class ActivityEditionPreviewWriteBadFeedbackComponent {
    @Input() badFeedback: string | null = null;
}
