import {Component, Inject, Optional} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

/**
 * This is a generic modal used in multiple context to alert the user :
 * - before living editor without saving
 * - before reset gamecode content
 */
@Component({
    selector: 'app-gamecode-generic-alert-modal',
    templateUrl: './gamecode-generic-alert-modal.component.html'
})
export class GamecodeGenericAlertModalComponent {

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public modalData: { contentKey: string },
        public selfDialogRef: MatDialogRef<GamecodeGenericAlertModalComponent>) {
    }

}
