import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ResearchSheetService} from "../../research-sheet.service";
import {ResearchSheet, ResearchTemplate} from "@modules/research-sheet/core/definitions";

@Component({
  selector: 'app-research-sheet-form',
  templateUrl: './research-sheet-form.component.html',
  styleUrls: ['./research-sheet-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResearchSheetFormComponent implements OnInit {
  public sheet: ResearchSheet;
  public sheetForm: UntypedFormGroup;
  public action: string;
  public dialogTitle: string;
  public groupsList: any[];
  public templatesList: ResearchTemplate[];
  formErrors: any;

  constructor(
    public dialogRef: MatDialogRef<ResearchSheetFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: UntypedFormBuilder,
    private sheetService: ResearchSheetService
  ) {
    this.action = data.action;

    this.formErrors = {
      name: {},
      template: {},
      group : {}
    }


    if (this.action === 'edit') {
      this.sheet = data.sheet;
      this.sheetForm = this.editSheetForm();
    } else {
      this.sheet = new ResearchSheet();
      this.sheetForm = this.createProjectForm();
    }

    this.sheetForm.valueChanges.subscribe(() => {
      this.onRegisterFormValuesChanged();
    });
  }

  onRegisterFormValuesChanged(){
    for ( const field in this.formErrors )
    {
      if ( !this.formErrors.hasOwnProperty(field))
      {
        continue;
      }

      // Clear previous errors
      this.formErrors[field] = {};

      // Get the control
      const control = this.sheetForm.get(field);

      if ( control && control.dirty && !control.valid )
      {
        this.formErrors[field] = control.errors;
      }
    }
  }

  emptyObject(control: AbstractControl){

    let i = 0;

    if ( !control.parent || !control )
    {
      return;
    }

    const group = control.parent.get('group');

    if (!group)
    {
      return;
    } else {
      for (let key in group.value){
        ++i;
      }

      if (i === 0){
        return {
          noGroup : true
        };
      }


    }



  }

  ngOnInit() {
  }

  private createProjectForm(): UntypedFormGroup {
    this.groupsList = this.sheetService.getGroups();
    this.templatesList = this.sheetService.getTemplates();

    return this.formBuilder.group({
      name: [this.sheet.name, Validators.required],
      template: [this.sheet.template, Validators.required],
      group: [this.sheet.group, [Validators.required, (control) => this.emptyObject(control)]]
    });
  }

  private editSheetForm(): UntypedFormGroup {
    this.groupsList = this.sheetService.getGroups();
    this.templatesList = this.sheetService.getTemplates();

    return this.formBuilder.group({
      id: [this.sheet.id],
      name: [this.sheet.name, Validators.required],
      group: [this.sheet.group, Validators.required]
    });
  }

}
