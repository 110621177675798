import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lms-research-sheet-list-section-state',
  templateUrl: './research-sheet-list-section-state.component.html',
  styleUrls: ['./research-sheet-list-section-state.component.scss']
})
export class ResearchSheetListSectionStateComponent implements OnInit {
  @Input('state') state: string;
  private states = {
    validated: {
      name: 'research-sheet.state_validated',
      icon: 'chat_happy'
    },
    correct: {
      name: 'research-sheet.state_correct',
      icon: 'chat_sad'
    },
    pending: {
      name: 'research-sheet.state_pending',
      icon: 'hourglass'
    },
    closed: {
      name: 'research-sheet.state_closed',
      icon: 'chat_close'
    },
    default: {
      name: '',
      icon: ''
    }
  }

  constructor() { }

  ngOnInit() {
    if (!this.states[this.state]) {
      this.state = 'default';
    }
  }

  public get name(): string {
    return this.states[this.state].name;
  }

  public get icon(): string {
    return this.states[this.state].icon;
  }

  public get classes(): {[key:string]:boolean} {
    return {
      validated: this.state === 'validated',
      correct: this.state === 'correct',
      pending: this.state === 'pending',
      closed: this.state === 'closed'
    };
  }
}
