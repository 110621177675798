import {Component, DoCheck, inject, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {AssignmentByStepService, IGroup, IUSer, IWorkGroup} from '@modules/assignation/core/components/assignment-by-steps/assignment-by-step.service';

@Component({
    selector: 'app-assignment-select-assignated',
    templateUrl: './assignment-select-assignated.component.html',
})

export class AssignmentSelectAssignatedComponent implements OnInit, DoCheck {
    public assignmentByStepService: AssignmentByStepService;
    public selectedOption: string;
    private previousSelectionStatus: boolean;
    public breadcrumb: string[] = [];

    constructor() {
        this.assignmentByStepService = inject(AssignmentByStepService);
        this.breadcrumb = this.assignmentByStepService.setBreadcrumb();
        this.breadcrumb.push(
            this.assignmentByStepService.title + ' (' + this.assignmentByStepService.seances.filter(s => s.selected).length + ' séances)'
        );
    }

    ngOnInit(): void {
        this.setSelectedOption();
    }

    ngDoCheck() {
        const currentSelectionStatus = this.atLeastOneItemSelected();
        if (currentSelectionStatus !== this.previousSelectionStatus) {
            this.assignmentByStepService.disabledNextButton = !currentSelectionStatus;
            this.assignmentByStepService.disabledValidateButton = !currentSelectionStatus;
            this.previousSelectionStatus = currentSelectionStatus;
        }
    }

    public setSelectedOption(): string {
        if (this.assignmentByStepService.workgroups.length > 0) {
            return this.selectedOption = 'workgroups';
        } else if (this.assignmentByStepService.workgroups.length === 0 && this.assignmentByStepService.groups.length > 0) {
            return this.selectedOption = 'groups';
        } else if (this.assignmentByStepService.workgroups.length === 0 && this.assignmentByStepService.groups.length === 0 && this.assignmentByStepService.learners.length > 0) {
            return this.selectedOption = 'learners';
        }
    }

    public learnersSelected(): IUSer[] {
        return this.assignmentByStepService.learners.filter(l => l.selected === true);
    }

    public groupsSelected(): IGroup[] {
        return this.assignmentByStepService.groups.filter(l => l.selected === true);
    }

    public workgroupsSelected(): IWorkGroup[] {
        return this.assignmentByStepService.workgroups.filter(l => l.selected === true);
    }

    public atLeastOneItemSelected(): boolean {
        return this.workgroupsSelected().length > 0 || this.groupsSelected().length > 0 || this.learnersSelected().length > 0;
    }
}