import {ActivityReference} from '@modules/activities/core/models/activities/activity.reference';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
import {ActivityMetaEntityAttributes} from '@modules/activities/core/models/activities/activity-meta-entity.attributes';
import {ActivityContent} from 'app/@modules/activities/core/models/activities/activity-contents';
import {GranuleEntityAttributesInterface} from 'shared/models/granule';

export type ActivityGranuleAttributes<T extends ActivityContent = ActivityContent, U = any> =
    Omit<GranuleEntityAttributesInterface<ActivityReference<U, T>>, 'metadatas'>
    & {metadatas: ActivityMetaEntityAttributes}

export type ActivityGranule<T extends ActivityContent = ActivityContent, U = any> = TypedDataEntityInterface<ActivityGranuleAttributes<T, U>>

