import {Injectable} from '@angular/core';
import {CommunicationCenterService} from "@modules/communication-center";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {FuseConfirmDialogComponent} from "fuse-core/components/confirm-dialog/confirm-dialog.component";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class ActionsService {

    constructor(
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private translate: TranslateService,
    ) {
    }

    public do(actionString: string): void {
        const [subject, ...values] = actionString.split('/');

        switch (subject) {
            case 'dialog':
                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                    backdropClass: ['cdk-overlay-dark-backdrop', 'backdrop-blur'],
                    panelClass: ['onboard-dialog'],
                    data: {
                        bodyDialog: this.translate.instant(values[0]),
                        labelTrueDialog: this.translate.instant('generic.close'),
                    }
                });
                break;
            case 'filter':
            case 'navigate':
                this.communicationCenter.getRoom('contextualActions').next(subject, values);
                break;
            default:
                this.communicationCenter.getRoom('contextualActions').next(subject, values[0]);
        }
    }
}
