import { Component } from '@angular/core';

@Component({
  selector: 'app-generic-file-upload-interface',
  templateUrl: './generic-file-upload-interface.component.html',
  styleUrls: ['./generic-file-upload-interface.component.scss']
})
export class GenericFileUploadInterfaceComponent {

}
