import {Component, Input, OnInit} from '@angular/core';
import {CustomBlock} from 'fuse-core/components/collection/custom-blocks/custom-block.model';
import {BlockHandlerService} from 'fuse-core/components/collection/custom-blocks/block-handler.service';

@Component({
  selector: 'app-full-width-cta',
  templateUrl: './full-width-cta.component.html',
  styleUrls: ['./full-width-cta.component.scss']
})
export class FullWidthCtaComponent  {

    @Input() block: CustomBlock;

    constructor(
        public blockHandler: BlockHandlerService
    ) {}

}
