import {Injectable} from '@angular/core';
import {AssignationComponent} from '@modules/assignation/core/components/assignation/assignation.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {ContextualService} from '@modules/assignation/core/services/contextual.service';
import {AssignmentByStepsComponent} from '@modules/assignation/core/components/assignment-by-steps/assignment-by-steps.component';
import {AssignationConfigurationService} from '@modules/assignation/core/services/assignation-configuration.service';

@Injectable()
export class AssignmentComponentsService {
    private assignationComponentIsOpen = false;

    constructor(
        private contextualService: ContextualService,
        private communicationCenter: CommunicationCenterService,
        private assignationConfigurationService: AssignationConfigurationService,
    ) {
        this.communicationCenter
            .getRoom('assignment')
            .next('view', assignationConfigurationService.assignationModalComponentToUse() === 'AssignationComponent' ? AssignationComponent : AssignmentByStepsComponent);

        this.contextualService.conditionAssignmentDialogOpen$
            .subscribe((callback) => callback(this.assignationComponentIsOpen));
    }

    public setAssignationComponentOpened(): void {
        this.assignationComponentIsOpen = true;
        this.updateContextual();
    }

    public setAssignationComponentClosed(): void {
        this.assignationComponentIsOpen = false;
        this.updateContextual();
    }

    private updateContextual(): void {
        this.contextualService.onConditionUpdate();
    }
}
