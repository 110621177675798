// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
*
*   move to theme/default/modules/_corpus.scss
*
 */`, "",{"version":3,"sources":["webpack://./src/app/@modules/corpus/core/components/corpus-display/corpus-display.component.scss"],"names":[],"mappings":"AAAA;;;;EAAA","sourcesContent":["/*\n*\n*   move to theme/default/modules/_corpus.scss\n*\n */"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
