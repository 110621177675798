import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {MessagingService} from '@modules/messaging/core/messaging.service';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {RouterModule, Routes} from '@angular/router';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import { MessagingIndexComponent } from './components/messaging-index/messaging-index.component';
import { MessagingListComponent } from './components/messaging-list/messaging-list.component';
import { MessagingThreadComponent } from './components/messaging-thread/messaging-thread.component';
import {FuseSharedModule} from '@fuse/shared.module';
import {SharedModule} from 'app/shared/shared.module';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {LearnersByGroupByWorkingGroupPipe} from './pipes/leaners-by-group-by-working-group.pipe';
import { MessagingAddEditComponent } from './components/messaging-add-edit/messaging-add-edit.component';
import { LeanersByGroupByWorkingGroupsPipe } from './pipes/leaners-by-group-by-working-groups.pipe';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { DateReversedMessagesPipe } from './pipes/date-reversed-messages.pipe';
import { MessagingConfirmComponent } from './components/messaging-confirm/messaging-confirm.component';
import { GroupMessagesPipe } from './pipes/group-messages.pipe';

const routes: Routes = [
    {
        path: 'messaging',
        canActivate: [IsUserLogged],
        component: MessagingIndexComponent,
        data: {
            icon: 'chat_basic'
        }
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        CommonSharedModule,
        MatProgressBarModule,
        RouterModule.forChild(routes)
    ],
    declarations: [
        MessagingIndexComponent,
        MessagingListComponent,
        MessagingThreadComponent,
        LearnersByGroupByWorkingGroupPipe,
        MessagingAddEditComponent,
        LeanersByGroupByWorkingGroupsPipe,
        DateReversedMessagesPipe,
        MessagingConfirmComponent,
        GroupMessagesPipe,
    ],
    providers: [
        DatePipe,
    ]
})
export class MessagingModule {
    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private communicationCenter: CommunicationCenterService,
        private authService: AuthenticationService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    static forRoot(): ModuleWithProviders<MessagingModule> {
        return {
            ngModule: MessagingModule,
            providers: [
                MessagingService
            ]
        };
    }

    private postLogout(): void {
        MessagingModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'messaging');
    }

    private postAuthentication(): void {
        if (!MessagingModule.isMenuSet && this.authService.isManager() === false) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'messaging',
                'title': 'Discussions',
                'translate': 'navigation.messaging',
                'type': 'item',
                'icon': 'chat_basic',
                'url': '/messaging'
            });

            MessagingModule.isMenuSet = true;
        }
    }
}
