import {Component} from '@angular/core';
import {GraphHumanumService} from '../../services/graph-humanum.service';
import {
    GraphHumanumAuthorizationService
} from "@modules/graph-humanum/core/services/graph-humanum-authorization.service";

@Component({
    selector: 'app-multi-graph-view',
    templateUrl: './multi-graph-view.component.html',
})
export class MultiGraphViewComponent {
    constructor(
        private graphService: GraphHumanumService,
        private graphHumanumAuthorizationService: GraphHumanumAuthorizationService
    ) {
    }

    graphsAreAvailable(): boolean {
        return this.graphService.graphsAreAvailable();
    }

    public get rolesCanShowBannerInfo(): boolean {
        return this.graphHumanumAuthorizationService.rolesCanShowBannerInfo;
    }

}
