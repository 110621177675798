import {Component} from '@angular/core';
import {EMPTY, Observable, Subject} from 'rxjs';
import {ActivityGranule} from '../../models/activities/activity-granule.attributes';
import {BaseActivityComponent} from '../base-activity.component';
import {cloneDeep} from 'lodash-es';
import {FlashCardInterface} from 'fuse-core/components/flashcard/flash-card.interface';
import {ButtonComponentConfigInterface, FlashCardsContent, TypologyLabel} from '@modules/activities/core/models';

export type VoiceRecorderActivityGranule = ActivityGranule<FlashCardsContent[], unknown>;

@Component({
    selector: 'app-voice-recorder-activity',
    templateUrl: 'voice-recorder-activity.component.html'
})
export class VoiceRecorderActivityComponent extends BaseActivityComponent<VoiceRecorderActivityGranule> {
    public pauseIncomingAudio$ = new Subject<void>();
    public forceReset$ = new Subject<void>();
    public flashcard: FlashCardInterface;
    public isActivityIsDone = false;

    nextActivity(): void {
        this.pauseIncomingAudio$.next();
        this.doAction('next');
    }

    public activityIsDone($event: void): void {
        this.isActivityIsDone = true;
        if (this.isActivityEmbedded) {
            super.setFeedBackFromApi();
            this.isActivityEmbeddedDone = true;
        }
    }

    public actionFromFeedBack(button: ButtonComponentConfigInterface) {
        if (button.type === 'reset') {
            this.forceReset$.next();
        }
        super.onAction(button);
    }

    protected setContentData(
        attributes: VoiceRecorderActivityGranule['attributes']
    ): void {
        this.forceReset$.next();
        this.instructionAudio = attributes?.reference.instructionAudio;
        this.wordingAudio = attributes?.reference.wordingAudio;
        this.flashcard = attributes?.reference?.activity_content[0]?.flashcards?.map((flashcard) => {
            if ((<any>flashcard?.image) === '' || !flashcard?.image) {
                const flashcardDuplicated = cloneDeep(flashcard);
                flashcardDuplicated.image = <any> {
                    uri: '#',
                    title: ''
                };
                return flashcardDuplicated;
            }
            return flashcard;
        })[0];
        if (!this.flashcard) {
            throw new Error('A flashcard is necessary');
        }
        this.wording =
            (this.preview
                && !this.activitiesService.settings['hiddenFieldActivityPreview'].find((field) => field === 'wording'))
            || !this.preview
                ? attributes.reference.wording
                : '';
        if (this.isActivityEmbedded && this.activityType === TypologyLabel.recording) {
            super.addExceptionsForButtonsFromActivity([
                {
                    type: 'next',
                    display: false,
                    options: {
                        recording: {
                            display: {
                                case: 'force',
                                value: false
                            }
                        },
                    }
                }
            ]);
        }
    }

    public buttonClick(button): void {
        if (button.type === 'next') {
            this.isActivityIsDone = false;
        }
        this.onAction(button);
    }

    protected saveAnswer(): Observable<number[]> {
        return EMPTY;
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected getGrade(): { oldGrade: number; newGrade: number } {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}
