import { Component, OnInit } from '@angular/core';
import {
    GraphMathiaAuthorizationService
} from "@modules/graph-mathia/core/services/graph-mathia-authorization.service";

@Component({
  selector: 'app-single-graph-view',
  templateUrl: './single-graph-view.component.html',
})
export class SingleGraphViewComponent implements OnInit {

  constructor(
      private graphMathiaAuthorizationService: GraphMathiaAuthorizationService
  ) { }

  ngOnInit(): void {
  }

    public get rolesCanShowBannerInfo(): boolean {
        return this.graphMathiaAuthorizationService.rolesCanShowBannerInfo;
    }

}
