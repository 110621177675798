import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import {AccountManagementProviderService} from '@modules/account-management';
import {NewUser} from '@modules/account-management/core/model/new-user.model';
import {WidgetInstance} from 'friendly-challenge';  // Modifier le chemin en conséquence

@Injectable({
    providedIn: 'root'
})
export class CaptchaService {

    constructor(
        private recaptchaV3Service: ReCaptchaV3Service,
        public accountProvider: AccountManagementProviderService
    ) { }

    handleRecaptcha(newUser: NewUser, callback: (captchaOk: boolean, newUser: NewUser) => void): void {
        this.recaptchaV3Service.execute('registerForm')
            .subscribe((token) => {
                this.verifyToken(token, newUser, callback);
            });
    }

    handleFriendlyCaptcha(newUser: NewUser, friendlyCaptchaSolution: string, widget: WidgetInstance, callback: (captchaOk: boolean, newUser: NewUser) => void): void {
        if (friendlyCaptchaSolution) {
            this.verifyToken(friendlyCaptchaSolution, newUser, callback);
        } else {
            widget.start();
            this.verifyToken(friendlyCaptchaSolution, newUser, callback);
        }
    }

    verifyToken(token: string, newUser: NewUser, callback: (captchaOk: boolean, newUser: NewUser) => void): void {
        this.accountProvider.verifyToken(token).subscribe({
            next: (verifyResult) => {
                let captchaOk = false;
                // if score >= 0.7 (0 = bot -> 1 = human)
                // (google api is returning success: boolean, challenge_ts: string, hostname: string, score: number, action: string)
                if (verifyResult['data'][0]['score'] >= 0.7 || verifyResult['data'][0]['success'] === true) {
                    captchaOk = true;
                }

                callback(captchaOk, newUser);
            },
            error: () => {
                callback(false, newUser);
            }
        });
    }
}
