import {ChangeDetectionStrategy, Component} from '@angular/core';
import {IsNewUserService} from '@modules/achievement/core/is-new-user/is-new-user.service';
import {map} from 'rxjs/operators';
import {ConfigService, INavlink} from '@modules/achievement/core/config.service';
import {AuthenticationService} from '@modules/authentication';

@Component({
    templateUrl: './achievement.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AchievementComponent {
    public navLinks: INavlink;

    constructor(private isNewUserService: IsNewUserService, private configService: ConfigService,
                private authenticationService: AuthenticationService) {
        this.navLinks = this.configService.navLinks();
    }

    public get isNewUser(): boolean {
        return this.isNewUserService.isNewUser;
    }

    public navLinksToApply(): {
        path: string,
        keyTranslate: string
    }[] {
        if (this.authenticationService.isGAR()) {
            return this.navLinks.gar;
        } else {
            return this.navLinks.default;
        }
    }
}
