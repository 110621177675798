import {Directive, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';

/**
 * make a loader inside a button and hide or not in regard of params text and icons
 * example of use inside a button
 * disabled button during loading is made in html directive just push the loader inside
 * <button
 * [disabled]="loading"
 * [loading]="loading">
 * * other example hidding text and mat icon during loading
 *  <button
 * [disabled]="loading"
 * [loading]="loading" [hideIconWhenLoading]="false" [hideTextWhenLoading]="false">
 * this directive use css in the file _spinner.scss
 *
 * in form you can use this directive with this balise at begin of the form and end of it
 * for disabled all controls during loading
    <fieldset class="disabled-border-fieldset-when-loading" [disabled]="loading">
        ......form inside
    </fieldset>
 * all controls will be disabled automaticly (except mat-chip list and mat-select)
 * be carefull mat-select and mat-chip list must be manage manually
 */
@Directive({
    selector: '[appSpinner]'
})
export class SpinnerDirective implements OnChanges {
    // if loading is true in directive class spinner-button is add to component and it will be disabled
    @Input()
    @HostBinding('class.spinner-button')
    loading = false;

    // by default if component contain text it will be remove during loading
    // [hideTextWhenLoading] can be passed to let the text visible during loading
    private _isInputHideText = true;

    @Input()
    public get hideTextWhenLoading(): boolean {
        return this._isInputHideText;
    }

    public set hideTextWhenLoading(val: boolean) {
        this._isInputHideText = val;
        this.hideText = val && this.loading;
    }

    // class spinner-active-hide-text is pass to hide the text if option is active and if loading is true
    @HostBinding('class.spinner-active-hide-text')
    public hideText = false;

    // by default if component contain mat-icon it will be remove during loading
    // [hideIconWhenLoading]= false can be passed to let the icon visible during loading
    private _isHidingIconWhenLoading = true;

    @Input()
    public get hideIconWhenLoading(): boolean {
        return this._isHidingIconWhenLoading;
    }

    public set hideIconWhenLoading(val: boolean) {
        this._isHidingIconWhenLoading = val;
        this.hideIcon = val && this.loading;
    }

    // class spinner-active-hide-text is pass to hide the text if option is active and if loading is true
    @HostBinding('class.spinner-active-hide-icons')
    public hideIcon = false;

    constructor() {
    }

    /*
    listen the change of input to update the hide text status in regard of loading status and hide option
     */
    ngOnChanges(changes: SimpleChanges): void {
        this.hideText = this._isInputHideText && this.loading;
        this.hideIcon = this._isHidingIconWhenLoading && this.loading;
    }
}
