import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataEntity} from 'octopus-connect';
import {take} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';
import {IUserProgress} from '@modules/activities/core/my-progress/models/user-progress';
import {RewardService} from '@modules/activities/core/services/reward.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-my-progress',
    templateUrl: './my-progress.component.html'
})
export class MyProgressComponent implements AfterViewInit, OnDestroy {
    public userProgress: IUserProgress;
    public progressState = {
        progressBarValue: 0, // real value of user to convert in pourcentToShow waiting real data for now 80 by default
        currentScore: 0, // current user score // fot test use score 12 to have a reward
        scoreTodDo: 0, // score to do to win next item
        imageToWinUrl: '', // img of reward to win send by backend
        showStarAnimation: false, // animation under reward begin only a little after seting icone,
        assetType: '' // asset type
    };

    constructor(
        private router: Router,
        private communicationCenter: CommunicationCenterService,
        private rewardService: RewardService,
        private translate: TranslateService) {
        this.initialize();
    }

    ngAfterViewInit(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'position-top-lower-for-content');
    }

    ngOnDestroy(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'position-top-lower-for-content');
    }

    /**
     * initialize all data
     * @private
     */
    private initialize(): void {
        this.communicationCenter.getRoom('gamification').getSubject('loadAvatar').next(true);
        // get current level = score
        this.communicationCenter
            .getRoom('gamification')
            .getSubject('levelData')
            .pipe(take(1))
            .subscribe((data) => {
                this.progressState.currentScore = data.level;
                this.progressState.progressBarValue = data.progress ? data.progress : 0;
            });
        // get current progress of current user
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((currentUser: DataEntity) => {
                if (currentUser) {
                    this.rewardService.loadProgressionStat()
                        .pipe(take(1))
                        .subscribe(data => {
                            // get data for current user
                            this.userProgress = <IUserProgress>data.entities[0].get(currentUser.id.toString());
                            // set badge to win and score to do to win
                            if (data.entities[0].get(currentUser.id.toString()).nextBadge) {
                                this.progressState.imageToWinUrl = data.entities[0].get(currentUser.id.toString()).nextBadge.image;
                                this.progressState.scoreTodDo = data.entities[0].get(currentUser.id.toString()).nextBadge.level;
                                // get asset type translation if exists
                                let assetType;
                                if (data.entities[0].get(currentUser.id.toString()).nextBadge.type?.location_key
                                    && data.entities[0].get(currentUser.id.toString()).nextBadge.type.location_key !== '') {
                                    this.translate.get(data.entities[0].get(currentUser.id.toString()).nextBadge.type.location_key)
                                        .subscribe((translation: string) => assetType = translation);
                                } else {
                                    assetType = data.entities[0].get(currentUser.id.toString()).nextBadge.type?.name;
                                }
                                this.progressState.assetType = assetType;
                            }

                            setTimeout(() => {
                                this.progressState.showStarAnimation = true;
                            }, 2500);
                        });
                }
            });
    }
}
