import { Component, OnInit, HostBinding } from '@angular/core';
import { GamificationService } from '../gamification.service';
import { DataEntity } from 'octopus-connect';

@Component({
  selector: 'app-buy-popup',
  templateUrl: './buy-popup.component.html',
  styleUrls: ['./buy-popup.component.scss']
})
export class BuyPopupComponent implements OnInit {

  buttonLabel = 'Obtenir';
  status: 'buying'|'loading'|'error'|'success' = 'buying';

  @HostBinding('class.hidden')
  get isHidden(): boolean {
    return this.gamificationService.buyPopupInfo.hidden;
  }

  get buyPopupInfo(): {
    hidden: boolean;
    badge: DataEntity;
  } {
    return this.gamificationService.buyPopupInfo;
  }

  constructor(private gamificationService: GamificationService) { }

  ngOnInit() {
  }

  close() {
    this.gamificationService.closeBuyPopup();
    this.status = 'buying';
  }

  buy(): void {
    this.status = 'loading';
    this.gamificationService.buyBadge().then(res => {
      this.status = 'success';
      this.close();
    }).catch(err => {
      console.log('Error buying item', err);
      this.status = 'error';
    });
  }

}
