import {Component, ViewEncapsulation} from '@angular/core';
import {brand} from '../../../../../settings';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {ActivatedRoute, Params} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models/auto-unsubscribe-take-until.class';

@Component({
    selector: 'fuse-app-lessons-list',
    templateUrl: './lessons-list.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LessonsListComponent extends AutoUnsubscribeTakeUntilClass {

    public filters: {
        [key: string]: string;
    } = {};

    public creatorsRolesFilter: number[] = this.lessonsService.getAllowedRoleIdsForModelsCreation();

    constructor(
        public lessonsService: LessonsService,
        private activitiesService: ActivitiesService,
        private route: ActivatedRoute
    ) {
        super();
        this.initServices();
        this.initFilters();
        this.subscribeToRouteParams();
    }

    private initServices(): void {
        this.activitiesService.metadatasUsedForOverride = [];
        this.lessonsService.selectedTabIndex = this.lessonsService.settings.selectedTabIndex;
    }

    protected initFilters(): void {
        this.filters['titleFilter'] = this.route.snapshot.paramMap.get('title');
    }

    private subscribeToRouteParams(): void {
        this.route.queryParams
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(params => {
            this.updateFiltersFromParams(params);
        });
    }

    private updateFiltersFromParams(params: Params): void {
        if (params['usage']) {
            this.filters['usageFilter'] = params['usage'];
        } else {
            this.filters['usageFilter'] = '';
        }
        if (params['concepts']) {
            this.filters['conceptsFilter'] = params['concepts'];
        } else {
            this.filters['conceptsFilter'] = '';
        }
        if (params['notions']) {
            this.filters['notionsFilter'] = params['notions'];
        } else {
            this.filters['notionsFilter'] = '';
        }
        if (params['educationalLevel']) {
            this.filters['educationnalLevelFilter'] = params['educationalLevel'];
        }else {
            this.filters['educationnalLevelFilter'] = '';
        }
        if (params['skills']) {
            this.filters['searchSkillsFilter'] = params['skills'];
        }else {
            this.filters['searchSkillsFilter'] = '';
        }
        if (params['chapters']) {
            this.filters['chaptersFilter'] = params['chapters'];
        }else {
            this.filters['chaptersFilter'] = '';
        }
        if (params['title']) {
            this.filters['titleFilter'] = decodeURIComponent(params['title']).replace('+', ' ');
        } else {
            this.filters['titleFilter'] = '';
        }
        if (params['autocorrection']) {
            this.filters['searchAutocorrectionFilter'] = "1";
        } else {
            this.filters['searchAutocorrectionFilter'] = '';
        }
    }

}
