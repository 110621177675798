import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import * as _ from 'lodash-es';
import {INotepadFormOptions} from '@modules/notepad/core/notepad.service';

interface INotepadDataInterface {
    title: string;
    associatedLessonIds?: (string | number)[];
}

interface ModalDataInterface {
    defaultValues: INotepadDataInterface;
    options: INotepadFormOptions;
    saveNotepad: (data: INotepadDataInterface) => Observable<DataEntity>;
    forceAssociationOnSave: string | number;
}

@Component({
    selector: 'app-notepad-data-editor-modal',
    templateUrl: './notepad-data-editor-modal.component.html',
})
export class NotepadDataEditorModalComponent implements OnInit {
    /**
     * Define if the component is currently loaded for initialization
     */
    public isInitializing = true;
    /**
     * Define if the component is currently in saving (should be used for temporally disable component)
     */
    public isSaving = false;

    /**
     * Reactive form used to receive and control user inputs
     */
    public noteForm: UntypedFormGroup;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) private modalData: ModalDataInterface,
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        public selfDialogRef: MatDialogRef<NotepadDataEditorModalComponent>
    ) {
    }

    public get isValid(): boolean {
        return this.noteForm.valid;
    };

    ngOnInit(): void {
        this.noteForm = this.formBuilder.group({
            title: [_.get(this.modalData, 'defaultValues.title', ''), Validators.required],
        });

        this.isInitializing = false;
    }

    /**
     * Save the notepad. No need to know if it's a path or a creation.
     * @param value
     */
    public onSubmit(value: INotepadDataInterface): void {

        if (this.isValid === false) {
            return;
        }

        this.isSaving = true;
        this.noteForm.disable();

        const associatedLessonIds = _.get(this.modalData, 'defaultValues.associatedLessonIds') || [];

        if (!!this.modalData.forceAssociationOnSave) {
            associatedLessonIds.push(this.modalData.forceAssociationOnSave);
        }

        const notepadData = _.merge(value, {associatedLessonIds});

        this.modalData.saveNotepad(notepadData).pipe(
            tap((dataEntity) => {
                this.selfDialogRef.close(dataEntity);
            }),
            take(1)
        ).subscribe();
    }
}
