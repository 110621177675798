import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {FuseGroupsFormDialogComponent} from '@modules/groups-management/core/groups-listing/groups-form/groups-form.component';
import {UntypedFormControl} from '@angular/forms';
import {DataEntity} from 'octopus-connect';

@Component({
  selector: 'app-join-group',
  templateUrl: './join-group.component.html',
  styleUrls: ['./join-group.component.scss']
})
export class JoinGroupComponent implements OnInit {

   public controlCode: UntypedFormControl;
   private classInfo: any;
   private classEntity: DataEntity;

   public errorId: boolean;

   public finish: boolean;
   public state = 'start';

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
              public dialogRef: MatDialogRef<FuseGroupsFormDialogComponent>,
  ) {

  }

  ngOnInit(): any {
      this.controlCode = new UntypedFormControl('');
  }

    testCode(): void {

        if (!this.classExist) {
            this.data
                .loadClass(this.controlCode.value)
                .subscribe((entity) => {
                    if (entity.get('type') === '2') {
                        this.classEntity = entity;
                        this.setClassInfo = entity;
                        this.errorId = false;
                    } else {
                        this.errorId = true;
                    }
                }, (error) => {
                    this.errorId = true;
                });
            this.controlCode.disable();
        }
    }

    reset(): void {
      this.controlCode.setValue(null);
      this.controlCode.enable();
      this.classInfo = null;
      this.errorId = false;
      this.dialogRef.close();
    }

    public get classExist(): boolean {
        return (this.controlCode.value && this.controlCode.value !== '') && this.data.classExist(this.controlCode.value);
    }

    public get getClassInfo(): any {
        return this.classInfo;
    }

    public set setClassInfo(entity) {
        this.classInfo = {};
        this.classInfo['name'] = entity.get('label');
        if (entity.get('parents')[0]) {
            this.classInfo['institution'] = entity.get('parents')[0].label;
        }
    }

    public get disableTest(): boolean {
      return !this.controlCode.value || this.controlCode.value && this.controlCode.value === '';
    }

    public joinClass(): void {
      this.state = 'pending';
      this.data.joinClass(this.classEntity)
          .subscribe(() => {
              this.removeGroup(true);
              this.finish = true;
              this.state = 'end';
          }, (error) => {
                this.state = 'start';
          });
    }

    public get classError(): boolean {
      return (this.controlCode.value && this.controlCode.value !== '') && this.errorId;
    }

    public get titleGroup(): string {
      return ' ' + this.getClassInfo.name;
    }

    public get title(): string {
      if (this.finish && !this.classError) {
          return 'group-management.success_join_class';
      }
      return 'group-management.enter_code_class';
    }

    removeGroup(saveSuccess = null): void {
      if (saveSuccess) {
          this.data.removeClassFromList();
      } else {
          this.data.removeClassFromList(this.classEntity);
      }
    }

}
