import {Injectable} from '@angular/core';
import {
    GroupService,
    InstitutionGroupService,
    LearnerService,
    TrainerService,
    WorkgroupService
} from "@modules/groups-management";
import {LicensesService} from "@modules/groups-management/core/services/licenses.service";
import {OnboardingService} from "@modules/groups-management/core/onboarding/onboarding.service";
import {ContextualService} from "@modules/groups-management/core/services/contextual.service";
import {
    GroupsManagementComponentsService
} from "@modules/groups-management/core/services/groups-management-components.service";

@Injectable({
    providedIn: 'root'
})
export class ServicesInstantiatorService {

    constructor(
        private groupService: GroupService,
        private institutionGroupService: InstitutionGroupService,
        private learnerService: LearnerService,
        private trainerService: TrainerService,
        private workgroupService: WorkgroupService,
        private licensesService: LicensesService,
        private onboardingService: OnboardingService,
        private contextualActions: ContextualService,
        private groupsManagementComponentsService: GroupsManagementComponentsService,
    ) {
    }
}
