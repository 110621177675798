import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {DataSource} from '@angular/cdk/collections';
import {LearnerService} from '@modules/groups-management/core/services/learner.service';
import {UntypedFormGroup} from '@angular/forms';
import {GroupsManagementService} from '@modules/groups-management/core/services/groups-management.service';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {FuseGroupsFormDialogData} from '@modules/groups-management/core/groups-listing/groups-form/groups-form.component';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '../models/rules';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {EditUserComponent} from '@modules/groups-management/core/institution-management/institution-users/edit-user/edit-user.component';
import {filter, mergeMap} from 'rxjs/operators';
import {InstitutionUsersService} from '@modules/groups-management/core/services/institution-users-service/institution-users.service';
import {Group, Learner, Workgroup} from '@modules/groups-management/core/definitions';
import {filterType, GroupManagementConfigurationService, roleList} from '@modules/groups-management/core/services/group-management-configuration.service';
import {ActivatedRoute} from "@angular/router";

enum JoinUserToANewGroupOrWorkGroupNameOption {
    username = 'username',
    nickname = 'nickname',
    name = 'name',
    educationalLevel = 'educationalLevel',
}

type option = keyof typeof JoinUserToANewGroupOrWorkGroupNameOption;

type JoinUserToANewGroupOrWorkGroup = {
    [key in option]: string | number;
} & {
    id: string | number,
    workgroups: ({
        workgroupname: string;
    } | string)[];
    groups: ({
        groupname: string;
    } | string)[];
};

@Component({
    selector: 'app-learner',
    templateUrl: './learner.component.html',
    styleUrls: ['./learner.component.scss']
})
export class LearnerComponent implements OnInit, OnDestroy {
    // use to force filter on a classroom
    @Input() groupNameClassroomSelected = '';
    // use to force filter on a group
    @Input() groupNameSelected = '';
    // is used to hide filter when we use component inside group or workgroup row
    @Input() isAddingInlineLearnerRow = false;
    // todo to remove when next us will be done with add learner
    @Input() isAddingInlineLearnerRowAddButton = true;
    // is learner adding from group or workgroup instead of learner list
    @Input() isAddingLearnerFromGroupOrWorkgroup = false;

    public displayedFilters: string[] = []; // filter to show
    public displayedColumns = ['checkbox', 'avatar', 'username', 'groups', 'workgroups', 'buttons'];
    // use to show less columns when we show learner inside group or workgroup array
    public displayedColumnsInlineAdding = ['avatar', 'username', 'groups', 'workgroups', 'buttons'];
    public dataSource: LearnerDataSource | null;
    public customActions: FuseGroupsFormDialogData[];

    public newLearner: FuseGroupsFormDialogData = {
        data: {
            action: 'new',
            title: 'add_student' + this.garSuffix,
            fields: this.getCreateFields(),
            selects: {
                groups: () => this.learnerService.getGroups(),
                workgroups: () => this.learnerService.getWorkgroups(),
                learnersList: () => this.learnerService.getLearnerList(),
                educationalLevels: () => this.learnerService.getEducationalLevelList()
            },
            typeEntity: 'learner'
        },
        callback: (response) => this.newLearnerCallback(response),
        isAuthorized: (learner) => this.authorizationService.currentUserCan(SyncRules.CreateLearner, undefined, learner),
    };
    // exact same code than from adding new user except callback that only join it
    // used when we want to add an existing learner inside a group or workgroup from the
    // group or workgroup listing
    public joinLearnerToGroupsOrWorkgroups: FuseGroupsFormDialogData = {
        data: {
            action: 'new',
            title: 'join_student' + this.garSuffix,
            fields: this.getJoinFields(),
            selects: {
                groups: () => this.learnerService.getGroups(),
                workgroups: () => this.learnerService.getWorkgroups(),
                learnersList: () => this.learnerService.getLearnerList(),
                educationalLevels: () => this.learnerService.getEducationalLevelList()
            },
            typeEntity: 'learner'
        },
        callback: (response) => this.joinLearnerCallback(response),
        isAuthorized: (learner) => {
            let authorized = false;
            
            if (this.route.component.name === 'GroupComponent') {
                authorized = this.authorizationService.currentUserCan(SyncRules.AttachLearnerToGroup, undefined, learner);
            } else if (this.route.component.name === 'WorkgroupComponent') {
                authorized = this.authorizationService.currentUserCan(SyncRules.AttachLearnerToWorkgroup, undefined, learner);
            }
            
            return authorized;
        }
    };

    public editLearner: FuseGroupsFormDialogData = {
        data: {
            action: 'edit',
            title: 'edit_student',
            fields: this.getEditFields(),
            selects: {
                groups: () => this.learnerService.getGroups(),
                workgroups: () => this.learnerService.getWorkgroups(),
                learnersList: () => this.learnerService.getLearnerList(),
                educationalLevels: () => this.learnerService.getEducationalLevelList()
            },
            typeEntity: 'learner'
        },
        callback: (response) => this.editLearnerCallback(response),
        callbackWithMultiple: (response) => this.editLearnerMultipleCallback(response),
        isAuthorized: (learner) => {
            let authorized = this.authorizationService.currentUserCan(SyncRules.EditLearner, undefined, [learner]);
            
            if (!authorized) {
                if (this.route.component.name === 'GroupComponent') {
                    authorized = this.authorizationService.currentUserCan(SyncRules.AttachLearnerToGroup, undefined, learner);
                } else if (this.route.component.name === 'WorkgroupComponent') {
                    authorized = this.authorizationService.currentUserCan(SyncRules.AttachLearnerToWorkgroup, undefined, learner);
                }
            }
            
            return authorized;
        },
        assignGroups: (learner, assignOnly) => this.assignGroups(learner, assignOnly)
    };

    public deleteLearner: FuseGroupsFormDialogData = {
        data: {
            titleDialog: 'groups-management.title_remove',
            bodyDialog: 'groups-management.sure_remove_learner',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (learner) => this.deleteLearnerCallback(learner),
        callbackWithMultiple: (list) => this.deleteListCallback(list),
        isAuthorized: (learner) => this.authorizationService.currentUserCan(SyncRules.DeleteLearner, undefined, learner),
    };

    public showMethodsLicensing: any = {
        title: 'groups-management.licensing.title',
        views: [{'room': 'licensing', 'subject': 'licenses_content', 'params': {'uid': '{userid}'}}],
        actionsButton: [{'room': 'licensing', 'subject': 'licenses_add', 'params': {'uid': '{userid}'}}],
        styleClasses: 'w-80-p',
        rules: {'needSso': false}
    };

    constructor(
        private learnerService: LearnerService,
        private groupsManagementService: GroupsManagementService,
        private groupManagementConfigurationService: GroupManagementConfigurationService,
        private authService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private institutionUsersService: InstitutionUsersService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
    ) {
        try {
            this.displayedFilters = this.groupManagementConfigurationService.displayFilterByOriginAndRole(filterType.learners, roleList[this.authService.accessLevel]);
        } catch (ex) {
            console.error('erreur getting settings learner component 128 ' + ex);
        }
    }

    ngOnInit(): void {
        this.dataSource = new LearnerDataSource(this.learnerService);
        this.groupsManagementService.setHeaderTitle('groups-management.students');
        this.groupsManagementService.gettingStarted = this.groupsManagementService.settings.gettingStarted.learners;
        this.customActions = [];
        const actionToAdd = [];
        if (this.learnerService?.settingsLicensing?.visible) {
            actionToAdd.push({'show_licenses_method': {icon: 'bookmark', label: 'groups-management.list.title', data: this.showMethodsLicensing}});
        }
        actionToAdd.forEach(action => {
            Object.assign(this.customActions, action);
        });
        this.setColumnsByAccessLevel();

        this.learnerService.filterLearnerToshow(this.groupNameClassroomSelected, this.groupNameSelected);
    }

    ngOnDestroy(): void {
        // reset learner filtering on service when component is destroy
        this.learnerService.filterLearnerToshow('', '');
        this.groupsManagementService.gettingStarted = '';
    }
    
    private get garSuffix(): string {
        return this.authService.isGAR() ? '_gar' : '';
    }

    public newLearnerCallback(response: UntypedFormGroup): Observable<DataEntity> {
        if (response) {
            return this.learnerService.addLearner(response.getRawValue());
        }
    }

    /**
     * join an existing learner to a new group or workgroup
     * is used when we open learner list in a row of group or workgroup list to add an existing learner
     * @param response
     */
    public joinLearnerCallback(response: UntypedFormGroup): Observable<DataEntity> {
        if (response) {
            if (response.value.onlyJoinCurrentUserToGroupOrWorkgroup) {
                return this.joinUSerToANewGroupOrWorkGroup(response.value);
            }
        }
    }

    public editLearnerCallback(response: UntypedFormGroup): Observable<DataEntity> {
        if (response) {
            return this.learnerService.saveLearner(response.getRawValue());
        }
    }

    public editLearnerMultipleCallback(response): void {
        if (response && response.length) {
            const obsLearnersSaves: Observable<DataEntity>[] = response.map((data) => this.learnerService.saveLearner(data));
            combineLatest(obsLearnersSaves).subscribe();
        }
    }

    public deleteLearnerCallback(learner: any): Observable<boolean> {
        return this.learnerService.deleteLearner(learner);
    }

    public deleteListCallback(learners: any): void {
        for (const learner of learners) {
            this.learnerService.deleteLearner(learner);
        }
    }

    /**
     * check if learner component is use inside a row on group or workgroup array
     * this is use to show different columns when we are in row context
     */
    public isInLineAddingLearner(): boolean {
        return ((this.groupNameClassroomSelected && this.groupNameClassroomSelected !== '')
            ||
            (this.groupNameSelected && this.groupNameSelected !== ''));
    }

    /**
     * for moment the ux décide to use the same row to for add a new user or join an already user
     * we use a flag to separate case and when we are in join group or workgroup we save learner with the
     * new group or workgroup
     * @param response FormGroup contain groups or workgroup
     */
    private joinUSerToANewGroupOrWorkGroup(response: JoinUserToANewGroupOrWorkGroup): Observable<DataEntity> {
        const learnerToJoinToGroup = this.learnerService.getLearnerList().find(l =>
            response.id ? +l.id === +response.id : (l.username === (response.username || response.nickname || response.name)
                || l.nickname === (response.username || response.nickname || response.name)));

        const asGroupName = (obj, key: 'workgroupname' | 'groupname') => typeof obj === 'string' ? obj : obj[key];

        if (response.workgroups && response.workgroups.length > 0
            && !response.workgroups.some((group) => learnerToJoinToGroup.workgroups.includes(asGroupName(group, 'workgroupname')))) {
            learnerToJoinToGroup.workgroups.push(...response.workgroups.map((group) => asGroupName(group, 'workgroupname')));
        }
        // case of join a classroom (group)
        if (response.groups && response.groups.length > 0
            && !response.groups.some((group) => learnerToJoinToGroup.groups.includes(asGroupName(group, 'groupname')))) {
            learnerToJoinToGroup.groups.push(...response.groups.map((group) => asGroupName(group, 'groupname')));
        }
        
        if (response.educationalLevel) {
            learnerToJoinToGroup.educationalLevel = +response.educationalLevel;
        }
        
        return this.learnerService.saveLearner(learnerToJoinToGroup);
    }

    private setColumnsByAccessLevel(): void {
        this.displayedColumns = []; // force reinit
        this.displayedColumns = this.groupsManagementService.settings.learner.columns[this.authService.accessLevel] ?
            this.groupsManagementService.settings.trainer.columns[this.authService.accessLevel] : this.groupsManagementService.settings.learner.columns['default'];
    }

    private getValuesByRole(settings: { [role: string]: string[] }): string[] {
        const settingsByRole = settings[this.authService.accessLevel];

        if (!!settingsByRole) {
            return settingsByRole;
        }
        return settings['default'];
    }

    private getEditFields(): string[] {
        const fields = this.getValuesByRole(this.groupsManagementService.settings.learner.editFields).slice();
        
        if (!this.authorizationService.currentUserCan(SyncRules.EditLearner)) {
            fields.splice(fields.indexOf('nickname'), 1);
        }
        
        return fields;
    }

    private getCreateFields(): string[] {
        return this.getValuesByRole(this.groupsManagementService.settings.learner.createFields);
    }

    private getJoinFields(): string[] {
        return this.getValuesByRole(this.groupsManagementService.settings.learner.joinFields);
    }

    public assignGroups(user: Learner, assignOnly = false): void {
        const refDialog: MatDialogRef<EditUserComponent> = this.dialog.open(EditUserComponent, {
            data: {
                user: this.formatLearnerToAssignGroups(user),
                classes: this.learnerService.groupsList,
                workgroups: this.learnerService.workgroupsList,
                assignOnly,
                save: (data) => this.joinUSerToANewGroupOrWorkGroup(data)
            }
        });
        refDialog.afterClosed().subscribe();
    }

    private formatLearnerToAssignGroups(learner: Learner): {
        id: number,
        name: string,
        mail: string,
        og_user_node: {
            id: string,
            title: string,
            type: string
        }[],
        roles: { [key: string]: string },
        rolesInInstitution: any
    } {
        return {
            id: learner.id,
            name: learner.nickname,
            mail: learner.mail,
            og_user_node: [...this.learnerService.groupsList
                .filter((group: Group) => learner.groups.indexOf(group.groupname) > -1)
                .map((group) => ({
                    id: group.id.toString(),
                    title: group.groupname,
                    type: 'Class'
                })),
                ...this.learnerService.workgroupsList.filter((group: Workgroup) => learner.workgroups.indexOf(group.workgroupname) > -1)
                    .map((group) => ({
                        id: group.id.toString(),
                        title: group.workgroupname,
                        type: 'Group'
                    }))],
            roles: null,
            rolesInInstitution: null
        };
    }
}

export class LearnerDataSource extends DataSource<any> {
    constructor(private learnerService: LearnerService) {
        super();
    }

    connect(): Observable<any[]> {
        return this.learnerService.onLearnersChanged;
    }

    disconnect(): void {
    }
}
