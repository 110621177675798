// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.state {
  border-radius: 14px;
  border: 1px solid #FF787D;
  width: 106px;
  height: 28px;
}
.state.validated {
  color: #00E998;
  border-color: #00E998;
}
.state.correct {
  color: #DFB900;
  border-color: #DFB900;
}
.state.pending {
  color: white;
  border-color: #FF787D;
  background-color: #FF787D;
}
.state.closed {
  color: #979797;
  border-color: #979797;
}
.state mat-icon {
  display: inline-block;
}
.state .name {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/research-sheet/core/research-sheet/research-sheet-list/research-sheet-list-section-state/research-sheet-list-section-state.component.scss"],"names":[],"mappings":"AAAA;EAEE,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;AAAF;AAGE;EACE,cAAA;EACA,qBAAA;AADJ;AAGE;EACE,cAAA;EACA,qBAAA;AADJ;AAGE;EACE,YAAA;EACA,qBAAA;EACA,yBAAA;AADJ;AAGE;EACE,cAAA;EACA,qBAAA;AADJ;AAIE;EACE,qBAAA;AAFJ;AAKE;EACE,qBAAA;AAHJ","sourcesContent":[".state {\n  //float: right;\n  border-radius: 14px;\n  border: 1px solid #FF787D;\n  width: 106px;\n  height: 28px;\n  //padding: 4px;\n\n  &.validated {\n    color: #00E998;\n    border-color: #00E998;\n  }\n  &.correct {\n    color: #DFB900;\n    border-color: #DFB900;\n  }\n  &.pending {\n    color: white;\n    border-color: #FF787D;\n    background-color: #FF787D;\n  }\n  &.closed {\n    color: #979797;\n    border-color: #979797;\n  }\n\n  mat-icon {\n    display: inline-block;\n  }\n\n  .name {\n    display: inline-block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
