import {Component, Injectable} from '@angular/core';
import { CommunicationCenterService } from '@modules/communication-center';
import {ConfigService} from '@modules/gamification/core/config.service';
import {NewsInterface} from 'fuse-core/news/news.interface';
import {AvatarPagePopupComponent} from '@modules/gamification/core/onboarding/avatar-page-popup/avatar-page-popup.component';

const AVATAR_URL_REGEXP = /^\/avatars$/;

@Injectable({providedIn: 'root'})
export class OnboardingService {

    static avatarPagePopup: Partial<NewsInterface> = {
        id: 'avatarPagePopup',
        channel: {
            snackbar: {
                acceptedUrlRegex: AVATAR_URL_REGEXP
            }
        },
        component: AvatarPagePopupComponent as Component,
    };

    constructor(
        private config: ConfigService,
        private communicationCenter: CommunicationCenterService
    ) {
        if (this.config.isOnboardingEnabled()) {
            this.communicationCenter.getRoom('news').next('add', OnboardingService.avatarPagePopup);
        }
    }
}
