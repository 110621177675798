import {ActivatedRoute, Router} from '@angular/router';
import {
    CollectionOptionsInterface,
    DataEntity,
    OrderDirection,
} from 'octopus-connect';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';


@Component({
    selector: 'app-lesson-card',
    templateUrl: './lesson-card.component.html',
})
export class LessonCardComponent implements OnInit, OnDestroy {
    @Input() chapterId: string[] = [];
    @Input() assignmentsList: DataEntity[] = [];
    @Input() autoAssignmentsList: DataEntity[] = [];
    @Input() closedAssignmentsList: DataEntity[] = [];
    @Input() gradeId: number;
    @Input() params: { lesson: string, chapter: string };

    public assignmentsListId: number[] = [];
    public autoAssignmentsListId: number[] = [];
    public closedAssignmentsListId: number[] = [];
    public showSpinner = true;
    private optionsInterface: CollectionOptionsInterface;
    private unsubscribeInTakeUntil = new Subject<void>();
    public resources;

    constructor(
        public lessonsService: LessonsService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            if (params['gradeId']) {
                this.gradeId = params['gradeId'];
            }

            this.optionsInterface = {
                filter: {
                    chapters: this.chapterId.join(','),
                },
                page: 1,
                range: 50,
            };

            this.assignmentsListId = this.assignmentsList.map(
                (n) => n['attributes']['assignated_node'].id
            );
            this.autoAssignmentsListId = this.autoAssignmentsList.map(
                (n) => n['attributes']['assignated_node'].id
            );
            this.closedAssignmentsListId = this.closedAssignmentsList.map(
                (n) => n['attributes']['assignated_node'].id
            );

            this.getLessonsById(this.optionsInterface);
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private getLessonsById(
        optionsInterface: CollectionOptionsInterface
    ): Subscription {
        if (this.gradeId) {
            this.optionsInterface.filter.educationalLevel = this.gradeId;
        }

        return this.lessonsService
            .loadPaginatedLessons('all', [6], '', optionsInterface)
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(
                (resources: DataEntity[]) => {
                    // sort by created date desc
                    // TODO directly get sort from webservice

                    this.resources = resources.sort(
                        (a, b) =>
                            0 -
                            (a['attributes'].metadatas.changed >
                            b['attributes'].metadatas.changed
                                ? 1
                                : -1)
                    );

                    if (this.lessonsService.settings.showLessonWithNoSubTheme === false) {
                        this.resources = this.resources.filter(
                            (e) =>
                                e.get('metadatas').chapters.length > 1 &&
                                e
                                    .get('metadatas')
                                    .chapters.some((chapt) => chapt.id === this.chapterId[0]) &&
                                e
                                    .get('metadatas')
                                    .chapters.some((chapt) => chapt.id === this.chapterId[1])
                        );
                    } else {
                        this.resources = this.resources.filter(
                            (e) =>
                                e.get('metadatas').chapters.length >= 1 &&
                                e
                                    .get('metadatas')
                                    .chapters.some((chapt) => chapt.id === this.chapterId[0]) &&
                                e
                                    .get('metadatas')
                                    .chapters.some(
                                    (chapt) =>
                                        chapt.id === this.chapterId[1] ||
                                        this.chapterId.length === 1
                                )
                        );
                    }
                    this.showSpinner = false;

                    // open modal if lesson query param
                    for (const resource of this.resources) {
                        if (
                            this.params &&
                            this.params.lesson &&
                            this.params.lesson === resource.id
                        ) {
                            this.launchModal(
                                resource,
                                this.assignmentsListId.includes(resource.id),
                                this.autoAssignmentsListId.includes(resource.id),
                                this.closedAssignmentsListId.includes(resource.id)
                            );
                        }
                    }
                },
                (error) => {
                    throw error;
                },
                () => {
                }
            );
    }

    public launchModal(
        resource: DataEntity,
        isAssignment: boolean,
        isAutoAssignment: boolean,
        closedAssignment: boolean
    ): void {
        let dialogRef;
        if (isAutoAssignment || isAssignment || closedAssignment) {
            const assignmentList = isAssignment
                ? this.assignmentsList
                : [...this.autoAssignmentsList, ...this.closedAssignmentsList];
            const assignment = assignmentList.find(
                (as: DataEntity) => as.get('assignated_node').id === resource.id
            );
            dialogRef = this.lessonsService.openLessonModal(resource, assignment);
        } else {
            dialogRef = this.lessonsService.openLessonModal(resource);
        }
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                this.router.navigate(['.'], {
                    relativeTo: this.route,
                    queryParams: {
                        lesson: null,
                        chapter: null,
                    },
                    queryParamsHandling: 'merge',
                });
            }
        });
    }

    public pourcent(resourceId): number {
        const assignment = [
            ...this.autoAssignmentsList,
            ...this.assignmentsList,
            ...this.closedAssignmentsList,
        ].find((as: DataEntity) => as.get('assignated_node').id === resourceId);
        return assignment ? assignment.get('progress') : 0;
    }

    /**
     * use to return assignement comment if exist in regard of the id of assignment
     * @param resourceId current resourceId
     */
    public getAssignmentComment(resourceId: number): string {
        const assignment = this.assignmentsList.find(
            (as: DataEntity) => as.get('assignated_node').id === resourceId
        );
        return assignment ? assignment.get('comment') : '';
    }

    /**
     * use to return assignement comment if exist in regard of the id of assignment
     * @param resourceId current resourceId
     */
    public getAssignmentAssignatorName(resourceId: number): string {
        const assignment = this.assignmentsList.find(
            (as: DataEntity) => as.get('assignated_node').id === resourceId
        );
        return assignment && assignment.get('assignator')
            ? assignment.get('assignator').name
            : '';
    }

    /**
     * if assignator is a teacher we show a text for inform teacher of who assign it to her/him
     * @param resourceId
     */
    public notAutoAssignation(resourceId: number): boolean {
        const assignatedUser = this.assignmentsList.find(
            (as: DataEntity) => as.get('assignated_node').id === resourceId
        ).get('assignated_user');
        const assignator = this.assignmentsList.find(
            (as: DataEntity) => as.get('assignated_node').id === resourceId
        ).get('assignator');

        return assignator && assignatedUser && (assignator.uid !== assignatedUser.uid || assignator.name !== assignatedUser.name);
    }
}