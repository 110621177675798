// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.see-more {
  padding: 10px 20px;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/projects-management/core/widget/widget-corpus/widget-corpus.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;AACF","sourcesContent":[".see-more {\n  padding: 10px 20px;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
