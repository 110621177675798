import { NgModule, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AuthenticationService } from '@modules/authentication'; //
import { CommunicationCenterService } from '@modules/communication-center'; //
import { DynamicNavigationService } from '../../../navigation/dynamic-navigation.service'; //
import { DataEntity } from 'octopus-connect'; //
import { AgendaService } from './agenda.service'; //
import { RouterModule, Routes } from '@angular/router';
import { IsUserLogged } from '../../../guards/is-user-logged.class';
import { AgendaComponent } from './agenda/agenda.component';
import localeFr from '@angular/common/locales/fr';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventControlsComponent } from './event-controls/event-controls.component';
import { DialogConfirmDeleteComponent } from './dialog-confirm-delete/dialog-confirm-delete.component';
import { EventFiltersModule } from './event-filters/event-filters.module';
import { TranslateModule } from '@ngx-translate/core';
import { MembersSelectionModule } from './members-selection/members-selection.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {DialogEventComponent} from './dialog-event/dialog-event.component';
import { AgendaWeeklyComponent } from './widget/agenda-weekly/agenda-weekly.component';
import {SharedModule} from 'shared/shared.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {MembersFilterModule} from 'fuse-core/components/members-filter/members-filter.module';
import {defineFullCalendarElement} from '@fullcalendar/web-component';

registerLocaleData(localeFr);

const routes: Routes = [
  {
    path: 'agenda',
    canActivate: [IsUserLogged],
    component: AgendaComponent
  }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        EventFiltersModule,
        MembersSelectionModule,
        NgxMaterialTimepickerModule,
        TranslateModule,
        SharedModule,
        FuseSharedModule,
        MembersFilterModule
    ],
  declarations: [
    AgendaComponent,
    DialogEventComponent,
    EventControlsComponent,
    DialogConfirmDeleteComponent,
    AgendaWeeklyComponent
  ],
    exports: [
        AgendaWeeklyComponent,
        SharedModule
    ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: MAT_DIALOG_DATA, useValue: [] }
  ]
})
export class AgendaModule {
  private static isMenuSet = false;

  constructor(
    private dynamicNavigation: DynamicNavigationService,
    private communicationCenter: CommunicationCenterService,
    private authService: AuthenticationService //
  ) {
    this.communicationCenter
      .getRoom('authentication')
      .getSubject('userData')
      .subscribe((data: DataEntity) => {
        if (data) {
          this.postAuthentication();
        } else {
          this.postLogout();
        }
      });

    this.communicationCenter
      .getRoom('agenda')
      .next('agendaWeeklyComponent', AgendaWeeklyComponent);
  }

  static forRoot(): ModuleWithProviders<AgendaModule> {
    return {
      ngModule: AgendaModule,
      providers: [
        AgendaService
      ]
    };
  }

  private postLogout(): void {
    AgendaModule.isMenuSet = false;
    this.dynamicNavigation.clearMenuItem('level0', 'agenda');
  }

  private postAuthentication(): void {
    if (!AgendaModule.isMenuSet && this.authService.hasLevel(['learner', 'trainer', 'manager'])) {
      this.dynamicNavigation.registerModuleMenu('level0', {
        'id': 'agenda',
        'title': 'Agenda',
        'translate': 'navigation.agenda',
        'type': 'item',
        'icon': 'agenda',
        'url': '/agenda'
      });

      AgendaModule.isMenuSet = true;
    }
  }
}
