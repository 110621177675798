import {DynamicGraphFilters} from '@modules/graph-mathia/core/model/dynamic-graph-filters';
import * as moment from 'moment';

export const OwnProgressFilters: DynamicGraphFilters = {
    always: [{
        label: 'startDate',
        value: moment().add(-2, 'weeks').startOf('day').toDate()
    }, {
        label: 'endDate',
        value: moment().endOf('day').toDate()
    }],
    hidden: []
};
