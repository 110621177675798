import * as moment from 'moment';
import {GraphFilterInterface} from '@modules/graph-dysapp/core/model/graph-filter-interface';

export const ProgressFilters: GraphFilterInterface[] = [
    {
        label: 'startDate',
        value: moment().add(-2, 'weeks').startOf('day').toDate()
    }, {
        label: 'endDate',
        value: moment().endOf('day').toDate()
    }, {
        label: 'exerciseType',
        value: null,
    }, {
        label: 'learner',
        value: null,
    }
];
