import {tap, takeUntil} from 'rxjs/operators';
import {ChangeDetectorRef, Component, inject, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {defaultApiURL} from '../../../../../../settings';
import {Observable, Subject, combineLatest} from 'rxjs';
import {AuthenticationService} from '@modules/authentication';
import {TranslateService} from '@ngx-translate/core';
import {ActivitiesService} from '../../../activities.service';
import {DataCollection, DataEntity} from 'octopus-connect';
import {NgxFileDropEntry} from 'ngx-file-drop';
import {HttpClient} from '@angular/common/http';
import {AccountManagementProviderService} from '@modules/account-management';
import {CorpusDisplayWrapperComponent} from '../../../shared-components/corpus-display-wrapper/corpus-display-wrapper.component';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect/typed-data-entity.interface';
import {EducationalLevelService} from '../../../services/educational-level.service';
import {ThemesService} from '../../../themes/themes.service';
import {UsageEntity, UsagesService} from '@modules/activities/core/services/usages.service';
import {ChapterEntity} from 'fuse-core/services/chapters.service';

@Component({
    selector: 'app-edit-lesson-dialog',
    templateUrl: './edit-lesson-dialog.component.html'
})
export class EditLessonDialogComponent implements OnInit, OnDestroy {
    public entityForm: UntypedFormGroup;
    public action: string;
    public dialogTitle: string;
    public entity: { indexation?: { label: string }[] };
    public chips: { label: string }[];
    public methods: { id: number | string; label: string }[];
    public method: { name: string, code: string };
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    tagModified = false;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    private unsubscribeInTakeUntil = new Subject<void>();
    public levelsDataEntity: DataEntity[] = [];
    public skillsDataEntity: DataEntity[] = [];
    public difficultiesDataEntity: DataEntity[] = [];
    public usageDataEntity: UsageEntity[] = [];
    public assignation_typeDataEntity: DataEntity[] = [];
    public chaptersDataEntity: ChapterEntity[] = [];
    public themesDataEntity: DataEntity[] = [];
    public isLoading = true;
    public isFileFormatAllowed = true;
    public isFileFormatDocumentAllowed = true;

    /**
     * get image format allowed
     */
    get imageUploadFormatAllowed(): string [] {
        return this.activitiesService.settings['allowedThumbnailExtensions'];
    }

    /**
     * get allowed extenssion
     */
    get imageUploadExtenssion(): string {
        return this.activitiesService.settings['allowedThumbnailExtensions'].toString().replace(new RegExp('image/', 'g'), '');
    }

    /**
     * show the name of the file to upload
     */
    get currentThumbnail(): string {
        if (this.imageUploadUri && this.imageUploadUri.name) {
            return this.imageUploadUri.name;
        } else if (this.entityForm.get('thumbnail') && this.entityForm.get('thumbnail').value && this.entityForm.get('thumbnail').value.uri) {
            return this.entityForm.get('thumbnail').value.uri;
        } else {
            return 'generic.nofile';
        }
    }

    /**
     * show the name of the file to upload for pdf document
     */
    get currentDocument(): string {
        if (this.documentUploadUri && this.documentUploadUri.name) {
            return this.documentUploadUri.name;
        } else if (this.entityForm.get('files') && this.entityForm.get('files').value) {
            return this.entityForm.get('files').value[0].uri;
        } else {
            return 'generic.nofile';
        }
    }

    get fileType() {
        return FileType;
    }

    private imageUploadUri: File;
    public thumbnail: { id: number, title: string } = null;

    private _urlFileUpload: string = defaultApiURL + 'api/file-upload';

    private documentUploadUri: File;

    private usagesService = inject(UsagesService);
    constructor(
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<EditLessonDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: { action: string, item: DataEntity },
        private formBuilder: UntypedFormBuilder,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private activitiesService: ActivitiesService,
        private http: HttpClient,
        private accountManagementProvider: AccountManagementProviderService,
        private changeDetector: ChangeDetectorRef,
        private educationalLevelService: EducationalLevelService,
        private themesService: ThemesService,
        private authService: AuthenticationService,
    ) {

    }

    /**
     * set the list data needed
     */
    private setLists(): void {
        if (this.displayField('educationnalLevel') || this.displayField('educationalLevel') || this.displayField('target-age')) {
            this.setEducationnalLevelList();
        } else {
            this.isLoading = false;
        }

        if (this.displayField('skill')) {
            this.setSkillsList();
        } else {
            this.isLoading = false;
        }

        if (this.displayField('difficulty')) {
            this.setDifficultiesList();
        } else {
            this.isLoading = false;
        }

        if (this.displayField('usage')) {
            this.setUsageList();
        } else {
            this.isLoading = false;
        }

        if (this.displayField('assignation_type')) {
            this.setAssignationTypeList();
        } else {
            this.isLoading = false;
        }

        if (this.displayField('chapters')) {
            this.setChaptersList();
        } else {
            this.isLoading = false;
        }

        if (this.displayField('theme')) {
            this.setThemesList();
        } else {
            this.isLoading = false;
        }
    }

    /**
     * set the educationnal level list
     */
    private setEducationnalLevelList(): void {
        this.educationalLevelService
            .getEducationalLevels()
            .subscribe({
                next: (educationalLevelCollection) => {
                    this.levelsDataEntity = educationalLevelCollection.entities.slice();
            this.isLoading = false;
                },
                error: (err) => {
                    console.error(err);
            this.isLoading = false;
        }});
    }

    /**
     * set the skills list
     */
    private setSkillsList(): void {
        this.activitiesService.getSkills().subscribe({
            next: skills => {
            this.skillsDataEntity = skills;
            this.isLoading = false;
            }, error: err => {
                console.error(err);
            this.isLoading = false;
            }
        });
    }

    /**
     * set the difficulty list
     */
    private setDifficultiesList(): void {
        this.activitiesService.getDifficulties().subscribe({ next:difficulties => {
            this.difficultiesDataEntity = difficulties;
            this.isLoading = false;
        }, error : error => {
                console.error(error);
            this.isLoading = false;
        }});
    }

    /**
    * set the usage list
    */
    private setUsageList(): void {
        this.usagesService.getUsages().subscribe({ next: usageCollection => {
            this.usageDataEntity = usageCollection.entities.slice();
            this.isLoading = false;
        }, error : error => {
                console.error(error);
            this.isLoading = false;
        }});
    }

    /**
     * set the assignation type list
     */
    private setAssignationTypeList(): void {
        this.activitiesService.getAssignationTypes().subscribe({
            next: assignation_types => {
            this.assignation_typeDataEntity = assignation_types;
            this.isLoading = false;
            }, error: error => {
                console.error(error);
            this.isLoading = false;
            }
        });
    }

    /**
     * set the chapters list
     */
    private setChaptersList(): void {
        this.activitiesService.getChaptersTypes().subscribe({
            next: chapters => {
            this.chaptersDataEntity = chapters;
            this.isLoading = false;
            }, error: error => {
                console.error(error);
            this.isLoading = false;
            }
        });
    }

    /**
     * set the themes list
     */
    private setThemesList(): void {
        this.themesService.getThemes().subscribe({next: themes => {
            this.themesDataEntity = themes;
            this.isLoading = false;
        }, error: error => {
            console.error(error);
            this.isLoading = false;
        }});
    }

    initEditCopy(metadata): void {
        this.entity = metadata;
        this.chips = metadata.indexation;
        // on recup le premier élément (et le seul normalement)
        const licenseId = Array.isArray(metadata.licenseContent) && metadata.licenseContent.length > 0 ? metadata.licenseContent[0].id : metadata.licenseContent;
        if (this.methods.map(method => method.id).includes(licenseId)) {
            metadata.licenseContent = licenseId;
        } else {
            metadata.licenseContent = [];
        }
        this.entityForm = this.createEntityForm([
            'title',
            'educationalLevel',
            'description',
            'licenseContent',
            'indexation',
            'tagModified',
            'thumbnail',
            'files',
            'skills',
            'difficulty',
            'usage',
            'theme',
            'source-author',
            'chapters',
            'assignation_type'
        ]);
        // set value store in back
        this.entityForm.get('educationalLevel').setValue(metadata?.educationalLevel?.length ? metadata.educationalLevel[0].id : '');
        this.entityForm.get('chapters').setValue(metadata ? metadata.chapters.id : '');
        this.entityForm.get('assignation_type').setValue(metadata ? metadata.assignation_type.id : '');
        this.entityForm.get('difficulty').setValue(metadata ? metadata.difficulty.id : '');

        if (metadata && metadata.skills.length > 0) {
            const idSelected = metadata.skills.map(skill => skill.id);
            this.entityForm.get('skills').setValue(idSelected);
        }

        // only one theme and theme is store in granule not in metadata but manage in forms with all metadata
        const theme = this.data.item.get('theme');
        if (theme) {
            this.entityForm.get('theme').setValue(theme.id);
        }

        // set usage value if exists
        const usage = this.data.item.get('usage');
        if (usage && usage.length > 0){
            this.entityForm.get('usage').setValue(usage.map(u => u.id));
        }
    }

    ngOnInit(): void {
    this.imageUploadUri = null;
        this.setLists();

        this.action = this.data.action;
        this.dialogTitle = `activities.lesson.dialog_${this.action}`;
        this.entity = {};
        this.chips = [];
        const metadata = this.data.item ? this.data.item.get('metadatas') : null;
        let obs: Observable<DataCollection | object[]>;

        if (this.authenticationService.isManager()) {
            obs = this.activitiesService.getMethods().pipe(
                takeUntil(this.unsubscribeInTakeUntil),
                tap((globalMethods: DataCollection) => {
                    this.methods = globalMethods.entities.map(method => {
                        return {
                            id: method.id,
                            label: method.get('name')
                        };
                    });
                }));
        } else {
            obs = this.activitiesService.licensingMethods.pipe(
                takeUntil(this.unsubscribeInTakeUntil),
                tap((currentUserMethods: { id: number | string; label: string }[]) => {
                    this.methods = currentUserMethods;
                }));
        }

        obs.subscribe(() => {
            if (this.data.action === 'edit') {
                this.initEditCopy(metadata);
            } else if (this.data.action === 'copy') {
                this.translate.get('generic.copy_of').subscribe((translation: string) => metadata.title = translation + ' ' + metadata.title);
                this.initEditCopy(metadata);
            } else {
                this.entity.indexation = [];
                this.chips = this.entity.indexation;
                this.entityForm = this.createEntityForm([
                    'title',
                    'educationalLevel',
                    'description',
                    'licenseContent',
                    'indexation',
                    'tagModified',
                    'thumbnail',
                    'files',
                    'skills',
                    'difficulty',
                    'usage',
                    'theme',
                    'source-author',
                    'chapters',
                    'assignation_type'
                ]);
            }
        });
        this.tagModified = false; // init variable to false on modal load
    }

    createEntityForm(fields: string[]): UntypedFormGroup {
        const config = {};
        fields.forEach((field: string) => {
            if (Array.isArray(this.entity[field]) && this.entity[field].length > 0) {
                const onlyFirstElement = this.entity[field][0];
                if('label' in onlyFirstElement) {
                    config[field] = [onlyFirstElement.id+''];
                } else {
                    config[field] = [this.entity[field]];
                }
            } else {
            config[field] = [this.entity[field]];
            }

        });
        return this.formBuilder.group(config);
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim() && !!this.chips.find((e) => e.label === value)) {
                // add tag
                this.chips.push({label: value.trim()});
                this.tagModified = true;
                this.entityForm.get('tagModified').setValue(this.tagModified);
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(tag): void {
        const index = this.chips.indexOf(tag);

        if (index >= 0) {
            this.chips.splice(index, 1);
            this.tagModified = true;
            this.entityForm.get('tagModified').setValue(this.tagModified);
        }
    }

    public get isManager(): boolean {
        return this.authenticationService.isManager();
    }

    /**
     * show or not filter Field filter by role
     */
    public displayField(filterField: string): boolean {
        const role = this.authService.accessLevel;
        let lessonDialogFields = this.activitiesService.settings.lessonDialogFields[role];
        if (lessonDialogFields === undefined) {
            lessonDialogFields = this.activitiesService.settings.lessonDialogFields['default'];
        }
        return lessonDialogFields.filter(field => field === filterField).length > 0;
    }

    /**
     * define if field is required or not by role
     */
    displayRequiredField(name: string): boolean {
        const role = this.authService.accessLevel;
        let lessonDialogRequiredFields = this.activitiesService.settings.lessonDialogRequiredFields[role];
        if (lessonDialogRequiredFields === undefined) {
            lessonDialogRequiredFields = this.activitiesService.settings.lessonDialogRequiredFields['default'];
        }
        return lessonDialogRequiredFields.filter(field => field === name).length > 0;
    }

    /**
     * get the file drop in zone and set the uri of the file
     * @param files
     * @param idType
     */
    public dropped(files: NgxFileDropEntry[], idType: number): void {
        for (const droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as any;
                fileEntry.file((file: File) => {
                    if (idType === FileType.image) {
                        if (this.fileFormatAllowed(file.name) && !this.isSizeTooBig(file)) {
                            this.imageUploadUri = file;
                            this.isFileFormatAllowed = true;
                            // reset value selected for corpus if exist because we will use file instead
                            this.thumbnail = null;
                        } else {
                            this.isFileFormatAllowed = false;
                        }
                    }

                    if (idType === FileType.document) {
                        const extension = file.name.substr(file.name.lastIndexOf('.') + 1);
                        if (extension.toLowerCase() === 'pdf' && !this.isSizeTooBig(file)) {
                            this.documentUploadUri = file;
                            this.isFileFormatDocumentAllowed = true;
                        } else {
                            this.isFileFormatDocumentAllowed = false;
                        }

                    }
                });
            }
        }
    }

    /**
     * set the imageUri when file is not set by drag and drop
     * @param evt
     * @param idFileType
     */
    public onFileChanged(evt: Event, idFileType: number): void {
        // control to block user if change type manualy from type to other
        if (idFileType === FileType.image) {
            if (this.fileFormatAllowed(evt.target['files'][0].name) && !this.isSizeTooBig(evt.target['files'][0])) {
                this.imageUploadUri = evt.target['files'][0];
                this.isFileFormatAllowed = true;
                // reset value selected for corpus if exist because we will use file instead
                this.thumbnail = null;
            } else {
                this.isFileFormatAllowed = false;
            }
        }

        if (idFileType === FileType.document) {
            const extension = evt.target['files'][0].name.substr(evt.target['files'][0].name.lastIndexOf('.') + 1);
            if (extension.toLowerCase() === 'pdf' && !this.isSizeTooBig(evt.target['files'][0])) {
                this.documentUploadUri = evt.target['files'][0];
                this.isFileFormatDocumentAllowed = true;
            } else {
                this.isFileFormatDocumentAllowed = false;
            }
        }
    }

    /**
     * @param filePath : path of file to upload
     * to block upload exemple only .pdf
     */
    private fileFormatAllowed(filePath: string): boolean {
        const extension = filePath.substr(filePath.lastIndexOf('.') + 1);
        return this.imageUploadExtenssion.includes(extension.toLowerCase());
    }

    /**
     * is the file limit is respected in ko
     * @param e : file
     */
    private isSizeTooBig(e): boolean {
        const sizeMax = this.activitiesService.settings['maxSizeThumbnail'];
        return e.size > +sizeMax;
    }

    /**
     * @deprecated use MediaService.createMedia instead
     * upload file and return an observable with the id of the create element to link it in metadata
     * of current granule
     * @param file : file to upload
     */
    private uploadFile(file: File): Observable<{ id: string, filemime: File }> {
        const formData = new FormData();
        formData.append('file', file);
        this.changeDetector.detectChanges();
        return this.http.post<{ id: string, filemime: File }>(this._urlFileUpload, formData, {headers: {'access-token': this.accountManagementProvider.userAccessToken}});
    }

    /**
     * Close current modal
     * code to launch is not the same in regard of existing a doc or a thumbnail or both
     * parent will create or edit lesson after close
     */
    public close(): void {
        if (this.isTheCaseTosave(CaseToSave.document)) {
            // save only a document
            this.managePdfCrud();
        } else if (this.isTheCaseTosave(CaseToSave.thumbnail)) {
            // save only thumbnail (background card image)
            this.manageThumbnailCrud();
        } else if (this.isTheCaseTosave(CaseToSave.thumbnailCorpus)) {
            // only thumbnail but from corpus
            this.manageThumbnailCorpusCrud();
            this.closeModal();
        } else if (this.isTheCaseTosave(CaseToSave.documentAnThumbnailCorpus)) {
            // we have thumbnail but (not file) corpus and pdf document
            this.manageThumbnailCorpusCrud();
            this.managePdfCrud();
        } else if (this.isTheCaseTosave(CaseToSave.docAndThumbnail)) {
            // we have both file thumbnail and pdf document we need to wait both upload before closing
            this.manageThumbnailAndPdfCrud();
        } else {
            this.closeModal();
        }
    }

    /**
     * we had multiple possibility thumbnail and doc doc only thumbnail only thumbnail from file or corpus...
     * check wich case is the good one.
     */
    private isTheCaseTosave(idCase: number): boolean {
        // each case control
        const isThumbnail = (this.data.action === 'new' || this.data.action === 'edit') && (this.imageUploadUri !== null && this.imageUploadUri !== undefined);
        const isDocument = (this.data.action === 'new' || this.data.action === 'edit') && (this.documentUploadUri !== null && this.documentUploadUri !== undefined);
        const isPdfZoneAndThumbnailZoneAllowed = this.displayField('files') && this.displayField('thumbnail');
        const isDocumentZoneAllowed = this.displayField('files');
        const isThumbnailZoneAllowed = this.displayField('thumbnail');
        const isThumbnailCorpus = (this.data.action === 'new' || this.data.action === 'edit') && (this.thumbnail !== null && this.thumbnail !== undefined);
        // mix of possible case control
        switch (idCase) {
            case CaseToSave.docAndThumbnail:
                return isThumbnail && isDocument && isPdfZoneAndThumbnailZoneAllowed;
            case CaseToSave.document:
                return !isThumbnail && !isThumbnailCorpus && isDocument && isDocumentZoneAllowed;
            case CaseToSave.documentAnThumbnailCorpus:
                return !isThumbnail && isThumbnailCorpus && isDocument && isThumbnailZoneAllowed && isDocumentZoneAllowed;
            case  CaseToSave.thumbnail :
                return isThumbnail && !isDocument && isThumbnailZoneAllowed;
            case  CaseToSave.thumbnailCorpus:
                return !isThumbnail && isThumbnailCorpus && !isDocument && isThumbnailZoneAllowed;
            default:
                return false;
        }
    }

    /**
     * we have a doc and a thumbnail we will save both and get both id before closing madal
     */
    private manageThumbnailAndPdfCrud(): void {
        this.isLoading = true;
        const thumbnail = this.uploadFile(this.imageUploadUri);
        const document = this.uploadFile(this.documentUploadUri);
        // upload both files thumbnail and document
        combineLatest(thumbnail, document).subscribe(([thumb, doc]) => {
            // set value of background in form to save it in metadata
            this.entityForm.get('thumbnail').setValue(+thumb['data'][0][0]['id']);
            this.entityForm.get('files').setValue(+doc['data'][0][0]['id']);
            this.isLoading = false;
            this.closeModal();
        });
    }

    /**
     * save thumbnail if need before closing because we need id of file to save it in parent lesson granule
     */
    private manageThumbnailCrud(): void {
        this.isLoading = true;
        this.uploadFile(this.imageUploadUri).subscribe(res => {
            // set value of background in form to save it in metadata
            this.entityForm.get('thumbnail').setValue(+res['data'][0][0]['id']);
            this.isLoading = false;
            this.closeModal();
        });
    }

    /**
     * save thumbnail if need before closing because we need id of file to save it in parent lesson granule
     */
    private manageThumbnailCorpusCrud(): void {
        // add image selected in corpus
        this.entityForm.get('thumbnail').setValue(+this.thumbnail.id);
        this.isLoading = false;
    }

    /**
     * save pdf if need before closing because we need id of file to save it in parent lesson granule
     */
    private managePdfCrud(): void {
        this.isLoading = true;
        this.uploadFile(this.documentUploadUri).subscribe(res => {
            // set value of pdf in form to save it in metadata it's an array of of id bacause we could store multiple type of files
            this.entityForm.get('files').setValue(+res['data'][0][0]['id']);
            this.isLoading = false;
            this.closeModal();
        });
    }

    /**
     * close the modal and emit data of current forms to the parent
     */
    private closeModal(): void {
        const data = {form: this.entityForm, redirect: true};
        this.dialogRef.close(data);
    }

    /**
     * open the corpus and get the selected element in thumbnail field
     */
    public openCorpus(isMyCorpus: boolean): void {
        const data = {
            isMyCorpus: isMyCorpus
        };
        const dialogRef = this.dialog.open(CorpusDisplayWrapperComponent, {
            width: '90%',
            height: '100%',
            data
        });

        dialogRef.afterClosed().subscribe((thumbnail: DataEntity) => {


            if (thumbnail) {
                // we will load corpus image
                this.imageUploadUri = null;
                this.thumbnail = {id: thumbnail.get('reference').id, title: thumbnail.get('title')};
            } else {
                // reset value selected if one image was uploaded from computer
                if (this.imageUploadUri !== null) {
                    this.thumbnail = null;
                }
            }
        });
    }

    public localizedType(type: string): string {
        return `assignment.type.${type}`;
    }

    ngOnDestroy(): void {
        this.isLoading = false;
        this.imageUploadUri = null;
        this.isFileFormatAllowed = true;
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }
}

export enum FileType {
    image,
    document,
}

/**
 * each save case mix beetwen pdf doc and thumbnail
 */
export enum CaseToSave {
    docAndThumbnail,
    thumbnail,
    thumbnailCorpus,
    document,
    documentAnThumbnailCorpus
}
