import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';
import * as _ from 'lodash-es';

const settingsStructure: ModelSchema = new ModelSchema({
    avatarChoice: Structures.boolean(true),
    defaultActiveTab: Structures.string('accessories'),
    exportAvatar: Structures.boolean(false),
    isOnboardingEnabled: Structures.boolean(false),
    showRewards: Structures.boolean(false),
});

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private readonly privateSettings: { [key: string]: any };

    constructor() {
        this.privateSettings = settingsStructure.filterModel(modulesSettings.gamification);
    }

    public isAvatarChoiceEnabled(): boolean {
        return _.get(this.privateSettings, 'avatarChoice', false);
    }

    public isShowRewardsEnabled(): boolean {
        return _.get(this.privateSettings, 'showRewards', false);
    }

    public getDefaultActiveTab(): string {
        return _.get(this.privateSettings, 'defaultActiveTab', 'accessories');
    }

    public isExportAvatarEnabled(): boolean {
        return _.get(this.privateSettings, 'exportAvatar', false);
    }

    public isOnboardingEnabled(): boolean {
        return _.get(this.privateSettings, 'isOnboardingEnabled', false);
    }
}
