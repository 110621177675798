import {Component} from '@angular/core';
import {GraphMathiaService} from '../../services/graph-mathia.service';
import {
    GraphMathiaAuthorizationService
} from "@modules/graph-mathia/core/services/graph-mathia-authorization.service";

@Component({
    selector: 'app-multi-graph-view',
    templateUrl: './multi-graph-view.component.html',
})
export class MultiGraphViewComponent {
    constructor(
        private graphService: GraphMathiaService,
        private graphMathiaAuthorizationService: GraphMathiaAuthorizationService
    ) {
    }

    graphsAreAvailable(): boolean {
        return this.graphService.graphsAreAvailable();
    }

    public get rolesCanShowBannerInfo(): boolean {
        return this.graphMathiaAuthorizationService.rolesCanShowBannerInfo;
    }
}
