import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ChaptersSelectionComponent} from 'fuse-core/components/chapters-selection/chapters-selection.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {map, startWith, tap} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from '@angular/material/legacy-autocomplete';
import {Group} from '@modules/dashboard/core/definitions';
import {MatLegacyChipInputEvent as MatChipInputEvent} from '@angular/material/legacy-chips';
import {Workgroup} from '@modules/groups-management/core/definitions';
import {MatLegacySlideToggleChange as MatSlideToggleChange} from '@angular/material/legacy-slide-toggle';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '../../../models/rules';
import {Observable} from 'rxjs';
import {DataEntity} from 'octopus-connect';

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss']
})

export class EditUserComponent implements OnInit {
    @ViewChild('classesChipInput') classesChipInput: ElementRef;
    @ViewChild('workgroupsChipInput') workgroupsChipInput: ElementRef;

    public form: UntypedFormGroup;
    public controls: {
        name: UntypedFormControl,
        lastName: UntypedFormControl,
        mail: UntypedFormControl,
        classes: UntypedFormControl,
        workgroups: UntypedFormControl,
        password: UntypedFormControl,
        'teacher-rights': UntypedFormControl,
        'admin-rights': UntypedFormControl
    };
    public inputAddOnBlur = true;

    public classesChips = [];
    public inputSelectable = true;
    public inputRemovable = true;
    public classesFilteredChips;

    public workgroupsChips = [];
    public workgroupsFilteredChips;
    public separatorKeysCodes = [ENTER, COMMA];
    private errorFromApi: any; // erreur de l'api

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            user: { [key: string]: any }, classes: Group[],
            workgroups: Workgroup[],
            assignOnly: boolean,
            passwordPolicy?: {regex: any},
            modalTitle: string,
            save: (data) => Observable<DataEntity>
        },
        public dialogRef: MatDialogRef<ChaptersSelectionComponent>,
        private authorization: AuthorizationService
    ) {
    }

    ngOnInit(): void {
        this.initialise();
    }

    private initialise(): void {
        this.initFormControls();
        this.initFormControlSubscribes();
        this.classesChips = this.data.classes.slice()
            .filter((classItem) => this.data.user && this.data.user.og_user_node
                .some((classAssociated) => classAssociated.type === 'Class' && +classAssociated.id === +classItem.id));
        this.workgroupsChips = this.data.workgroups.slice()
            .filter((workgroupItem) => this.data.user && this.data.user.og_user_node
                .some((workgroupAssociated) => workgroupAssociated.type === 'Group' && +workgroupAssociated.id === +workgroupItem.id));
        this.classesFilteredChips = this.controls.classes.valueChanges.pipe(
            startWith(null),
            map((chip: any | null) => chip && typeof chip === 'string' ?
                this.distinctFilterForClasses(this.filterClasses(chip)) :
                this.distinctFilterForClasses(this.data.classes.slice())
            )
        );
        this.workgroupsFilteredChips = this.controls.workgroups.valueChanges.pipe(
            startWith(null),
            map((chip: any | null) => chip && typeof chip === 'string' ?
                this.distinctFilterForWorkgroups(this.filterWorkgroups(chip)) :
                this.distinctFilterForWorkgroups(this.data.workgroups.slice())
            )
        );
    }

    private filterClasses(classNameEntered: string): any[] {
        return this.data.classes.filter((classItem) =>
            classItem.groupname.toLowerCase().includes(classNameEntered.toLowerCase()));
    }

    private filterWorkgroups(workgroupNameEntered: string): any[] {
        return this.data.workgroups.filter((workgroupItem) =>
            workgroupItem.workgroupname.toLowerCase().includes(workgroupNameEntered.toLowerCase()));
    }

    private distinctFilterForClasses(classes): Group[] {
        return classes.filter(item => !this.classesChips.find((classItem) => classItem.id === item.id));
    }

    private distinctFilterForWorkgroups(workgroups): Group[] {
        return workgroups.filter(item => !this.workgroupsChips.find((workgroup) => workgroup.id === item.id));
    }

    public close(): void {
        this.dialogRef.close();
    }

    public save(): void {
        const values: { id?: number, name: string, firstName: string, lastName: string, mail: string, groups: any[], workgroups: any[], roles: any, password?: string } =
            {
                firstName: this.controls.name.value,
                lastName: this.controls.lastName.value,
                name: this.controls.name.value + this.controls.lastName.value,
                mail: this.controls.mail.value,
                groups: [...this.classesChips, ...this.workgroupsChips],
                workgroups: [...this.workgroupsChips], // needed if user is learner (cf joinUSerToANewGroupOrWorkGroup method)
                roles: {}
            };
        if (this.formControlValueByField('teacher-rights')) {
            values.roles['4'] = 'educator';
        }
        if (this.formControlValueByField('admin-rights')) {
            values.roles['5'] = 'manager';
        }
        if (!this.isNewUser) {
            values.id = this.data.user.id;
        } else {
            values.password = this.controls.password.value;
        }
        this.data.save(values).subscribe(() => {
                this.dialogRef.close(values);
            },
            errors => {
                this.errorFromApi = errors;
                if (this.errorFromApi?.data?.response?.title === 'account-management.registration_error') {
                    this.controls.mail.markAsTouched();
                    this.controls.mail.markAsDirty();
                    this.controls.mail.setErrors({alreadyTaken: true}, {emitEvent: false});
                }
            });
    }

    private initFormControlSubscribes(): void {
        // eslint-disable-next-line guard-for-in
        for (const field in this.controls) {
            this.controls[field].valueChanges.pipe(
                tap((val) => {
                    if (field === 'mail') {
                        if (this.controls.mail.errors?.alreadyTaken) {
                            this.controls.mail.setErrors(null);
                        }
                    }
                })
                // wip ici verifier si la valeur corresponds au format que l'on attends (cf formErrors)
            ).subscribe();
        }
    }

    public add(event: MatChipInputEvent, type): void {
        if (event && event.value) {
            if (type === 'classes') {
                const classSelected = this.data.classes.slice()
                        .find((classItem) => classItem.groupname.toLowerCase() === event.value.toLowerCase())
                    || this.distinctFilterForClasses(this.filterClasses(event.value))[0];

                if (classSelected && !this.classesChips.some((classItem) => classItem.id === classSelected.id)) {
                    this.classesChips.push(classSelected);
                    // Clear the input value
                    event.input.value = '';
                }
                this.controls.classes.setValue(null);
                this.classesChipInput.nativeElement.blur();
            } else {
                const workgroupSelected = this.data.workgroups.slice()
                        .find((workgroupItem) => workgroupItem.workgroupname.toLowerCase() === event.value.toLowerCase())
                    || this.distinctFilterForWorkgroups(this.filterWorkgroups(event.value))[0];

                if (workgroupSelected && !this.workgroupsChips.some((workgroupItem) => workgroupItem.id === workgroupSelected.id)) {
                    this.workgroupsChips.push(workgroupSelected);
                    event.input.value = '';
                }
                this.controls.workgroups.setValue(null);
                this.workgroupsChipInput.nativeElement.blur();
            }

        }
    }

    public classSelected(event: MatAutocompleteSelectedEvent): void {
        if (!this.classesChips.some((classItem) => classItem.id === event.option.value.id)) {
            this.classesChips.push(event.option.value);
        }
        this.controls.classes.setValue(null);
        this.classesChipInput.nativeElement.value = '';
        this.classesChipInput.nativeElement.blur();
    }

    public workgroupSelected(event: MatAutocompleteSelectedEvent): void {
        if (!this.workgroupsChips.some((workgroupItem) => workgroupItem.id === event.option.value.id)) {
            this.workgroupsChips.push(event.option.value);
        }
        this.controls.workgroups.setValue(null);
        this.workgroupsChipInput.nativeElement.value = '';
    }

    public removeClassChip(classChip: any): void {
        const index = this.classesChips.findIndex((classItem) => classItem.id === classChip.id);
        if (index >= 0) {
            this.classesChips.splice(index, 1);
            this.controls.classes.setValue(null);
        }
    }

    public removeWorkgroupChip(workgroupChip: any): void {
        const index = this.workgroupsChips.findIndex((workgroupItem) => workgroupItem.id === workgroupChip.id);
        if (index >= 0) {
            this.workgroupsChips.splice(index, 1);
            this.controls.workgroups.setValue(null);
        }
    }

    private initFormControls(): void {
        this.controls = {
            name: new UntypedFormControl({value: this.data.user && this.data.user.firstName || '', disabled: !this.isNewUser}),
            lastName: new UntypedFormControl({value: this.data.user && this.data.user.lastName || '', disabled: !this.isNewUser}),
            mail: new UntypedFormControl({value: this.data.user && this.data.user.mail || '', disabled: !this.isNewUser}, [Validators.email, Validators.required]),
            classes: new UntypedFormControl([]),
            workgroups: new UntypedFormControl([]),
            password: new UntypedFormControl(''),
            'teacher-rights': new UntypedFormControl(this.isUserHasEducatorRight()),
            'admin-rights': new UntypedFormControl(this.data.user && this.getRoleInInstitution(this.data.user.rolesInInstitution, 'manager') || null),
        };
    }

    /**
     * know if user have educator right
     * @private
     */
    private isUserHasEducatorRight(): boolean {
        if (this.data.user) {
            if (!this.getRoleInInstitution(this.data.user.rolesInInstitution, 'educator') &&
                !this.getRoleInInstitution(this.data.user.rolesInInstitution, 'manager')) {
                return true;
            }
            return this.getRoleInInstitution(this.data.user.rolesInInstitution, 'educator');
        }
        return true;
    }

    private getRoleInInstitution(roles: { [key: string]: string }, role): boolean {
        let isUserHasRole = false;
        if (roles) {
            for (const field in roles) {
                if (roles[field] === role) {
                    isUserHasRole = true;
                }
            }
        }
        return isUserHasRole;
    }

    public toggle(event: MatSlideToggleChange, role: string): void {
        if (role === 'educator') {
            this.controls['teacher-rights'].setValue(event.checked);
        }
        if (role === 'admin') {
            this.controls['admin-rights'].setValue(event.checked);
        }
    }

    public formControlValueByField(field: string): any {
        return this.controls && this.controls[field] && this.controls[field].value || null;
    }

    public get modalTitle(): string {
        return this.data.modalTitle ? this.data.modalTitle : null;
    }
    
    public get assignOnly(): boolean {
        return this.data.assignOnly;
    }

    public get isNewUser(): boolean {
        return !this.data.user;
    }

    public canEditEducatorRights(): boolean {
        return this.authorization.currentUserCan(SyncRules.EditTrainer);
    }

    public canEditInstitutionManagerRights(): boolean {
        return this.authorization.currentUserCan(SyncRules.EditTrainerInstitutionManagerTrainerRights);
    }

    public isAllowedToAssignToWorkGroup(): boolean {
        return this.authorization.currentUserCan(SyncRules.BeAttachedToWorkgroup);

    }

    public isAllowedToAssignToGroup(): boolean {
        return this.authorization.currentUserCan(SyncRules.BeAttachedToGroup);

    }

    /**
     * know when the button save is disabled
     */
    public get isButtonForSaveDisabled(): boolean {
        return !this.assignOnly && (this.isPasswordInValid() || (this.isNameInValid() || this.isNameInValid(true)) || this.isMailInValid() || this.isUserRightIsInValid());
    }

    private isPasswordInValid(): boolean {
        if (!this.data.user && this.controls && (!this.controls.password.value
            || this.controls.password.value === ''
            || this.data.passwordPolicy && !this.data.passwordPolicy.regex.test(this.controls.password.value))) {
            this.controls.password.setErrors({passwordPolicyError: true});
            return true;
        }
        this.controls.password.setErrors(null);
        return false;
    }

    public isNameInValid(isLastNameField?: boolean): boolean {
        const name = isLastNameField ? this.controls.lastName : this.controls.name;
        if (this.controls && name.value === '') {
            name.setErrors({invalid: true});
        }
        if (this.controls && name.errors && (name.errors.invalid || name.errors.required)) {
            return true;
        }
        name.setErrors(null);
        return false;
    }

    private isMailInValid(): boolean {
        return this.controls && (!this.controls.mail.value
            || this.controls.mail.value === ''
            || this.controls.mail.errors && (this.controls.mail.errors.email || this.controls.mail.errors.required));
    }

    public isUserRightIsInValid(): boolean {
        return this.controls && !this.controls['teacher-rights'].value && !this.controls['admin-rights'].value;
    }

    public get userName(): string {
        return !this.isNewUser ? ' ' + `${this.data.user.name}` : '';
    }
}
