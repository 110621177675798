import {NgModule} from '@angular/core';

import {KeysPipe} from './keys.pipe';
import {ReplaceTextPipe} from 'fuse-core/pipes/replace-text.pipe';
import {LoadTemplateDataPipe} from './load-template-data.pipe';
import {LoadTemplateComponentPipe} from './load-template-component.pipe';
import { CamelCasePipe } from './camel-case.pipe';
import { SnakeCasePipe } from './snake-case.pipe';

@NgModule({
    declarations: [
        KeysPipe,
        ReplaceTextPipe,
        LoadTemplateDataPipe,
        LoadTemplateComponentPipe,
        CamelCasePipe,
        SnakeCasePipe,
    ],
    imports: [],
    exports: [
        KeysPipe,
        ReplaceTextPipe,
        LoadTemplateDataPipe,
        LoadTemplateComponentPipe,
        CamelCasePipe,
        SnakeCasePipe,
    ],
    providers: [
        ReplaceTextPipe,
        LoadTemplateDataPipe,
        LoadTemplateComponentPipe,
    ]
})
export class FusePipesModule {
}
