import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-activity-edition-true-false',
    templateUrl: './activity-edition-true-false.component.html',
    styleUrls: ['./activity-edition-true-false.component.scss']
})
export class ActivityEditionTrueFalseComponent /*implements OnChanges*/ {
    @Input() control?: FormControl;
}
