export const authentication = {
    activeChangePasswordStrategy: true,
    validateEmailStrategyActivated: false,
    displayGARHelper: false,
    displayLoginLogo: true,
    enableGAR: true,
    enableSSO: false,
    firstConnexionRedirection: {
        trainer: '/groups/list/groups'
    },
    overrideDefaultRouteByRole: {
        learner: '/home',
        trainer: '/home',
    },
    licenceLink: 'https://www.catalogue.education/i-love-english-and-co/',// lien vers formulaire licence
    specificRedirectionPath: 'followed/tab'
};
