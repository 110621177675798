import {Observable, Observer} from 'rxjs';

import {getBase64Image} from 'shared/utils/base64-image/get-base64.image';

/**
 * Load the image, transform and return it as a base64 string
 * @param url
 */
export const getBase64ImageFromUrl = (url: string): Observable<string> => {
    return Observable.create((observer: Observer<string>) => {
        // create an image object
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = url;
        if (!img.complete) {
            // This will call another method that will create image from url
            img.onload = () => {
                observer.next(getBase64Image(img));
                observer.complete();
            };
            img.onerror = err => {
                observer.error(err);
            };
        } else {
            observer.next(getBase64Image(img));
            observer.complete();
        }
    });
};