import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsManagementComponent } from './projects-management.component';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { ProjectsFormComponent } from './projects-form/projects-form.component';
import { IsUserLogged } from '../../../guards/is-user-logged.class';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { ProjectsService } from '@modules/projects-management/core/projects.service';
import { DynamicNavigationService } from '../../../navigation/dynamic-navigation.service';
import { ProjectsDashboardComponent } from './projects-dashboard/projects-dashboard.component';
import { ProjectsMenuResolve } from '@modules/projects-management/core/projects-menu.resolve';
import { WidgetCorpusComponent } from './widget/widget-corpus/widget-corpus.component';
import {SharedModule} from 'shared/shared.module';
import {DashWidgetModule} from 'fuse-core/components/dash-widget/dash-widget.module';
import {ResearchSheetModule} from '@modules/research-sheet';
import {IdeasWallModule} from '@modules/ideas-wall';
import {AssignationModule} from '@modules/assignation';
import { WidgetResearchSheetComponent } from './widget/widget-research-sheet/widget-research-sheet.component';
import { WidgetCorpusSummaryComponent } from './widget/widget-corpus-summary/widget-corpus-summary.component';
import { WidgetSectionDraftComponent } from './widget/widget-section-draft/widget-section-draft.component';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {WidgetCorpusResearchSpaceComponent} from '@modules/projects-management/core/widget/widget-corpus-research-space/widget-corpus-research-space.component';
import {FullPageComponent} from 'fuse-core/components/basic-page/full-page/full-page.component';
import {WidgetFormsComponent} from './widget/widget-forms/widget-forms.component';
import {WidgetFormsAssignedComponent} from '@modules/projects-management/core/widget/widget-forms-assigned/widget-forms-assigned.component';
import {WidgetFormsClosedComponent} from '@modules/projects-management/core/widget/widget-forms-closed/widget-forms-closed.component';

const routes: Routes = [
    {
        path       : 'projects',
        canActivate: [IsUserLogged],
        children: [
            {
                path       : 'list',
                component  : ProjectsManagementComponent
            },
            {
                path       : ':projectId',
                resolve: {
                    menu: ProjectsMenuResolve
                },
                children: [
                    {
                        path: 'dashboard',
                        component  : ProjectsDashboardComponent,
                    },
                    {
                        path: 'tools',
                        children: [
                            {
                                path: '',
                                loadChildren: () => import('@modules/research-sheet/core/research-sheet.module').then(m => m.ResearchSheetModule)
                            },
                            {
                                path: '',
                                loadChildren: () => import('@modules/ideas-wall/core/ideas-wall.module').then(m => m.IdeasWallModule)

                            },
                            {
                                path: '',
                                loadChildren: () => import('@modules/assignation/core/assignation.module').then(m => m.AssignationModule)
                            },
                            {
                                path: '',
                                loadChildren: () => import('@modules/corpus').then(m => m.CorpusModule)
                            }
                        ]
                    },
                    {
                        path: 'page/:alias',
                        component: FullPageComponent,
                    },
                    {
                        path: '**',
                        pathMatch: 'full',
                        redirectTo: 'dashboard'
                    }
                ]
            },
            {
                path: '**',
                pathMatch: 'full',
                redirectTo: 'list'
            }
        ]
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedModule,
        DashWidgetModule
    ],
    declarations: [
        ProjectsManagementComponent,
        ProjectsListComponent,
        ProjectsFormComponent,
        ProjectsDashboardComponent,
        WidgetCorpusComponent,
        WidgetResearchSheetComponent,
        WidgetCorpusSummaryComponent,
        WidgetSectionDraftComponent,
        WidgetCorpusResearchSpaceComponent,
        WidgetFormsComponent,
        WidgetFormsAssignedComponent,
        WidgetFormsClosedComponent
    ],
    providers: [],
})
export class ProjectsManagementModule {
    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    static forRoot(): ModuleWithProviders<ProjectsManagementModule> {
        return {
            ngModule: ProjectsManagementModule,
            providers: [
                ProjectsService,
                ProjectsMenuResolve
            ]
        };
    }

    private postLogout(): void {
        ProjectsManagementModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'projectsManagement');
    }

    private postAuthentication(): void {
        if (!ProjectsManagementModule.isMenuSet && this.authService.isAuthenticated) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'projectsManagement',
                'title': 'Projects',
                'translate': 'navigation.projects-management',
                'type': 'item',
                'icon': 'projects',
                'url': '/projects'
            });

            ProjectsManagementModule.isMenuSet = true;
        }
    }
}
