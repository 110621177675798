import {Component, ComponentFactoryResolver, Inject, Injectable, ViewContainerRef} from '@angular/core';


@Injectable()
export class DynamicComponentLoaderService {
    private rootViewContainer: ViewContainerRef;

    constructor ( @Inject(ComponentFactoryResolver) private componentFacctoryResolver){

    }

    setRootViewContainerRef (viewContainerRef): void {
        this.rootViewContainer = viewContainerRef;
    }

    addComponentDynamically (componentToBeAdded, activityId?: string, componentLocalService?: any): void {
        const dynamicComponentFactory = this.componentFacctoryResolver.resolveComponentFactory(componentToBeAdded);
        const componentRef = dynamicComponentFactory.create(this.rootViewContainer);
        componentRef.instance.activityId = activityId;
        componentRef.instance.activityService = componentLocalService;
        this.rootViewContainer.insert(componentRef.hostView);
    }
}
