
import {take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, ReplaySubject} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {AuthenticationService} from '@modules/authentication';

@Injectable()
export class IsUserLogged  {

    userInformation: DataEntity;

    constructor(
        private connector: OctopusConnectService,
        private usersProvider: AccountManagementProviderService,
        private authService: AuthenticationService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

        const obs: Observable<DataEntity> = this.connector.authenticated('http');

        obs.pipe(take(1)).subscribe((userData: DataEntity) => {
            subject.next(true);
        }, () => {
            subject.next(false);

            this.authService.goToLogin(state);
        });

        return subject;
    }
}
