import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {fuseAnimations} from 'fuse-core/animations';
import {Router} from '@angular/router';

import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged, tap, filter, map, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Subscription, Subject, combineLatest} from 'rxjs';
import {CollectionOptionsInterface} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';

@Component({
    selector: 'fuse-app-lessons-list',
    templateUrl: './lessons-selection.component.html',
    styleUrls: ['./lessons-selection.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class LessonsSelectionComponent implements OnInit, OnDestroy {
    public displayedColumns = ['label'];
    public dataSource = new MatTableDataSource([]);
    public methods: any;
    public resources: DataEntity[];
    public tags: any;
    public entity: any = {};
    public activities: any = {};
    public type: string;
    public lessonsSelected: object[] = [];
    entityForm: UntypedFormGroup;
    public titleFilter: UntypedFormControl;
    public resourcesSubscription: Subscription;
    private optionsInterface: CollectionOptionsInterface;
    private unsubscribeInTakeUntil = new Subject<void>();
    pageIndex = 0;
    lessonsSelectedButton: boolean;
    private loadPaginatedLessons: any;

    public tagsChanged: BehaviorSubject<any> = new BehaviorSubject([]);


    constructor(
        public dialog: MatDialog,
        private router: Router,
        public dialogRef: MatDialogRef<LessonsSelectionComponent>,
        private communicationCenter: CommunicationCenterService,
        @Inject(MAT_DIALOG_DATA) private data: {
            activities: DataEntity[];
            currentUserRoles: number[];
            allowedRoleIdsForModelsCreation: number[];
        },
    ) {
        this.activities = data.activities;

        this.optionsInterface = {
            filter: {},
            page: 1,
            range: 12
        };

        const setSchoolYearFilter = this.communicationCenter.getRoom('lessons')
            .getSubject('schoolYearFilterValue')
            .pipe(
                filter((schoolYearFilterValue: boolean) => !!schoolYearFilterValue),
                tap((schoolYearFilterValue) => this.optionsInterface.filter['schoolyear'] = schoolYearFilterValue)
            );

        const setLoadPaginatedLessonsMethod = this.communicationCenter.getRoom('activities')
            .getSubject('loadPaginatedLessonsCallback')
            .pipe(
                tap((callback: any) => {
                    this.loadPaginatedLessons = callback;
                })
            );

        combineLatest(setSchoolYearFilter, setLoadPaginatedLessonsMethod).subscribe();

    }

    ngOnInit(): void {
        this.lessonsSelectedButton = false;
        this.resourcesSubscription = this.refreshList();
        this.titleFilter = new UntypedFormControl('');
        this.titleFilter.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.launchSearch();
            });

    }

    launchSearch(): void {
        this.resourcesSubscription.unsubscribe();
        this.resourcesSubscription = this.refreshList();
    }

    refreshList(): Subscription {
        const searchValue = this.titleFilter ? this.titleFilter.value : '';
        const userIsAllowedToCreateModels = this.data.allowedRoleIdsForModelsCreation.some(allowedId => this.data.currentUserRoles.includes(allowedId));
        this.type = userIsAllowedToCreateModels ? 'byRole' : 'currentUser';

        return this.resourcesSubscription = this.loadPaginatedLessons(this.type, this.data.allowedRoleIdsForModelsCreation, searchValue, this.optionsInterface)
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
                filter(resources => !!resources),
                // '0' = false, '1' = true. It's bad to filter in front but we cannot make this filter in back.
                map((resources: DataEntity[]) => resources.filter(r => r.get('locked') === '0')),
                tap((resources: DataEntity[])  => this.resources = resources)
            ).subscribe();
    }

    filter(name: string): Array<string> {
        return this.tags.filter(chip =>
            chip.name.toLowerCase().indexOf(name.trim().toLowerCase()) > -1);
    }

    resetTags(): void {
        this.lessonsSelected = [];
        if (this.titleFilter && !this.titleFilter.value) {
            this.dataSource.data = [];
        }
    }

    selectedLesson(event: object): void {
        const position = this.checkSelected(event);
        if (position > -1) {
            this.lessonsSelected.splice(position, 1);
            this.lessonsSelectedButton = false;
        } else {
            this.lessonsSelected = []; // empty object
            if (event['attributes'].locked !== '1') {
                this.lessonsSelected.push(event);
                this.lessonsSelectedButton = true;
            }
        }
    }

    checkSelected(entity): number {
        return this.lessonsSelected.findIndex(obj => entity['id'] === obj['id']);
    }

    ngOnDestroy(): void {

    }

}

