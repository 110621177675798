import { Component, OnInit } from '@angular/core';

@Component({
    selector   : 'corpus-main-sidenav',
    templateUrl: './corpus-main.component.html',
    styleUrls  : ['./corpus-main.component.scss']
})
export class CorpusMainSidenavComponent implements OnInit
{
    selected: any;

    constructor()
    {

    }

    ngOnInit()
    {
    }

}
