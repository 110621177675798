import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {DataCollection, OctopusConnectService} from 'octopus-connect';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentService {

  constructor(private octopusConnect: OctopusConnectService) { }

    searchEstablishmentNames(postalCode: string, establishmentName: string): Observable<DataCollection> {
        const queryParams: { postalCode?: string, name?: string } = {};

        if (postalCode) {
            queryParams.postalCode = postalCode;
        }

        if (establishmentName) {
            queryParams.name = '%' + establishmentName + '%';
        }

        return this.octopusConnect.loadCollection('establishments', queryParams).pipe(
            take(1)
        );
    }
}
