import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';

@Component({
    selector: 'fuse-section-media-learner',
    templateUrl: './section-media-learner.component.html',
    styleUrls: ['./section-media-learner.component.scss']
})
export class SectionMediaLearnerComponent implements OnInit {

    @Input('resource') resource: CorpusRessource;
    @Output('remove') remove: EventEmitter<void> = new EventEmitter<void>();


    constructor() { }

    removeResource(evt: MouseEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.remove.emit();
    }

    ngOnInit() {
    }

    get type() {
        let iconName = '';

        if (this.resource && this.resource.ressourceEntity) {
            const format = this.resource.ressourceEntity.get('format');

            if (format) {
                switch (format.label) {
                    case 'document':
                        iconName = 'document';
                        break;
                    case 'image':
                        iconName = 'photo';
                        break;
                    case 'url':
                        iconName = 'link';
                        break;
                    case 'video':
                        iconName = 'video';
                        break;
                }
            }
        }

        return iconName;
    }


}
