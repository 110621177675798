import * as _ from 'lodash-es';
import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from "octopus-model";
import {modulesSettings} from '../../../../settings';

const settingsStructureAuth: ModelSchema = new ModelSchema({
    displayLoginLogo: Structures.boolean(false),
    displayGARHelper: Structures.boolean(true),
    enableGAR: Structures.boolean(false),
    forceGAR: Structures.boolean(false),
    enableSSO: Structures.boolean(false),
    enableLocalCache: Structures.boolean(false),
    firstConnexionRedirection: Structures.object({}),
    forceSetEmail: Structures.boolean(false),
    floatLabelControl: Structures.string('auto'),
    // Occurs in login.component
    isRegisterLinkTop: Structures.boolean(false),
    logoutUrlSSO: Structures.object(),
    onlyLoginWithMail: Structures.boolean(false),
    overrideDefaultRouteByRole: Structures.object({}),
    urlSSO: Structures.object(),
    validateEmailStrategyActivated: Structures.boolean(false),
    extentedValidation: Structures.boolean(false),
    toolsAllowedBylevel: Structures.object({
        notepad: ['trainer', 'manager', 'administrator'],
        mindmap: ['trainer', 'manager', 'administrator']
    }),
    specificRedirectionPath: Structures.string(null),
});

const settingsStructureAccount: ModelSchema = new ModelSchema({
    selfSignup: Structures.boolean(true),
    redirectSignup: Structures.object({url:'', target:'_blank'}),
    signup: Structures.boolean(true),
});

interface AuthenticationSettings {
    displayGARHelper: boolean;
    enableLocalCache: boolean;
    selfSignup: boolean;
    signup: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationConfigurationService {
    /** @deprecated use a method here */
    public settings: AuthenticationSettings;
    private readonly privateSettings: AuthenticationSettings;

    constructor() {
        const settings = settingsStructureAuth.filterModel(modulesSettings.authentication) as AuthenticationSettings;
        Object.assign(settings, settingsStructureAccount.filterModel(modulesSettings.accountManagement));
        this.settings = this.privateSettings = settings;
    }

    public get isLocalCacheEnabled(): boolean {
        return _.get(this.privateSettings, 'enableLocalCache', false);
    }

    public get isSignupEnabled(): boolean {
        return _.get(this.privateSettings, 'signup', true);
    }
    public get isSelfSignupEnabled(): boolean {
        return _.get(this.privateSettings, 'selfSignup', true);
    }

    public get displayGARHelper(): boolean {
        return _.get(this.privateSettings, 'displayGARHelper', true);
    }

    public get specificRedirectionPath(): string {
        return _.get(this.privateSettings, 'specificRedirectionPath', null);
    }
}
