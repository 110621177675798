import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {combineLatest, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, mergeMap, startWith, take, tap} from 'rxjs/operators';
import {OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {LearnerInterface} from '../model/learner-interface';
import * as moment from 'moment';
import * as _ from 'lodash-es';
import {GraphFiltersValuesInterface} from '../model/graph-filters-values-interface';
import {ExerciseType} from '../model/exercise-type';
import {ProgressDataAttributeInterface} from '../model/progress-data-attribute-interface';
import {ProgressEndpointFilters} from '../model/progress-endpoint-filters';
import {TypedDataCollectionInterface} from 'shared/models/octopus-connect/typed-data-collection.interface';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect/typed-data-entity.interface';
import {ProgressFilters} from '../model/progress-filters';
import {AuthenticationService} from '@modules/authentication';

const SHARED_FILTERS = ['startDate', 'endDate', 'exerciseType'];

@Injectable({
    providedIn: 'root'
})
export class GraphDysappService {
    public filtersChanges = new ReplaySubject<{ raw: Partial<GraphFiltersValuesInterface>, optimised: Partial<GraphFiltersValuesInterface> }>(1);
    public forceFiltersValues = new Subject<void>();
    public dynamicFilters = ProgressFilters;
    public learners: LearnerInterface[] = [];
    public exerciseTypes: ExerciseType[] = [];
    public isReady = new ReplaySubject(1);
    public graphDataArePending = new ReplaySubject<void>(1);
    public currentUser: TypedDataEntityInterface<unknown>;

    private cacheFilters: Partial<GraphFiltersValuesInterface> = {};

    private cache: { progressRawData: TypedDataCollectionInterface<ProgressDataAttributeInterface> } = {
        progressRawData: null,
    };

    constructor(private router: Router,
                private authenticationService: AuthenticationService,
                private communicationCenter: CommunicationCenterService,
                private octopusConnect: OctopusConnectService) {
        this.isReady.next(false);
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((currentUser: TypedDataEntityInterface<unknown>) => {
                if (!!currentUser) {
                    this.postAuthentication(currentUser);
                    this.currentUser = currentUser;
                } else {
                    this.postLogout();
                    this.currentUser = undefined;
                }
            });
        this.filtersChanges.pipe(debounceTime(250)).subscribe(filters => {
            this.cacheFilters = _.merge(this.cacheFilters, filters.raw);
        });
    }

    /**
     * Transforme an object from something like user filters (inputs) to a format accepted by the endpoint 'Progress' ({@link loadProgressData})
     * @param filters
     */
    static toProgressEndpointFriendlyFilters(filters: Partial<GraphFiltersValuesInterface>): ProgressEndpointFilters {
        return !filters ? undefined : new ProgressEndpointFilters(
            moment(filters.startDate).unix(),
            moment(filters.endDate).unix(),
            filters.exerciseType,
            +filters.learner
        );
    }

    /**
     * Return the data used to generate a 'progress' graph. If filters changed, the observable is refresh
     * @remarks, use a custom cache to avoid useless request.
     */
    public getProgressGraphData(): Observable<TypedDataCollectionInterface<ProgressDataAttributeInterface>> {
        const isSame = (a: Partial<GraphFiltersValuesInterface>, b: Partial<GraphFiltersValuesInterface>) =>
            a.exerciseType === b.exerciseType
            && (!this.authenticationService.isLearner() && a.learner === b.learner)
            && a.endDate.toString() === b.endDate.toString()
            && a.startDate.toString() === b.startDate.toString();

        const isSameWithLearner = (a: Partial<GraphFiltersValuesInterface>, b: Partial<GraphFiltersValuesInterface>) =>
            isSame(a, b) && a.learner === b.learner;

        const isValid = (a: Partial<GraphFiltersValuesInterface>) =>
            !!a.startDate
            && !!a.endDate
            && !!a.exerciseType;

        const isValidWithLearner = (a: Partial<GraphFiltersValuesInterface>) =>
            isValid(a) && !!a.learner;

        this.resetDynamicFiltersForProgressGraph();

        // @ts-ignore
        return this.filtersChanges.pipe(
            debounceTime(1000),
            map(f => f.optimised),
            filter(f => {
                if (this.authenticationService.isLearner()) {
                    return isValid(f);
                } else {
                    return isValidWithLearner(f);
                }
            }),
            distinctUntilChanged((a, b) => {
                if (this.authenticationService.isLearner()) {
                    return isSame(a, b);
                } else {
                    return isSameWithLearner(a, b);
                }
            }),
            tap(() => this.graphDataArePending.next()),
            mergeMap(graphFilters => this.getProgressDataIfNeeded(graphFilters, this.cache.progressRawData).pipe(
                tap(progressData => this.cache.progressRawData = progressData)
            ))
        );
    }

    /**
     * Return the list of the learner sorted by nicknames
     */
    public getLearnersAlphabetically(): LearnerInterface[] {
        return this.learners.sort((a, b) => a.nickname.localeCompare(b.nickname, [], {numeric: false}));
    }

    /**
     * store the learner selected on level graph to use it on progress graph when open it directly from level graph
     * @param learnerId : id of learner
     */
    public storeLearnerSelectedInCacheFilter(learnerId: string): void {
        const currentLearner = <any>this.learners.filter(l => l.id.toString() === learnerId)[0];
        this.cacheFilters.learner = currentLearner.username;
    }

    /**
     * take the learner in cache and put it inside the dynamic filter used to init filter
     * use to set filter in progress of the learner we click previously in level graph
     */
    public setLearnerDynamicFilterWithCacheFilter(): void {
        // get learner stored in cache to inject the same
        if (!!this.cacheFilters.learner) {
            const learnerFilter = this.dynamicFilters.find(f => f.label === 'learner');
            if (!!learnerFilter) {
                learnerFilter.value = this.cacheFilters.learner;
            }
        }
    }

    private postAuthentication(_currentUser: TypedDataEntityInterface<unknown>): void {
        combineLatest([
            this.initLearners(),
            this.initExerciseTypes(),
        ])
            .subscribe(() => {
                this.isReady.next(true);
            });
    }

    private postLogout(): void {
        this.learners = [];
        this.exerciseTypes = [];
        this.cache.progressRawData = null;
        this.isReady.next(false);
    }

    private resetDynamicFiltersForProgressGraph(): void {
        // Set default values
        const learner = this.getFirstLearnerAlphabetically();
        const exerciseType = this.getFirstExerciseTypeAlphabetically();
        this.dynamicFilters = _.clone(ProgressFilters);
        if (!!learner) {
            this.dynamicFilters.find(f => f.label === 'learner').value = learner.nickname;
        }
        if (!!exerciseType) {
            this.dynamicFilters.find(f => f.label === 'exerciseType').value = exerciseType.attributes.label;
        }

        // But erase it with cache filters
        this.setCachedFilters();
        this.forceFiltersValues.next();
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    private loadProgressData(filter: ProgressEndpointFilters): Observable<TypedDataCollectionInterface<ProgressDataAttributeInterface>> {
        // Fix pour gérer la vue learner (par défaut un learner n'a pas accès aux données des autres
        if (this.authenticationService.isLearner()) {
            filter.learner = +this.currentUser.id;
        }

        return this.octopusConnect
            .paginatedLoadCollection('statements-stats', {filter})
            .collectionObservable as Observable<TypedDataCollectionInterface<ProgressDataAttributeInterface>>;
    }

    private getProgressDataIfNeeded(
        graphFilters: Partial<GraphFiltersValuesInterface>,
        cache: TypedDataCollectionInterface<ProgressDataAttributeInterface> = null
    ): Observable<TypedDataCollectionInterface<ProgressDataAttributeInterface>> {
        const newFilters = GraphDysappService.toProgressEndpointFriendlyFilters(graphFilters);
        const oldFilters = GraphDysappService.toProgressEndpointFriendlyFilters(_.get(cache, 'graphFilters'));

        let obs: Observable<TypedDataCollectionInterface<ProgressDataAttributeInterface>>;
        if (!!oldFilters && newFilters.isSame(oldFilters)) {
            const cloned = _.clone(cache);
            obs = of(cloned);
        } else {
            obs = this.loadProgressData(newFilters).pipe(
                take(1),
            );
        }

        return obs;
    }

    private getFirstLearnerAlphabetically(): LearnerInterface {
        return this.getLearnersAlphabetically()[0];
    }

    private getFirstExerciseTypeAlphabetically(): ExerciseType {
        return this.exerciseTypes.sort((a, b) => a.attributes.label.localeCompare(b.attributes.label, [], {numeric: false}))[0];
    }

    private initLearners(): Observable<void> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList')
            .pipe(
                startWith([]),
                filter((learners) => learners !== null),
                map(list => this.learners = list),
                tap(() => this.forceFiltersValues.next()) // ¨Fix la liste vide d'élève en rechargeant la page d'un graph.
            );
    }

    private initExerciseTypes(): Observable<void> {
        return this.communicationCenter
            .getRoom('activities')
            .getSubject('activityTypes')
            .pipe(
                map(list => this.exerciseTypes = list)
            );
    }

    private setCachedFilters(...limitFilterList: string[]): void {
        const filters = limitFilterList.length > 0
            ? this.dynamicFilters.filter(f => limitFilterList.includes(f.label))
            : this.dynamicFilters;

        filters.forEach(graphFilter => {
            if (SHARED_FILTERS.includes(graphFilter.label) && !!this.cacheFilters[graphFilter.label]) {
                graphFilter.value = this.cacheFilters[graphFilter.label];
            }
        });
    }
}
