import {Injectable} from '@angular/core';
import {CommunicationCenterService} from "@modules/communication-center";
import {ChooseGroupComponent} from "@modules/groups-management/core/choose-group/choose-group.component";

@Injectable({
    providedIn: 'root'
})
export class GroupsManagementComponentsService {

    constructor(
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('group')
            .next('chooseGroup', ChooseGroupComponent);
    }
}
