import {Directive, EventEmitter, OnInit, Output} from '@angular/core';

@Directive({
    selector: '[appOnCreate]'
})
export class OnCreateDirective implements OnInit {

    @Output() appOnCreate: EventEmitter<any> = new EventEmitter<any>();

    @Output() reset = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit() {
        this.reset.emit();
    }

}
