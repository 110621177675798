import {Component, OnInit} from '@angular/core';
import {AssignationService} from '../../services/assignation.service';
import {AssignationConfigurationService} from '@modules/assignation/core/services/assignation-configuration.service';
import {cloneDeep} from 'lodash-es';
import {ActivatedRoute, IsActiveMatchOptions, NavigationEnd, Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {ContextualService} from "@modules/assignation/core/services/contextual.service";
import {filter, takeUntil} from "rxjs/operators";
import {AutoUnsubscribeTakeUntilClass} from "shared/models/auto-unsubscribe-take-until.class";
import {AssignationRoutes} from "@modules/assignation/core/assignation.routes";

@Component({
    selector: 'app-followed-tabs',
    templateUrl: './followed-tabs.component.html',
    styleUrls: ['./followed-tabs.component.scss']
})

/**
 * component use to wrap different followed component into tabs
 */
export class FollowedTabsComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    public isSelfAssignmentsActive: boolean;
    public isAssignationGroupsActive: boolean;
    public isAssignationGroupsDetailActive: boolean;
    public isAssignationClosedActive: boolean;
    public isUserSaveListActive: boolean;
    public matTabs: string[] = [];
    public selected = new FormControl(0);
    public learnerId: string;
    private links: {[label: string]: string};

    constructor(
        private assignationService: AssignationService,
        private config: AssignationConfigurationService,
        private contextualService: ContextualService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {
        super();

        this.links = {
            'assignation.self_assignments_list': '/followed/tab/' + AssignationRoutes.Self,
            'assignment.followed_data': '/followed/tab/' + AssignationRoutes.List,
            'assignment.followed_data_trainer': '/followed/tab/' + AssignationRoutes.List,
            'assignment.followed_data_closed': '/followed/tab/' + AssignationRoutes.Closed,
            'assignation.usersaves-list': '/followed/tab/' + AssignationRoutes.UserSaves,
            'assignation.groups-list': '/followed/tab/' + AssignationRoutes.GroupsList,
            'assignation.groups-list-detail': '/followed/tab/' + AssignationRoutes.GroupsListDetail,
            'assignment.followed_diary': '/followed/tab/' + AssignationRoutes.LogBook,
            'assignment.followed_diary_trainer': '/followed/tab/' + AssignationRoutes.LogBook,
        };
    }

    public get translationTabLabelList(): string {
        return this.assignationService.translationTabLabelListByRole();
    }

    public get translationTabLabelLogBook(): string {
        return this.assignationService.translationTabLabelLogBookByRole();
    }

    ngOnInit(): void {
        this.setupTabs();
    }

    private setupTabs(): void {
        this.isSelfAssignmentsActive = this.config.isSelfAssignmentListActive();
        this.isAssignationGroupsActive = this.config.isAssignationGroupsActive();
        this.isAssignationGroupsDetailActive = this.config.isAssignationGroupsDetailActive();
        this.isAssignationClosedActive = this.config.isAssignationClosedActive();
        this.isUserSaveListActive = this.config.isUserSaveListActive();

        let tabs: string[] = cloneDeep(this.config.followedTabsList());

        const activeTabs = {
            'assignation.self_assignments_list': this.isSelfAssignmentsActive,
            'assignation.groups-list': this.isAssignationGroupsActive,
            'assignation.groups-list-detail': this.isAssignationGroupsDetailActive,
            'assignation.usersaves-list': this.isUserSaveListActive,
            'assignment.followed_data_closed': this.isAssignationClosedActive
        };

        tabs = tabs.filter(tab => {
            if (tab in activeTabs) {
                return activeTabs[tab];
            }
            return true;
        }).map(tab => {
            if (!(tab in activeTabs)) {
                return this[tab];
            }
            return tab;
        });

        this.matTabs = tabs;
        this.checkAndRedirectToDefaultTab(this.router.url);

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this.unsubscribeInTakeUntil)
            )
            .subscribe((event: NavigationEnd) => {
                this.checkAndRedirectToDefaultTab(event.url);
            });
    }

    public isActive(label: string): boolean {
        return this.router.isActive(this.getLink(label), {
            matrixParams: 'ignored',
            queryParams: 'ignored',
            paths: 'exact',
            fragment: 'ignored'
        });
    }

    public getLink(label: string): string {
        return this.links[label] || '';
    }

    private getLabels(url: string): string[] {
        let labels: string[] = [];

        for (let label in this.links) {
            if (this.links[label] === url) {
                labels.push(label);
            }
        }

        return labels;
    }

    private checkAndRedirectToDefaultTab(url: string): void {
        let labels = this.getLabels(url);
        let labelIsAvailable = labels.some(label => this.matTabs.includes(label));

        if (!labelIsAvailable && this.matTabs.length > 0) {
            this.router.navigate([this.getLink(this.matTabs[0])]);
        }
    }
}
