import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-activity-edition-point-img-upload',
    templateUrl: './activity-edition-point-img-upload.component.html',
    styleUrls: ['./activity-edition-point-img-upload.component.scss']
})
export class ActivityEditionPointImgUploadComponent {
    imageSrc: string | ArrayBuffer | null = null;
    selectedFile: File | null = null;
    @Input() disableUpload = false;
    @Output() myPicture = new EventEmitter<string | ArrayBuffer>();

    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files[0]) {
            this.selectedFile = input.files[0];

            const reader = new FileReader();
            reader.onload = e => {
                this.imageSrc = reader.result;
                this.uploadImage();
            };
            reader.readAsDataURL(this.selectedFile);
        }
    }

    uploadImage(): void {
        if (!this.selectedFile || !this.imageSrc) {
            return;
        }

        const formData = new FormData();
        formData.append('file', this.selectedFile);
        this.myPicture.emit(this.imageSrc);
    }
}
