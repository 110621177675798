// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#add-project-button {
  position: absolute;
  bottom: 12px;
  right: 12px;
  padding: 0;
  z-index: 99;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/projects-management/core/projects-management.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;AACF","sourcesContent":["#add-project-button {\n  position: absolute;\n  bottom: 12px;\n  right: 12px;\n  padding: 0;\n  z-index: 99;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
