import {Component, OnInit} from '@angular/core';
import {FollowedListComponentOptionsInterface} from '@modules/assignation/core/components/followed-list/followed-list-component-options.interface';
import {AssignationConfigurationService} from '@modules/assignation/core/services/assignation-configuration.service';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector: 'app-self-assignments-list',
    templateUrl: './self-assignments-list.component.html',
    styleUrls: ['./self-assignments-list.component.scss']
})
export class SelfAssignmentsListComponent implements OnInit {
    public readonly options: FollowedListComponentOptionsInterface;

    constructor(
        private assignationConfigurationService: AssignationConfigurationService,
        private authService: AuthenticationService,
    ) {

        const initialRequestFilters = {
            'assignated_user': this.authService.userData.id,
            'assignator': this.authService.userData.id,
        };

        this.options = {
            ...this.assignationConfigurationService.getAssignmentsListOptions('self'),
            initialRequestFilters
        };
    }

    ngOnInit(): void {
    }

}
