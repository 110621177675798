import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-activity-edition-preview-write-instruction',
    templateUrl: './activity-edition-preview-write-instruction.component.html',
    styleUrls: ['./activity-edition-preview-write-instruction.component.scss']
})
export class ActivityEditionPreviewWriteInstructionComponent {
    @Input() instruction: string | null = null;
}
