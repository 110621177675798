import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {GraphConfig} from 'fuse-core/components/graph/graph-mixed/graph.config';
import {GraphMathiaService} from '../../services/graph-mathia.service';
import {delay, filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {CustomTooltipModalContentSettings} from 'fuse-core/components/graph/graph-details-modal/custom-tooltip.setting';
import {LevelData} from '@modules/graph-mathia/core/model/level-data';
import * as _ from 'lodash-es';
import {Learner} from '@modules/graph-mathia/core/model/learner';
import {Router} from '@angular/router';
import {FakeStepperOptions} from '@modules/graph-mathia/core/component/fake-stepper/fake-stepper.component';

interface GraphDetail {
    index: number;
    data: {
        learner: string;
        average: string;
        count: number;
        link: string;
    }[];
    displayedColumns: string[];
    class: string;
    chartColumnLabel: string;
}

const ColumnsLabels = [
    'graph.progression_not_acquired',
    'graph.progression_to_do',
    'graph.progression_so_close',
    'graph.progression_acquired',
];

const firstColor = 'rgb(236,0,140)';
const secondColor = 'rgb(255,140,0)';
const thirdColor = 'rgb(0,188,242)';
const fourthColor = 'rgb(0,178,148)';

const Colors = [
    firstColor,
    secondColor,
    thirdColor,
    fourthColor,
];

// TODO move to utils
const opacity = (color: string, factor: string) => color.replace(')', `,${factor})`);

@Component({
    selector: 'app-level-graph',
    templateUrl: './level-graph.component.html',
})
export class LevelGraphComponent implements OnInit, OnDestroy {
    public graphConfigObs: ReplaySubject<GraphConfig> = new ReplaySubject<GraphConfig>(1);
    public isReady = false;
    public details: GraphDetail;
    /**
     * Cache le loader si le service n'est pas encore pret
     */
    public showLoader = false;
    /**
     * Aide a déterminer le temps entre les steps du loader
     * @private
     */
    private loaderShouldEnd = false;
    /**
     * Défini les steps du loader, le temps entre les stepsn, etc.
     */
    public loaderOptions: Partial<FakeStepperOptions> = {
        loop: false,
        interval: stateIndex => (this.loaderShouldEnd === false || stateIndex <= 2 ? 4000 : 1000),
        states: ['fake_stepper.state_1', 'fake_stepper.state_2', 'fake_stepper.state_3'],
        onEnd: new ReplaySubject(1)
    };
    private unsubscribeInTakeUntil = new Subject<void>();
    private graphData: LevelData;

    constructor(private graphMathiaService: GraphMathiaService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.graphMathiaService.isReady
            .pipe(
                filter(isReady => !!isReady),
                tap(() => this.showLoader = true),
                tap(() => this.graphMathiaService.graphDataArePending.subscribe(() => this.isReady = false)),
                mergeMap(() => this.graphMathiaService.getLevelGraphData()),
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data) => {
            this.loaderShouldEnd = true;
            this.graphData = data;
            this.showDetails(0);
            this.generateSimpleGraph();
            // Ne retirez pas de delay, sinon le timing foire et ça ne marche pas (je pense que le graphDataArePending est trigger trop tot)
            this.loaderOptions.onEnd.pipe(take(1), delay(750)).subscribe(() => this.isReady = true);
        });

    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public onGraphClick(activeElement: number | null): void {
        if (activeElement !== null && activeElement !== undefined) {
            if (this.details.index !== activeElement) {
                this.showDetails(activeElement);
            }
        }
    }

    /**
     * open progress graph of learner selected
     * @param learnerId
     */
    public onTableClick(learnerId: string): void {
        this.graphMathiaService.storeLearnerSelectedInCacheFilter(learnerId);
        this.router.navigate(['graph-mathia', 'multi', 'progress']);
    }

    private generateSimpleGraph(): void {
        const chartLabels: string[] = [];
        const dots: number[] = [];
        const dotsDetails: CustomTooltipModalContentSettings[] = [];

        this.graphData.toGraphFriendlyData(5).forEach(bar => {
            chartLabels.push('');
            dots.push(bar.data);
        });

        this.graphConfigObs.next({
            chartLabels,
            chartConfig: {
                aspectRatio: 1.5,
                scales: {
                    y: {
                        beginAtZero: true, min: 0,
                        ticks: {precision: 0}
                    },
                },
                onClick: (event, activeElements?: Array<{}>) => {
                    event.native.stopPropagation();
                    this.onGraphClick(_.get(activeElements, '[0]._index', null));
                },
                plugins: {
                    legend: {display: false},
                },
                layout: {}
            },
            chartData: [{
                data: dots,
                type: 'bar'
            }],
            modalContent: [dotsDetails]
        });
    }

    private showDetails(index: number): void {
        // On pourrait faire ca mieux, ma ça c'est les données utilisé par le graph donc limité aux 5 derniers exo par eleves
        const graphStats = this.graphData.getStatsByLearner(5);
        // alors qu'ici on est sur TOUT (en fonction des filtres toujours) les exos, donc la moyenne diffère mais on a le total d'exo.
        const globalStats = this.graphData.getStatsByLearner();
        const filteredBySuccessGlobalStats = this.graphData.filterBySuccess(graphStats, LevelData.successLevel[Object.keys(LevelData.successLevel)[index]]);

        this.details = {
            chartColumnLabel: ColumnsLabels[index],
            index: index,
            data: Object.keys(filteredBySuccessGlobalStats).map((learnerId) => ({
                learner: this.getLearnerById(learnerId).nickname,
                average: graphStats[learnerId].average + '%',
                count: globalStats[learnerId].count,
                link: learnerId
            })),
            displayedColumns: ['learner', 'average', 'count', 'link'],
            class: 'column-' + index,
        };
    }

    private getLearnerById(learnerId: string): Learner {
        return this.graphMathiaService.learners.find(l => +l.id === +learnerId);
    }
}
