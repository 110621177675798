// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-media-item {
  position: relative;
  cursor: pointer;
}
.section-media-item h2 {
  font-size: 14px;
  padding: 4px;
}
.section-media-item mat-icon {
  display: block;
}
.section-media-item button {
  position: absolute;
  top: 0;
  right: -25px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/research-sheet/core/section-display/section-media-learner/section-media-learner.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;AACJ;AACI;EACI,eAAA;EACA,YAAA;AACR;AAEI;EACI,cAAA;AAAR;AAGI;EACI,kBAAA;EACA,MAAA;EACA,YAAA;AADR","sourcesContent":[".section-media-item {\n    position: relative;\n    cursor: pointer;\n\n    h2 {\n        font-size: 14px;\n        padding: 4px;\n    }\n\n    mat-icon {\n        display: block;\n    }\n\n    button {\n        position: absolute;\n        top: 0;\n        right: -25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
