import { HeaderNavigationService } from './service/header-navigation.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {SharedModule} from 'shared/shared.module';



@NgModule({
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
    imports: [
        CommonModule,
        FlexLayoutModule,
        CommonSharedModule,
        SharedModule
    ]
})
export class HeaderModule {
    static forRoot(): ModuleWithProviders<HeaderModule> {

        return {
            ngModule: HeaderModule,
            providers: [
                HeaderNavigationService
            ]
        };
    }
 }
