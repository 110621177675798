import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {ProjectsService} from '@modules/projects-management/core/projects.service';
import {ReplaySubject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-widget-corpus-summary',
  templateUrl: './widget-corpus-summary.component.html',
  styleUrls: ['./widget-corpus-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetCorpusSummaryComponent implements OnInit, OnDestroy {
    @Input('corpusSubscription') corpusSubscription: ReplaySubject<any>;

    displayedColumns: string[] = ['name', 'count'];
    dataSource = new MatTableDataSource();
    resource: boolean;

    constructor(public projectService: ProjectsService,
                private router: Router,
                private route: ActivatedRoute) { }

    ngOnInit(): any {
        this.corpusSubscription.subscribe((data) => {
            this.dataSource.data = data;
            this.resource = data && data.length;

        });
    }

    goToCorpus(id): void {
        this.router.navigate(['../', 'tools', 'corpus', id], { relativeTo: this.route});
    }

    public get align(): string {
        if (this.resource) {
            return 'start center';
        }
        return 'center center';
    }

    get empty(): boolean {
        return this.resource;
    }

    getTerms(document): string {
        return document && document.data && +document.data.count > 1 ? 'generic.documents' : 'generic.document';
    }

    ngOnDestroy(): any {
        this.corpusSubscription.complete();
    }
}
