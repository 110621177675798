import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {IdeasWallService} from '../ideas-wall.service';

@Component({
    selector: 'ideas-wall-remote-controller',
    templateUrl: './remote-controller.component.html',
    styleUrls: ['./remote-controller.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RemoteControllerComponent implements OnInit {

    selectedTab;
    currentTabLabel: string;

    @Output('setIdeasFilter') setIdeasFilter: EventEmitter<void> = new EventEmitter<void>();
    @Output('setCategoriesFilter') setCategoriesFilter: EventEmitter<void> = new EventEmitter<void>();
    @Output('close') close: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private wallsService: IdeasWallService
    ) {}

    ngOnInit(): void {
        this.selectTab('t1');
    }

    get ideasMode(): string {
        return this.wallsService.currentIdeasFilterMode;
    }

    set ideasMode(value: string) {
        this.wallsService.currentWall.set('ideasMode', value);
        this.wallsService.currentWall.save(true);
    }

    get categoriesMode(): string {
        return this.wallsService.currentCategoriesFilterMode;
    }

    set categoriesMode(value: string) {
        this.wallsService.currentWall.set('categoriesMode', value);
        this.wallsService.currentWall.save(true);
    }

    get teacherIdeasFilterMode(): string {
        return this.wallsService.currentTeacherIdeasFilterMode;
    }

    set teacherIdeasFilterMode(value: string) {
        this.wallsService.currentWall.set('teacherIdeasMode', value);
        this.wallsService.currentWall.save(true);
    }

    get teacherCategoriesFilterMode(): string {
        return this.wallsService.currentTeacherCategoriesFilterMode;
    }

    set teacherCategoriesFilterMode(value: string) {
        this.wallsService.currentWall.set('teacherCategoriesMode', value);
        this.wallsService.currentWall.save(true);
    }

    get filteredGroups(): any[] {
        const projectId = this.wallsService.currentWall.get('parent');
        return this.wallsService.notArchivedGroups.filter((group) => group.projects.includes(projectId) );
    }

    isIdeasGroupSelected(group: any): boolean {
        return this.isGroupSelected(group, 'ideasDisplayedGroups');
    }

    isCategoriesGroupSelected(group: any): boolean {
        return this.isGroupSelected(group, 'categoriesDisplayedGroups');
    }

    selectIdeasGroup(group: any): void {
        this.selectGroup(group, 'ideasDisplayedGroups');
        this.setIdeasFilter.emit();
    }

    selectCategoriesGroup(group: any): void {
        this.selectGroup(group, 'categoriesDisplayedGroups');
        this.setCategoriesFilter.emit();
    }

    isGroupSelected(group: any, field: string): boolean {
        const displayedGroups: number[] = (this.wallsService.currentWall.get(field) || []).map(groupId => +groupId);
        return displayedGroups.indexOf(+group.id) !== -1;
    }

    selectTab(value: string): void {
        this.selectedTab = value;
        if (value === 't1') {
            this.currentTabLabel = 'ideas-wall.my-screen';
        }
        else if (value === 't2') {
            this.currentTabLabel = 'ideas-wall.students-screen';
        }
    }

    selectGroup(group: any, field: string): void {
        const displayedGroups: number[] = (this.wallsService.currentWall.get(field) || []).map(groupId => +groupId);

        const index: number = displayedGroups.indexOf(+group.id);

        if (index === -1) {
            displayedGroups.push(+group.id);
        } else {
            displayedGroups.splice(index, 1);
        }

        this.wallsService.currentWall.set(field, displayedGroups);
        this.wallsService.currentWall.save();
    }

    public get lockedIdeas(): boolean {
        return this.wallsService.currentWall.get('locked') || this.wallsService.currentWall.get('locked_item');
    }

    public get lockedCategories(): boolean {
        return this.wallsService.currentWall.get('locked') || this.wallsService.currentWall.get('locked_category');
    }

    public lockIdeas(): void {
        if (!this.wallsService.currentWall.get('locked')) {
            this.wallsService.lockToggle.next({type: 'ideas', locked: !this.lockedIdeas});
            this.wallsService.currentWall.set('locked_item', !this.lockedIdeas);
            this.wallsService.currentWall.save();
        }
    }

    public lockCategories(): void {
        if (!this.wallsService.currentWall.get('locked')) {
            this.wallsService.lockToggle.next({type: 'categories', locked: !this.lockedCategories});
            this.wallsService.currentWall.set('locked_category', !this.lockedCategories);
            this.wallsService.currentWall.save();
        }
    }
}
