import { Component, OnInit } from '@angular/core';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {DataEntity} from 'octopus-connect';
import {CorpusSet} from '@modules/corpus/core/corpus-set.class';

@Component({
    selector: 'app-corpus-list',
    templateUrl: './corpus-list.component.html',
    styleUrls: ['./corpus-list.component.scss']
})
export class CorpusListComponent implements OnInit {

    corpusList: DataEntity[];
    setLists: CorpusSet[];

    constructor(
        private corpusService: CorpusService
    ) { }

    ngOnInit() {
        this.corpusService.loadUserCorpusCollection().subscribe(entities => {
            this.corpusList = entities;
        });

        this.corpusService.loadCorpusSets().subscribe(sets => {
            this.setLists = sets;
        });
    }

    addCorpus() {
        this.corpusService.createCorpus();
    }

    addSet() {
    }

}
