import {Component, Injectable} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {NewsInterface} from 'fuse-core/news/news.interface';
import {BeginningPopUpComponent} from '@modules/achievement/core/onboarding/beginning-pop-up/beginning-pop-up.component';
import {AfterBeginningPopUpComponent} from '@modules/achievement/core/onboarding/after-beginning-pop-up/after-beginning-pop-up.component';
import {DataEntity} from 'octopus-connect';
import {Observable, Subject} from 'rxjs';
import {distinctUntilChanged, mergeMap, takeUntil} from 'rxjs/operators';
import {ConfigService} from '../config.service';
import {MyProfilePopupComponent} from '@modules/achievement/core/onboarding/my-profile-popup/my-profile-popup.component';
import {LeaderboardPopUpComponent} from '@modules/achievement/core/onboarding/leaderboard-pop-up/leaderboard-pop-up.component';

const MY_CITY_URL_REGEX = /^\/achievement\/my-city$/;
const MY_PROFILE_URL_REGEX = /^\/achievement\/my-profile$/;
const RANKING_URL_REGEX = /^\/achievement\/leaderboard$/;

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {

    popupLeaderboard: Partial<NewsInterface> = {
        id: 'LeaderboardPopUpComponent',
        component: LeaderboardPopUpComponent as Component,
        channel: {
            snackbar: {
                acceptedUrlRegex: RANKING_URL_REGEX
            }
        }
    };

    popupNewLearner: Partial<NewsInterface> = {
        id: 'BeginningPopUpComponent',
        component: BeginningPopUpComponent as Component,
        channel: {
            snackbar: {
                acceptedUrlRegex: MY_CITY_URL_REGEX
            }
        }
    };
    popupOldLearner: Partial<NewsInterface> = {
        id: 'AfterBeginningPopUpComponent',
        component: AfterBeginningPopUpComponent as Component,
        channel: {
            snackbar: {
                acceptedUrlRegex: MY_CITY_URL_REGEX
            }
        }
    };

    popupMyProfile: Partial<NewsInterface> = {
        id: 'MyProfilePopUpComponent',
        component: MyProfilePopupComponent as Component,
        channel: {
            snackbar: {
                acceptedUrlRegex: MY_PROFILE_URL_REGEX
            }
        }
    };

    public isNewUser = false;
    private logout$ = new Subject<void>();

    constructor(
        private communicationCenter: CommunicationCenterService,
        private config: ConfigService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    public displayPopup(): void {
        this.communicationCenter
            .getRoom('news')
            .next('add', [
                this.isNewUser ? this.popupNewLearner : this.popupOldLearner,
                this.popupMyProfile,
                this.popupLeaderboard
            ]);
    }

    public hidePopups(): void {
        this.communicationCenter
            .getRoom('news')
            .next('delete', [
                this.popupNewLearner,
                this.popupOldLearner,
                this.popupMyProfile,
                this.popupLeaderboard
            ]);
    }

    private postAuthentication(): void {
        this.hidePopups();

        // Ce test est inutile car aucune instance n'a ce module sans onboarding, mais hors PROD, tous les modules sont présents pour toutes les instances.
        if (this.config.isOnboardingEnabled()) {
            this.communicationCenter
                .getRoom('achievement')
                .getSubject('isNewUser$')
                .pipe(
                    mergeMap((isNewUser$: Observable<boolean>) => isNewUser$),
                    takeUntil(this.logout$),
                    distinctUntilChanged(),
                )
                .subscribe((isNewUser) => {
                    this.isNewUser = isNewUser;
                    this.hidePopups();
                    this.displayPopup();
                });
        }

    }

    private postLogout(): void {
        this.logout$.next();
        this.logout$.complete();
        this.logout$ = new Subject<void>();

        this.hidePopups();
        this.isNewUser = false;
    }
}
