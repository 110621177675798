import {Injectable} from '@angular/core';
import {Observable, shareReplay} from 'rxjs';
import {OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {ConceptDataCollection, ConceptEntity} from 'shared/models';

interface GetConceptsOptions {
    filters: {
        label: string;
    } | {
        id: string | number | (string | number)[];
    }
}

@Injectable({
    providedIn: 'root'
})

export class ConceptsService {
    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('concepts')
            .next('getConceptsCallback', () => {
                return this.getConcepts();
            })
    }

    public getConcepts(options?: GetConceptsOptions): Observable<ConceptDataCollection> {
        return this.octopusConnect.loadCollection('concepts', options?.filters) as Observable<ConceptDataCollection>;
    }

    public getConcept(id: string | number): Observable<ConceptEntity> {
        return this.octopusConnect.loadEntity('concepts', id) as Observable<ConceptEntity>;
    }
}
