import {ChangeDetectorRef, Component} from '@angular/core';
import * as _ from 'lodash-es';
import {Observable, of} from 'rxjs';
import {BaseActivityComponent, TIME_DELAY_BEFORE_SAVE, TIME_DISPLAYING_CORRECTION} from '../base-activity.component';
import {AnswerResultInterface, ItemAnswerStateEnum} from '@modules/activities/core/models';
import {shuffle} from 'shared/utils/array';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../../activities.service';
import {LessonsService} from '../../lessons/services/lessons.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {v4 as uuidv4} from 'uuid';
import {LessonNavigationService} from '../../lesson-navigation.service';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';
import {ContextualService} from '@modules/activities/core/services/contextual.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {TextMatchingActivityGranule} from '@modules/activities/core/models/activities/typologies/text-matching-activity.granule';
import {tap} from 'rxjs/operators';


@Component({
    selector: 'app-text-matching',
    templateUrl: './text-matching.component.html',
    animations: [
        trigger('fadeInOut', [
            state('in', style({ opacity: 1 })),
            state('out', style({ opacity: 0 })),
            transition('out <=> in', animate('1000ms ease-in-out')),
            transition('in <=> out', animate('1000ms ease-in-out'))
        ])
    ]
})
export class TextMatchingComponent extends BaseActivityComponent<TextMatchingActivityGranule> {
    // Lot de réponses possible, généré depuis imagesToGuess
    public availableAnswers: (AnswerInterface & {state?: ItemAnswerStateEnum})[] = [];
    public buttonState: ItemAnswerStateEnum = ItemAnswerStateEnum.pristine;
    // Lot d'images utilisé comme instruction
    public imagesToGuess: (AnswerInterface & {state?: ItemAnswerStateEnum})[] = [];
    public currentImageToGuess: (AnswerInterface & {state?: ItemAnswerStateEnum}) = null;
    public disableAllAnswers = false;
    public uuid: string = uuidv4();
    private waitUntilCorrectionFinished: boolean;
    public currentImageToGuessTemp: string;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        protected communicationCenter: CommunicationCenterService,
        protected contextualService: ContextualService,
        private ref: ChangeDetectorRef,
        protected lessonNavigationService: LessonNavigationService
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, contextualService, lessonNavigationService);
    }

    public setSelectedAnswers(answerToSelect: AnswerInterface): void {
        this.answersSelected.forEach((answer) => {
            answer.select = false;
            if (answer.id === answerToSelect.id) {
                answer.select = true;
                if (this.answerContainAudio(answerToSelect.target)) {
                    this.playAudio('answer-selected-' + answerToSelect.id);
                }
            }
        });
    }
    public setAvailableAnswerSelected(answerToSelect: AnswerInterface): void {
        this.availableAnswers.forEach((answer) => {
            answer.select = false;
            if (answer.id === answerToSelect.id) {
                answer.select = true;
                if (this.answerContainAudio(answerToSelect.source)) {
                    this.playAudio(answerToSelect.id);
                }
            }
        });
    }

    private setDefaultSelectedAnswer(): void {
        this.answersSelected = shuffle(this.referenceActivityGranule.activity_content.answers_app.map((answer) => {
            return {
                id: answer.id,
                answer: answer.answer,
                image: answer.image,
                select: !!+answer.select,
                correct_answer: !!+answer.correct_answer,
                state: ItemAnswerStateEnum.pristine,
                feedback: answer.feedback ? answer.feedback : '',
                source: answer.source,
                target: answer.target
            };
        }), true);
    }

    public get answerToDisplay(): AnswerInterface[] {
        const answers = [];
        if (this.availableAnswers.length) {
            this.availableAnswers.forEach((answer, index) => {
                answers.push(answer);
                answers.push(this.answersSelected[index]);
            })
        }
        return answers
    }

    protected validate(): void {
        if (this.displayForSummary) {
            // TODO: DISPLAY summary  with new screen from figma
        } else {
            if (!this.waitUntilCorrectionFinished) {
                const answerResult: AnswerResultInterface = {
                    id: +this.activity.id,
                    state: ItemAnswerStateEnum.missing,
                    isLast: !this.answersSelected.some((answer) => !answer.select)
                };
                this.disableAllAnswers = true;
                const availableAnswerSelected = this.availableAnswers.find((answer) => answer.select && answer.state === ItemAnswerStateEnum.pristine);
                const answerSelected = this.answersSelected.find((answer) => answer.select && answer.state === ItemAnswerStateEnum.pristine);
                if (!!availableAnswerSelected && !!answerSelected) {
                    if (availableAnswerSelected.id === answerSelected.id) {
                        this.buttonState = ItemAnswerStateEnum.wasCorrect;
                        answerSelected.state = ItemAnswerStateEnum.wasCorrect;
                        answerSelected.select = false;
                        availableAnswerSelected.state = ItemAnswerStateEnum.wasCorrect;
                        availableAnswerSelected.select = false;
                    } else {
                        this.buttonState = ItemAnswerStateEnum.incorrect;
                        answerSelected.state = ItemAnswerStateEnum.incorrect;
                        availableAnswerSelected.state = ItemAnswerStateEnum.incorrect;
                        if (answerSelected.feedback) {
                            super.feedbackEndExoOpenModal({title: 'activities.title_modal_bad_answer_help', content: answerSelected.feedback});
                        }
                    }
                    answerResult.state = answerSelected.state;
                }
                super.manageProgressBarEventToSend(answerResult);
                this.nextItem();
            }
        }
    }

    public getColumnClass(): string {
        const numberOfColumns = this.referenceActivityGranule.config.columns;
        return 'columns-' + (_.isNumber(numberOfColumns) ? numberOfColumns : 1);
    }

    public getAvailableAnswersClasses(): string {
        const inputMode = 'input-as-' + this.getTypeOfInputs();
        return [this.getColumnClass(), inputMode].join(' ');
    }

    public getGuessingItemClasses(): string {
        return 'instruction-as-' + this.getTypeOfInstruction();
    }

    protected setAnswer() {
        return; // TODO ATTENDRE DE SAVOIR QU'EST CE QU'ON SAUVEGARDE
    }

    protected getGrade() {
        let oldGrade = 0;
        const correctCnt = this.availableAnswers.length;
        const selectedCorrect = this.availableAnswers.filter(item => item.state === ItemAnswerStateEnum.currentlyCorrect || item.state === ItemAnswerStateEnum.wasCorrect).length;

        if (this.userSave) {
            const oldAnswers = <(AnswerInterface & {state?: ItemAnswerStateEnum})[]>this.userSave.get('userActivity').entitySave.answers;
            const oldSelectedCorrect = oldAnswers.filter(item => item.state === ItemAnswerStateEnum.currentlyCorrect || item.state === ItemAnswerStateEnum.wasCorrect).length;

            if (oldSelectedCorrect > 0) {
                oldGrade = oldSelectedCorrect / correctCnt;
            }
        }

        const grade = selectedCorrect / correctCnt;
        return {
            newGrade: grade,
            oldGrade: oldGrade
        };
    }

    protected checkAnswer(): void {
        this.answerStatus = answerStatusEnum.wrong;
        const isCorrect = (i: (AnswerInterface & {state?: ItemAnswerStateEnum})) => i.state === ItemAnswerStateEnum.currentlyCorrect || i.state === ItemAnswerStateEnum.wasCorrect;
        const isPristine = (i: (AnswerInterface & {state?: ItemAnswerStateEnum})) => i.state === ItemAnswerStateEnum.pristine;

        if (this.availableAnswers.every(isCorrect)) {
            this.answerStatus = answerStatusEnum.correct;
        } else if (this.availableAnswers.some(isPristine)) {
            this.answerStatus = answerStatusEnum.missing;
        }
        this.activitiesService.doesUserResponsed.next(true);
        /* state message showing */
        if (this.lessonsService.isLessonTest() || this.lessonsService.isLessonTraining()) {
            this.testAnswer = true;
            this.displayFeedback = this.answerStatus === 3
                && this.lessonsService.isLessonTraining()
                && !this.lessonsService.isAtLeastTrainer()
                && this.activitiesService.settings.displayFeedback;
        }

        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});

        if (this.testAnswer) {
            this.activitiesService.checkAnswers.next({lessonCorrected: true});
        }
    }

    protected seeAnswerSolution(): void {
        this.testAnswer = true;
        this.displaySolution = true;
        this.availableAnswers = _.cloneDeep(this.imagesToGuess);
    }

    protected reset(resetAllSubscribe = false, type: string = null): Observable<boolean> {
        this.waitUntilCorrectionFinished = false;
        this.makeItShuffled();
        this.availableAnswers.forEach((item) => item.state = ItemAnswerStateEnum.pristine);
        return super.reset(resetAllSubscribe, type).pipe(
            tap(() => this.setDefaultSelectedAnswer())
        );
    }

    protected reviewAnswer(): void {
        this.displayFeedback =
            this.answerStatus === answerStatusEnum.wrong
            && this.lessonsService.isLessonTraining()
            && !this.lessonsService.isAtLeastTrainer()
            && this.activitiesService.settings.displayFeedback;

        this.reset(false, 'reviewAnswer');
        this.testAnswer = true;
        // restore answer only if add one and if almost one response is not null
        if (this.userSave && this.userSave.get('userActivity') && this.userSave.get('userActivity').entitySave.answers.filter(res => res !== null).length > 0) {
            const answers = this.userSave.get('userActivity').entitySave.answers;
            this.restoreAnswers(answers);
            this.checkAnswer();
        } else {
            // we don't have choose solution so we go back like if user click on reset button
            this.makeItShuffled();
            this.reset(false, 'reset');
        }
    }

    protected setContentData(data): void {
        this.waitUntilCorrectionFinished = false;
        this.wordingAlreadyReadWithTts = false;
        this.isTTSSpeaking = null;
        this.referenceActivityGranule = data.reference;
        if (this.referenceActivityGranule.config) {
            this.isVertical = (this.referenceActivityGranule.config.direction === 'vertical');
        }
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.imagesToGuess = this.referenceActivityGranule.activity_content.answers_app.map((answer) => {
            return {
                id: answer.id,
                answer: answer.answer,
                image: answer.image,
                select: !!+answer.select,
                correct_answer: !!+answer.correct_answer,
                state: ItemAnswerStateEnum.pristine,
                feedback: answer.feedback ? answer.feedback : '',
                source: answer.source,
                target: answer.target
            };
        });
        if (this.displayForSummary) {
            this.seeAnswerSolution();
        } else {
            this.makeItShuffled();
            if (!this.isActivityEmbedded) {
            this.loadUserSave();
        }
        }
        this.currentImageToGuess = _.cloneDeep(this.imagesToGuess[0]);
        this.currentImageToGuessTemp = this.currentImageToGuess.id;
        this.setDefaultSelectedAnswer();
    }

    /**
     * know if answer contain IMG, (used to display answer img)
     */
    public answerContainAudio(answer: string): boolean {
        const regexp = /<audio/gi;
        return !!answer && answer.search(regexp) !== -1;
    }

    // API Save Answer functionality
    protected saveAnswer(): Observable<number[]> {
        return of(this.availableAnswers.map((answer: AnswerInterface) => +answer.id));
    }

    private makeItShuffled(): void {
        this.availableAnswers = _.cloneDeep(this.imagesToGuess);
        this.availableAnswers = shuffle(this.availableAnswers, true);
        this.imagesToGuess = shuffle(this.availableAnswers, true);
    }

    private restoreAnswers(answers: AnswerInterface[]): void {
        this.imagesToGuess = _.cloneDeep(answers);
        this.makeItShuffled();
    }

    private nextItem(): void {
        this.waitUntilCorrectionFinished = true;
        // TODO faire une meilleure animation angular
        setTimeout(() => {
            this.disableAllAnswers = false;
            this.buttonState = ItemAnswerStateEnum.pristine;
            this.availableAnswers.filter(a => a.state === ItemAnswerStateEnum.incorrect).forEach(a => a.state = ItemAnswerStateEnum.pristine);
            this.availableAnswers.filter(a => a.state === ItemAnswerStateEnum.currentlyCorrect).forEach(a => a.state = ItemAnswerStateEnum.wasCorrect);
            this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.incorrect).forEach(a => a.state = ItemAnswerStateEnum.pristine);
            this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.currentlyCorrect).forEach(a => a.state = ItemAnswerStateEnum.wasCorrect);
            if (!this.answersSelected.some((answer) => answer.state === ItemAnswerStateEnum.pristine)) {
                if (this.isActivityEmbedded) {
                    super.setFeedBackFromApi(null, answerStatusEnum.correct);
                    this.isActivityEmbeddedDone = true;
                } else {
                    setTimeout(() => {
                        this.doAction('next', ['save']);
                    }, TIME_DELAY_BEFORE_SAVE);
                }
            }
            this.waitUntilCorrectionFinished = false;
        }, TIME_DISPLAYING_CORRECTION);
    }

    private getTypeOfInputs(): string {
        const mode = this.referenceActivityGranule.config.mode;

        if (!!mode) {
            return mode;
        }

        return 'undefined';
    }

    private getTypeOfInstruction(): string {
        const mode = this.referenceActivityGranule.config.instructionMode;

        if (!!mode) {
            return mode;
        }

        return 'undefined';
    }

    /**
     * change state of TTS
     * TODO better to move in base-activity, need to add ref (ChangeDetectorRef) in contructor
     * @param data
     */
    public speakStateChanged(data: { id: string, value: boolean }, id: string): void {
        this.isTTSSpeaking = data;
        this.ref.detectChanges();
        if (this.isTTSSpeaking && !this.isTTSSpeaking.value && id !== this.isTTSSpeaking.id) {
            this.wordingAlreadyReadWithTts = true;
        }
    }

    public isTTSReadingText(id: string): boolean {
        return this.isTTSSpeaking && this.isTTSSpeaking.id === id && !!this.isTTSSpeaking.value;
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    public playAudio(id: string): void {
        const audioElements: HTMLMediaElement[] = Array.prototype.slice.call(document.querySelectorAll('audio')) as HTMLMediaElement[] || [];
        audioElements.forEach((audioElement) => {
            audioElement.pause();
            audioElement.currentTime = 0;
        });
        const audio: HTMLAudioElement = document.getElementById(id).getElementsByTagName('audio')[0];
        if (audio) {
            audio.currentTime = 0;
            audio.play();
        }
    }

    public getStateClassCss(answer: (AnswerInterface & {state?: ItemAnswerStateEnum})): string {
        if (answer.state === ItemAnswerStateEnum.pristine && answer.select === true) {
            return 'selected';
        }
        return answer.state;
    }

    /**
     * know if answer contain IMG, (used to display answer img)
     */
    public answerContainImg(answer: string): boolean {
        const regexp = /<img/gi;
        return !!answer && answer.search(regexp) !== -1;
    }

    public isIntPair(index: number): boolean {
        return (index === 0 || index % 2 === 0);
    }

    public get isButtonValidateMustBeDisabled(): boolean {
        return !(this.answersSelected.some((answer) => answer.select && answer.state === ItemAnswerStateEnum.pristine)
            && this.availableAnswers.some((answer) => answer.select && answer.state === ItemAnswerStateEnum.pristine));
    }

    public readWithTts(id: string): void {
        const elem = document.getElementById(id);
        elem.click();
    }

    public getStyles(numberOfAnswers:number) {
        return {
            'display': 'grid',
            'grid-template-rows': `repeat(${numberOfAnswers/2}, 1fr)`,
            'grid-template-columns': 'repeat(2, 1fr)',
            'justify-items': 'center'

        };
    }
}
