import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ChaptersSelectionComponent} from 'fuse-core/components/chapters-selection/chapters-selection.component';
import {InstitutionDataEntity} from '@modules/groups-management/core/definitions';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatExpansionPanel} from '@angular/material/expansion';
import {map, startWith, tap} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';
import {Observable} from 'rxjs';
import {
    LicenseControls,
    LicenseManagementData,
    LicenseManagementDataToSave
} from '@modules/groups-management/core/models/license-management';
import {TranslatedLicenseType} from '@modules/groups-management/core/services/license-management-service/license-management.service';

@Component({
    selector: 'app-edit-user-and-license',
    templateUrl: './edit-user-and-license.component.html',
    styleUrls: ['./edit-user-and-license.component.scss']
})
export class EditUserAndLicenseComponent implements OnInit, AfterViewInit {

    public institutionFormGroup: UntypedFormGroup;
    public filterControl: UntypedFormControl;
    public controls: LicenseControls;
    public disableExpansionPanel = false;
    public formErrors: {
        endDate?: { required?: boolean; };
        license?: { required?: boolean; };
    } = {};

    public institutionFormErrors: {
        label?: { required?: boolean; exist?: boolean};
        uai?: { required?: boolean; exist?: boolean};
    } = {};
    public panelOpenState = false;
    public filteredInstitutions: Observable<InstitutionDataEntity[]>;

    @ViewChild('panel') panel: MatExpansionPanel;
    private errorFromApi: any; // erreur de l'api
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: LicenseManagementData,
        public dialogRef: MatDialogRef<ChaptersSelectionComponent>,
        private formBuilder: UntypedFormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.initialise();
    }

    ngAfterViewInit(): void {
        this.disableAndOpenExpansionPanel();

    }

    private initialise(): void {
        this.initFormControls();
        this.initFormControlSubscribes();
        this.institutionFormGroup = this.createInstitutionForm();
        this.initInstitutionFormSubscribes();
    }

    private initFormControls(): void {
        this.controls = {
            firstName: new UntypedFormControl({ value: this.data.user && this.data.user.firstName || null, disabled: this.disabledFields('firstName')}),
            lastName: new UntypedFormControl({ value: this.data.user && this.data.user.lastName || null, disabled: this.disabledFields('lastName')}),
            mail: new UntypedFormControl({ value: this.data.user && this.data.user.mail || null, disabled: this.disabledFields('mail')}, [Validators.email, Validators.required]),
            license: new UntypedFormControl( {value: this.data.license && this.data.license.type || 'free', disabled: this.disabledFields('license')}),
            endDate: new UntypedFormControl( {value: this.data.license && this.data.license.endDate
                    && new Date(this.data.license.endDate * 1000) || undefined, disabled: this.disabledFields('endDate')}),
            institution: new UntypedFormControl({ value: this.data.userInstitution  || null, disabled: this.disabledFields('institution')}),
            uai: new UntypedFormControl({ value: this.data.userInstitution && this.data.userInstitution.get('uai')  || null, disabled: this.disabledFields('uai')}),
        };
        this.filterControl = new UntypedFormControl({value: this.data.userInstitution && this.data.userInstitution.get('label') || null,
            disabled: this.disabledFields('institution')});

        this.disableExpansionPanel = this.disabledFields('institution');
    }

    private initFormControlSubscribes(): void {
        // eslint-disable-next-line guard-for-in
        this.filteredInstitutions = this.filterControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? this._filter(value || '') : this.institutions),
        );
        for (const field in this.controls) {
            this.controls[field].valueChanges.pipe(
                tap((val: string | Date | InstitutionDataEntity) => {
                    if (field === 'mail') {
                        if (this.controls.mail.errors?.alreadyTaken) {
                            this.controls.mail.setErrors(null);
                        }
                    }
                    if (field === 'endDate' && !val && this.controls[field].errors) {
                        this.formErrors.endDate = this.controls[field].errors;
                    }
                    if (field === 'institution' && val && typeof val !== 'string') {
                        const institutionSelected: InstitutionDataEntity = val as InstitutionDataEntity;
                        if (this.panel) {
                            this.panel.close();
                            this.institutionFormGroup.get('label').setValue(null, {emitEvent: false});
                            this.institutionFormGroup.get('uai').setValue(null, {emitEvent: false});
                        }
                        this.controls.license.setValue(institutionSelected && institutionSelected.get('license').type ?
                            this.data.getEnumKey(institutionSelected.get('license').type) : null, {emitEvent: false});
                        this.controls.endDate.setValue(institutionSelected.get('license').endDate && this.data.getEnumKey(institutionSelected.get('license').type) !== 'class' ?
                            new Date(+institutionSelected.get('license').endDate * 1000) : undefined, {emitEvent: false});
                        this.disableEnDateIfNecessary();
                    }
                    if (field === 'license') {
                        if (val && val !== 'free') {
                            this.disableExpansionPanel = this.disabledFields('institution');
                            this.institutionFormGroup.enable({emitEvent: false});
                            if (!this.disabledFields('institution') && !this.panelOpenState) {
                                this.controls.institution.enable({emitEvent: false});
                                this.filterControl.enable({emitEvent: false});
                                this.institutionFormGroup.enable({emitEvent: false});
                            }
                            if (!this.disabledFields('endDate')) {
                                this.controls.endDate.enable({emitEvent: false});
                            }

                            if (this.data.roles.includes('trainer') && !this.controls.institution.value && this.panel) {
                                this.panel.open();
                            }
                            this.disableEnDateIfNecessary();
                        } else if (val && val === 'free') {
                            this.controls.endDate.setValue(null, {emitEvent: false});
                            this.controls.institution.setValue(null, {emitEvent: false});
                            this.controls.endDate.disable({emitEvent: false});
                            this.controls.institution.disable({emitEvent: false});
                            this.institutionFormGroup.disable({emitEvent: false});
                            this.filterControl.disable({emitEvent: false});
                            this.filterControl.setValue(null, {emitEvent: false});
                            if (this.panel) {
                                this.panel.close();
                                this.disableExpansionPanel = true;
                            }
                        } else {
                            this.controls.endDate.clearValidators();
                            this.controls.endDate.reset();
                        }
                    }
                })
                // wip ici verifier si la valeur corresponds au format que l'on attends (cf formErrors)
            ).subscribe();
        }
    }

    private initInstitutionFormSubscribes(): void {
        this.institutionFormGroup.valueChanges.subscribe((value) => {
            const controlLabel = this.institutionFormGroup.get('label');
            const controlUai = this.institutionFormGroup.get('uai');

            if (this.institutionFormErrors) {
                for (const field in this.institutionFormErrors) {
                    this.institutionFormErrors[field] = {};
                }
            }
            if (controlLabel && controlLabel.dirty && !controlLabel.valid) {
                this.institutionFormErrors['label'] = controlLabel.errors;
            }
            if (controlUai && controlUai.dirty && !controlUai.valid) {
                this.institutionFormErrors['uai'] = controlUai.errors;
            }

            const institutionWithSameLabel = this.institutions.find((institution) => institution.get('label') === controlLabel.value);
            if (institutionWithSameLabel && this.institutions
                .some((institution) => institution.get('uai') ===  controlUai.value && institution.id === institutionWithSameLabel.id)) {
                this.institutionFormErrors.label = {exist: true};
                this.institutionFormErrors.uai = {exist: true};
                this.institutionFormGroup.setErrors({invalid: true});
            } else {
                this.institutionFormErrors.label = {exist: false};
                this.institutionFormErrors.uai = {exist: false};
                this.institutionFormGroup.setErrors(null);
            }
        });
    }

    public close(): void {
        this.dialogRef.close();
    }

    public save(): void {
        const values: LicenseManagementDataToSave =
            {
                user: this.data.user,
                firstName: this.controls.firstName.value,
                lastName: this.controls.lastName.value,
                mail: this.controls.mail.value,
                license: this.controls.license.value || null,
                endDate:  this.controls.endDate.value ? Math.round(new Date(this.controls.endDate.value).getTime() / 1000) : null
            };

        if (!this.data.user) {
            values.password = uuidv4().slice(8);
        }
        if (this.institutionFormGroup.enabled) {
            values.institution =  this.institutionFormGroup.getRawValue();
        }
        if (!this.panelOpenState) {
            values.institution =  this.controls.institution.value;
        }
        this.data.save(values).subscribe(() => {
                this.dialogRef.close(values);
            },
            errors => {
                this.errorFromApi = errors;
                if (this.errorFromApi?.data?.response?.title === 'account-management.mail_taken') {
                    this.controls.mail.markAsTouched();
                    this.controls.mail.markAsDirty();
                    this.controls.mail.setErrors({alreadyTaken: true}, {emitEvent: false});
                }
            });

    }

    get licenseTypes(): string[] {
        return this.data.licenseTypes || [];
    }

    get institutions(): InstitutionDataEntity[] {
        return this.data.institutions || [];
    }

    private createInstitutionForm(): UntypedFormGroup {
        const config = {
            label: [null, [Validators.required]],
            uai: [null, [Validators.required]],
        };
        return this.formBuilder.group(config);
    }

    public enableNewInstitution(): void {
        this.panelOpenState = true;
        this.controls.lastName.markAsDirty();
        this.controls.lastName.markAsTouched();
        this.controls.mail.markAsDirty();
        this.controls.mail.markAsTouched();
        this.filterControl.setValue(null, {emitEvent: false});
        if (this.controls.institution.value) {
            this.controls.license.setValue(null, {emitEvent: false});
            this.controls.endDate.setValue(null, {emitEvent: false});
            this.controls.institution.setValue(null, {emitEvent: false});
            this.filterControl.setValue(null, {emitEvent: false});
            this.filterControl.disable({emitEvent: false});
            this.controls.license.enable({emitEvent: false});
        }
        this.controls.institution.disable({emitEvent: false});
        this.institutionFormGroup.enable({emitEvent: false});
        // si user est directeur, obligatoire qu'il est que institution comme license
        if (this.data.roles.includes('director')) {
            this.controls.license.setValue('institution', {emitEvent: false});
            this.controls.license.disable({emitEvent: false});
            this.controls.endDate.markAsDirty();
            this.controls.endDate.markAsTouched();
        }
        // si user est prof, autoriser le changement de date et license si permis par disabledFields.
        if (this.data.roles.includes('trainer')) {
            if (!this.disabledFields('license')) {
                this.controls.license.enable({emitEvent: false});
            }
            if (!this.disabledFields('endDate')) {
                this.controls.endDate.enable({emitEvent: false});
            }
        }
    }

    public disableNewInstitution(): void {
        this.panelOpenState = false;
        this.filterControl.enable({emitEvent: false});
        this.institutionFormGroup.get('label').setValue(null);
        this.institutionFormGroup.get('uai').setValue(null);
        if (this.institutionFormErrors) {
            for (const field in this.institutionFormErrors) {
                this.institutionFormErrors[field] = {};
            }
        }
        if (!this.disabledFields('institution')) {
            this.controls.institution.enable({emitEvent: false});
        }
        if (!this.disabledFields('license') && !this.controls.institution.value) {
            this.controls.license.enable({emitEvent: false});
        }
        this.controls.license.reset();
        this.institutionFormGroup.disable({emitEvent: false});
        if (this.data.roles.includes('director')) {
            if (this.formErrors) {
                for (const field in this.formErrors) {
                    this.formErrors[field] = {};
                }
            }

            this.controls.endDate.reset();
            this.controls.endDate.setValue(this.data.license && this.data.license.endDate
                && new Date(this.data.license.endDate * 1000) || null, {emitEvent: false});
            this.controls.license.setValue(this.data.license && this.data.license.type || null, {emitEvent: false});
        }
    }

    public disableOption(licenseType: string): boolean {
        return this.data.disableOption(licenseType);
    }

    public disabledFields(field): boolean {
        return this.data.disabledFields(this.controls).includes(field);
    }

    private disableAndOpenExpansionPanel(): void {
        if (this.data.institutions.length === 0) {
            this.disableExpansionPanel = true;
            setTimeout(() => this.panel.open(), 1000);
        }
    }

    public get disableLicenseAndEndDate(): boolean {
        if (this.data && this.data.institutions.length === 0) {
            return this.institutionFormGroup.invalid;
        }
        return false;
    }

    public get isFormInvalid(): boolean {
        // TODO: pour l'instant le check est fait sur la presence de valeur mais par la suite, utiliser "valid" du Formcontrol
        return this.isInstitutionAndLicenseInvalid() || (this.isNameInvalid() || this.isNameInvalid(true)) || this.isMailInvalid();

    }

    public get userName(): string {
        return this.data && this.data.user ? ' ' + `${this.data.user.name}` : '';
    }

    public isInstitutionAndLicenseInvalid(): boolean {
        // peut être simplifié mais je garde le format return true ou false pour plus de lisibilité

        // si création d'une institution, le champs "label" et "uai" doit être remplie
        if (this.panelOpenState && this.institutionFormGroup.errors && ((!this.controls.license.value || this.controls.license.value === 'free')
            || (!this.institutionFormGroup.get('label').value || !this.institutionFormGroup.get('uai').value)
            || (this.institutionFormGroup.get('label').value === '' || this.institutionFormGroup.get('uai').value === ''))) {
            return true;
        }

        // si aucune institution existante selectionné, le champs "institution" et "license"
        // doit être remplie sauf si le type de license est "free" (free => pas besoin d'institution)
        if (!this.panelOpenState
            && this.controls.institution
            && !this.controls.institution.value
            && (!this.controls.license.value || this.controls.license.value !== 'free')) {
            return true;
        }

        // si institution existante selectionné, le champs "license" doit être remplie et ne doit pas être "free"
        if (!this.panelOpenState
            && this.controls.institution
            && this.controls.institution.value
            && (!this.controls.license.value || this.controls.license.value === 'free')) {
            return true;
        }
        return false;
    }

    public isNameInvalid(isLastNameField?: boolean): boolean {
        if (this.controls) {
            const name = isLastNameField ? this.controls.lastName : this.controls.firstName;
            if (!name.value || name.value === '') {
                name.setErrors({invalid: true});
            }
            if (name.errors && (name.errors.invalid || name.errors.required)) {
                return true;
            }
            name.setErrors(null);
            return false;
        }
        return true;
    }

    private isMailInvalid(): boolean {
        return this.controls && (!this.controls.mail.value
            || this.controls.mail.value === ''
            || !!this.controls.mail.errors);
    }


    private _filter(value: string): InstitutionDataEntity[] {
        const filterValue = value.toLowerCase();

        return this.institutions.filter((institution: InstitutionDataEntity) => {
            return this.showLabelInstitutionWithUai(institution).toLowerCase().includes(filterValue);
        });
    }

    public onInstitutionChange(institution: InstitutionDataEntity): void {
        this.controls.institution.setValue(institution);
        this.filterControl.setValue(institution.get('label'), {emitEvent: false});
    }

    public isLicenseError(): boolean {
        return this.panelOpenState && !this.controls?.license?.value;
    }

    public showLabelInstitutionWithUai(institution: InstitutionDataEntity): string {
        if (institution) {
            const label = institution.get('label') || '';
            const uai = institution.get('uai') && '[' + institution.get('uai') + ']' || '';
            return uai + ' ' + label;
        }
        return '';
    }

    private disableEnDateIfNecessary(): void {
        if ((this.data.userInstitution?.id === this.controls?.institution?.value?.id
            && this.data.roles.includes('trainer') && this.controls?.license?.value === 'institution')
        || (this.data.user && this.controls?.institution?.value
                && this.controls.license.value === 'institution' && this.data.roles.includes('trainer'))) {
            this.controls.endDate.disable();
        } else {
            this.controls.endDate.enable();
        }
    }

    public getTranslation(value: string): string {
        return TranslatedLicenseType[value];
    }
}
