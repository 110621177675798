import { Directive, Input, OnInit, ElementRef, Renderer2, Component } from '@angular/core';

// Directive qui ajoute une classe CSS aléatoire à un élément à partir d'un tableau de classes ou de string.
@Directive({
    selector: '[appRandomClass]',
    standalone: true
})
export class RandomClassDirective implements OnInit {

    @Input('appRandomClass') classArray: string[];


    // Tableau des classes CSS déjà utilisées.
    private usedClasses: string[] = [];

    /**
     * Constructeur de la directive.
     * @param el Référence à l'élément hôte.
     * @param renderer Service utilisé pour manipuler l'élément hôte.
     */
    constructor(private el: ElementRef, private renderer: Renderer2) { }


     // initialisation
    ngOnInit() {
        // Vérifie si le tableau de classes est vide, sinon continue.
        if (!this.classArray || this.classArray.length === 0) {
            return;
        }

        // Récupère les classes disponibles.
        const availableClasses = this.getAvailableClasses();

        // Génère un index aléatoire pour sélectionner une classe.
        const randomIndex = Math.floor(Math.random() * availableClasses.length);

        // Récupère la classe aléatoire.
        const randomClass = availableClasses[randomIndex];

        // Ajoute la classe aléatoire à l'élément hôte.
        this.renderer.addClass(this.el.nativeElement, randomClass);

        // Ajoute la classe utilisée au tableau des classes utilisées.
        this.usedClasses.push(randomClass);
    }

    /**
     * Méthode qui récupère les classes disponibles.
     * @return Tableau des classes disponibles.
     */
    private getAvailableClasses(): string[] {
        // Filtre les classes qui n'ont pas encore été utilisées.
        return this.classArray.filter(cls => !this.usedClasses.includes(cls));
    }
}
