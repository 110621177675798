import {Injectable} from '@angular/core';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '@modules/graph-bayard/core/model/rules';
import {GraphConfigurationService} from '@modules/graph-bayard/core/services/graph-configuration.service';
import {Roles} from 'shared/models/roles';

@Injectable({
    providedIn: 'root'
})
export class GraphBayardAuthorizationService {

    constructor(
        private authorizationService: AuthorizationService,
        private graphConfig: GraphConfigurationService
    ) {

    }

    activeRulesOnStartup(): void {
        this.authorizationService.addRoleRule(SyncRules.seeGraphsLinksInMenu, ['learner', 'trainer']);
        // TODO dans les settings on peut mettre "default" mais c'est un role non géré par addRoleRule
        this.authorizationService.addRoleRule(SyncRules.seeBayardGraphsBannerInfo, this.graphConfig.getRolesCanShowBannerInfo() as Roles[]);
    }

    /**
     * if true, display header banner info
     */
    public get isRolesCanShowBannerInfo(): boolean {
        return this.authorizationService.currentUserCan<boolean>(SyncRules.seeBayardGraphsBannerInfo);
    }

}
