import * as _ from 'lodash-es';
import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from "octopus-model";
import {modulesSettings} from '../../../../settings';
import {AuthenticationService} from "@modules/authentication";

const settingsStructure: ModelSchema = new ModelSchema({
    contestDetailsTabs: Structures.object({
        default: ['home', 'lessons', 'manage', 'ranking']
    }),
});

@Injectable({
    providedIn: 'root'
})
export class ContestConfigurationService {
    private readonly settings: { [p: string]: any };

    constructor(
        private authenticationService: AuthenticationService,
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.contest);
    }
    
    public get contestDetailsTabs(): string[] {
        const role = this.authenticationService.accessLevel;
        const contestDetailsTabs = _.get(this.settings, 'contestDetailsTabs', {default: []});

        return contestDetailsTabs.hasOwnProperty(role) ? contestDetailsTabs[role] : contestDetailsTabs.default;
    }
}
