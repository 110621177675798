import {Injectable} from '@angular/core';
import {CommunicationCenterService} from "@modules/communication-center";
import {NewsletterFormComponent} from "@modules/account-management/core/newsletter-form/newsletter-form.component";

@Injectable({
    providedIn: 'root'
})
export class AccountManagementComponentsService {

    constructor(
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('account-management')
            .next('newsletterForm', NewsletterFormComponent);
    }
}
