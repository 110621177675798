import {
    Component,
    ElementRef,
    HostListener,
    ViewChild
} from '@angular/core';
import {BaseActivityComponent} from '../base-activity.component';
import {Observable, of} from 'rxjs';
import {ActivityGranule, IActivityContentPoint, InteractiveImageActivityContent} from '@modules/activities/core/models';

export type ImageActivityGranule = ActivityGranule<InteractiveImageActivityContent, unknown>

@Component({
    selector: 'app-interactive-image',
    templateUrl: './interactive-image.component.html'
})
export class InteractiveImageComponent extends BaseActivityComponent<ImageActivityGranule>{

    @ViewChild('imgViewport', {static: true}) imgViewport: ElementRef;
    @ViewChild('interactiveImage') interactiveImage: ElementRef;
    @ViewChild('targetDetailsImage') targetDetailsImage: ElementRef;

    public imgWidth = 200;
    public imgHeight = 100;


    private _isDotDocOpen = false;

    /***
     * is media open by click on the interactiv picture
     */
    get isDotDocOpen(): boolean {
        return this._isDotDocOpen;
    }

    private _currentDot: IActivityContentPoint;

    /***
     * current dot click who was click on the interactive picture( content data to show)
     */
    get currentDot(): IActivityContentPoint {
        return this._currentDot;
    }

    private _isShowDot = false;

    /***
     * is dot show on interactive picture
     */
    get isShowDot(): boolean {
        return this._isShowDot;
    }

    public currentMediaType(media): string {

        if (media.uri.includes('.png') || media.uri.includes('.jpg')) {
            return 'wrapper-image';
        }
        if (media.uri.includes('.mp3')) {
            return 'wrapper-audio';
        }
        if (media.uri.includes('.mp4')) {
            return 'wrapper-video';
        }
        return null;
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.imageSizeByRatio();
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    /***
     * open the media after a click on a dot on interactive picture
     */
    public openDocument(point: IActivityContentPoint): void {
        this._currentDot = point;
        this._isDotDocOpen = true;
        // add info about media was already open to change color
        this.referenceActivityGranule.activity_content.points.filter(res => res.id === point.id)[0].alreadyOpen = true;
    }

    /***
     * close the media open
     */
    public closeDocument(): void {
        this._isDotDocOpen = false;
        this._currentDot = null;
    }

    /**
     * show dot on interactive picture
     */
    public showhideDot(): void {
        this._isShowDot = !this._isShowDot;
    }

    /***
     * open pdf
     */
    public openPdf(path: string): void {
        window.open(path, '_blank');
    }

    public imageRatioType(): void {
        const img = this.targetDetailsImage.nativeElement;

        if (img.naturalHeight > img.naturalWidth) {
            img.classList.add('img-portrait');
        } else {
            img.classList.add('img-paysage');
        }
    }

    protected setContentData(activityAttributes: ImageActivityGranule['attributes']): void {
        this.activityType = activityAttributes.metadatas.typology.label;
        this.referenceActivityGranule = activityAttributes.reference;
        this.initLocalValueDotOpenToFalse();
    }

    private initLocalValueDotOpenToFalse(): void {
        // local value not exist in object from back
        // => init value dot not already selected when open interactive picture
        this.referenceActivityGranule.activity_content.points.forEach(res => {
            res.alreadyOpen = false;
        });
    }

    protected reset(resetAllSubscribe = false, type = null): Observable<boolean> {
        this._isShowDot = false;
        this._isDotDocOpen = false;
        return super.reset(resetAllSubscribe, type);
    }

    /**
     * resize the image according to its frame/viewport
     */
    public imageSizeByRatio(): void {

        const imgViewport = this.imgViewport.nativeElement;
        const img = this.interactiveImage.nativeElement;

        const ratioImgWidth = img.naturalWidth / img.naturalHeight;
        const ratioImgHeight = img.naturalHeight / img.naturalWidth;

        this.imgWidth = imgViewport.offsetHeight * ratioImgWidth;
        this.imgHeight = imgViewport.offsetWidth * ratioImgHeight;

        if (this.imgHeight > imgViewport.offsetHeight) {
            this.imgHeight = imgViewport.offsetHeight;
            this.imgWidth = imgViewport.offsetHeight * ratioImgWidth;
        } else {
            this.imgWidth = imgViewport.offsetWidth;
            this.imgHeight = imgViewport.offsetWidth * ratioImgHeight;
        }
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected  getGrade(): {oldGrade: number, newGrade: number} {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }


    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}
