// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  white-space: nowrap;
}

.main-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.columns-container {
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  position: relative;
}

.add-button {
  border-radius: 50%;
  border: 1px solid black;
  background-color: white;
  width: 20px;
  height: 20px;
  cursor: pointer;
  text-align: center;
  font-size: 24px;
  line-height: 22px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 0;
  z-index: 99;
}

.column {
  width: 200px;
  display: inline-block;
  vertical-align: top;
  height: 1200px;
  bottom: 0;
  position: relative;
}

.column-placeholder {
  border: 2px dashed lightgray;
  border-radius: 4px;
  margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/ideas-wall/core/ideas-view/ideas-view.component.scss"],"names":[],"mappings":"AAAA;EAEE,mBAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,OAAA;EACA,SAAA;AAAF;;AAGA;EACE,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,uBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EAEA,kBAAA;EAEA,YAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;AAFF;;AAKA;EACE,YAAA;EACA,qBAAA;EACA,mBAAA;EACA,cAAA;EACA,SAAA;EACA,kBAAA;AAFF;;AAKA;EACE,4BAAA;EACA,kBAAA;EACA,YAAA;AAFF","sourcesContent":[":host {\n  //position: relative;\n  white-space: nowrap;\n}\n\n.main-container {\n  width: 100%;\n  height: 100%;\n  max-height: 100%;\n  overflow: hidden;\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n}\n\n.columns-container {\n  padding-left: 40px;\n  padding-right: 40px;\n  width: 100%;\n  height: 100%;\n  max-height: 100%;\n  overflow: auto;\n  position: relative;\n}\n\n.add-button {\n  border-radius: 50%;\n  border: 1px solid black;\n  background-color: white;\n  width: 20px;\n  height: 20px;\n  cursor: pointer;\n  text-align: center;\n  font-size: 24px;\n  line-height: 22px;\n  //margin: 10px;\n  position: absolute;\n\n  bottom: 20px;\n  right: 20px;\n  padding: 0;\n  z-index: 99;\n}\n\n.column {\n  width: 200px;\n  display: inline-block;\n  vertical-align: top;\n  height: 1200px;\n  bottom: 0;\n  position: relative;\n}\n\n.column-placeholder {\n  border: 2px dashed lightgray;\n  border-radius: 4px;\n  margin: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
