import {Component, EventEmitter, inject, Output} from '@angular/core';
import {AssignmentByStepService} from '@modules/assignation/core/components/assignment-by-steps/assignment-by-step.service';

@Component({
    selector: 'app-assignment-select-one-seance',
    templateUrl: './assignment-select-one-seance-component.component.html',
})
export class AssignmentSelectOneSeanceComponentComponent {

    private assignmentByStepService: AssignmentByStepService;
    public breadcrumb: string[] = [];
    @Output('clickOnNextButton') clickOnNextButton = new EventEmitter<boolean>();

    constructor() {
        this.assignmentByStepService = inject(AssignmentByStepService);
        this.breadcrumb = this.assignmentByStepService.setBreadcrumb();
    }

    public get seances(): { value: string, label: string, selected: boolean }[] {
        return this.assignmentByStepService.seances;
    }

    public get title(): string {
        return this.assignmentByStepService.title;
    }

    public launch(sceance: { label: string, value: string, selected: boolean }): void {
        this.seances.filter(s => s.value === sceance.value)[0].selected = true;
        this.clickOnNextButton.emit(true);
    }
}