import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ProfileService} from '../../profile.service';
import {fuseAnimations} from '@fuse/animations';
import {FormProfile} from '@modules/account-management/core/form-profil.class';
import {TralaTranslationLoaderService} from '../../../../../../trala-translation-loader.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {langs} from '../../../../../../settings';
import {AuthenticationService} from '@modules/authentication';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ProfileDeleteConfirmModalComponent} from '@modules/account-management/core/profile/profile-delete-confirm-modal/profile-delete-confirm-modal.component';
import {AuthorizationService} from '@modules/authorization';
import {AccountManagementProviderService, OPTIONAL_EMAIL_DOMAIN} from '../../../account-management-provider.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {localizedDate} from 'shared/utils/datetime';

@Component({
    selector: 'fuse-profile-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class FuseProfileAboutComponent implements OnInit {
    userInformation: DataEntity;
    defaultUserInformation: FormProfile;
    findUsValue: string;
    regionValue: string;
    levelValue: string;
    youAreValue: string;
    userIDO: string;
    userUAI: string;
    userIsAllowed = false;
    schoolName = '';
    displayLabelFieldInProfile = false;
    checkBoxNewsletter!: boolean;
    public canJoinGroup = false;
    public settingsAuth: { [key: string]: boolean };
    public userLicences = '';

    constructor(
        public authenticationService: AuthenticationService,
        private dialog: MatDialog,
        public profileProvider: ProfileService,
        private translationLoader: TralaTranslationLoaderService,
        private octopusConnect: OctopusConnectService,
        private authorizationService: AuthorizationService,
        public accountProvider: AccountManagementProviderService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.defaultUserInformation = new FormProfile({}, this.octopusConnect);
        const defaultLang: string[] = langs.map((lang) => lang.id);
        this.translationLoader.loadTranslations(...defaultLang);
        // rules is in group managment : SyncRules.BeAttachedToInstitution
        this.userIsAllowed = this.authorizationService.currentUserCan('group-management.be-attached-to-institution');
        if (!this.authenticationService.isGAR()) {
            const currentDate = new Date();
            const moisCourant = currentDate.getMonth() + 1;
            const anneeCourante = currentDate.getFullYear();
            let anneeAjustee = Number(anneeCourante);
            if (moisCourant >= 9 && moisCourant <= 12) {
                anneeAjustee += 1;
            }
            this.userLicences = '15/08/' + anneeAjustee;
        } else {
            this.communicationCenter
                .getRoom('groups-management')
                .getSubject('userLicenses')
                .subscribe((userLicenses) => {
                    const licences = userLicenses.map(ul => +ul.get('expirationDate'));
                    const MostLongerLicence = Math.max(...licences);
                    this.userLicences = localizedDate(MostLongerLicence);
                });
        }
    }

    ngOnInit(): void {
        this.profileProvider.userInformationOnChanged.subscribe(userInformation => {
            this.userInformation = userInformation;
            const newsletterValue: boolean = this.userInformation.get('newsletter');
            if (newsletterValue === false) {
                this.checkBoxNewsletter = true;
            } else {
                this.checkBoxNewsletter = false;
            }

            if (this.userInformation.get('sso')) {
                const ssoToken = JSON.parse(this.userInformation.get('sso_token'));
                if (ssoToken.hasOwnProperty('IDO') && ssoToken.hasOwnProperty('UAI')) {
                    this.userIDO = ssoToken.IDO;
                    this.userUAI = ssoToken.UAI;
                }
            }

            this.findUsValue = this.getValue(this.profileProvider.getFindUsValues(), this.userInformation.get('find_us'));

            if (this.userInformation.get('region') && this.userInformation.get('region').hasOwnProperty('label')) {
                this.regionValue = this.userInformation.get('region').label;
            }

            if (this.userInformation.get('level') && this.userInformation.get('level').hasOwnProperty('label')) {
                this.levelValue = this.userInformation.get('level').label;
            }
            // format is not always the same format : code to manage both case array and not array
            if (this.userInformation.get('level') && this.userInformation.get('level').length > 0 && this.userInformation.get('level')[0].hasOwnProperty('label')) {
                this.levelValue = this.userInformation.get('level')[0].label;
            }

            this.displayLabelFieldInProfile = this.settings.displayLabelFieldInProfile;

            this.youAreValue = this.getValue(this.profileProvider.getYouAreValues(), this.userInformation.get('you_are'));

            this.canJoinGroup = this.profileProvider.getCanJoinGroup();
        });
    }

    setMode(mode: boolean): void {
        this.profileProvider.editMode = mode;
    }

    private getValue(field, value): string {
        const found = field.find((find) => find === value);
        return found ? found : 'generic.to-complete';
    }

    getTranslate(): string {
        if (this.userInformation) {
            if (this.userInformation.get('newsletter')) {
                return 'generic.yes';
            } else {
                return 'generic.no';
            }
        }

        return 'generic.to-complete';
    }

    ifEmptyValue(val): string {
        if (this.userInformation && this.userInformation.get(val)) {
            if (val === 'email') {
                return this.filterOptionalEmail(this.userInformation.get(val));
            } else {
                return this.userInformation.get(val);
            }
        }
        return 'generic.to-complete';
    }


    displayField(name: string): boolean {
        return this.profileProvider.getEditableFields().some(f => f.label === name);
    }

    public deleteCurrentAccount(): void {
        this.dialog
            .open(ProfileDeleteConfirmModalComponent);
    }

    public canDeleteCurrentAccount(): boolean {
        return this.profileProvider.getCanSelfDelete().includes(this.authenticationService.accessLevel);
    }

    public hideEdition(): boolean {
        return this.profileProvider.settings.hideEditSSO && this.userInformation.get('sso');
    }

    public setCurrentSchoolJoined(schoolName: string): void {
        this.schoolName = schoolName;
    }

    public get settings(): { [key: string]: any } {
        return this.accountProvider.settings;
    }

    public onCheckNotifMail(event): void {
        const isChecked = event.target.checked;
        if (isChecked) {
            this.accountProvider.setUserNewsletter(false, () => {
            });
        } else {
            this.accountProvider.setUserNewsletter(true, () => {
            });
        }
    }

    private filterOptionalEmail(email: string): string {
        if (email.indexOf(OPTIONAL_EMAIL_DOMAIN) < 0) {
            return email;
        } else {
            return '';
        }
    }
}
