// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-content {
  max-height: none !important;
}
.mat-dialog-content .msg-thread-add-edit-learner-hint {
  color: gray;
  margin-left: 15px;
  margin-bottom: 15px;
}
.mat-dialog-content .msg-thread-add-edit-audience-invalid {
  border: 1px solid red !important;
}
.mat-dialog-content fieldset {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}
.mat-dialog-content fieldset legend {
  color: gray;
}
.mat-dialog-content .msg-thread-add-edit-create {
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/messaging/core/components/messaging-add-edit/messaging-add-edit.component.scss"],"names":[],"mappings":"AACA;EAEI,2BAAA;AADJ;AAGI;EACI,WAAA;EACA,iBAAA;EACA,mBAAA;AADR;AAII;EACI,gCAAA;AAFR;AAKI;EACI,qCAAA;EACA,kBAAA;AAHR;AAKQ;EACI,WAAA;AAHZ;AAOI;EACI,WAAA;AALR","sourcesContent":["\n.mat-dialog-content {\n\n    max-height: none !important;\n\n    .msg-thread-add-edit-learner-hint {\n        color: gray;\n        margin-left: 15px;\n        margin-bottom: 15px;\n    }\n\n    .msg-thread-add-edit-audience-invalid {\n        border: 1px solid red !important;\n    }\n\n    fieldset {\n        border: 1px solid rgba(0, 0, 0, 0.12);\n        border-radius: 5px;\n\n        legend {\n            color: gray;\n        }\n    }\n\n    .msg-thread-add-edit-create {\n        color: gray;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
