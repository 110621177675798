
import {take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {CommunicationCenterService} from "@modules/communication-center";


@Injectable()
export class LessonActivitiesResolver  {
    constructor(
        private communicationCenter: CommunicationCenterService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.communicationCenter
            .getRoom('activities')
            .next('loadLessons', route);

        return this.communicationCenter
            .getRoom('activities')
            .getSubject('lessonsList').pipe(
            take(1));
    }
}
