import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatToolbarModule } from '@angular/material/toolbar';

import {CommonModule} from '@angular/common';
import {ErrorReportingModule} from 'fuse-core/components/error-reporting/error-reporting.module';
import {FormsModule} from '@angular/forms';
import {RewardsDialogComponent} from '@modules/gamification/core/rewards-dialog/rewards-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';

@NgModule({
    declarations: [
        RewardsDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        ErrorReportingModule,
        FormsModule,
        MatInputModule,
        TranslateModule,
        MatTooltipModule
    ],
})
export class RewardsDialogModule
{
}
