import {DataEntity} from 'octopus-connect';

export interface EditableActivityDefaultValues {
    type: 'DefaultValues';
    granuleType?: string;
    instruction?: string;
    title?: string;
    format?: 'activity' | 'lesson';
}

export class EditableActivity {
    protected _title: string;
    protected _instruction: string;
    protected readonly granuleActivity: DataEntity;
    protected readonly origins: 'granule' | 'DefaultValues';
    protected readonly _granuleType: string;
    private _format: string;

    constructor(granuleActivity: DataEntity | EditableActivityDefaultValues) {
        if (granuleActivity && granuleActivity.type === 'granule') {
            this.granuleActivity = <DataEntity>granuleActivity;
            this.origins = 'granule';
        } else if (granuleActivity && granuleActivity.type === 'DefaultValues') {
            const defaultValues = <EditableActivityDefaultValues>granuleActivity;
            this._title = defaultValues.title;
            this._instruction = defaultValues.instruction;
            this._granuleType = defaultValues.granuleType;
            this.origins = 'DefaultValues';
        } else {
            /**
             * get activity from the "granule" endpoint
             * because we need fields that do not exist in the "granule_search" endpoint
             */
            throw new Error('activity must be from the granule endpoint or a DefaultValues implementation');
        }
    }

    public get title(): string {
        if (!!this._title) {
            return this._title;
        }
        if (!this.granuleActivity) {
            return '';
        }
        return this.granuleActivity
            && this.granuleActivity.get('metadatas')
            && this.granuleActivity.get('metadatas').title;
    }

    public get instruction(): string {
        if (!!this._instruction) {
            return this._instruction;
        }
        if (!this.granuleActivity) {
            return '';
        }
        if (this.granuleActivity.get('format') &&
            this.granuleActivity.get('format').label === 'lesson') {
            return '';
        }
        const field = this.type === 'QCMU' ? 'wording' : 'instruction';
        return this.granuleActivity.get('format')
            && this.granuleActivity.get('reference')[field];
    }

    public set title(title: string) {
        this._title = title;
    }

    public set instruction(instruction: string) {
        this._instruction = instruction;
    }

    public get type(): string {
        if (!!this._granuleType) {
            return this._granuleType;
        }

        return this.granuleActivity.get('metadatas')
            && this.granuleActivity.get('metadatas').typology
            && this.granuleActivity.get('metadatas').typology.label;
    }

    public get format(): string {
        if (!!this._format) {
            return this._format;
        }

        try {
            return this.granuleActivity.get('format').label;
        } catch (e) {
            return 'activity';
        }
    }

    public get original(): boolean {
        if (this.origins === 'DefaultValues') {
            return false;
        }

        return this.granuleActivity.get('original');
    }

    public get granuleActivityEntity(): DataEntity {
        return this.granuleActivity;
    }

}
