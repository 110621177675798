// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  padding: 4px;
  text-align: center;
}
.content .modal-text {
  margin-bottom: 20px;
  font-size: 15px;
}
.content .modal-buttons input {
  height: 30px;
  width: 130px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  margin-bottom: 7px;
  cursor: pointer;
  border: none;
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/research-sheet/core/generic-modal/generic-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF;AAEE;EACE,mBAAA;EAEA,eAAA;AADJ;AAMI;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EAEA,eAAA;EAEA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;AANN","sourcesContent":[".content {\n  padding: 4px;\n  text-align: center;\n  //font-family: \"robotoregular\";\n\n  .modal-text {\n    margin-bottom: 20px;\n    //font-weight: bold;\n    font-size: 15px;\n  }\n\n  .modal-buttons {\n\n    input {\n      height: 30px;\n      width: 130px;\n      border-radius: 4px;\n      //background-color: #358ED7;\n      font-size: 14px;\n      //color: white;\n      text-align: center;\n      line-height: 22px;\n      margin-bottom: 7px;\n      cursor: pointer;\n      border: none;\n      display: inline-block;\n      margin-left: 20px;\n      margin-right: 20px;\n      //outline: none;\n\n      &.cancel-action, &.no-action {\n        //background-color: #ddd9d9;\n        //color: black;\n      }\n\n      &.validate-action, &.yes-action {\n        //background-color: #48D2A0;\n        //color: white;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
