import * as _ from 'lodash-es';

export class ProgressEndpointFilters {
    constructor(
        public date_from: number,
        public date_to: number,
        public id?: string | string[]
    ) {
    }

    static isSame = (a: ProgressEndpointFilters, b: ProgressEndpointFilters): boolean => {
        try {
            return a.date_from === b.date_from && a.date_to === b.date_to && _.isEqual(a.id, b.id);
        } catch (e) {
            console.warn(e);
            return false;
        }
    }

    isSame(b: ProgressEndpointFilters): boolean {
        return ProgressEndpointFilters.isSame(this, b);
    }
}
