import {Component, OnInit, Inject, ViewEncapsulation, ElementRef, ViewChild} from '@angular/core';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {AgendaService} from '../agenda.service';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {AgendaEventUI} from '../definitions';
import {AuthenticationService} from '@modules/authentication';
import {ActivatedRoute, Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {Group} from '@modules/groups-management/core/definitions';

export interface DialogEventData {
    event: AgendaEventUI;
    canEdit: boolean;
    userRole: 'learner' | 'trainer' | 'manager';
    agendaService: AgendaService;
    windowRole: 'learner' | 'trainer' | 'manager';
}

@Component({
    selector: 'app-dialog-event',
    templateUrl: './dialog-event.component.html',
    styleUrls: ['./dialog-event.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DialogEventComponent implements OnInit {

    windowTitle: string;
    titlePlaceholder: string;
    today: Date;
    event: AgendaEventUI;
    canEdit: boolean;
    userRole: 'learner' | 'trainer' | 'manager';

    @ViewChild('participantsInput') participantsInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;


    private _startTime: string;
    private _endTime: string;
    private _fullDays: boolean;

    get fullDays(): boolean {
        return this._fullDays;
    }

    set fullDays(value: boolean) {
        if (value === true) {
            this.startTime = '00:00';
            this.endTime = '23:59:59';
        }
        this._fullDays = value;
    }

    get startTime(): string {
        return this._startTime;
    }

    set startTime(time: string) {
        this._startTime = time;
        const parsedTime = time.split(':');
        this.event.start = moment(this.event.start).set('hours', +parsedTime[0]).format();
        this.event.start = moment(this.event.start).set('minutes', +parsedTime[1]).format();
        if (moment(this.event.start).isAfter(this.event.end)) {
            this.endTime = moment(this.event.start).add(1, 'hours').format('HH:mm');
        }
    }

    get startDate(): string {
        return this.event.start;
    }

    set startDate(startDate: string) {
        this.event.start = startDate;
        const parsedTime = this.startTime.split(':');
        this.event.start = moment(this.event.start).set('hours', +parsedTime[0]).format();
        this.event.start = moment(this.event.start).set('minutes', +parsedTime[1]).format();
        if (moment(this.event.start).isAfter(this.event.end)) {
            this.event.end = moment(this.event.start).toISOString();
        }

    }

    get endDate(): string {
        return this.event.end;
    }

    set endDate(endDate: string) {
        const parsedTime = this.endTime.split(':');
        endDate = moment(endDate).set('hours', +parsedTime[0]).format();
        endDate = moment(endDate).set('minutes', +parsedTime[1]).format();
        if (moment(endDate).isAfter(this.event.start)) {
            this.event.end = endDate;
        }
    }

    get endTime(): string {
        return this._endTime;
    }

    set endTime(time: string) {
        this._endTime = time;
        const parsedTime = time.split(':');
        this.event.end = moment(this.event.end).set('hours', +parsedTime[0]).format();
        this.event.end = moment(this.event.end).set('minutes', +parsedTime[1]).format();
    }

    constructor(
        public dialogRef: MatDialogRef<DialogEventComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogEventData,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private communicationCenter: CommunicationCenterService,
        public agendaService: AgendaService
    ) {
        if (data) {
            if (data.event) {
                this.event = {...data.event, extendedProps: {...data.event.extendedProps}};
                this.startTime = moment(this.event.start).format('HH:mm');
                this.endTime = moment(this.event.end).format('HH:mm');
            } else {
                this.event = {
                    id: undefined,
                    title: '',
                    start: moment().add(1, 'days').set('hours', 0).set('minutes', 0).set('seconds', 0).format(),
                    end: moment().add(2, 'days').set('hours', 0).set('minutes', 0).set('seconds', 0).subtract('seconds', 1).format(),
                    extendedProps: {notes: '', role: this.data.userRole, type: 'event'},
                    classNames: [this.data.userRole]
                };
                this.startTime = moment(this.event.start).format('HH:mm');
                this.endTime = moment(this.event.end).format('HH:mm');
                this.fullDays = true;
            }
            this.canEdit = data.canEdit;
            this.userRole = data.userRole;

        } else {
            this.startTime = '00:00';
            this.endTime = '23:59';
        }
        this.agendaService = this.data.agendaService;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        if (this.event.extendedProps.type === 'event') {
            this.translate.get('agenda.event.window_title.event').subscribe((translation: string) => this.windowTitle = translation);
            this.translate.get('agenda.event.title.placeholder.event').subscribe((translation: string) => this.titlePlaceholder = translation);
        } else if (this.event.extendedProps.type === 'assignation') {
            this.translate.get('agenda.event.window_title.' + this.event.extendedProps.assignationType).subscribe((translation: string) => this.windowTitle = translation);
            this.translate.get('agenda.event.title.placeholder.' + this.event.extendedProps.assignationType).subscribe((translation: string) => this.titlePlaceholder = translation);
        }
        this.today = new Date();
    }

    get minTime(): string {
        if (moment(this.event.start).isSame(this.event.end, 'days')) {
            return moment(this.event.start).format('HH:mm');
        }
        return '00:00 am';
    }

    get learners(): Array<number | string> {
        return this.event.extendedProps.members;
    }

    set learners(learners: Array<number | string>) {
        if (!learners) {
            delete this.event.extendedProps.members;
        } else {
            this.event.extendedProps.members = learners;
        }
    }

    get submitEnabled(): boolean {
        return !!this.event.title &&
            !!this.event.start &&
            !!this.event.end &&
            !!this.startTime &&
            !!this.endTime;
    }

    get isTrainer(): boolean {
        return this.authenticationService.isTrainer();
    }

    get isLearner(): boolean {
        return this.authenticationService.isLearner();
    }

    get isAssignment(): boolean {
        return this.event.extendedProps.type === 'assignation';
    }

    get isHomeworkOrAssessment(): boolean {
        return this.event.extendedProps.entity.attributes.type_term.label === 'assessment' || this.event.extendedProps.entity.attributes.type_term.label === 'homework';

    }

    get isToday(): boolean {
        return moment(this.today).isBetween(this.startDate, this.endDate);
    }

    public goToFollowedAssignments(): void {
        const data = {
            title: this.event.title,
            type: this.event.extendedProps.assignationType
        };
        this.router.navigate(['../', 'followed', 'list'], {relativeTo: this.route, queryParams: data});
        this.dialogRef.close();
    }

    public goToAssignment(): void {
        this.agendaService.launchAssignment(this.event);
        this.dialogRef.close();
    }

    /**
     * list of selected learners for filter
     * @param learners
     */
    selectedLearners(learners: number[] | string[]): void {
        if (!learners || learners.length === 0) {
            delete this.event.extendedProps.members;
        } else {
            this.event.extendedProps.members = learners;
        }
    }

    /**
     * for read only case return only learner previously selected
     */
    get learnersListingSelected(): Array<Group> {
        return this.agendaService.learnersList ? this.agendaService.learnersList.filter(l => this.learners.includes(l.id.toString())) : [];
    }

}
