import { Injectable } from '@angular/core';
import {modulesSettings} from '../../../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {Roles} from 'shared/models/roles';

const settingsStructure: ModelSchema = new ModelSchema({
    rolesCanShowBannerInfo: Structures.array(),
});

@Injectable({
  providedIn: 'root'
})
export class GraphConfigurationService {
    private settings: { rolesCanShowBannerInfo: Roles[] };

  constructor() {
      this.settings = settingsStructure.filterModel(
          modulesSettings.graphMulti
      ) as GraphConfigurationService['settings'];
  }

    public isRolesCanShowBannerInfo(): Roles[] {
        return this.settings.rolesCanShowBannerInfo || [];
    }
}
