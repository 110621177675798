import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-continue-bar',
    templateUrl: './continue-bar.component.html'
})
export class ContinueBarComponent implements OnInit {

    @Input('displayDefaultLink') displayDefaultLink = false;
    public showSpinner = true;
    public hasSave = false;
    public buttonText = '';
    public buttonTabText = '';
    public buttonRouteArray: string[] = ['lessons', 'home']; // set default route
    public buttonRouteQueryParam: any;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private lessonsService: LessonsService,
        private router: Router,
        private translate: TranslateService,
    ) {
        this.initialize();
    }

    ngOnInit(): void {}

    /**
     * set button to navigate to last saved lesson or default route
     */
    initialize(): void {
        this.translate.get('generic.no_lesson_is_in_progress').subscribe((translation: string) => this.buttonTabText = translation);
        this.translate.get('concept_card.play_button').subscribe((translation: string) => this.buttonText = translation);

        this.lessonsService.loadLastUserSave().subscribe(collection => {
            if (collection) {
                const userSaves = collection;
                // if user saves
                if (userSaves.length > 0) {
                    const lessonEntity = userSaves[0]['attributes']['granuleParent'];
                    // get lesson from assignment
                    if (lessonEntity
                        && lessonEntity['metadatas']
                        && lessonEntity['metadatas'].educationalLevel
                        && lessonEntity['metadatas'].educationalLevel[0]
                        && lessonEntity['metadatas'].educationalLevel[0].id
                        && lessonEntity['metadatas'].concepts
                        && lessonEntity['metadatas'].concepts[0].id
                        && lessonEntity['metadatas'].chapters) {
                        const educationalLevelId = lessonEntity['metadatas'].educationalLevel[0].id;
                        const conceptId = lessonEntity['metadatas'].concepts[0].id;

                        // get chapterId with context
                        let chapterId = null;
                        for (const chapter of lessonEntity['metadatas'].chapters) {
                            if (chapter.concepts.includes(conceptId)) {
                                chapterId = chapter.id;
                            }
                        }
                        // navigate to lesson
                        this.buttonRouteArray = ['lessons', 'grade', educationalLevelId, 'concept', conceptId];
                        this.buttonRouteQueryParam = {queryParams: {lesson: lessonEntity.id, chapter: chapterId}};
                        this.translate.get('generic.lesson_is_in_progress').subscribe((translation: string) => this.buttonTabText = translation);
                        this.translate.get('concept_card.continue_button').subscribe((translation: string) => this.buttonText = translation);
                        if (!this.displayDefaultLink){
                            this.hasSave = true;
                        }
                        this.showSpinner = false;
                    } else {
                        if (!this.displayDefaultLink){
                            this.hasSave = false;
                        }
                        this.showSpinner = false;
                    }
                } else {
                    if (!this.displayDefaultLink){
                        this.hasSave = false;
                    }
                    this.showSpinner = false;
                }
            }
        });
    }

    /**
     * navigate to default if no lesson o reditect to
     */
    navigate(): void {
        this.router.navigate(this.buttonRouteArray, this.buttonRouteQueryParam);
    }

}
