import { Component, OnInit } from '@angular/core';
import {UserPoints, UserScoreService} from '@modules/achievement/core/services/user-score.service';
import {AccountManagementProviderService} from '@modules/account-management';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect/typed-data-entity.interface';
import {BehaviorSubject} from 'rxjs';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {Subject} from 'rxjs/internal/Subject';

@Component({
    selector: 'app-my-ranking',
    templateUrl: './my-ranking.component.html'
})
export class MyRankingComponent implements OnInit {

    public userInformation$: BehaviorSubject<UserDataEntity> = this.accountService.data;

    constructor(
        private userScoreService: UserScoreService,
        private accountService: AccountManagementProviderService,
    ) { }

    ngOnInit(): void {
    }

    public get userPoints(): TypedDataEntityInterface<UserPoints>[] {
        return this.userScoreService.userPoints;
    }

    public get loadingSubject(): Subject<boolean> {
        return this.userScoreService.loadingSubject;
    }
}
