import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResearchSheetService} from '../../research-sheet.service';
import {DataEntity} from 'octopus-connect';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {DraftQuestionsModalComponent} from '@modules/research-sheet/core/section-draft-display/draft-questions-modal/draft-questions-modal.component';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {combineLatest} from 'rxjs';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {CorpusService, ExternalCorpusDisplayComponent} from '@modules/corpus';

@Component({
    selector: 'app-section-draft-edit',
    templateUrl: './section-draft-edit.component.html',
    styleUrls: ['./section-draft-edit.component.scss']
})
export class SectionDraftEditComponent implements OnInit, OnDestroy, AfterViewInit {

    currentPanel = 'consigne';
    mode = 'edition';

    currentDraftId: number;
    currentDraft: DataEntity;
    sectionDefinition: DataEntity;
    readingGrid: Object;
    feedbacks: string[];

    draftText = '';
    originalText = '';

    logicalConnectors: Object[];
    openings: Object[];

    @ViewChild('texteditor', { static: true }) textEditor: ElementRef;
    @ViewChild('corpusDisplay') corpusDisplay: ExternalCorpusDisplayComponent;

    formSection: UntypedFormGroup;
    formErrors: any;

    selectedIndex = 0;

    linkedResources: CorpusRessource[] = [];

    firstUse = false;

    displayMode: string;

    expanded = true;
    accordionDisplay = false;
    corpusDisplayed = false;

    hideFeedback = null;

    public canSave: boolean;
    textAreaControl: UntypedFormControl;

    constructor(
        private route: ActivatedRoute,
        private sheetService: ResearchSheetService,
        private router: Router,
        private dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        private corpusService: CorpusService
    ) { }

    ngAfterViewInit(): any {
        this.expanded = false;
    }

    ngOnInit(): any {

        this.textAreaControl = new UntypedFormControl('');

        this.route.data.subscribe((routeData: Object) => {
            this.displayMode = routeData['displayMode'];
        });

        this.formErrors = {
            sectionNewValue: {}
        };

        this.formSection = this.formBuilder.group({
            sectionNewValue: ['', Validators.required]
        });

        this.route.params.subscribe(params => {
            this.currentDraftId = params['editionid'];

            combineLatest(this.sheetService.loadDraft(this.currentDraftId), this.sheetService.completedObs).subscribe(values => {
                const entity: DataEntity = values[0];

                this.corpusService.loadCorpusRessourcesList(entity.get('medias')).subscribe((resources: CorpusRessource[]) => {
                    this.linkedResources = resources;
                });

                this.currentDraft = entity;

                this.draftText = entity.get('text') || '';
                this.textAreaControl.setValue(entity.get('text') || '');

                if (this.draftText === '') {
                    this.firstUse = true;
                } else {
                    // this.mode = 'reformulation';
                }

                this.sectionDefinition = this.sheetService.templatesSectionsCollection[+entity.get('sectionDefinitions')];
                this.readingGrid = this.sectionDefinition.get('readingGrids');

                this.logicalConnectors = this.sectionDefinition.get('logicalLinkSet')['logicalLinks'].filter(elem => {
                    return elem['type'] === 'logicalLinks';
                });

                this.openings = this.sectionDefinition.get('logicalLinkSet')['logicalLinks'].filter(elem => {
                    return elem['type'] === 'openings';
                });

                this.hideFeedback = this.sectionDefinition.get('hideFeedbacks');

            });
        });
    }

    ngOnDestroy(): void {
        this.corpusService.clearSelection();
    }

    textChanged(): void {
        this.draftText = this.textAreaControl.value;
        this.checkSaveAbility();
    }

    get readingGridText(): string {
        if (this.readingGrid) {
            return this.readingGrid['text'] || '';
        }

        return '';
    }

    private checkSaveAbility() {
        const medias = this.currentDraft.get('medias');
        const textChanged = this.currentDraft.get('text') !== this.draftText;
        const mediasChanged = medias.length !== this.linkedResources.length || (medias.length && !medias.every(media => this.linkedResources.find(resource => +resource.id === +media)));

        this.canSave = textChanged || mediasChanged;
    }

    validateDraft(): void {
        this.currentDraft.set('text', this.draftText);
        this.currentDraft.set('medias', this.linkedResources.map(resource => resource.id));
        this.canSave = false;
        if (!this.hideFeedback){
            this.displayQuestionsSequence();
        }
    }

    displayResource(resource: CorpusRessource): void {
        this.corpusService.openRessource(resource);
    }

    removeResource(resource: CorpusRessource): void {
        const index: number = this.linkedResources.indexOf(resource);
        this.linkedResources.splice(index, 1);
        this.checkSaveAbility();
    }

    cancelDraft(): void {
        this.router.navigate([this.sheetService.getDraftListLink()]);
    }

    displayQuestionsSequence(): void {
        this.dialog.open(DraftQuestionsModalComponent, {
            panelClass: 'research-sheet-draftQuestions-dialog',
            data: this.sectionDefinition.get('draftQuestions'),
            disableClose: true
        }).afterClosed().subscribe((feedbacks: string[]) => {
            this.feedbacks = [];
            this.feedbacks.push(...feedbacks.filter((feedB) => feedB !== null));
            this.displayFeedbacks(this.feedbacks);
            this.mode = 'reformulation';

            const ids: number[] = [];

            this.linkedResources.forEach(resource => {
                ids.push(+resource.id);
            });

            this.currentDraft.set('medias', ids);

            this.currentDraft.save();

            this.selectedIndex = 1;
        });
    }

    // pas vraiment clean, mais en attendant
    openTab(event) {
        if (event.index === 1) {
            setTimeout(() => {
                this.accordionDisplay = true;
            }, 100);
        } else {
            setTimeout(() => {
                this.accordionDisplay = false;
            }, 100);
        }

        if (event.index === 2) {
            setTimeout(() => {
                this.corpusDisplayed = true;
            }, 100);
        } else {
            setTimeout(() => {
                this.corpusDisplayed = false;
            }, 100);
        }
    }

    displayFeedbacks(feedbacks: string[]) {
        this.feedbacks = feedbacks;
        // this.currentPanel = 'feedbacks';
        // this.selectedIndex = 1;
    }

    logicalLinkInsertion(link: Object): void {
        const position: number = this.textEditor.nativeElement.selectionEnd;

        let before = '';

        if (link['type'] === 'logicalLinks') {
            // insertion d'un espace avant si besoin
            if (this.draftText.length > 0 && this.draftText.charAt(position - 1) !== ' ') {
                before += ' ';
            }
        } else if (link['type'] === 'openings' && position !== 0) {
            // before += '\n\n';
        }

        this.textEditor.nativeElement.focus();

        // insertion d'un espace après si besoin (optionnel)

        // this.selectedIndex = 2;
        this.draftText = this.draftText.substr(0, position) + before + link['name']  + this.draftText.substr(position);
        this.textAreaControl.setValue(this.draftText);
        this.textChanged();
    }

    get corpusId(): number {
        return this.corpusService.getCurrentCorpusSet(this.sheetService.currentProjectId).corpusArray[0].id;
    }

    onCorpusSelectionChanged(resources: DataEntity[]) {
        if (resources) {
            this.linkedResources = resources.map((resource: DataEntity) => new CorpusRessource(resource, this.corpusService));
            this.checkSaveAbility();
        }
    }

    public get showDraftFeedback(): boolean {
        return this.feedbacks && this.feedbacks.length > 0;
    }
    public get showWritingHelp(): boolean {
        return this.openings && this.displayMode === 'edition' && this.openings.length > 0;
    }
}
