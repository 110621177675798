import { Component, OnInit, Input } from '@angular/core';
import { GamificationService } from '../gamification.service';

@Component({
  selector: 'app-accessory-thumbnail',
  templateUrl: './accessory-thumbnail.component.html',
  styleUrls: ['./accessory-thumbnail.component.scss']
})
export class AccessoryThumbnailComponent implements OnInit {

  @Input()
  badge: any;

  get userPoints(): any {
    return this.gamificationService.userPoints;
  }

  constructor(private gamificationService: GamificationService) { }

  ngOnInit(): void {
  }

}
