// Rules list to test authorizations. As a `SyncRules` they must return a boolean when they are tested.
export enum SyncRules {
    // Allow menu to display menu item for the graph dysapp module in the global dynamic menu
    seeGraphsLinksInMenu = 'graph-dysapp.see-graphs-links-in-menu',
    // Allow display banner info for the graph dysapp
    seeDysappGraphsBannerInfo = 'graph-dysapp.seeDysappGraphsBannerInfo'
}

// Async Rules list to test authorizations. As an `AsyncRules` they must return a `Observable<boolean>` when they are tested.
export enum AsyncRules {

}