import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-dialog-resume-action-menu',
    templateUrl: './dialog-resume-action-menu.component.html',
})
export class DialogResumeActionMenuComponent implements OnChanges {
    public isPlayingUserAudio = false;
    public isPlayingOriginalAudio = false;
    @Output() recordAgain = new EventEmitter<boolean>();
    @Output() playUserRecord = new EventEmitter<boolean>();
    @Output() playOriginalVersion = new EventEmitter<boolean>();
    @Output() stopPlaying = new EventEmitter<boolean>();
    @Input() playing = false;
    @Input() recordForSelectedElementExist = false;


    ngOnChanges(changes: SimpleChanges): void {
        if (!this.playing) {
            this.isPlayingOriginalAudio = false;
            this.isPlayingUserAudio = false;
        }
    }

    playUserVersion(): void {
        this.isPlayingUserAudio = true;
        this.isPlayingOriginalAudio = false;
        this.playUserRecord.emit(true);
    }

    play(): void {
        this.isPlayingOriginalAudio = true;
        this.isPlayingUserAudio = false;
        this.playOriginalVersion.emit(true);
    }

    stop(): void {
        this.isPlayingUserAudio = false;
        this.isPlayingOriginalAudio = false;
        this.stopPlaying.emit(true);
    }

    record(): void {
        this.recordAgain.emit(true);
    }
}
