import {Inject, Injectable} from '@angular/core';
import {BrowserTestConfiguration} from './browser-test-configuration.interface';

@Injectable()
export class ConfigurationProvider {

    constructor(
        @Inject('browser-test-configuration') public configuration: BrowserTestConfiguration
    ) {}

}